import api from 'HttpClient/index';

export const getRoutersPerPage = (pageNo, pageSize) => {
  return api.get(`/routeinfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`);
};
export const getRoutersPerPageAll = (params) => {
  return api.get(`/routeinfo/paging/page`, { params });
};

export const getRoutersByID = (id) => {
  return api.get(`/routeinfo/getById/${id}`);
};

export const createRouters = (payload) => {
  return api.post(`/routeinfo/create`, payload);
};

export const updateRouters = (payload) => {
  return api.put(`/routeinfo/update`, payload);
};

export const deleteRouters = (id) => {
  return api.delete(`/routeinfo/delete/${id}`);
};

export const getAllRouters = () => {
  return api.get(`/routeinfo/getAll`);
};

export const searchData = (params) => {
  return api.get(`/routeinfo/search`, { params });
};
export const searchDataAll = (params) => {
  return api.get(`/routeinfo/search`, { params });
};

export const getOptionRoute = () => {
  return api.get(`/routeinfo/getAll/cbxTravelFee`);
};
