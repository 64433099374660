import httpClient from 'HttpClient/index';
import {
  getTruckingFeeByID,
  getTruckingFeePerPage,
  updateAddingTruckingFee,
  updateAddingTruckingFeeTravelFee,
  updateAddingTruckingFeeTruckingFee,
  updateTruckingFee,
  updateTruckingFeeTravelFee,
  updateTruckingFeeTruckingFee
} from 'services/truckingfee';

// define const actions
export const TRUCKING_FEE_ACTIONS = {
  GET_TRUCKING_FEE_PER_PAGE: 'GET_TRUCKING_FEE_PER_PAGE',
  GET_TRUCKING_FEE_PER_PAGE_PENDING: 'GET_TRUCKING_FEE_PER_PAGE_PENDING',
  GET_TRUCKING_FEE_PER_PAGE_FULFILLED: 'GET_TRUCKING_FEE_PER_PAGE_FULFILLED',
  GET_TRUCKING_FEE_PER_PAGE_REJECTED: 'GET_TRUCKING_FEE_PER_PAGE_REJECTED',

  SEARCH_TRUCKING_FEE: 'SEARCH_TRUCKING_FEE',
  SEARCH_TRUCKING_FEE_PENDING: 'SEARCH_TRUCKING_FEE_PENDING',
  SEARCH_TRUCKING_FEE_FULFILLED: 'SEARCH_TRUCKING_FEE_FULFILLED',
  SEARCH_TRUCKING_FEE_REJECTED: 'SEARCH_TRUCKING_FEE_REJECTED',

  GET_TRUCKING_FEE_BY_ID: 'GET_TRUCKING_FEE_BY_ID',
  GET_TRUCKING_FEE_BY_ID_PENDING: 'GET_TRUCKING_FEE_BY_ID_PENDING',
  GET_TRUCKING_FEE_BY_ID_FULFILLED: 'GET_TRUCKING_FEE_BY_ID_FULFILLED',
  GET_TRUCKING_FEE_BY_ID_REJECTED: 'GET_TRUCKING_FEE_BY_ID_REJECTED',

  GET_TRUCKING_FEE_BY_CONSIGNMENT_ID: 'GET_TRUCKING_FEE_BY_CONSIGNMENT_ID',
  GET_TRUCKING_FEE_BY_CONSIGNMENT_ID_PENDING: 'GET_TRUCKING_FEE_BY_CONSIGNMENT_ID_PENDING',
  GET_TRUCKING_FEE_BY_CONSIGNMENT_ID_FULFILLED: 'GET_TRUCKING_FEE_BY_CONSIGNMENT_ID_FULFILLED',
  GET_TRUCKING_FEE_BY_CONSIGNMENT_ID_REJECTED: 'GET_TRUCKING_FEE_BY_CONSIGNMENT_ID_REJECTED',

  UPDATE_TRUCKING_FEE: 'UPDATE_TRUCKING_FEE',
  UPDATE_TRUCKING_FEE_PENDING: 'UPDATE_TRUCKING_FEE_PENDING',
  UPDATE_TRUCKING_FEE_FULFILLED: 'UPDATE_TRUCKING_FEE_FULFILLED',
  UPDATE_TRUCKING_FEE_REJECTED: 'UPDATE_TRUCKING_FEE_REJECTED',

  UPDATE_ADDING_TRUCKING_FEE: 'UPDATE_ADDING_TRUCKING_FEE',
  UPDATE_ADDING_TRUCKING_FEE_PENDING: 'UPDATE_ADDING_TRUCKING_FEE_PENDING',
  UPDATE_ADDING_TRUCKING_FEE_FULFILLED: 'UPDATE_ADDING_TRUCKING_FEE_FULFILLED',
  UPDATE_ADDING_TRUCKING_FEE_REJECTED: 'UPDATE_ADDING_TRUCKING_FEE_REJECTED',

  FETCH_NEWEST_TRUCKING_FEE: 'FETCH_NEWEST_TRUCKING_FEE',
  FETCH_NEWEST_TRUCKING_FEE_PENDING: 'FETCH_NEWEST_TRUCKING_FEE_PENDING',
  FETCH_NEWEST_TRUCKING_FEE_FULFILLED: 'FETCH_NEWEST_TRUCKING_FEE_FULFILLED',
  FETCH_NEWEST_TRUCKING_FEE_REJECTED: 'FETCH_NEWEST_TRUCKING_FEE_REJECTED',

  SEARCH_BY_FIELD_TRUCKING_FEE: 'SEARCH_BY_FIELD_TRUCKING_FEE',
  SEARCH_BY_FIELD_TRUCKING_FEE_PENDING: 'SEARCH_BY_FIELD_TRUCKING_FEE_PENDING',
  SEARCH_BY_FIELD_TRUCKING_FEE_FULFILLED: 'SEARCH_BY_FIELD_TRUCKING_FEE_FULFILLED',
  SEARCH_BY_FIELD_TRUCKING_FEE_REJECTED: 'SEARCH_BY_FIELD_TRUCKING_FEE_REJECTED',

  SEARCH_ABNORMAL_BY_FIELD_TRUCKING_FEE: 'SEARCH_ABNORMAL_BY_FIELD_TRUCKING_FEE',
  SEARCH_ABNORMAL_BY_FIELD_TRUCKING_FEE_PENDING: 'SEARCH_ABNORMAL_BY_FIELD_TRUCKING_FEE_PENDING',
  SEARCH_ABNORMAL_BY_FIELD_TRUCKING_FEE_FULFILLED: 'SEARCH_ABNORMAL_BY_FIELD_TRUCKING_FEE_FULFILLED',
  SEARCH_ABNORMAL_BY_FIELD_TRUCKING_FEE_REJECTED: 'SEARCH_ABNORMAL_BY_FIELD_TRUCKING_FEE_REJECTED',

  UPDATE_PAYMENT_TRUCKING_FEE: 'UPDATE_PAYMENT_TRUCKING_FEE',
  UPDATE_PAYMENT_TRUCKING_FEE_PENDING: 'UPDATE_PAYMENT_TRUCKING_FEE_PENDING',
  UPDATE_PAYMENT_TRUCKING_FEE_FULFILLED: 'UPDATE_PAYMENT_TRUCKING_FEE_FULFILLED',
  UPDATE_PAYMENT_TRUCKING_FEE_REJECTED: 'UPDATE_PAYMENT_TRUCKING_FEE_REJECTED',

  UPDATE_EDITABLE_TRUCKING_FEE: 'UPDATE_EDITABLE_TRUCKING_FEE',
  UPDATE_EDITABLE_TRUCKING_FEE_PENDING: 'UPDATE_EDITABLE_TRUCKING_FEE_PENDING',
  UPDATE_EDITABLE_TRUCKING_FEE_FULFILLED: 'UPDATE_EDITABLE_TRUCKING_FEE_FULFILLED',
  UPDATE_EDITABLE_TRUCKING_FEE_REJECTED: 'UPDATE_EDITABLE_TRUCKING_FEE_REJECTED',

  UPDATE_CONT_WEIGHT: 'UPDATE_CONT_WEIGHT',
  UPDATE_CONT_WEIGHT_PENDING: 'UPDATE_CONT_WEIGHT_PENDING',
  UPDATE_CONT_WEIGHT_FULFILLED: 'UPDATE_CONT_WEIGHT_FULFILLED',
  UPDATE_CONT_WEIGHT_REJECTED: 'UPDATE_CONT_WEIGHT_REJECTED',

  UPDATE_STANDARD: 'UPDATE_CONT_WEIGHT',
  UPDATE_STANDARD_PENDING: 'UPDATE_STANDARD_PENDING',
  UPDATE_STANDARD_FULFILLED: 'UPDATE_STANDARD_FULFILLED',
  UPDATE_STANDARD_REJECTED: 'UPDATE_STANDARD_REJECTED',

  UPDATE_FULL_STANDARD: 'UPDATE_FULL_STANDARD',
  UPDATE_FULL_STANDARD_PENDING: 'UPDATE_FULL_STANDARD_PENDING',
  UPDATE_FULL_STANDARD_FULFILLED: 'UPDATE_FULL_STANDARD_FULFILLED',
  UPDATE_FULL_STANDARD_REJECTED: 'UPDATE_FULL_STANDARD_REJECTED',

  UPDATE_DATA_TABLE: 'UPDATE_DATA_TABLE'
};

// define actions
export const actionGetTruckingFeeByID = (id) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.GET_TRUCKING_FEE_BY_ID,
    payload: getTruckingFeeByID(id)
  });
};

export const actionGetTruckingFeePerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.GET_TRUCKING_FEE_PER_PAGE,
    payload: getTruckingFeePerPage(pageNo, pageSize)
  });
};

export const actionSearchTruckingFee = (value, pageNo, pageSize, fromDate, toDate) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.SEARCH_TRUCKING_FEE,
    payload: httpClient.get(
      `/TruckingFee/Search?value=${value}&Page=${pageNo}&PageSize=${pageSize}&fromDate=${fromDate}&toDate=${toDate}`
    )
  });
};

export const actionGetTruckingFeeByConsignmentId = (id) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.GET_TRUCKING_FEE_BY_CONSIGNMENT_ID,
    payload: httpClient.get(`/TruckingFee/GetByConsignmentId?consignmentId=${id}`)
  });
};

export const actionUpdateTruckingFee = (payload) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.UPDATE_TRUCKING_FEE,
    payload: updateTruckingFee(payload),
    meta: {
      currentPage: payload?.currentPage ?? 1
    }
  });
};

export const actionUpdateTruckingFeeTruckingFee = (payload) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.UPDATE_TRUCKING_FEE,
    payload: updateTruckingFeeTruckingFee(payload),
    meta: {
      currentPage: payload?.currentPage ?? 1
    }
  });
};

export const actionUpdateTruckingFeeTravelFee = (payload) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.UPDATE_TRUCKING_FEE,
    payload: updateTruckingFeeTravelFee(payload),
    meta: {
      currentPage: payload?.currentPage ?? 1
    }
  });
};

export const actionUpdateAddingTruckingFee = (payload) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.UPDATE_ADDING_TRUCKING_FEE,
    payload: updateAddingTruckingFee(payload),
    meta: {
      truckingFeeID: payload.truckingFeeID,
      currentPage: payload.currentPage
    }
  });
};

export const actionUpdateAddingTruckingFeeTruckingFee = (payload) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.UPDATE_ADDING_TRUCKING_FEE,
    payload: updateAddingTruckingFeeTruckingFee(payload),
    meta: {
      truckingFeeID: payload.truckingFeeID,
      currentPage: payload.currentPage
    }
  });
};

export const actionUpdateAddingTruckingFeeTravelFee = (payload) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.UPDATE_ADDING_TRUCKING_FEE,
    payload: updateAddingTruckingFeeTravelFee(payload),
    meta: {
      truckingFeeID: payload.truckingFeeID,
      currentPage: payload.currentPage
    }
  });
};

export const fetchNewestTruckingFee = (payload) => (dispatch) => {
  const url = `/AddingTruckingFee/getExistFee`;
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.FETCH_NEWEST_TRUCKING_FEE,
    payload: httpClient.post(url, payload)
  });
};
export const actionSearchAbnormalByFieldTruckingFee = (startDate, endDate, pageNo, pageSize) => (dispatch) => {
  let url = `/TruckingFee/SearchAbnormalByField?Page=${pageNo}&PageSize=${pageSize}`;
  if (startDate) url += `&startDate=${startDate}`;
  if (endDate) url += `&endDate=${endDate}`;
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.SEARCH_BY_FIELD_TRUCKING_FEE,
    payload: httpClient.get(url)
  });
};

export const actionSearchAbnormalByFieldTruckingFeeTruckingFee =
  (startDate, endDate, pageNo, pageSize) => (dispatch) => {
    let url = `/TruckingFee/SearchAbnormalByField/truckingfee?Page=${pageNo}&PageSize=${pageSize}`;
    if (startDate) url += `&startDate=${startDate}`;
    if (endDate) url += `&endDate=${endDate}`;
    return dispatch({
      type: TRUCKING_FEE_ACTIONS.SEARCH_BY_FIELD_TRUCKING_FEE,
      payload: httpClient.get(url)
    });
  };

export const actionSearchAbnormalByFieldTruckingFeeTravelFee = (startDate, endDate, pageNo, pageSize) => (dispatch) => {
  let url = `/TruckingFee/SearchAbnormalByField/travelfee?Page=${pageNo}&PageSize=${pageSize}`;
  if (startDate) url += `&startDate=${startDate}`;
  if (endDate) url += `&endDate=${endDate}`;
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.SEARCH_BY_FIELD_TRUCKING_FEE,
    payload: httpClient.get(url)
  });
};

export const actionSearchByFieldTruckingFee = (params, cancelToken) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.SEARCH_BY_FIELD_TRUCKING_FEE,
    payload: httpClient.get(`/TruckingFee/SearchByField`, {
      params: params,
      cancelToken: cancelToken ? cancelToken.token : null
    })
  });
};

export const actionSearchByFieldTruckingFeeAll = (params) => (dispatch) => {
  return dispatch({
    // type: TRUCKING_FEE_ACTIONS.SEARCH_BY_FIELD_TRUCKING_FEE,
    payload: httpClient.get(`/TruckingFee/SearchByField`, { params: params })
  });
};

export const actionSearchByFieldTruckingFeeNew = (params, cancelToken) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.SEARCH_BY_FIELD_TRUCKING_FEE,
    payload: httpClient.get(`TruckingFee/TruckingFee/SearchByField`, {
      params: params,
      cancelToken: cancelToken ? cancelToken.token : null
    })
  });
};

export const actionSearchByFieldTruckingFeeAllNew = (params) => (dispatch) => {
  return dispatch({
    // type: TRUCKING_FEE_ACTIONS.SEARCH_BY_FIELD_TRUCKING_FEE,
    payload: httpClient.get(`TruckingFee/TruckingFee/SearchByField`, { params: params })
  });
};

export const actionSearchByFieldTravelFee = (params, cancelToken) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.SEARCH_BY_FIELD_TRUCKING_FEE,
    payload: httpClient.get(`TruckingFee/TravelFee/SearchByField`, {
      params: params,
      cancelToken: cancelToken ? cancelToken.token : null
    })
  });
};

export const actionSearchByFieldTravelFeeAll = (params) => (dispatch) => {
  return dispatch({
    // type: TRUCKING_FEE_ACTIONS.SEARCH_BY_FIELD_TRUCKING_FEE,
    payload: httpClient.get(`TruckingFee/TravelFee/SearchByField`, { params: params })
  });
};
export const actionUpdateStandard = (body) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.UPDATE_STANDARD,
    payload: httpClient.get(`/StandardBackend/updateStandard`, { params: body })
  });
};

export const actionGetAllTruckingFee = (params) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.SEARCH_BY_FIELD_TRUCKING_FEE,
    payload: httpClient.get('/TruckingFee/getall', { params })
  });
};

export const actionSearchByFieldTruckingFeeSortOldToNew = (params) => (dispatch) => {
  let url = `/TruckingFeeReport/SearchByField`;
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.SEARCH_BY_FIELD_TRUCKING_FEE,
    payload: httpClient.get(url, { params })
  });
};
export const actionSearchByFieldTruckingFeeSortOldToNewAll = (params) => (dispatch) => {
  let url = `/TruckingFeeReport/SearchByField`;
  return dispatch({
    payload: httpClient.get(url, { params })
  });
};

export const updateDataTable = (data) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.UPDATE_DATA_TABLE,
    payload: data
  });
};

export const actionTruckingFeeUpdatePayment = (payload) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.UPDATE_PAYMENT_TRUCKING_FEE,
    payload: httpClient.put('/TruckingFee/updatepayment', payload)
  });
};

export const actionUpdateEditableFee = (id, value) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.UPDATE_EDITABLE_TRUCKING_FEE,
    payload: httpClient.put(`/TruckingFee/updateEditableTruckingFee/${id}/${value}`)
  });
};

export const actionUpdateEditableTravelFee = (id, value) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.UPDATE_EDITABLE_TRUCKING_FEE,
    payload: httpClient.put(`/TruckingFee/updateEditableTravelFee/${id}/${value}`)
  });
};

export const actionUpdateContWeight = (payload) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.UPDATE_CONT_WEIGHT,
    payload: httpClient.put('/TruckingFee/updateContWeight', payload)
  });
};

export const actionSearchByFieldTruckingFeeV2 = (params, startDate, endDate, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.SEARCH_BY_FIELD_TRUCKING_FEE,
    payload: httpClient.get(
      `/TruckingFee/SearchByField?Page=${pageNo}&PageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`,
      { params }
    )
  });
};

export const actionSearchByFieldTruckingFeeV2All = (params) => (dispatch) => {
  return dispatch({
    payload: httpClient.get(`/TruckingFee/SearchByField`, { params })
  });
};

export const getMoocReportAc = (params) => (dispatch) => {
  return dispatch({
    payload: httpClient.get(`/TruckingFee/MoocReport`, { params })
  });
};

export const actionUpdateTransportFeeAndExternalTransportFeeWithPhaseActive = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.post(`/StandardBackend/updateTransportFeeAndExternalTransportFeeWithPhaseActive`, payload)
  });
};

export const updateStandardOil = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.post(`/StandardBackend/updateStandardOil`, payload)
  });
};

export const updateTravelFee = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.post(`/StandardBackend/updateTravelFee`, payload)
  });
};

export const updateFullStandard = (payload) => (dispatch) => {
  return dispatch({
    type: TRUCKING_FEE_ACTIONS.UPDATE_FULL_STANDARD,
    payload: httpClient.post(`/StandardBackend/updateFullStandard`, payload)
  });
};

export const updateLocksListEditTableTruckingFee = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.put(`TruckingFee/updateListEditTableTruckingFee/0`, payload)
  });
};
export const updateUnLocksListEditTableTruckingFee = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.put(`TruckingFee/updateListEditTableTruckingFee/1`, payload)
  });
};
export const updateLocksListEditTableTravelFee = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.put(`TruckingFee/updateListEditTableTravelFee/0`, payload)
  });
};

export const updateUnLocksListEditTableTravelFee = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.put(`TruckingFee/updateListEditTableTravelFee/1`, payload)
  });
};
