import httpClient from "HttpClient";

export const FETCH_SIDEBAR_NAV = "FETCH_SIDEBAR_NAV";
export const FETCH_SIDEBAR_NAV_PENDING = "FETCH_SIDEBAR_NAV_PENDING";
export const FETCH_SIDEBAR_NAV_FULFILLED = "FETCH_SIDEBAR_NAV_FULFILLED";
export const FETCH_SIDEBAR_NAV_REJECTED = "FETCH_SIDEBAR_NAV_REJECTED";

export const fetchSideBarNav = () => dispatch => {
    return dispatch({
        type: FETCH_SIDEBAR_NAV,
        payload: httpClient.get(`/LeftMenu/getLeftMenu`)
    });
};


export const RESET_SIDEBAR_NAV = "RESET_SIDEBAR_NAV";
export const resetSideBarNav =  () => dispatch => {
    return dispatch({
        type: RESET_SIDEBAR_NAV
    });
};