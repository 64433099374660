import { OIL_NORM_ACTIONS } from 'actions/oilNorm';

const dataMessageExist = ['Dữ liệu đã tồn tại'];

const INIT_STATE = {
  listPerPage: [],
  listCar: [],
  listGoodType: [],
  listRouter: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  pagination: {},
  isCloseModal: false,
  isSearch: false,
  fetchPagingloading: false,
  getCarLoading: false,
  getGoodTypeLoading: false,
  getRouteLoading: false,
  truckingTypes: [],
  infoData: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OIL_NORM_ACTIONS.GET_OIL_NORM_PER_PAGE_PENDING: {
      return {
        ...state,
        fetchPagingloading: true,
        isShowMessage: false
      };
    }
    case OIL_NORM_ACTIONS.GET_OIL_NORM_PER_PAGE_FULFILLED: {
      return {
        ...state,
        fetchPagingloading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false
      };
    }
    case OIL_NORM_ACTIONS.GET_OIL_NORM_PER_PAGE_REJECTED: {
      return {
        ...state,
        fetchPagingloading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false
      };
    }

    case OIL_NORM_ACTIONS.CREATE_OIL_NORM_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case OIL_NORM_ACTIONS.CREATE_OIL_NORM_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isShowMessage: true,
        isCloseModal: true
      };
    }
    case OIL_NORM_ACTIONS.CREATE_OIL_NORM_REJECTED: {
      const messageError = action.payload.response.data.message + '';
      let check = dataMessageExist.includes(messageError);
      return {
        ...state,
        loading: false,
        message: check ? messageError : 'Thêm dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false
      };
    }

    case OIL_NORM_ACTIONS.DELETE_OIL_NORM_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case OIL_NORM_ACTIONS.DELETE_OIL_NORM_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Xóa dữ liệu thành công',
        isShowMessage: true
      };
    }
    case OIL_NORM_ACTIONS.DELETE_OIL_NORM_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Xóa dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error'
      };
    }

    case OIL_NORM_ACTIONS.UPDATE_OIL_NORM_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case OIL_NORM_ACTIONS.UPDATE_OIL_NORM_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công',
        isShowMessage: true,
        isCloseModal: true
      };
    }
    case OIL_NORM_ACTIONS.UPDATE_OIL_NORM_REJECTED: {
      const messageError = action.payload.response.data.message + '';
      let check = dataMessageExist.includes(messageError);
      return {
        ...state,
        loading: false,
        message: check ? messageError : 'Cập nhật dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false
      };
    }

    case OIL_NORM_ACTIONS.GET_CARS_PENDING: {
      return {
        ...state,
        getCarLoading: true
      };
    }

    case OIL_NORM_ACTIONS.GET_CARS_FULFILLED: {
      return {
        ...state,
        getCarLoading: false,
        listCar: action.payload.data.data
      };
    }

    case OIL_NORM_ACTIONS.GET_CARS_REJECTED: {
      return {
        ...state,
        getCarLoading: false,
        message: 'Đã có lỗi xảy ra',
        isShowMessage: true,
        typeMessage: 'error'
      };
    }

    case OIL_NORM_ACTIONS.GET_GOOD_TYPE_PENDING: {
      return {
        ...state,
        getGoodTypeLoading: true
      };
    }

    case OIL_NORM_ACTIONS.GET_GOOD_TYPE_FULFILLED: {
      return {
        ...state,
        getGoodTypeLoading: false,
        listGoodType: action.payload.data.data
      };
    }

    case OIL_NORM_ACTIONS.GET_GOOD_TYPE_REJECTED: {
      return {
        ...state,
        getGoodTypeLoading: false,
        message: 'Đã có lỗi xảy ra',
        isShowMessage: true,
        typeMessage: 'error'
      };
    }
    case OIL_NORM_ACTIONS.GET_ROUTES_PENDING: {
      return {
        ...state,
        getRouteLoading: true
      };
    }
    case OIL_NORM_ACTIONS.GET_ROUTES_FULFILLED: {
      return {
        ...state,
        getRouteLoading: false,
        listRoute: action.payload.data.data
      };
    }

    case OIL_NORM_ACTIONS.GET_ROUTES_REJECTED: {
      return {
        ...state,
        getRouteLoading: false,
        message: 'Đã có lỗi xảy ra',
        isShowMessage: true,
        typeMessage: 'error'
      };
    }

    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }

    case OIL_NORM_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case OIL_NORM_ACTIONS.SEARCH_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination
      };
    }
    case OIL_NORM_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: false,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case OIL_NORM_ACTIONS.GET_TRUCKING_TYPE_ALL_PENDING: {
      return {
        ...state,
        getCarLoading: true
      };
    }

    case OIL_NORM_ACTIONS.GET_TRUCKING_TYPE_ALL_FULFILLED: {
      return {
        ...state,
        getCarLoading: false,
        truckingTypes: action.payload.data.data
      };
    }

    case OIL_NORM_ACTIONS.GET_TRUCKING_TYPE_ALL_REJECTED: {
      return {
        ...state,
        getCarLoading: false,
        message: 'Đã có lỗi xảy ra',
        isShowMessage: true,
        typeMessage: 'error'
      };
    }

    case OIL_NORM_ACTIONS.ACTION_GET_SETTING_INFO_PENDING: {
      return {
        ...state,
        getCarLoading: true
      };
    }

    case OIL_NORM_ACTIONS.ACTION_GET_SETTING_INFO_FULFILLED: {
      return {
        ...state,
        getCarLoading: false,
        infoData: action.payload.data.data
      };
    }

    case OIL_NORM_ACTIONS.ACTION_GET_SETTING_INFO_REJECTED: {
      return {
        ...state,
        getCarLoading: false,
        message: 'Đã có lỗi xảy ra',
        isShowMessage: true,
        typeMessage: 'error'
      };
    }

    default:
      return state;
  }
};
