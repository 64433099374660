import { REPAIR_FEE_NORM } from 'actions/repair/repairFeeNorm';
const INIT_STATE = {
  fees: [],
  pagination: {},
  isLoading: false,
  msg: ''
};
const RepairFeeNorm = (state = INIT_STATE, action) => {
  switch (action.type) {
    case REPAIR_FEE_NORM.GET_DATA_LIST_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case REPAIR_FEE_NORM.GET_DATA_LIST_PER_PAGE_FULFILLED:
      return {
        ...state,
        fees: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isLoading: false
      };

    case REPAIR_FEE_NORM.GET_DATA_LIST_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        msg: 'Đã xảy ra lỗi'
      };

    default:
      return state;
  }
};

export default RepairFeeNorm;
