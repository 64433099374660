import { OIL_CATEGORY_ACTIONS } from 'actions/oilcategory';

const oilCategoryReducer = (
  state = {
    oilCustomer: [],
    oilCatefgory: {
      data: []
    }
  },
  action
) => {
  switch (action.type) {
    // get all oil category
    case OIL_CATEGORY_ACTIONS.GET_ALL_OIL_CATEGORY_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case OIL_CATEGORY_ACTIONS.GET_ALL_OIL_CATEGORY_FULFILLED:
      let oilCatefgory = {
        data: action.payload.data.data
      };
      return {
        ...state,
        oilCatefgory: oilCatefgory,
        isLoading: false
      };

    case OIL_CATEGORY_ACTIONS.GET_ALL_OIL_CATEGORY_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    case OIL_CATEGORY_ACTIONS.GET_ALL_OIL_CUSTOMERS_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case OIL_CATEGORY_ACTIONS.GET_ALL_OIL_CUSTOMERS_FULFILLED:
      let oilCustomer = {
        data: action.payload.data.data
      };
      return {
        ...state,
        oilCustomer: oilCustomer,
        isLoading: false
      };

    case OIL_CATEGORY_ACTIONS.GET_ALL_OIL_CUSTOMERS_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    case OIL_CATEGORY_ACTIONS.GET_LASTEST_OIL_PRICE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case OIL_CATEGORY_ACTIONS.GET_LASTEST_OIL_PRICE_FULFILLED:
      let lastestPrice = action.payload.data.data;
      return {
        ...state,
        lastestPrice: lastestPrice,
        isLoading: false
      };

    case OIL_CATEGORY_ACTIONS.GET_LASTEST_OIL_PRICE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    default:
      return state;
  }
};

export default oilCategoryReducer;
