import { NORM_SETTING_ACTIONS } from 'actions/norm/NormSetting';
import {
  BACKUP_COLUMN,
  CHANGE_SETUP_FIELD_VALUE_FORMULAR_FULFILLED,
  // CHANGE_SETUP_FIELD_VALUE_FORMULAR,
  CHANGE_SETUP_FIELD_VALUE_FORMULAR_PENDING,
  FETCH_SETUP_COLUMN_FORMULAR_FULFILLED,
  // FETCH_SETUP_COLUMN_FORMULAR,
  FETCH_SETUP_COLUMN_FORMULAR_PENDING,
  FETCH_SETUP_COLUMN_FORMULAR_REJECTED,
  MOVE_DOWN_COLUMN_FULFILLED,
  MOVE_DOWN_COLUMN_PENDING,
  MOVE_UP_COLUMN_FULFILLED,
  MOVE_UP_COLUMN_PENDING,
  UPDATE_SETUP_DIALOG_FORMULAR_FULFILLED,
  UPDATE_SETUP_DIALOG_FORMULAR_PENDING,
  UPDATE_SETUP_DIALOG_FORMULAR_REJECTED} from 'actions/setupDialogFormularAction';
import { extractDisplayColumnInfo } from 'commons/columnTreeUtils';
import { flatColumns, getDisplayColumns,moveDownColumn, moveUpColumn } from 'commons/setupDialogUtils';
import {
  ALL_FORMULAS_OPTIONS,
  MSG_EDIT_FAILURE,
  MSG_EDIT_SUCCESS,
  MSG_GET_ALL_FAILURE,
  MSG_TYPE_ERROR,
  MSG_TYPE_SUCCESS} from 'const/common';
// import {
//     // flatTreePermission,
//     findPermission,
//     changeFieldPermission,
//     changeRowPermission
// } from "../commons/groupPermissionUtils";

function findAllChangedNode(flattedColumns, columnId, prop, value) {
  let changedColumns = flattedColumns[`${columnId}`];
  if (prop === 'DisplayStatus') {
    changedColumns = flatColumns([changedColumns], true);
    const parentChanged = {};
    findAllParentChangeNode(parentChanged, flattedColumns, columnId, value);
    changedColumns = {
      ...changedColumns,
      ...parentChanged
    };
  } else {
    changedColumns = {
      [`${columnId}`]: flattedColumns[`${columnId}`]
    };
  }

  return changedColumns;
}

function findAllParentChangeNode(affectedParentColumn, flattedColumns, columnId, value) {
  const ParentColumn = flattedColumns[`${columnId}`].ParentColumn;
  if (!ParentColumn || 0 === ParentColumn) {
    return;
  }
  const parent = flattedColumns[`${ParentColumn}`];
  const siblings = parent.SysSettingGrids;
  if (siblings && siblings.length > 0) {
    const parentDisplay = siblings.reduce((acc, sibling, index) => {
      const siblingId = sibling.ColumnName;
      if (siblingId === columnId) {
        return acc || value;
      } else {
        return acc || flattedColumns[`${siblingId}`].DisplayStatus;
      }
    }, false);
    if ((!parentDisplay && parent.DisplayStatus) || (parentDisplay && !parent.DisplayStatus)) {
      affectedParentColumn[`${ParentColumn}`] = parent;
      findAllParentChangeNode(affectedParentColumn, flattedColumns, ParentColumn, value);
    }
  }
}

function updateFlattedColumn(flattedColumns, changedColumns, action) {
  for (let prop in changedColumns) {
    changedColumns[prop] = {
      ...flattedColumns[`${prop}`],
      Dirty: true,
      [action.payload.prop]: action.payload.value
    };
  }
  let returnFlattedColumns = {
    ...flattedColumns,
    ...changedColumns
  };
  return returnFlattedColumns;
}

function checkActiveFieldFomulas(columns) {
  if (columns.length === 0) return '';
  let dataColumnsFomulasDisplay = columns.filter((el) => {
    return el.RequirementValue === true;
  });
  let mapFieldName = dataColumnsFomulasDisplay.map((el) => {
    return el.ColumnName;
  });
  return mapFieldName.join('-');
}

const INIT_STATE = {
  columns: [],
  displayColumns: [],
  displayColumnInfos: extractDisplayColumnInfo([]),
  flattedColumns: {},
  pristine: true,
  sorted: false,
  submitting: false,
  showMessage: false,
  alertMessage: '',
  messageType: '',
  allFormulas: ALL_FORMULAS_OPTIONS,
  settingFields: {
    OilNorm: {
      columns: [],
      originalColumns: [],
      displayColumns: [],
      displayColumnInfos: extractDisplayColumnInfo([]),
      flattedColumns: {}
    },
    RouteCost: {
      columns: [],
      originalColumns: [],
      displayColumns: [],
      displayColumnInfos: extractDisplayColumnInfo([]),
      flattedColumns: {}
    },
    TransportCost: {
      columns: [],
      originalColumns: [],
      displayColumns: [],
      displayColumnInfos: extractDisplayColumnInfo([]),
      flattedColumns: {}
    }
  },
  reload: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_SETUP_COLUMN_FORMULAR_PENDING: {
      return {
        ...state,
        loading: true,
        fetchColumnsLoading: true,
        reload: false
      };
    }

    case FETCH_SETUP_COLUMN_FORMULAR_FULFILLED: {
      const columns = action.payload.data.data;
      const originalColumns = JSON.parse(JSON.stringify(columns));
      const displayColumns = getDisplayColumns(originalColumns);
      const displayColumnInfos = extractDisplayColumnInfo(displayColumns);
      let flattedColumns = state.pristine ? flatColumns(columns, true) : state.flattedColumns;
      let stringFieldFormulasActived = checkActiveFieldFomulas(columns);
      localStorage.setItem('flattedColumnsFormulas', stringFieldFormulasActived);
      const screenName = action.meta.screenName;

      return {
        ...state,
        loading: false,
        fetchColumnsLoading: false,
        columns: columns,
        originalColumns: originalColumns,
        displayColumns: displayColumns,
        displayColumnInfos: displayColumnInfos,
        flattedColumns: flattedColumns,
        settingFields: {
          ...state.settingFields,
          [screenName]: {
            columns: columns,
            originalColumns: originalColumns,
            displayColumns: displayColumns,
            displayColumnInfos: displayColumnInfos,
            flattedColumns: flattedColumns
          }
        },
        reload: false
      };
    }
    case FETCH_SETUP_COLUMN_FORMULAR_REJECTED: {
      return {
        ...state,
        loading: false,
        fetchColumnsLoading: false,
        showMessage: true,
        alertMessage: MSG_GET_ALL_FAILURE,
        messageType: MSG_TYPE_ERROR,
        flattedColumns: flatColumns(state.columns, true),
        reload: false
      };
    }

    case NORM_SETTING_ACTIONS.DELETE_DATA_FOMULAS_PENDING: {
      return {
        ...state,
        loading: true,
        fetchColumnsLoading: false,
        showMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case NORM_SETTING_ACTIONS.DELETE_DATA_FOMULAS_FULFILLED: {
      return {
        ...state,
        loading: false,
        fetchColumnsLoading: false,
        showMessage: true,
        messageType: 'success',
        alertMessage: 'Xóa dữ liệu thành công !',
        reload: true
      };
    }
    case NORM_SETTING_ACTIONS.DELETE_DATA_FOMULAS_REJECTED: {
      return {
        ...state,
        loading: false,
        fetchColumnsLoading: false,
        showMessage: true,
        messageType: MSG_TYPE_ERROR,
        alertMessage:
          action.payload.response.data.status === 400
            ? action.payload.response.data.message + ''
            : 'Xóa dữ liệu thất bại !',
        isCloseModal: false,
        reload: false
      };
    }

    case CHANGE_SETUP_FIELD_VALUE_FORMULAR_PENDING: {
      return {
        ...state,
        loading: true,
        reload: false
      };
    }
    case CHANGE_SETUP_FIELD_VALUE_FORMULAR_FULFILLED: {
      const columnId = action.payload.column.ColumnName;
      let changedColumns = findAllChangedNode(
        state.flattedColumns,
        columnId,
        action.payload.prop,
        action.payload.value
      );

      let flattedColumns = updateFlattedColumn(state.flattedColumns, changedColumns, action);
      return {
        ...state,
        flattedColumns: flattedColumns,
        pristine: false,
        loading: false,
        reload: false
      };
    }

    case UPDATE_SETUP_DIALOG_FORMULAR_PENDING: {
      return {
        ...state,
        loading: true,
        saving: true,
        submitting: true,
        reload: false
      };
    }

    case UPDATE_SETUP_DIALOG_FORMULAR_FULFILLED: {
      return {
        ...state,
        loading: false,
        saving: false,
        submitting: false,
        pristine: true,
        showMessage: true,
        alertMessage: MSG_EDIT_SUCCESS,
        messageType: MSG_TYPE_SUCCESS,
        reload: false
      };
    }
    case UPDATE_SETUP_DIALOG_FORMULAR_REJECTED: {
      return {
        ...state,
        loading: false,
        saving: false,
        submitting: false,
        showMessage: true,
        alertMessage: MSG_EDIT_FAILURE,
        messageType: MSG_TYPE_ERROR,
        reload: false
      };
    }

    case MOVE_UP_COLUMN_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case MOVE_UP_COLUMN_FULFILLED: {
      const changedColumns = moveUpColumn(state.columns, action.payload);
      return {
        ...state,
        columns: changedColumns,
        loading: false,
        pristine: false,
        sorted: true
      };
    }

    case MOVE_DOWN_COLUMN_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case MOVE_DOWN_COLUMN_FULFILLED: {
      const changedColumns = moveDownColumn(state.columns, action.payload);
      return {
        ...state,
        columns: changedColumns,
        loading: false,
        pristine: false,
        sorted: true
      };
    }

    case BACKUP_COLUMN: {
      const columns = state?.originalColumns || [];
      const originalColumns = JSON.parse(JSON.stringify(columns));
      const displayColumns = getDisplayColumns(originalColumns);
      const displayColumnInfos = extractDisplayColumnInfo(displayColumns);
      let flattedColumns = flatColumns(columns, true);
      let stringFieldFormulasActived = checkActiveFieldFomulas(columns);
      localStorage.setItem('flattedColumnsFormulas', stringFieldFormulasActived);

      return {
        ...state,
        loading: false,
        fetchColumnsLoading: false,
        columns: columns,
        originalColumns: originalColumns,
        displayColumns: displayColumns,
        displayColumnInfos: displayColumnInfos,
        flattedColumns: flattedColumns,
        reload: false,
        pristine: true
      };
    }

    default:
      return state;
  }
};
