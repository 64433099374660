import api from 'HttpClient';

// const type of function get data per page
export const FETCH_ROUTE_ADDRESSES = 'FETCH_ROUTE_ADDRESSES';
export const FETCH_ROUTE_ADDRESSES_PENDING = 'FETCH_ROUTE_ADDRESSES_PENDING';
export const FETCH_ROUTE_ADDRESSES_FULFILLED = 'FETCH_ROUTE_ADDRESSES_FULFILLED';
export const FETCH_ROUTE_ADDRESSES_REJECTED = 'FETCH_ROUTE_ADDRESSES_REJECTED';
export const fetchRouteAddresses = (page, size) => (dispatch) => {
  return dispatch({
    type: FETCH_ROUTE_ADDRESSES,
    payload: api.get(`/RouteAddress/paging/page?page=${page}&pagesize=${size}`)
    // payload: api.get(`/address`)
  });
};
// const type of function get data per page
export const FETCH_ALL_ROUTE_ADDRESSES = 'FETCH_ALL_ROUTE_ADDRESSES';
export const FETCH_ALL_ROUTE_ADDRESSES_PENDING = 'FETCH_ALL_ROUTE_ADDRESSES_PENDING';
export const FETCH_ALL_ROUTE_ADDRESSES_FULFILLED = 'FETCH_ALL_ROUTE_ADDRESSES_FULFILLED';
export const FETCH_ALL_ROUTE_ADDRESSES_REJECTED = 'FETCH_ALL_ROUTE_ADDRESSES_REJECTED';
export const fetchAllRouteAddresses = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_ROUTE_ADDRESSES,
    payload: api.get(`/RouteAddress/getAll`)
    // payload: api.get(`/address`)
  });
};

// const type of function create data
export const CREATE_ROUTE_ADDRESS = 'CREATE_ROUTE_ADDRESS';
export const CREATE_ROUTE_ADDRESS_PENDING = 'CREATE_ROUTE_ADDRESS_PENDING';
export const CREATE_ROUTE_ADDRESS_FULFILLED = 'CREATE_ROUTE_ADDRESS_FULFILLED';
export const CREATE_ROUTE_ADDRESS_REJECTED = 'CREATE_ROUTE_ADDRESS_REJECTED';
export const createRouteAddress = (address) => (dispatch) => {
  return dispatch({
    type: CREATE_ROUTE_ADDRESS,
    payload: api.post(`/RouteAddress/create`, address)
  });
};

// const type of function update data
export const UPDATE_ROUTE_ADDRESS = 'UPDATE_ROUTE_ADDRESS';
export const UPDATE_ROUTE_ADDRESS_PENDING = 'UPDATE_ROUTE_ADDRESS_PENDING';
export const UPDATE_ROUTE_ADDRESS_FULFILLED = 'UPDATE_ROUTE_ADDRESS_FULFILLED';
export const UPDATE_ROUTE_ADDRESS_REJECTED = 'UPDATE_ROUTE_ADDRESS_REJECTED';
export const updateRouteAddress = (address) => (dispatch) => {
  return dispatch({
    type: UPDATE_ROUTE_ADDRESS,
    payload: api.put(`/RouteAddress/update`, address)
  });
};

// const type of get deatail
export const GET_DETAIL_ROUTE_ADDRESS = 'GET_DETAIL_ROUTE_ADDRESS';
export const GET_DETAIL_ROUTE_ADDRESS_PENDING = 'GET_DETAIL_ROUTE_ADDRESS_PENDING';
export const GET_DETAIL_ROUTE_ADDRESS_FULFILLED = 'GET_DETAIL_ROUTE_ADDRESS_FULFILLED';
export const GET_DETAIL_ROUTE_ADDRESS_REJECTED = 'GET_DETAIL_ROUTE_ADDRESS_REJECTED';
export const getDetailRouteAddress = (id) => (dispatch) => {
  return dispatch({
    type: GET_DETAIL_ROUTE_ADDRESS,
    payload: api.get(`/RouteAddress/getById/${id}`)
  });
};

// const type of delete
export const DELETE_ROUTE_ADDRESS = 'DELETE_ROUTE_ADDRESS';
export const DELETE_ROUTE_ADDRESS_PENDING = 'DELETE_ROUTE_ADDRESS_PENDING';
export const DELETE_ROUTE_ADDRESS_FULFILLED = 'DELETE_ROUTE_ADDRESS_FULFILLED';
export const DELETE_ROUTE_ADDRESS_REJECTED = 'DELETE_ROUTE_ADDRESS_REJECTED';
export const deleteRouteAddress = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_ROUTE_ADDRESS,
    payload: api.delete(`/RouteAddress/delete/${id}`)
  });
};

// const type of delete
export const SEARCH_ROUTE_ADDRESS = 'SEARCH_ROUTE_ADDRESS';
export const SEARCH_ROUTE_ADDRESS_PENDING = 'SEARCH_ROUTE_ADDRESS_PENDING';
export const SEARCH_ROUTE_ADDRESS_FULFILLED = 'SEARCH_ROUTE_ADDRESS_FULFILLED';
export const SEARCH_ROUTE_ADDRESS_REJECTED = 'SEARCH_ROUTE_ADDRESS_REJECTED';
export const searchRouteAddresses = (searchKey, page, pageSize, searchMode) => (dispatch) => {
  return dispatch({
    type: SEARCH_ROUTE_ADDRESS,
    payload: { searchKey, page, pageSize, searchMode }
  });
};
export const searchRouteAddressesAll = (params) => (dispatch) => {
  return dispatch({
    // type: SEARCH_ROUTE_ADDRESS,
    // payload: { params }
    payload: api.get(`/RouteAddress/search`, { params })
  });
};

// const type of delete
export const GET_ROUTE_ADDRESS_BY_PORT_CATEGORY = 'GET_ROUTE_ADDRESS_BY_PORT_CATEGORY';
export const GET_ROUTE_ADDRESS_BY_PORT_CATEGORY_PENDING = 'GET_ROUTE_ADDRESS_BY_PORT_CATEGORY_PENDING';
export const GET_ROUTE_ADDRESS_BY_PORT_CATEGORY_FULFILLED = 'GET_ROUTE_ADDRESS_BY_PORT_CATEGORY_FULFILLED';
export const GET_ROUTE_ADDRESS_BY_PORT_CATEGORY_REJECTED = 'GET_ROUTE_ADDRESS_BY_PORT_CATEGORY_REJECTED';
export const getRouteAddessByPortCategory = (id) => (dispatch) => {
  return dispatch({
    type: GET_ROUTE_ADDRESS_BY_PORT_CATEGORY,
    payload: api.get(`/RouteAddress/getByPortCategory?id=${id}`)
  });
};
