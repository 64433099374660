import httpClient from "HttpClient";
// define const actions
export const REVENUE_BY_CUSTOMERS_ACTIONS = {
  GET_REVENUE_BY_CUSTOMERS_PER_PAGE: "GET_REVENUE_BY_CUSTOMERS_PER_PAGE",
  GET_REVENUE_BY_CUSTOMERS_PER_PAGE_PENDING:
    "GET_REVENUE_BY_CUSTOMERS_PER_PAGE_PENDING",
  GET_REVENUE_BY_CUSTOMERS_PER_PAGE_FULFILLED:
    "GET_REVENUE_BY_CUSTOMERS_PER_PAGE_FULFILLED",
  GET_REVENUE_BY_CUSTOMERS_PER_PAGE_REJECTED:
    "GET_REVENUE_BY_CUSTOMERS_PER_PAGE_REJECTED",

  GET_CUSTOMERS: "GET_CUSTOMERS",
  GET_CUSTOMERS_PENDING: "GET_CUSTOMERS_PENDING",
  GET_CUSTOMERS_FULFILLED: "GET_CUSTOMERS_FULFILLED",
  GET_CUSTOMERS_REJECTED: "GET_CUSTOMERS_REJECTED",

  SEARCH_DATA: "SEARCH_DATA",
  SEARCH_DATA_PENDING: "SEARCH_DATA_PENDING",
  SEARCH_DATA_FULFILLED: "SEARCH_DATA_FULFILLED",
  SEARCH_DATA_REJECTED: "SEARCH_DATA_REJECTED",
};

// define actions
export const getDataPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: REVENUE_BY_CUSTOMERS_ACTIONS.GET_REVENUE_BY_CUSTOMERS_PER_PAGE,
    payload: httpClient.get(
      `/revenuebycustomer?page=${pageNo}&pagesize=${pageSize}`
    ),
  });
};

export const getCustomers = () => (dispatch) => {
  return dispatch({
    type: REVENUE_BY_CUSTOMERS_ACTIONS.GET_CUSTOMERS,
    payload: httpClient.get(`/customerinfo/getAll`),
  });
};

export const updateShowAlert = () => {
  return {
    type: "UPDATE_SHOW_ALERT",
  };
};

export const actionSearchData = (
  customerId,
  startDate,
  endDate,
  pageNo,
  pageSize
) => (dispatch) => {
  return dispatch({
    type: REVENUE_BY_CUSTOMERS_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(
      `/revenuebycustomer?customerId=${customerId}&fromdate=${startDate}&todate=${endDate}&page=${pageNo}&pagesize=${pageSize}`
    ),
  });
};
