import api from 'HttpClient';

export const SET_OPEN_DRIVER_PLAN_UPDATE_MODAL = 'SET_OPEN_DRIVER_PLAN_UPDATE_MODAL';
export const setOpenDriverPlanUpdateModal = (driverPlan) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_DRIVER_PLAN_UPDATE_MODAL,
    payload: driverPlan
  });
};

export const SET_CLOSE_DRIVER_PLAN_UPDATE_MODAL = 'SET_CLOSE_DRIVER_PLAN_UPDATE_MODAL';
export const setCloseDriverPlanUpdateModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_DRIVER_PLAN_UPDATE_MODAL
  });
};

export const SET_OPEN_DRIVER_PLAN_COST_ADDING_MODAL = 'SET_OPEN_DRIVER_PLAN_COST_ADDING_MODAL';
export const setOpenDriverPlanCostAddingModal = (driverPlan) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_DRIVER_PLAN_COST_ADDING_MODAL,
    payload: driverPlan
  });
};

export const SET_CLOSE_DRIVER_PLAN_COST_ADDING_MODAL = 'SET_CLOSE_DRIVER_PLAN_COST_ADDING_MODAL';
export const setCloseDriverPlanCostAddingModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_DRIVER_PLAN_COST_ADDING_MODAL
  });
};

export const SET_OPEN_ADD_CAR_MODAL = 'SET_OPEN_ADD_CAR_MODAL';
export const setOpenAddCarModal = (transportId, fromScreen) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_ADD_CAR_MODAL,
    payload: { transportId, fromScreen }
  });
};

export const SET_CLOSE_ADD_CAR_MODAL = 'SET_CLOSE_ADD_CAR_MODAL';
export const setCloseAddCarModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_ADD_CAR_MODAL
  });
};

export const SET_OPEN_TEMPLATE_MODAL = 'SET_OPEN_TEMPLATE_MODAL';
export const setOpenTemplateModal = (templateData) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_TEMPLATE_MODAL,
    payload: templateData
  });
};

export const SET_CLOSE_TEMPLATE_MODAL = 'SET_CLOSE_TEMPLATE_MODAL';
export const setCloseTemplateModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_TEMPLATE_MODAL
  });
};

export const SEARCH_DRIVER_PLAN_COST_PER_PAGE = 'SEARCH_DRIVER_PLAN_COST_PER_PAGE';
export const SEARCH_DRIVER_PLAN_COST_PER_PAGE_PENDING = 'SEARCH_DRIVER_PLAN_COST_PER_PAGE_PENDING';
export const SEARCH_DRIVER_PLAN_COST_PER_PAGE_FULFILLED = 'SEARCH_DRIVER_PLAN_COST_PER_PAGE_FULFILLED';
export const SEARCH_DRIVER_PLAN_COST_PER_PAGE_REJECTED = 'SEARCH_DRIVER_PLAN_COST_PER_PAGE_REJECTED';

// define actions
export const searchDriverPlanCostPerPage = (payload, pageNo, pageSize) => (dispatch) => {
  const searchUrl = `/TruckingPlan/Search/mode`;
  return dispatch({
    type: SEARCH_DRIVER_PLAN_COST_PER_PAGE,
    payload: api.get(searchUrl, { params: { ...payload, page: pageNo, pageSize } })
  });
};
export const searchDriverPlanCostPerPageAll = (params) => (dispatch) => {
  const searchUrl = `/TruckingPlan/Search/mode`;
  return dispatch({
    // type: SEARCH_DRIVER_PLAN_COST_PER_PAGE,
    payload: api.get(searchUrl, { params })
  });
};

export const CREATE_DRIVER_PLAN_COST = 'CREATE_DRIVER_PLAN_COST';
export const CREATE_DRIVER_PLAN_COST_PENDING = 'CREATE_DRIVER_PLAN_COST_PENDING';
export const CREATE_DRIVER_PLAN_COST_FULFILLED = 'CREATE_DRIVER_PLAN_COST_FULFILLED';
export const CREATE_DRIVER_PLAN_COST_REJECTED = 'CREATE_DRIVER_PLAN_COST_REJECTED';

// define actions
export const createDriverPlanCost = (payload) => (dispatch) => {
  const createUrl = `/TruckingPlan/CreatePlanFull`;
  return dispatch({
    type: CREATE_DRIVER_PLAN_COST,
    payload: api.post(createUrl, payload)
  });
};

export const FETCH_DISPATCHED_DRIVER_PLANS = 'FETCH_DISPATCHED_DRIVER_PLANS';
export const FETCH_DISPATCHED_DRIVER_PLANS_PENDING = 'FETCH_DISPATCHED_DRIVER_PLANS_PENDING';
export const FETCH_DISPATCHED_DRIVER_PLANS_FULFILLED = 'FETCH_DISPATCHED_DRIVER_PLANS_FULFILLED';
export const FETCH_DISPATCHED_DRIVER_PLANS_REJECTED = 'FETCH_DISPATCHED_DRIVER_PLANS_REJECTED';

// define actions
export const fetchDispathedDriverPlans = (payload) => (dispatch) => {
  const searchUrl = `/TruckingPlan/getCombinableTruckingPlan`;
  const searchParams = {
    Date: payload.truckingDate
  };
  return dispatch({
    type: FETCH_DISPATCHED_DRIVER_PLANS,
    payload: api.get(searchUrl, { params: searchParams })
  });
};

// export const CREATE_DRIVER_PLAN_COST = 'CREATE_DRIVER_PLAN_COST';
// export const CREATE_DRIVER_PLAN_COST_PENDING = 'CREATE_DRIVER_PLAN_COST_PENDING';
// export const CREATE_DRIVER_PLAN_COST_FULFILLED = 'CREATE_DRIVER_PLAN_COST_FULFILLED';
// export const CREATE_DRIVER_PLAN_COST_REJECTED = 'CREATE_DRIVER_PLAN_COST_REJECTED';

// define actions
export const createDriverPlanCostNew = (payload) => (dispatch) => {
  const createUrl = `/truckingplan/createv2`;
  return dispatch({
    type: CREATE_DRIVER_PLAN_COST,
    payload: api.post(createUrl, payload)
  });
};

export const searchDriverPlanCostPerPageNew = (payload, pageNo, pageSize) => (dispatch) => {
  const searchUrl = `/truckingplan/Search/datev2`;
  return dispatch({
    type: SEARCH_DRIVER_PLAN_COST_PER_PAGE,
    payload: api.get(searchUrl, { params: { ...payload, page: pageNo, pageSize } })
  });
};
export const searchDriverPlanCostPerPageNewAll = (params) => (dispatch) => {
  const searchUrl = `/truckingplan/Search/datev2`;
  return dispatch({
    // type: SEARCH_DRIVER_PLAN_COST_PER_PAGE,
    payload: api.get(searchUrl, { params })
  });
};

export const UPDATE_DRIVER_PLAN_COST = 'UPDATE_DRIVER_PLAN_COST';
export const UPDATE_DRIVER_PLAN_COST_PENDING = 'UPDATE_DRIVER_PLAN_COST_PENDING';
export const UPDATE_DRIVER_PLAN_COST_FULFILLED = 'UPDATE_DRIVER_PLAN_COST_FULFILLED';
export const UPDATE_DRIVER_PLAN_COST_REJECTED = 'UPDATE_DRIVER_PLAN_COST_REJECTED';

export const updateDriverPlanCostNew = (payload) => (dispatch) => {
  const createUrl = `/truckingplan/updatev2`;
  return dispatch({
    type: UPDATE_DRIVER_PLAN_COST,
    payload: api.put(createUrl, payload)
  });
};

export const SET_OPEN_DRIVER_PLAN_COST_NORMAL_MODAL = 'SET_OPEN_DRIVER_PLAN_COST_NORMAL_MODAL';
export const setOpenDriverPlanCostNormalModal = (driverPlan) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_DRIVER_PLAN_COST_NORMAL_MODAL,
    payload: driverPlan
  });
};

export const SET_CLOSE_DRIVER_PLAN_COST_NORMAL_MODAL = 'SET_CLOSE_DRIVER_PLAN_COST_NORMAL_MODAL';
export const setCloseDriverPlanCostNormalModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_DRIVER_PLAN_COST_NORMAL_MODAL
  });
};
