import { uploadFile } from "services/utils";

// define const actions
export const UTILS_ACTIONS = {
    UPLOAD_FILE: "UPLOAD_FILE",
    UPLOAD_FILE_PENDING: "UPLOAD_FILE_PENDING",
    UPLOAD_FILE_FULFILLED: "UPLOAD_FILE_FULFILLED",
    UPLOAD_FILE_REJECTED: "UPLOAD_FILE_REJECTED"
};

export const UTILS_ACTIONS_CLEAR_UPLOADED_EXCEL_FILE = {
    CLEAR_UPLOADED_EXCEL_FILE: "CLEAR_UPLOADED_EXCEL_FILE"
};

// define actions
export const actionUploadFile = (file) => dispatch => {
    return dispatch({
        type: UTILS_ACTIONS.UPLOAD_FILE,
        payload: uploadFile(file)
    });
};

export const actionClearUploadedExcelFile = () => (dispatch) => {
    return dispatch({
        type: UTILS_ACTIONS_CLEAR_UPLOADED_EXCEL_FILE.CLEAR_UPLOADED_EXCEL_FILE
    });
};