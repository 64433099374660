import {
    SPARE_PARTS_ACTIONS
} from "actions/repair/sparePartsManagement";
import {
    put,
    takeEvery,
} from 'redux-saga/effects';
import {
    getSparePartPerPage
} from "services/repair/sparePartsManagement";

function* putActionUpdateData(actions) {
    const pageSize = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE);
    yield put({
        type: SPARE_PARTS_ACTIONS.GET_SPARE_PARTS_PER_PAGE,
        payload: getSparePartPerPage(actions.meta.currentPage, pageSize)
    })
}

export function* actionUpdateSparePartData() {
    yield takeEvery([
        SPARE_PARTS_ACTIONS.UPDATE_SPARE_PARTS_FULFILLED,
        SPARE_PARTS_ACTIONS.CREATE_SPARE_PARTS_FULFILLED,
        SPARE_PARTS_ACTIONS.DELETE_SPARE_PARTS_FULFILLED
    ], putActionUpdateData);
}


