import api from 'HttpClient';

export const SET_OPEN_WORKING_TYPE_MODAL = 'SET_OPEN_WORKING_TYPE_MODAL';
export const setOpenWorkingTypeModal = (data) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_WORKING_TYPE_MODAL,
    payload: data
  });
};

export const SET_CLOSE_WORKING_TYPE_MODAL = 'SET_CLOSE_WORKING_TYPE_MODAL';
export const setCloseWorkingTypeModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_WORKING_TYPE_MODAL
  });
};

export const FETCH_ALL_WORKING_TYPES = 'FETCH_ALL_WORKING_TYPES';
export const FETCH_ALL_WORKING_TYPES_PENDING = 'FETCH_ALL_WORKING_TYPES_PENDING';
export const FETCH_ALL_WORKING_TYPES_FULFILLED = 'FETCH_ALL_WORKING_TYPES_FULFILLED';
export const FETCH_ALL_WORKING_TYPES_REJECTED = 'FETCH_ALL_WORKING_TYPES_REJECTED';
export const fetchAllWorkingTypes = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_WORKING_TYPES,
    payload: api.get(`/WorkingTypeInfo/getAll`)
  });
};

// const type of function get data per page
export const FETCH_WORKING_TYPES = 'FETCH_WORKING_TYPES';
export const FETCH_WORKING_TYPES_PENDING = 'FETCH_WORKING_TYPES_PENDING';
export const FETCH_WORKING_TYPES_FULFILLED = 'FETCH_WORKING_TYPES_FULFILLED';
export const FETCH_WORKING_TYPES_REJECTED = 'FETCH_WORKING_TYPES_REJECTED';
export const fetchWorkingTypes = (searchPayload, page, pageSize) => (dispatch) => {
  return dispatch({
    type: FETCH_WORKING_TYPES,
    payload: api.get(`/WorkingTypeInfo/Search`, { params: { ...searchPayload, page, pageSize: pageSize } })
  });
};

// const type of function create data
export const CREATE_WORKING_TYPE = 'CREATE_WORKING_TYPE';
export const CREATE_WORKING_TYPE_PENDING = 'CREATE_WORKING_TYPE_PENDING';
export const CREATE_WORKING_TYPE_FULFILLED = 'CREATE_WORKING_TYPE_FULFILLED';
export const CREATE_WORKING_TYPE_REJECTED = 'CREATE_WORKING_TYPE_REJECTED';
export const createWorkingType = (payload) => (dispatch) => {
  return dispatch({
    type: CREATE_WORKING_TYPE,
    payload: api.post(`/WorkingTypeInfo/create`, payload)
  });
};

// const type of function update data
export const UPDATE_WORKING_TYPE = 'UPDATE_WORKING_TYPE';
export const UPDATE_WORKING_TYPE_PENDING = 'UPDATE_WORKING_TYPE_PENDING';
export const UPDATE_WORKING_TYPE_FULFILLED = 'UPDATE_WORKING_TYPE_FULFILLED';
export const UPDATE_WORKING_TYPE_REJECTED = 'UPDATE_WORKING_TYPE_REJECTED';
export const updateWorkingType = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_WORKING_TYPE,
    payload: api.put(`/WorkingTypeInfo/update`, payload),
    meta: payload
  });
};
// const type of get deatail
export const FETCH_WORKING_TYPE_DETAIL = 'FETCH_WORKING_TYPE_DETAIL';
export const FETCH_WORKING_TYPE_DETAIL_PENDING = 'FETCH_WORKING_TYPE_DETAIL_PENDING';
export const FETCH_WORKING_TYPE_DETAIL_FULFILLED = 'FETCH_WORKING_TYPE_DETAIL_FULFILLED';
export const FETCH_WORKING_TYPE_DETAIL_REJECTED = 'FETCH_WORKING_TYPE_DETAIL_REJECTED';
export const getWorkingTypeDetail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_WORKING_TYPE_DETAIL,
    payload: api.get(`/WorkingTypeInfo/${id}`)
  });
};

// const type of delete
export const DELETE_WORKING_TYPE = 'DELETE_WORKING_TYPE';
export const DELETE_WORKING_TYPE_PENDING = 'DELETE_WORKING_TYPE_PENDING';
export const DELETE_WORKING_TYPE_FULFILLED = 'DELETE_WORKING_TYPE_FULFILLED';
export const DELETE_WORKING_TYPE_REJECTED = 'DELETE_WORKING_TYPE_REJECTED';
export const deleteWorkingType = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_WORKING_TYPE,
    payload: api.delete(`/WorkingTypeInfo/delete/${id}`)
  });
};
