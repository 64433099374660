import httpClient from 'HttpClient';
import {
  createImportSparePart,
  createImportSparePartPlan,
  deleteImportSparePart,
  getAllImportSparePart,
  getImportSparePartPerPage,
  searchData,
  searchDataAll,
  updateImportSparePart,
  updateImportSparePartPlan
} from 'services/repair/importSparePartManagement';

// define const actions
export const IMPORT_SPARE_PART_ACTIONS = {
  GET_ALL_DATA_IMPORT: 'GET_ALL_DATA_IMPORT',
  GET_ALL_DATA_IMPORT_PENDING: 'GET_ALL_DATA_IMPORT_PENDING',
  GET_ALL_DATA_IMPORT_FULFILLED: 'GET_ALL_DATA_IMPORT_FULFILLED',
  GET_ALL_DATA_IMPORT_REJECTED: 'GET_ALL_DATA_IMPORT_REJECTED',

  GET_DATA_PER_PAGE: 'GET_DATA_PER_PAGE',
  GET_DATA_PER_PAGE_PENDING: 'GET_DATA_PER_PAGE_PENDING',
  GET_DATA_PER_PAGE_FULFILLED: 'GET_DATA_PER_PAGE_FULFILLED',
  GET_DATA_PER_PAGE_REJECTED: 'GET_DATA_PER_PAGE_REJECTED',

  CREATE_DATA: 'CREATE_DATA',
  CREATE_DATA_PENDING: 'CREATE_DATA_PENDING',
  CREATE_DATA_FULFILLED: 'CREATE_DATA_FULFILLED',
  CREATE_DATA_REJECTED: 'CREATE_DATA_REJECTED',

  UPDATE_DATA: 'UPDATE_DATA',
  UPDATE_DATA_PENDING: 'UPDATE_DATA_PENDING',
  UPDATE_DATA_FULFILLED: 'UPDATE_DATA_FULFILLED',
  UPDATE_DATA_REJECTED: 'UPDATE_DATA_REJECTED',

  DELETE_DATA: 'DELETE_DATA',
  DELETE_DATA_PENDING: 'DELETE_DATA_PENDING',
  DELETE_DATA_FULFILLED: 'DELETE_DATA_FULFILLED',
  DELETE_DATA_REJECTED: 'DELETE_DATA_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  GET_SPAREPART_RECEIVING_BY_ID: 'GET_SPAREPART_RECEIVING_BY_ID',
  GET_SPAREPART_RECEIVING_BY_ID_PENDING: 'GET_SPAREPART_RECEIVING_BY_ID_PENDING',
  GET_SPAREPART_RECEIVING_BY_ID_FULFILLED: 'GET_SPAREPART_RECEIVING_BY_ID_FULFILLED',
  GET_SPAREPART_RECEIVING_BY_ID_REJECTED: 'GET_SPAREPART_RECEIVING_BY_ID_REJECTED',

  GET_APPROVED_STATUS: 'GET_APPROVED_STATUS',
  GET_APPROVED_STATUS_PENDING: 'GET_APPROVED_STATUS_PENDING',
  GET_APPROVED_STATUS_FULFILLED: 'GET_APPROVED_STATUS_FULFILLED',
  GET_APPROVED_STATUS_REJECTED: 'GET_APPROVED_STATUS_REJECTED',

  CREATE_NEW_APPROVED: 'CREATE_NEW_APPROVED',
  CREATE_NEW_APPROVED_PENDING: 'CREATE_NEW_APPROVED_PENDING',
  CREATE_NEW_APPROVED_FULFILLED: 'CREATE_NEW_APPROVED_FULFILLED',
  CREATE_NEW_APPROVED_REJECTED: 'CREATE_NEW_APPROVED_REJECTED',

  UPDATE_APPROVAL: 'UPDATE_APPROVAL',
  UPDATE_APPROVAL_PENDING: 'UPDATE_APPROVAL_PENDING',
  UPDATE_APPROVAL_FULFILLED: 'UPDATE_APPROVAL_FULFILLED',
  UPDATE_APPROVAL_REJECTED: 'UPDATE_APPROVAL_REJECTED',

  DELETE_FILE: 'DELETE_FILE',
  DELETE_FILE_PENDING: 'DELETE_FILE_PENDING',
  DELETE_FILE_FULFILLED: 'DELETE_FILE_FULFILLED',
  DELETE_FILE_REJECTED: 'DELETE_FILE_REJECTED',

  GET_SPAREPART_ORDER_BY_CODE: 'GET_SPAREPART_ORDER_BY_CODE',
  GET_SPAREPART_ORDER_BY_CODE_PENDING: 'GET_SPAREPART_ORDER_BY_CODE_PENDING',
  GET_SPAREPART_ORDER_BY_CODE_FULFILLED: 'GET_SPAREPART_ORDER_BY_CODE_FULFILLED',
  GET_SPAREPART_ORDER_BY_CODE_REJECTED: 'GET_SPAREPART_ORDER_BY_CODE_REJECTED'
};

// define actions:

export const actionGetAllData = () => (dispatch) => {
  return dispatch({
    type: IMPORT_SPARE_PART_ACTIONS.GET_ALL_DATA_IMPORT,
    payload: getAllImportSparePart()
  });
};

export const actionGetDataPerPage = (pageNo, pageSize, IsReceived) => (dispatch) => {
  return dispatch({
    type: IMPORT_SPARE_PART_ACTIONS.GET_DATA_PER_PAGE,
    payload: getImportSparePartPerPage(pageNo, pageSize, IsReceived)
  });
};

export const actionCreateData = (payload, IsReceived, currentPage) => (dispatch) => {
  const payloadAction = IsReceived ? createImportSparePart(payload) : createImportSparePartPlan(payload);
  return dispatch({
    type: IMPORT_SPARE_PART_ACTIONS.CREATE_DATA,
    payload: payloadAction,
    meta: { currentPage, IsReceived }
  });
};

// export const actionCreateDataPlan = (payload, IsReceived, currentPage) => (dispatch) => {
//   return dispatch({
//     type: IMPORT_SPARE_PART_ACTIONS.CREATE_DATA,
//     payload: createImportSparePartPlan(payload),
//     meta: { currentPage, IsReceived }
//   });
// };

export const actionUpdateData = (payload, IsReceived, currentPage) => (dispatch) => {
  const payloadAction = IsReceived ? updateImportSparePart(payload) : updateImportSparePartPlan(payload);
  return dispatch({
    type: IMPORT_SPARE_PART_ACTIONS.UPDATE_DATA,
    payload: payloadAction,
    meta: { currentPage, IsReceived }
  });
};
export const actionDeleteData = (id, currentPage, IsReceived) => (dispatch) => {
  return dispatch({
    type: IMPORT_SPARE_PART_ACTIONS.DELETE_DATA,
    payload: deleteImportSparePart(id),
    meta: { currentPage: currentPage, IsReceived: IsReceived }
  });
};

export const actionSearchData = (params, pageNo, pageSize, IsReceived, mode = 1) => (dispatch) => {
  return dispatch({
    type: IMPORT_SPARE_PART_ACTIONS.SEARCH_DATA,
    payload: searchData(params, pageNo, pageSize, IsReceived, mode)
  });
};

export const actionSearchDataAll = (params, mode = 1) => (dispatch) => {
  return dispatch({
    payload: searchDataAll(params, mode)
  });
};

export const getSparepartReceivingById = (id) => (dispatch) => {
  return dispatch({
    type: IMPORT_SPARE_PART_ACTIONS.GET_SPAREPART_RECEIVING_BY_ID,
    payload: httpClient.get(`/sparepartreceiving/getById/${id}`)
  });
};

export const getApprovedStatus = () => (dispatch) => {
  return dispatch({
    type: IMPORT_SPARE_PART_ACTIONS.GET_APPROVED_STATUS,
    payload: httpClient.get(`/Approved/getApprovedStatus?statusGroup=1`)
  });
};

export const createNewApproved = (payload) => (dispatch) => {
  return dispatch({
    type: IMPORT_SPARE_PART_ACTIONS.CREATE_NEW_APPROVED,
    payload: httpClient.post(`/Approved/create`, payload),
    meta: { currentPage: payload.currentPage, IsReceived: false }
  });
};

export const updateAprrovalTransferApproval = (payload) => (dispatch) => {
  return dispatch({
    type: IMPORT_SPARE_PART_ACTIONS.UPDATE_APPROVAL,
    payload: httpClient.put(`/Approved/update`, payload),
    meta: { currentPage: payload.currentPage, IsReceived: false }
  });
};

export const updateAfterAprover = (payload) => (dispatch) => {
  return dispatch({
    type: IMPORT_SPARE_PART_ACTIONS.UPDATE_APPROVAL,
    payload: httpClient.put(`/sparepartreceiving/updateAfterAprover `, payload),
    meta: { currentPage: payload.currentPage, IsReceived: false }
  });
};

export const actionDeleteFile = (id, fileId) => (dispatch) => {
  return dispatch({
    type: IMPORT_SPARE_PART_ACTIONS.DELETE_FILE,
    payload: httpClient.delete(`/sparepartreceiving/deleteImageFile?id=${id}&fileId=${fileId}`)
  });
};

export const getSparePartOrderByCode = (code) => (dispatch) => {
  return dispatch({
    type: IMPORT_SPARE_PART_ACTIONS.GET_SPAREPART_ORDER_BY_CODE,
    payload: httpClient.get(`/SparePartOrder/getBy?code=${code}`)
  });
};
