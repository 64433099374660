import { DOWNPAYMENT_SELL_DETAIL_ACTIONS } from "actions/downPaymentSellDetail";

const INIT_STATE = {
  listData: [],
  loading: false,
  typeMessage: "",
  message: "",
  isShowMessage: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DOWNPAYMENT_SELL_DETAIL_ACTIONS.GET_DOWNPAYMENT_SELL_DETAIL_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case DOWNPAYMENT_SELL_DETAIL_ACTIONS.GET_DOWNPAYMENT_SELL_DETAIL_FULFILLED: {
      return {
        ...state,
        loading: false,
        listData: action.payload.data.data,
      };
    }
    case DOWNPAYMENT_SELL_DETAIL_ACTIONS.GET_DOWNPAYMENT_SELL_DETAIL_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }
    case "UPDATE_SHOW_ALERT": {
      return {
        ...state,
        isShowMessage: false,
      };
    }

    default:
      return state;
  }
};
