import api from "HttpClient";

// const type of function get data per page
export const FETCH_REVENUE_COST_BY_CAR = "FETCH_REVENUE_COST_BY_CAR";
export const FETCH_REVENUE_COST_BY_CAR_PENDING = "FETCH_REVENUE_COST_BY_CAR_PENDING";
export const FETCH_REVENUE_COST_BY_CAR_FULFILLED = "FETCH_REVENUE_COST_BY_CAR_FULFILLED";
export const FETCH_REVENUE_COST_BY_CAR_REJECTED = "FETCH_REVENUE_COST_BY_CAR_REJECTED";
export const fetchRevenueCostByCars = (page, size) => dispatch => {
    return dispatch({
        type: FETCH_REVENUE_COST_BY_CAR,
        payload: api.get(`/User/paging/page?page=${page}&pagesize=${size}`)
        // payload: api.get(`/user`)
    });
};