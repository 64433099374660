import httpClient from "HttpClient";
export const getTransportCostPerPage = (pageNo, pageSize) => {
  return httpClient.get(
    `/RealTransportFeeInfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`
  );
};

export const deleteItem = (id) => {
  return httpClient.delete(`/RealTransportFeeInfo/delete/${id}`);
};
