import { FEE_CATEGORIES_ACTIONS } from 'actions/feeCategoriesActions';

const INIT_STATE = {
  allData: {
    data: []
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // get all vehicles
    case FEE_CATEGORIES_ACTIONS.GET_ALL_FEES_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case FEE_CATEGORIES_ACTIONS.GET_ALL_FEES_FULFILLED:
      let listAll = {
        data: action.payload.data.data
      };
      return {
        ...state,
        allData: listAll
      };

    case FEE_CATEGORIES_ACTIONS.GET_ALL_FEES_REJECTED:
      return {
        ...state
      };

    case FEE_CATEGORIES_ACTIONS.GET_FEES_BY_TYPE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case FEE_CATEGORIES_ACTIONS.GET_FEES_BY_TYPE_FULFILLED:
      let listByType = {
        data: action.payload.data.data
      };
      return {
        ...state,
        allData: listByType
      };

    case FEE_CATEGORIES_ACTIONS.GET_FEES_BY_TYPE_REJECTED:
      return {
        ...state
      };

    default:
      return state;
  }
};
