import {
    ROAD_FEE_ACTIONS
} from "actions/roadFeeManagement";
import {
    put,
    takeEvery,
} from 'redux-saga/effects';
import {
    getRoadFeePerPage
} from "services/roadFeeManagement";

function* putActionUpdateData() {
    const pageSize = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE);
    yield put({
        type: ROAD_FEE_ACTIONS.GET_ROAD_FEE_PER_PAGE,
        payload: getRoadFeePerPage(1, pageSize)
    })
}

export function* actionUpdateRoadFeeData() {
    yield takeEvery([
        ROAD_FEE_ACTIONS.UPDATE_ROAD_FEE_FULFILLED,
        ROAD_FEE_ACTIONS.CREATE_ROAD_FEE_FULFILLED,
    ], putActionUpdateData);
}
