import { isNullOrEmpty } from './utils';

export function getValue(value) {
  if (isNullOrEmpty(value)) return null;
  let result = value;
  if (typeof value === 'object') {
    result = value.value;
  }
  return result;
}

export function getObject(selectedValue, values) {
  if (isNullOrEmpty(selectedValue)) return null;
  let result = selectedValue;
  if (typeof result !== 'object') {
    result = values?.find((item) => item.value === selectedValue) ?? null;
  }
  return result;
}

export function getLabel(item, items) {
  if (isNullOrEmpty(item)) return null;
  let result = null;
  const foundItem = getObject(item, items);
  if (foundItem) {
    result = foundItem.label;
  }
  return result;
}

export function normalizeToOptions(items, labelField) {
  if (isNullOrEmpty(items)) return [];
  const result = items.map((item) => normalizeToOption(item, labelField));
  return result;
}

export function normalizeToOption(item, labelField) {
  if (isNullOrEmpty(item)) return undefined;
  let label = null;
  if (typeof labelField === 'function') {
    label = labelField(item);
  } else {
    label = item[labelField];
  }
  const value = { ...item, value: item.Id, label };
  return value;
}
