import httpClient from 'HttpClient';
// define const actions
export const SEA_NORM_MANAGEMENT_ACTIONS = {
  GET_SEA_NORM_MANAGEMENT_PER_PAGE: 'GET_SEA_NORM_MANAGEMENT_PER_PAGE',
  GET_SEA_NORM_MANAGEMENT_PER_PAGE_PENDING: 'GET_SEA_NORM_MANAGEMENT_PER_PAGE_PENDING',
  GET_SEA_NORM_MANAGEMENT_PER_PAGE_FULFILLED: 'GET_SEA_NORM_MANAGEMENT_PER_PAGE_FULFILLED',
  GET_SEA_NORM_MANAGEMENT_PER_PAGE_REJECTED: 'GET_SEA_NORM_MANAGEMENT_PER_PAGE_REJECTED',

  GET_SEA_NORM_MANAGEMENT_BY_ID: 'GET_SEA_NORM_MANAGEMENT_BY_ID',
  GET_SEA_NORM_MANAGEMENT_BY_ID_PENDING: 'GET_SEA_NORM_MANAGEMENT_BY_ID_PENDING',
  GET_SEA_NORM_MANAGEMENT_BY_ID_FULFILLED: 'GET_SEA_NORM_MANAGEMENT_BY_ID_FULFILLED',
  GET_SEA_NORM_MANAGEMENT_BY_ID_REJECTED: 'GET_SEA_NORM_MANAGEMENT_BY_ID_REJECTED',

  CREATE_SEA_NORM_MANAGEMENT: 'CREATE_SEA_NORM_MANAGEMENT',
  CREATE_SEA_NORM_MANAGEMENT_PENDING: 'CREATE_SEA_NORM_MANAGEMENT_PENDING',
  CREATE_SEA_NORM_MANAGEMENT_FULFILLED: 'CREATE_SEA_NORM_MANAGEMENT_FULFILLED',
  CREATE_SEA_NORM_MANAGEMENT_REJECTED: 'CREATE_SEA_NORM_MANAGEMENT_REJECTED',

  UPDATE_SEA_NORM_MANAGEMENT: 'UPDATE_SEA_NORM_MANAGEMENT',
  UPDATE_SEA_NORM_MANAGEMENT_PENDING: 'UPDATE_SEA_NORM_MANAGEMENT_PENDING',
  UPDATE_SEA_NORM_MANAGEMENT_FULFILLED: 'UPDATE_SEA_NORM_MANAGEMENT_FULFILLED',
  UPDATE_SEA_NORM_MANAGEMENT_REJECTED: 'UPDATE_SEA_NORM_MANAGEMENT_REJECTED',

  DELETE_SEA_NORM_MANAGEMENT: 'DELETE_SEA_NORM_MANAGEMENT',
  DELETE_SEA_NORM_MANAGEMENT_PENDING: 'DELETE_SEA_NORM_MANAGEMENT_PENDING',
  DELETE_SEA_NORM_MANAGEMENT_FULFILLED: 'DELETE_SEA_NORM_MANAGEMENT_FULFILLED',
  DELETE_SEA_NORM_MANAGEMENT_REJECTED: 'DELETE_SEA_NORM_MANAGEMENT_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  GET_CUSTOMERS: 'GET_CUSTOMERS',
  GET_CUSTOMERS_PENDING: 'GET_CUSTOMERS_PENDING',
  GET_CUSTOMERS_FULFILLED: 'GET_CUSTOMERS_FULFILLED',
  GET_CUSTOMERS_REJECTED: 'GET_CUSTOMERS_REJECTED',

  GET_ROUTES: 'GET_ROUTES',
  GET_ROUTES_PENDING: 'GET_ROUTES_PENDING',
  GET_ROUTES_FULFILLED: 'GET_ROUTES_FULFILLED',
  GET_ROUTES_REJECTED: 'GET_ROUTES_REJECTED',

  GET_CONT_CATEGORY: 'GET_CONT_CATEGORY',
  GET_CONT_CATEGORY_PENDING: 'GET_CONT_CATEGORY_PENDING',
  GET_CONT_CATEGORY_FULFILLED: 'GET_CONT_CATEGORY_FULFILLED',
  GET_CONT_CATEGORY_REJECTED: 'GET_CONT_CATEGORY_REJECTED',

  CREATE_SEA_NORM_MANAGEMENT_V2: 'CREATE_SEA_NORM_MANAGEMENT_V2',
  CREATE_SEA_NORM_MANAGEMENT_V2_PENDING: 'CREATE_SEA_NORM_MANAGEMENT_V2_PENDING',
  CREATE_SEA_NORM_MANAGEMENT_V2_FULFILLED: 'CREATE_SEA_NORM_MANAGEMENT_V2_FULFILLED',
  CREATE_SEA_NORM_MANAGEMENT_V2_REJECTED: 'CREATE_SEA_NORM_MANAGEMENT_V2_REJECTED'
};

// define actions
export const actionGetSeaNormManagementPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: SEA_NORM_MANAGEMENT_ACTIONS.GET_SEA_NORM_MANAGEMENT_PER_PAGE,
    payload: httpClient.get(`/OceanFreight/search?Page=${pageNo}&PageSize=${pageSize}`)
  });
};

export const actionGetSeaNormManagementByID = (id) => (dispatch) => {
  return dispatch({
    type: SEA_NORM_MANAGEMENT_ACTIONS.GET_SEA_NORM_MANAGEMENT_BY_ID,
    payload: httpClient.get(`/OceanFreight/getById/${id}`)
  });
};

export const actionCreateSeaNormManagement = (payload) => (dispatch) => {
  return dispatch({
    type: SEA_NORM_MANAGEMENT_ACTIONS.CREATE_SEA_NORM_MANAGEMENT,
    payload: httpClient.post(`/OceanFreight/create`, payload)
  });
};

export const actionUpdateSeaNormManagement = (payload) => (dispatch) => {
  return dispatch({
    type: SEA_NORM_MANAGEMENT_ACTIONS.UPDATE_SEA_NORM_MANAGEMENT,
    payload: httpClient.put(`/OceanFreight/update`, payload)
  });
};

export const actionDeleteSeaNormManagement = (id) => (dispatch) => {
  return dispatch({
    type: SEA_NORM_MANAGEMENT_ACTIONS.DELETE_SEA_NORM_MANAGEMENT,
    payload: httpClient.delete(`/OceanFreight/delete/${id}`)
  });
};

export const actionSearchSeaNormManagement = (params, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: SEA_NORM_MANAGEMENT_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/OceanFreight/search?Page=${pageNo}&PageSize=${pageSize}`, { params: params })
  });
};

export const actionGetRoutes = () => (dispatch) => {
  return dispatch({
    type: SEA_NORM_MANAGEMENT_ACTIONS.GET_ROUTES,
    payload: httpClient.get(`/routeinfo/getAll`)
  });
};

export const actionGetCustomers = () => (dispatch) => {
  return dispatch({
    type: SEA_NORM_MANAGEMENT_ACTIONS.GET_CUSTOMERS,
    payload: httpClient.get(`/customerinfo/getAll`)
  });
};

export const actionGetContCategory = () => (dispatch) => {
  return dispatch({
    type: SEA_NORM_MANAGEMENT_ACTIONS.GET_CONT_CATEGORY,
    payload: httpClient.get(`/Container/getAll`)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

//update import excel
export const actionCreateSeaNormManagementV2 = (payload) => (dispatch) => {
  return dispatch({
    type: SEA_NORM_MANAGEMENT_ACTIONS.CREATE_SEA_NORM_MANAGEMENT_V2,
    payload: httpClient.post(`/OceanFreight/createV2`, payload)
  });
};
