import { OUT_SOURCE_TRANSPORT_COST_ACTIONS } from 'actions/outSourceTransportCost';

const dataMessageExist = ['Dữ liệu đã tồn tại'];

const INIT_STATE = {
  listPerPage: [],
  listContCategory: [],
  listCustomer: [],
  listRouter: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  pagination: {},
  isCloseModal: false,
  isSearch: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.GET_OUT_SOURCE_TRANSPORT_COST_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.GET_OUT_SOURCE_TRANSPORT_COST_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false
      };
    }
    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.GET_OUT_SOURCE_TRANSPORT_COST_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        isCloseModal: false,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.CREATE_OUT_SOURCE_TRANSPORT_COST_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.CREATE_OUT_SOURCE_TRANSPORT_COST_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isShowMessage: true,
        isCloseModal: true
      };
    }
    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.CREATE_OUT_SOURCE_TRANSPORT_COST_REJECTED:
      const messageError = action.payload.response.data.message + '';
      let check = dataMessageExist.includes(messageError);

      return {
        ...state,
        loading: false,
        message: check ? messageError : 'Thêm dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false
      };

    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.DELETE_OUT_SOURCE_TRANSPORT_COST_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.DELETE_OUT_SOURCE_TRANSPORT_COST_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Xóa dữ liệu thành công',
        isShowMessage: true
      };
    }
    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.DELETE_OUT_SOURCE_TRANSPORT_COST_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Xóa dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error'
      };
    }

    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.UPDATE_OUT_SOURCE_TRANSPORT_COST_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.UPDATE_OUT_SOURCE_TRANSPORT_COST_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công',
        isShowMessage: true,
        isCloseModal: true
      };
    }
    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.UPDATE_OUT_SOURCE_TRANSPORT_COST_REJECTED:
      const messageErrorCreate = action.payload.response.data.message + '';
      let checkCreate = dataMessageExist.includes(messageErrorCreate);
      return {
        ...state,
        loading: false,
        message: checkCreate ? messageErrorCreate : 'Cập nhật dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false
      };

    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.SEARCH_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination
      };
    }
    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.GET_CONT_CATEGORY_FULFILLED: {
      return {
        ...state,
        listContCategory: action.payload.data.data
      };
    }

    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.GET_CONT_CATEGORY_REJECTED: {
      return {
        ...state,
        // loading: false,
        message: 'Đã có lỗi xảy ra',
        isShowMessage: true,
        typeMessage: 'error'
      };
    }

    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.GET_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        // loading: false,
        listCustomer: action.payload.data.data
      };
    }

    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.GET_CUSTOMERS_REJECTED: {
      return {
        ...state,
        // loading: false,
        message: 'Đã có lỗi xảy ra',
        isShowMessage: true,
        typeMessage: 'error'
      };
    }
    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.GET_ROUTES_FULFILLED: {
      return {
        ...state,
        // loading: false,
        listRoute: action.payload.data.data
      };
    }

    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.GET_ROUTES_REJECTED: {
      return {
        ...state,
        // loading: false,
        message: 'Đã có lỗi xảy ra',
        isShowMessage: true,
        typeMessage: 'error'
      };
    }

    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }

    //update import excel
    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.CREATE_OUT_SOURCE_TRANSPORT_COST_V2_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.CREATE_OUT_SOURCE_TRANSPORT_COST_V2_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Nhập dữ liệu thành công !',
        isShowMessage: true,
        isCloseModal: true
      };
    }
    case OUT_SOURCE_TRANSPORT_COST_ACTIONS.CREATE_OUT_SOURCE_TRANSPORT_COST_V2_REJECTED:
      const messageError2 = action.payload.response.data.message + '';
      let check2 = dataMessageExist.includes(messageError2);

      return {
        ...state,
        loading: false,
        message: check2 ? messageError2 : 'Nhập dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false
      };

    default:
      return state;
  }
};
