import { FILL_UP_FUEL_MANAGEMENT_ACTIONS } from 'actions/fillupfuelmanagement';

const fillUpFuelManagementReducer = (
  state = {
    isSearch: false,
    fuelingImages: [],
    fuelingImagesLoading: false
  },
  action
) => {
  switch (action.type) {
    // get fill up fuel management per page
    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true,
        isSearch: false
      };

    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_FULFILLED:
      const fillUpFuelManagement = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        fillUpFuelManagement: fillUpFuelManagement,
        isLoading: false,
        isSearch: false
      };

    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi',
        isSearch: false
      };

    // search fill up fuel management per page
    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.SEARCH_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true,
        isSearch: true
      };

    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.SEARCH_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_FULFILLED:
      const newFillUpFuelManagement = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        fillUpFuelManagement: newFillUpFuelManagement,
        isLoading: false,
        isSearch: true
      };

    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.SEARCH_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi',
        isSearch: true
      };

    // get fill up fuel management by id
    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_FILL_UP_FUEL_MANAGEMENT_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_FILL_UP_FUEL_MANAGEMENT_BY_ID_FULFILLED:
      return {
        ...state,
        isLoading: false
      };

    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_FILL_UP_FUEL_MANAGEMENT_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // create fill up fuel management
    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.CREATE_FILL_UP_FUEL_MANAGEMENT_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.CREATE_FILL_UP_FUEL_MANAGEMENT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Thêm mới dữ liệu thành công'
      };

    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.CREATE_FILL_UP_FUEL_MANAGEMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };
    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_FUE_IMAGES_FULFILLED:
      return {
        ...state,
        fuelingImages: action?.payload?.data?.data || [],
        fuelingImagesLoading: false
      };
    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_FUE_IMAGES_PENDING:
      return {
        ...state,
        fuelingImages: [],
        fuelingImagesLoading: true
      };
    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_FUE_IMAGES_REJECTED:
      return {
        ...state,
        fuelingImages: [],
        fuelingImagesLoading: false
      };
    // update fill up fuel management
    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.UPDATE_FILL_UP_FUEL_MANAGEMENT_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.UPDATE_FILL_UP_FUEL_MANAGEMENT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Thành công'
      };

    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.UPDATE_FILL_UP_FUEL_MANAGEMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // delete fill up fuel management
    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.DELETE_FILL_UP_FUEL_MANAGEMENT_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.DELETE_FILL_UP_FUEL_MANAGEMENT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Thành công'
      };

    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.DELETE_FILL_UP_FUEL_MANAGEMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    //   //get all
    // case FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_ALL_FILL_UP_FUEL_MANAGEMENT_PENDING:
    //   return {
    //     ...state,
    //     isLoading: true
    //   };

    // case FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_ALL_FILL_UP_FUEL_MANAGEMENT_FULFILLED:
    //   let allRouter = {
    //     data: action.payload.data.data
    //   };
    //   return {
    //     ...state,
    //     allRouter: allRouter,
    //       isLoading: false,
    //   };

    // case FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_ALL_FILL_UP_FUEL_MANAGEMENT_REJECTED:
    //   return {
    //     ...state,
    //     isLoading: false,
    //       isMsg: true,
    //       msg: "Đã xảy ra lỗi"
    //   };

    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_SUMMARY_FILL_UP_FUEL_MANAGEMENT_BY_ID_PENDING:
      return {
        ...state
      };

    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_SUMMARY_FILL_UP_FUEL_MANAGEMENT_BY_ID_FULFILLED:
      return {
        ...state,
        summaryData: action.payload.data.data
      };

    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_SUMMARY_FILL_UP_FUEL_MANAGEMENT_BY_ID_REJECTED:
      return {
        ...state,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi khi tổng hợp dữ liệu'
      };

    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.CLEAR_SUMMARY_FILL_UP_FUEL_MANAGEMENT_BY_ID:
      return {
        ...state,
        summaryData: null
      };

    default:
      return state;
  }
};

export default fillUpFuelManagementReducer;
