import { DEPARTMENT_ACTIONS } from 'actions/departmentInfo';

const INIT_STATE = {
  allData: [],
  departmentWithTractorTrailer: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // get all vehicles
    case DEPARTMENT_ACTIONS.GET_ALL_DEPARTMENT_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case DEPARTMENT_ACTIONS.GET_ALL_DEPARTMENT_FULFILLED: {
      let allData = action.payload.data.data;
      return {
        ...state,
        allData: allData,
        isLoading: false
      };
    }

    case DEPARTMENT_ACTIONS.GET_ALL_DEPARTMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Không thể lấy được dữ liệu !'
      };
    case DEPARTMENT_ACTIONS.GET_DEPARTMENT_WITH_TRACTOR_TRAILER_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case DEPARTMENT_ACTIONS.GET_DEPARTMENT_WITH_TRACTOR_TRAILER_FULFILLED: {
      let data = action.payload.data.data;
      return {
        ...state,
        departmentWithTractorTrailer: data,
        isLoading: false
      };
    }

    case DEPARTMENT_ACTIONS.GET_DEPARTMENT_WITH_TRACTOR_TRAILER_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Không thể lấy được dữ liệu !'
      };
    //get vehicle by department:
    case DEPARTMENT_ACTIONS.GET_VEHICLE_BY_DEPARTMENT_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case DEPARTMENT_ACTIONS.GET_VEHICLE_BY_DEPARTMENT_FULFILLED:
      return {
        ...state,
        vehicleByDepartmentId: action.payload.data.data,
        isLoading: false
      };

    case DEPARTMENT_ACTIONS.GET_VEHICLE_BY_DEPARTMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Không thể lấy được dữ liệu !'
      };

    case DEPARTMENT_ACTIONS.GET_PAYMENT_CODE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case DEPARTMENT_ACTIONS.GET_PAYMENT_CODE_FULFILLED:
      return {
        ...state,
        paymentCodes: action.payload.data.data,
        isLoading: false
      };

    case DEPARTMENT_ACTIONS.GET_PAYMENT_CODE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Không thể lấy được dữ liệu !'
      };

    case DEPARTMENT_ACTIONS.CREATE_NEW_DEPARTMENT_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case DEPARTMENT_ACTIONS.CREATE_NEW_DEPARTMENT_FULFILLED:
      return {
        ...state,
        paymentCodes: action.payload.data.data,
        isLoading: false
      };

    case DEPARTMENT_ACTIONS.CREATE_NEW_DEPARTMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Không thể tạo mới dữ liệu !'
      };

    default:
      return state;
  }
};
