import { TRUCKING_FEE_ACTIONS } from "actions/truckingfee";
import { PAGE_SIZE } from "const/common";
import { put, takeEvery } from "redux-saga/effects";
import {
    getTruckingFeeByID,
    getTruckingFeePerPage} from "services/truckingfee";

function* putActionUpdateData(actions) {
    yield put({
        type: TRUCKING_FEE_ACTIONS.GET_TRUCKING_FEE_PER_PAGE,
        payload: getTruckingFeePerPage(actions.meta.currentPage, PAGE_SIZE)
    });
}

export function* actionUpdateTruckingFeeData() {
    yield takeEvery(
        [
            // TRUCKING_FEE_ACTIONS.UPDATE_TRUCKING_FEE_FULFILLED,
            // TRUCKING_FEE_ACTIONS.UPDATE_TRUCKING_FEE_REJECTED
        ],
        putActionUpdateData
    );
}

//adding trucking fee
function* putActionUpdateAddingTruckingFeeData(actions) {
    yield put({
        type: TRUCKING_FEE_ACTIONS.GET_TRUCKING_FEE_BY_ID,
        payload: getTruckingFeeByID(actions.meta.truckingFeeID)
    });
    // yield put({
    //     type: TRUCKING_FEE_ACTIONS.GET_TRUCKING_FEE_PER_PAGE,
    //     payload: getTruckingFeePerPage(actions.meta.currentPage, PAGE_SIZE)
    // })
}

export function* actionUpdateAddingTruckingFeeData() {
    yield takeEvery(
        [
            // TRUCKING_FEE_ACTIONS.UPDATE_ADDING_TRUCKING_FEE_FULFILLED,
            // TRUCKING_FEE_ACTIONS.UPDATE_ADDING_TRUCKING_FEE_REJECTED
        ],
        putActionUpdateAddingTruckingFeeData
    );
}