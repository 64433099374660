import {
  CHANGE_SETUP_FIELD_VALUE_FORMULAR,
  CHANGE_SETUP_FIELD_VALUE_FORMULAR_FULFILLED,
  CHANGE_SETUP_FIELD_VALUE_FORMULAR_PENDING,
  CHANGE_SETUP_FIELD_VALUE_FORMULAR_REJECTED
} from 'actions/setupDialogFormularAction';
import { call, put, takeLatest } from 'redux-saga/effects';

// (TTS)LyLV 2020/04/08 Watcher START
export function* watchChangeSetupFieldFormulas() {
  yield takeLatest(CHANGE_SETUP_FIELD_VALUE_FORMULAR, processChangeSetupField);
}

// (TTS)LyLV 2020/04/08 Watcher END

// (TTS)LyLV 2020/04/08 SAGA START
// saga worker sigin
function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function* processChangeSetupField(action) {
  // console.log("auth sagas userSignin");
  yield put({ type: CHANGE_SETUP_FIELD_VALUE_FORMULAR_PENDING, payload: action.payload });
  try {
    yield call(wait, 100);
    yield put({ type: CHANGE_SETUP_FIELD_VALUE_FORMULAR_FULFILLED, payload: action.payload });
  } catch (err) {
    yield put({ type: CHANGE_SETUP_FIELD_VALUE_FORMULAR_REJECTED, payload: action.payload });
  }
}

// (TTS)LyLV 2020/04/08 SAGA END
