import {
  createCustomFee,
  deleteCustomFee,
  getCustomFeeByID,
  getCustomFeePerPage,
  searchData,
  searchDataAll,
  updateCustomFee
} from 'services/customfee';

// define const actions
export const CUSTOM_FEE_ACTIONS = {
  GET_CUSTOM_FEE_PER_PAGE: 'GET_CUSTOM_FEE_PER_PAGE',
  GET_CUSTOM_FEE_PER_PAGE_PENDING: 'GET_CUSTOM_FEE_PER_PAGE_PENDING',
  GET_CUSTOM_FEE_PER_PAGE_FULFILLED: 'GET_CUSTOM_FEE_PER_PAGE_FULFILLED',
  GET_CUSTOM_FEE_PER_PAGE_REJECTED: 'GET_CUSTOM_FEE_PER_PAGE_REJECTED',

  GET_CUSTOM_FEE_BY_ID: 'GET_CUSTOM_FEE_BY_ID',
  GET_CUSTOM_FEE_BY_ID_PENDING: 'GET_CUSTOM_FEE_BY_ID_PENDING',
  GET_CUSTOM_FEE_BY_ID_FULFILLED: 'GET_CUSTOM_FEE_BY_ID_FULFILLED',
  GET_CUSTOM_FEE_BY_ID_REJECTED: 'GET_CUSTOM_FEE_BY_ID_REJECTED',

  CREATE_CUSTOM_FEE: 'CREATE_CUSTOM_FEE',
  CREATE_CUSTOM_FEE_PENDING: 'CREATE_CUSTOM_FEE_PENDING',
  CREATE_CUSTOM_FEE_FULFILLED: 'CREATE_CUSTOM_FEE_FULFILLED',
  CREATE_CUSTOM_FEE_REJECTED: 'CREATE_CUSTOM_FEE_REJECTED',

  UPDATE_CUSTOM_FEE: 'UPDATE_CUSTOM_FEE',
  UPDATE_CUSTOM_FEE_PENDING: 'UPDATE_CUSTOM_FEE_PENDING',
  UPDATE_CUSTOM_FEE_FULFILLED: 'UPDATE_CUSTOM_FEE_FULFILLED',
  UPDATE_CUSTOM_FEE_REJECTED: 'UPDATE_CUSTOM_FEE_REJECTED',

  DELETE_CUSTOM_FEE: 'DELETE_CUSTOM_FEE',
  DELETE_CUSTOM_FEE_PENDING: 'DELETE_CUSTOM_FEE_PENDING',
  DELETE_CUSTOM_FEE_FULFILLED: 'DELETE_CUSTOM_FEE_FULFILLED',
  DELETE_CUSTOM_FEE_REJECTED: 'DELETE_CUSTOM_FEE_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED'
};

// define actions
export const actionGetCustomFeePerPage = (payload) => (dispatch) => {
  return dispatch({
    type: CUSTOM_FEE_ACTIONS.GET_CUSTOM_FEE_PER_PAGE,
    payload: getCustomFeePerPage(payload)
  });
};

export const actionGetCustomFeeByID = (id) => (dispatch) => {
  return dispatch({
    type: CUSTOM_FEE_ACTIONS.GET_CUSTOM_FEE_BY_ID,
    payload: getCustomFeeByID(id)
  });
};

export const actionCreateCustomFee = (payload) => (dispatch) => {
  return dispatch({
    type: CUSTOM_FEE_ACTIONS.CREATE_CUSTOM_FEE,
    payload: createCustomFee(payload)
  });
};

export const actionUpdateCustomFee = (payload) => (dispatch) => {
  return dispatch({
    type: CUSTOM_FEE_ACTIONS.UPDATE_CUSTOM_FEE,
    payload: updateCustomFee(payload)
  });
};

export const actionDeleteCustomFee = (id) => (dispatch) => {
  return dispatch({
    type: CUSTOM_FEE_ACTIONS.DELETE_CUSTOM_FEE,
    payload: deleteCustomFee(id)
  });
};

export const actionSearchData = (value, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: CUSTOM_FEE_ACTIONS.SEARCH_DATA,
    payload: searchData(value, pageNo, pageSize)
  });
};

export const actionSearchDataAll = (params) => (dispatch) => {
  return dispatch({
    payload: searchDataAll(params)
  });
};
