import { SETTLEMENT_REPAIR } from 'actions/repair/settlementRepair';

const SettlementRepairReducer = (state = {}, action) => {
  switch (action.type) {
    //get by id
    case SETTLEMENT_REPAIR.GET_ALL_DATA_SETTLEMENT_PENDING:
      return {
        ...state,
        isLoading: true,
        isSuccess: false
      };

    case SETTLEMENT_REPAIR.GET_ALL_DATA_SETTLEMENT_FULFILLED:
      return {
        ...state,
        dataList: action.payload.data,
        isLoading: false,
        isSuccess: false,
        repairingStatus: action.payload.data.repairingStatus
      };

    case SETTLEMENT_REPAIR.GET_ALL_DATA_SETTLEMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi',
        isSuccess: false
      };
    // create
    case SETTLEMENT_REPAIR.CREATE_SETTLEMENT_REPAIR_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
        isSuccess: false
      };

    case SETTLEMENT_REPAIR.CREATE_SETTLEMENT_REPAIR_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Tạo dữ liệu thành công',
        isSuccess: true
      };

    case SETTLEMENT_REPAIR.CREATE_SETTLEMENT_REPAIR_REJECTED:
      return {
        ...state,
        isLoading: true,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Tạo dữ liệu thất bại',
        isSuccess: false
      };

    // update
    case SETTLEMENT_REPAIR.UPDATE_SETTLEMENT_REPAIR_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
        isSuccess: false
      };

    case SETTLEMENT_REPAIR.UPDATE_SETTLEMENT_REPAIR_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Sửa dữ liệu thành công',
        typeMessage: 'success',
        isSuccess: true
      };

    case SETTLEMENT_REPAIR.UPDATE_SETTLEMENT_REPAIR_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Sửa dữ liệu thất bại',
        isSuccess: false
      };

    // delete
    case SETTLEMENT_REPAIR.DELETE_SETTLEMENT_REPAIR_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
        isSuccess: false
      };

    case SETTLEMENT_REPAIR.DELETE_SETTLEMENT_REPAIR_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Xóa dữ liệu thành công',
        isSuccess: true
      };

    case SETTLEMENT_REPAIR.DELETE_SETTLEMENT_REPAIR_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Xóa dữ liệu thất bại',
        isSuccess: false
      };

    case SETTLEMENT_REPAIR.CHANGE_DATA_EDIT_TABEL_REPAIR_SETTLEMENT: {
      const data = action.payload;
      let dataReturn = { ...state.dataList, ...data, error: action.meta?.error };
      return {
        ...state,
        isLoading: false,
        dataList: dataReturn,
        isUpdateSubmit: action.meta.message !== undefined ? false : true
      };
    }

    default:
      return state;
  }
};

export default SettlementRepairReducer;
