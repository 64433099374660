import { minMaxNumber } from './ValidateType';

export const fommatCurrency = (value) => {
  const isString = typeof value === 'string';
  const isNegative = isString ? value.startsWith('-') : value < 0;
  const positiveValue = value ? value.toString().replace('-', '') : value;
  const positiveNumb = positiveValue ? Number(positiveValue) : '';
  const formatter = new Intl.NumberFormat('ja-JP', {
    // style: "currency",
    // currency: "VNĐ",
    aximumSignificantDigits: 3
  });
  //   const formatter = new Intl.NumberFormat();
  if (isNaN(value)) {
    return value;
  } else {
    const res = (isNegative ? '-' : '') + (positiveNumb ? formatter.format(positiveNumb) : isNegative ? '' : '0');
    return res;
  }
};
export const checkValueFieldMinMax = (value, number, isCurrency, isAcceptNegative) => {
  const validator = minMaxNumber(0.0001, 100000, 'tấn');
  return validator(value);
};
export const checkValueField = (value, number, isCurrency, isAcceptNegative) => {
  let check = value.includes(',');
  const regexDots = /\./g;
  const regexComma = /\,/g;
  const positiveValue = value.startsWith('-') && isAcceptNegative ? value.slice(1) : value;
  if (value.startsWith('-') && !isAcceptNegative) {
    return 'Yêu cầu nhập dữ liệu là số dương !';
  }
  let valueTest = !check ? positiveValue.replace(regexDots, '') : positiveValue.replace(regexComma, '');
  let patt = /^(\d{1,3}(,\d{1,3})*?)?\.?\d{1,3}(,\d{1,3})?$/g;
  let testDecimal = /^(\d{1,3}(,\d{1,3})*?)?\.?\d{1,3}(,\d{1})?$/g;
  let isLengthDecimal = testDecimal.test(value);

  let isNumber = patt.test(value);
  const isNumber2 = /^\d+$/.test(value);
  console.log(value, isNumber, isNumber2);
  if (!isNumber) {
    if (isCurrency && valueTest === '') {
      return undefined;
    } else if (!isNumber2) {
      return 'Yêu cầu nhập dữ liệu là số !';
    }
  } else if (valueTest.length === 0 && !isCurrency) {
    return 'Vui lòng không để trống trường này !';
  } else if (valueTest.length > parseInt(number)) {
    return `Trường này không được quá ${number} ký tự !`;
  } else if (!isLengthDecimal) {
    console.log(value);
    return 'Số thập phân không quá 4 ký tự';
  } else {
    return undefined;
  }
};

export const checkValidateVAT = (value, maxNumber = 100) => {
  let patt = new RegExp('^[0-9]+$');
  let isNumber = patt.test(value.replace(/,/g, ''));
  if (isNumber) {
    return Number(value) >= 0 && Number(value) <= maxNumber ? undefined : 'VAT phải nhỏ hơn 100';
  } else {
    return 'VAT phải là số';
  }
};

export const checkValidateString = (value, number, isRequire) => {
  if (isRequire) {
    return 'Vui lòng không để trống trường này !';
  } else if (value.length > parseInt(number)) {
    return `Trường này không được quá ${number} ký tự !`;
  }
};

export function getMessageValidate(array) {
  if ((array && array.length === 0) || !array) return undefined;
  let message = undefined;
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    if (item) {
      message = item;
      break;
    }
  }
  return message;
}

export function validateEditableField(funcs, data) {
  let validateResults = [];
  if (funcs && funcs.length === 0) return undefined;
  if (funcs && funcs.length) {
    funcs.forEach((item) => {
      const index = validateFuncs.findIndex((el) => {
        return el.title === item;
      });
      if (index > -1) {
        const calFunctions = validateFuncs[index].func;
        const validateValue = calFunctions(...data);
        if (validateValue) {
          validateResults.push(validateValue);
        }
      }
    });
  }

  return validateResults;
}

const validateFuncs = [
  { func: validateNumber, title: 'validateNumber' },
  { func: validateMaxLength, title: 'validateMaxLength' },
  { func: checkIsHtml, title: 'checkIsHtml' }
];

function validateNumber(value) {
  const patt = new RegExp('^[0-9]+$');
  let message = undefined;
  const removeDotsAndCommasNumber = removeDotsAndCommas(value);
  const isNumberType = patt.test(removeDotsAndCommasNumber);
  if (!isNumberType && value !== '') {
    return (message = `Không đúng định dạng số`);
  }
  return message;
}

function validateMaxLength(value, length) {
  let message = undefined;
  if (value && value.length > length) {
    return (message = `Không được nhập quá ${length} ký tự `);
  }
  return message;
}

export function removeDotsAndCommas(value) {
  const valueString = value + '';
  const regex = /[^a-zA-Z0-9]/g;
  const valueRemoved = valueString.replace(regex, '');
  return valueRemoved;
}

//TODO: show message validate input
export function showValidateMessage(mess, fieldName) {
  const error = mess.error;
  if (error) {
    return error[fieldName];
  } else {
    return '';
  }
}

export function checkIsHtml(value) {
  const isHtml = /<[a-z][\s\S]*>/i.test(value);
  return isHtml ? 'Không đúng định dạng' : undefined;
}
