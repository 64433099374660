export const WARNING_COLOR = '#E29C06';
export const DANGER_COLOR = '#E03232';
export const PRIMARY_COLOR = '#2A1AB9';
export const SECONDARY_COLOR = '#B9BEC7';
export const CONFIRM_COLOR = '#20a8d8';
export const RETURN_YARD_COLOR = '#bc9749';
export const COMPLETED_COLOR = '#45a164';
export const TAKE_THE_SHELL_COLOR = '#009a68';

export const PRINT_STANDARD_OIL_SUCCESS = '#47eaa3';
