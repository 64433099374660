import {
  getAllPortCategory
} from "services/portcategory";

// define const actions
export const PORT_CATEGORY_ACTIONS = {
  GET_ALL_PORT_CATEGORY: "GET_ALL_PORT_CATEGORY",
  GET_ALL_PORT_CATEGORY_PENDING: "GET_ALL_PORT_CATEGORY_PENDING",
  GET_ALL_PORT_CATEGORY_FULFILLED: "GET_ALL_PORT_CATEGORY_FULFILLED",
  GET_ALL_PORT_CATEGORY_REJECTED: "GET_ALL_PORT_CATEGORY_REJECTED",
};


// define actions
export const actionGetAllPortCategory = () => dispatch => {
  return dispatch({
    type: PORT_CATEGORY_ACTIONS.GET_ALL_PORT_CATEGORY,
    payload: getAllPortCategory()
  });
};
