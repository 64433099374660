import { SETTING_CONT_MODE_CHECK } from 'const/common';
import { isValidContNo } from 'const/validateContNo';
import moment from 'moment';

import { isNull, isNullOrEmpty } from './utils';

//common validate
export const required = (value) =>
  value !== undefined && value !== null && value !== '' ? undefined : 'Vui lòng không để trắng ô này';

// string validate
export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Vui lòng nhập đúng định dạng email' : undefined;
// ký tự đặc biệt không bao gồm _ -
// const routeCharacter = /[ `!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;
const routeCharacter = /[%'\/]/;
export const notIncludeCharacterNotAcceptWithRoute = (value) =>
  value && routeCharacter.test(value) ? 'Vui lòng không nhập kí tự đặc biệt' : undefined;

// number validate
export const number = (value) => (value && isNaN(Number(value)) ? 'Yêu cầu nhập dữ liệu là số' : undefined);
export const minValue = (min) => (value) => value && value < min ? `Phải nhập số lớn hơn hoặc bằng ${min}` : undefined;
export const maxValue = (max) => (value) => value && value > max ? `Bạn cần nhập số nhỏ hơn ${max}` : undefined;
export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const maxLength = (max) => (value) => value && value.length > max ? `Không nhập quá ${max} kí tự` : undefined;
export const tooOld = (value) => (value && value > 65 ? 'You might be too old for this' : undefined);
export const greaterValue = (min) => (value) => value && value <= min ? `Phải nhập số lớn hơn ${min}` : undefined;
function isValidDecimal(number) {
  const regex = /^-?\d{1,10}(\.\d{1,4})?$/;
  return !regex.test(number);
}
export const minMaxNumber =
  (min, max, type = '') =>
  (text) => {
    const value = text?.toString()?.replaceAll(/,/g, '') || '';
    return value
      ? isNaN(Number(value))
        ? 'Yêu cầu nhập dữ liệu là số'
        : value == 0
        ? undefined
        : value > max
        ? `Bạn cần nhập số nhỏ hơn ${max} ` + type
        : value < min
        ? `Phải nhập số lớn hơn bằng ${min} hoặc 0` + type
        : isValidDecimal(value)
        ? 'Không được quá 4 ký tự sau "."'
        : undefined
      : undefined;
  };

//check exits
export const checkValidExist = (isValid) => (value) =>
  isValid ? `Bạn không được nhập trùng giá trị trường này ` : undefined;

export const checkValidYear = (value) =>
  value && !/^(19[5-9]\d|20[0-4]\d|2050)$/i.test(value) ? 'Vui lòng nhập đúng định dạng năm' : undefined;

export const checkCarLience = (value) =>
  value && !/^[0-9]{2}[A-Z]{1,4}[0-9]{1,4}-[0-9]{4,8}$/i.test(value)
    ? 'Vui lòng nhập đúng định dạng biển số'
    : undefined;

export const checkValueSelect = (value) => {
  const errors = {};
  return isNull(value) ? { ...errors, message: 'Vui lòng chọn trường này' } : undefined;
};

export const checkDate = (value) =>
  value && !/^\d{4}[./-]\d{2}[./-]\d{2}$/i.test(value) ? 'Vui lòng nhập đúng định dạng ngày' : undefined;

export const checkReturnTruckingFeeDate = (value, allValues, props, name) => {
  if (value === null || value === undefined || value === '') {
    return undefined;
  }

  value = value.replace(/-/g, '');
  value = parseInt(value);

  let truckingDate = allValues.TruckingDate;
  if (truckingDate !== null && truckingDate !== undefined) {
    truckingDate = truckingDate.slice(0, 10);
    truckingDate = truckingDate.replace(/-/g, '');
    truckingDate = parseInt(truckingDate);

    let msg = value - truckingDate >= 0 ? undefined : 'Ngày nộp cước phải lớn hơn hoặc bằng ngày điều xe';

    if (msg !== undefined) {
      return msg;
    }
  }

  let deliveryDate = allValues.DeliveryDate;
  if (deliveryDate !== null && deliveryDate !== undefined) {
    deliveryDate = deliveryDate.slice(0, 10);
    deliveryDate = deliveryDate.replace(/-/g, '');
    deliveryDate = parseInt(deliveryDate);

    let msg = value - deliveryDate >= 0 ? undefined : 'Ngày nộp cước phải lớn hơn hoặc bằng ngày trả hàng';

    if (msg !== undefined) {
      return msg;
    }
  }

  let receiveTruckingFeeDate = allValues.ReceiveTruckingFeeDate;
  if (receiveTruckingFeeDate !== null && receiveTruckingFeeDate !== undefined) {
    receiveTruckingFeeDate = receiveTruckingFeeDate.slice(0, 10);
    receiveTruckingFeeDate = receiveTruckingFeeDate.replace(/-/g, '');
    receiveTruckingFeeDate = parseInt(receiveTruckingFeeDate);

    let msg = value - receiveTruckingFeeDate >= 0 ? undefined : 'Ngày nộp cước phải lớn hơn hoặc bằng ngày trả cước';

    if (msg !== undefined) {
      return msg;
    }
  }

  let departureDate = allValues.DepartureDate;
  if (departureDate !== null && departureDate !== undefined) {
    departureDate = departureDate.slice(0, 10);
    departureDate = departureDate.replace(/-/g, '');
    departureDate = parseInt(departureDate);

    let msg = value - departureDate >= 0 ? undefined : 'Ngày nộp cước phải lớn hơn hoặc bằng ngày giờ đi';

    if (msg !== undefined) {
      return msg;
    }
  }

  return undefined;
};

export const checkTravelAllowanceDate = (value, allValues, props, name) => {
  let deliveryDate = allValues.DeliveryDate;
  if (deliveryDate === null || deliveryDate === undefined || value === null || value === undefined || value === '') {
    return undefined;
  }
  deliveryDate = deliveryDate.replace(/-/g, '');
  deliveryDate = parseInt(deliveryDate);

  value = value.replace(/-/g, '');
  value = parseInt(value);

  let msg = value - deliveryDate >= 0 ? undefined : 'Ngày thanh toán phải lớn hơn hoặc bằng ngày trả hàng';

  return msg;
};

export const checkReturnDate = (value, allValues, props, name) => {
  let departureDate = allValues.DepartureDate;
  if (departureDate === null || departureDate === undefined || value === null || value === undefined || value === '') {
    return undefined;
  }

  departureDate = departureDate.replace(/-/g, '');
  departureDate = departureDate.replace(/T/g, '');
  departureDate = departureDate.replace(/:/g, '');
  departureDate = departureDate?.slice(0, 12) ?? departureDate;
  departureDate = parseInt(departureDate);

  value = value.replace(/-/g, '');
  value = value.replace(/T/g, '');
  value = value.replace(/:/g, '');
  value = value?.slice(0, 12) ?? value;
  value = parseInt(value);

  let msg = value - departureDate >= 0 ? undefined : 'Ngày giờ về phải lớn hơn hoặc bằng ngày giờ đi';

  return msg;
};

export const IsDeliveryDateAfterDeliveryDate = (value, allValues, props, name) => {
  if (value === null || value === undefined || value === '') {
    return undefined;
  }

  // check by trucking date
  let departureDate = allValues.DepartureDate;
  if (departureDate !== null && departureDate !== undefined) {
    departureDate = moment(departureDate).format('YYYYMMDD');
    departureDate = parseInt(departureDate);

    value = moment(value).format('YYYYMMDD');
    value = parseInt(value);

    let msg = value - departureDate >= 0 ? undefined : 'Ngày trả hàng phải lớn hơn hoặc bằng ngày giờ đi';
    if (msg !== undefined) {
      return msg;
    }
  }

  return undefined;
};

export const IsDeliveryDateAfterGoodsTakingDate = (value, allValues, props, name) => {
  if (value === null || value === undefined || value === '') {
    return undefined;
  }

  // check by trucking date
  let GoodsTakingDate = allValues.GoodsTakingDate;
  if (GoodsTakingDate !== null && GoodsTakingDate !== undefined) {
    GoodsTakingDate = moment(GoodsTakingDate).format('YYYYMMDD');
    GoodsTakingDate = parseInt(GoodsTakingDate);

    value = moment(value).format('YYYYMMDD');
    value = parseInt(value);

    let msg = value - GoodsTakingDate >= 0 ? undefined : 'Ngày trả hàng phải lớn hơn hoặc bằng ngày đóng hàng';
    if (msg !== undefined) {
      return msg;
    }
  }

  return undefined;
};

export const IsDeliveryDateAfterTruckingDate = (value, allValues, props, name) => {
  if (value === null || value === undefined || value === '') {
    return undefined;
  }

  // check by trucking date
  let truckingDate = allValues.TruckingDate;
  if (truckingDate !== null && truckingDate !== undefined) {
    truckingDate = truckingDate.replace(/-/g, '');
    truckingDate = parseInt(truckingDate);

    value = value.replace(/-/g, '');
    value = parseInt(value);

    let msg = value - truckingDate >= 0 ? undefined : 'Ngày trả hàng phải lớn hơn hoặc bằng ngày điều xe';
    if (msg !== undefined) {
      return msg;
    }
  }

  return undefined;
};

export const checkReceiveTruckingFeeDate = (value, allValues, props, name) => {
  if (value === null || value === undefined || value === '') {
    return undefined;
  }

  value = value.replace(/-/g, '');
  value = parseInt(value);

  // check by trucking date
  let truckingDate = allValues.TruckingDate;
  if (truckingDate !== null && truckingDate !== undefined) {
    truckingDate = truckingDate.replace(/-/g, '');
    truckingDate = parseInt(truckingDate);

    let msg = value - truckingDate >= 0 ? undefined : 'Ngày nộp cước phải lớn hoặc bằng ngày điều xe';
    if (msg !== undefined) {
      return msg;
    }
  }

  // check by delivery date
  let deliveryDate = allValues.DeliveryDate;
  if (deliveryDate !== null && deliveryDate !== undefined) {
    deliveryDate = deliveryDate.replace(/-/g, '');
    deliveryDate = parseInt(deliveryDate);

    let msg = value - deliveryDate >= 0 ? undefined : 'Ngày nộp cước phải lớn hoặc bằng ngày trả hàng';
    if (msg !== undefined) {
      return msg;
    }
  }

  return undefined;
};

export const checkContNo = (value, allValues, props, name) => {
  const customContCheck = localStorage.getItem(SETTING_CONT_MODE_CHECK);
  if (customContCheck === '1') return undefined;
  if (isNullOrEmpty(value)) return undefined;
  let flag = isValidContNo(value);
  let msg = flag ? undefined : 'Số cont nhập không hợp lệ';
  return msg;
};

export const checkExistContNo = (value, allValues, props, name, state) => {
  if (isNullOrEmpty(value)) return undefined;
  let flag = (props?.contsDetail || []).find((item) => item.ContSerialNo === value);
  let msg = flag ? 'Số cont đã tồn tại trong lô hàng' : undefined;
  return msg;
};

//repair
export const CheckBrokenDateAndRepairedDate = (value, allValues, props, name) => {
  let damagedDate = allValues.DamagedDate;
  if (damagedDate === null || damagedDate === undefined || value === null || value === undefined || value === '') {
    return undefined;
  }
  damagedDate = damagedDate.replace(/-/g, '');
  damagedDate = parseInt(damagedDate);

  value = value.replace(/-/g, '');
  value = parseInt(value);

  let msg = value - damagedDate >= 0 ? undefined : 'Ngày sửa chữa phải lớn hơn hoặc bằng ngày hỏng';

  return msg;
};

export const CheckRepairDate = (value) => {
  let msg = moment().diff(value, 'days') <= 30 ? undefined : 'Quá thời hạn thay thế, sửa chữa';

  return msg;
};

export const CheckRepairedDateAndRepairFinishDate = (value, allValues, props, name) => {
  let repairDate = allValues.RepairDate;
  if (repairDate === null || repairDate === undefined || value === null || value === undefined || value === '') {
    return undefined;
  }
  repairDate = repairDate.replace(/-/g, '');
  repairDate = parseInt(repairDate);

  value = value.replace(/-/g, '');
  value = parseInt(value);

  let msg = value - repairDate >= 0 ? undefined : 'Ngày hoàn thành phải lớn hơn hoặc bằng ngày sửa chữa';

  return msg;
};

export const CheckRepairedDateAndRepairExpirationDate = (value, allValues, props, name) => {
  let repairDate = allValues.RepairDate;
  if (repairDate === null || repairDate === undefined || value === null || value === undefined || value === '') {
    return undefined;
  }
  repairDate = repairDate.replace(/-/g, '');
  repairDate = parseInt(repairDate);

  value = value.replace(/-/g, '');
  value = parseInt(value);

  let msg = value - repairDate >= 0 ? undefined : 'Ngày quá hạn phải lớn hơn hoặc bằng ngày sửa chữa';

  return msg;
};
