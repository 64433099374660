import { DOWNPAYMENT_SELL_ACTIONS } from 'actions/downpaymentShellManagement';
import { getErrorMessage } from 'commons/ReducerUtils';
import { DEFAULT_PAGE_SIZE } from 'const/common';

const INIT_STATE = {
  listPerPage: [],
  listStatus: [],
  listEmployees: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE },
  isCloseModal: false,
  isSearch: false,
  reload: false,

  summary: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DOWNPAYMENT_SELL_ACTIONS.GET_DOWNPAYMENT_SELL_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case DOWNPAYMENT_SELL_ACTIONS.GET_DOWNPAYMENT_SELL_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false
      };
    }
    case DOWNPAYMENT_SELL_ACTIONS.GET_DOWNPAYMENT_SELL_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false
      };
    }

    case DOWNPAYMENT_SELL_ACTIONS.UPDATE_DOWNPAYMENT_SELL_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case DOWNPAYMENT_SELL_ACTIONS.UPDATE_DOWNPAYMENT_SELL_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công',
        isShowMessage: true,
        isCloseModal: true,
        reload: true
      };
    }

    case DOWNPAYMENT_SELL_ACTIONS.UPDATE_DOWNPAYMENT_SELL_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Cập nhật dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        reload: false
      };
    }

    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }

    case DOWNPAYMENT_SELL_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case DOWNPAYMENT_SELL_ACTIONS.SEARCH_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isCloseModal: false
      };
    }
    case DOWNPAYMENT_SELL_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false
      };
    }

    case DOWNPAYMENT_SELL_ACTIONS.GET_EMPLOYEES_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case DOWNPAYMENT_SELL_ACTIONS.GET_EMPLOYEES_FULFILLED: {
      return {
        ...state,
        loading: false,
        listEmployees: action.payload.data.data
      };
    }
    case DOWNPAYMENT_SELL_ACTIONS.GET_EMPLOYEES_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case DOWNPAYMENT_SELL_ACTIONS.GET_STATUS_PENDING: {
      return {
        ...state,
        isShowMessage: false
      };
    }
    case DOWNPAYMENT_SELL_ACTIONS.GET_STATUS_FULFILLED: {
      return {
        ...state,
        loading: false,
        listStatus: action.payload.data.data
      };
    }
    case DOWNPAYMENT_SELL_ACTIONS.GET_STATUS_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case DOWNPAYMENT_SELL_ACTIONS.GET_SUMMARY_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case DOWNPAYMENT_SELL_ACTIONS.GET_SUMMARY_FULFILLED: {
      return {
        ...state,
        loading: false,
        summary: action.payload.data.data
      };
    }
    case DOWNPAYMENT_SELL_ACTIONS.GET_SUMMARY_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: getErrorMessage(action, 'Không thể lấy được dữ liệu !')
      };
    }

    default:
      return state;
  }
};
