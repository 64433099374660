import api from 'HttpClient';
import {
  createSparePart,
  deleteSparePart,
  getSparePartById,
  getSparePartPerPage,
  getUnitOfSparepart,
  searchAllData,
  searchSparePart,
  updateSparePart
} from 'services/repair/sparePartsManagement';

// define const actions
export const SPARE_PARTS_ACTIONS = {
  GET_ALL_SPARE_PARTS: 'GET_ALL_SPARE_PARTS',
  GET_ALL_SPARE_PARTS_PENDING: 'GET_ALL_SPARE_PARTS_PENDING',
  GET_ALL_SPARE_PARTS_FULFILLED: 'GET_ALL_SPARE_PARTS_FULFILLED',
  GET_ALL_SPARE_PARTS_REJECTED: 'GET_ALL_SPARE_PARTS_REJECTED',

  GET_SPARE_PARTS_PER_PAGE: 'GET_SPARE_PARTS_PER_PAGE',
  GET_SPARE_PARTS_PER_PAGE_PENDING: 'GET_SPARE_PARTS_PER_PAGE_PENDING',
  GET_SPARE_PARTS_PER_PAGE_FULFILLED: 'GET_SPARE_PARTS_PER_PAGE_FULFILLED',
  GET_SPARE_PARTS_PER_PAGE_REJECTED: 'GET_SPARE_PARTS_PER_PAGE_REJECTED',

  CREATE_SPARE_PARTS: 'CREATE_SPARE_PARTS',
  CREATE_SPARE_PARTS_PENDING: 'CREATE_SPARE_PARTS_PENDING',
  CREATE_SPARE_PARTS_FULFILLED: 'CREATE_SPARE_PARTS_FULFILLED',
  CREATE_SPARE_PARTS_REJECTED: 'CREATE_SPARE_PARTS_REJECTED',

  UPDATE_SPARE_PARTS: 'UPDATE_SPARE_PARTS',
  UPDATE_SPARE_PARTS_PENDING: 'UPDATE_SPARE_PARTS_PENDING',
  UPDATE_SPARE_PARTS_FULFILLED: 'UPDATE_SPARE_PARTS_FULFILLED',
  UPDATE_SPARE_PARTS_REJECTED: 'UPDATE_SPARE_PARTS_REJECTED',

  DELETE_SPARE_PARTS: 'DELETE_SPARE_PARTS',
  DELETE_SPARE_PARTS_PENDING: 'DELETE_SPARE_PARTS_PENDING',
  DELETE_SPARE_PARTS_FULFILLED: 'DELETE_SPARE_PARTS_FULFILLED',
  DELETE_SPARE_PARTS_REJECTED: 'DELETE_SPARE_PARTS_REJECTED',

  SEARCH_SPARE_PARTS: 'SEARCH_SPARE_PARTS',
  SEARCH_SPARE_PARTS_PENDING: 'SEARCH_SPARE_PARTS_PENDING',
  SEARCH_SPARE_PARTS_FULFILLED: 'SEARCH_SPARE_PARTS_FULFILLED',
  SEARCH_SPARE_PARTS_REJECTED: 'SEARCH_SPARE_PARTS_REJECTED',

  GET_SPARE_PARTS_BY_ID: 'GET_SPARE_PARTS_BY_ID',
  GET_SPARE_PARTS_BY_ID_PENDING: 'GET_SPARE_PARTS_BY_ID_PENDING',
  GET_SPARE_PARTS_BY_ID_FULFILLED: 'GET_SPARE_PARTS_BY_ID_FULFILLED',
  GET_SPARE_PARTS_BY_ID_REJECTED: 'GET_SPARE_PARTS_BY_ID_REJECTED',

  //get unit of spare part

  GET_UNIT_OF_SPARE_PART: 'GET_UNIT_OF_SPARE_PART',
  GET_UNIT_OF_SPARE_PART_PENDING: 'GET_UNIT_OF_SPARE_PART_PENDING',
  GET_UNIT_OF_SPARE_PART_FULFILLED: 'GET_UNIT_OF_SPARE_PART_FULFILLED',
  GET_UNIT_OF_SPARE_PART_REJECTED: 'GET_UNIT_OF_SPARE_PART_REJECTED',

  UPDATE_IS_SEARCH: 'UPDATE_IS_SEARCH',

  UPDATE_SELLING_PRICE: 'UPDATE_SELLING_PRICE',

  UPDATE_SERIAL_VALUE_SELECTED: 'UPDATE_SERIAL_VALUE_SELECTED',

  GET_ALL_SPARE_PART_GROUP: 'GET_ALL_SPARE_PART_GROUP',
  GET_ALL_SPARE_PART_GROUP_PENDING: 'GET_ALL_SPARE_PART_GROUP_PENDING',
  GET_ALL_SPARE_PART_GROUP_FULFILLED: 'GET_ALL_SPARE_PART_GROUP_FULFILLED',
  GET_ALL_SPARE_PART_GROUP_REJECTED: 'GET_ALL_SPARE_PART_GROUP_REJECTED'
};

// define actions:

export const actionGetAllSparePart = () => (dispatch) => {
  return dispatch({
    type: SPARE_PARTS_ACTIONS.GET_ALL_SPARE_PARTS,
    payload: api.get(`/sparepartinfo/getAll`)
  });
};

export const actionGetSparePartPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: SPARE_PARTS_ACTIONS.GET_SPARE_PARTS_PER_PAGE,
    payload: getSparePartPerPage(pageNo, pageSize)
  });
};

export const actionCreateSparePart = (payload) => (dispatch) => {
  return dispatch({
    type: SPARE_PARTS_ACTIONS.CREATE_SPARE_PARTS,
    payload: createSparePart(payload),
    meta: { currentPage: payload.currentPage }
  });
};

export const actionUpdateSparePart = (payload) => (dispatch) => {
  return dispatch({
    type: SPARE_PARTS_ACTIONS.UPDATE_SPARE_PARTS,
    payload: updateSparePart(payload),
    meta: { currentPage: payload.currentPage }
  });
};
export const actionDeleteSparePart = (id, currentPage) => (dispatch) => {
  return dispatch({
    type: SPARE_PARTS_ACTIONS.DELETE_SPARE_PARTS,
    payload: deleteSparePart(id),
    meta: { currentPage: currentPage }
  });
};
export const actionSearchSparePart = (params, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: SPARE_PARTS_ACTIONS.SEARCH_SPARE_PARTS,
    payload: searchSparePart(params, pageNo, pageSize)
  });
};
export const actionSearchSparePartAll = (params) => (dispatch) => {
  return dispatch({
    payload: searchSparePart(params)
  });
};

export const actionGetSparePartById = (id) => (dispatch) => {
  return dispatch({
    type: SPARE_PARTS_ACTIONS.GET_SPARE_PARTS_BY_ID,
    payload: getSparePartById(id)
  });
};

export const actionUpdateSellingPrice = (value) => (dispatch) => {
  return dispatch({
    type: SPARE_PARTS_ACTIONS.UPDATE_SELLING_PRICE,
    payload: value
  });
};
export const actionGetUnitOfSparepart = (id) => (dispatch) => {
  return dispatch({
    type: SPARE_PARTS_ACTIONS.GET_UNIT_OF_SPARE_PART,
    payload: getUnitOfSparepart(id)
  });
};

export const actionUpdateIsSearch = (status) => (dispatch) => {
  return dispatch({
    type: SPARE_PARTS_ACTIONS.UPDATE_IS_SEARCH,
    payload: status
  });
};

export const updateSerialSelected = (status) => (dispatch) => {
  return dispatch({
    type: SPARE_PARTS_ACTIONS.UPDATE_SERIAL_VALUE_SELECTED,
    payload: status
  });
};

export const getAllSparePartGroup = () => (dispatch) => {
  return dispatch({
    type: SPARE_PARTS_ACTIONS.GET_ALL_SPARE_PART_GROUP,
    payload: api.get(`/SparePartGroup/getAll`)
  });
};
export const actionSearchAllData = (payload) => (dispatch) => {
  return dispatch({
    type: SPARE_PARTS_ACTIONS.GET_SPARE_PARTS_PER_PAGE,
    payload: searchAllData(payload)
  });
};
