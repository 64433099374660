import { TRANSPORT_COST_REALITY_ACTIONS } from "actions/transportCostReality";
import { put, takeEvery } from "redux-saga/effects";
import { getTransportCostPerPage } from "services/transportCostReality";

function* putActionUpdateData() {
  yield put({
    type: TRANSPORT_COST_REALITY_ACTIONS.GET_TRANSPORT_COST_REALITY_PER_PAGE,
    payload: getTransportCostPerPage(
      1,
      parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE)
    ),
  });
}

export function* actionUpdateTransportCostRealityData() {
  yield takeEvery(
    [
      TRANSPORT_COST_REALITY_ACTIONS.UPDATE_TRANSPORT_COST_REALITY_FULFILLED,
      TRANSPORT_COST_REALITY_ACTIONS.CREATE_TRANSPORT_COST_REALITY_FULFILLED,
      //   TRANSPORT_COST_ACTIONS.DELETE_TRANSPORT_COST_FULFILLED,
    ],
    putActionUpdateData
  );
}
