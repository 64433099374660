import { WAREHOUSE_INFO_ACTIONS } from "actions/warehouseInfo";
import { put, takeEvery } from "redux-saga/effects";
import { getWarehouseInfoPerPage } from "services/warehouseInfo";

function* putActionUpdateData() {
  yield put({
    type: WAREHOUSE_INFO_ACTIONS.GET_WAREHOUSE_INFO_PER_PAGE,
    payload: getWarehouseInfoPerPage(
      1,
      parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE)
    ),
  });
}

export function* actionUpdateWarehouseInfoData() {
  yield takeEvery(
    [
      WAREHOUSE_INFO_ACTIONS.UPDATE_WAREHOUSE_INFO_FULFILLED,
      WAREHOUSE_INFO_ACTIONS.CREATE_WAREHOUSE_INFO_FULFILLED,
      //   WAREHOUSE_INFO_ACTIONS.DELETE_WAREHOUSE_INFO_FULFILLED,
    ],
    putActionUpdateData
  );
}
