import { WAREHOUSE_INFO_ACTIONS } from "actions/warehouseInfo";

const dataMessageExist = ["Tên cảng đã tồn tại"];

const INIT_STATE = {
  listPerPage: [],
  listAll: [],
  listPortCategory: [],
  listCustom: [],
  loading: false,
  typeMessage: "",
  message: "",
  isShowMessage: false,
  pagination: {},
  isCloseModal: false,
  isSearch: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case WAREHOUSE_INFO_ACTIONS.GET_WAREHOUSE_INFO_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case WAREHOUSE_INFO_ACTIONS.GET_WAREHOUSE_INFO_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false,
      };
    }
    case WAREHOUSE_INFO_ACTIONS.GET_WAREHOUSE_INFO_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        isCloseModal: false,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }

    case WAREHOUSE_INFO_ACTIONS.CREATE_WAREHOUSE_INFO_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
      };
    }
    case WAREHOUSE_INFO_ACTIONS.CREATE_WAREHOUSE_INFO_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: "success",
        message: "Thêm mới dữ liệu thành công !",
        isShowMessage: true,
        isCloseModal: true,
      };
    }
    case WAREHOUSE_INFO_ACTIONS.CREATE_WAREHOUSE_INFO_REJECTED:
      const messageErrorCreate = action.payload.response.data.message + "";
      let checkCreate = dataMessageExist.includes(messageErrorCreate);

      return {
        ...state,
        loading: false,
        message: checkCreate ? messageErrorCreate : "Thêm dữ liệu thất bại",
        //   message: messageErrorCreate,
        isShowMessage: true,
        typeMessage: "error",
        isCloseModal: false,
      };

    case WAREHOUSE_INFO_ACTIONS.DELETE_WAREHOUSE_INFO_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case WAREHOUSE_INFO_ACTIONS.DELETE_WAREHOUSE_INFO_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: "success",
        message: "Xóa dữ liệu thành công",
        isShowMessage: true,
      };
    }
    case WAREHOUSE_INFO_ACTIONS.DELETE_WAREHOUSE_INFO_REJECTED: {
      return {
        ...state,
        loading: false,
        message: "Không thể xóa được dữ liệu này",
        isShowMessage: true,
        typeMessage: "error",
      };
    }

    case WAREHOUSE_INFO_ACTIONS.UPDATE_WAREHOUSE_INFO_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
      };
    }
    case WAREHOUSE_INFO_ACTIONS.UPDATE_WAREHOUSE_INFO_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: "success",
        message: "Cập nhật dữ liệu thành công",
        isShowMessage: true,
        isCloseModal: true,
      };
    }
    case WAREHOUSE_INFO_ACTIONS.UPDATE_WAREHOUSE_INFO_REJECTED:
      const messageError = action.payload.response.data.message + "";
      let check = dataMessageExist.includes(messageError);

      return {
        ...state,
        loading: false,
        message: check ? messageError : "Cập nhật dữ liệu thất bại",
        isShowMessage: true,
        typeMessage: "error",
        isCloseModal: false,
      };

    case WAREHOUSE_INFO_ACTIONS.GET_PORT_CATEGORY_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPortCategory: action.payload.data.data,
      };
    }

    case WAREHOUSE_INFO_ACTIONS.GET_CUSTOM_FULFILLED: {
      return {
        ...state,
        loading: false,
        listCustom: action.payload.data.data,
      };
    }

    case "UPDATE_SHOW_ALERT": {
      return {
        ...state,
        isShowMessage: false,
      };
    }
    case WAREHOUSE_INFO_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case WAREHOUSE_INFO_ACTIONS.SEARCH_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
      };
    }
    case WAREHOUSE_INFO_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }

    case WAREHOUSE_INFO_ACTIONS.GET_ALL_WAREHOUSE_INFO_FULFILLED: {
      return {
        ...state,
        loading: false,
        listAll: action.payload.data.data,
      };
    }
    default:
      return state;
  }
};
