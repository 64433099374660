export const SCREEN_NAME_WITH_ROUTE = [
  { path: '/group-info-management', screenName: 'GroupPermission' },
  { path: '/user-management', screenName: 'User' },
  { path: '/commons/employess', screenName: 'Employees' },
  { path: '/commons/customer', screenName: 'Customer' },
  { path: '/common/customer-permission-type', screenName: 'CustomerGroup' },
  { path: '/commons/mooc', screenName: 'Romooc' },
  { path: '/commons/cars', screenName: 'Vehicle' },
  { path: '/commons/warehouse-info', screenName: 'PortDepot' },
  { path: '/commons/shipping-line', screenName: 'ShippingLine' },
  { path: '/commons/product', screenName: 'Good' },
  { path: '/commons/route-address', screenName: 'Address' },
  { path: '/commons/route', screenName: 'Route' },
  { path: '/commons/oil-norm', screenName: 'OilNorm' },
  { path: '/commons/customs-norm-cost', screenName: 'CustomsFee' },
  { path: '/commons/pay-on-behaft', screenName: 'PrePayFee' },
  { path: '/commons/transport-cost', screenName: 'TransportFee' },
  { path: '/commons/route-cost', screenName: 'RouteCost' },
  { path: '/commons/deposit-shell-management', screenName: 'ContainerFee' },
  { path: '/commons/oil-price-management', screenName: 'OilPrice' },
  { path: '/management/order-management-input', screenName: 'FullOrder' },
  { path: '/management/order-management-input/detail/:id/:name', screenName: 'CreateContainerFullOrder' },
  { path: '/management/order-management-retail/detail/:id/:name', screenName: 'CreateContainerFullOrder' },
  {
    path: '/management/order-management-input/accounting/down-payment-order/:Id/:name/:index/:instalmentId',
    screenName: 'DownPaymentOfOrder'
  },
  {
    path: '/management/order-management-retail/accounting/down-payment-order/:Id/:name/:index/:instalmentId',
    screenName: 'DownPaymentOfOrder'
  },
  { path: '/accounting/down-payment-order/:Id/:name/:index/:instalmentId', screenName: 'DownPaymentOfOrder' },
  { path: '/management/order-management-retail', screenName: 'RetailOrder' },
  { path: '/management/transportation-plan', screenName: 'ContainerOrderList' },
  { path: '/management/daily-transport-plan-management', screenName: 'DriverPlan' },
  { path: '/management/driver-plan', screenName: 'VehicleOperation' },
  { path: '/management/driver-plan-short', screenName: 'DriverPlanIncludeCost' },
  { path: '/management/notification', screenName: 'NoticeManagement' },
  { path: '/accounting/down-payment-of-order', screenName: 'SettlementOrderAccounting' },
  { path: '/accounting/down-payment-order-list/:Id/:name', screenName: 'PaymentBehaftList' },
  { path: '/accounting/settlement-of-order/:Id/:name', screenName: 'SettlementOrder' },
  {
    path: '/management/order-management-input/accounting/settlement-of-order/:Id/:name',
    screenName: 'SettlementOrder'
  },
  {
    path: '/management/order-management-retail/accounting/settlement-of-order/:Id/:name',
    screenName: 'SettlementOrder'
  },
  { path: '/accounting/settlement-customer', screenName: 'SettlementCustomerAcounting' },
  { path: '/accounting/down-payment-shell-management', screenName: 'DownPaymentShellManagement' },
  { path: '/management/annual-fees', screenName: 'AnnualFee' },
  { path: '/accounting/repair-accounting', screenName: 'RepairAcounting' },
  { path: '/accounting/advance-management', screenName: 'AdvanceManagementAccounting' },
  { path: '/management/input-fuel-management', screenName: 'FuelInputManagement' },
  { path: '/management/standard-oil', screenName: 'OilNormManagement' },
  { path: '/repair/import-accessory-management', screenName: 'ImportAccessoryManagement' },
  { path: '/repair/purchasing-and-quotation-management', screenName: 'PurchasingAndQuotation' },
  {
    path: '/repair/purchasing-and-quotation-management/quotation-detail/:id',
    screenName: 'PurchasingAndQuotation'
  },
  { path: '/management/approval-management', screenName: 'Approval' },
  { path: '/repair/repair-list', screenName: 'RepairList' },
  { path: '/repair/expiration-date', screenName: 'ExpirationDate' },
  { path: '/report/revenue-cost-follow-car', screenName: 'RevenueFollowCar' },
  { path: '/report/consignment', screenName: 'ConsignmentReport' },
  { path: '/report/settlement-customer-summary', screenName: 'PaymentCustomerReport' },
  { path: '/report/business-result', screenName: 'BusinessReport' },
  { path: '/report/customs-declaration', screenName: 'CustomDeclerationReport' },
  { path: '/report/payment-settlement', screenName: 'PaySettlementSummary' },
  { path: '/report/trucking-fee-report', screenName: 'FeeReport' },
  { path: '/report/repair', screenName: 'RepairReport' },
  { path: '/report/tire-used', screenName: 'TireReport' },
  { path: '/report/oil-exist', screenName: 'OilExistReport' },
  { path: '/driver-management/driver-income', screenName: 'DriverSalary' },
  { path: '/report/vehicle-activity-report', screenName: 'VehicleActivityReport' },
  { path: '/driver-management/advance-management', screenName: 'DriverAdvanceManagement' },
  { path: '/repair/warehouse-export', screenName: 'ExportWarehouse' }
];
