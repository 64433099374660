const { BACKUP, RESTORE, REST_DATA } = require('actions/backupRestore');

const INIT_STATE = {
  backupData: null,
  loanding: false,
  percent: 0,
  status: null,
  mess: '',
  textColor: ''
};

const backupRestore = (state = INIT_STATE, action) => {
  switch (action.type) {
    case BACKUP.BACKUP_DATA_FULFILLED:
      console.log(action?.payload);
      return {
        ...state,
        loanding: false,
        backupData: action?.payload?.data,
        status: null,
        percent: 100,
        mess: 'Sao lưu dữ liệu thành công ',
        textColor: 'success'
      };
    case BACKUP.BACKUP_DATA_PENDING:
      return {
        ...state,
        loanding: true,
        percent: 20,
        textColor: '',
        status: 'active',
        mess: '⚠️ Đang chuẩn bị dữ liệu vui lòng không chuyển trang'
      };
    case BACKUP.BACKUP_DATA_REJECTED:
      return {
        ...state,
        loanding: false,
        status: 'exception',
        mess: 'Không thể tải xuống dữ liệu vào lúc này vui lòng thử lại sau!',
        textColor: 'danger'
      };
    case RESTORE.RESTORE_DATA_FULFILLED:
      return {
        ...state,
        status: null,
        loanding: false,
        percent: 100,
        mess: 'Khôi phục dữ liệu thành công ',
        textColor: 'success'
      };
    case RESTORE.RESTORE_DATA_PENDING:
      return {
        ...state,
        loanding: true,
        percent: 68,
        textColor: '',
        status: 'active',
        mess: '⚠️ Đang chuẩn bị dữ liệu vui lòng không chuyển trang'
      };
    case RESTORE.RESTORE_DATA_REJECTED:
      return {
        ...state,
        loanding: false,
        status: 'exception',
        mess: 'Không thể khôi phục liệu vào lúc này vui lòng thử lại sau!',
        textColor: 'danger'
      };
    case REST_DATA:
      return { INIT_STATE };
    default:
      return state;
  }
};

export default backupRestore;
