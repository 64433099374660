import httpClient from 'HttpClient/index';
import {
  createFillUpFuelManagement,
  deleteFillUpFuelManagement,
  getFillUpFuelManagementByID,
  getFillUpFuelManagementPerPage,
  searchFillUpFuelManagementPerPage,
  updateFillUpFuelManagement
} from 'services/fillupfuelmanagement';

// define const actions
export const FILL_UP_FUEL_MANAGEMENT_ACTIONS = {
  GET_FILL_UP_FUEL_MANAGEMENT_PER_PAGE: 'GET_FILL_UP_FUEL_MANAGEMENT_PER_PAGE',
  GET_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_PENDING: 'GET_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_PENDING',
  GET_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_FULFILLED: 'GET_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_FULFILLED',
  GET_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_REJECTED: 'GET_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_REJECTED',

  GET_FILL_UP_FUEL_MANAGEMENT_BY_ID: 'GET_FILL_UP_FUEL_MANAGEMENT_BY_ID',
  GET_FILL_UP_FUEL_MANAGEMENT_BY_ID_PENDING: 'GET_FILL_UP_FUEL_MANAGEMENT_BY_ID_PENDING',
  GET_FILL_UP_FUEL_MANAGEMENT_BY_ID_FULFILLED: 'GET_FILL_UP_FUEL_MANAGEMENT_BY_ID_FULFILLED',
  GET_FILL_UP_FUEL_MANAGEMENT_BY_ID_REJECTED: 'GET_FILL_UP_FUEL_MANAGEMENT_BY_ID_REJECTED',

  CREATE_FILL_UP_FUEL_MANAGEMENT: 'CREATE_FILL_UP_FUEL_MANAGEMENT',
  CREATE_FILL_UP_FUEL_MANAGEMENT_PENDING: 'CREATE_FILL_UP_FUEL_MANAGEMENT_PENDING',
  CREATE_FILL_UP_FUEL_MANAGEMENT_FULFILLED: 'CREATE_FILL_UP_FUEL_MANAGEMENT_FULFILLED',
  CREATE_FILL_UP_FUEL_MANAGEMENT_REJECTED: 'CREATE_FILL_UP_FUEL_MANAGEMENT_REJECTED',

  UPDATE_FILL_UP_FUEL_MANAGEMENT: 'UPDATE_FILL_UP_FUEL_MANAGEMENT',
  UPDATE_FILL_UP_FUEL_MANAGEMENT_PENDING: 'UPDATE_FILL_UP_FUEL_MANAGEMENT_PENDING',
  UPDATE_FILL_UP_FUEL_MANAGEMENT_FULFILLED: 'UPDATE_FILL_UP_FUEL_MANAGEMENT_FULFILLED',
  UPDATE_FILL_UP_FUEL_MANAGEMENT_REJECTED: 'UPDATE_FILL_UP_FUEL_MANAGEMENT_REJECTED',

  DELETE_FILL_UP_FUEL_MANAGEMENT: 'DELETE_FILL_UP_FUEL_MANAGEMENT',
  DELETE_FILL_UP_FUEL_MANAGEMENT_PENDING: 'DELETE_FILL_UP_FUEL_MANAGEMENT_PENDING',
  DELETE_FILL_UP_FUEL_MANAGEMENT_FULFILLED: 'DELETE_FILL_UP_FUEL_MANAGEMENT_FULFILLED',
  DELETE_FILL_UP_FUEL_MANAGEMENT_REJECTED: 'DELETE_FILL_UP_FUEL_MANAGEMENT_REJECTED',

  SEARCH_FILL_UP_FUEL_MANAGEMENT_PER_PAGE: 'SEARCH_FILL_UP_FUEL_MANAGEMENT_PER_PAGE',
  SEARCH_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_PENDING: 'SEARCH_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_PENDING',
  SEARCH_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_FULFILLED: 'SEARCH_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_FULFILLED',
  SEARCH_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_REJECTED: 'SEARCH_FILL_UP_FUEL_MANAGEMENT_PER_PAGE_REJECTED',

  GET_SUMMARY_FILL_UP_FUEL_MANAGEMENT_BY_ID: 'GET_SUMMARY_FILL_UP_FUEL_MANAGEMENT_BY_ID',
  GET_SUMMARY_FILL_UP_FUEL_MANAGEMENT_BY_ID_PENDING: 'GET_SUMMARY_FILL_UP_FUEL_MANAGEMENT_BY_ID_PENDING',
  GET_SUMMARY_FILL_UP_FUEL_MANAGEMENT_BY_ID_FULFILLED: 'GET_SUMMARY_FILL_UP_FUEL_MANAGEMENT_BY_ID_FULFILLED',
  GET_SUMMARY_FILL_UP_FUEL_MANAGEMENT_BY_ID_REJECTED: 'GET_SUMMARY_FILL_UP_FUEL_MANAGEMENT_BY_ID_REJECTED',

  UPDATE_IS_PRINTED: 'UPDATE_IS_PRINTED',
  UPDATE_IS_PRINTED_PENDING: 'UPDATE_IS_PRINTED_PENDING',
  UPDATE_IS_PRINTED_FULFILLED: 'UPDATE_IS_PRINTED_FULFILLED',
  UPDATE_IS_PRINTED_REJECTED: 'UPDATE_IS_PRINTED_REJECTED',

  CLEAR_SUMMARY_FILL_UP_FUEL_MANAGEMENT_BY_ID: 'CLEAR_SUMMARY_FILL_UP_FUEL_MANAGEMENT_BY_ID',

  GET_FUE_IMAGES: 'GET_FUE_IMAGES',
  GET_FUE_IMAGES_PENDING: 'GET_FUE_IMAGES_PENDING',
  GET_FUE_IMAGES_FULFILLED: 'GET_FUE_IMAGES_FULFILLED',
  GET_FUE_IMAGES_REJECTED: 'GET_FUE_IMAGES_REJECTED'
  //   GET_ALL_FILL_UP_FUEL_MANAGEMENT: "GET_ALL_FILL_UP_FUEL_MANAGEMENT",
  //   GET_ALL_FILL_UP_FUEL_MANAGEMENT_PENDING: "GET_ALL_FILL_UP_FUEL_MANAGEMENT_PENDING",
  //   GET_ALL_FILL_UP_FUEL_MANAGEMENT_FULFILLED: "GET_ALL_FILL_UP_FUEL_MANAGEMENT_FULFILLED",
  //   GET_ALL_FILL_UP_FUEL_MANAGEMENT_REJECTED: "GET_ALL_FILL_UP_FUEL_MANAGEMENT_REJECTED",
};

// define actions
export const actionGetFillUpFuelManagementPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_FILL_UP_FUEL_MANAGEMENT_PER_PAGE,
    payload: getFillUpFuelManagementPerPage(pageNo, pageSize)
  });
};

export const actionGetFillUpFuelManagementByID = (id) => (dispatch) => {
  return dispatch({
    type: FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_FILL_UP_FUEL_MANAGEMENT_BY_ID,
    payload: getFillUpFuelManagementByID(id)
  });
};

export const actionCreateFillUpFuelManagement = (payload) => (dispatch) => {
  return dispatch({
    type: FILL_UP_FUEL_MANAGEMENT_ACTIONS.CREATE_FILL_UP_FUEL_MANAGEMENT,
    payload: createFillUpFuelManagement(payload),
    meta: {
      currentPage: payload.currentPage
    }
  });
};

export const actionUpdateFillUpFuelManagement = (payload) => (dispatch) => {
  return dispatch({
    type: FILL_UP_FUEL_MANAGEMENT_ACTIONS.UPDATE_FILL_UP_FUEL_MANAGEMENT,
    payload: updateFillUpFuelManagement(payload),
    meta: {
      currentPage: payload.currentPage
    }
  });
};

export const actionDeleteFillUpFuelManagement = (id, currentPage) => (dispatch) => {
  return dispatch({
    type: FILL_UP_FUEL_MANAGEMENT_ACTIONS.DELETE_FILL_UP_FUEL_MANAGEMENT,
    payload: deleteFillUpFuelManagement(id),
    meta: {
      currentPage: currentPage
    }
  });
};

export const actionSearchFillUpFuelManagementPerPage = (payload) => (dispatch) => {
  return dispatch({
    type: !payload.EXPORT_EXCEL_ALL ? FILL_UP_FUEL_MANAGEMENT_ACTIONS.SEARCH_FILL_UP_FUEL_MANAGEMENT_PER_PAGE : null,
    payload: searchFillUpFuelManagementPerPage(payload)
  });
};

export const getImagesFueling = (id) => (dispatch) => {
  return dispatch({
    type: FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_FUE_IMAGES,
    payload: httpClient.get(`Fueling/GetImage/` + id)
  });
};

export const actionGetSummaryFillUpFuelManagementByID = (id, fromDate, toDate) => (dispatch) => {
  return dispatch({
    type: FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_SUMMARY_FILL_UP_FUEL_MANAGEMENT_BY_ID,
    payload: httpClient.get(`/Fueling/GetSummary?tractorTrailerId=${id}&fromdate=${fromDate}&todate=${toDate}`)
  });
};

export const actionupdateIsPrinted = (payload) => (dispatch) => {
  return dispatch({
    type: FILL_UP_FUEL_MANAGEMENT_ACTIONS.UPDATE_IS_PRINTED,
    payload: httpClient.put(`/Fueling/updateIsPrinted`, payload)
  });
};

export const actionClearSummaryFillUpFuelManagementByID = () => (dispatch) => {
  return dispatch({
    type: FILL_UP_FUEL_MANAGEMENT_ACTIONS.CLEAR_SUMMARY_FILL_UP_FUEL_MANAGEMENT_BY_ID
  });
};
