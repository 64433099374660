import { isNull, isNullOrEmpty } from "./utils";

export function getErrorMessage(action, defaultMessage) {
  let message = defaultMessage ? defaultMessage : "";
  if (
    isNull(action.payload.response) ||
    isNullOrEmpty(action.payload.response.data)
  ) {
    message = "Lỗi, không tìm thấy kết nối mạng đến máy chủ.";
  } else if (action.payload.response.data.message) {
    message = action.payload.response.data.message;
    if (action.payload.response.data.exception) {
      message += ": " + action.payload.response.data.exception;
    }
  } else if (action.payload.response.data.title) {
    message = action.payload.response.data.title;
  }else if(action.payload.response.data){
    message = action.payload.response.data;
  }
  return message;
}
