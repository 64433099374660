import api from 'HttpClient';

// const type of function get data per page
export const FETCH_REPORTS_BUSSINESS_RESULT = 'FETCH_REPORTS_BUSSINESS_RESULT';
export const FETCH_REPORTS_BUSSINESS_RESULT_PENDING = 'FETCH_REPORTS_BUSSINESS_RESULT_PENDING';
export const FETCH_REPORTS_BUSSINESS_RESULT_FULFILLED = 'FETCH_REPORTS_BUSSINESS_RESULT_FULFILLED';
export const FETCH_REPORTS_BUSSINESS_RESULT_REJECTED = 'FETCH_REPORTS_BUSSINESS_RESULT_REJECTED';
export const fetchBusinessResultReports =
  (reportUrl, params, ...extraParams) =>
  (dispatch) => {
    if (extraParams.length > 0) {
      const searchParams = extraParams[0];
      params = { ...params, ...searchParams };
    }
    return dispatch({
      type: FETCH_REPORTS_BUSSINESS_RESULT,
      payload: api.get(`${reportUrl}`, {
        params: params
      })
    });
  };
export const fetchBusinessResultReportsAll = (params) => (dispatch) => {
  return dispatch({
    // type: FETCH_REPORTS_BUSSINESS_RESULT,
    // payload: api.get(`${reportUrl}`, { params: params })
    payload: api.get(`/ReportRevenue/getByMonth`, { params: params })
  });
};

export const RESET_REPORT_BUSINESS_RESULT = 'RESET_REPORT_BUSINESS_RESULT';
export const resetBusinessResultReports = () => (dispatch) => {
  return dispatch({
    type: RESET_REPORT_BUSINESS_RESULT
  });
};

export const SET_REPORT_BUSSINESS_RESULT_SEARCH_PARAMS = 'SET_REPORT_BUSSINESS_RESULT_SEARCH_PARAMS';
export const setBusinessResultReportsSearchParams = (search) => (dispatch) => {
  return dispatch({
    type: SET_REPORT_BUSSINESS_RESULT_SEARCH_PARAMS,
    payload: search
  });
};

export const ADD_ROW_REPORT_INLAND_REVUNUE = 'ADD_ROW_REPORT_INLAND_REVUNUE';
export const addRowInlanRevunueReport = () => (dispatch) => {
  return dispatch({
    type: ADD_ROW_REPORT_INLAND_REVUNUE
  });
};

export const HANDEL_CHANGE_VALUE_INPUT = 'HANDEL_CHANGE_VALUE_INPUT';
export const handelChangeValueInput = (value) => (dispatch) => {
  return dispatch({
    type: HANDEL_CHANGE_VALUE_INPUT,
    payload: value
  });
};

export const GET_MINING_PARENTS = 'GET_MINING_PARENTS';
export const GET_MINING_PARENTS_PENDING = 'GET_MINING_PARENTS_PENDING';
export const GET_MINING_PARENTS_FULFILLED = 'GET_MINING_PARENTS_FULFILLED';
export const GET_MINING_PARENTS_REJECTED = 'GET_MINING_PARENTS_REJECTED';
export const getMiningParents = (id) => (dispatch) => {
  return dispatch({
    type: GET_MINING_PARENTS,
    payload: api.get(`/ReportMining/GetById/${id}`)
  });
};

export const GET_MINING_CHILDS = 'GET_MINING_CHILDS';
export const GET_MINING_CHILDS_PENDING = 'GET_MINING_CHILDS_PENDING';
export const GET_MINING_CHILDS_FULFILLED = 'GET_MINING_CHILDS_FULFILLED';
export const GET_MINING_CHILDS_REJECTED = 'GET_MINING_CHILDS_REJECTED';
export const getMiningChilds = (id) => (dispatch) => {
  return dispatch({
    type: GET_MINING_CHILDS,
    payload: api.get(`/ReportMining/GetByParentId?parentId=${id}`)
  });
};

export const DELETE_ITEM_REPORT_RESULT = 'DELETE_ITEM_REPORT_RESULT';
export const DELETE_ITEM_REPORT_RESULT_PENDING = 'DELETE_ITEM_REPORT_RESULT_PENDING';
export const DELETE_ITEM_REPORT_RESULT_FULFILLED = 'DELETE_ITEM_REPORT_RESULT_FULFILLED';
export const DELETE_ITEM_REPORT_RESULT_REJECTED = 'DELETE_ITEM_REPORT_RESULT_REJECTED';
export const deleteItemReportResult = (url, id) => (dispatch) => {
  const endPoint = `${url}${id}`;
  return dispatch({
    type: DELETE_ITEM_REPORT_RESULT,
    payload: api.delete(endPoint)
  });
};

export const CREATE_DATA_BUSINEES_REPORT = 'CREATE_DATA_BUSINEES_REPORT';
export const CREATE_DATA_BUSINEES_REPORT_PENDING = 'CREATE_DATA_BUSINEES_REPORT_PENDING';
export const CREATE_DATA_BUSINEES_REPORT_FULFILLED = 'CREATE_DATA_BUSINEES_REPORT_FULFILLED';
export const CREATE_DATA_BUSINEES_REPORT_REJECTED = 'CREATE_DATA_BUSINEES_REPORT_REJECTED';
export const createDataBusineesReport = (url, payload) => (dispatch) => {
  return dispatch({
    type: CREATE_DATA_BUSINEES_REPORT,
    payload: api.post(url, payload)
  });
};

export const UPDATE_DATA_BUSINEES_REPORT = 'UPDATE_DATA_BUSINEES_REPORT';
export const UPDATE_DATA_BUSINEES_REPORT_PENDING = 'UPDATE_DATA_BUSINEES_REPORT_PENDING';
export const UPDATE_DATA_BUSINEES_REPORT_FULFILLED = 'UPDATE_DATA_BUSINEES_REPORT_FULFILLED';
export const UPDATE_DATA_BUSINEES_REPORT_REJECTED = 'UPDATE_DATA_BUSINEES_REPORT_REJECTED';
export const updateDataBusineesReport = (url, payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_DATA_BUSINEES_REPORT,
    payload: api.put(url, payload)
  });
};

export const REMOVE_ROW_REPORT_INLAND_REVUNUE = 'REMOVE_ROW_REPORT_INLAND_REVUNUE';
export const removeRowInlanRevunueReport = (index) => (dispatch) => {
  return dispatch({
    type: REMOVE_ROW_REPORT_INLAND_REVUNUE,
    payload: index
  });
};

export const ADD_ROW_REPORT_MINING_YARD_REVUNUE = 'ADD_ROW_REPORT_MINING_YARD_REVUNUE';
export const addRowInlanMiningYardReport = (parentId) => (dispatch) => {
  return dispatch({
    type: ADD_ROW_REPORT_MINING_YARD_REVUNUE,
    payload: parentId
  });
};

export const REMOVE_ROW_REPORT_MINING_REVUNUE = 'REMOVE_ROW_REPORT_MINING_REVUNUE';
export const removeRowMiningRevunueReport = (index, parentId) => (dispatch) => {
  return dispatch({
    type: REMOVE_ROW_REPORT_MINING_REVUNUE,
    payload: { index: index, parentId: parentId }
  });
};
