import { TRANSPORTATION_PLAN_ACTIONS } from 'actions/transportationplan';

const transportationPlanReducer = (state = {}, action) => {
  switch (action.type) {
    // get transportation plan per page
    case TRANSPORTATION_PLAN_ACTIONS.GET_TRANSPORTATION_PLAN_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRANSPORTATION_PLAN_ACTIONS.GET_TRANSPORTATION_PLAN_PER_PAGE_FULFILLED:
      let transportationPlan = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        transportationPlan: transportationPlan,
        isLoading: false
      };

    case TRANSPORTATION_PLAN_ACTIONS.GET_TRANSPORTATION_PLAN_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // get transportation list by consignment per page
    case TRANSPORTATION_PLAN_ACTIONS.GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRANSPORTATION_PLAN_ACTIONS.GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_PER_PAGE_FULFILLED:
      let contsDetail = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        contsDetail: contsDetail,
        isLoading: false
      };

    case TRANSPORTATION_PLAN_ACTIONS.GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    case TRANSPORTATION_PLAN_ACTIONS.GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRANSPORTATION_PLAN_ACTIONS.GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_FULFILLED:
      return {
        ...state,
        containers: action.payload?.data?.data ?? [],
        isLoading: false
      };

    case TRANSPORTATION_PLAN_ACTIONS.GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // create transportation plan
    case TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Thêm mới dữ liệu thành công'
      };

    case TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_REJECTED:
      return {
        ...state,
        isLoading: true,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // create transportation plan list
    case TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_LIST_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_LIST_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Thêm mới dữ liệu thành công'
      };

    case TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_LIST_REJECTED:
      return {
        ...state,
        isLoading: true,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // update transportation plan
    case TRANSPORTATION_PLAN_ACTIONS.UPDATE_TRANSPORTATION_PLAN_LIST_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case TRANSPORTATION_PLAN_ACTIONS.UPDATE_TRANSPORTATION_PLAN_LIST_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Thêm mới dữ liệu thành công'
      };

    case TRANSPORTATION_PLAN_ACTIONS.UPDATE_TRANSPORTATION_PLAN_LIST_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // delete transportation plan
    case TRANSPORTATION_PLAN_ACTIONS.DELETE_TRANSPORTATION_PLAN_LIST_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case TRANSPORTATION_PLAN_ACTIONS.DELETE_TRANSPORTATION_PLAN_LIST_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Thành công'
      };

    case TRANSPORTATION_PLAN_ACTIONS.DELETE_TRANSPORTATION_PLAN_LIST_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: action?.payload?.response?.data?.message || 'Đã xảy ra lỗi'
      };
    case TRANSPORTATION_PLAN_ACTIONS.SEARCH_TRANSPORTATION_PLAN_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRANSPORTATION_PLAN_ACTIONS.SEARCH_TRANSPORTATION_PLAN_PER_PAGE_FULFILLED:
      let transportationPlanSearch = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        transportationPlan: transportationPlanSearch,
        isLoading: false
      };

    case TRANSPORTATION_PLAN_ACTIONS.SEARCH_TRANSPORTATION_PLAN_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // update cont
    case TRANSPORTATION_PLAN_ACTIONS.UPDATE_CONT_DETAIL_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case TRANSPORTATION_PLAN_ACTIONS.UPDATE_CONT_DETAIL_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Cập nhật dữ liệu thành công'
      };

    case TRANSPORTATION_PLAN_ACTIONS.UPDATE_CONT_DETAIL_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };
    // get image
    case TRANSPORTATION_PLAN_ACTIONS.GET_IMAGE_CONSIGNMENT_BY_ID_PENDING:
      return {
        ...state,
        isLoadingImage: true,
        isMsg: false
      };
    case TRANSPORTATION_PLAN_ACTIONS.GET_ALL_TRANSPORTATION_PLAN_FULFILLED:
      return {
        ...state,
        isLoadingImage: false,
        isMsg: false,
        images: action.payload.data.data
      };
    case TRANSPORTATION_PLAN_ACTIONS.GET_CONT_SERIAL_BY_CONSIGNMENTID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRANSPORTATION_PLAN_ACTIONS.GET_CONT_SERIAL_BY_CONSIGNMENTID_FULFILLED:
      let data = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        contsDetail: data,
        isLoading: false
      };

    case TRANSPORTATION_PLAN_ACTIONS.GET_CONT_SERIAL_BY_CONSIGNMENTID_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };
    default:
      return state;
  }
};

export default transportationPlanReducer;
