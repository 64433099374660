import { OIL_PRICES_MANAGEMENT_ACTIONS } from 'actions/oilpricesmanagement';
import { put, takeEvery } from 'redux-saga/effects';
import { getOilPricesManagementPerPage } from 'services/oilpricesmanagement';

function* putActionUpdateData() {
  yield put({
    type: OIL_PRICES_MANAGEMENT_ACTIONS.GET_OIL_PRICES_MANAGEMENT_PER_PAGE,
    payload: getOilPricesManagementPerPage(1, parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE))
  });
}

export function* actionUpdateOilPricesManagementData() {
  yield takeEvery(
    [
      // OIL_PRICES_MANAGEMENT_ACTIONS.UPDATE_OIL_PRICES_MANAGEMENT_FULFILLED,
      // OIL_PRICES_MANAGEMENT_ACTIONS.CREATE_OIL_PRICES_MANAGEMENT_FULFILLED,
      // OIL_PRICES_MANAGEMENT_ACTIONS.DELETE_OIL_PRICES_MANAGEMENT_FULFILLED,
    ],
    putActionUpdateData
  );
}
