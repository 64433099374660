import { CUSTOM_SERVICE_ACTIONS } from 'actions/CustomerServiceAction';

const initialState = {
  data: [],
  pagination: [],
  loading: false,
  customerInfor: [],
  routerInfor: [],
  truckingTypeInfo: [],
  consimentGoodsType: [],
  unit: [],
  standardFee: [],
  DeclarationFlow: []
};

const CustomerServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOM_SERVICE_ACTIONS.GET_CUSTOMER_INFO_SERVICE_PENDING: {
      return { ...state, loading: true };
    }
    case CUSTOM_SERVICE_ACTIONS.GET_CUSTOM_SERVICE_PENDING: {
      return { ...state, loading: true };
    }
    case CUSTOM_SERVICE_ACTIONS.GET_CUSTOM_SERVICE_FULFILLED: {
      return { ...state, data: action.payload.data.data, pagination: action.payload.data.pagination, loading: false };
    }
    case CUSTOM_SERVICE_ACTIONS.GET_CUSTOM_SERVICE_REJECTED: {
      return { ...state, loading: false };
    }
    case CUSTOM_SERVICE_ACTIONS.GET_CUSTOMER_INFO_SERVICE_FULFILLED: {
      return { ...state, customerInfor: action.payload.data.Result };
    }
    case CUSTOM_SERVICE_ACTIONS.GET_ROUTER_INFO_SERVICE_FULFILLED: {
      return { ...state, routerInfor: action.payload.data.data };
    }
    case CUSTOM_SERVICE_ACTIONS.GET_TRUCKING_TYPE_INFO_SERVICE_FULFILLED: {
      return { ...state, truckingTypeInfo: action.payload.data.data };
    }
    case CUSTOM_SERVICE_ACTIONS.GET_CONSIMENT_GOODS_TYPE_INFO_SERVICE_FULFILLED: {
      return { ...state, consimentGoodsType: action.payload.data.data };
    }
    case CUSTOM_SERVICE_ACTIONS.GET_UNIT_SERVICE_FULFILLED: {
      return { ...state, unit: action.payload.data.data };
    }
    case CUSTOM_SERVICE_ACTIONS.GET_STANDARD_FEE_SERVICE_FULFILLED: {
      return { ...state, standardFee: action.payload.data.data };
    }
    case CUSTOM_SERVICE_ACTIONS.DECLARATION_FLOW_FULFILLED: {
      return {
        ...state,
        DeclarationFlow: action?.payload?.data?.data?.map((item) => {
          return { value: item.Id, label: item.Name };
        })
      };
    }
    default:
      return { ...state };
  }
};
export default CustomerServiceReducer;
