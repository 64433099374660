import React from 'react';

// const HomePageMobile = React.lazy(() => import('./Pages/MobileWeb/Page/HomeScreen'));
const HomePageMobile = React.lazy(() => import('./Pages/MobileWeb/Page/HomePage'));
const DriverPlanMobile = React.lazy(() => import('./Pages/MobileWeb/Page/DriverPlanMobile'));
const RepairMobile = React.lazy(() => import('./Pages/MobileWeb/Page/RepairMobile'));
const DetailDriverPlan = React.lazy(() => import('./Pages/MobileWeb/Page/DriverPlanMobile/DetailDriverPlan/index.js'));
const DetailScreen = React.lazy(() => import('./Pages/MobileWeb/Page/DetailScreen'));
const ContentFee = React.lazy(() => import('./Pages/MobileWeb/Page/FeeScreen'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/mobile',
    exact: true,
    name: 'Home Web Mobile',
    component: HomePageMobile
  },
  {
    path: '/mobile/driver-plan',
    exact: true,
    name: 'Điều xe Mobile',
    component: DriverPlanMobile
  },
  {
    path: '/mobile/repair',
    exact: true,
    name: 'Sửa xe Mobile',
    component: RepairMobile
  },
  {
    path: '/mobile/driver-plan/:Id',
    exact: true,
    name: 'Điều xe Mobile',
    component: DetailDriverPlan
  },
  {
    path: '/mobile/detail/:id/:transportCode',
    exact: true,
    name: 'DetailScreen',
    component: DetailScreen
  },
  {
    path: '/mobile/fee/:transportId/:transportCode',
    exact: true,
    name: 'FeeScreen',
    component: ContentFee
  }
];

export default routes;
