import {
  CREATE_DRIVER_PLAN_COST_FULFILLED,
  CREATE_DRIVER_PLAN_COST_PENDING,
  CREATE_DRIVER_PLAN_COST_REJECTED,
  FETCH_DISPATCHED_DRIVER_PLANS_FULFILLED,
  FETCH_DISPATCHED_DRIVER_PLANS_PENDING,
  FETCH_DISPATCHED_DRIVER_PLANS_REJECTED,
  SEARCH_DRIVER_PLAN_COST_PER_PAGE_FULFILLED,
  SEARCH_DRIVER_PLAN_COST_PER_PAGE_PENDING,
  SEARCH_DRIVER_PLAN_COST_PER_PAGE_REJECTED,
  SET_CLOSE_ADD_CAR_MODAL,
  SET_CLOSE_DRIVER_PLAN_COST_ADDING_MODAL,
  SET_CLOSE_DRIVER_PLAN_COST_NORMAL_MODAL,
  SET_CLOSE_DRIVER_PLAN_UPDATE_MODAL,
  SET_CLOSE_TEMPLATE_MODAL,
  SET_OPEN_ADD_CAR_MODAL,
  SET_OPEN_DRIVER_PLAN_COST_ADDING_MODAL,
  SET_OPEN_DRIVER_PLAN_COST_NORMAL_MODAL,
  SET_OPEN_DRIVER_PLAN_UPDATE_MODAL,
  SET_OPEN_TEMPLATE_MODAL,
  UPDATE_DRIVER_PLAN_COST_FULFILLED,
  UPDATE_DRIVER_PLAN_COST_PENDING,
  UPDATE_DRIVER_PLAN_COST_REJECTED
} from 'actions/DriverPlanCostAction';
import { getErrorMessage } from 'commons/ReducerUtils';
import {
  DISPATCH_CAR_SCR,
  MSG_ADD_SUCCESS,
  MSG_EDIT_FAILURE,
  MSG_EDIT_SUCCESS,
  MSG_PAGING_FAILURE,
  MSG_TYPE_ERROR,
  MSG_TYPE_SUCCESS
} from 'const/common';
const INIT_STATE = {
  driverPlans: [],
  pagination: { pageNo: 1, pageSize: 100, totalRecord: 0 },
  driverPlansLoading: false,
  createDriverPlanLoading: false,
  fetchDispathedDriverPlansLoading: false,
  showMessage: false,
  alertMessage: '',
  // update modal
  isOpenUpdateModal: false, // Show/hidden update modal
  updatingDriverPlan: {}, // Default data for update modal
  // adding modal
  isOpenAddDriverCostModal: false, //Show/hidden modal to add driver plan with adding cost, norm in one screen
  addingDriverPlan: {}, // Default data for adding modal
  // add car
  isOpenAddCarModal: false,
  addCarData: { fromScreen: DISPATCH_CAR_SCR.DIEU_1_XE, transportId: null },
  // add template
  isOpenTemplateModal: false,
  templateData: {},
  // Danh sach ma chuyen xe ghep
  dispatchedDriverPlans: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //update modal
    case SET_OPEN_DRIVER_PLAN_UPDATE_MODAL: {
      return {
        ...state,
        isOpenUpdateModal: true,
        updatingDriverPlan: action.payload
      };
    }
    case SET_CLOSE_DRIVER_PLAN_UPDATE_MODAL: {
      return {
        ...state,
        isOpenUpdateModal: false,
        updatingDriverPlan: INIT_STATE.updatingDriverPlan
      };
    }
    // adding driver plan cost modal
    case SET_OPEN_DRIVER_PLAN_COST_ADDING_MODAL: {
      return {
        ...state,
        isOpenAddDriverCostModal: true,
        addingDriverPlan: action.payload
      };
    }
    case SET_CLOSE_DRIVER_PLAN_COST_ADDING_MODAL: {
      return {
        ...state,
        isOpenAddDriverCostModal: false,
        addingDriverPlan: INIT_STATE.addingDriverPlan
      };
    }
    // adding car modal
    case SET_OPEN_ADD_CAR_MODAL: {
      return {
        ...state,
        isOpenAddCarModal: true,
        addCarData: action.payload
      };
    }
    case SET_CLOSE_ADD_CAR_MODAL: {
      return {
        ...state,
        isOpenAddCarModal: false,
        addCarData: INIT_STATE.addCarData
      };
    }
    // adding template modal
    case SET_OPEN_TEMPLATE_MODAL: {
      return {
        ...state,
        isOpenTemplateModal: true,
        templateData: action.payload
      };
    }
    case SET_CLOSE_TEMPLATE_MODAL: {
      return {
        ...state,
        isOpenTemplateModal: false,
        templateData: INIT_STATE.templateData
      };
    }

    // get data
    case SEARCH_DRIVER_PLAN_COST_PER_PAGE_PENDING: {
      return {
        ...state,
        driverPlansLoading: true,
        showMessage: false
      };
    }
    case SEARCH_DRIVER_PLAN_COST_PER_PAGE_FULFILLED: {
      return {
        ...state,
        driverPlansLoading: false,
        driverPlans: action.payload.data.data,
        pagination: action.payload.data.pagination ? action.payload.data.pagination : INIT_STATE.pagination,
        showMessage: false
      };
    }
    case SEARCH_DRIVER_PLAN_COST_PER_PAGE_REJECTED: {
      return {
        ...state,
        driverPlansLoading: false,
        showMessage: true,
        alertMessage: getErrorMessage(action, MSG_PAGING_FAILURE),
        messageType: MSG_TYPE_ERROR
      };
    }

    // get data
    case CREATE_DRIVER_PLAN_COST_PENDING: {
      return {
        ...state,
        createDriverPlanLoading: true,
        showMessage: false
      };
    }
    case CREATE_DRIVER_PLAN_COST_FULFILLED: {
      return {
        ...state,
        createDriverPlanLoading: false,
        alertMessage: MSG_ADD_SUCCESS,
        messageType: MSG_TYPE_SUCCESS,
        showMessage: true
      };
    }
    case CREATE_DRIVER_PLAN_COST_REJECTED: {
      return {
        ...state,
        createDriverPlanLoading: false,
        showMessage: true,
        alertMessage: getErrorMessage(action, MSG_PAGING_FAILURE),
        messageType: MSG_TYPE_ERROR
      };
    }

    // get data
    case FETCH_DISPATCHED_DRIVER_PLANS_PENDING: {
      return {
        ...state,
        fetchDispathedDriverPlansLoading: true,
        showMessage: false
      };
    }
    case FETCH_DISPATCHED_DRIVER_PLANS_FULFILLED: {
      const driverPlans = action.payload.data.data;

      // const dispatchedDriverPlan = driverPlans.filter(
      //   (driverPlan) => driverPlan.TrunckingStatusId === DRIVER_PLAN_STATUSES.NOT_TRANSPORT
      // );
      return {
        ...state,
        fetchDispathedDriverPlansLoading: false,
        dispatchedDriverPlans: driverPlans,
        showMessage: false
      };
    }
    case FETCH_DISPATCHED_DRIVER_PLANS_REJECTED: {
      return {
        ...state,
        fetchDispathedDriverPlansLoading: false,
        showMessage: true,
        alertMessage: getErrorMessage(action, MSG_PAGING_FAILURE),
        messageType: MSG_TYPE_ERROR
      };
    }

    case UPDATE_DRIVER_PLAN_COST_PENDING: {
      return {
        ...state,
        createDriverPlanLoading: true,
        showMessage: false
      };
    }
    case UPDATE_DRIVER_PLAN_COST_FULFILLED: {
      return {
        ...state,
        createDriverPlanLoading: false,
        alertMessage: MSG_ADD_SUCCESS,
        messageType: MSG_TYPE_SUCCESS,
        showMessage: true
      };
    }
    case UPDATE_DRIVER_PLAN_COST_REJECTED: {
      return {
        ...state,
        createDriverPlanLoading: false,
        showMessage: true,
        alertMessage: getErrorMessage(action, MSG_PAGING_FAILURE),
        messageType: MSG_TYPE_ERROR
      };
    }

    //open normal driverplan form
    case SET_OPEN_DRIVER_PLAN_COST_NORMAL_MODAL: {
      return {
        ...state,
        isOpenAddDriverNormalModal: true,
        normalDriverPlan: action.payload
      };
    }

    case SET_CLOSE_DRIVER_PLAN_COST_NORMAL_MODAL: {
      return {
        ...state,
        isOpenAddDriverNormalModal: false,
        normalDriverPlan: {}
      };
    }

    default:
      return state;
  }
};
