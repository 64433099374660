import api from 'HttpClient';

export const SET_OPEN_QUOTATION_MODAL = 'SET_OPEN_QUOTATION_MODAL';
export const setOpenQuotationModal = (data, typeForm) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_QUOTATION_MODAL,
    payload: data,
    meta: {
      typeForm
    }
  });
};

export const SET_CLOSE_QUOTATION_MODAL = 'SET_CLOSE_QUOTATION_MODAL';
export const setCloseQuotationModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_QUOTATION_MODAL
  });
};

export const FETCH_ALL_QUOTATIONS = 'FETCH_ALL_QUOTATIONS';
export const FETCH_ALL_QUOTATIONS_PENDING = 'FETCH_ALL_QUOTATIONS_PENDING';
export const FETCH_ALL_QUOTATIONS_FULFILLED = 'FETCH_ALL_QUOTATIONS_FULFILLED';
export const FETCH_ALL_QUOTATIONS_REJECTED = 'FETCH_ALL_QUOTATIONS_REJECTED';
export const fetchAllQuotations = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_QUOTATIONS,
    payload: api.get(`/QuotationInfo/getAll`)
  });
};

// const type of function get data per page
export const FETCH_QUOTATIONS = 'FETCH_QUOTATIONS';
export const FETCH_QUOTATIONS_PENDING = 'FETCH_QUOTATIONS_PENDING';
export const FETCH_QUOTATIONS_FULFILLED = 'FETCH_QUOTATIONS_FULFILLED';
export const FETCH_QUOTATIONS_REJECTED = 'FETCH_QUOTATIONS_REJECTED';
export const fetchQuotations = (searchPayload, page, pageSize) => (dispatch) => {
  return dispatch({
    type: FETCH_QUOTATIONS,
    payload: api.get(`/QuotationInfo/Search`, { params: { ...searchPayload, page, pageSize: pageSize } })
  });
};
export const fetchQuotationsAll = (params) => (dispatch) => {
  return dispatch({
    // type: FETCH_QUOTATIONS,
    payload: api.get(`/QuotationInfo/Search`, { params: { ...params } })
  });
};

// const type of function create data
export const CREATE_QUOTATION = 'CREATE_QUOTATION';
export const CREATE_QUOTATION_PENDING = 'CREATE_QUOTATION_PENDING';
export const CREATE_QUOTATION_FULFILLED = 'CREATE_QUOTATION_FULFILLED';
export const CREATE_QUOTATION_REJECTED = 'CREATE_QUOTATION_REJECTED';
export const createQuotation = (payload) => (dispatch) => {
  return dispatch({
    type: CREATE_QUOTATION,
    payload: api.post(`/QuotationInfo/create`, payload)
  });
};

// const type of function update data
export const UPDATE_QUOTATION = 'UPDATE_QUOTATION';
export const UPDATE_QUOTATION_PENDING = 'UPDATE_QUOTATION_PENDING';
export const UPDATE_QUOTATION_FULFILLED = 'UPDATE_QUOTATION_FULFILLED';
export const UPDATE_QUOTATION_REJECTED = 'UPDATE_QUOTATION_REJECTED';
export const updateQuotation = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_QUOTATION,
    payload: api.put(`/QuotationInfo/update`, payload),
    meta: payload
  });
};
// const type of get deatail
export const FETCH_QUOTATION_DETAIL = 'FETCH_QUOTATION_DETAIL';
export const FETCH_QUOTATION_DETAIL_PENDING = 'FETCH_QUOTATION_DETAIL_PENDING';
export const FETCH_QUOTATION_DETAIL_FULFILLED = 'FETCH_QUOTATION_DETAIL_FULFILLED';
export const FETCH_QUOTATION_DETAIL_REJECTED = 'FETCH_QUOTATION_DETAIL_REJECTED';
export const getQuotationDetail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_QUOTATION_DETAIL,
    payload: api.get(`/QuotationInfo/${id}`)
  });
};

// const type of delete
export const DELETE_QUOTATION = 'DELETE_QUOTATION';
export const DELETE_QUOTATION_PENDING = 'DELETE_QUOTATION_PENDING';
export const DELETE_QUOTATION_FULFILLED = 'DELETE_QUOTATION_FULFILLED';
export const DELETE_QUOTATION_REJECTED = 'DELETE_QUOTATION_REJECTED';
export const deleteQuotation = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_QUOTATION,
    payload: api.delete(`/QuotationInfo/deleteById/${id}`)
  });
};

// const type of get by id
export const FETCH_QUOTATION_BY_ID = 'FETCH_QUOTATION_BY_ID';
export const FETCH_QUOTATION_BY_ID_PENDING = 'FETCH_QUOTATION_BY_ID_PENDING';
export const FETCH_QUOTATION_BY_ID_FULFILLED = 'FETCH_QUOTATION_BY_ID_FULFILLED';
export const FETCH_QUOTATION_BY_ID_REJECTED = 'FETCH_QUOTATION_BY_ID_REJECTED';
export const getQuotationById = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_QUOTATION_BY_ID,
    payload: api.get(`/QuotationInfo/getById/${id}`)
  });
};

export const getQuotationByIdV2 = (id) => (dispatch) => {
  return dispatch({
    payload: api.get(`/QuotationInfo/getById/${id}`)
  });
};

export const DELETE_FILE_QUOTATION = 'DELETE_FILE_QUOTATION';
export const DELETE_FILE_QUOTATION_PENDING = 'DELETE_FILE_QUOTATION_PENDING';
export const DELETE_FILE_QUOTATION_FULFILLED = 'DELETE_FILE_QUOTATION_FULFILLED';
export const DELETE_FILE_QUOTATION_REJECTED = 'DELETE_FILE_QUOTATION_REJECTED';
export const actionDeleteFileQuotation = (fileId) => (dispatch) => {
  return dispatch({
    type: DELETE_FILE_QUOTATION,
    payload: api.delete(`/QuotationInfo/deleteQuotatioResource/${fileId}`)
  });
};
