import { FILL_UP_FUEL_MANAGEMENT_ACTIONS } from "actions/fillupfuelmanagement";
import { PAGE_SIZE } from "const/common";
import { put, takeEvery } from "redux-saga/effects";
import { getFillUpFuelManagementPerPage } from "services/fillupfuelmanagement";

function* putActionUpdateData(actions) {
    yield put({
        type: FILL_UP_FUEL_MANAGEMENT_ACTIONS.GET_FILL_UP_FUEL_MANAGEMENT_PER_PAGE,
        payload: getFillUpFuelManagementPerPage(actions.meta.currentPage, PAGE_SIZE)
    });
}

export function* actionUpdateFullUpFuelManagementData() {
    yield takeEvery(
        [
            // FILL_UP_FUEL_MANAGEMENT_ACTIONS.UPDATE_FILL_UP_FUEL_MANAGEMENT_FULFILLED,
            // FILL_UP_FUEL_MANAGEMENT_ACTIONS.CREATE_FILL_UP_FUEL_MANAGEMENT_FULFILLED,
            // FILL_UP_FUEL_MANAGEMENT_ACTIONS.DELETE_FILL_UP_FUEL_MANAGEMENT_FULFILLED
        ],
        putActionUpdateData
    );
}