import {
  ROUTER_ACTIONS
} from "actions/router";
import { DEFAULT_PAGE_SIZE } from "const/common";
import {
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  getRoutersPerPage,
} from "services/router";

function* putActionUpdateData(action) {
  
  yield put({
    type: ROUTER_ACTIONS.GET_ROUTERS_PER_PAGE,
    payload: getRoutersPerPage(1, DEFAULT_PAGE_SIZE)
  })
}

export function* actionUpdateRoutersData() {
  yield takeEvery([ROUTER_ACTIONS.UPDATE_ROUTERS_FULFILLED, ROUTER_ACTIONS.CREATE_ROUTERS_FULFILLED, ROUTER_ACTIONS.DELETE_ROUTERS_FULFILLED], putActionUpdateData);
}
