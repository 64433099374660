import api from "HttpClient/index";

export const uploadFile = file => {
    const formData = new FormData();
    const config = {
        headers: {
            "content-type": "multipart/form-data"
        }
    };
    formData.append("excelFile", file);
    return api.post(`/File/readContsFromExcel`, formData, config);
};