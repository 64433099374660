import httpClient from "HttpClient";

export const getShippingLinePerPage = (pageNo, pageSize) => {
    return httpClient.get(
        `/ShippingLineInfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`
    );
};

export const deleteItem = id => {
    return httpClient.delete(`/ShippingLineInfo/delete/${id}`);
};