let whitelist = ['id', 'tagName', 'className', 'childNodes', 'style'];
function domToObj(domEl) {
  var obj = {};
  for (let i = 0; i < whitelist.length; i++) {
    if (domEl[whitelist[i]] instanceof NodeList) {
      obj[whitelist[i]] = Array.from(domEl[whitelist[i]]);
    } else {
      if (whitelist[i] === 'style') {
        obj.style = domEl?.style?.cssText ?? {};
      } else {
        obj[whitelist[i]] = domEl[whitelist[i]];
      }
    }
  }
  return obj;
}

export function stringifyDOM(container) {
  return JSON.stringify(container, function (name, value) {
    if (name === '') {
      return domToObj(value);
    }
    if (Array.isArray(this)) {
      if (typeof value === 'object') {
        return domToObj(value);
      }
      return value;
    }
    if (whitelist.find((x) => x === name)) return value;
  });
}

export function getRowsElementNode(nodes, indexStart) {
  const tbodyElement = nodes[1];
  const rows = tbodyElement.childNodes?.map((el, i) => {
    return { ...el, index: indexStart + 1 + i };
  });
  return rows;
}

const html = `<table cellspacing="0" cellpadding="0" class="signature-config" style="border:none;width:100%">
<tbody>
<tr>
<td style="text-align: center;border:none;"><strong>Người lập phiếu</strong></td>
<td style="text-align: center;border:none;"><strong>Người nhận tiền</strong></td>
<td style="text-align: center;border:none;"><strong>Kế toán</strong></td>
<td style="text-align: center;border:none;"><strong>Thủ quỹ</strong></td>
<td style="text-align: center;border:none;"><strong>Giám đốc</strong></td>
</tr>
<tr>
<td style="text-align: center;border:none;"><em>(K&yacute; ghi r&otilde; họ t&ecirc;n)</em></td>
<td style="text-align: center;border:none;"><em>(K&yacute; ghi r&otilde; họ t&ecirc;n)</em></td>
<td style="text-align: center;border:none;"><em>(K&yacute; ghi r&otilde; họ t&ecirc;n)</em></td>
<td style="text-align: center;border:none;"><em>(K&yacute; ghi r&otilde; họ t&ecirc;n)</em></td>
<td style="text-align: center;border:none;"><em>(K&yacute; ghi r&otilde; họ t&ecirc;n)</em></td>
</tr>
<tr style="margin-top: 15px;">
<td style="text-align: center;border:none;" class="signature-with-user-login"></td>
<td style="text-align: center;border:none;" class=""></td>
<td style="text-align: center;border:none;" class=""></td>
<td style="text-align: center;border:none;" class=""></td>
<td style="text-align: center;border:none;" class="signature-with-manager"></td>
</tr>
</tbody>
</table>`;

const mapDataFillSignatureWithClass = {
  IS_USER_LOGIN: { className: 'signature-with-user-login', fieldValue: 'userLogin' }
};

const mapValuesFillSignature = (data) => {
  const signatureValues = {};
  Object.keys(mapDataFillSignatureWithClass).forEach((el) => {
    const item = mapDataFillSignatureWithClass[el];
    const className = item.className;
    const fillValue = item.fieldValue;
    signatureValues[className] = data[fillValue];
  });
  return signatureValues;
};

export function processingDomOfSignature(signatureValues, htmlTemplate = html) {
  const fillSignatureValues = mapValuesFillSignature(signatureValues);
  const newDom = document.createElement('html');
  newDom.innerHTML = `<html><head><title>Signature</title></head><body>${htmlTemplate}</body></html>`;
  Object.values(mapDataFillSignatureWithClass).forEach((el) => {
    const elementFill = newDom.querySelectorAll(`.${el.className}`);
    for (var i = 0; i < elementFill.length; i++) {
      elementFill[i].innerHTML = fillSignatureValues[el.className];
    }
  });
  console.log('🚀 ~ file: processingDom.js:75 ~ Object.values ~ elementFill', newDom.outerHTML);
  return newDom;
}

export function processingDomOfSignatureByExcel(signatureValues, htmlTemplate = html) {
  const newDom = document.createElement('html');
  newDom.innerHTML = `<html><head><title>Signature</title></head><body>${htmlTemplate}</body></html>`;
  Object.keys(signatureValues).forEach((el) => {
    const elementFill = newDom.querySelectorAll(`.${el}`);

    for (var i = 0; i < elementFill.length; i++) {
      elementFill[i].innerHTML = signatureValues[el];
    }
  });
  console.log('🚀 ~ file: processingDom.js:75 ~ Object.values ~ elementFill', newDom.outerHTML);
  return newDom;
}
