  import {
    CUSTOM_FEE_ACTIONS
  } from "actions/customfee";
import { DEFAULT_PAGE_SIZE } from 'const/common';
import {
    put,
    takeEvery,
  } from 'redux-saga/effects';
  import {
    getCustomFeePerPage,
  } from "services/customfee";
  
  function* putActionUpdateData() {
    yield put({
      type: CUSTOM_FEE_ACTIONS.GET_CUSTOM_FEE_PER_PAGE,
      payload: getCustomFeePerPage(1, DEFAULT_PAGE_SIZE)
    })
  }
  
  export function* actionUpdateCustomFeeData() {
    yield takeEvery([CUSTOM_FEE_ACTIONS.UPDATE_CUSTOM_FEE_FULFILLED, CUSTOM_FEE_ACTIONS.CREATE_CUSTOM_FEE_FULFILLED], putActionUpdateData);
  }
  