import { REPAIR_REPORT_ACTIONS } from 'actions/report/repair';
import { isNull } from 'commons/utils';
import lodash from 'lodash';

const INIT_STATE = {
  listPerPage: [],
  listSparepart: [],
  listAll: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  pagination: {},
  isCloseModal: false,
  isSearch: false,
  isReload: false,
  listDepartment: [],
  listCar: []
};

function removeReduntDantForRepairingReport(reports) {
  if (isNull(reports) || !Array.isArray(reports)) return [];
  return reports.map((report) => ({
    ...report,
    RepairingDetail: removeReduntDantRepairingDetail(report.RepairingDetail)
  }));
}

function removeReduntDantRepairingDetail(repairingDetails) {
  if (isNull(repairingDetails) || !Array.isArray(repairingDetails)) return [];
  const uniqueRepairingDetails = lodash.uniqBy(repairingDetails, (repairingDetail) => repairingDetail.Id);
  return uniqueRepairingDetails;
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REPAIR_REPORT_ACTIONS.GET_REPAIR_REPORT_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case REPAIR_REPORT_ACTIONS.GET_REPAIR_REPORT_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: removeReduntDantForRepairingReport(action.payload.data.data),
        pagination: action.payload.data.pagination,
        isSearch: false,
        isReload: false
      };
    }
    case REPAIR_REPORT_ACTIONS.GET_REPAIR_REPORT_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isReload: false
      };
    }

    case REPAIR_REPORT_ACTIONS.GET_ALL_REPAIR_REPORT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isReload: false
      };
    }
    case REPAIR_REPORT_ACTIONS.GET_ALL_REPAIR_REPORT_FULFILLED: {
      return {
        ...state,
        loading: false,
        listSparepart: action.payload.data.data,
        isReload: false
      };
    }
    case REPAIR_REPORT_ACTIONS.GET_ALL_REPAIR_REPORT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }

    case REPAIR_REPORT_ACTIONS.SEARCH_DATA_REPORT_REPAIR_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case REPAIR_REPORT_ACTIONS.SEARCH_DATA_REPORT_REPAIR_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        listPerPage: removeReduntDantForRepairingReport(action.payload.data.data),
        pagination: action.payload.data.pagination
      };
    }
    case REPAIR_REPORT_ACTIONS.SEARCH_DATA_REPORT_REPAIR_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }
    case REPAIR_REPORT_ACTIONS.UPDATE_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case REPAIR_REPORT_ACTIONS.UPDATE_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công !',
        isCloseModal: true
      };
    }
    case REPAIR_REPORT_ACTIONS.UPDATE_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể cập nhật dữ liệu!',
        isCloseModal: false
      };
    }
    case REPAIR_REPORT_ACTIONS.GET_ALL_REPORT_REPAIR_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case REPAIR_REPORT_ACTIONS.GET_ALL_REPORT_REPAIR_FULFILLED: {
      return {
        ...state,
        loading: false,
        listAll: action.payload.data.data
      };
    }
    case REPAIR_REPORT_ACTIONS.GET_ALL_REPORT_REPAIR_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    //
    case REPAIR_REPORT_ACTIONS.GET_DEPARTMENT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isReload: false
      };
    }
    case REPAIR_REPORT_ACTIONS.GET_DEPARTMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        listDepartment: action.payload.data.data,
        isReload: false
      };
    }
    case REPAIR_REPORT_ACTIONS.GET_DEPARTMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case REPAIR_REPORT_ACTIONS.GET_CAR_FILTER_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isReload: false
      };
    }
    case REPAIR_REPORT_ACTIONS.GET_CAR_FILTER_FULFILLED: {
      return {
        ...state,
        loading: false,
        listCar: action.payload.data.data,
        isReload: false
      };
    }
    case REPAIR_REPORT_ACTIONS.GET_CAR_FILTER_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    default:
      return state;
  }
};
