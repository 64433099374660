import api from 'HttpClient';
import {
  createPaymentOnBehaft,
  deletePaymentOnBehaft,
  getAllPaymentOnBehaft,
  getPaymentOnBehaftByID,
  getPaymentOnBehaftPerPage,
  getPaymentOnBehaftPerPageAll,
  searchData,
  updatePaymentOnBehaft
} from 'services/paymentonbehaft';

// define const actions
export const PAYMENT_ON_BEHAFT_ACTIONS = {
  GET_ALL_PAYMENT_ON_BEHAFT: 'GET_PAYMENT_ON_BEHAFT',
  GET_ALL_PAYMENT_ON_BEHAFT_PENDING: 'GET_ALL_PAYMENT_ON_BEHAFT_PENDING',
  GET_ALL_PAYMENT_ON_BEHAFT_FULFILLED: 'GET_ALL_PAYMENT_ON_BEHAFT_FULFILLED',
  GET_ALL_PAYMENT_ON_BEHAFT_REJECTED: 'GET_ALL_PAYMENT_ON_BEHAFT_REJECTED',

  GET_PAYMENT_ON_BEHAFT_PER_PAGE: 'GET_PAYMENT_ON_BEHAFT_PER_PAGE',
  GET_PAYMENT_ON_BEHAFT_PER_PAGE_PENDING: 'GET_PAYMENT_ON_BEHAFT_PER_PAGE_PENDING',
  GET_PAYMENT_ON_BEHAFT_PER_PAGE_FULFILLED: 'GET_PAYMENT_ON_BEHAFT_PER_PAGE_FULFILLED',
  GET_PAYMENT_ON_BEHAFT_PER_PAGE_REJECTED: 'GET_PAYMENT_ON_BEHAFT_PER_PAGE_REJECTED',

  GET_PAYMENT_ON_BEHAFT_BY_ID: 'GET_PAYMENT_ON_BEHAFT_BY_ID',
  GET_PAYMENT_ON_BEHAFT_BY_ID_PENDING: 'GET_PAYMENT_ON_BEHAFT_BY_ID_PENDING',
  GET_PAYMENT_ON_BEHAFT_BY_ID_FULFILLED: 'GET_PAYMENT_ON_BEHAFT_BY_ID_FULFILLED',
  GET_PAYMENT_ON_BEHAFT_BY_ID_REJECTED: 'GET_PAYMENT_ON_BEHAFT_BY_ID_REJECTED',

  CREATE_PAYMENT_ON_BEHAFT: 'CREATE_PAYMENT_ON_BEHAFT',
  CREATE_PAYMENT_ON_BEHAFT_PENDING: 'CREATE_PAYMENT_ON_BEHAFT_PENDING',
  CREATE_PAYMENT_ON_BEHAFT_FULFILLED: 'CREATE_PAYMENT_ON_BEHAFT_FULFILLED',
  CREATE_PAYMENT_ON_BEHAFT_REJECTED: 'CREATE_PAYMENT_ON_BEHAFT_REJECTED',

  UPDATE_PAYMENT_ON_BEHAFT: 'UPDATE_PAYMENT_ON_BEHAFT',
  UPDATE_PAYMENT_ON_BEHAFT_PENDING: 'UPDATE_PAYMENT_ON_BEHAFT_PENDING',
  UPDATE_PAYMENT_ON_BEHAFT_FULFILLED: 'UPDATE_PAYMENT_ON_BEHAFT_FULFILLED',
  UPDATE_PAYMENT_ON_BEHAFT_REJECTED: 'UPDATE_PAYMENT_ON_BEHAFT_REJECTED',

  DELETE_PAYMENT_ON_BEHAFT: 'DELETE_PAYMENT_ON_BEHAFT',
  DELETE_PAYMENT_ON_BEHAFT_PENDING: 'DELETE_PAYMENT_ON_BEHAFT_PENDING',
  DELETE_PAYMENT_ON_BEHAFT_FULFILLED: 'DELETE_PAYMENT_ON_BEHAFT_FULFILLED',
  DELETE_PAYMENT_ON_BEHAFT_REJECTED: 'DELETE_PAYMENT_ON_BEHAFT_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED'
};

// define actions
export const actionGetPaymentOnBehaftPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: PAYMENT_ON_BEHAFT_ACTIONS.GET_PAYMENT_ON_BEHAFT_PER_PAGE,
    payload: getPaymentOnBehaftPerPage(pageNo, pageSize)
  });
};
export const actionGetPaymentOnBehaftPerPageAll = (params) => (dispatch) => {
  return dispatch({
    // type: PAYMENT_ON_BEHAFT_ACTIONS.GET_PAYMENT_ON_BEHAFT_PER_PAGE,
    payload: getPaymentOnBehaftPerPageAll(params)
  });
};

export const actionGetAllPaymentOnBehaft = () => (dispatch) => {
  return dispatch({
    type: PAYMENT_ON_BEHAFT_ACTIONS.GET_ALL_PAYMENT_ON_BEHAFT,
    payload: getAllPaymentOnBehaft()
  });
};

export const actionGetPaymentOnBehaftByID = (id) => (dispatch) => {
  return dispatch({
    type: PAYMENT_ON_BEHAFT_ACTIONS.GET_PAYMENT_ON_BEHAFT_BY_ID,
    payload: getPaymentOnBehaftByID(id)
  });
};

export const actionCreatePaymentOnBehaft = (payload) => (dispatch) => {
  return dispatch({
    type: PAYMENT_ON_BEHAFT_ACTIONS.CREATE_PAYMENT_ON_BEHAFT,
    payload: createPaymentOnBehaft(payload)
  });
};

export const actionUpdatePaymentOnBehaft = (payload) => (dispatch) => {
  return dispatch({
    type: PAYMENT_ON_BEHAFT_ACTIONS.UPDATE_PAYMENT_ON_BEHAFT,
    payload: updatePaymentOnBehaft(payload)
  });
};

export const actionDeletePaymentOnBehaft = (id) => (dispatch) => {
  return dispatch({
    type: PAYMENT_ON_BEHAFT_ACTIONS.DELETE_PAYMENT_ON_BEHAFT,
    payload: deletePaymentOnBehaft(id)
  });
};

export const actionSearchData = (payload) => (dispatch) => {
  return dispatch({
    type: PAYMENT_ON_BEHAFT_ACTIONS.SEARCH_DATA,
    payload: searchData(payload)
  });
};
export const actionSearchDataAll = (params) => (dispatch) => {
  return dispatch({
    // type: PAYMENT_ON_BEHAFT_ACTIONS.SEARCH_DATA,
    // payload: api.get(`/PaymentOnBehalfInfo/search`, { params })
    payload: searchData(params)
  });
};
