import {
  createRepair,
  createRepairs,
  deleteRepair,
  getAllRepair,
  getEmployees,
  getRepairingById,
  getRepairPerPage,
  searchData,
  updateRepair,
  updateRepairFee
} from 'services/repair/repairManagement';

// define const actions
export const REPAIR_ACTIONS = {
  GET_ALL_REPAIR: 'GET_ALL_REPAIR',
  GET_ALL_REPAIR_PENDING: 'GET_ALL_REPAIR_PENDING',
  GET_ALL_REPAIR_FULFILLED: 'GET_ALL_REPAIR_FULFILLED',
  GET_ALL_REPAIR_REJECTED: 'GET_ALL_REPAIR_REJECTED',

  GET_REPAIR_PER_PAGE: 'GET_REPAIR_PER_PAGE',
  GET_REPAIR_PER_PAGE_PENDING: 'GET_REPAIR_PER_PAGE_PENDING',
  GET_REPAIR_PER_PAGE_FULFILLED: 'GET_REPAIR_PER_PAGE_FULFILLED',
  GET_REPAIR_PER_PAGE_REJECTED: 'GET_REPAIR_PER_PAGE_REJECTED',

  GET_REPAIR_BY_ID: 'GET_REPAIR_BY_ID',
  GET_REPAIR_BY_ID_PENDING: 'GET_REPAIR_BY_ID_PENDING',
  GET_REPAIR_BY_ID_FULFILLED: 'GET_REPAIR_BY_ID_FULFILLED',
  GET_REPAIR_BY_ID_REJECTED: 'GET_REPAIR_BY_ID_REJECTED',

  CREATE_REPAIR: 'CREATE_REPAIR',
  CREATE_REPAIR_PENDING: 'CREATE_REPAIR_PENDING',
  CREATE_REPAIR_FULFILLED: 'CREATE_REPAIR_FULFILLED',
  CREATE_REPAIR_REJECTED: 'CREATE_REPAIR_REJECTED',

  UPDATE_REPAIR: 'UPDATE_REPAIR',
  UPDATE_REPAIR_PENDING: 'UPDATE_REPAIR_PENDING',
  UPDATE_REPAIR_FULFILLED: 'UPDATE_REPAIR_FULFILLED',
  UPDATE_REPAIR_REJECTED: 'UPDATE_REPAIR_REJECTED',

  DELETE_REPAIR: 'DELETE_REPAIR',
  DELETE_REPAIR_PENDING: 'DELETE_REPAIR_PENDING',
  DELETE_REPAIR_FULFILLED: 'DELETE_REPAIR_FULFILLED',
  DELETE_REPAIR_REJECTED: 'DELETE_REPAIR_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  UPDATE_REPAIR_FEE: 'UPDATE_REPAIR_FEE',
  UPDATE_REPAIR_FEE_PENDING: 'UPDATE_REPAIR_FEE_PENDING',
  UPDATE_REPAIR_FEE_FULFILLED: 'UPDATE_REPAIR_FEE_FULFILLED',
  UPDATE_REPAIR_FEE_REJECTED: 'UPDATE_REPAIR_FEE_REJECTED',

  GET_EMPLOYEES_REPAIR: 'GET_EMPLOYEES_REPAIR',
  GET_EMPLOYEES_REPAIR_PENDING: 'GET_EMPLOYEES_REPAIR_PENDING',
  GET_EMPLOYEES_REPAIR_FULFILLED: 'GET_EMPLOYEES_REPAIR_FULFILLED',
  GET_EMPLOYEES_REPAIR_REJECTED: 'GET_EMPLOYEES_REPAIR_REJECTED'
};

// define actions:

export const actionGetAllRepair = () => (dispatch) => {
  return dispatch({
    type: REPAIR_ACTIONS.GET_ALL_REPAIR,
    payload: getAllRepair()
  });
};

export const actionGetRepairPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: REPAIR_ACTIONS.GET_REPAIR_PER_PAGE,
    payload: getRepairPerPage(pageNo, pageSize)
  });
};

export const actionCreateRepair = (payload) => (dispatch) => {
  return dispatch({
    type: REPAIR_ACTIONS.CREATE_REPAIR,
    payload: createRepair(payload)
  });
};
export const actionCreateRepairs = (payload) => (dispatch) => {
  return dispatch({
    type: REPAIR_ACTIONS.CREATE_REPAIR,
    payload: createRepairs(payload)
  });
};

export const actionUpdateRepair = (payload) => (dispatch) => {
  return dispatch({
    type: REPAIR_ACTIONS.UPDATE_REPAIR,
    payload: updateRepair(payload)
  });
};
export const actionDeleteRepair = (id) => (dispatch) => {
  return dispatch({
    type: REPAIR_ACTIONS.DELETE_REPAIR,
    payload: deleteRepair(id)
  });
};
export const actionGetRepairById = (id) => (dispatch) => {
  return dispatch({
    type: REPAIR_ACTIONS.GET_REPAIR_BY_ID,
    payload: getRepairingById(id)
  });
};

export const actionSearchData = (payload, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: REPAIR_ACTIONS.SEARCH_DATA,
    payload: searchData(payload)
  });
};

export const actionSearchDataAll = (payload) => (dispatch) => {
  return dispatch({
    payload: searchData(payload)
  });
};

export const actionUpdateRepairFee = (payload, type) => (dispatch) => {
  return dispatch({
    type: REPAIR_ACTIONS.UPDATE_REPAIR_FEE,
    payload: updateRepairFee(payload, type)
  });
};

export const actiongetEmployeesRepair = () => (dispatch) => {
  return dispatch({
    type: REPAIR_ACTIONS.GET_EMPLOYEES_REPAIR,
    payload: getEmployees()
  });
};
