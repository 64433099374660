import { convertToNumber } from './formatedNumber';
import { isNull, isNullOrEmpty } from './utils';

export const sum = (...params) => {
  if (params.length === 0) return 0;
  const sumValue = aggregate(sumFunc, 0, ...params);
  return sumValue;
};

export const max = (...params) => {
  if (params.length === 0) return 0;
  const maxValue = aggregate(maxFunc, params[0], ...params);
  return maxValue;
};

export const min = (...params) => {
  if (params.length === 0) return 0;
  const minValue = aggregate(minFunc, params[0], ...params);
  return minValue;
};

export const avg = (...params) => {
  if (params.length === 0) return 0;
  const sumValue = sum(...params);
  const avgValue = sumValue / params.length;
  return avgValue;
};

export const last = (...params) => {
  if (params.length === 0) return null;
  const lastValue = params[params.length - 1];
  return lastValue;
};

export const first = (...params) => {
  if (params.length === 0) return null;
  const firstValue = params[0];
  return firstValue;
};

export const empty = (...params) => {
  return null;
};

export const number = (a) => (a ? a : 0);

const sumFunc = (a, b) => {
  const isHtmlObject = typeof b !== 'number';
  const first = number(a);
  const html = isHtmlObject ? getHtmlTextNumberValue(b) : 0;
  const second = isHtmlObject ? html : number(b);
  return first + second;
};
const maxFunc = (a, b) => {
  const first = number(a);
  const second = number(b);
  return first > second ? first : second;
};
const minFunc = (a, b) => {
  const first = number(a);
  const second = number(b);
  return first < second ? first : second;
};
const aggregate = (func, initialValue, ...params) => {
  if (params.length === 0) return 0;
  const result = params.reduce(func, initialValue);
  return result;
};

const getHtmlTextNumberValue = (symbol) => {
  if (!symbol) return 0;
  const isNotHtmlObject = typeof symbol !== 'object';
  if (isNotHtmlObject) return symbol;
  return symbol?.props?.children && Array.isArray(symbol?.props?.children)
    ? numberOf(symbol?.props?.children[0]?.props?.html) || 0
    : numberOf(symbol?.props?.children?.props?.children) || 0;
};

export const normalizeInputToReadableString = (input, allowNegative = true, allowDecimal = true) => {
  if (!input) return input;
  const inputNumber = `${input}`;
  const inputStartsWithMinus = inputNumber.startsWith('-');
  const prefix = allowNegative && inputStartsWithMinus ? '-' : '';
  const redundantCharsRegex = allowDecimal ? /(-|\D)/g : /(-|\D|\.)/g;
  const valueWithoutMinus = inputStartsWithMinus ? inputNumber.replaceAll(redundantCharsRegex, '') : inputNumber;
  // const number = valueWithoutMinus !== '' ? parseInt(valueWithoutMinus) : '';
  const formatedNumber = formatNumberToReadableString(valueWithoutMinus);
  const result = prefix + (formatedNumber ?? '');
  return result;
};

export const normalizeInputToReadableStringRound =
  (x) =>
  (input, allowNegative = true, allowDecimal = true) => {
    if (!input) return input;
    const inputNumber = `${input}`;
    const inputStartsWithMinus = inputNumber.startsWith('-');
    const prefix = allowNegative && inputStartsWithMinus ? '-' : '';
    const redundantCharsRegex = allowDecimal ? /(-|\D)/g : /(-|\D|\.)/g;
    let valueWithoutMinus = inputStartsWithMinus ? inputNumber.replaceAll(redundantCharsRegex, '') : inputNumber;
    const number = numberOf(valueWithoutMinus);
    // kiểm tra có phải số thập phân không
    if (number && number % 1 !== 0) {
      const numberRound = number.toFixed(x);
      valueWithoutMinus = numberRound;
    }
    const formatedNumber = formatNumberToReadableString(valueWithoutMinus);

    const result = prefix + (formatedNumber ?? '');
    return result;
  };

const reverseString = (str) => {
  if (!str) return null;
  if (str === '') return '';
  // Step 1. Use the split() method to return a new array
  var splitString = str.split(''); // var splitString = "hello".split("");
  // ["h", "e", "l", "l", "o"]

  // Step 2. Use the reverse() method to reverse the new created array
  var reverseArray = splitString.reverse(); // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
  // ["o", "l", "l", "e", "h"]

  // Step 3. Use the join() method to join all elements of the array into a string
  var joinArray = reverseArray.join(''); // var joinArray = ["o", "l", "l", "e", "h"].join("");
  // "olleh"

  //Step 4. Return the reversed string
  return joinArray; // "olleh"
};

const formatDecimalToReadableString = (string) => {
  const [integerPart, fractionalPart] = string.split('.');
  const formatedIntegerPart = formatNumberToReadableString(integerPart);
  const reverseFractionalPart = reverseString(fractionalPart);
  const formatedReverseFractionalPart = formatNumberToReadableString(reverseFractionalPart);
  const formatedFractionalPart = reverseString(formatedReverseFractionalPart);
  const result = `${formatedIntegerPart ? formatedIntegerPart : 0}.${
    formatedFractionalPart ? formatedFractionalPart : ''
  }`;
  return result;
};
function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export function formatNumberCalculateResult(number, roundNumber) {
  if (isNullOrEmpty(number) || !isNumber(number)) return null;
  let value = number;
  const isFloatNumber = isFloat(number);
  if (!isFloatNumber) {
    value = formatNumberToReadableString(parseInt(number));
  } else {
    value = Math.round(number * Math.pow(10, roundNumber)) / Math.pow(10, roundNumber);
  }
  return value;
}

function formatIntegerToReadableString(trimNumber) {
  if (isNullOrEmpty(trimNumber)) return null;
  const numberGroups = [];
  const isNagativeNumber = Number(trimNumber) < 0;
  const positiveNumber = isNagativeNumber ? trimNumber.slice(1) : trimNumber;
  const length = positiveNumber.length;
  for (let i = length; i > 0; i -= 3) {
    let startPoint = i - 3;
    if (startPoint < 0) {
      startPoint = 0;
    }
    const numberGroup = positiveNumber.slice(startPoint, i);
    numberGroups.unshift(numberGroup);
  }
  let formatedNumber = numberGroups.join(',');
  formatedNumber = isNagativeNumber ? '-' + formatedNumber : formatedNumber;
  return formatedNumber;
}

const StringNumberWithoutComma = (string) => {
  if (isNullOrEmpty(string)) return null;
  if (typeof string !== 'string') return string;
  const stringStartsWithMinus = string.startsWith('-');
  const normalizeString = string.replaceAll(/,|\-/g, '');
  const result = stringStartsWithMinus ? -normalizeString : normalizeString;
  return result;
};

export const formatNumberToReadableString = (number) => {
  let result = null;
  if (isNullOrEmpty(number)) return null;
  const trimNumber = `${number}`.split(',').join('');
  if (trimNumber === '' || isNaN(trimNumber)) return null;
  // debugger here
  const realNumber = `${StringNumberWithoutComma(trimNumber)}`;

  if (trimNumber.indexOf('.') >= 0) {
    result = formatDecimalToReadableString(realNumber);
  } else {
    result = formatIntegerToReadableString(realNumber);
  }
  return result;
};

export const xor = (a, b) => {
  const result = (a && !b) || (!a && b);
  return result;
};

export const xand = (a, b) => {
  const result = (a && b) || (!a && !b);
  return result;
};

export function roundNumber(number) {
  const numberOf100Times = parseInt(number * 100);
  const roundedNumber = numberOf100Times / 100;
  return roundedNumber;
}

export function valueOf(value) {
  if (value === undefined || value === null || value === '') {
    return null;
  }
  let result = value;
  if (typeof value === 'string') {
    if (value.indexOf('.') >= 0) {
      const [integerPart, decimalPart] = value.split('.');
      const integerValue = valueOf(integerPart);
      const decimalValue = valueOf(decimalPart);
      const floatValueString = `${integerValue}.${decimalValue}`;
      result = parseFloat(floatValueString);
      result = Math.round(result * 100) / 100;
    } else {
      let inValueString = value;
      if (value.indexOf(',')) {
        inValueString = value.split(',').join('');
      }
      if (!isNaN(inValueString)) {
        result = parseInt(inValueString);
      } else {
        result = 0;
      }
    }
  }
  return result;
}

export const numberOf = (string) => {
  if (isNullOrEmpty(string)) return null;
  if (typeof string !== 'string') return string;
  const stringStartsWithMinus = string.startsWith('-');
  const normalizeString = string.replaceAll(/,|\-/g, '');
  let number = 0;
  if (normalizeString !== '' && !isNaN(normalizeString)) {
    number = normalizeString.endsWith('.') ? normalizeString : parseFloat(normalizeString);
  }
  const result = stringStartsWithMinus ? -number : number;
  return result;
};

export const toFloat = (number) => {
  const result = Number(parseFloat(number).toFixed(2));
  return !isNaN(result) ? result : null;
};

export const normalizeInputToIntegerNumber = (string) => {
  if (isNullOrEmpty(string)) return 0;
  if (typeof string === 'number') return string;
  const number = parseInt(string);
  return number;
};

export const floatEquals = (number1, number2, approximateRate = 1e-6) => {
  const subtract = Math.abs(number1 - number2);
  const isEqual = subtract < approximateRate;
  return isEqual;
};

//hàm đọc số
var mangso = ['không', 'một', 'hai', 'ba', 'bốn', 'năm', 'sáu', 'bảy', 'tám', 'chín'];
function dochangchuc(so, daydu) {
  var chuoi = '';
  let chuc = Math.floor(so / 10);
  let donvi = so % 10;
  if (chuc > 1) {
    chuoi = ' ' + mangso[chuc] + ' mươi';
    if (donvi == 1) {
      chuoi += ' mốt';
    }
  } else if (chuc == 1) {
    chuoi = ' mười';
    if (donvi == 1) {
      chuoi += ' một';
    }
  } else if (daydu && donvi > 0) {
    chuoi = ' lẻ';
  }
  if (donvi == 5 && chuc > 1) {
    chuoi += ' lăm';
  } else if (donvi > 1 || (donvi == 1 && chuc == 0)) {
    chuoi += ' ' + mangso[donvi];
  }
  return chuoi;
}
function docblock(so, daydu) {
  var chuoi = '';
  let tram = Math.floor(so / 100);
  so = so % 100;
  if (daydu || tram > 0) {
    chuoi = ' ' + mangso[tram] + ' trăm';
    chuoi += dochangchuc(so, true);
  } else {
    chuoi = dochangchuc(so, false);
  }
  return chuoi;
}
function dochangtrieu(so, daydu) {
  var chuoi = '';
  let trieu = Math.floor(so / 1000000);
  so = so % 1000000;
  if (trieu > 0) {
    chuoi = docblock(trieu, daydu) + ' triệu';
    daydu = true;
  }
  let nghin = Math.floor(so / 1000);
  so = so % 1000;
  if (nghin > 0) {
    chuoi += docblock(nghin, daydu) + ' nghìn';
    daydu = true;
  }
  if (so > 0) {
    chuoi += docblock(so, daydu);
  }
  return chuoi;
}
export function readNumber(so) {
  let soCopy = convertToNumber(so);
  soCopy = Math.round(soCopy);
  if (soCopy == 0) return mangso[0];
  var chuoi = '',
    hauto = '',
    ty = 0;
  do {
    ty = soCopy % 1000000000;
    soCopy = Math.floor(soCopy / 1000000000);
    if (soCopy > 0) {
      chuoi = dochangtrieu(ty, true) + hauto + chuoi;
    } else {
      chuoi = dochangtrieu(ty, false) + hauto + chuoi;
    }
    hauto = ' tỷ';
  } while (soCopy > 0);
  if (isNull(chuoi)) {
    chuoi = 'Không';
  } else {
    let first = chuoi?.trim()[0]?.toUpperCase() ?? '';
    let rest = chuoi?.trim().slice(1) ?? '';
    chuoi = first + rest;
  }

  return chuoi;
}

export function selectYears() {
  const currentYear = new Date().getFullYear();
  const year = currentYear - 100;
  const Years = [];
  for (let i = 0; i <= 100; i++) {
    Years.push({
      value: currentYear - i,
      label: currentYear - i + ''
    });
  }
  return Years;
}

export function romanize(num) {
  if (isNaN(num)) return NaN;
  let digits = String(+num).split(''),
    key = [
      '',
      'C',
      'CC',
      'CCC',
      'CD',
      'D',
      'DC',
      'DCC',
      'DCCC',
      'CM',
      '',
      'X',
      'XX',
      'XXX',
      'XL',
      'L',
      'LX',
      'LXX',
      'LXXX',
      'XC',
      '',
      'I',
      'II',
      'III',
      'IV',
      'V',
      'VI',
      'VII',
      'VIII',
      'IX'
    ],
    roman = '',
    i = 3;
  while (i--) roman = (key[+digits.pop() + i * 10] || '') + roman;
  return Array(+digits.join('') + 1).join('M') + roman;
}

export function range(start, stop, step = 1) {
  const result = [start];
  let index = start;
  while (index <= stop) {
    index += step;
    result.push(index);
  }
  return result;
}

export function isNumber(stringValue) {
  if (typeof stringValue === 'number' || isNullOrEmpty(stringValue)) return true;

  let result = false;
  if (typeof stringValue === 'string') {
    const trimString = stringValue.replace(/[, ]/g, '');
    const number = parseFloat(trimString);
    if (!isNaN(number)) {
      result = true;
    }
  }

  return result;
}

export function formatCarsNumber(trimNumber) {
  if (isNullOrEmpty(trimNumber)) return null;
  const numberGroups = [];
  const isNagativeNumber = Number(trimNumber) < 0;
  const positiveNumber = isNagativeNumber ? trimNumber.slice(1) : trimNumber;
  const length = positiveNumber.length;
  for (let i = 0; i < length; i += 3) {
    let startPoint = i + 3;
    if (startPoint > length) {
      startPoint = length;
    }
    const numberGroup = positiveNumber.slice(i, startPoint);
    numberGroups.push(numberGroup);
  }
  let formatedNumber = numberGroups.join('.');
  formatedNumber = isNagativeNumber ? '-' + formatedNumber : formatedNumber;
  return formatedNumber;
}

const conversion = {
  M: 1000,
  D: 500,
  C: 100,
  L: 50,
  X: 10,
  V: 5,
  I: 1
};

export const convertRomanToNumber = (roman) => {
  const array = roman.split('');
  let total = 0;
  let current;
  let currentValue;
  let next;
  let nextValue;
  for (let i = 0; i < array.length; i++) {
    current = array[i];
    currentValue = conversion[current];
    next = array[i + 1];
    nextValue = conversion[next];
    if (currentValue < nextValue) {
      total -= currentValue;
    } else {
      total += currentValue;
    }
  }
  return total;
};

export const formatRoundedNumberToReadableString = (num) => {
  const number = Math.round(num);
  let result = null;
  if (isNullOrEmpty(number)) return null;
  const trimNumber = `${number}`.split(',').join('');
  if (trimNumber === '' || isNaN(trimNumber)) return null;
  const realNumber = `${numberOf(trimNumber)}`;

  if (trimNumber.indexOf('.') >= 0) {
    result = formatDecimalToReadableString(realNumber);
  } else {
    result = formatIntegerToReadableString(realNumber);
  }
  return result;
};

export function roundResultForCalculating(number, roundNumber) {
  if (!number) return 0;
  const numberFormatted = Math.round(number * Math.pow(10, roundNumber)) / Math.pow(10, roundNumber);
  return numberFormatted;
}

export function roundResultDisplay(number, roundNumber) {
  if (!number) return null;
  const numberFormatted = Math.round(number * Math.pow(10, roundNumber)) / Math.pow(10, roundNumber);
  return numberFormatted;
}
