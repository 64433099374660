import { toast } from 'react-toastify';
import { TIME_SHOW_ALERT_MESS } from 'const/common';
import api from 'HttpClient';
import moment from 'moment';

const toastError = (msg) => {
  const toastId = toast.error(msg, {
    containerId: 'notificationToast',
    autoClose: TIME_SHOW_ALERT_MESS
  });
};

const toastSuccess = (msg) => {
  const toastId = toast.info(msg, {
    containerId: 'notificationToast',
    autoClose: TIME_SHOW_ALERT_MESS
  });
};

export const CHANGE_CHECK_BOX_TIME_SHEET_TYPE = 'CHANGE_CHECK_BOX_TIME_SHEET_TYPE';
export const ChangeCheckBox = (item) => {
  return (dispatch) => {
    return dispatch({
      type: CHANGE_CHECK_BOX_TIME_SHEET_TYPE,
      data: item
    });
  };
};

export const TOGGLE_REPORT_FORM_TYPE = 'TOGGLE_REPORT_FORM_TYPE';
export const toggleDetailAction = (item) => {
  return (dispatch) => {
    return dispatch({
      type: TOGGLE_REPORT_FORM_TYPE
    });
  };
};

export const CHANGE_CHECK_BOX_ALL_TIME_SHEET_TYPE = 'CHANGE_CHECK_BOX_ALL_TIME_SHEET_TYPE';
export const ChangeCheckBoxAll = (item) => {
  return (dispatch) => {
    return dispatch({
      type: CHANGE_CHECK_BOX_ALL_TIME_SHEET_TYPE
    });
  };
};

export const CHANGE_TIMEKEEPING_TIME_SHEET_TYPE = 'CHANGE_TIMEKEEPING_TIME_SHEET_TYPE';
export const ChangeTimeKeeping = (value) => {
  return (dispatch) => {
    return dispatch({
      type: CHANGE_TIMEKEEPING_TIME_SHEET_TYPE,
      data: value
    });
  };
};

export const TIMEKEEPING_ALL_TIME_SHEET_TYPE = 'TIMEKEEPING_ALL_TIME_SHEET_TYPE';
export const ChangeTimeKeepingAll = (value) => {
  return (dispatch) => {
    return dispatch({
      type: TIMEKEEPING_ALL_TIME_SHEET_TYPE
    });
  };
};

export const SELECT_TIME_KEEPING_TYPE = 'SELECT_TIME_KEEPING_TYPE';
export const selectTimeKeepingAction = (value, item) => {
  return (dispatch) => {
    return dispatch({
      type: SELECT_TIME_KEEPING_TYPE,
      data: { value, item }
    });
  };
};

export const CAP_NHAT_NGAY = 'CAP_NHAT_NGAY';
export const capNhatNgayAction = (value, item) => {
  return (dispatch) => {
    return dispatch({
      type: CAP_NHAT_NGAY,
      data: value
    });
  };
};

export const CAP_NHAT_MOTH_YEAR = 'CAP_NHAT_MOTH_YEAR';
export const capNhatMothYearAction = (value, item) => {
  return (dispatch) => {
    return dispatch({
      type: CAP_NHAT_MOTH_YEAR,
      data: value
    });
  };
};

export const MOTHLYSALARY_TIMKEEPING_TYPE = 'MOTHLYSALARY_TIMKEEPING_TYPE';
export const MOTHLYSALARY_TIMKEEPING_TYPE_PENDING = 'MOTHLYSALARY_TIMKEEPING_TYPE_PENDING';
export const MOTHLYSALARY_TIMKEEPING_TYPE_FULFILLED = 'MOTHLYSALARY_TIMKEEPING_TYPE_FULFILLED';
export const MOTHLYSALARY_TIMKEEPING_TYPE_REJECTED = 'MOTHLYSALARY_TIMKEEPING_TYPE_REJECTED';
export const monthlySalaryTimekeeping = (moth) => (dispatch) => {
  return dispatch({
    type: MOTHLYSALARY_TIMKEEPING_TYPE,
    payload: api.get(`timesheet/bymonth?month=${moth}`)
  });
};

export const DAILY_TIMKEEPING_TYPE = 'DAILY_TIMKEEPING_TYPE';
export const DAILY_TIMKEEPING_TYPE_PENDING = 'DAILY_TIMKEEPING_TYPE_PENDING';
export const DAILY_TIMKEEPING_TYPE_FULFILLED = 'DAILY_TIMKEEPING_TYPE_FULFILLED';
export const DAILY_TIMKEEPING_TYPE_REJECTED = 'DAILY_TIMKEEPING_TYPE_REJECTED';
export const dailyTimekeepingAction = (day) => (dispatch) => {
  return dispatch({
    type: DAILY_TIMKEEPING_TYPE,
    payload: api.get(`/timesheet/byday?day=${day}`)
  });
};

export const STATUS_INFO_TYPE = 'STATUS_INFO_TYPE';
export const STATUS_INFO_TYPE_PENDING = 'STATUS_INFO_TYPE_PENDING';
export const STATUS_INFO_TYPE_FULFILLED = 'STATUS_INFO_TYPE_FULFILLED';
export const STATUS_INFO_TYPE_REJECTED = 'STATUS_INFO_TYPE_REJECTED';
export const StatusInfoAction = (day) => (dispatch) => {
  return dispatch({
    type: STATUS_INFO_TYPE,
    payload: api.get(`StatusInfo/getBySatusCategoryId/12`)
  });
};

export const TIME_SHEET_TYPE = 'TIME_SHEET_TYPE';
export const TIME_SHEET_TYPE_PENDING = 'TIME_SHEET_TYPE_PENDING';
export const TIME_SHEET_TYPE_FULFILLED = 'TIME_SHEET_TYPE_FULFILLED';
export const TIME_SHEET_TYPE_REJECTED = 'TIME_SHEET_TYPE_REJECTED';

export const submitTimeSheetAction = (day, data) => async (dispatch) => {
  try {
    const payload = await api.put(`DailySalary/updateAll?day=${day}`, data);
    if (payload.data.status === 200) {
      const moth = moment(day).format('MM-YYYY');
      await dispatch(monthlySalaryTimekeeping(moth));
      await dispatch(dailyTimekeepingAction(day));
      // await dispatch(toggleDetailAction());
      toastSuccess('Chấm công thành công');
    }
  } catch (err) {
    console.log(err);
    toastError('Chấm công thất bại');
  }
};

export const DeleteDailySalaryByDateAction = (day) => async (dispatch) => {
  try {
    const payload = await api.delete(`DailySalary/DeleteDailySalaryByDay?day=${day}`);
    if (payload.status === 204) {
      const moth = moment(day).format('MM-YYYY');
      await dispatch(dailyTimekeepingAction(day));
      await dispatch(monthlySalaryTimekeeping(moth));
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateAllMonth = (month) => async (dispatch) => {
  try {
    return dispatch({
      payload: api.get(`/Timesheet/updateAllMonth`, { params: { date: month } })
    });
  } catch (err) {
    console.log(err);
  }
};
