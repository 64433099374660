import { WARE_HOUSE_REPORT_ACTIONS } from "actions/report/warehouse";

const INIT_STATE = {
  listPerPage: [],
  listSparepart: [],
  listAll: [],
  loading: false,
  typeMessage: "",
  message: "",
  isShowMessage: false,
  pagination: {},
  isCloseModal: false,
  isSearch: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case WARE_HOUSE_REPORT_ACTIONS.GET_WAREHOUSE_REPORT_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case WARE_HOUSE_REPORT_ACTIONS.GET_WAREHOUSE_REPORT_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
      };
    }
    case WARE_HOUSE_REPORT_ACTIONS.GET_WAREHOUSE_REPORT_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }

    case WARE_HOUSE_REPORT_ACTIONS.CALCULATE_REMAINING_AMOUNT_BY_MONTH_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case WARE_HOUSE_REPORT_ACTIONS.CALCULATE_REMAINING_AMOUNT_BY_MONTH_FULFILLED: {
      return {
        ...state,
        loading: false,
      };
    }
    case WARE_HOUSE_REPORT_ACTIONS.CALCULATE_REMAINING_AMOUNT_BY_MONTH_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể cập nhật dữ liệu !",
      };
    }

    case WARE_HOUSE_REPORT_ACTIONS.GET_ALL_REPAIR_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case WARE_HOUSE_REPORT_ACTIONS.GET_ALL_REPAIR_FULFILLED: {
      return {
        ...state,
        loading: false,
        listSparepart: action.payload.data.data,
      };
    }
    case WARE_HOUSE_REPORT_ACTIONS.GET_ALL_REPAIR_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }

    case "UPDATE_SHOW_ALERT": {
      return {
        ...state,
        isShowMessage: false,
      };
    }

    case WARE_HOUSE_REPORT_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case WARE_HOUSE_REPORT_ACTIONS.SEARCH_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
      };
    }
    case WARE_HOUSE_REPORT_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }
    case WARE_HOUSE_REPORT_ACTIONS.UPDATE_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
      };
    }
    case WARE_HOUSE_REPORT_ACTIONS.UPDATE_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "success",
        message: "Cập nhật dữ liệu thành công !",
        isCloseModal: true,
      };
    }
    case WARE_HOUSE_REPORT_ACTIONS.UPDATE_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể cập nhật dữ liệu!",
        isCloseModal: false,
      };
    }
    case WARE_HOUSE_REPORT_ACTIONS.GET_ALL_REPORT_WAREHOUSE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case WARE_HOUSE_REPORT_ACTIONS.GET_ALL_REPORT_WAREHOUSE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listAll: action.payload.data.data,
      };
    }
    case WARE_HOUSE_REPORT_ACTIONS.GET_ALL_REPORT_WAREHOUSE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }

    default:
      return state;
  }
};
