import httpClient from 'HttpClient';

export const TRANSPORT_FEE_PHASE = {
  GET_CUSTOMER_BY_TYPE_TRANSPORT_FEE_PHASE: 'GET_CUSTOMER_BY_TYPE_TRANSPORT_FEE_PHASE',
  GET_CUSTOMER_BY_TYPE_TRANSPORT_FEE_PHASE_PENDING: 'GET_CUSTOMER_BY_TYPE_TRANSPORT_FEE_PHASE_PENDING',
  GET_CUSTOMER_BY_TYPE_TRANSPORT_FEE_PHASE_FULFILLED: 'GET_CUSTOMER_BY_TYPE_TRANSPORT_FEE_PHASE_FULFILLED',
  GET_CUSTOMER_BY_TYPE_TRANSPORT_FEE_PHASE_REJECTED: 'GET_CUSTOMER_BY_TYPE_TRANSPORT_FEE_PHASE_REJECTED',

  TRANSPORT_FEE_PHASE: 'TRANSPORT_FEE_PHASE',
  TRANSPORT_FEE_PHASE_PENDING: 'TRANSPORT_FEE_PHASE_PENDING',
  TRANSPORT_FEE_PHASE_FULFILLED: 'TRANSPORT_FEE_PHASE_FULFILLED',
  TRANSPORT_FEE_PHASE_REJECTED: 'TRANSPORT_FEE_PHASE_REJECTED'
};

export const getByCustomerTypeTransportFeePhase = (params) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_FEE_PHASE.GET_CUSTOMER_BY_TYPE_TRANSPORT_FEE_PHASE,
    payload: httpClient.get(`/CustomerInfo/getByCustomerTypeAny`, { params })
  });
};

export const getTransportFeePhase = (payload) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_FEE_PHASE.TRANSPORT_FEE_PHASE,
    payload: httpClient.get('TransportFeePhase/search', { params: payload })
  });
};

export const updateTransportFeePhaseAll = (payload) => (dispatch) => {
  return dispatch({
    // type: TRANSPORT_COST_ACTIONS.TRANSPORT_FEE_PHASE,
    payload: httpClient.put('TransportFeePhase/updateAll', payload)
  });
};

export const createTransportFeePhase = (payload) => (dispatch) => {
  return dispatch({
    // type: TRANSPORT_COST_ACTIONS.TRANSPORT_FEE_PHASE,
    payload: httpClient.post('TransportFeePhase/insert', payload)
  });
};

export const deleteTransportFeePhase = (Id) => (dispatch) => {
  return dispatch({
    // type: TRANSPORT_COST_ACTIONS.TRANSPORT_FEE_PHASE,
    payload: httpClient.delete(`TransportFeePhase/delete/${Id}`)
  });
};

export const updatePhaseId = (payload) => (dispatch) => {
  return dispatch({
    // type: TRANSPORT_COST_ACTIONS.TRANSPORT_FEE_PHASE,
    payload: httpClient.put(`/CustomerInfo/updatePhaseId`, payload)
  });
};
