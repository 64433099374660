import api from 'HttpClient';

export const getRepairVoiceDetailById = (id) => {
  return api.get(`/repairing/${id}/repairingDetail/getAll`);
};

export const createRepairInvoiceDetail = (payload) => {
  return api.post(`/repairing/${payload.RepairingId}/repairingDetail/create?isOutSource=${payload.OutSource}`, payload);
};

export const updateRepairInvoiceDetail = (payload) => {
  return api.put(`/repairing/${payload.RepairingId}/repairingDetail/update?isOutSource=${payload.OutSource}`, payload);
};

export const deleteRepairInvoiceDetail = (repairingDetailId, repairingID, OutSource) => {
  return api.delete(`/repairing/repairingDetail/deleteById/${repairingDetailId}`);
};

export const getAllRepairInvoiceDetail = (id) => {
  return api.get(`/repairing/${id}/repairingDetail/getAll`);
};
