import { DRIVER_SALARY } from 'actions/driverSalaryInfo';
import { getErrorMessage } from 'commons/ReducerUtils';
import { removeDotsAndCommas } from 'commons/ValidateEditable';
import { MSG_PAGING_FAILURE } from 'const/common';
export const TYPE_NUMBER = 'number';

const INIT_STATE = {
  loading: false,
  alertMessage: '',
  salaryInfos: [],
  driverSalaryDetail: [],
  pagination: {},
  isDisableBtnSave: false,
  isDisplayBtnSave: false,
  extension: [],
  driverSalary: {}
};

function processingHandelInput(reports, payloadData) {
  const valueEdit = payloadData.value;
  const fieldName = payloadData.fieldName;
  const indexItemEdit = payloadData.index;
  const id = payloadData.id;
  const validateMessage = payloadData.message;
  const typeInput = payloadData.type;
  const valueUpdate =
    typeInput === TYPE_NUMBER && !validateMessage && !payloadData.isAcceptNegative && valueEdit
      ? parseInt(removeDotsAndCommas(valueEdit))
      : valueEdit;
  const itemEdit = reports.filter((item) => item.Id == id)[0];

  let error = { ...itemEdit.error, [fieldName]: validateMessage };
  const itemEditUpdated = {
    ...itemEdit,
    [fieldName]: valueUpdate,
    isEditable: true,
    error: error
  };
  const reportUpdated = [...reports];
  // update reports by changed item
  const mapReportsUpdatedWithValidateValue = reportUpdated.map((el) => {
    return el.Id == id ? itemEditUpdated : { ...el };
  });

  return mapReportsUpdatedWithValidateValue;
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DRIVER_SALARY.GET_SALARY_INFO_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case DRIVER_SALARY.GET_SALARY_INFO_FULFILLED: {
      return {
        ...state,
        loading: false,
        salaryInfos: action.payload.data?.data ? action.payload.data.data : 0,
        pagination: action.payload.data.pagination
      };
    }
    case DRIVER_SALARY.GET_SALARY_INFO_REJECTED: {
      let alertMessage = MSG_PAGING_FAILURE;
      if (action?.payload?.response?.status === 400) {
        alertMessage = getErrorMessage(action);
      }
      return {
        ...state,
        loading: false,
        alertMessage: alertMessage
      };
    }

    case DRIVER_SALARY.UPDATE_SALARY_INFO_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case DRIVER_SALARY.UPDATE_SALARY_INFO_FULFILLED: {
      return {
        ...state,
        loading: false,
        isDisplayBtnSave: false,
        alertMessage: 'Cập nhật thông tin thành công !'
      };
    }
    case DRIVER_SALARY.UPDATE_SALARY_INFO_REJECTED: {
      let alertMessage = MSG_PAGING_FAILURE;
      if (action?.payload?.response?.status === 400) {
        alertMessage = getErrorMessage(action);
      }
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage
      };
    }

    case DRIVER_SALARY.HANDEL_CHANGE_VALUE_INPUT_DRIVER_SALARY: {
      let salaryInfos = [...state.salaryInfos];
      const payloadData = action.payload;
      const resultData = processingHandelInput(salaryInfos, payloadData);
      const errors = resultData.map((el) => {
        return el.error;
      });
      const errorsHasMessage = errors.filter((el) => typeof el === 'object');
      return {
        ...state,
        salaryInfos: resultData,
        isDisableBtnSave: errorsHasMessage.some((el) => {
          return Object.values(el).some((obj) => {
            return obj;
          });
        }),
        isDisplayBtnSave: true
      };
    }

    case DRIVER_SALARY.GET_DRIVER_SALARY_DETAIL_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case DRIVER_SALARY.GET_DRIVER_SALARY_DETAIL_FULFILLED: {
      return {
        ...state,
        loading: false,
        driverSalaryDetail: action?.payload?.data?.data ?? [],
        extension: action?.payload?.data?.extension ?? []
      };
    }
    case DRIVER_SALARY.GET_DRIVER_SALARY_DETAIL_REJECTED: {
      let alertMessage = MSG_PAGING_FAILURE;
      if (action?.payload?.response?.status === 400) {
        alertMessage = getErrorMessage(action);
      }
      return {
        ...state,
        loading: false,
        alertMessage: alertMessage
      };
    }

    case DRIVER_SALARY.LOCK_INFO_SALARY_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case DRIVER_SALARY.LOCK_INFO_SALARY_FULFILLED: {
      return {
        ...state,
        loading: false,
        alertMessage: 'Cập nhật thành công !'
      };
    }
    case DRIVER_SALARY.LOCK_INFO_SALARY_REJECTED: {
      let alertMessage = MSG_PAGING_FAILURE;
      if (action?.payload?.response?.status === 400) {
        alertMessage = getErrorMessage(action);
      }
      return {
        ...state,
        loading: false,
        alertMessage: alertMessage
      };
    }

    case DRIVER_SALARY.HANDEL_DETAIL_DRIVER_SALARY: {
      return {
        ...state,
        driverSalary: action.payload
      };
    }
    case DRIVER_SALARY.UPDATE_COMPANY_PAID_WEIGHT_LOSS_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case DRIVER_SALARY.UPDATE_COMPANY_PAID_WEIGHT_LOSS_FULFILLED: {
      return {
        ...state,
        loading: false,
        alertMessage: 'Cập nhật thông tin thành công !'
      };
    }
    case DRIVER_SALARY.UPDATE_COMPANY_PAID_WEIGHT_LOSS_REJECTED: {
      let alertMessage = MSG_PAGING_FAILURE;
      if (action?.payload?.response?.status === 400) {
        alertMessage = getErrorMessage(action);
      }
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage
      };
    }

    default:
      return state;
  }
};
