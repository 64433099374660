import httpClient from 'HttpClient';
// define const actions
export const SPAREPART_DETAIL_MG_ACTIONS = {
  GET_SPAREPART_DETAIL_PER_PAGE: 'GET_SPAREPART_DETAIL_PER_PAGE',
  GET_SPAREPART_DETAIL_PER_PAGE_PENDING: 'GET_SPAREPART_DETAIL_PER_PAGE_PENDING',
  GET_SPAREPART_DETAIL_PER_PAGE_FULFILLED: 'GET_SPAREPART_DETAIL_PER_PAGE_FULFILLED',
  GET_SPAREPART_DETAIL_PER_PAGE_REJECTED: 'GET_SPAREPART_DETAIL_PER_PAGE_REJECTED',

  GET_SPAREPART_BY_RECEIVINGID_PER_PAGE: 'GET_SPAREPART_BY_RECEIVINGID_PER_PAGE',
  GET_SPAREPART_BY_RECEIVINGID_PER_PAGE_PENDING: 'GET_SPAREPART_BY_RECEIVINGID_PER_PAGE_PENDING',
  GET_SPAREPART_BY_RECEIVINGID_PER_PAGE_FULFILLED: 'GET_SPAREPART_BY_RECEIVINGID_PER_PAGE_FULFILLED',
  GET_SPAREPART_BY_RECEIVINGID_PER_PAGE_REJECTED: 'GET_SPAREPART_BY_RECEIVINGID_PER_PAGE_REJECTED',

  //   SEARCH_ACCOUNTING_REPAIRING_ITEMS: 'SEARCH_ACCOUNTING_REPAIRING_ITEMS',
  //   SEARCH_ACCOUNTING_REPAIRING_ITEMS_PENDING: 'SEARCH_ACCOUNTING_REPAIRING_ITEMS_PENDING',
  //   SEARCH_ACCOUNTING_REPAIRING_ITEMS_FULFILLED: 'SEARCH_ACCOUNTING_REPAIRING_ITEMS_FULFILLED',
  //   SEARCH_ACCOUNTING_REPAIRING_ITEMS_REJECTED: 'SEARCH_ACCOUNTING_REPAIRING_ITEMS_REJECTED',

  GET_SPAREPART_DETAIL_BY_SPAREPART: 'GET_SPAREPART_DETAIL_BY_SPAREPART',
  GET_SPAREPART_DETAIL_BY_SPAREPART_PENDING: 'GET_SPAREPART_DETAIL_BY_SPAREPART_PENDING',
  GET_SPAREPART_DETAIL_BY_SPAREPART_FULFILLED: 'GET_SPAREPART_DETAIL_BY_SPAREPART_FULFILLED',
  GET_SPAREPART_DETAIL_BY_SPAREPART_REJECTED: 'GET_SPAREPART_DETAIL_BY_SPAREPART_REJECTED',

  CREATE_DATA_SPAREPART_DETAIL: 'CREATE_DATA_SPAREPART_DETAIL',
  CREATE_DATA_SPAREPART_DETAIL_PENDING: 'CREATE_DATA_SPAREPART_DETAIL_PENDING',
  CREATE_DATA_SPAREPART_DETAIL_FULFILLED: 'CREATE_DATA_SPAREPART_DETAIL_FULFILLED',
  CREATE_DATA_SPAREPART_DETAIL_REJECTED: 'CREATE_DATA_SPAREPART_DETAIL_REJECTED',

  UPDATE_DATA_SPAREPART_DETAIL: 'UPDATE_DATA_SPAREPART_DETAIL',
  UPDATE_DATA_SPAREPART_DETAIL_PENDING: 'UPDATE_DATA_SPAREPART_DETAIL_PENDING',
  UPDATE_DATA_SPAREPART_DETAIL_FULFILLED: 'UPDATE_DATA_SPAREPART_DETAIL_FULFILLED',
  UPDATE_DATA_SPAREPART_DETAIL_REJECTED: 'UPDATE_DATA_SPAREPART_DETAIL_REJECTED',

  DELETE_DATA_SPAREPART_DETAIL: 'DELETE_DATA_SPAREPART_DETAIL',
  DELETE_DATA_SPAREPART_DETAIL_PENDING: 'DELETE_DATA_SPAREPART_DETAIL_PENDING',
  DELETE_DATA_SPAREPART_DETAIL_FULFILLED: 'DELETE_DATA_SPAREPART_DETAIL_FULFILLED',
  DELETE_DATA_SPAREPART_DETAIL_REJECTED: 'DELETE_DATA_SPAREPART_DETAIL_REJECTED',

  GET_POSITION_TYPE_REPAIR: 'GET_POSITION_TYPE_REPAIR',
  GET_POSITION_TYPE_REPAIR_PENDING: 'GET_POSITION_TYPE_REPAIR_PENDING',
  GET_POSITION_TYPE_REPAIR_FULFILLED: 'GET_POSITION_TYPE_REPAIR_FULFILLED',
  GET_POSITION_TYPE_REPAIR_REJECTED: 'GET_POSITION_TYPE_REPAIR_REJECTED',

  GET_DETAIL_BY_PROVIDER_AND_SPAREPART: 'GET_DETAIL_BY_PROVIDER_AND_SPAREPART',
  GET_DETAIL_BY_PROVIDER_AND_SPAREPART_PENDING: 'GET_DETAIL_BY_PROVIDER_AND_SPAREPART_PENDING',
  GET_DETAIL_BY_PROVIDER_AND_SPAREPART_FULFILLED: 'GET_DETAIL_BY_PROVIDER_AND_SPAREPART_FULFILLED',
  GET_DETAIL_BY_PROVIDER_AND_SPAREPART_REJECTED: 'GET_DETAIL_BY_PROVIDER_AND_SPAREPART_REJECTED',

  GET_INFO_SPAREPART_DEFAULT: 'GET_INFO_SPAREPART_DEFAULT',
  GET_INFO_SPAREPART_DEFAULT_PENDING: 'GET_INFO_SPAREPART_DEFAULT_PENDING',
  GET_INFO_SPAREPART_DEFAULT_FULFILLED: 'GET_INFO_SPAREPART_DEFAULT_FULFILLED',
  GET_INFO_SPAREPART_DEFAULT_REJECTED: 'GET_INFO_SPAREPART_DEFAULT_REJECTED',

  GET_SPAREPART_EXIST: 'GET_SPAREPART_EXIST',
  GET_SPAREPART_EXIST_PENDING: 'GET_SPAREPART_EXIST_PENDING',
  GET_SPAREPART_EXIST_FULFILLED: 'GET_SPAREPART_EXIST_FULFILLED',
  GET_SPAREPART_EXIST_REJECTED: 'GET_SPAREPART_EXIST_REJECTED',

  UPDATE_SHOW_ALERT: 'UPDATE_SHOW_ALERT',

  RESET_SPAREPART_DETAIL: 'RESET_SPAREPART_DETAIL'
};

// define actions
export const getDataSparepartDetailPerpage = (value, sparePartInfoId, pageNo, pageSize) => (dispatch) => {
  const payload = {
    // value: value,
    sparePartInfoId: sparePartInfoId,
    page: pageNo,
    pagesize: pageSize
  };
  return dispatch({
    type: SPAREPART_DETAIL_MG_ACTIONS.GET_SPAREPART_DETAIL_PER_PAGE,
    payload: httpClient.get(`/SparePartDetail/paging/bySparePartInfo?`, { params: payload })
  });
};

// define actions
export const resetSparepartDetail = () => (dispatch) => {
  return dispatch({
    type: SPAREPART_DETAIL_MG_ACTIONS.RESET_SPAREPART_DETAIL
  });
};

export const getDataSparepartByReceivingPerpage = (receivingId, pageNo, pageSize) => (dispatch) => {
  const payload = {
    receivingId: receivingId,
    page: pageNo,
    pagesize: pageSize
  };
  return dispatch({
    type: SPAREPART_DETAIL_MG_ACTIONS.GET_SPAREPART_BY_RECEIVINGID_PER_PAGE,
    payload: httpClient.get(`/SparePartDetail/paging/bySparePartReceiving?`, { params: payload })
  });
};

// define actions
export const updateShowAlert = () => {
  return {
    type: SPAREPART_DETAIL_MG_ACTIONS.UPDATE_SHOW_ALERT
  };
};

export const createDataSparepartDetail = (payload) => (dispatch) => {
  return dispatch({
    type: SPAREPART_DETAIL_MG_ACTIONS.CREATE_DATA_SPAREPART_DETAIL,
    payload: httpClient.post(`/SparePartDetail/create`, payload)
  });
};

export const updateDataSparepartDetail = (payload) => (dispatch) => {
  return dispatch({
    type: SPAREPART_DETAIL_MG_ACTIONS.UPDATE_DATA_SPAREPART_DETAIL,
    payload: httpClient.put(`/SparePartDetail/update`, payload)
  });
};

export const deleteDataSparepartDetail = (id) => (dispatch) => {
  return dispatch({
    type: SPAREPART_DETAIL_MG_ACTIONS.DELETE_DATA_SPAREPART_DETAIL,
    payload: httpClient.delete(`/SparePartDetail/delete/${id}`)
  });
};

export const getListSparepartBySparepartInfo = (sparePartInfoId) => (dispatch) => {
  return dispatch({
    type: SPAREPART_DETAIL_MG_ACTIONS.GET_SPAREPART_DETAIL_BY_SPAREPART,
    payload: httpClient.get(`/SparePartDetail/getBySparePartInfoId/${sparePartInfoId}`)
  });
};

export const getPositionTypeRepair = () => (dispatch) => {
  return dispatch({
    type: SPAREPART_DETAIL_MG_ACTIONS.GET_POSITION_TYPE_REPAIR,
    payload: httpClient.get(`/TyrePosition/getAll`)
  });
};

export const getSparepartDetailByProviderAndSparepartId = (sparepartId, providerId) => (dispatch) => {
  return dispatch({
    type: SPAREPART_DETAIL_MG_ACTIONS.GET_DETAIL_BY_PROVIDER_AND_SPAREPART,
    payload: httpClient.get(
      `/SparePartDetail/getAllSparePartDetailForRepair?providerId=${providerId}&sparePartId=${sparepartId}`
    )
  });
};

export const getDefaultValueSparepart = (sparepartId, customerId) => (dispatch) => {
  return dispatch({
    type: SPAREPART_DETAIL_MG_ACTIONS.GET_INFO_SPAREPART_DEFAULT,
    payload: httpClient.get(`/SparePartWareHouse/getById?customerId=${customerId}&sparePartId=${sparepartId}`)
  });
};

export const getExistValueSparepart = (sparepartId, serial) => (dispatch) => {
  return dispatch({
    type: SPAREPART_DETAIL_MG_ACTIONS.GET_SPAREPART_EXIST,
    payload: httpClient.get(`/SparePartDetail/existsSerial?sparePartId=${sparepartId}&serial=${serial}`)
  });
};
