import { TRUCKING_FEE_ACTIONS } from 'actions';
import { DRIVER_PLAN_ACTIONS, SET_CONT_INFO, SET_DRIVER_PLAN_SEARCH_PARAMS, SET_ROUTES } from 'actions/driverplan';
import { getErrorMessage } from 'commons/ReducerUtils';
import {
  DEFAULT_PAGE_SIZE,
  INTERRUPT_API,
  MSG_ADD_FAILURE,
  MSG_ADD_SUCCESS,
  MSG_DELETE_FAILURE,
  MSG_DELETE_SUCCESS,
  MSG_EDIT_FAILURE,
  MSG_EDIT_SUCCESS
} from 'const/common';
import moment from 'moment';

const SEARCH_START_DATE_KEY = 'searchStartDate';
const SEARCH_END_DATE_KEY = 'searchEndDate';
const SEARCH_TEXT_KEY = 'searchText';
const SEARCH_DATE_SAVED_FORMAT = 'YYYY-MM-DD';
const mapKeys = {
  startDate: SEARCH_START_DATE_KEY,
  endDate: SEARCH_END_DATE_KEY,
  value: SEARCH_TEXT_KEY
};

function getDefaultDate(saveKey) {
  const savedDate = localStorage.getItem(saveKey);
  let date = moment();
  if (savedDate) {
    date = moment(savedDate, SEARCH_DATE_SAVED_FORMAT);
  }
  return date;
}

function getDefaultStartDate() {
  const startDate = getDefaultDate(SEARCH_START_DATE_KEY);
  return startDate;
}
function getDefaultEndDate() {
  const endDate = getDefaultDate(SEARCH_END_DATE_KEY);
  return endDate;
}

function getDefaultTextSearch() {
  const savedValue = localStorage.getItem(SEARCH_TEXT_KEY);
  let value = '';
  if (savedValue) {
    value = savedValue;
  }
  return value;
}

function saveDefaultDate(savedKey, value) {
  let data = value;
  try {
    data = value.format(SEARCH_DATE_SAVED_FORMAT);
  } catch (error) {
    // do nothing
  }
  localStorage.setItem(savedKey, data);
}

const INIT_STATE = {
  search: {
    startDate: getDefaultStartDate(),
    endDate: getDefaultEndDate(),
    value: getDefaultTextSearch()
  },
  data: [],
  pagination: {
    pageSize: DEFAULT_PAGE_SIZE,
    pageNo: 1
  },
  input: {
    pagination: {
      pageSize: DEFAULT_PAGE_SIZE,
      pageNo: 1
    },
    data: []
  },
  defaultRoutes: [{}],
  summaryCars: [],
  contInfos: [],
  inputDetail: {},
  isLoadingSummary: false,
  isLoading: false,
  tractorTrailerByEmployee: []
};

const driverPlanReducer = (state = INIT_STATE, action) => {
  //   console.log('actions driver plan', action);
  switch (action.type) {
    case DRIVER_PLAN_ACTIONS.GET_DRIVER_PLAN_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case DRIVER_PLAN_ACTIONS.GET_DRIVER_PLAN_PER_PAGE_FULFILLED: {
      let input = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        input: input,
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isLoading: false
      };
    }

    case DRIVER_PLAN_ACTIONS.GET_DRIVER_PLAN_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // search
    case DRIVER_PLAN_ACTIONS.SEARCH_DRIVER_PLAN_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case DRIVER_PLAN_ACTIONS.SEARCH_DRIVER_PLAN_PER_PAGE_FULFILLED: {
      const data =
        action.payload.data.pagination.pageNo === 1
          ? action.payload.data.data
          : state.input.data.concat(action.payload.data.data);
      let input = {
        pagination: action.payload.data.pagination,
        data,
        isMsg: false
      };
      return {
        ...state,
        input: input,
        pagination: action.payload.data.pagination,
        data: data,
        isLoading: false
      };
    }

    case DRIVER_PLAN_ACTIONS.SEARCH_DRIVER_PLAN_PER_PAGE_REJECTED:
      // eslint-disable-next-line no-case-declarations
      const isLoading = action?.payload?.message === INTERRUPT_API ? true : false;

      return {
        ...state,
        isLoading: isLoading,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // get by id
    case DRIVER_PLAN_ACTIONS.GET_DRIVER_PLAN_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case DRIVER_PLAN_ACTIONS.GET_DRIVER_PLAN_BY_ID_FULFILLED: {
      let inputDetail = {
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        inputDetail: inputDetail,
        isLoading: false
      };
    }

    case DRIVER_PLAN_ACTIONS.GET_DRIVER_PLAN_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi',
        typeMessage: 'error'
      };

    // create
    case DRIVER_PLAN_ACTIONS.CREATE_DRIVER_PLAN_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case DRIVER_PLAN_ACTIONS.CREATE_DRIVER_PLAN_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: MSG_ADD_SUCCESS
      };

    case DRIVER_PLAN_ACTIONS.CREATE_DRIVER_PLAN_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: getErrorMessage(action, MSG_ADD_FAILURE)
      };

    // update
    case DRIVER_PLAN_ACTIONS.UPDATE_DRIVER_PLAN_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case DRIVER_PLAN_ACTIONS.UPDATE_DRIVER_PLAN_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: MSG_EDIT_SUCCESS
      };

    case DRIVER_PLAN_ACTIONS.UPDATE_DRIVER_PLAN_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: getErrorMessage(action, MSG_EDIT_FAILURE)
      };

    // delete
    case DRIVER_PLAN_ACTIONS.DELETE_DRIVER_PLAN_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case DRIVER_PLAN_ACTIONS.DELETE_DRIVER_PLAN_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: MSG_DELETE_SUCCESS
      };

    case DRIVER_PLAN_ACTIONS.DELETE_DRIVER_PLAN_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: getErrorMessage(action, MSG_DELETE_FAILURE)
      };

    case SET_DRIVER_PLAN_SEARCH_PARAMS: {
      const payload = action.payload;
      let newSearchState = {};
      Object.keys(payload).forEach((key) => {
        newSearchState[key] = payload[key];
        if (Object.keys(mapKeys).includes(key)) {
          if (payload[key] !== null && payload[key] !== undefined) {
            saveDefaultDate(mapKeys[key], payload[key]);
          }
        }
      });
      return {
        ...state,
        search: {
          ...state.search,
          ...newSearchState
        }
      };
    }

    case SET_ROUTES: {
      return {
        ...state,
        defaultRoutes: action.payload
      };
    }

    case SET_CONT_INFO: {
      return {
        ...state,
        contInfos: action.payload
      };
    }

    // delete
    case DRIVER_PLAN_ACTIONS.SAVE_FAST_DISPATCHER_NO_CONSIGNMENT_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case DRIVER_PLAN_ACTIONS.SAVE_FAST_DISPATCHER_NO_CONSIGNMENT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Thêm mới dữ liệu thành công'
      };

    case DRIVER_PLAN_ACTIONS.SAVE_FAST_DISPATCHER_NO_CONSIGNMENT_REJECTED: {
      const msg = getErrorMessage(action, 'Đã xảy ra lỗi');
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: msg
      };
    }

    case DRIVER_PLAN_ACTIONS.GET_TRUCKING_PLAN_COMMAND_BY_ID_PENDING: {
      return {
        ...state,
        isLoading: true
      };
    }

    case DRIVER_PLAN_ACTIONS.GET_TRUCKING_PLAN_COMMAND_BY_ID_FULFILLED:
      const payload = action.payload.data.data && action.payload.data.data[0];
      return {
        ...state,
        itemTruckingPlanCommand: payload,
        isLoading: false
      };

    case DRIVER_PLAN_ACTIONS.GET_TRUCKING_PLAN_COMMAND_BY_ID_REJECTED: {
      const msg = getErrorMessage(action, 'Đã xảy ra lỗi');
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: msg
      };
    }

    case DRIVER_PLAN_ACTIONS.UPDATE_TRUCKING_STATUS_PENDING: {
      return {
        ...state,
        isLoading: true
      };
    }

    case DRIVER_PLAN_ACTIONS.UPDATE_TRUCKING_STATUS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: MSG_EDIT_SUCCESS
      };

    case DRIVER_PLAN_ACTIONS.UPDATE_TRUCKING_STATUS_REJECTED: {
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: getErrorMessage(action, MSG_EDIT_FAILURE)
      };
    }
    // UPDATE VAT
    case DRIVER_PLAN_ACTIONS.UPDATE_INFO_VAT_PENDING: {
      return {
        ...state,
        isLoading: true
      };
    }

    case DRIVER_PLAN_ACTIONS.UPDATE_INFO_VAT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: MSG_EDIT_SUCCESS
      };

    case DRIVER_PLAN_ACTIONS.UPDATE_INFO_VAT_REJECTED: {
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: getErrorMessage(action, MSG_EDIT_FAILURE)
      };
    }

    // search
    case DRIVER_PLAN_ACTIONS.GET_SUMMARY_CAR_DAY_PENDING:
      return {
        ...state,
        isLoadingSummary: true
      };

    case DRIVER_PLAN_ACTIONS.GET_SUMMARY_CAR_DAY_FULFILLED: {
      return {
        ...state,
        summaryCars: action.payload.data.data,
        isLoadingSummary: false
      };
    }

    case DRIVER_PLAN_ACTIONS.GET_SUMMARY_CAR_DAY_REJECTED:
      return {
        ...state,
        isLoadingSummary: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    //dieu xe ghep
    case DRIVER_PLAN_ACTIONS.GET_DRIVER_PLAN_BY_ID_V2_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case DRIVER_PLAN_ACTIONS.GET_DRIVER_PLAN_BY_ID_V2_FULFILLED: {
      let inputDetail = {
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        inputDetail: inputDetail,
        isLoading: false
      };
    }

    case DRIVER_PLAN_ACTIONS.GET_DRIVER_PLAN_BY_ID_V2_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi',
        typeMessage: 'error'
      };

    case DRIVER_PLAN_ACTIONS.RESET_SEARCH_DRIVER_PLAN_PER_PAGE:
      return {
        ...state,
        input: {},
        pagination: {},
        data: [],
        isLoading: false
      };

    case DRIVER_PLAN_ACTIONS.LINK_SCREEN_CONSIGNMENT:
      return {
        ...state,
        screenConsignment: action.payload.consignmentCode,
        search: {
          ...state.search,
          startDate: action.payload.createDate,
          endDate: action.payload.DeliveryDate
        }
      };
    case DRIVER_PLAN_ACTIONS.GET_TRACTOR_TRAILER_BY_EMPLOYEE_FULFILLED:
      return {
        ...state,
        tractorTrailerByEmployee: action.payload.data.data
      };

    case TRUCKING_FEE_ACTIONS.UPDATE_FULL_STANDARD_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRUCKING_FEE_ACTIONS.UPDATE_FULL_STANDARD_FULFILLED:
      return {
        ...state,
        isLoading: false
      };

    case TRUCKING_FEE_ACTIONS.UPDATE_FULL_STANDARD_REJECTED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default driverPlanReducer;
