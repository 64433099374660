import httpClient from 'HttpClient';
import { deleteItem } from 'services/shippingLine';
// define const actions
export const SHIPPING_LINE_ACTIONS = {
  GET_SHIPPING_LINE_PER_PAGE: 'GET_SHIPPING_LINE_PER_PAGE',
  GET_SHIPPING_LINE_PER_PAGE_PENDING: 'GET_SHIPPING_LINE_PER_PAGE_PENDING',
  GET_SHIPPING_LINE_PER_PAGE_FULFILLED: 'GET_SHIPPING_LINE_PER_PAGE_FULFILLED',
  GET_SHIPPING_LINE_PER_PAGE_REJECTED: 'GET_SHIPPING_LINE_PER_PAGE_REJECTED',

  GET_SHIPPING_LINE_BY_ID: 'GET_SHIPPING_LINE_BY_ID',
  GET_SHIPPING_LINE_BY_ID_PENDING: 'GET_SHIPPING_LINE_BY_ID_PENDING',
  GET_SHIPPING_LINE_BY_ID_FULFILLED: 'GET_SHIPPING_LINE_BY_ID_FULFILLED',
  GET_SHIPPING_LINE_BY_ID_REJECTED: 'GET_SHIPPING_LINE_BY_ID_REJECTED',

  CREATE_SHIPPING_LINE: 'CREATE_SHIPPING_LINE',
  CREATE_SHIPPING_LINE_PENDING: 'CREATE_SHIPPING_LINE_PENDING',
  CREATE_SHIPPING_LINE_FULFILLED: 'CREATE_SHIPPING_LINE_FULFILLED',
  CREATE_SHIPPING_LINE_REJECTED: 'CREATE_SHIPPING_LINE_REJECTED',

  UPDATE_SHIPPING_LINE: 'UPDATE_SHIPPING_LINE',
  UPDATE_SHIPPING_LINE_PENDING: 'UPDATE_SHIPPING_LINE_PENDING',
  UPDATE_SHIPPING_LINE_FULFILLED: 'UPDATE_SHIPPING_LINE_FULFILLED',
  UPDATE_SHIPPING_LINE_REJECTED: 'UPDATE_SHIPPING_LINE_REJECTED',

  DELETE_SHIPPING_LINE: 'DELETE_SHIPPING_LINE',
  DELETE_SHIPPING_LINE_PENDING: 'DELETE_SHIPPING_LINE_PENDING',
  DELETE_SHIPPING_LINE_FULFILLED: 'DELETE_SHIPPING_LINE_FULFILLED',
  DELETE_SHIPPING_LINE_REJECTED: 'DELETE_SHIPPING_LINE_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  GET_ALL_SHIPPING_LINE: 'GET_ALL_SHIPPING_LINE',
  GET_ALL_SHIPPING_LINE_FULFILLED: 'GET_ALL_SHIPPING_LINE_FULFILLED'
};

// define actions
export const actionGetShippingLinePerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: SHIPPING_LINE_ACTIONS.GET_SHIPPING_LINE_PER_PAGE,
    payload: httpClient.get(`/ShippingLineInfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`)
  });
};
export const actionGetShippingLinePerPageAll = (params) => (dispatch) => {
  return dispatch({
    // type: SHIPPING_LINE_ACTIONS.GET_SHIPPING_LINE_PER_PAGE,
    payload: httpClient.get(`/ShippingLineInfo/paging/page`, { params })
  });
};

export const actionGetShippingLineByID = (id) => (dispatch) => {
  return dispatch({
    type: SHIPPING_LINE_ACTIONS.GET_SHIPPING_LINE_BY_ID,
    payload: httpClient.get(`/ShippingLineInfo/getById/${id}`)
  });
};

export const actionCreateShippingLine = (payload) => (dispatch) => {
  return dispatch({
    type: SHIPPING_LINE_ACTIONS.CREATE_SHIPPING_LINE,
    payload: httpClient.post(`/ShippingLineInfo/create`, payload)
  });
};

export const actionUpdateShippingLine = (payload) => (dispatch) => {
  return dispatch({
    type: SHIPPING_LINE_ACTIONS.UPDATE_SHIPPING_LINE,
    payload: httpClient.put(`/ShippingLineInfo/update`, payload)
  });
};

export const actionDeleteShippingLine = (id) => (dispatch) => {
  return dispatch({
    type: SHIPPING_LINE_ACTIONS.DELETE_SHIPPING_LINE,
    payload: deleteItem(id)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const actionSearchShippingLine = (value, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: SHIPPING_LINE_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/ShippingLineInfo/search?value=${value}&Page=${pageNo}&PageSize=${pageSize}`)
  });
};
export const actionSearchShippingLineAll = (params) => (dispatch) => {
  return dispatch({
    // type: SHIPPING_LINE_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/ShippingLineInfo/search`, { params })
  });
};

export const getAll = () => (dispatch) => {
  return dispatch({
    type: SHIPPING_LINE_ACTIONS.GET_ALL_SHIPPING_LINE,
    payload: httpClient.get(`/ShippingLineInfo/getAll`)
  });
};
