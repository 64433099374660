import httpClient from 'HttpClient';
// define const actions
export const INPUT_FUEL_MANAGEMENT_ACTIONS = {
  GET_INPUT_FUEL_MANAGEMENT_PER_PAGE: 'GET_INPUT_FUEL_MANAGEMENT_PER_PAGE',
  GET_INPUT_FUEL_MANAGEMENT_PER_PAGE_PENDING: 'GET_INPUT_FUEL_MANAGEMENT_PER_PAGE_PENDING',
  GET_INPUT_FUEL_MANAGEMENT_PER_PAGE_FULFILLED: 'GET_INPUT_FUEL_MANAGEMENT_PER_PAGE_FULFILLED',
  GET_INPUT_FUEL_MANAGEMENT_PER_PAGE_REJECTED: 'GET_INPUT_FUEL_MANAGEMENT_PER_PAGE_REJECTED',

  GET_INPUT_FUEL_MANAGEMENT_BY_ID: 'GET_INPUT_FUEL_MANAGEMENT_BY_ID',
  GET_INPUT_FUEL_MANAGEMENT_BY_ID_PENDING: 'GET_INPUT_FUEL_MANAGEMENT_BY_ID_PENDING',
  GET_INPUT_FUEL_MANAGEMENT_BY_ID_FULFILLED: 'GET_INPUT_FUEL_MANAGEMENT_BY_ID_FULFILLED',
  GET_INPUT_FUEL_MANAGEMENT_BY_ID_REJECTED: 'GET_INPUT_FUEL_MANAGEMENT_BY_ID_REJECTED',

  CREATE_INPUT_FUEL_MANAGEMENT: 'CREATE_INPUT_FUEL_MANAGEMENT',
  CREATE_INPUT_FUEL_MANAGEMENT_PENDING: 'CREATE_INPUT_FUEL_MANAGEMENT_PENDING',
  CREATE_INPUT_FUEL_MANAGEMENT_FULFILLED: 'CREATE_INPUT_FUEL_MANAGEMENT_FULFILLED',
  CREATE_INPUT_FUEL_MANAGEMENT_REJECTED: 'CREATE_INPUT_FUEL_MANAGEMENT_REJECTED',

  UPDATE_INPUT_FUEL_MANAGEMENT: 'UPDATE_INPUT_FUEL_MANAGEMENT',
  UPDATE_INPUT_FUEL_MANAGEMENT_PENDING: 'UPDATE_INPUT_FUEL_MANAGEMENT_PENDING',
  UPDATE_INPUT_FUEL_MANAGEMENT_FULFILLED: 'UPDATE_INPUT_FUEL_MANAGEMENT_FULFILLED',
  UPDATE_INPUT_FUEL_MANAGEMENT_REJECTED: 'UPDATE_INPUT_FUEL_MANAGEMENT_REJECTED',

  DELETE_INPUT_FUEL_MANAGEMENT: 'DELETE_INPUT_FUEL_MANAGEMENT',
  DELETE_INPUT_FUEL_MANAGEMENT_PENDING: 'DELETE_INPUT_FUEL_MANAGEMENT_PENDING',
  DELETE_INPUT_FUEL_MANAGEMENT_FULFILLED: 'DELETE_INPUT_FUEL_MANAGEMENT_FULFILLED',
  DELETE_INPUT_FUEL_MANAGEMENT_REJECTED: 'DELETE_INPUT_FUEL_MANAGEMENT_REJECTED',

  SEARCH_INPUT_FUEL_MANAGEMENT_PER_PAGE: 'SEARCH_INPUT_FUEL_MANAGEMENT_PER_PAGE',
  SEARCH_INPUT_FUEL_MANAGEMENT_PER_PAGE_PENDING: 'SEARCH_INPUT_FUEL_MANAGEMENT_PER_PAGE_PENDING',
  SEARCH_INPUT_FUEL_MANAGEMENT_PER_PAGE_FULFILLED: 'SEARCH_INPUT_FUEL_MANAGEMENT_PER_PAGE_FULFILLED',
  SEARCH_INPUT_FUEL_MANAGEMENT_PER_PAGE_REJECTED: 'SEARCH_INPUT_FUEL_MANAGEMENT_PER_PAGE_REJECTED',

  GET_TOTAL_OIL_REMAINING: 'GET_TOTAL_OIL_REMAINING',
  GET_TOTAL_OIL_REMAINING_PENDING: 'GET_TOTAL_OIL_REMAINING_PENDING',
  GET_TOTAL_OIL_REMAINING_FULFILLED: 'GET_TOTAL_OIL_REMAINING_FULFILLED',
  GET_TOTAL_OIL_REMAINING_REJECTED: 'GET_TOTAL_OIL_REMAINING_REJECTED'
};

// define actions
export const actionGetInputFuelManagementPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: INPUT_FUEL_MANAGEMENT_ACTIONS.GET_INPUT_FUEL_MANAGEMENT_PER_PAGE,
    payload: httpClient.get(`/OilReceiving/paging/page?Page=${pageNo}&PageSize=${pageSize}`)
  });
};

export const actionSearchInputFuelManagementPerPage = (payload) => (dispatch) => {
  return dispatch({
    type: !payload.EXPORT_EXCEL_ALL ? INPUT_FUEL_MANAGEMENT_ACTIONS.SEARCH_INPUT_FUEL_MANAGEMENT_PER_PAGE : null,
    payload: httpClient.get(`/OilReceiving/search`, { params: payload })
  });
};

export const actionUpdateInputFuelManagement = (data) => (dispatch) => {
  return dispatch({
    type: INPUT_FUEL_MANAGEMENT_ACTIONS.UPDATE_INPUT_FUEL_MANAGEMENT,
    payload: httpClient.put(`/OilReceiving/update`, data)
  });
};

export const actionCreateInputFuelManagement = (data) => (dispatch) => {
  return dispatch({
    type: INPUT_FUEL_MANAGEMENT_ACTIONS.CREATE_INPUT_FUEL_MANAGEMENT,
    payload: httpClient.post(`/OilReceiving/create`, data)
  });
};

export const actionDeleteInputFuelManagement = (id) => (dispatch) => {
  return dispatch({
    type: INPUT_FUEL_MANAGEMENT_ACTIONS.DELETE_INPUT_FUEL_MANAGEMENT,
    payload: httpClient.delete(`/OilReceiving/deleteById/${id}`)
  });
};

export const actionGetTotalOilRemaining = () => (dispatch) => {
  return dispatch({
    type: INPUT_FUEL_MANAGEMENT_ACTIONS.GET_TOTAL_OIL_REMAINING,
    payload: httpClient.get(`/OilReceiving/totalOilRemaining`)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};
