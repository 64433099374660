import {
  FETCH_IMAGE_FROM_SERVER_FULFILLED,
  FETCH_IMAGE_FROM_SERVER_PENDING,
  FETCH_IMAGE_FROM_SERVER_REJECTED,
  FETCH_TEMPLATE_EMAIL_FULFILLED,
  FETCH_TEMPLATE_EMAIL_PENDING,
  FETCH_TEMPLATE_EMAIL_REJECTED,
  SEND_EMAIL_FULFILLED,
  SEND_EMAIL_PENDING,
  SEND_EMAIL_REJECTED,
  SET_CLOSE_EMAIL_MODAL,
  SET_CLOSE_SELECT_IMAGE_MODAL,
  SET_LOADING_EMAIL,
  SET_OPEN_EMAIL_MODAL,
  SET_OPEN_SELECT_IMAGE_MODAL,
  UPDATE_IMAGES_EMAIL
} from 'actions';
import { getErrorMessage } from 'commons/ReducerUtils';
import { MSG_TYPE_ERROR, MSG_TYPE_SUCCESS } from 'const/common';
const INIT_STATE = {
  //combobox: get all
  // paging
  templates: [],
  images: [],
  pagination: { pageNo: 1, pageSize: 20, totalRecord: 0, totalPage: 0 },

  // add/edit modal
  isOpenEmailFormModal: false, // Show/hidden update modal
  email: {},
  // formLoading: false,
  // //delete
  // deleteLoading: false,
  // other
  showMessage: false,
  formAlertMessage: '',
  messageType: '',
  paginationImage: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region update modal
    case SET_OPEN_EMAIL_MODAL: {
      return {
        ...state,
        isOpenEmailFormModal: true,
        email: action.payload.data
      };
    }
    case SET_CLOSE_EMAIL_MODAL: {
      return {
        ...state,
        isOpenEmailFormModal: false,
        email: {}
      };
    }
    //#endregion
    //#region send

    case SEND_EMAIL_PENDING:
      return {
        ...state,
        formLoading: true
      };
    case SEND_EMAIL_FULFILLED: {
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: 'Gửi Email tới khách hàng thành công!',
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case SEND_EMAIL_REJECTED: {
      const alertMessage = getErrorMessage(action, 'Gửi Email thất bại!');
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#end region

    // #region fetch
    case FETCH_TEMPLATE_EMAIL_PENDING: {
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    }

    case FETCH_TEMPLATE_EMAIL_FULFILLED: {
      const templates = action.payload.data.data;
      return {
        ...state,
        formLoading: false,
        templates: templates,
        showMessage: false
      };
    }
    case FETCH_TEMPLATE_EMAIL_REJECTED: {
      const alertMessage = getErrorMessage(action, 'Lấy dữ liệu thất bại!');
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#end region

    case SET_LOADING_EMAIL: {
      return {
        ...state,
        formLoading: true
      };
    }

    //#region update modal select image
    case SET_OPEN_SELECT_IMAGE_MODAL: {
      return {
        ...state,
        isOpenSelectImageFormModal: true
      };
    }
    case SET_CLOSE_SELECT_IMAGE_MODAL: {
      return {
        ...state,
        isOpenSelectImageFormModal: false
      };
    }
    //#end region

    // #region fetch image
    case FETCH_IMAGE_FROM_SERVER_PENDING: {
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    }

    case FETCH_IMAGE_FROM_SERVER_FULFILLED: {
      const data = action.payload.data.data;
      const images = action?.meta?.funcProcessDataImage
        ? action.meta.funcProcessDataImage(data)
        : data.map((item) => {
            const preUrrl = process.env.REACT_APP_HOST_URL + '/';
            const url = preUrrl + item.ImageUrl;
            return {
              ...item,
              src: url,
              thumbnail: url,
              thumbnailWidth: 253,
              thumbnailHeight: 180,
              name: item.Name,
              urlOrigin: item.ImageUrl,
              thumbnailCaption: item.Name
            };
          });
      const paginationImage = action.payload.data.pagination;
      return {
        ...state,
        formLoading: false,
        images: images,
        showMessage: false,
        paginationImage
      };
    }
    case FETCH_IMAGE_FROM_SERVER_REJECTED: {
      const alertMessage = getErrorMessage(action, 'Lấy dữ liệu thất bại!');
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    case UPDATE_IMAGES_EMAIL: {
      const images = action.payload.data.map((item) => {
        const preUrrl = process.env.REACT_APP_HOST_URL + '/';
        const url = preUrrl + item.ImageUrl;
        return {
          src: url,
          thumbnail: url,
          thumbnailWidth: 253,
          thumbnailHeight: 180,
          name: item.Name,
          urlOrigin: item.ImageUrl,
          thumbnailCaption: item.Name
        };
      });
      const paginationImage = action.payload.pagination;
      return {
        ...state,
        formLoading: false,
        images: images,
        showMessage: false,
        paginationImage
      };
    }
    //#end region
    default:
      return state;
  }
};
