import httpClient from 'HttpClient';

export const PROFIT_BY_VEHICLE = {
  GET_REVENUE_BY_CAR_REPORT: 'GET_REVENUE_BY_CAR_REPORT',
  GET_REVENUE_BY_CAR_REPORT_PENDING: 'GET_REVENUE_BY_CAR_REPORT_PENDING',
  GET_REVENUE_BY_CAR_REPORT_FULFILLED: 'GET_REVENUE_BY_CAR_REPORT_FULFILLED',
  GET_REVENUE_BY_CAR_REPORT_REJECTED: 'GET_REVENUE_BY_CAR_REPORT_REJECTED',

  UPDATE_REVENUE_BY_CAR_REPORT: 'UPDATE_REVENUE_BY_CAR_REPORT',
  UPDATE_REVENUE_BY_CAR_REPORT_PENDING: 'UPDATE_REVENUE_BY_CAR_REPORT_PENDING',
  UPDATE_REVENUE_BY_CAR_REPORT_FULFILLED: 'UPDATE_REVENUE_BY_CAR_REPORT_FULFILLED',
  UPDATE_REVENUE_BY_CAR_REPORT_REJECTED: 'UPDATE_REVENUE_BY_CAR_REPORT_REJECTED'
};

export const actionGetRevenueByCarReport = (params) => (dispatch) => {
  return dispatch({
    type: PROFIT_BY_VEHICLE.GET_REVENUE_BY_CAR_REPORT,
    payload: httpClient.get(`/RevenueByCarReport/AOECarReport`, { params })
  });
};

export const actionGetRevenueByCarReportALL = (params) => (dispatch) => {
  return dispatch({
    payload: httpClient.get(`/RevenueByCarReport/AOECarReport`, { params })
  });
};

export const actionUpdateReportAOE = (payload) => (dispatch) => {
  return dispatch({
    type: PROFIT_BY_VEHICLE.UPDATE_REVENUE_BY_CAR_REPORT,
    payload: httpClient.post(`/RevenueByCarReport/UpdateReportAOE`, payload),
    meta: payload
  });
};
