export const TOGGLE_HEADER = 'TOGGLE_HEADER';
export const OPEN_HEADER = 'OPEN_HEADER';
export const CLOSE_HEADER = 'CLOSE_HEADER';

export const toggleHeader = () => (dispatch) => {
  return dispatch({
    type: TOGGLE_HEADER
  });
};
export const openHeader = () => (dispatch) => {
  return dispatch({
    type: OPEN_HEADER
  });
};
export const closeHeader = () => (dispatch) => {
  return dispatch({
    type: CLOSE_HEADER
  });
};
