import { ADDRESS_ACTIONS } from "actions/addressActions";

const INIT_STATE = {
  loading: false,
  typeMessage: "",
  message: "",
  isShowMessage: false,
  listCity: [],
  listDistrict: [],
  listSubDistrict: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADDRESS_ACTIONS.GET_CITY_PENDING: {
      return {
        ...state,
        loadingSelect: true,
        isShowMessage: false,
      };
    }
    case ADDRESS_ACTIONS.GET_CITY_FULFILLED: {
      return {
        ...state,
        loadingSelect: false,
        listCity: action.payload.data.data,
        loading: false,
        isShowMessage: false,
      };
    }
    case ADDRESS_ACTIONS.GET_CITY_REJECTED: {
      return {
        ...state,
        loadingSelect: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Có lỗi xảy ra !",
      };
    }
    case ADDRESS_ACTIONS.GET_DISTRICT_PENDING: {
      return {
        ...state,
        loadingSelect: true,
        isShowMessage: false,
      };
    }
    case ADDRESS_ACTIONS.GET_DISTRICT_FULFILLED: {
      return {
        ...state,
        loadingSelect: false,
        listDistrict: action.payload.data.data,
        loading: false,
        isShowMessage: false,
      };
    }
    case ADDRESS_ACTIONS.GET_DISTRICT_REJECTED: {
      return {
        ...state,
        loadingSelect: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Có lỗi xảy ra !",
      };
    }
    case ADDRESS_ACTIONS.GET_SUBDISTRICT_PENDING: {
      return {
        ...state,
        loadingSelect: true,
        isShowMessage: false,
      };
    }
    case ADDRESS_ACTIONS.GET_SUBDISTRICT_FULFILLED: {
      return {
        ...state,
        loadingSelect: false,
        listSubDistrict: action.payload.data.data,
        loading: false,
        isShowMessage: false,
      };
    }
    case ADDRESS_ACTIONS.GET_SUBDISTRICT_REJECTED: {
      return {
        ...state,
        loadingSelect: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Có lỗi xảy ra !",
      };
    }

    
    case ADDRESS_ACTIONS.GET_DEPARTURES_PENDING: {
      return {
        ...state,
        loadingSelect: true
      };
    }
    case ADDRESS_ACTIONS.GET_DEPARTURES_FULFILLED: {
      return {
        ...state,
        loadingSelect: false,
        departures: action.payload.data.data
      };
    }
    case ADDRESS_ACTIONS.GET_DEPARTURES_REJECTED: {
      let alertMessage = "Có lỗi xảy ra !";
      if (action.payload.response.data.status === 400) {
        alertMessage = action.payload.response.data.message;
      }
      return {
        ...state,
        loadingSelect: false,
        isShowMessage: true,
        typeMessage: "error",
        message: alertMessage,
      };
    }

    case ADDRESS_ACTIONS.GET_DESTINATIONS_PENDING: {
      return {
        ...state,
        loadingSelect: true
      };
    }
    case ADDRESS_ACTIONS.GET_DESTINATIONS_FULFILLED: {
      return {
        ...state,
        loadingSelect: false,
        destinations: action.payload.data.data
      };
    }
    case ADDRESS_ACTIONS.GET_DESTINATIONS_REJECTED: {
      let alertMessage = "Có lỗi xảy ra !";
      if (action.payload.response.data.status === 400) {
        alertMessage = action.payload.response.data.message;
      }
      return {
        ...state,
        loadingSelect: false,
        isShowMessage: true,
        typeMessage: "error",
        message: alertMessage,
      };
    }
    default:
      return state;
  }
};
