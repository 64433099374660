import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import babyBoring from 'assets/500error.png';

class Page500 extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Row className="justify-content-center">
                <img style={{ width: '80%' }} src={babyBoring} alt=""></img>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="6">
              <span className="clearfix">
                <h1 className="float-left display-3 mr-4">500</h1>
                <h4 className="pt-3">Oh, xin lỗi đã để bạn gặp phải sự cố này!</h4>
                <p className="text-muted float-left">Trang bạn yêu cầu tạm thời không hoạt động hoặc chưa có sẵn.</p>
                <p className="text-muted float-left">
                  <a
                    href="#!"
                    onClick={(event) => {
                      event.preventDefault();
                      window.location.reload();
                    }}
                  >
                    Tải lại trang
                  </a>{' '}
                  hoặc di chuyển đến <a href="/">trang chủ</a>.
                </p>
              </span>
              {/* <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-search"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input size="16" type="text" placeholder="What are you looking for?" />
                <InputGroupAddon addonType="append">
                  <Button color="info">Search</Button>
                </InputGroupAddon>
              </InputGroup> */}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page500;
