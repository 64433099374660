import httpClient from 'HttpClient';
export const ROUTE_COST_FEE_ACTIONS = {
  GET_LIST_ROUTE_COST_FEE: 'GET_LIST_ROUTE_COST_FEE',
  GET_LIST_ROUTE_COST_FEE_PENDING: 'GET_LIST_ROUTE_COST_FEE_PENDING',
  GET_LIST_ROUTE_COST_FEE_FULFILLED: 'GET_LIST_ROUTE_COST_FEE_FULFILLED',
  GET_LIST_ROUTE_COST_FEE_REJECTED: 'GET_LIST_ROUTE_COST_FEE_REJECTED',

  UPDATE_PRICE_ROUTE_COST_FEE: 'UPDATE_PRICE_ROUTE_COST_FEE',
  UPDATE_PRICE_ROUTE_COST_FEE_PENDING: 'UPDATE_PRICE_ROUTE_COST_FEE_PENDING',
  UPDATE_PRICE_ROUTE_COST_FEE_FULFILLED: 'UPDATE_PRICE_ROUTE_COST_FEE_FULFILLED',
  UPDATE_PRICE_ROUTE_COST_FEE_REJECTED: 'UPDATE_PRICE_ROUTE_COST_FEE_REJECTED',

  UPDATE_ROUTE_COST_FEE: 'UPDATE_ROUTE_COST_FEE',
  UPDATE_ROUTE_COST_FEE_PENDING: 'UPDATE_ROUTE_COST_FEE_PENDING',
  UPDATE_ROUTE_COST_FEE_FULFILLED: 'UPDATE_ROUTE_COST_FEE_FULFILLED',
  UPDATE_ROUTE_COST_FEE_REJECTED: 'UPDATE_ROUTE_COST_FEE_REJECTED',

  DELETE_DATA: 'DELETE_DATA',
  DELETE_DATA_PENDING: 'DELETE_DATA_PENDING',
  DELETE_DATA_FULFILLED: 'DELETE_DATA_FULFILLED',
  DELETE_DATA_REJECTED: 'DELETE_DATA_REJECTED',

  GET_UNIT: 'GET_UNIT',
  GET_UNIT_PENDING: 'GET_UNIT_PENDING',
  GET_UNIT_FULFILLED: 'GET_UNIT_FULFILLED',
  GET_UNIT_REJECTED: 'GET_UNIT_REJECTED',

  GET_FEE_TYPE: 'GET_FEE_TYPE',
  GET_FEE_TYPE_PENDING: 'GET_FEE_TYPE_PENDING',
  GET_FEE_TYPE_FULFILLED: 'GET_FEE_TYPE_FULFILLED',
  GET_FEE_TYPE_REJECTED: 'GET_FEE_TYPE_REJECTED',

  CHANGE_DATA_EDIT_TABEL: 'CHANGE_DATA_EDIT_TABEL',
  CHANGE_DATA_EDIT_TABEL_FULFILLED: 'CHANGE_DATA_EDIT_TABEL_FULFILLED',

  ON_KEY_UP_FIELD_ROUTE_COST: 'ON_KEY_UP_FIELD_ROUTE_COST',

  CREATE_ITEM_PAYMENT_ADDED: 'CREATE_ITEM_PAYMENT_ADDED',
  CREATE_ITEM_PAYMENT_ADDED_PENDING: 'CREATE_ITEM_PAYMENT_ADDED_PENDING',
  CREATE_ITEM_PAYMENT_ADDED_FULFILLED: 'CREATE_ITEM_PAYMENT_ADDED_FULFILLED',
  CREATE_ITEM_PAYMENT_ADDED_REJECTED: 'CREATE_ITEM_PAYMENT_ADDED_REJECTED',

  GET_LIST_IN_PAYMENT_ADDED: 'GET_LIST_IN_PAYMENT_ADDED',
  GET_LIST_IN_PAYMENT_ADDED_PENDING: 'GET_LIST_IN_PAYMENT_ADDED_PENDING',
  GET_LIST_IN_PAYMENT_ADDED_FULFILLED: 'GET_LIST_IN_PAYMENT_ADDED_FULFILLED',
  GET_LIST_IN_PAYMENT_ADDED_REJECTED: 'GET_LIST_IN_PAYMENT_ADDED_REJECTED',

  ADD_FEES: 'ADD_FEES',
  ADD_FEES_PENDING: 'ADD_FEES_PENDING',
  ADD_FEES_FULFILLED: 'ADD_FEES_FULFILLED',
  ADD_FEES_REJECTED: 'ADD_FEES_REJECTED',

  UPDATE_ELECTRONIC_TICKET: 'UPDATE_ELECTRONIC_TICKET',
  UPDATE_ELECTRONIC_TICKET_PENDING: 'UPDATE_ELECTRONIC_TICKET_PENDING',
  UPDATE_ELECTRONIC_TICKET_FULFILLED: 'UPDATE_ELECTRONIC_TICKET_FULFILLED',
  UPDATE_ELECTRONIC_TICKET_REJECTED: 'UPDATE_ELECTRONIC_TICKET_REJECTED',

  DELETE_TRANSPORT_FEE: 'DELETE_TRANSPORT_FEE',
  DELETE_TRANSPORT_FEE_PENDING: 'DELETE_TRANSPORT_FEE_PENDING',
  DELETE_TRANSPORT_FEE_FULFILLED: 'DELETE_TRANSPORT_FEE_FULFILLED',
  DELETE_TRANSPORT_FEE_REJECTED: 'DELETE_TRANSPORT_FEE_REJECTED',

  CHANGE_DATA_EDIT_TABEL_FIELD_ROUTE_COST_FEE: 'CHANGE_DATA_EDIT_TABEL_FIELD_ROUTE_COST_FEE'
};

// define actions
export const getRouteCostFees = (params) => (dispatch) => {
  return dispatch({
    type: ROUTE_COST_FEE_ACTIONS.GET_LIST_ROUTE_COST_FEE,
    payload: httpClient.get(`/transportFee/Search/date`, { params: { ...params } })
    // payload: httpClient.get(
    //   `/transportFee/Search/date?fromDate=${startDate}&toDate=${endDate}&value=${carId}&Page=${pageNo}&PageSize=${pageSize}`
    // )
  });
};
export const getRouteCostFeesAll = (params) => (dispatch) => {
  return dispatch({
    payload: httpClient.get(`/transportFee/Search/date`, { params: { ...params } })
  });
};

export const updateDownpaymentRouteCostFee = (payload) => (dispatch) => {
  return dispatch({
    type: ROUTE_COST_FEE_ACTIONS.UPDATE_PRICE_ROUTE_COST_FEE,
    payload: httpClient.put(`/transportFee/updateTransportFeePerTruckingPlan`, payload)
  });
};

export const updateItemRouteCostFee = (payload) => (dispatch) => {
  return dispatch({
    type: ROUTE_COST_FEE_ACTIONS.UPDATE_ROUTE_COST_FEE,
    payload: httpClient.put(`/transportFee/updateTransportFees`, payload)
  });
};

export const actionDeleteDownPaymentOrder = (id) => (dispatch) => {
  return dispatch({
    type: ROUTE_COST_FEE_ACTIONS.DELETE_DATA,
    payload: httpClient.delete(`/advanceonconsignment/deleteById/${id}`)
  });
};

export const getUnit = () => (dispatch) => {
  return dispatch({
    type: ROUTE_COST_FEE_ACTIONS.GET_UNIT,
    payload: httpClient.get(`/Unit/getAll/payment`)
  });
};

export const getFeeType = (id) => (dispatch) => {
  return dispatch({
    type: ROUTE_COST_FEE_ACTIONS.GET_FEE_TYPE,
    payload: httpClient.get(`/SysStandardFee/getByFeeCategoryId?feeCategoryId=${id}`)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const changeEditTableRouteCostFee = (id, fieldName, value, indexFee, indexArray) => {
  let checkValueIsBoolean = 'boolean';
  const isStringField = ['Description'].includes(fieldName);

  let dataValue = value;
  if (!isStringField) {
    const check = (value + '').includes(',');
    const regexDots = /\./g;
    // eslint-disable-next-line no-useless-escape
    const regexComma = /\,/g;
    dataValue =
      typeof value === checkValueIsBoolean
        ? value
        : !check
        ? value.replace(regexDots, '')
        : value.replace(regexComma, '');
  }

  let data = {
    Id: id,
    [fieldName]: isStringField ? value : parseInt(dataValue),
    isChange: true,
    indexFee: indexFee,
    indexArray: indexArray
  };
  return {
    type: ROUTE_COST_FEE_ACTIONS.CHANGE_DATA_EDIT_TABEL_FIELD_ROUTE_COST_FEE,
    meta: {
      value,
      fieldName
    },
    payload: data
  };
};

export const actionAddingFees = (payload) => (dispatch) => {
  return dispatch({
    type: ROUTE_COST_FEE_ACTIONS.ADD_FEES,
    payload: httpClient.post(`/TransportFee/create`, payload)
  });
};

export const actionUpdateElectronicTicket = (payload) => (dispatch) => {
  return dispatch({
    type: ROUTE_COST_FEE_ACTIONS.UPDATE_ELECTRONIC_TICKET,
    payload: httpClient.put(`/TruckingPlan/updateElectronicTicket`, payload)
  });
};

//TransportFee/delete/{id}
export const actionDeleteTransPortFee = (Id) => (dispatch) => {
  return dispatch({
    type: ROUTE_COST_FEE_ACTIONS.DELETE_TRANSPORT_FEE,
    payload: httpClient.delete(`/TransportFee/delete/${Id}`)
  });
};
