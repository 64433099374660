import { EMPLOYEES_ACTIONS } from 'actions/employees';
import { put, takeEvery } from 'redux-saga/effects';
import { getEmployeesPerPage } from 'services/employees';

function* putActionUpdateData() {
  yield put({
    type: EMPLOYEES_ACTIONS.GET_EMPLOYEES_PER_PAGE,
    payload: getEmployeesPerPage(1, parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE))
  });
}

export function* actionUpdateEmployeesData() {
  yield takeEvery(
    [
      // EMPLOYEES_ACTIONS.UPDATE_EMPLOYEES_FULFILLED,
      // EMPLOYEES_ACTIONS.CREATE_EMPLOYEES_FULFILLED,
      //   EMPLOYEES_ACTIONS.DELETE_EMPLOYEES_FULFILLED,
    ],
    putActionUpdateData
  );
}
