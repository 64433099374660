import httpClient from 'HttpClient';

// const type of function get data per page

export const SUMMARY_ACTIONS = {
  SUM_BY_TRANSPORT_COMPANY: 'SUM_BY_TRANSPORT_COMPANY',
  SUM_BY_TRANSPORT_COMPANY_PENDING: 'SUM_BY_TRANSPORT_COMPANY_PENDING',
  SUM_BY_TRANSPORT_COMPANY_FULFILLED: 'SUM_BY_TRANSPORT_COMPANY_FULFILLED',
  SUM_BY_TRANSPORT_COMPANY_REJECTED: 'SUM_BY_TRANSPORT_COMPANY_REJECTED',

  SUM_BY_CUSTOMERS: 'SUM_BY_CUSTOMERS',
  SUM_BY_CUSTOMERS_PENDING: 'SUM_BY_CUSTOMERS_PENDING',
  SUM_BY_CUSTOMERS_FULFILLED: 'SUM_BY_CUSTOMERS_FULFILLED',
  SUM_BY_CUSTOMERS_REJECTED: 'SUM_BY_CUSTOMERS_REJECTED'
};

export const getSumByTransportCompany = (pageNo, pageSize, searchParams) => (dispatch) => {
  return dispatch({
    type: SUMMARY_ACTIONS.SUM_BY_TRANSPORT_COMPANY,
    payload: httpClient.get(
      `/TransportCompanyTruckingFeeReport/total?page=${pageNo}&pageSize=${pageSize}&transportCompanyId=${
        searchParams.transportCompanyId ? searchParams.transportCompanyId : ''
      }&fromDate=${searchParams.fromDate}&toDate=${searchParams.toDate}`
    )
  });
};

export const getSumByCustomers = (pageNo, pageSize, searchParams) => (dispatch) => {
  return dispatch({
    type: SUMMARY_ACTIONS.SUM_BY_CUSTOMERS,
    payload: httpClient.get(
      `/customerTruckingFeereport/total?page=${pageNo}&pageSize=${pageSize}&customerId=${
        searchParams.customerId ? searchParams.customerId : ''
      }&fromDate=${searchParams.fromDate}&toDate=${searchParams.toDate}`
    )
  });
};
