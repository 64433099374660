import httpClient from 'HttpClient';

export const REPAIR_MOBILE_TYPE = {
  GET_WORKING_INFO_MOBILE: 'GET_WORKING_INFO_MOBILE',
  GET_WORKING_INFO_MOBILE_PENDING: 'GET_WORKING_INFO_MOBILE_PENDING',
  GET_WORKING_INFO_MOBILE_FULFILLED: 'GET_WORKING_INFO_MOBILE_FULFILLED',
  GET_WORKING_INFO_MOBILE_REJECTED: 'GET_WORKING_INFO_MOBILE_REJECTED',

  GET_USER_POSITiON_MOBILE: 'GET_USER_POSITiON_MOBILE',
  GET_USER_POSITiON_MOBILE_PENDING: 'GET_USER_POSITiON_MOBILE_PENDING',
  GET_USER_POSITiON_MOBILE_FULFILLED: 'GET_USER_POSITiON_MOBILE_FULFILLED',
  GET_USER_POSITiON_MOBILE_REJECTED: 'GET_USER_POSITiON_MOBILE_REJECTED',

  GET_BY_FEE_CATEGORY_ID: 'GET_BY_FEE_CATEGORY_ID',
  GET_BY_FEE_CATEGORY_ID_PENDING: 'GET_BY_FEE_CATEGORY_ID_PENDING',
  GET_BY_FEE_CATEGORY_ID_FULFILLED: 'GET_BY_FEE_CATEGORY_ID_FULFILLED',
  GET_BY_FEE_CATEGORY_ID_REJECTED: 'GET_BY_FEE_CATEGORY_ID_REJECTED'
};

export const getWorkingInfoMobile = (params) => (dispatch) => {
  return dispatch({
    type: REPAIR_MOBILE_TYPE.GET_WORKING_INFO_MOBILE,
    payload: httpClient.get(`/WorkingInfo/Work`, { params })
  });
};

export const createWorkingInfoMobile = (payload) => (dispatch) => {
  return dispatch({
    // type: REPAIR_MOBILE_TYPE.GET_WORKING_INFO_MOBILE,
    payload: httpClient.post(`WorkingInfo/create`, payload)
  });
};
export const updateWorkingInfoMobile = (payload) => (dispatch) => {
  return dispatch({
    // type: REPAIR_MOBILE_TYPE.GET_WORKING_INFO_MOBILE,
    payload: httpClient.put(`WorkingInfo/update`, payload)
  });
};

export const deleteWorkingInfoMobile = (Id) => (dispatch) => {
  return dispatch({
    // type: REPAIR_MOBILE_TYPE.GET_WORKING_INFO_MOBILE,
    payload: httpClient.delete(`WorkingInfo/delete/${Id}`)
  });
};

export const getUserPosition = (Id) => (dispatch) => {
  return dispatch({
    type: REPAIR_MOBILE_TYPE.GET_USER_POSITiON_MOBILE,
    payload: httpClient.get(`user/getUserByGroupId/?groupIds=${Id}`)
  });
};

export const getByFeeCategoryId = (params) => (dispatch) => {
  return dispatch({
    type: REPAIR_MOBILE_TYPE.GET_BY_FEE_CATEGORY_ID,
    payload: httpClient.get(`SysStandardFee/getByFeeCategoryId`, { params })
  });
};

export const createDayRepairing = (tractor, bill, data) => (dispatch) => {
  return dispatch({
    payload: httpClient.post(`/repairing/day?TractorTrailerId=${tractor}&bill_code=${bill}&isOutSource=false`, data)
  });
};
