import api from 'HttpClient';

export const getAllRoadFee = () => {
  return api.get(`/travelfeeinfo/getAll`);
};

export const getRoadFeePerPage = (pageNo, pageSize) => {
  return api.get(`/travelfeeinfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`);
};
export const getRoadFeePerPageAll = (params) => {
  return api.get(`/travelfeeinfo/paging/page`, { params });
};

export const getRoadFeeById = (id) => {
  return api.get(`/travelfeeinfo/getByID/${id}`);
};

export const createRoadFee = (payload) => {
  return api.post(`/travelfeeinfo/create`, payload);
};

export const updateRoadFee = (payload) => {
  return api.put(`/travelfeeinfo/update`, payload);
};

export const deleteRoadFee = (id) => {
  return api.delete(`/travelfeeinfo/deleteByID/${id}`);
};

export const searchData = (value, pageNo, pageSize) => {
  return api.get(`/travelfeeinfo/search?value=${value}&Page=${pageNo}&PageSize=${pageSize}`);
};
export const searchDataAll = (params) => {
  return api.get(`/travelfeeinfo/search`, { params });
};
