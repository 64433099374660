import {
  EXCEL_CUSTOMER_STYLE,
  EXCEL_DEFAULT_DATA,
  EXCEL_EXPORT_DATA,
  EXCEL_HEADER_FOOTER_FULFILLED,
  EXCEL_START,
  RESET_DATA
} from 'actions/excelActive';
import { setLocalStorageItem } from 'commons/localStorageUtils';

const INIT_STATE = {
  excelLoading: false,
  headerFooter: [],
  loading: 0,
  defaultData: [],
  paperSize: 9,
  orientation: 'portrait',
  exportData: {}
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EXCEL_HEADER_FOOTER_FULFILLED: {
      const data = action.payload.data.data;
      setLocalStorageItem('headerFooterStore', JSON.stringify(data));

      return { ...state, headerFooter: action.payload.data.data };
    }
    case EXCEL_START: {
      return {
        ...state,
        excelLoading: action.payload.excelLoading,
        loading: action.payload.loading
      };
    }
    case EXCEL_DEFAULT_DATA: {
      return {
        ...state,
        defaultData: action.payload
      };
    }
    case EXCEL_EXPORT_DATA: {
      return {
        ...state,
        exportData: action.payload
      };
    }
    case EXCEL_CUSTOMER_STYLE: {
      return {
        ...state,
        ...action.payload
        // paperSize: action.payload.paperSize,
        // orientation: action.payload.orientation
      };
    }
    case RESET_DATA: {
      return INIT_STATE;
    }
    default:
      return state;
  }
};
