import api from 'HttpClient';

export const SET_OPEN_WORKING_MODAL = 'SET_OPEN_WORKING_MODAL';
export const setOpenWorkingModal = (data) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_WORKING_MODAL,
    payload: data
  });
};

export const SET_CLOSE_WORKING_MODAL = 'SET_CLOSE_WORKING_MODAL';
export const setCloseWorkingModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_WORKING_MODAL
  });
};

export const SET_LIST_ACTIVE_TAB = 'SET_LIST_ACTIVE_TAB';
export const setListActiveTab = (activeTab) => (dispatch) => {
  return dispatch({
    type: SET_LIST_ACTIVE_TAB,
    payload: activeTab
  });
};

export const FETCH_ALL_WORKINGS = 'FETCH_ALL_WORKINGS';
export const FETCH_ALL_WORKINGS_PENDING = 'FETCH_ALL_WORKINGS_PENDING';
export const FETCH_ALL_WORKINGS_FULFILLED = 'FETCH_ALL_WORKINGS_FULFILLED';
export const FETCH_ALL_WORKINGS_REJECTED = 'FETCH_ALL_WORKINGS_REJECTED';
export const fetchAllWorkings = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_WORKINGS,
    payload: api.get(`/WorkingInfo/getAll`)
  });
};

// const type of function get data per page
export const FETCH_WORKINGS = 'FETCH_WORKINGS';
export const FETCH_WORKINGS_PENDING = 'FETCH_WORKINGS_PENDING';
export const FETCH_WORKINGS_FULFILLED = 'FETCH_WORKINGS_FULFILLED';
export const FETCH_WORKINGS_REJECTED = 'FETCH_WORKINGS_REJECTED';
export const fetchWorkings = (searchPayload) => (dispatch) => {
  return dispatch({
    type: FETCH_WORKINGS,
    payload: api.get(`/WorkingInfo/Work`, { params: searchPayload })
  });
};
export const fetchWorkingsAll = (params) => (dispatch) => {
  return dispatch({
    // type: FETCH_WORKINGS,
    payload: api.get(`/WorkingInfo/Work`, { params })
  });
};
// const type of function get data per page
export const FETCH_WORKINGS_BY_GROUP_ID = 'FETCH_WORKINGS_BY_GROUP_ID';
export const FETCH_WORKINGS_BY_GROUP_ID_PENDING = 'FETCH_WORKINGS_BY_GROUP_ID_PENDING';
export const FETCH_WORKINGS_BY_GROUP_ID_FULFILLED = 'FETCH_WORKINGS_BY_GROUP_ID_FULFILLED';
export const FETCH_WORKINGS_BY_GROUP_ID_REJECTED = 'FETCH_WORKINGS_BY_GROUP_ID_REJECTED';
export const fetchWorkingsByGroupId = (groupId, searchPayload) => (dispatch) => {
  return dispatch({
    type: FETCH_WORKINGS_BY_GROUP_ID,
    payload: api.get(`/WorkGroupInfo/${groupId}/Work`, { params: { ...searchPayload } })
  });
};

// const type of function create data
export const CREATE_WORKING = 'CREATE_WORKING';
export const CREATE_WORKING_PENDING = 'CREATE_WORKING_PENDING';
export const CREATE_WORKING_FULFILLED = 'CREATE_WORKING_FULFILLED';
export const CREATE_WORKING_REJECTED = 'CREATE_WORKING_REJECTED';
export const createWorking = (payload) => (dispatch) => {
  return dispatch({
    type: CREATE_WORKING,
    payload: api.post(`/WorkingInfo/create`, payload)
  });
};

// const type of function update data
export const UPDATE_WORKING = 'UPDATE_WORKING';
export const UPDATE_WORKING_PENDING = 'UPDATE_WORKING_PENDING';
export const UPDATE_WORKING_FULFILLED = 'UPDATE_WORKING_FULFILLED';
export const UPDATE_WORKING_REJECTED = 'UPDATE_WORKING_REJECTED';
export const updateWorking = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_WORKING,
    payload: api.put(`/WorkingInfo/update`, payload),
    meta: payload
  });
};
// const type of get deatail
export const FETCH_WORKING_DETAIL = 'FETCH_WORKING_DETAIL';
export const FETCH_WORKING_DETAIL_PENDING = 'FETCH_WORKING_DETAIL_PENDING';
export const FETCH_WORKING_DETAIL_FULFILLED = 'FETCH_WORKING_DETAIL_FULFILLED';
export const FETCH_WORKING_DETAIL_REJECTED = 'FETCH_WORKING_DETAIL_REJECTED';
export const getWorkingDetail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_WORKING_DETAIL,
    payload: api.get(`/WorkingInfo/getById/${id}`)
  });
};

// const type of delete
export const DELETE_WORKING = 'DELETE_WORKING';
export const DELETE_WORKING_PENDING = 'DELETE_WORKING_PENDING';
export const DELETE_WORKING_FULFILLED = 'DELETE_WORKING_FULFILLED';
export const DELETE_WORKING_REJECTED = 'DELETE_WORKING_REJECTED';
export const deleteWorking = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_WORKING,
    payload: api.delete(`/WorkingInfo/delete/${id}`)
  });
};

// const type of function create data
export const CREATE_TRUCKING_PLAN_WORKING = 'CREATE_TRUCKING_PLAN_WORKING';
export const CREATE_TRUCKING_PLAN_WORKING_PENDING = 'CREATE_TRUCKING_PLAN_WORKING_PENDING';
export const CREATE_TRUCKING_PLAN_WORKING_FULFILLED = 'CREATE_TRUCKING_PLAN_WORKING_FULFILLED';
export const CREATE_TRUCKING_PLAN_WORKING_REJECTED = 'CREATE_TRUCKING_PLAN_WORKING_REJECTED';
export const createTruckingPlanWorking = (payload, truckingId) => (dispatch) => {
  return dispatch({
    type: CREATE_TRUCKING_PLAN_WORKING,
    payload: api.post(`/WorkingInfo/create/${truckingId}`, payload)
  });
};

export const GET_REPAIR_STATATUS_INFO = 'GET_REPAIR_STATATUS_INFO';
export const GET_REPAIR_STATATUS_INFO_PENDING = 'GET_REPAIR_STATATUS_INFO_PENDING';
export const GET_REPAIR_STATATUS_INFO_FULFILLED = 'GET_REPAIR_STATATUS_INFO_FULFILLED';
export const GET_REPAIR_STATATUS_INFO_REJECTED = 'GET_REPAIR_STATATUS_INFO_REJECTED';
export const getRepairStatusInfoById = (Id) => (dispatch) => {
  return dispatch({
    type: GET_REPAIR_STATATUS_INFO,
    payload: api.get(`/StatusInfo/GetRepair?TypeId=${Id}`),
    meta: Id
  });
};
