import httpClient from 'HttpClient';

export const ADVANCE_MANAGEMENT = {
  CREATE_ADVANCE: 'CREATE_ADVANCE',
  CREATE_ADVANCE_PENDING: 'CREATE_ADVANCE_PENDING',
  CREATE_ADVANCE_FULFILLED: 'CREATE_ADVANCE_FULFILLED',
  CREATE_ADVANCE_REJECTED: 'CREATE_ADVANCE_REJECTED',

  UPDATE_ADVANCE: 'UPDATE_ADVANCE',
  UPDATE_ADVANCE_PENDING: 'UPDATE_ADVANCE_PENDING',
  UPDATE_ADVANCE_FULFILLED: 'UPDATE_ADVANCE_FULFILLED',
  UPDATE_ADVANCE_REJECTED: 'UPDATE_ADVANCE_REJECTED',

  DELETE_REPORT: 'DELETE_REPORT',
  DELETE_REPORT_PENDING: 'DELETE_REPORT_PENDING',
  DELETE_REPORT_FULFILLED: 'DELETE_REPORT_FULFILLED',
  DELETE_REPORT_REJECTED: 'DELETE_REPORT_REJECTED',

  DELETE_PAYMENT: 'DELETE_PAYMENT',
  DELETE_PAYMENT_PENDING: 'DELETE_PAYMENT_PENDING',
  DELETE_PAYMENT_FULFILLED: 'DELETE_PAYMENT_FULFILLED',
  DELETE_PAYMENT_REJECTED: 'DELETE_PAYMENT_REJECTED',

  CONFIRM_PAYMENT: 'CONFIRM_PAYMENT',
  CONFIRM_PAYMENT_PENDING: 'CONFIRM_PAYMENT_PENDING',
  CONFIRM_PAYMENT_FULFILLED: 'CONFIRM_PAYMENT_FULFILLED',
  CONFIRM_PAYMENT_REJECTED: 'CONFIRM_PAYMENT_REJECTED',

  CREATE_PAYMENT: 'CREATE_PAYMENT',
  CREATE_PAYMENT_PENDING: 'CREATE_PAYMENT_PENDING',
  CREATE_PAYMENT_FULFILLED: 'CREATE_PAYMENT_FULFILLED',
  CREATE_PAYMENT_REJECTED: 'CREATE_PAYMENT_REJECTED',

  UPDATE_PAYMENT: 'UPDATE_PAYMENT',
  UPDATE_PAYMENT_PENDING: 'UPDATE_PAYMENT_PENDING',
  UPDATE_PAYMENT_FULFILLED: 'UPDATE_PAYMENT_FULFILLED',
  UPDATE_PAYMENT_REJECTED: 'UPDATE_PAYMENT_REJECTED',

  //accounting
  CREATE_ACCOUNTING: 'CREATE_ACCOUNTING',
  CREATE_ACCOUNTING_PENDING: 'CREATE_ACCOUNTING_PENDING',
  CREATE_ACCOUNTING_FULFILLED: 'CREATE_ACCOUNTING_FULFILLED',
  CREATE_ACCOUNTING_REJECTED: 'CREATE_ACCOUNTING_REJECTED',

  LOCK_FUNDS_ACCOUNTING: 'LOCK_FUNDS_ACCOUNTING',
  LOCK_FUNDS_ACCOUNTING_PENDING: 'LOCK_FUNDS_ACCOUNTING_PENDING',
  LOCK_FUNDS_ACCOUNTING_FULFILLED: 'LOCK_FUNDS_ACCOUNTING_FULFILLED',
  LOCK_FUNDS_ACCOUNTING_REJECTED: 'LOCK_FUNDS_ACCOUNTING_REJECTED',

  UPDATE_ACCOUNTING: 'UPDATE_ACCOUNTING',
  UPDATE_ACCOUNTING_PENDING: 'UPDATE_ACCOUNTING_PENDING',
  UPDATE_ACCOUNTING_FULFILLED: 'UPDATE_ACCOUNTING_FULFILLED',
  UPDATE_ACCOUNTING_REJECTED: 'UPDATE_ACCOUNTING_REJECTED',

  DELETE_ACCOUNTING: 'DELETE_ACCOUNTING',
  DELETE_ACCOUNTING_PENDING: 'DELETE_ACCOUNTING_PENDING',
  DELETE_ACCOUNTING_FULFILLED: 'DELETE_ACCOUNTING_FULFILLED',
  DELETE_ACCOUNTING_REJECTED: 'DELETE_ACCOUNTING_REJECTED',

  GET_ADVANCE_REPORT_PERMISSION: 'GET_ADVANCE_REPORT_PERMISSION',
  GET_ADVANCE_REPORT_PERMISSION_PENDING: 'GET_ADVANCE_REPORT_PERMISSION_PENDING',
  GET_ADVANCE_REPORT_PERMISSION_FULFILLED: 'GET_ADVANCE_REPORT_PERMISSION_FULFILLED',
  GET_ADVANCE_REPORT_PERMISSION_REJECTED: 'GET_ADVANCE_REPORT_PERMISSION_REJECTED',

  UPDATE_AVANCE_CONTSERIAL_NO: 'UPDATE_AVANCE_CONTSERIAL_NO',
  UPDATE_AVANCE_CONTSERIAL_NO_PENDING: 'UPDATE_AVANCE_CONTSERIAL_NO_PENDING',
  UPDATE_AVANCE_CONTSERIAL_NO_FULFILLED: 'UPDATE_AVANCE_CONTSERIAL_NO_FULFILLED',
  UPDATE_AVANCE_CONTSERIAL_NO_REJECTED: 'UPDATE_AVANCE_CONTSERIAL_NO_REJECTED',

  UPDATE_TRANSPORT_FEE_BY_OPS: 'UPDATE_TRANSPORT_FEE_BY_OPS',
  UPDATE_TRANSPORT_FEE_BY_OPS_PENDING: 'UPDATE_TRANSPORT_FEE_BY_OPS_PENDING',
  UPDATE_TRANSPORT_FEE_BY_OPS_FULFILLED: 'UPDATE_TRANSPORT_FEE_BY_OPS_FULFILLED',
  UPDATE_TRANSPORT_FEE_BY_OPS_REJECTED: 'UPDATE_TRANSPORT_FEE_BY_OPS_REJECTED'
};

export const updateTransportFeePaidByOPS = (payload) => (dispatch) => {
  return dispatch({
    type: ADVANCE_MANAGEMENT.UPDATE_TRANSPORT_FEE_BY_OPS,
    payload: httpClient.put(`/TransportFee/updateTransportFeePaidByOPS`, payload)
  });
};

export const createAdvance = (payload) => (dispatch) => {
  return dispatch({
    type: ADVANCE_MANAGEMENT.CREATE_ADVANCE,
    payload: httpClient.post(`/AdvanceMoney/createReport`, payload)
  });
};

export const updateAdvance = (payload) => (dispatch) => {
  return dispatch({
    type: ADVANCE_MANAGEMENT.UPDATE_ADVANCE,
    payload: httpClient.put(`/AdvanceMoney/updateReport`, payload)
  });
};

export const actionDeleteReport = (id) => (dispatch) => {
  return dispatch({
    type: ADVANCE_MANAGEMENT.DELETE_REPORT,
    payload: httpClient.delete(`/AdvanceMoney/reportDeleteMany?ids=${id}`)
  });
};

export const actionDeletePayment = (id) => (dispatch) => {
  return dispatch({
    type: ADVANCE_MANAGEMENT.DELETE_PAYMENT,
    payload: httpClient.delete(`/AdvanceMoney/paymentDelete/${id}`)
  });
};

export const actionConfirmPayment = (listPayment) => (dispatch) => {
  return dispatch({
    type: ADVANCE_MANAGEMENT.CONFIRM_PAYMENT,
    payload: httpClient.put(`/AdvanceMoney/confirmPayment`, listPayment)
  });
};

export const actionCreatePayment = (payload) => (dispatch) => {
  return dispatch({
    type: ADVANCE_MANAGEMENT.CREATE_PAYMENT,
    payload: httpClient.post(`/AdvanceMoney/createPayment`, payload)
  });
};

export const actionUpdatePayment = (payload) => (dispatch) => {
  return dispatch({
    type: ADVANCE_MANAGEMENT.UPDATE_PAYMENT,
    payload: httpClient.put(`/AdvanceMoney/updatePayment`, payload)
  });
};

//accounting
export const actionCreateAccounting = (payload) => (dispatch) => {
  return dispatch({
    type: ADVANCE_MANAGEMENT.CREATE_ACCOUNTING,
    payload: httpClient.post(`/AdvanceMoney/createAccounting`, payload)
  });
};

export const actionUpdateLockFundsAccounting = (payload, query) => (dispatch) => {
  return dispatch({
    type: ADVANCE_MANAGEMENT.LOCK_FUNDS_ACCOUNTING,
    payload: httpClient.put(`/AdvanceMoney/lockFunds`, payload, { params: query })
  });
};

export const actionUpdateAccounting = (payload) => (dispatch) => {
  return dispatch({
    type: ADVANCE_MANAGEMENT.UPDATE_ACCOUNTING,
    payload: httpClient.put(`/AdvanceMoney/updateAccounting`, payload)
  });
};

export const actionDeleteAccounting = (id) => (dispatch) => {
  return dispatch({
    type: ADVANCE_MANAGEMENT.DELETE_ACCOUNTING,
    payload: httpClient.delete(`/AdvanceMoney/deleteAccounting/${id}`)
  });
};

export const actionGetAdvanceReportPermission = (payload) => (dispatch) => {
  return dispatch({
    type: ADVANCE_MANAGEMENT.GET_ADVANCE_REPORT_PERMISSION,
    payload: httpClient.get(`/WorkGroupInfo/advanceReportPermission`)
  });
};

export const actionuUpdateContSerialNo = (payload) => (dispatch) => {
  return dispatch({
    type: ADVANCE_MANAGEMENT.UPDATE_AVANCE_CONTSERIAL_NO,
    payload: httpClient.put(`AdvanceMoney/updateContSerialNo`, payload)
  });
};
