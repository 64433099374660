import React, { Component } from 'react';
import Page500 from 'views/Pages/Page500/Page500';

export default class ErrorBoundary extends Component {
    state = { hasError: false }
    
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }


    render() {
        if (this.state.hasError) {
            return <Page500 />
        }
        return this.props.children;
    }
}