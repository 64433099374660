import React from 'react';
import { CustomInput } from 'reactstrap';
import { CSwitch } from '@coreui/react';
import classnames from 'classnames';

class renderCheckboxField extends React.PureComponent {
  render() {
    const { input, label, isDisable, checked, idName, defaultChecked, type, color, divClassName, ...rest } = this.props;
    // console.log('🚀 ~ file: renderCheckboxField.js ~ line 9 ~ renderCheckboxField ~ render ~ isDisable', isDisable);
    return (
      <div className={classnames(divClassName)}>
        {type === 'switch' ? (
          <CSwitch
            {...input}
            {...rest}
            className={'mx-1'}
            // variant={'3d'}
            shape={'pill'}
            color={color ? color : 'primary'}
            checked={!!input.value}
            disabled={isDisable}
          />
        ) : (
          <CustomInput
            {...input}
            {...rest}
            type="checkbox"
            id={idName}
            label={label}
            checked={input.value}
            disabled={isDisable}
          />
        )}
      </div>
    );
  }
}
export default renderCheckboxField;
