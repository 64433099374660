import { ADD_DRIVER_PLAN_ACTIONS } from "actions/adddriverplan";
import { DRIVER_PLAN_ACTIONS } from "actions/driverplan";
import { put, takeEvery } from "redux-saga/effects";
import { getDriverPlanByID } from "services/driverplan";

function* putActionUpdateData(actions) {
    yield put({
        type: DRIVER_PLAN_ACTIONS.GET_DRIVER_PLAN_BY_ID,
        payload: getDriverPlanByID(actions.meta.truckingPlanID)
    });
}

export function* actionUpdateAddDriverPlanData() {
    yield takeEvery(
        [
            ADD_DRIVER_PLAN_ACTIONS.UPDATE_ADD_DRIVER_PLAN_FULFILLED,
            ADD_DRIVER_PLAN_ACTIONS.UPDATE_ADD_DRIVER_PLAN_REJECTED,

            ADD_DRIVER_PLAN_ACTIONS.CREATE_ADD_DRIVER_PLAN_FULFILLED,
            ADD_DRIVER_PLAN_ACTIONS.CREATE_ADD_DRIVER_PLAN_REJECTED,

            ADD_DRIVER_PLAN_ACTIONS.DELETE_ADD_DRIVER_PLAN_FULFILLED,
            ADD_DRIVER_PLAN_ACTIONS.DELETE_ADD_DRIVER_PLAN_REJECTED
        ],
        putActionUpdateData
    );
}