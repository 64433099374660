import { getAllContainers } from "services/container";

// define const actions
export const CONTAINERS_ACTIONS = {
    GET_ALL_CONTAINERS: "GET_ALL_CONTAINERS",
    GET_ALL_CONTAINERS_PENDING: "GET_ALL_CONTAINERS_PENDING",
    GET_ALL_CONTAINERS_FULFILLED: "GET_ALL_CONTAINERS_FULFILLED",
    GET_ALL_CONTAINERS_REJECTED: "GET_ALL_CONTAINERS_REJECTED"
};

// define actions
export const actionGetAllContainers = () => dispatch => {
    return dispatch({
        type: CONTAINERS_ACTIONS.GET_ALL_CONTAINERS,
        payload: getAllContainers()
    });
};