import api from 'HttpClient/index';

export const getCustomFeePerPage = (payload) => {
  return api.get(`/CustomFeeInfo/search`, { params: payload });
};

export const getCustomFeeByID = (id) => {
  return api.get(`/CustomFeeInfo/getById/${id}`);
};

export const createCustomFee = (payload) => {
  return api.post(`/CustomFeeInfo/create`, payload);
};

export const updateCustomFee = (payload) => {
  return api.put(`/CustomFeeInfo/update`, payload);
};

export const deleteCustomFee = (id) => {
  return api.delete(`/CustomFeeInfo/delete/${id}`);
};
export const searchData = (value, pageNo, pageSize) => {
  return api.get(`/CustomFeeInfo/search?value=${value}&Page=${pageNo}&PageSize=${pageSize}`);
};
export const searchDataAll = (params) => {
  return api.get(`/CustomFeeInfo/search?`, { params });
};
