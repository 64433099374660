import { SPARE_PARTS_ACTIONS } from 'actions/repair/sparePartsManagement';

const INIT_STATE = {
  allData: { data: { data: [] } },
  serialValueSelected: '',
  sparePartGroup: []
};
const SparePartManagementReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    //get all
    case SPARE_PARTS_ACTIONS.GET_ALL_SPARE_PARTS_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case SPARE_PARTS_ACTIONS.GET_ALL_SPARE_PARTS_FULFILLED:
      let allData = {
        data: action.payload.data
      };
      return {
        ...state,
        allData: allData,
        isLoading: false
      };

    case SPARE_PARTS_ACTIONS.GET_ALL_SPARE_PARTS_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // get DATA per page
    case SPARE_PARTS_ACTIONS.GET_SPARE_PARTS_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case SPARE_PARTS_ACTIONS.GET_SPARE_PARTS_PER_PAGE_FULFILLED:
      let dataPerPage = {
        pagination: action.payload.data.pagination,
        data: action.payload.data,
        isMsg: false
      };
      return {
        ...state,
        dataPerPage: dataPerPage,
        isLoading: false
      };

    case SPARE_PARTS_ACTIONS.GET_SPARE_PARTS_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };
    //get by id
    case SPARE_PARTS_ACTIONS.GET_SPARE_PARTS_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case SPARE_PARTS_ACTIONS.GET_SPARE_PARTS_BY_ID_FULFILLED:
      return {
        ...state,
        sparePartById: action.payload.data.data,
        isLoading: false
      };

    case SPARE_PARTS_ACTIONS.GET_SPARE_PARTS_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // update selling price
    case SPARE_PARTS_ACTIONS.UPDATE_SELLING_PRICE:
      return {
        ...state,
        SellingPrice: action.payload
      };

    // create
    case SPARE_PARTS_ACTIONS.CREATE_SPARE_PARTS_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case SPARE_PARTS_ACTIONS.CREATE_SPARE_PARTS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Tạo dữ liệu thành công'
      };

    case SPARE_PARTS_ACTIONS.CREATE_SPARE_PARTS_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Tạo dữ liệu thất bại'
      };

    // update routers
    case SPARE_PARTS_ACTIONS.UPDATE_SPARE_PARTS_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case SPARE_PARTS_ACTIONS.UPDATE_SPARE_PARTS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Sửa dữ liệu thành công',
        typeMessage: 'success'
      };

    case SPARE_PARTS_ACTIONS.UPDATE_SPARE_PARTS_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Sửa dữ liệu thất bại'
      };

    // delete routers
    case SPARE_PARTS_ACTIONS.DELETE_SPARE_PARTS_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case SPARE_PARTS_ACTIONS.DELETE_SPARE_PARTS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Xóa dữ liệu thành công'
      };

    case SPARE_PARTS_ACTIONS.DELETE_SPARE_PARTS_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Xóa dữ liệu thất bại'
      };

    //get unit by id
    case SPARE_PARTS_ACTIONS.GET_UNIT_OF_SPARE_PART_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case SPARE_PARTS_ACTIONS.GET_UNIT_OF_SPARE_PART_FULFILLED:
      let dataUnit = {
        data: action.payload.data
      };
      return {
        ...state,
        dataUnit: dataUnit,
        isLoading: false
      };

    case SPARE_PARTS_ACTIONS.GET_UNIT_OF_SPARE_PART_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    case SPARE_PARTS_ACTIONS.SEARCH_SPARE_PARTS_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case SPARE_PARTS_ACTIONS.SEARCH_SPARE_PARTS_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    case SPARE_PARTS_ACTIONS.SEARCH_SPARE_PARTS_FULFILLED:
      let searchResultPerPage = {
        pagination: action.payload.data.pagination,
        data: action.payload.data,
        isMsg: false
      };
      return {
        ...state,
        dataPerPage: searchResultPerPage,
        isLoading: false,
        isSearch: true
      };

    case SPARE_PARTS_ACTIONS.UPDATE_IS_SEARCH:
      return {
        ...state,
        isSearch: action.payload
      };
    case SPARE_PARTS_ACTIONS.UPDATE_SERIAL_VALUE_SELECTED:
      return {
        ...state,
        serialValueSelected: action.payload
      };
    //get all group
    case SPARE_PARTS_ACTIONS.GET_ALL_SPARE_PART_GROUP_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case SPARE_PARTS_ACTIONS.GET_ALL_SPARE_PART_GROUP_FULFILLED:
      const sparePartGroup = action.payload.data.data;
      return {
        ...state,
        sparePartGroup,
        isLoading: false
      };

    case SPARE_PARTS_ACTIONS.GET_ALL_SPARE_PART_GROUP_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    default:
      return state;
  }
};

export default SparePartManagementReducer;
