import httpClient from 'HttpClient/index';
import { getAllGoodsType } from 'services/goodstype';

// define const actions
export const GOODS_TYPE_ACTIONS = {
  GET_ALL_GOODS_TYPE: 'GET_ALL_GOODS_TYPE',
  GET_ALL_GOODS_TYPE_PENDING: 'GET_ALL_GOODS_TYPE_PENDING',
  GET_ALL_GOODS_TYPE_FULFILLED: 'GET_ALL_GOODS_TYPE_FULFILLED',
  GET_ALL_GOODS_TYPE_REJECTED: 'GET_ALL_GOODS_TYPE_REJECTED',

  CREATE_GOODS_TYPE: 'CREATE_GOODS_TYPE',
  CREATE_GOODS_TYPE_PENDING: 'CREATE_GOODS_TYPE_PENDING',
  CREATE_GOODS_TYPE_FULFILLED: 'CREATE_GOODS_TYPE_FULFILLED',
  CREATE_GOODS_TYPE_REJECTED: 'CREATE_GOODS_TYPE_REJECTED',

  GET_CONSIGNMENT_GOODS_TYPE_BY_ID: 'GET_CONSIGNMENT_GOODS_TYPE_BY_ID',
  GET_CONSIGNMENT_GOODS_TYPE_BY_ID_PENDING: 'GET_CONSIGNMENT_GOODS_TYPE_BY_ID_PENDING',
  GET_CONSIGNMENT_GOODS_TYPE_BY_ID_FULFILLED: 'GET_CONSIGNMENT_GOODS_TYPE_BY_ID_FULFILLED',
  GET_CONSIGNMENT_GOODS_TYPE_BY_ID_REJECTED: 'GET_CONSIGNMENT_GOODS_TYPE_BY_ID_REJECTED',

  GET_ALL_CONSIGNMENT_GOODS_TYPE: 'GET_ALL_CONSIGNMENT_GOODS_TYPE',
  GET_ALL_CONSIGNMENT_GOODS_TYPE_PENDING: 'GET_ALL_CONSIGNMENT_GOODS_TYPE_PENDING',
  GET_ALL_CONSIGNMENT_GOODS_TYPE_FULFILLED: 'GET_ALL_CONSIGNMENT_GOODS_TYPE_FULFILLED',
  GET_ALL_CONSIGNMENT_GOODS_TYPE_REJECTED: 'GET_ALL_CONSIGNMENT_GOODS_TYPE_REJECTED'
};

// define actions
export const actionGetAllGoodsType = () => (dispatch) => {
  return dispatch({
    type: GOODS_TYPE_ACTIONS.GET_ALL_GOODS_TYPE,
    payload: getAllGoodsType()
  });
};

export const actionGetConsignmentGoodsTypeById = (goodsTypeId) => (dispatch) => {
  return dispatch({
    type: GOODS_TYPE_ACTIONS.GET_CONSIGNMENT_GOODS_TYPE_BY_ID,
    payload: httpClient.get(`/SysConsignmentGoodsType/getByGoodsTypeId/${goodsTypeId}`)
  });
};

export const actionCreateGoodsType = (params) => (dispatch) => {
  return dispatch({
    type: GOODS_TYPE_ACTIONS.CREATE_GOODS_TYPE,
    payload: httpClient.post(`/GoodsCategoryInfo/create`, params)
  });
};

export const actionGetAllConsignmentGoodsType = () => (dispatch) => {
  return dispatch({
    type: GOODS_TYPE_ACTIONS.GET_ALL_CONSIGNMENT_GOODS_TYPE,
    payload: httpClient.get(`/SysConsignmentGoodsType/getAll`)
  });
};
