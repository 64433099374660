import httpClient from 'HttpClient';
// define const actions
export const SETTLEMENT_OF_ORDER_ACTIONS = {
  GET_LIST_SETTLEMENT_OF_ORDER: 'GET_LIST_SETTLEMENT_OF_ORDER',
  GET_LIST_SETTLEMENT_OF_ORDER_PENDING: 'GET_LIST_SETTLEMENT_OF_ORDER_PENDING',
  GET_LIST_SETTLEMENT_OF_ORDER_FULFILLED: 'GET_LIST_SETTLEMENT_OF_ORDER_FULFILLED',
  GET_LIST_SETTLEMENT_OF_ORDER_REJECTED: 'GET_LIST_SETTLEMENT_OF_ORDER_REJECTED',

  CREATE_SETTLEMENT_ORDER: 'CREATE_SETTLEMENT_ORDER',
  CREATE_SETTLEMENT_ORDER_PENDING: 'CREATE_SETTLEMENT_ORDER_PENDING',
  CREATE_SETTLEMENT_ORDER_FULFILLED: 'CREATE_SETTLEMENT_ORDER_FULFILLED',
  CREATE_SETTLEMENT_ORDER_REJECTED: 'CREATE_SETTLEMENT_ORDER_REJECTED',

  UPDATE_SETTLEMENT_OF_ORDER_MANAGEMENT: 'UPDATE_SETTLEMENT_OF_ORDER_MANAGEMENT',
  UPDATE_SETTLEMENT_OF_ORDER_MANAGEMENT_PENDING: 'UPDATE_SETTLEMENT_OF_ORDER_MANAGEMENT_PENDING',
  UPDATE_SETTLEMENT_OF_ORDER_MANAGEMENT_FULFILLED: 'UPDATE_SETTLEMENT_OF_ORDER_MANAGEMENT_FULFILLED',
  UPDATE_SETTLEMENT_OF_ORDER_MANAGEMENT_REJECTED: 'UPDATE_SETTLEMENT_OF_ORDER_MANAGEMENT_REJECTED',

  DELETE_DATA: 'DELETE_DATA',
  DELETE_DATA_PENDING: 'DELETE_DATA_PENDING',
  DELETE_DATA_FULFILLED: 'DELETE_DATA_FULFILLED',
  DELETE_DATA_REJECTED: 'DELETE_DATA_REJECTED',

  CREATE_TEMPLATE: 'CREATE_TEMPLATE',
  CREATE_TEMPLATE_PENDING: 'CREATE_TEMPLATE_PENDING',
  CREATE_TEMPLATE_FULFILLED: 'CREATE_TEMPLATE_FULFILLED',
  CREATE_TEMPLATE_REJECTED: 'CREATE_TEMPLATE_REJECTED',

  CHANGE_DATA_EDIT_TABEL_FIELD: 'CHANGE_DATA_EDIT_TABEL_FIELD',
  CHANGE_DATA_EDIT_UNIT_PRICE_CONTRACT: 'CHANGE_DATA_EDIT_UNIT_PRICE_CONTRACT',

  ON_KEY_UP: 'ON_KEY_UP',

  REFRESH_DATA: 'REFRESH_DATA',
  REFRESH_DATA_PENDING: 'REFRESH_DATA_PENDING',
  REFRESH_DATA_FULFILLED: 'REFRESH_DATA_FULFILLED',
  REFRESH_DATA_REJECTED: 'REFRESH_DATA_REJECTED',

  CHANGE_FEE_CATEGORY: 'CHANGE_FEE_CATEGORY',
  CHANGE_FEE_CATEGORY_PENDING: 'CHANGE_FEE_CATEGORY_PENDING',
  CHANGE_FEE_CATEGORY_FULFILLED: 'CHANGE_FEE_CATEGORY_FULFILLED',
  CHANGE_FEE_CATEGORY_REJECTED: 'CHANGE_FEE_CATEGORY_REJECTED',

  UPDATE_SPLIT_FEE_BY_CONTAINER: 'UPDATE_SPLIT_FEE_BY_CONTAINER',
  UPDATE_SPLIT_FEE_BY_CONTAINER_PENDING: 'UPDATE_SPLIT_FEE_BY_CONTAINER_PENDING',
  UPDATE_SPLIT_FEE_BY_CONTAINER_FULFILLED: 'UPDATE_SPLIT_FEE_BY_CONTAINER_FULFILLED',
  UPDATE_SPLIT_FEE_BY_CONTAINER_REJECTED: 'UPDATE_SPLIT_FEE_BY_CONTAINER_REJECTED'
};

// define actions
export const getList = (consigmentId) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_OF_ORDER_ACTIONS.GET_LIST_SETTLEMENT_OF_ORDER,
    payload: httpClient.get(`/internalsettlement/getList/${consigmentId}`)
  });
};

export const actionCreateSettlementOrder = (consigmentId, payload) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_OF_ORDER_ACTIONS.CREATE_SETTLEMENT_ORDER,
    payload: httpClient.post(`/internalsettlement/${consigmentId}/create`, payload)
  });
};

export const createTamplate = (consigmentid) => (dispatch) => {
  const payload = {};
  return dispatch({
    type: SETTLEMENT_OF_ORDER_ACTIONS.CREATE_TEMPLATE,
    payload: httpClient.post(`/internalsettlement/create/${consigmentid}`, payload)
  });
};

export const actionUpdateSettlementOfOrder = (id, payload) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_OF_ORDER_ACTIONS.UPDATE_SETTLEMENT_OF_ORDER_MANAGEMENT,
    payload: httpClient.put(`/internalsettlement/${id}/update`, payload)
  });
};

export const actionsChangeFeeCategory = (id, feeCategoryId) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_OF_ORDER_ACTIONS.CHANGE_FEE_CATEGORY,
    payload: httpClient.put(`/AdvanceOnConsignment/${id}/changeFeeCategoryId/${feeCategoryId}`)
  });
};

export const actionDeleteSettlementOrder = (id) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_OF_ORDER_ACTIONS.DELETE_DATA,
    payload: httpClient.delete(`/internalsettlement/deleteById/${id}`)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const changeDataEditTableField = (id, fieldName, value, message, isConditionQuantityWeight, acceptDecimal) => {
  let checkValueIsBoolean = 'boolean';
  let check = (value + '').includes(',');
  const regexDots = /\./g;
  const regexComma = /\,/g;
  const valueIsTypeBoolean = typeof value === checkValueIsBoolean;

  let dataValue =
    typeof value === checkValueIsBoolean
      ? value
      : !check && !acceptDecimal
      ? value.replace(regexDots, '')
      : value.replace(regexComma, '');

  let error = {
    [fieldName]: message
  };

  let data = {
    Id: id,
    [fieldName]:
      fieldName === 'BillNo'
        ? dataValue + ''
        : acceptDecimal
        ? dataValue
        : !isNaN(dataValue) && dataValue !== ''
        ? parseInt(dataValue)
        : fieldName === 'ActualUnitPrice' && dataValue === ''
        ? 0
        : dataValue + '',
    isChange: true
  };
  return {
    type: SETTLEMENT_OF_ORDER_ACTIONS.CHANGE_DATA_EDIT_TABEL_FIELD,
    meta: {
      error,
      message,
      checkValueIsBoolean,
      value,
      isConditionQuantityWeight,
      fieldName,
      valueIsTypeBoolean
    },
    payload: data
  };
};

export const changeEditUnitPriceContractSettlement = (
  id,
  fieldName,
  value,
  message,
  isConditionQuantityWeight,
  acceptDecimal
) => {
  let checkValueIsBoolean = 'boolean';
  let check = (value + '').includes(',');
  const regexDots = /\./g;
  const regexComma = /\,/g;
  const valueIsTypeBoolean = typeof value === checkValueIsBoolean;
  let dataValue =
    typeof value === checkValueIsBoolean
      ? value
      : !check && !acceptDecimal
      ? value.replace(regexDots, '')
      : value.replace(regexComma, '');

  let error = {
    [fieldName]: message
  };

  let data = {
    Id: id,
    [fieldName]:
      fieldName === 'BillNo'
        ? dataValue + ''
        : acceptDecimal
        ? +dataValue
        : !isNaN(dataValue) && dataValue !== ''
        ? parseInt(dataValue)
        : fieldName === 'ContractUnitPrice' && dataValue === ''
        ? 0
        : dataValue + '',
    isChange: true
  };
  return {
    type: SETTLEMENT_OF_ORDER_ACTIONS.CHANGE_DATA_EDIT_UNIT_PRICE_CONTRACT,
    meta: {
      error,
      message,
      checkValueIsBoolean,
      value,
      isConditionQuantityWeight,
      fieldName,
      valueIsTypeBoolean
    },
    payload: data
  };
};

export const onKeyUpActionsField = (id, isFocusTotal) => {
  let data = {
    Id: id,
    isFocus: true,
    isFocusTotal: isFocusTotal
  };
  return {
    type: SETTLEMENT_OF_ORDER_ACTIONS.ON_KEY_UP,
    payload: data
  };
};

export const refreshData = (id) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_OF_ORDER_ACTIONS.REFRESH_DATA,
    payload: httpClient.get(`/InternalSettlement/refresh/${id}`)
  });
};

export const updateSplitFeeByContainer = (payload) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_OF_ORDER_ACTIONS.UPDATE_SPLIT_FEE_BY_CONTAINER,
    payload: httpClient.post(`/AdvanceOnConsignment/splitFeeByContainer`, payload)
  });
};
