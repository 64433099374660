import httpClient from 'HttpClient';
// define const actions
export const REPAIR_REPORT_ACTIONS = {
  GET_REPAIR_REPORT_PER_PAGE: 'GET_REPAIR_REPORT_PER_PAGE',
  GET_REPAIR_REPORT_PER_PAGE_PENDING: 'GET_REPAIR_REPORT_PER_PAGE_PENDING',
  GET_REPAIR_REPORT_PER_PAGE_FULFILLED: 'GET_REPAIR_REPORT_PER_PAGE_FULFILLED',
  GET_REPAIR_REPORT_PER_PAGE_REJECTED: 'GET_REPAIR_REPORT_PER_PAGE_REJECTED',

  GET_ALL_REPAIR_REPORT: 'GET_ALL_REPAIR_REPORT',
  GET_ALL_REPAIR_REPORT_PENDING: 'GET_ALL_REPAIR_REPORT_PENDING',
  GET_ALL_REPAIR_REPORT_FULFILLED: 'GET_ALL_REPAIR_REPORT_FULFILLED',
  GET_ALL_REPAIR_REPORT_REJECTED: 'GET_ALL_REPAIR_REPORT_REJECTED',

  SEARCH_DATA_REPORT_REPAIR: 'SEARCH_DATA_REPORT_REPAIR',
  SEARCH_DATA_REPORT_REPAIR_PENDING: 'SEARCH_DATA_REPORT_REPAIR_PENDING',
  SEARCH_DATA_REPORT_REPAIR_FULFILLED: 'SEARCH_DATA_REPORT_REPAIR_FULFILLED',
  SEARCH_DATA_REPORT_REPAIR_REJECTED: 'SEARCH_DATA_REPORT_REPAIR_REJECTED',

  UPDATE_DATA: 'UPDATE_DATA',
  UPDATE_DATA_PENDING: 'UPDATE_DATA_PENDING',
  UPDATE_DATA_FULFILLED: 'UPDATE_DATA_FULFILLED',
  UPDATE_DATA_REJECTED: 'UPDATE_DATA_REJECTED',

  GET_ALL_REPORT_REPAIR: 'GET_ALL_REPORT_REPAIR',
  GET_ALL_REPORT_REPAIR_PENDING: 'GET_ALL_REPORT_REPAIR_PENDING',
  GET_ALL_REPORT_REPAIR_FULFILLED: 'GET_ALL_REPORT_REPAIR_FULFILLED',
  GET_ALL_REPORT_REPAIR_REJECTED: 'GET_ALL_REPORT_REPAIR_REJECTED',

  GET_DEPARTMENT: 'GET_DEPARTMENT',
  GET_DEPARTMENT_PENDING: 'GET_DEPARTMENT_PENDING',
  GET_DEPARTMENT_FULFILLED: 'GET_DEPARTMENT_FULFILLED',
  GET_DEPARTMENT_REJECTED: 'GET_DEPARTMENT_REJECTED',

  GET_CAR_FILTER: 'GET_CAR_FILTER',
  GET_CAR_FILTER_PENDING: 'GET_CAR_FILTER_PENDING',
  GET_CAR_FILTER_FULFILLED: 'GET_CAR_FILTER_FULFILLED',
  GET_CAR_FILTER_REJECTED: 'GET_CAR_FILTER_REJECTED'
};

// define actions
export const getDataPerPage = (id, time, mode, value, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: REPAIR_REPORT_ACTIONS.GET_REPAIR_REPORT_PER_PAGE,
    payload: httpClient.get(
      `/repairreport?valueId=${id}&monthYear=${time}&Page=${pageNo}&Pagesize=${pageSize}&searchMode=${mode}&value=${value}`
    )
  });
};

export const getAllSparepartInfo = () => (dispatch) => {
  return dispatch({
    type: REPAIR_REPORT_ACTIONS.GET_ALL_REPAIR_REPORT,
    payload: httpClient.get(`/sparepartinfo/getAll`)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const actionSearchData = (params) => (dispatch) => {
  return dispatch({
    type: REPAIR_REPORT_ACTIONS.SEARCH_DATA_REPORT_REPAIR,
    payload: httpClient.get(`/repairreport`, { params: params })
  });
};

export const actionSearchDataAll = (params) => (dispatch) => {
  return dispatch({
    payload: httpClient.get(`/repairreport`, { params: params })
  });
};

export const getAllData = (id, time) => (dispatch) => {
  return dispatch({
    type: REPAIR_REPORT_ACTIONS.GET_ALL_REPORT_REPAIR,
    payload: httpClient.get(`/repairreport?TractorTrailerId=${id}&monthYear=${time}`)
  });
};

export const updateData = (payload) => (dispatch) => {
  return dispatch({
    type: REPAIR_REPORT_ACTIONS.UPDATE_DATA,
    payload: httpClient.put(`/SparePartReport/update`, payload)
  });
};

export const getAllDepartment = () => (dispatch) => {
  return dispatch({
    type: REPAIR_REPORT_ACTIONS.GET_DEPARTMENT,
    payload: httpClient.get(`/DepartmentInfo/getAll`)
  });
};

export const getAllCarFiler = () => (dispatch) => {
  return dispatch({
    type: REPAIR_REPORT_ACTIONS.GET_CAR_FILTER,
    payload: httpClient.get(`/TractorTrailerInfo/getTractorTrailerByPrimaryCompany?isPrimaryCompany=true`)
  });
};
