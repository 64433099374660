import httpClient from 'HttpClient';
import {
  createRouters,
  deleteRouters,
  getAllRouters,
  getOptionRoute,
  getRoutersByID,
  getRoutersPerPage,
  getRoutersPerPageAll,
  searchData,
  searchDataAll,
  updateRouters
} from 'services/router';

// define const actions
export const ROUTER_ACTIONS = {
  GET_ROUTERS_PER_PAGE: 'GET_ROUTERS_PER_PAGE',
  GET_ROUTERS_PER_PAGE_PENDING: 'GET_ROUTERS_PER_PAGE_PENDING',
  GET_ROUTERS_PER_PAGE_FULFILLED: 'GET_ROUTERS_PER_PAGE_FULFILLED',
  GET_ROUTERS_PER_PAGE_REJECTED: 'GET_ROUTERS_PER_PAGE_REJECTED',

  GET_ROUTERS_BY_ID: 'GET_ROUTERS_BY_ID',
  GET_ROUTERS_BY_ID_PENDING: 'GET_ROUTERS_BY_ID_PENDING',
  GET_ROUTERS_BY_ID_FULFILLED: 'GET_ROUTERS_BY_ID_FULFILLED',
  GET_ROUTERS_BY_ID_REJECTED: 'GET_ROUTERS_BY_ID_REJECTED',

  CREATE_ROUTERS: 'CREATE_ROUTERS',
  CREATE_ROUTERS_PENDING: 'CREATE_ROUTERS_PENDING',
  CREATE_ROUTERS_FULFILLED: 'CREATE_ROUTERS_FULFILLED',
  CREATE_ROUTERS_REJECTED: 'CREATE_ROUTERS_REJECTED',

  UPDATE_ROUTERS: 'UPDATE_ROUTERS',
  UPDATE_ROUTERS_PENDING: 'UPDATE_ROUTERS_PENDING',
  UPDATE_ROUTERS_FULFILLED: 'UPDATE_ROUTERS_FULFILLED',
  UPDATE_ROUTERS_REJECTED: 'UPDATE_ROUTERS_REJECTED',

  DELETE_ROUTERS: 'DELETE_ROUTERS',
  DELETE_ROUTERS_PENDING: 'DELETE_ROUTERS_PENDING',
  DELETE_ROUTERS_FULFILLED: 'DELETE_ROUTERS_FULFILLED',
  DELETE_ROUTERS_REJECTED: 'DELETE_ROUTERS_REJECTED',

  GET_ALL_ROUTERS: 'GET_ALL_ROUTERS',
  GET_ALL_ROUTERS_PENDING: 'GET_ALL_ROUTERS_PENDING',
  GET_ALL_ROUTERS_FULFILLED: 'GET_ALL_ROUTERS_FULFILLED',
  GET_ALL_ROUTERS_REJECTED: 'GET_ALL_ROUTERS_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  GET_OPTIONAL_ROUTES: 'GET_OPTIONAL_ROUTES',
  GET_OPTIONAL_ROUTES_PENDING: 'GET_OPTIONAL_ROUTES_PENDING',
  GET_OPTIONAL_ROUTES_FULFILLED: 'GET_OPTIONAL_ROUTES_FULFILLED',
  GET_OPTIONAL_ROUTES_REJECTED: 'GET_OPTIONAL_ROUTES_REJECTED',

  GET_START_POINT: 'GET_START_POINT',
  GET_START_POINT_PENDING: 'GET_START_POINT_PENDING',
  GET_START_POINT_FULFILLED: 'GET_START_POINT_FULFILLED',
  GET_START_POINT_REJECTED: 'GET_START_POINT_REJECTED',

  GET_START_DESTINATION: 'GET_START_DESTINATION',
  GET_START_DESTINATION_PENDING: 'GET_START_DESTINATION_PENDING',
  GET_START_DESTINATION_FULFILLED: 'GET_START_DESTINATION_FULFILLED',
  GET_START_DESTINATION_REJECTED: 'GET_START_DESTINATION_REJECTED',

  GET_ALL_REFERENCE_ROUTES: 'GET_ALL_REFERENCE_ROUTES',
  GET_ALL_REFERENCE_ROUTES_PENDING: 'GET_ALL_REFERENCE_ROUTES_PENDING',
  GET_ALL_REFERENCE_ROUTES_FULFILLED: 'GET_ALL_REFERENCE_ROUTES_FULFILLED',
  GET_ALL_REFERENCE_ROUTES_REJECTED: 'GET_ALL_REFERENCE_ROUTES_REJECTED',

  GET_ROUTE_BY_START_AND_END: 'GET_ROUTE_BY_START_AND_END',
  GET_ROUTE_BY_START_AND_END_PENDING: 'GET_ROUTE_BY_START_AND_END_PENDING',
  GET_ROUTE_BY_START_AND_END_FULFILLED: 'GET_ROUTE_BY_START_AND_END_FULFILLED',
  GET_ROUTE_BY_START_AND_END_REJECTED: 'GET_ROUTE_BY_START_AND_END_REJECTED'
};

// define actions
export const actionGetRoutersPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: ROUTER_ACTIONS.GET_ROUTERS_PER_PAGE,
    payload: getRoutersPerPage(pageNo, pageSize)
  });
};
export const actionGetRoutersPerPageAll = (params) => (dispatch) => {
  return dispatch({
    payload: getRoutersPerPageAll(params)
  });
};

export const actionGetRoutersByID = (id) => (dispatch) => {
  return dispatch({
    type: ROUTER_ACTIONS.GET_ROUTERS_BY_ID,
    payload: getRoutersByID(id)
  });
};

export const actionCreateRouters = (payload) => (dispatch) => {
  return dispatch({
    type: ROUTER_ACTIONS.CREATE_ROUTERS,
    payload: createRouters(payload)
  });
};

export const actionUpdateRouters = (payload) => (dispatch) => {
  return dispatch({
    type: ROUTER_ACTIONS.UPDATE_ROUTERS,
    payload: updateRouters(payload)
  });
};

export const actionDeleteRouters = (id) => (dispatch) => {
  return dispatch({
    type: ROUTER_ACTIONS.DELETE_ROUTERS,
    payload: deleteRouters(id)
  });
};

export const actionGetAllRouters = () => (dispatch) => {
  return dispatch({
    type: ROUTER_ACTIONS.GET_ALL_ROUTERS,
    payload: getAllRouters()
  });
};

export const actionSeachData = (params, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: ROUTER_ACTIONS.SEARCH_DATA,
    payload: searchData(params)
  });
};
export const actionSeachDataAll = (params) => (dispatch) => {
  return dispatch({
    payload: searchDataAll(params)
  });
};

export const actionGetOptionRoute = () => (dispatch) => {
  return dispatch({
    type: ROUTER_ACTIONS.GET_OPTIONAL_ROUTES,
    payload: getOptionRoute()
  });
};

export const actionGetStartPoint = () => (dispatch) => {
  return dispatch({
    type: ROUTER_ACTIONS.GET_START_POINT,
    payload: httpClient.get(`/StartingPoint/getAll`)
  });
};

export const actionGetDestination = (cityId) => (dispatch) => {
  return dispatch({
    type: ROUTER_ACTIONS.GET_START_DESTINATION,
    payload: httpClient.get(`Destination/getById?cityId=${cityId}`)
  });
};

export const getAllReferenceRoutes = () => (dispatch) => {
  return dispatch({
    type: ROUTER_ACTIONS.GET_ALL_REFERENCE_ROUTES,
    payload: httpClient.get(`/routeinfo/getAll`)
  });
};

export const actionGetRouteByStartAndEnd = (startingPointId, destinationId, isMatching = false) => (dispatch) => {
  return dispatch({
    type: ROUTER_ACTIONS.GET_ROUTE_BY_START_AND_END,
    payload: httpClient.get(
      `/routeinfo/getByStartAndEnd?startingPointId=${startingPointId}&destinationId=${destinationId}&isMatching=${isMatching}`
    )
  });
};
