import { MOOCS_ACTIONS } from "actions/mooc";

const INIT_STATE = {
  moocData: {
    data: [],
  }
}

const MoocReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    //get all
    case MOOCS_ACTIONS.GET_ALL_MOOCS_PENDING:
      return {
        ...state,
        isLoading: true,
      };

    case MOOCS_ACTIONS.GET_ALL_MOOCS_FULFILLED:
      let moocData = {
        data: action.payload.data.data,
      };
      return {
        ...state,
        moocData: moocData,
        isLoading: false,
      };

    case MOOCS_ACTIONS.GET_ALL_MOOCS_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: "Đã xảy ra lỗi",
      };

    // get  per page
    case MOOCS_ACTIONS.GET_MOOCS_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true,
      };

    case MOOCS_ACTIONS.GET_MOOCS_PER_PAGE_FULFILLED:
      let moocsPerPage = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false,
      };
      return {
        ...state,
        moocsPerPage: moocsPerPage,
        isLoading: false,
      };

    case MOOCS_ACTIONS.GET_MOOCS_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "error",
        isMsg: true,
        msg: "Đã xảy ra lỗi",
      };

    // get routers by id
    case MOOCS_ACTIONS.GET_MOOC_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
      };

    case MOOCS_ACTIONS.GET_MOOC_BY_ID_FULFILLED:
      return {
        ...state,
        isLoading: false,
      };

    case MOOCS_ACTIONS.GET_MOOC_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "error",
        isMsg: true,
        msg: "Đã xảy ra lỗi",
      };

    // create
    case MOOCS_ACTIONS.CREATE_MOOC_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
      };

    case MOOCS_ACTIONS.CREATE_MOOC_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "success",
        isMsg: true,
        msg: "Tạo dữ liệu thành công",
      };

    case MOOCS_ACTIONS.CREATE_MOOC_REJECTED:
      let message = action.payload.response.data.message;
      return {
        ...state,
        isLoading: false,
        typeMessage: "error",
        isMsg: true,
        msg: message,
        isCloseModal: false,
      };

    // update routers
    case MOOCS_ACTIONS.UPDATE_MOOC_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
      };

    case MOOCS_ACTIONS.UPDATE_MOOC_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: "Sửa dữ liệu thành công",
        typeMessage: "success",
      };

    case MOOCS_ACTIONS.UPDATE_MOOC_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "error",
        isMsg: true,
        msg: "Đã xảy ra lỗi",
      };

    // delete routers
    case MOOCS_ACTIONS.DELETE_MOOC_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
      };

    case MOOCS_ACTIONS.DELETE_MOOC_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "success",
        isMsg: true,
        msg: "Xóa dữ liệu thành công",
      };

    case MOOCS_ACTIONS.DELETE_MOOC_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "error",
        isMsg: true,
        msg: "Đã xảy ra lỗi",
      };
    //Search
    case MOOCS_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        isLoading: true,
        isMsg: false,
      };
    }
    case MOOCS_ACTIONS.SEARCH_DATA_FULFILLED: {
      let moocsPerPage = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false,
      };
      return {
        ...state,
        isLoading: false,
        moocsPerPage: moocsPerPage,
        pagination: action.payload.data.pagination,
        isSearch: true,
      };
    }
    case MOOCS_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        isLoading: false,
        isShowMessage: true,
        typeMessage: "error",
        msg: "Không thể lấy được dữ liệu !",
      };
    }

    //get all type
    case MOOCS_ACTIONS.GET_TYPE_ROMOOC_PENDING:
      return {
        ...state,
        isLoading: true,
      };

    case MOOCS_ACTIONS.GET_TYPE_ROMOOC_FULFILLED:
      let moocDataType = {
        data: action.payload.data.data,
      };
      return {
        ...state,
        typeMooc: moocDataType,
        isLoading: false,
      };

    case MOOCS_ACTIONS.GET_TYPE_ROMOOC_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: "Đã xảy ra lỗi",
      };

    default:
      return state;
  }
};

export default MoocReducer;
