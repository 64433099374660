import { ROUTER_ACTIONS } from 'actions/router';
import { DEFAULT_PAGE_SIZE } from 'const/common';

const INIT_STATE = {
  routers: {
    data: [],
    startPoints: [],
    destinations: [],
    pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE }
  },
  allRouter: {
    data: []
  },
  referenceRoutes: [],
  isSearch: false
};

const routerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // get routers per page
    case ROUTER_ACTIONS.GET_ROUTERS_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ROUTER_ACTIONS.GET_ROUTERS_PER_PAGE_FULFILLED:
      let routers = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        routers: routers,
        isLoading: false,
        isSearch: false
      };

    case ROUTER_ACTIONS.GET_ROUTERS_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // get routers by id
    case ROUTER_ACTIONS.GET_ROUTERS_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case ROUTER_ACTIONS.GET_ROUTERS_BY_ID_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isSearch: false
      };

    case ROUTER_ACTIONS.GET_ROUTERS_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // create routers
    case ROUTER_ACTIONS.CREATE_ROUTERS_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case ROUTER_ACTIONS.CREATE_ROUTERS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Thêm dữ liệu thành công',
        isSearch: false
      };

    case ROUTER_ACTIONS.CREATE_ROUTERS_REJECTED:
      const messageError = action.payload.response.data.message + '';
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: action.payload.response.data.status === '400' ? messageError : 'Thêm dữ liệu thất bại'
      };

    // update routers
    case ROUTER_ACTIONS.UPDATE_ROUTERS_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case ROUTER_ACTIONS.UPDATE_ROUTERS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Cập nhật dữ liệu hành công',
        isSearch: false
      };

    case ROUTER_ACTIONS.UPDATE_ROUTERS_REJECTED:
      const messageErrorUpdate = action.payload.response.data.message + '';
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: action.payload.status === 400 ? messageErrorUpdate : 'Cập nhật dữ liệu thất bại'
      };

    // delete routers
    case ROUTER_ACTIONS.DELETE_ROUTERS_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case ROUTER_ACTIONS.DELETE_ROUTERS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Xóa dữ liệu thành công',
        isSearch: false
      };

    case ROUTER_ACTIONS.DELETE_ROUTERS_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Xóa dữ liệu thất bại'
      };
    //get all
    case ROUTER_ACTIONS.GET_ALL_ROUTERS_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ROUTER_ACTIONS.GET_ALL_ROUTERS_FULFILLED:
      let allRouter = {
        data: action.payload.data.data
      };
      return {
        ...state,
        allRouter: allRouter,
        isLoading: false,
        isSearch: false
      };

    case ROUTER_ACTIONS.GET_ALL_ROUTERS_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    //Search
    case ROUTER_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };
    }
    case ROUTER_ACTIONS.SEARCH_DATA_FULFILLED: {
      let routers = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false,
        isSearch: true
      };
      return {
        ...state,
        isLoading: false,
        routers: routers,
        isSearch: true
      };
    }
    case ROUTER_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        isLoading: false,
        isShowMessage: true,
        typeMessage: 'error',
        msg: 'Không thể lấy được dữ liệu !'
      };
    }

    //route options
    case ROUTER_ACTIONS.GET_OPTIONAL_ROUTES_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ROUTER_ACTIONS.GET_OPTIONAL_ROUTES_FULFILLED:
      return {
        ...state,
        routeOption: action.payload.data.data,
        isLoading: false,
        isMsg: false,
        isSearch: false
      };

    case ROUTER_ACTIONS.GET_OPTIONAL_ROUTES_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    //route options
    case ROUTER_ACTIONS.GET_START_POINT_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ROUTER_ACTIONS.GET_START_POINT_FULFILLED:
      return {
        ...state,
        startPoints: action.payload.data.data,
        isLoading: false,
        isMsg: false,
        isSearch: false
      };

    case ROUTER_ACTIONS.GET_START_POINT_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    case ROUTER_ACTIONS.GET_START_DESTINATION_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ROUTER_ACTIONS.GET_START_DESTINATION_FULFILLED:
      return {
        ...state,
        destinations: action.payload.data.data,
        isLoading: false,
        isMsg: false,
        isSearch: false
      };

    case ROUTER_ACTIONS.GET_START_DESTINATION_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    case ROUTER_ACTIONS.GET_ALL_REFERENCE_ROUTES_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ROUTER_ACTIONS.GET_ALL_REFERENCE_ROUTES_FULFILLED:
      return {
        ...state,
        referenceRoutes: action.payload.data.data.filter((route) => route.IsReferenceRoute),
        isLoading: false,
        isMsg: false
      };

    case ROUTER_ACTIONS.GET_ALL_REFERENCE_ROUTES_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi',
        isSearch: false
      };
    // get by startingpoint and destination point
    case ROUTER_ACTIONS.GET_ROUTE_BY_START_AND_END_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case ROUTER_ACTIONS.GET_ROUTE_BY_START_AND_END_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isSearch: false,
        routeSearch : action.payload.data.data,
      };

    case ROUTER_ACTIONS.GET_ROUTE_BY_START_AND_END_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    default:
      return state;
  }
};

export default routerReducer;
