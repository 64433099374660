import {
  createDataSettlementRepair,
  deleteDataSettlementRepair,
  getDataSettlementRepair,
  updateDataSettlementRepair
} from 'services/repair/settlementRepair';

export const SETTLEMENT_REPAIR = {
  GET_ALL_DATA_SETTLEMENT: 'GET_ALL_DATA_SETTLEMENT',
  GET_ALL_DATA_SETTLEMENT_PENDING: 'GET_ALL_DATA_SETTLEMENT_PENDING',
  GET_ALL_DATA_SETTLEMENT_FULFILLED: 'GET_ALL_DATA_SETTLEMENT_FULFILLED',
  GET_ALL_DATA_SETTLEMENT_REJECTED: 'GET_ALL_DATA_SETTLEMENT_REJECTED',

  CREATE_SETTLEMENT_REPAIR: 'CREATE_SETTLEMENT_REPAIR',
  CREATE_SETTLEMENT_REPAIR_PENDING: 'CREATE_SETTLEMENT_REPAIR_PENDING',
  CREATE_SETTLEMENT_REPAIR_FULFILLED: 'CREATE_SETTLEMENT_REPAIR_FULFILLED',
  CREATE_SETTLEMENT_REPAIR_REJECTED: 'CREATE_SETTLEMENT_REPAIR_REJECTED',

  UPDATE_SETTLEMENT_REPAIR: 'UPDATE_SETTLEMENT_REPAIR',
  UPDATE_SETTLEMENT_REPAIR_PENDING: 'UPDATE_SETTLEMENT_REPAIR_PENDING',
  UPDATE_SETTLEMENT_REPAIR_FULFILLED: 'UPDATE_SETTLEMENT_REPAIR_FULFILLED',
  UPDATE_SETTLEMENT_REPAIR_REJECTED: 'UPDATE_SETTLEMENT_REPAIR_REJECTED',

  DELETE_SETTLEMENT_REPAIR: 'DELETE_SETTLEMENT_REPAIR',
  DELETE_SETTLEMENT_REPAIR_PENDING: 'DELETE_SETTLEMENT_REPAIR_PENDING',
  DELETE_SETTLEMENT_REPAIR_FULFILLED: 'DELETE_SETTLEMENT_REPAIR_FULFILLED',
  DELETE_SETTLEMENT_REPAIR_REJECTED: 'DELETE_SETTLEMENT_REPAIR_REJECTED',

  CHANGE_DATA_EDIT_TABEL_REPAIR_SETTLEMENT: 'CHANGE_DATA_EDIT_TABEL_REPAIR_SETTLEMENT',
  CHANGE_DATA_EDIT_TABEL_REPAIR_SETTLEMENT_FULFILLED: 'CHANGE_DATA_EDIT_TABEL_REPAIR_SETTLEMENT_FULFILLED'
};

export const actionGetDataSettlementRepair = (id) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_REPAIR.GET_ALL_DATA_SETTLEMENT,
    payload: getDataSettlementRepair(id)
  });
};
//create
export const actionCreateDataSettlementRepair = (payload) => (dispatch) => {
  let RepairingId = payload.RepairingId;

  return dispatch({
    type: SETTLEMENT_REPAIR.CREATE_SETTLEMENT_REPAIR,
    payload: createDataSettlementRepair(payload),
    meta: {
      RepairingId: RepairingId
    }
  });
};
//UPDATE
export const actionUpdateDataSettlementRepair = (payload) => (dispatch) => {
  let RepairingId = payload.RepairingId;
  return dispatch({
    type: SETTLEMENT_REPAIR.UPDATE_SETTLEMENT_REPAIR,
    payload: updateDataSettlementRepair(payload),
    meta: {
      RepairingId: RepairingId
    }
  });
};
//DELETE
export const actionDeleteDataSettlementRepair = (repairingDetailId, repairingID, OutSource) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_REPAIR.DELETE_SETTLEMENT_REPAIR,
    payload: deleteDataSettlementRepair(repairingDetailId, repairingID, OutSource),
    meta: {
      RepairingId: repairingID
    }
  });
};

export const changeDataEditTable = (fieldName, value, message) => {
  let check = value.includes(',');
  const regexDots = /\./g;
  const regexComma = /\,/g;
  let dataValue = !check ? value.replace(regexDots, '') : value.replace(regexComma, '');
  let error = {
    [fieldName]: message
  };
  let data = {
    [fieldName]:
      !isNaN(dataValue) && dataValue !== ''
        ? parseInt(dataValue)
        : fieldName === 'actualRepairFee' && dataValue === ''
        ? 0
        : dataValue + '',
    isChange: true
  };
  return {
    type: SETTLEMENT_REPAIR.CHANGE_DATA_EDIT_TABEL_REPAIR_SETTLEMENT,
    meta: { error, message },
    payload: data
  };
};
