import api from 'HttpClient';


// const type of function get data per page
export const FETCH_HISTORIES = 'FETCH_HISTORIES';
export const FETCH_HISTORIES_PENDING = 'FETCH_HISTORIES_PENDING';
export const FETCH_HISTORIES_FULFILLED = 'FETCH_HISTORIES_FULFILLED';
export const FETCH_HISTORIES_REJECTED = 'FETCH_HISTORIES_REJECTED';
export const fetchHistory = (searchPayload) => (dispatch) => {
  return dispatch({
    type: FETCH_HISTORIES,
    payload: api.get(`/LogHistory/paging/page`, { params: { ...searchPayload} }),
    meta : searchPayload
  });
};
