import { getRowsElementNode, stringifyDOM } from 'commons/processingDom';
import { convertStyleStrToObj, rgbToHex } from 'commons/stylesUtils';
import { EMPTY_PAGING_RESULT_MSG, EMPTY_SEARCH_RESULT_MSG, GROUP_SETTING_INFOS } from 'const/common';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import XLSX from 'xlsx';

import { dataImg } from './../file/logoImage';
import { getFieldModeSettings, getModeSettings } from './utils';
const Excel = require('exceljs');
const emptyText = [EMPTY_PAGING_RESULT_MSG, EMPTY_SEARCH_RESULT_MSG];

//define style for cell
const fillCellStyle = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'F08080' }
};

const mapStylesDOMWithCells = {
  'background-color': 'fgColor'
};

function fitSizeToSheet(displayColumnInfos) {
  return displayColumnInfos.map((column) => {
    return { wpx: column.Size };
  });
}
const indexHaveDebit = 21;
const indexNotDebit = 9;
export async function downloadExcel(
  tableId,
  fileName,
  sheetName,
  displayColumnInfos,
  title = '',
  companyInfo,
  includedSignature = false,
  debitData,
  screenName,
  rowAmountBetweenFilterRowAndFirstRecord = 0,
  customerReport = false,
  preIndexHidden = 0 // for table have delete bulk preIndex = 1
) {
  //TODO: read table from html by sheetjs
  const tableElement = document.getElementById(tableId);
  const convertDOMToObj = JSON.parse(stringifyDOM(tableElement));
  var ws = XLSX.utils.table_to_sheet(tableElement, { raw: true });
  const cols = fitSizeToSheet(displayColumnInfos);
  ws['!cols'] = cols;
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  let downloadFile = fileName ? fileName : 'Download.xlsx';
  if (!downloadFile.endsWith('.xlsx') && !downloadFile.endsWith('.xls')) {
    downloadFile += '.xlsx';
  }

  //TODO: create new workbook by exceljs
  const workbookFinal = initExcel(sheetName, title, companyInfo, preIndexHidden);
  const workbook = new Excel.Workbook();
  const sheet = workbook.addWorksheet(sheetName, {
    pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 1, orientation: 'landscape' }
  });

  const merges = [...ws['!merges']];
  const colsWidth = [...ws['!cols']];

  delete ws['!autofilter'];
  delete ws['!cols'];
  delete ws['!margins'];
  delete ws['!merges'];
  delete ws['!protect'];
  delete ws['!ref'];
  delete ws['!rows'];
  delete ws['!type'];
  delete ws['!fullref'];

  if (debitData) {
    // const cellDebit = workbookFinal.worksheets[0].getCell(`${getAlphabetFromNumber(colCount - 2)}${rowCount + 2}`);
    workbookFinal.worksheets[0].addRows(getDebitNote(debitData, screenName, preIndexHidden));
    for (let i = indexNotDebit; i < indexHaveDebit; i++) {
      workbookFinal.worksheets[0].mergeCells('A' + String(i) + (i < indexHaveDebit - 2 ? ':B' : ':N') + String(i));
      // workbookFinal.worksheets[0].mergeCells('C' + String(i) + ':D' + String(i));
      const row = workbookFinal.worksheets[0].getRow(i);
      // Add style
      row.eachCell((cell) => {
        cell.font = {
          ...cell.font,
          bold: true
        };
      });
    }
  }
  //TODO: write data from workbook(sheetjs) to workbook(exceljs)
  var keys = Object.keys(ws);
  const rowsWithStylesFromDOMTree = getStylesRowFromDOM(convertDOMToObj);
  const theFirstRowIndexHasStyle = rowsWithStylesFromDOMTree[0]?.index ?? null;

  keys.forEach((key) => {
    if (!key.toString().startsWith('!')) {
      //TODO: check init value and fomat value cell
      let cell = workbook.worksheets[0].getCell(key);
      const cellFormat = getCellFormat(key, debitData);
      let cellStyles = workbookFinal.worksheets[0].getCell(cellFormat);
      const keyIndexNumber = splitKeyCell(key);
      const value = ws[key].v;
      const removeDot = value.replaceAll(',', '') + '';

      //TODO: define regex number type
      const regexIsNumber = /^([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)$/g;
      const regexIsNumberMinus = /^-([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)$/g;
      const regexIsFloatNumber = /^[+-]?\d+(\.\d+)$/g;
      const regexIsPhoneNumber = /(84|0+([1-9]{1}))+([0-9]{8})\b/g;

      const isNumber = regexIsNumber.test(removeDot);
      const isNumberMinus = regexIsNumberMinus.test(removeDot);
      const isFloatNumber = regexIsFloatNumber.test(removeDot);
      const isPhoneNumber = regexIsPhoneNumber.test(removeDot);

      if (isPhoneNumber) {
        cellStyles.value = ws[key].v;
      } else {
        if (isFloatNumber) {
          cellStyles.value = parseFloat(removeDot);
          cellStyles.numFmt = 'General';
        } else if (isNumber || isNumberMinus) {
          cellStyles.value = parseInt(removeDot);
          cellStyles.numFmt = '#,##0';
        } else {
          cellStyles.value = ws[key].v;
        }
      }

      if (theFirstRowIndexHasStyle && Number(keyIndexNumber) >= theFirstRowIndexHasStyle) {
        const rowOfIndex = rowsWithStylesFromDOMTree.find((el) => Number(keyIndexNumber) === el?.index);
        if (rowOfIndex && rowOfIndex?.fillStyles) cellStyles.fill = rowOfIndex.fillStyles;
      }

      regexIsNumber.lastIndex = 0;
      regexIsNumberMinus.lastIndex = 0;
      regexIsFloatNumber.lastIndex = 0;
    }
  });

  //TODO: add value
  workbook.eachSheet(function (worksheet, sheetId) {
    worksheet.eachRow(function (row, rowNumber) {
      workbookFinal.worksheets[0].addRow(row.values);
    });
  });

  //TODO: set width
  colsWidth.forEach((cw, index) => {
    const col = workbookFinal.worksheets[0].getColumn(index + 1);
    col.width = cw.wpx / 9;
  });

  //TODO: merge cells
  merges.forEach((merge) => {
    if (merge.e.r !== merge.s.r || merge.e.c !== merge.s.c) {
      // +1 cause row and col count from 0 but convert getAlphabetFromNumber(1) = A
      // +9 cause add header in initExcel()

      workbookFinal.worksheets[0].mergeCells(
        `${getAlphabetFromNumber(merge.s.c + 1)}${
          merge.s.r + (debitData ? indexHaveDebit : indexNotDebit)
        }:${getAlphabetFromNumber(merge.e.c + 1)}${merge.e.r + (debitData ? indexHaveDebit : indexNotDebit)}`
      );
    }
  });

  let indexFirstRecord = rowAmountBetweenFilterRowAndFirstRecord + 1;
  let isFoundFilter = false;
  const headerText = displayColumnInfos.map((item) => item.DisplayText);
  //TODO: set style table header and process filter row
  // start from 9 to avoid logo and common info initExcel()
  for (let irow = debitData ? indexHaveDebit : indexNotDebit; irow <= workbookFinal.worksheets[0].rowCount; irow++) {
    const row = workbookFinal.worksheets[0].getRow(irow);
    // console.log(row.values);
    //TODO: check if 1st row is No.(So thu tu - STT)
    if (parseInt(row.values[1 + preIndexHidden]).toString() !== 'NaN') break;
    //TODO: Add style
    row.eachCell((cell, index) => {
      cell.alignment = {
        ...cell.alignment,
        horizontal: 'center'
      };
      cell.font = {
        ...cell.font,
        bold: true
      };
    });
    if (irow < workbookFinal.worksheets[0].rowCount && !isFoundFilter) {
      //check empty table
      const nextRow = workbookFinal.worksheets[0].getRow(irow + 1);
      // remove first undefined cell value
      const nextRowValue = nextRow.values.slice(1);
      const uniqNextRowValue = _.uniq(nextRowValue);
      const isEmptyTable = uniqNextRowValue.length === 1 && emptyText.includes(uniqNextRowValue[0]);
      // check first record
      const nextRecordRow = workbookFinal.worksheets[0].getRow(irow + indexFirstRecord);
      const nextRecordRowValue = nextRecordRow.values;
      const firstRecord = parseInt(nextRecordRowValue[1 + preIndexHidden]).toString() !== 'NaN';
      // start tabble is not record
      if (isEmptyTable || firstRecord) {
        //when table empty or have row between filter row and first record hidden filter row
        // shouldnt delete filter row in this case
        const isFilterRow =
          row.values.some((value) => !headerText.includes(value)) && headerText.some((header) => header);
        row.hidden = isFilterRow;
        // stop find the filter row when found first record or empty table
        isFoundFilter = isFilterRow;
      }
    }
  }

  if (preIndexHidden) {
    for (let icol = 1; icol <= preIndexHidden; icol++) {
      const col = workbookFinal.worksheets[0].getColumn(icol);
      col.hidden = true;
    }
  }

  //TODO: set border
  for (let irow = debitData ? indexHaveDebit : indexNotDebit; irow <= workbookFinal.worksheets[0].rowCount; irow++) {
    const row = workbookFinal.worksheets[0].getRow(irow);
    row.eachCell((cell, index) => {
      cell.border = {
        ...cell.border,
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
      cell.alignment = { ...cell.alignment, wrapText: true, vertical: 'middle' };
    });
  }

  //TODO: add footer
  //#region
  const rowCount = workbookFinal.worksheets[0].rowCount;
  const colCount = workbookFinal.worksheets[0].columnCount;
  //báo cáo khách hàng
  const cellFooter = workbookFinal.worksheets[0].getCell(`${getAlphabetFromNumber(colCount - 2)}${rowCount + 2}`);
  cellFooter.value = customerReport ? 'BÊN VẬN TẢI' : 'NGƯỜI LẬP BÁO CÁO';
  cellFooter.font = { bold: true };
  const cellFooter2 = workbookFinal.worksheets[0].getCell(
    `${getAlphabetFromNumber(2 + preIndexHidden)}${rowCount + 2}`
  );
  cellFooter2.value = customerReport ? 'BÊN THUÊ VẬN TẢI' : 'NGƯỜI DUYỆT';
  cellFooter2.font = { bold: true };
  getBankInfo(workbookFinal, companyInfo);

  // signature form
  if (includedSignature) {
    const signatureRowValues = new Array(colCount);
    signatureRowValues.fill('');
    const signatureField = ['Người lập phiếu', 'Thương vụ', 'Kế toán', 'Giám đốc'];
    const signatureFieldPosition = [
      0,
      Math.floor((colCount - 1 + preIndexHidden) / (signatureField.length - 1)),
      Math.floor((2 * (colCount - 1) + preIndexHidden) / (signatureField.length - 1)),
      colCount - 2
    ];
    signatureFieldPosition.forEach((element, index) => {
      signatureRowValues[element] = signatureField[index];
    });
    const signatureRow = workbookFinal.worksheets[0].getRow(`${rowCount + 4}`);
    signatureRow.values = signatureRowValues;
  }
  //#endregion

  // set title style: bold + size 16 + in middle col of sheet
  //   if (title !== '') {
  //     const cellTitle = workbookFinal.worksheets[0].getCell(`D7`);
  //     const colCount = workbookFinal.worksheets[0].columnCount;
  //     const middleCellPosition = Math.floor(colCount / 2);

  //     const middleCell = workbookFinal.worksheets[0].getCell(`${getAlphabetFromNumber(middleCellPosition)}7`);
  //     const temp = cellTitle.value;
  //     cellTitle.value = '';
  //     middleCell.value = temp;
  //     middleCell.font = {
  //       ...middleCell.font,
  //       size: 16,
  //       bold: true
  //     };
  //   }
  if (title !== '') {
    const cellTitle = workbookFinal.worksheets[0].getCell(`A7`);
    const colCount = workbookFinal.worksheets[0].columnCount;
    const cellToMerge = `${getAlphabetFromNumber(colCount)}7`;
    const cellTitleMerged = `A7:${cellToMerge}`;
    workbookFinal.worksheets[0].mergeCells(cellTitleMerged);
    cellTitle.value = title.toUpperCase();
    cellTitle.font = {
      ...cellTitle.font,
      size: 16,
      bold: true
    };
    cellTitle.alignment = { horizontal: 'center' };
  }
  const cellSubTitle = workbookFinal.worksheets[0].getCell(`A8`);
  //  const colCount = workbookFinal.worksheets[0].columnCount;
  const cellToMerge = `${getAlphabetFromNumber(colCount)}8`;
  const cellTitleMerged = `A8:${cellToMerge}`;
  workbookFinal.worksheets[0].mergeCells(cellTitleMerged);
  cellSubTitle.value = 'Số ................ Ngày ................';
  cellSubTitle.font = {
    ...cellSubTitle.font,
    size: 14
  };
  cellSubTitle.alignment = { horizontal: 'center' };

  //TODO: set worksheet font =  time new roman
  workbookFinal.eachSheet((worksheet, sheetId) => {
    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell, cellNumber) => {
        const font = {
          ...cell.font,
          name: 'Times New Roman'
        };
        cell.font = font;
      });
    });
  });

  const buffer = await workbookFinal.xlsx.writeBuffer();

  saveAs(new Blob([buffer]), downloadFile);
}

//TODO: init header
function initExcel(sheetName, title, companyInfo, preIndexHidden) {
  const image = getModeSettings(
    companyInfo,
    GROUP_SETTING_INFOS.Report_Company_Url_Logo.GroupId,
    GROUP_SETTING_INFOS.Report_Company_Url_Logo.Name
  );
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet(sheetName, {
    // fittoHeight: is maximum possible number of pages, not number of page
    pageSetup: { fitToPage: true, fitToHeight: 1000, fitToWidth: 1, orientation: 'landscape' }
  });
  worksheet.pageSetup.margins = {
    left: 0.1,
    right: 0.1,
    top: 0.1,
    bottom: 0.1,
    header: 0.1,
    footer: 0.1
  };
  const rows = getHeader(title, companyInfo, preIndexHidden);
  worksheet.addRows(rows);
  worksheet.eachRow((row) => {
    row.eachCell((cell) => {
      cell.font = {
        ...cell.font,
        bold: true
      };
    });
  });
  const imageId = workbook.addImage({
    base64: image ?? getLogoImage(),
    extension: 'jpeg'
  });
  worksheet.addImage(imageId, {
    tl: { col: 0 + preIndexHidden, row: 0 },
    br: { col: 2 + preIndexHidden, row: 5 }
  });
  return workbook;
}

function getHeader(title, companyInfo, preIndexHidden) {
  let sheetTitle = title.toUpperCase();

  const companyName = getModeSettings(
    companyInfo,
    GROUP_SETTING_INFOS.Report_Company_Name.GroupId,
    GROUP_SETTING_INFOS.Report_Company_Name.Name
  );

  const taxCode = getModeSettings(
    companyInfo,
    GROUP_SETTING_INFOS.Report_Company_Tax_Code.GroupId,
    GROUP_SETTING_INFOS.Report_Company_Tax_Code.Name
  );

  const taxDisplayName = getFieldModeSettings(
    companyInfo,
    GROUP_SETTING_INFOS.Report_Company_Tax_Code.GroupId,
    GROUP_SETTING_INFOS.Report_Company_Tax_Code.Name,
    'DisplayName'
  );

  const address = getModeSettings(
    companyInfo,
    GROUP_SETTING_INFOS.Report_Company_Address.GroupId,
    GROUP_SETTING_INFOS.Report_Company_Address.Name
  );

  const addressDisplayName = getFieldModeSettings(
    companyInfo,
    GROUP_SETTING_INFOS.Report_Company_Address.GroupId,
    GROUP_SETTING_INFOS.Report_Company_Address.Name,
    'DisplayName'
  );

  const phoneNumber = getModeSettings(
    companyInfo,
    GROUP_SETTING_INFOS.Report_Company_Phone.GroupId,
    GROUP_SETTING_INFOS.Report_Company_Phone.Name
  );

  const phoneNumberDisplayName = getFieldModeSettings(
    companyInfo,
    GROUP_SETTING_INFOS.Report_Company_Phone.GroupId,
    GROUP_SETTING_INFOS.Report_Company_Phone.Name,
    'DisplayName'
  );

  const emptyCellPreInfo = _.fill(Array(2 + preIndexHidden), '');

  const header = [
    [], // row by array
    [...emptyCellPreInfo, companyName],
    [...emptyCellPreInfo, `${taxDisplayName} ${taxCode}`],
    [...emptyCellPreInfo, `${addressDisplayName} ${address}`],
    [...emptyCellPreInfo, `${phoneNumberDisplayName} ${phoneNumber}`],
    [],
    ['', '', '', sheetTitle],
    ['', '', '', 'Số ................ Ngày ................'],
    []
  ];
  return header;
}

function getBankInfo(workbookFinal, companyInfo) {
  const DefaultValue = getModeSettings(
    companyInfo,
    GROUP_SETTING_INFOS.Settlemet_Report_Footer.GroupId,
    GROUP_SETTING_INFOS.Settlemet_Report_Footer.Name
  );
  const inforBank = JSON.parse(DefaultValue);
  if (inforBank) {
    const bank = workbookFinal.worksheets[0].rowCount;

    const bankHeader = workbookFinal.worksheets[0].getCell(`${getAlphabetFromNumber(2)}${bank + 4}`);
    bankHeader.value =
      'Kính đề nghị quý khách hàng kiểm tra, đối chiếu và thanh toán cho chúng tôi theo số tài khoản sau:';
    bankHeader.font = { italic: true, bold: true };
    // bank account number company
    const rowCount3 = workbookFinal.worksheets[0].rowCount;
    const cellFooter5 = workbookFinal.worksheets[0].getCell(`${getAlphabetFromNumber(2)}${rowCount3 + 1}`);
    const cellFooter6 = workbookFinal.worksheets[0].getCell(`${getAlphabetFromNumber(2)}${rowCount3 + 2}`);
    const cellFooter7 = workbookFinal.worksheets[0].getCell(`${getAlphabetFromNumber(2)}${rowCount3 + 3}`);
    const cellFooter8 = workbookFinal.worksheets[0].getCell(`${getAlphabetFromNumber(2)}${rowCount3 + 4}`);
    cellFooter5.value = inforBank.title;
    cellFooter6.value = inforBank.accountNumber;
    cellFooter7.value = inforBank.accountHolder;
    cellFooter8.value = inforBank.Bank;
    cellFooter5.font = { bold: true, color: { argb: 'FF0000' }, underline: true };
    // bank account number personal
    const rowCount4 = workbookFinal.worksheets[0].rowCount;
    const cellFooter9 = workbookFinal.worksheets[0].getCell(`${getAlphabetFromNumber(2)}${rowCount4 + 2}`);
    const cellFooter10 = workbookFinal.worksheets[0].getCell(`${getAlphabetFromNumber(2)}${rowCount4 + 3}`);
    const cellFooter11 = workbookFinal.worksheets[0].getCell(`${getAlphabetFromNumber(2)}${rowCount4 + 4}`);
    const cellFooter12 = workbookFinal.worksheets[0].getCell(`${getAlphabetFromNumber(2)}${rowCount4 + 5}`);
    cellFooter9.value = inforBank.title2;
    cellFooter10.value = inforBank.accountNumber2;
    cellFooter11.value = inforBank.accountHolder2;
    cellFooter12.value = inforBank.Bank2;
    cellFooter9.font = { bold: true, color: { argb: 'FF0000' }, underline: true };
  }
}

function getDebitNote(debitData, screenName, preIndexHidden = 0) {
  const mess = debitData.mess;
  const ConsignmentCode = debitData.ConsignmentCode ? debitData.ConsignmentCode : '';
  const CustomsDeclarationNo = debitData.CustomsDeclarationNo ? debitData.CustomsDeclarationNo : '';
  const VesselNo = debitData.VesselNo ? debitData.VesselNo : '';
  const PortDeport = debitData.PortDeport ? debitData.PortDeport : '';
  const Destination = debitData.Destination ? debitData.Destination : '';
  const BillBookingNo = debitData.BillBookingNo ? debitData.BillBookingNo : '';
  const AddressCustomer = debitData.AddressCustomer ? debitData.AddressCustomer : '';
  const CustomsDeclarationDate = debitData.CustomsDeclarationDate
    ? moment(debitData.CustomsDeclarationDate).format('l')
    : '';
  let title = '';
  let value = '';
  switch (debitData.TruckingTypeId ? debitData.TruckingTypeId : '') {
    case 1:
      title = 'Trọng lượng: ';
      value = debitData.TotalWeight ? debitData.TotalWeight : 0;
      break;
    case 2:
      title = 'Số lượng cont: ';
      value = debitData.ContQuantity ? debitData.ContQuantity : 0;
      break;
    case 3:
      title = 'Số kiện: ';
      value = debitData.PackageQuantity ? debitData.PackageQuantity : 0;
      break;

    default:
      break;
  }
  const preIndexHiddenData = _.fill(Array(preIndexHidden), '');
  const debit = [
    [...preIndexHiddenData, 'Kính gửi: ', '', `${mess}`],
    [...preIndexHiddenData, 'Mã lô: ', '', `${ConsignmentCode}`],
    [...preIndexHiddenData, 'Số tờ khai: ', '', `${CustomsDeclarationNo}`],
    [...preIndexHiddenData, 'Ngày làm tờ khai:  ', '', `${CustomsDeclarationDate}`],
    [...preIndexHiddenData, 'Ngày tạo debit: ', ''],
    [...preIndexHiddenData, 'Tên tàu: ', '', `${VesselNo}`, '', '', 'Số bill/Vận đơn: ', `${BillBookingNo}`],
    [...preIndexHiddenData, 'Nơi giữ hàng: ', '', `${PortDeport}`],
    [...preIndexHiddenData, 'Điểm giao hàng: ', '', `${Destination}`],
    [...preIndexHiddenData, 'Ngày đi: ', '', '', '', '', 'Ngày trả hàng: ', ''],
    [...preIndexHiddenData, `${title}`, '', `${value}`],
    [...preIndexHiddenData, `Địa chỉ: ${AddressCustomer}`],
    [...preIndexHiddenData, `Trân trọng gửi đến Quý công ty ${screenName} như sau:`]
  ];
  return debit;
}

function getLogoImage() {
  return dataImg;
}

//TODO: convert int to Alphabet : 1-> A, 2->B, .... 27->AA,28->AB,...
function getAlphabetFromNumber(number) {
  var numeric = (number - 1) % 26;
  var letter = chr(65 + numeric);
  var number2 = parseInt((number - 1) / 26);
  if (number2 > 0) {
    return getAlphabetFromNumber(number2) + letter;
  } else {
    return letter;
  }
}

function chr(codePt) {
  if (codePt > 0xffff) {
    codePt -= 0x10000;
    return String.fromCharCode(0xd800 + (codePt >> 10), 0xdc00 + (codePt & 0x3ff));
  }
  return String.fromCharCode(codePt);
}

export function getRecordDepth(displayColumnInfos, record) {
  let depth = 1;
  displayColumnInfos.forEach((column) => {
    const columnIndex = column.index;
    const recordData = record[columnIndex];
    if (Array.isArray(recordData)) {
      const recordDataLength = recordData.length;
      if (depth < recordDataLength) {
        depth = recordDataLength;
      }
    }
  });
  return depth;
}

function createPdfTableDataByRecord(displayColumnInfos, record, mapReport) {
  const recordDepth = getRecordDepth(displayColumnInfos, record);
  const filledUpRecord = fillUpRecord(record, recordDepth, displayColumnInfos);
  let records = [];
  for (let i = 0; i < recordDepth; i++) {
    const recordText = displayColumnInfos.map((column) => {
      const columnIndex = column.index;
      const recordData = filledUpRecord[columnIndex];
      const columnText = {};
      let recordIndexData = '';
      let currentRecordDepth = 1;
      let rowSpan = 1;
      const hasColumnMapRecord = mapReport && mapReport[columnIndex];
      if (Array.isArray(recordData)) {
        currentRecordDepth = recordData.length;
        if (i < currentRecordDepth) {
          recordIndexData = hasColumnMapRecord ? mapReport[columnIndex](recordData[i], null, true) : recordData[i];
        }
        if (i === currentRecordDepth && currentRecordDepth < recordDepth) {
          rowSpan = recordDepth - i;
        }
      } else {
        if (i === 0) {
          recordIndexData = hasColumnMapRecord ? mapReport[columnIndex](recordData, null, true) : recordData;
        }
        rowSpan = recordDepth;
      }
      columnText.text = recordIndexData;
      columnText.rowSpan = rowSpan;

      return columnText;
    });
    records.push(recordText);
  }
  return records;
}

export function createPdfTableData(displayColumnInfos, exportReports, mapReport) {
  let records = [];
  exportReports.forEach((record) => {
    const reportRecords = createPdfTableDataByRecord(displayColumnInfos, record, mapReport);
    records = [...records, ...reportRecords];
  });
  return records;
}

export function fillUpRecord(record, recordDepth, columnDataInfos) {
  const filledUpRecord = { ...record };
  columnDataInfos.forEach((column) => {
    const columnIndex = column.index;
    const columnValue = record[columnIndex];
    if (Array.isArray(columnValue)) {
      const columnDepth = columnValue.length;
      for (let i = columnDepth; i < recordDepth; i++) {
        columnValue.push(null);
      }
    } else {
      filledUpRecord[columnIndex] = columnValue;
    }
  });
  return filledUpRecord;
}

function getCellFormat(column, debitData) {
  const tableRowStart = !debitData ? indexNotDebit : indexHaveDebit;
  let numberRow = column.match(/\d/g);
  let columnName = column.match(/\D/g);
  numberRow = numberRow.join('');
  columnName = columnName.join('');
  const cellNum = parseInt(numberRow) + tableRowStart - 1;
  const cell = `${columnName}${cellNum}`;
  return cell;
}

function getStylesRowFromDOM(rows) {
  const { childNodes } = rows;
  const rowHeaderAndFilter = childNodes[0];
  const rowHeaderAndFilterNumber = rowHeaderAndFilter.childNodes?.length;
  const rowsContent = getRowsElementNode(childNodes, rowHeaderAndFilterNumber);
  const mapStylesForCells = mapStyleFromDOMWithCell(rowsContent);
  return mapStylesForCells;
}

function splitKeyCell(key) {
  const numberIndexOfKey = key.match(/\d+/g);
  return numberIndexOfKey[0];
}

function mapStyleFromDOMWithCell(rows) {
  const rowsWithStyles = rows.map((el) => {
    const item = { ...el };
    const { style } = item;
    if (!style) return item;
    const stylesObj = convertStyleStrToObj(style);
    Object.keys(stylesObj).forEach((sty) => {
      Object.keys(mapStylesDOMWithCells).forEach((key) => {
        if (sty === key) {
          const property = mapStylesDOMWithCells[key];
          item.fillStyles = { ...fillCellStyle, [`${property}`]: { argb: rgbToHex(stylesObj[sty]) } };
        }
      });
    });
    return item;
  });
  return rowsWithStyles;
}
