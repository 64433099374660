import { REPAIR_ACTIONS } from 'actions/repair/repairManagement';

const RepairManagementReducer = (state = {}, action) => {
  switch (action.type) {
    //get all
    case REPAIR_ACTIONS.GET_ALL_REPAIR_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case REPAIR_ACTIONS.GET_ALL_REPAIR_FULFILLED:
      const repair = {
        pagination: action?.payload?.data?.pagination,
        data: action?.payload?.data || [],
        isMsg: false
      };
      return {
        ...state,
        repairPerPage: repair,
        allData: action?.payload?.data || [],
        isLoading: false
      };

    case REPAIR_ACTIONS.GET_ALL_REPAIR_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Không thể lấy được dữ liệu !'
      };

    // get per page
    case REPAIR_ACTIONS.GET_REPAIR_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case REPAIR_ACTIONS.GET_REPAIR_PER_PAGE_FULFILLED:
      const repairPerPage = {
        pagination: action.payload.data.pagination,
        data: action.payload.data,
        isMsg: false
      };
      return {
        ...state,
        repairPerPage: repairPerPage,
        isLoading: false
      };

    case REPAIR_ACTIONS.GET_REPAIR_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Không thể lấy được dữ liệu !'
      };

    //   // get  by id
    case REPAIR_ACTIONS.GET_REPAIR_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case REPAIR_ACTIONS.GET_REPAIR_BY_ID_FULFILLED:
      return {
        ...state,
        repairById: action.payload.data.data,
        isLoading: false,
        isSuccessUpdateFee: false
      };

    case REPAIR_ACTIONS.GET_REPAIR_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Không thể lấy được dữ liệu !'
      };
    // create
    case REPAIR_ACTIONS.CREATE_REPAIR_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case REPAIR_ACTIONS.CREATE_REPAIR_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Tạo dữ liệu thành công'
      };

    case REPAIR_ACTIONS.CREATE_REPAIR_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Tạo dữ liệu thất bại'
      };

    // update
    case REPAIR_ACTIONS.UPDATE_REPAIR_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case REPAIR_ACTIONS.UPDATE_REPAIR_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Sửa dữ liệu thành công',
        typeMessage: 'success'
      };

    case REPAIR_ACTIONS.UPDATE_REPAIR_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Sửa dữ liệu thất bại'
      };

    // delete routers
    case REPAIR_ACTIONS.DELETE_REPAIR_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case REPAIR_ACTIONS.DELETE_REPAIR_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Xóa dữ liệu thành công'
      };

    case REPAIR_ACTIONS.DELETE_REPAIR_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Sửa dữ liệu thất bại'
      };
    //search
    case REPAIR_ACTIONS.SEARCH_DATA_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case REPAIR_ACTIONS.SEARCH_DATA_FULFILLED:
      let resultSearch = {
        pagination: action.payload.data.pagination,
        data: action.payload.data,
        isMsg: false
      };
      return {
        ...state,
        repairPerPage: resultSearch,
        isLoading: false
      };

    case REPAIR_ACTIONS.SEARCH_DATA_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Không thể lấy được dữ liệu !'
      };

    //update repair fee

    case REPAIR_ACTIONS.UPDATE_REPAIR_FEE_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
        isSuccessUpdateFee: false
      };

    case REPAIR_ACTIONS.UPDATE_REPAIR_FEE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Sửa dữ liệu thành công',
        typeMessage: 'success',
        isSuccessUpdateFee: true
      };

    case REPAIR_ACTIONS.UPDATE_REPAIR_FEE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Sửa dữ liệu thất bại',
        isSuccessUpdateFee: false
      };

    case REPAIR_ACTIONS.GET_EMPLOYEES_REPAIR_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case REPAIR_ACTIONS.GET_EMPLOYEES_REPAIR_FULFILLED:
      let allDataEmployees = {
        data: action.payload.data.data
      };
      return {
        ...state,
        employees: allDataEmployees,
        isLoading: false
      };

    case REPAIR_ACTIONS.GET_EMPLOYEES_REPAIR_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Không thể lấy được dữ liệu !'
      };

    default:
      return state;
  }
};

export default RepairManagementReducer;
