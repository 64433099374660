import {
  CREATE_DRIVER_PLAN_TEMPLATE_FULFILLED,
  CREATE_DRIVER_PLAN_TEMPLATE_PENDING,
  CREATE_DRIVER_PLAN_TEMPLATE_REJECTED,
  FETCH_ALL_DRIVER_PLAN_TEMPLATES_FULFILLED,
  FETCH_ALL_DRIVER_PLAN_TEMPLATES_PENDING,
  FETCH_ALL_DRIVER_PLAN_TEMPLATES_REJECTED,
  UPSERT_DRIVER_PLAN_TEMPLATE_FULFILLED,
  UPSERT_DRIVER_PLAN_TEMPLATE_PENDING,
  UPSERT_DRIVER_PLAN_TEMPLATE_REJECTED
} from 'actions';
import { getErrorMessage } from 'commons/ReducerUtils';
import {
  MSG_ADD_FAILURE,
  MSG_ADD_SUCCESS,
  MSG_GET_ALL_FAILURE,
  MSG_TYPE_ERROR,
  MSG_TYPE_SUCCESS
} from 'const/common';
const INIT_STATE = {
  allTemplates: [],
  loading: false,
  showMessage: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_DRIVER_PLAN_TEMPLATES_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case FETCH_ALL_DRIVER_PLAN_TEMPLATES_FULFILLED: {
      return {
        ...state,
        loading: false,
        allTemplates: action.payload.data.data,
        showMessage: false,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case FETCH_ALL_DRIVER_PLAN_TEMPLATES_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: getErrorMessage(action, MSG_GET_ALL_FAILURE),
        messageType: MSG_TYPE_ERROR
      };
    }

    case CREATE_DRIVER_PLAN_TEMPLATE_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case CREATE_DRIVER_PLAN_TEMPLATE_FULFILLED: {
      return {
        ...state,
        loading: false,
        alertMessage: MSG_ADD_SUCCESS,
        showMessage: false,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case CREATE_DRIVER_PLAN_TEMPLATE_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: getErrorMessage(action, MSG_ADD_FAILURE),
        messageType: MSG_TYPE_ERROR
      };
    }

    case UPSERT_DRIVER_PLAN_TEMPLATE_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case UPSERT_DRIVER_PLAN_TEMPLATE_FULFILLED: {
      return {
        ...state,
        loading: false,
        alertMessage: MSG_ADD_SUCCESS,
        showMessage: false,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case UPSERT_DRIVER_PLAN_TEMPLATE_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: getErrorMessage(action, MSG_ADD_FAILURE),
        messageType: MSG_TYPE_ERROR
      };
    }

    default:
      return state;
  }
};
