import api from 'HttpClient';

export const SET_OPEN_PERMISSION_GROUP_BY_CUSTOMER_MODAL = 'SET_OPEN_PERMISSION_GROUP_BY_CUSTOMER_MODAL';
export const setOpenPermissionGroupByCustomerModal = (data, type) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_PERMISSION_GROUP_BY_CUSTOMER_MODAL,
    payload: { data, type }
  });
};

export const SET_CLOSE_PERMISSION_GROUP_BY_CUSTOMER_MODAL = 'SET_CLOSE_PERMISSION_GROUP_BY_CUSTOMER_MODAL';
export const setClosePermissionGroupByCustomerModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_PERMISSION_GROUP_BY_CUSTOMER_MODAL
  });
};

export const FETCH_ALL_PERMISSION_GROUP_BY_CUSTOMERS = 'FETCH_ALL_PERMISSION_GROUP_BY_CUSTOMERS';
export const FETCH_ALL_PERMISSION_GROUP_BY_CUSTOMERS_PENDING = 'FETCH_ALL_PERMISSION_GROUP_BY_CUSTOMERS_PENDING';
export const FETCH_ALL_PERMISSION_GROUP_BY_CUSTOMERS_FULFILLED = 'FETCH_ALL_PERMISSION_GROUP_BY_CUSTOMERS_FULFILLED';
export const FETCH_ALL_PERMISSION_GROUP_BY_CUSTOMERS_REJECTED = 'FETCH_ALL_PERMISSION_GROUP_BY_CUSTOMERS_REJECTED';
export const fetchAllPermissionGroupByCustomers = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_PERMISSION_GROUP_BY_CUSTOMERS,
    payload: api.get(`/CustomerInfo/getAllCustomerGroupInfo`)
  });
};

// const type of function get data per page
export const FETCH_PERMISSION_GROUP_BY_CUSTOMERS = 'FETCH_PERMISSION_GROUP_BY_CUSTOMERS';
export const FETCH_PERMISSION_GROUP_BY_CUSTOMERS_PENDING = 'FETCH_PERMISSION_GROUP_BY_CUSTOMERS_PENDING';
export const FETCH_PERMISSION_GROUP_BY_CUSTOMERS_FULFILLED = 'FETCH_PERMISSION_GROUP_BY_CUSTOMERS_FULFILLED';
export const FETCH_PERMISSION_GROUP_BY_CUSTOMERS_REJECTED = 'FETCH_PERMISSION_GROUP_BY_CUSTOMERS_REJECTED';
export const fetchPermissionGroupByCustomers = (searchPayload, page, pageSize) => (dispatch) => {
  return dispatch({
    type: FETCH_PERMISSION_GROUP_BY_CUSTOMERS,
    payload: api.get(`/CustomerGroupInfo/paging/page`, { params: { ...searchPayload, page, pageSize: pageSize } })
  });
};

// const type of function create data
export const CREATE_PERMISSION_GROUP_BY_CUSTOMER = 'CREATE_PERMISSION_GROUP_BY_CUSTOMER';
export const CREATE_PERMISSION_GROUP_BY_CUSTOMER_PENDING = 'CREATE_PERMISSION_GROUP_BY_CUSTOMER_PENDING';
export const CREATE_PERMISSION_GROUP_BY_CUSTOMER_FULFILLED = 'CREATE_PERMISSION_GROUP_BY_CUSTOMER_FULFILLED';
export const CREATE_PERMISSION_GROUP_BY_CUSTOMER_REJECTED = 'CREATE_PERMISSION_GROUP_BY_CUSTOMER_REJECTED';
export const createPermissionGroupByCustomer = (payload) => (dispatch) => {
  return dispatch({
    type: CREATE_PERMISSION_GROUP_BY_CUSTOMER,
    payload: api.post(`/CustomerGroupInfo/create`, payload)
  });
};

// const type of function update data
export const UPDATE_PERMISSION_GROUP_BY_CUSTOMER = 'UPDATE_PERMISSION_GROUP_BY_CUSTOMER';
export const UPDATE_PERMISSION_GROUP_BY_CUSTOMER_PENDING = 'UPDATE_PERMISSION_GROUP_BY_CUSTOMER_PENDING';
export const UPDATE_PERMISSION_GROUP_BY_CUSTOMER_FULFILLED = 'UPDATE_PERMISSION_GROUP_BY_CUSTOMER_FULFILLED';
export const UPDATE_PERMISSION_GROUP_BY_CUSTOMER_REJECTED = 'UPDATE_PERMISSION_GROUP_BY_CUSTOMER_REJECTED';
export const updatePermissionGroupByCustomer = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_PERMISSION_GROUP_BY_CUSTOMER,
    payload: api.put(`/CustomerGroupInfo/update`, payload),
    meta: payload
  });
};
// const type of get deatail
export const FETCH_PERMISSION_GROUP_BY_CUSTOMER_DETAIL = 'FETCH_PERMISSION_GROUP_BY_CUSTOMER_DETAIL';
export const FETCH_PERMISSION_GROUP_BY_CUSTOMER_DETAIL_PENDING = 'FETCH_PERMISSION_GROUP_BY_CUSTOMER_DETAIL_PENDING';
export const FETCH_PERMISSION_GROUP_BY_CUSTOMER_DETAIL_FULFILLED = 'FETCH_PERMISSION_GROUP_BY_CUSTOMER_DETAIL_FULFILLED';
export const FETCH_PERMISSION_GROUP_BY_CUSTOMER_DETAIL_REJECTED = 'FETCH_PERMISSION_GROUP_BY_CUSTOMER_DETAIL_REJECTED';
export const getPermissionGroupByCustomerDetail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_PERMISSION_GROUP_BY_CUSTOMER_DETAIL,
    payload: api.get(`/CustomerGroupInfo/getById/${id}`)
  });
};

// const type of delete
export const DELETE_PERMISSION_GROUP_BY_CUSTOMER = 'DELETE_PERMISSION_GROUP_BY_CUSTOMER';
export const DELETE_PERMISSION_GROUP_BY_CUSTOMER_PENDING = 'DELETE_PERMISSION_GROUP_BY_CUSTOMER_PENDING';
export const DELETE_PERMISSION_GROUP_BY_CUSTOMER_FULFILLED = 'DELETE_PERMISSION_GROUP_BY_CUSTOMER_FULFILLED';
export const DELETE_PERMISSION_GROUP_BY_CUSTOMER_REJECTED = 'DELETE_PERMISSION_GROUP_BY_CUSTOMER_REJECTED';
export const deletePermissionGroupByCustomer = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_PERMISSION_GROUP_BY_CUSTOMER,
    payload: api.delete(`/CustomerGroupInfo/delete/${id}`)
  });
};

