import { DOWNPAYMENT_ORDER_LIST_ACTIONS } from 'actions/downPaymentOfOrderList';
const INIT_STATE = {
  listData: [],
  isSuccess: false,
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  isCloseModal: false,
  statusDownPayment: {},
  extension: {},
  isReload: false,
  statusList: [],
  listInstalment: [],
  listInstalmentFees: [],
  listFeesByInstalments: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DOWNPAYMENT_ORDER_LIST_ACTIONS.GET_LIST_DOWNPAYMENT_ORDER_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isSuccess: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_LIST_ACTIONS.GET_LIST_DOWNPAYMENT_ORDER_FULFILLED: {
      return {
        ...state,
        loading: false,
        listData: action.payload.data.data.data,
        isSearch: false,
        isCloseModal: false,
        isSuccess: action.payload.data.data && action.payload.data.data.data.length > 0 ? false : true,
        isReload: false,
        // isUpdateSubmit: true,
        statusDownPayment: action.payload.data.data.status,
        extension: action.payload.data.data.extension,
        statusList: action.payload.data.data.status
      };
    }
    case DOWNPAYMENT_ORDER_LIST_ACTIONS.GET_LIST_DOWNPAYMENT_ORDER_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        isSuccess: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_LIST_ACTIONS.DELETE_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_LIST_ACTIONS.DELETE_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Xóa dữ liệu thành công',
        isShowMessage: true,
        isReload: true
      };
    }
    case DOWNPAYMENT_ORDER_LIST_ACTIONS.DELETE_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Xóa dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isReload: false
      };
    }
    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }
    case DOWNPAYMENT_ORDER_LIST_ACTIONS.CREATE_TEMPLATE_ITEM_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isSuccess: false,
        isCloseModal: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_LIST_ACTIONS.CREATE_TEMPLATE_ITEM_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isShowMessage: true,
        isSuccess: false,
        isCloseModal: true,
        isReload: true
      };
    }
    case DOWNPAYMENT_ORDER_LIST_ACTIONS.CREATE_TEMPLATE_ITEM_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Tạo dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isSuccess: false,
        isCloseModal: false,
        isReload: false
      };
    }

    case DOWNPAYMENT_ORDER_LIST_ACTIONS.GET_ALL_INSTALMENT_CATEGORY_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isSuccess: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_LIST_ACTIONS.GET_ALL_INSTALMENT_CATEGORY_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        listInstalment: action.payload.data ? action.payload.data.data : [],
        isCloseModal: false,
        isSuccess: action.payload.data && action.payload.data.data.length > 0 ? false : true,
        isReload: false,
        statusList: action.payload.data.data.status
      };
    }
    case DOWNPAYMENT_ORDER_LIST_ACTIONS.GET_ALL_INSTALMENT_CATEGORY_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        isSuccess: false,
        isReload: false
      };
    }

    case DOWNPAYMENT_ORDER_LIST_ACTIONS.CREATE_INSTALMENT_BY_CATEGORY_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isSuccess: false,
        isCloseModal: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_LIST_ACTIONS.CREATE_INSTALMENT_BY_CATEGORY_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isShowMessage: true,
        isSuccess: false,
        isCloseModal: true,
        isReload: true
      };
    }
    case DOWNPAYMENT_ORDER_LIST_ACTIONS.CREATE_INSTALMENT_BY_CATEGORY_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Tạo dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isSuccess: false,
        isCloseModal: false,
        isReload: false
      };
    }

    case DOWNPAYMENT_ORDER_LIST_ACTIONS.GET_INSTALMENT_FEES_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isSuccess: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_LIST_ACTIONS.GET_INSTALMENT_FEES_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        listInstalmentFees: action.payload.data ? action.payload.data.data : [],
        isCloseModal: false,
        isSuccess: action.payload.data && action.payload.data.data.length > 0 ? false : true,
        isReload: false,
        statusList: action.payload.data.data.status
      };
    }
    case DOWNPAYMENT_ORDER_LIST_ACTIONS.GET_INSTALMENT_FEES_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        isSuccess: false,
        isReload: false
      };
    }

    case DOWNPAYMENT_ORDER_LIST_ACTIONS.GET_INSTALMENT_BY_ID_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isSuccess: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_LIST_ACTIONS.GET_INSTALMENT_BY_ID_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        listFeesByInstalments: action.payload.data ? action.payload.data.data : [],
        isCloseModal: false,
        isSuccess: action.payload.data && action.payload.data.data.length > 0 ? false : true,
        isReload: false,
        statusList: action.payload.data.data.status
      };
    }
    case DOWNPAYMENT_ORDER_LIST_ACTIONS.GET_INSTALMENT_BY_ID_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        isSuccess: false,
        isReload: false
      };
    }

    default:
      return state;
  }
};
