import httpClient from 'HttpClient';
export const EXCEL_START = 'EXCEL_START';
export const EXCEL_HEADER_FOOTER_FULFILLED = 'EXCEL_HEADER_FOOTER_FULFILLED';
export const EXCEL_HEADER_FOOTER = 'EXCEL_HEADER_FOOTER';
export const EXCEL_DEFAULT_DATA = 'EXCEL_DEFAULT_DATA';
export const EXCEL_CUSTOMER_STYLE = 'EXCEL_CUSTOMER_STYLE';
export const EXCEL_EXPORT_DATA = 'EXCEL_EXPORT_DATA';
export const RESET_DATA = 'EXCEL_EXPORT_DATA';

export const excelStart = (params) => (dispatch) => {
  return dispatch({
    type: EXCEL_START,
    payload: params
  });
};
export const resetDataExcel = () => (dispatch) => {
  return dispatch({
    type: RESET_DATA
  });
};

export const excelDefaultData = (params) => (dispatch) => {
  return dispatch({
    type: EXCEL_DEFAULT_DATA,
    payload: params
  });
};
export const excelCustomerStyle = (params) => (dispatch) => {
  return dispatch({
    type: EXCEL_CUSTOMER_STYLE,
    payload: params
  });
};
export const exportExcel = (params) => (dispatch) => {
  return dispatch({
    type: EXCEL_EXPORT_DATA,
    payload: params
  });
};
export const excelHeaderFooter = (params) => (dispatch) => {
  return dispatch({
    type: EXCEL_HEADER_FOOTER,
    payload: httpClient.get('HeaderFooter/GetAllFooter', { params })
  });
};
