import { CONSIGNMENT_REPORT_ACTIONS } from 'actions/report/consignmentReport';
import { getErrorMessage } from 'commons/ReducerUtils';

const INIT_STATE = {
  listPerPage: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  pagination: {},
  isCloseModal: false,
  isSearch: false,
  consignmentByMonth: [],
  consignmentByDay: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONSIGNMENT_REPORT_ACTIONS.GET_CONSIGNMENT_REPORT_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case CONSIGNMENT_REPORT_ACTIONS.GET_CONSIGNMENT_REPORT_PER_PAGE_FULFILLED: {
      const data = action.payload.data.data
      const pagination =  action.payload.data.pagination
      const listPerPage = pagination.pageNo === 1 ? data : state.listPerPage.concat(data);
      return {
        ...state,
        loading: false,
        listPerPage,
        pagination,
        isSearch: false
      };
    }
    case CONSIGNMENT_REPORT_ACTIONS.GET_CONSIGNMENT_REPORT_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }
    case CONSIGNMENT_REPORT_ACTIONS.GET_CONSIGNMENT_BY_MONTH: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case CONSIGNMENT_REPORT_ACTIONS.GET_CONSIGNMENT_BY_MONTH_FULFILLED: {
      return {
        ...state,
        loading: false,
        consignmentByMonth: action.payload.data.data
      };
    }
    case CONSIGNMENT_REPORT_ACTIONS.GET_CONSIGNMENT_BY_MONTH_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: getErrorMessage(action, 'Không thể lấy được dữ liệu !')
      };
    }

    case CONSIGNMENT_REPORT_ACTIONS.GET_CONSIGNMENT_BY_DAY_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case CONSIGNMENT_REPORT_ACTIONS.GET_CONSIGNMENT_BY_DAY_FULFILLED: {
      return {
        ...state,
        loading: false,
        consignmentByDay: action.payload.data.data
      };
    }
    case CONSIGNMENT_REPORT_ACTIONS.GET_CONSIGNMENT_BY_DAY_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: getErrorMessage(action, 'Không thể lấy được dữ liệu !')
      };
    }

    default:
      return state;
  }
};
