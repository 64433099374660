const OPERATE_CHANGE_PERMISSION = ["AllowAdd", "AllowEdit", "AllowDelete"];

export function findPermission(treePermissions, FunctionId) {
    let changedPermission = null;
    if (Array.isArray(treePermissions)) {
        treePermissions.forEach((treePermission) => {
            changedPermission = traverseToFindTreePermission(treePermission, FunctionId);
        });
    }
    else {
        changedPermission = traverseToFindTreePermission(treePermissions, FunctionId);
    }
    return changedPermission;
}

function traverseToFindTreePermission(treePermission, FunctionId) {
    let changedPermission = null;
    if (treePermission.FunctionId === FunctionId) {
        changedPermission = treePermission;
    }
    else {
        treePermission.Branchs.forEach(child => {
            const result = traverseToFindTreePermission(child, FunctionId);
            if (null !== result) {
                changedPermission = result;
            }
        });
    }
    return changedPermission;
}

export function changeRowPermission(treePermissions, permissionId, value) {
    let changedPermission = null;
    if (Array.isArray(treePermissions)) {
        changedPermission = treePermissions.map((treePermission) => {
            return traverseToChangeRowTreePermission(treePermission, treePermission, permissionId, value);
        });
    }
    else {
        changedPermission = traverseToChangeRowTreePermission(treePermissions, treePermissions, permissionId, value);
    }
    return changedPermission;
}

function traverseToChangeRowTreePermission(root, node, permissionId, value) {
    let changedNode = node;
    if (node.FunctionId ===  permissionId) {
        changedNode = changeNodeRow(root, node, value);
    }
    const changedPermission = {
        ...changedNode,
        Branchs: node.Branchs.map(child => {
            return traverseToChangeRowTreePermission(root, child, permissionId, value);
        })
    };
    
    return changedPermission;
}

function changeNodeRow(root, node, value) {
    // if (node.AllowAll !== value) {
    //     node.AllowAll = value;
    //     node['Dirty'] = true;
    // }
    
    for (let prop in node) {
        if (prop !== 'AllowAll' && prop.startsWith('Allow')) {
            if (node[prop] !== value) {
                node[prop] = value;
                // updateChildren(root, node, prop, value);
                changeFieldPermission(root, node.FunctionId, prop, value);
            }
            
        }
    }
    // changeFieldPermission(root, node.FunctionId, 'AllowAll', value);
    return node;
}

// function updateChildren(root, node, prop, value) {
//     return changeFieldPermission(root, node.FunctionId, prop, value);
// }

export function changeFieldPermission(treePermissions, permissionId, fieldName, value) {
    let changedPermission = null;
    if (Array.isArray(treePermissions)) {
        changedPermission = treePermissions.map((treePermission) => {
            return traverseToChangeFieldTreePermission(treePermission, permissionId, fieldName, value);
        });
    }
    else {
        changedPermission = traverseToChangeFieldTreePermission(treePermissions, permissionId, fieldName, value);
    }
    return changedPermission;
}



function changeNode(node, fieldName, value) {
    if (node[fieldName] !== value) {
        node[fieldName] = value;
        node.Dirty = true;
        changeFieldAffect(node, fieldName, value);
    }
    
}

function changeFieldAffect(node, fieldName, value) {
    if (value && OPERATE_CHANGE_PERMISSION.includes(fieldName)) {
        node['AllowAccess'] = value;
    }
    if (!value && fieldName === 'AllowAccess') {
        OPERATE_CHANGE_PERMISSION.forEach(changedField => {
            node[changedField] = value;
        })
    }
}

function changeSubNode(node, fieldName, value) {
    node.Branchs.forEach(child => {
        if (child[fieldName] !== value) {
            child[fieldName] = value;
            child.Dirty = true;
            changeFieldAffect(child, fieldName, value);
        }
        changeSubNode(child, fieldName, value);
    });
}

function traverseToChangeFieldTreePermission(treePermission, permissionId, fieldName, value) {
    if (treePermission.FunctionId ===  permissionId) {
        changeNode(treePermission, fieldName, value);
        changeSubNode(treePermission, fieldName, value);
    }
    const changedPermissionBranches = treePermission.Branchs.map(child => {
        return traverseToChangeFieldTreePermission(child, permissionId, fieldName, value);
    });
    let changedPermission2 = changeParentFollowChildrenField(treePermission, changedPermissionBranches, fieldName);
    let changedPermission3 = changeAllowAllFieldFollowOtherField(treePermission, fieldName);
    let changedPermission = {
        ...changedPermission2,
        Dirty: changedPermission2.Dirty || changedPermission3.Dirty,
        AllowAll: changedPermission3.AllowAll,
        Branchs: changedPermissionBranches
    };
    
    return changedPermission;
}

function changeAllowAllFieldFollowOtherField(treePermission) {
    let allChanged = true;
    for (let prop in treePermission) {
        if (prop !== 'AllowAll' && prop.startsWith('Allow') && !treePermission[prop]) {
            allChanged = false;
        }
    }
    if (treePermission.AllowAll !== allChanged) {
        treePermission.AllowAll = allChanged;
        treePermission.Dirty = true;
    }
    return treePermission;
}

function changeParentFollowChildrenFieldWithoutAffect(treePermission, changedPermissionBranches, fieldName) {
    let allChanged = true;
    changedPermissionBranches.forEach(child => {
        if (!child[fieldName]) {
            allChanged = false;
        }
    });
    if (treePermission.fieldName !== allChanged) {
        treePermission[fieldName]= allChanged;
        treePermission.Dirty = true;
        changeFieldAffect(treePermission, fieldName, allChanged);
    }
    return allChanged;
}

function changeParentFollowChildrenField(treePermission, changedPermissionBranches, fieldName) {
    
    if (changedPermissionBranches.length > 0) {
        changeParentFollowChildrenFieldWithoutAffect(treePermission, changedPermissionBranches, fieldName);
        if (OPERATE_CHANGE_PERMISSION.includes(fieldName)) {
            changeParentFollowChildrenFieldWithoutAffect(treePermission, changedPermissionBranches, 'AllowAccess');
        }
        if (fieldName === 'AllowAccess') {
            OPERATE_CHANGE_PERMISSION.forEach(field => {
                changeParentFollowChildrenFieldWithoutAffect(treePermission, changedPermissionBranches, field);
            })
        }
    }
    
    return treePermission;
}

export function flatTreePermission(treePermissions) {
    let parseTreePermissions = treePermissions;
    if (!Array.isArray(treePermissions)) {
        parseTreePermissions = treePermissions.Branchs;
    }
    const flatPermissions = traverseToFlatTreePermissions(parseTreePermissions, []);
    
    return flatPermissions;
}

function traverseToFlatTreePermissions(treePermissions, flatPermissions) {
    if (!treePermissions || treePermissions.length === 0) return flatPermissions; 
    let permissions = [...flatPermissions];
    // copy each child node
    treePermissions.forEach((treePermission) => {
        const nodeFlatPermissions = traverseToFlatTreePermission(treePermission, []);
        permissions = [...permissions, ...nodeFlatPermissions];
    });
    return permissions;
}

function traverseToFlatTreePermission(treePermission, flatPermissions) {
    if (!treePermission) return flatPermissions; 
    let permissions = [...flatPermissions];
    //copy current node
    permissions[permissions.length] = copyPermission(treePermission);
    // copy each child node
    treePermission.Branchs.forEach((nodePermission) => {
        const nodeFlatPermissions = traverseToFlatTreePermission(nodePermission, []);
        permissions = [...permissions, ...nodeFlatPermissions];
    });
    return permissions;
}

function copyPermission(treePermission) {
    const permission = {...treePermission};
    delete permission.Branchs;
    return permission;
}

export function findDirtyPermission(treePermissions) {
    let permissions = [];
    if (Array.isArray(treePermissions)) {
        treePermissions.forEach((treePermission) => {
            const nodeFlatPermissions = traverseToFindDirtyTreePermission(treePermission, []);
            permissions = [...permissions, ...nodeFlatPermissions];
        });
    }
    else {
        permissions = traverseToFindDirtyTreePermission(treePermissions, []);
    }
    
    return permissions;
}


// function traverseToFindDirtyTreePermissions(treePermissions, flatPermissions) {
//     if (!treePermissions || treePermissions.length === 0) return flatPermissions; 
//     let permissions = [...flatPermissions];
//     // copy each child node
//     treePermissions.forEach((treePermission) => {
//         const nodeFlatPermissions = traverseToFindDirtyTreePermission(treePermission, []);
//         permissions = [...permissions, ...nodeFlatPermissions];
//     });
//     return permissions;
// }

function traverseToFindDirtyTreePermission(treePermission, flatPermissions) {
    if (!treePermission) return flatPermissions; 
    let permissions = [...flatPermissions];
    //copy current node
    if (treePermission.Dirty) {
        permissions[permissions.length] = copyPermission(treePermission);
    }
    treePermission.Branchs.forEach((nodePermission) => {
        const nodeFlatPermissions = traverseToFindDirtyTreePermission(nodePermission, []);
        permissions = [...permissions, ...nodeFlatPermissions];
    });
    
    return permissions;
}