import httpClient from 'HttpClient';

export const DRIVER_ADVANCE_MANAGEMENT = {
  CREATE_DRIVER_ADVANCE: 'CREATE_DRIVER_ADVANCE',
  CREATE_DRIVER_ADVANCE_PENDING: 'CREATE_DRIVER_ADVANCE_PENDING',
  CREATE_DRIVER_ADVANCE_FULFILLED: 'CREATE_DRIVER_ADVANCE_FULFILLED',
  CREATE_DRIVER_ADVANCE_REJECTED: 'CREATE_DRIVER_ADVANCE_REJECTED',

  UPDATE_DRIVER_ADVANCE: 'UPDATE_DRIVER_ADVANCE',
  UPDATE_DRIVER_ADVANCE_PENDING: 'UPDATE_DRIVER_ADVANCE_PENDING',
  UPDATE_DRIVER_ADVANCE_FULFILLED: 'UPDATE_DRIVER_ADVANCE_FULFILLED',
  UPDATE_DRIVER_ADVANCE_REJECTED: 'UPDATE_DRIVER_ADVANCE_REJECTED',

  DELETE_DRIVER_REPORT: 'DELETE_DRIVER_REPORT',
  DELETE_DRIVER_REPORT_PENDING: 'DELETE_DRIVER_REPORT_PENDING',
  DELETE_DRIVER_REPORT_FULFILLED: 'DELETE_DRIVER_REPORT_FULFILLED',
  DELETE_DRIVER_REPORT_REJECTED: 'DELETE_DRIVER_REPORT_REJECTED',

  DELETE_DRIVER_PAYMENT: 'DELETE_DRIVER_PAYMENT',
  DELETE_DRIVER_PAYMENT_PENDING: 'DELETE_DRIVER_PAYMENT_PENDING',
  DELETE_DRIVER_PAYMENT_FULFILLED: 'DELETE_DRIVER_PAYMENT_FULFILLED',
  DELETE_DRIVER_PAYMENT_REJECTED: 'DELETE_DRIVER_PAYMENT_REJECTED',

  CONFIRM_DRIVER_PAYMENT: 'CONFIRM_DRIVER_PAYMENT',
  CONFIRM_DRIVER_PAYMENT_PENDING: 'CONFIRM_DRIVER_PAYMENT_PENDING',
  CONFIRM_DRIVER_PAYMENT_FULFILLED: 'CONFIRM_DRIVER_PAYMENT_FULFILLED',
  CONFIRM_DRIVER_PAYMENT_REJECTED: 'CONFIRM_DRIVER_PAYMENT_REJECTED',

  CREATE_DRIVER_PAYMENT: 'CREATE_DRIVER_PAYMENT',
  CREATE_DRIVER_PAYMENT_PENDING: 'CREATE_DRIVER_PAYMENT_PENDING',
  CREATE_DRIVER_PAYMENT_FULFILLED: 'CREATE_DRIVER_PAYMENT_FULFILLED',
  CREATE_DRIVER_PAYMENT_REJECTED: 'CREATE_DRIVER_PAYMENT_REJECTED',

  UPDATE_DRIVER_PAYMENT: 'UPDATE_DRIVER_PAYMENT',
  UPDATE_DRIVER_PAYMENT_PENDING: 'UPDATE_DRIVER_PAYMENT_PENDING',
  UPDATE_DRIVER_PAYMENT_FULFILLED: 'UPDATE_DRIVER_PAYMENT_FULFILLED',
  UPDATE_DRIVER_PAYMENT_REJECTED: 'UPDATE_DRIVER_PAYMENT_REJECTED'
};

export const createDriverAdvance = (payload) => (dispatch) => {
  return dispatch({
    type: DRIVER_ADVANCE_MANAGEMENT.CREATE_DRIVER_ADVANCE,
    payload: httpClient.post(`/AdvanceMoneyForDriver/createReport`, payload)
  });
};

export const updateDriverAdvance = (payload) => (dispatch) => {
  return dispatch({
    type: DRIVER_ADVANCE_MANAGEMENT.UPDATE_DRIVER_ADVANCE,
    payload: httpClient.put(`/AdvanceMoneyForDriver/updateReport`, payload)
  });
};

export const actionDeleteDriverReport = (id) => (dispatch) => {
  return dispatch({
    type: DRIVER_ADVANCE_MANAGEMENT.DELETE_DRIVER_REPORT,
    payload: httpClient.delete(`/AdvanceMoneyForDriver/reportDelete/${id}`)
  });
};

export const actionDeleteDriverPayment = (id) => (dispatch) => {
  return dispatch({
    type: DRIVER_ADVANCE_MANAGEMENT.DELETE_DRIVER_PAYMENT,
    payload: httpClient.delete(`/AdvanceMoneyForDriver/deletePayment/${id}`)
  });
};

export const actionConfirmDriverPayment = (listPayment) => (dispatch) => {
  return dispatch({
    type: DRIVER_ADVANCE_MANAGEMENT.CONFIRM_DRIVER_PAYMENT,
    payload: httpClient.put(`/AdvanceMoneyForDriver/confirmPayment`, listPayment)
  });
};

export const actionCreateDriverPayment = (payload) => (dispatch) => {
  return dispatch({
    type: DRIVER_ADVANCE_MANAGEMENT.CREATE_DRIVER_PAYMENT,
    payload: httpClient.post(`/AdvanceMoneyForDriver/createPayment`, payload)
  });
};

export const actionUpdateDriverPayment = (payload) => (dispatch) => {
  return dispatch({
    type: DRIVER_ADVANCE_MANAGEMENT.UPDATE_DRIVER_PAYMENT,
    payload: httpClient.put(`/AdvanceMoneyForDriver/updatePayment`, payload)
  });
};  
