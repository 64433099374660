import { DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS } from "actions/accounting/downPaymentOfOrder";

const INIT_STATE = {
  listPerPage: [],
  loading: false,
  typeMessage: "",
  message: "",
  isShowMessage: false,
  pagination: {},
  isSearch: false,
  isCloseModal: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.GET_DOWNPAYMENT_OF_ODER_ACCOUNTANT_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.GET_DOWNPAYMENT_OF_ODER_ACCOUNTANT_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.GET_DOWNPAYMENT_OF_ODER_ACCOUNTANT_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
        isCloseModal: false,
      };
    }

    case "UPDATE_SHOW_ALERT": {
      return {
        ...state,
        isShowMessage: false,
      };
    }

    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        isShowMessage: false,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.SEARCH_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }

    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.UPDATE_STATUS_BEHAFT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.UPDATE_STATUS_BEHAFT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "success",
        message: "Cập nhật trạng thái thành công !",
        isCloseModal: true,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.UPDATE_STATUS_BEHAFT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể cập nhật trạng thái !",
        isCloseModal: false,
      };
    }

    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.UPDATE_STATUS_SETTLEMENT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.UPDATE_STATUS_SETTLEMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "success",
        message: "Cập nhật trạng thái thành công !",
        isCloseModal: true,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.UPDATE_STATUS_SETTLEMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể cập nhật được trạng thái !",
        isCloseModal: false,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.CREATE_DATA_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.CREATE_DATA_PAYMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "success",
        message: "Thêm mới dữ liệu thành công !",
        isCloseModal: true,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.CREATE_DATA_PAYMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message:
          action.payload.response.data.status === "400"
            ? action.payload.response.data.message
            : "Thêm mới dữ liệu thất bại !",
        isCloseModal: false,
      };
    }

    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.UPDATE_STATUS_SUB_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.UPDATE_STATUS_SUB_PAYMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "success",
        message: "Cập nhật trạng thái tạm ứng thành công !",
        isCloseModal: true,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.UPDATE_STATUS_SUB_PAYMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể cập nhật trạng thái tạm ứng !",
        isCloseModal: false,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.GET_USER_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.GET_USER_FULFILLED: {
      return {
        ...state,
        loading: false,
        employees: action.payload.data.data,
        isSearch: false,
        isCloseModal: false,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.GET_USER_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
        isCloseModal: false,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.DELETE_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.DELETE_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: "success",
        message: "Xóa dữ liệu thành công",
        isShowMessage: true,
        isCloseModal: true,
      };
    }
    case DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.DELETE_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        message:
          action.payload.response.data.status === "400"
            ? action.payload.response.data.message
            : "Xóa dữ liệu thất bại",
        isShowMessage: true,
        typeMessage: "error",
        isCloseModal: false,
      };
    }

    default:
      return state;
  }
};
