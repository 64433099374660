import api from 'HttpClient';

export const CUSTOM_SERVICE_ACTIONS = {
  GET_CUSTOM_SERVICE: 'GET_CUSTOM_SERVICE',
  GET_CUSTOM_SERVICE_PENDING: 'GET_CUSTOM_SERVICE_PENDING',
  GET_CUSTOM_SERVICE_FULFILLED: 'GET_CUSTOM_SERVICE_FULFILLED',
  GET_CUSTOM_SERVICE_REJECTED: 'GET_CUSTOM_SERVICE_REJECTED',

  CREATE_CUSTOM_SERVICE: 'CREATE_CUSTOM_SERVICE',
  CREATE_CUSTOM_SERVICE_PENDING: 'CREATE_CUSTOM_SERVICE_PENDING',
  CREATE_CUSTOM_SERVICE_FULFILLED: 'CREATE_CUSTOM_SERVICE_FULFILLED',
  CREATE_CUSTOM_SERVICE_REJECTED: 'CREATE_CUSTOM_SERVICE_REJECTED',

  UPDATE_CUSTOM_SERVICE: 'UPDATE_CUSTOM_SERVICE',
  UPDATE_CUSTOM_SERVICE_PENDING: 'UPDATE_CUSTOM_SERVICE_PENDING',
  UPDATE_CUSTOM_SERVICE_FULFILLED: 'UPDATE_CUSTOM_SERVICE_FULFILLED',
  UPDATE_CUSTOM_SERVICE_REJECTED: 'UPDATE_CUSTOM_SERVICE_REJECTED',

  DELETE_CUSTOM_SERVICE: 'DELETE_CUSTOM_SERVICE',
  DELETE_CUSTOM_SERVICE_PENDING: 'DELETE_CUSTOM_SERVICE_PENDING',
  DELETE_CUSTOM_SERVICE_FULFILLED: 'DELETE_CUSTOM_SERVICE_FULFILLED',
  DELETE_CUSTOM_SERVICE_REJECTED: 'DELETE_CUSTOM_SERVICE_REJECTED',

  GET_CUSTOMER_INFO_SERVICE: 'GET_CUSTOMER_INFO_SERVICE',
  GET_CUSTOMER_INFO_SERVICE_PENDING: 'GET_CUSTOMER_INFO_SERVICE_PENDING',
  GET_CUSTOMER_INFO_SERVICE_FULFILLED: 'GET_CUSTOMER_INFO_SERVICE_FULFILLED',
  GET_CUSTOMER_INFO_SERVICE_REJECTED: 'GET_CUSTOMER_INFO_SERVICE_REJECTED',

  GET_ROUTER_INFO_SERVICE: 'GET_ROUTER_INFO_SERVICE',
  GET_ROUTER_INFO_SERVICE_PENDING: 'GET_ROUTER_INFO_SERVICE_PENDING',
  GET_ROUTER_INFO_SERVICE_FULFILLED: 'GET_ROUTER_INFO_SERVICE_FULFILLED',
  GET_ROUTER_INFO_SERVICE_REJECTED: 'GET_ROUTER_INFO_SERVICE_REJECTED',

  GET_TRUCKING_TYPE_INFO_SERVICE: 'GET_TRUCKING_TYPE_INFO_SERVICE',
  GET_TRUCKING_TYPE_INFO_SERVICE_PENDING: 'GET_TRUCKING_TYPE_INFO_SERVICE_PENDING',
  GET_TRUCKING_TYPE_INFO_SERVICE_FULFILLED: 'GET_TRUCKING_TYPE_INFO_SERVICE_FULFILLED',
  GET_TRUCKING_TYPE_INFO_SERVICE_REJECTED: 'GET_TRUCKING_TYPE_INFO_SERVICE_REJECTED',

  GET_CONSIMENT_GOODS_TYPE_INFO_SERVICE: 'GET_CONSIMENT_GOODS_TYPE_INFO_SERVICE',
  GET_CONSIMENT_GOODS_TYPE_INFO_SERVICE_PENDING: 'GET_CONSIMENT_GOODS_TYPE_INFO_SERVICE_PENDING',
  GET_CONSIMENT_GOODS_TYPE_INFO_SERVICE_FULFILLED: 'GET_CONSIMENT_GOODS_TYPE_INFO_SERVICE_FULFILLED',
  GET_CONSIMENT_GOODS_TYPE_INFO_SERVICE_REJECTED: 'GET_CONSIMENT_GOODS_TYPE_INFO_SERVICE_REJECTED',

  GET_UNIT_SERVICE: 'GET_UNIT_SERVICE',
  GET_UNIT_SERVICE_PENDING: 'GET_UNIT_SERVICE_PENDING',
  GET_UNIT_SERVICE_FULFILLED: 'GET_UNIT_SERVICE_FULFILLED',
  GET_UNIT_SERVICE_REJECTED: 'GET_UNIT_SERVICE_REJECTED',

  GET_STANDARD_FEE_SERVICE: 'GET_STANDARD_FEE_SERVICE',
  GET_STANDARD_FEE_SERVICE_PENDING: 'GET_STANDARD_FEE_SERVICE_PENDING',
  GET_STANDARD_FEE_SERVICE_FULFILLED: 'GET_STANDARD_FEE_SERVICE_FULFILLED',
  GET_STANDARD_FEE_SERVICE_REJECTED: 'GET_STANDARD_FEE_SERVICE_REJECTED',

  DECLARATION_FLOW: 'DECLARATION_FLOW',
  DECLARATION_FLOW_PENDING: 'DECLARATION_FLOW_PENDING',
  DECLARATION_FLOW_FULFILLED: 'DECLARATION_FLOW_FULFILLED',
  DECLARATION_FLOW_REJECTED: 'DECLARATION_FLOW_REJECTED'
};

export const getCustomerCareAction = (params) => (dispatch) => {
  return dispatch({
    type: CUSTOM_SERVICE_ACTIONS.GET_CUSTOM_SERVICE,
    payload: api.get(`/customercare`, { params })
  });
};
export const getCustomerCareActionAll = (params) => (dispatch) => {
  return dispatch({
    // type: CUSTOM_SERVICE_ACTIONS.GET_CUSTOM_SERVICE,
    payload: api.get(`/customercare`, { params })
  });
};

export const createCustomerCareAction = (payload) => (dispatch) => {
  return dispatch({
    type: CUSTOM_SERVICE_ACTIONS.CREATE_CUSTOM_SERVICE,
    payload: api.post('/customercare', payload)
  });
};

export const updateCustomerCareAction = (payload) => (dispatch) => {
  return dispatch({
    type: CUSTOM_SERVICE_ACTIONS.UPDATE_CUSTOM_SERVICE,
    payload: api.put('/customercare', payload)
  });
};

export const deleteCustomerCareAction = (Id) => (dispatch) => {
  return dispatch({
    type: CUSTOM_SERVICE_ACTIONS.DELETE_CUSTOM_SERVICE,
    payload: api.delete(`/customercare/${Id}`)
  });
};

export const getCustomerInforAction = (payload) => (dispatch) => {
  return dispatch({
    type: CUSTOM_SERVICE_ACTIONS.GET_CUSTOMER_INFO_SERVICE,
    payload: api.get('/customercare/customerinfo')
  });
};

export const getRouterInfoAction = (payload) => (dispatch) => {
  return dispatch({
    type: CUSTOM_SERVICE_ACTIONS.GET_ROUTER_INFO_SERVICE,
    payload: api.get('/routeinfo/getall')
  });
};

export const getTruckingTypeInfoAction = (payload) => (dispatch) => {
  return dispatch({
    type: CUSTOM_SERVICE_ACTIONS.GET_TRUCKING_TYPE_INFO_SERVICE,
    payload: api.get('/truckingtypeinfo/getall')
  });
};

export const getConsimentGoodsTypeAction = (payload) => (dispatch) => {
  return dispatch({
    type: CUSTOM_SERVICE_ACTIONS.GET_CONSIMENT_GOODS_TYPE_INFO_SERVICE,
    payload: api.get('/sysconsignmentgoodstype/getall')
  });
};

export const getUnitAction = (payload) => (dispatch) => {
  return dispatch({
    type: CUSTOM_SERVICE_ACTIONS.GET_UNIT_SERVICE,
    payload: api.get('/unit/getall')
  });
};

export const getStandardFeeAction = (payload) => (dispatch) => {
  return dispatch({
    type: CUSTOM_SERVICE_ACTIONS.GET_STANDARD_FEE_SERVICE,
    payload: api.get('/customercare/sysstandardfee')
  });
};

export const getDeclarationFlow = (payload) => (dispatch) => {
  return dispatch({
    type: CUSTOM_SERVICE_ACTIONS.DECLARATION_FLOW,
    payload: api.get('DeclarationFlow/getAll')
  });
};
