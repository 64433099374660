import api from 'HttpClient';

//Add new group action

export const FETCH_SETUP_COLUMN_FORMULAR = 'FETCH_SETUP_COLUMN_FORMULAR';
export const FETCH_SETUP_COLUMN_FORMULAR_PENDING = 'FETCH_SETUP_COLUMN_FORMULAR_PENDING';
export const FETCH_SETUP_COLUMN_FORMULAR_FULFILLED = 'FETCH_SETUP_COLUMN_FORMULAR_FULFILLED';
export const FETCH_SETUP_COLUMN_FORMULAR_REJECTED = 'FETCH_SETUP_COLUMN_FORMULAR_REJECTED';
export const fetchSetupDialogColumnsFormulas = (screenName) => (dispatch) => {
  return dispatch({
    type: FETCH_SETUP_COLUMN_FORMULAR,
    payload: api.get(`/SettingGrid/getDefaultByScreen/${screenName}`),
    meta: {
      screenName
    }
  });
};

export const CHANGE_SETUP_FIELD_VALUE_FORMULAR = 'CHANGE_SETUP_FIELD_VALUE_FORMULAR';
export const CHANGE_SETUP_FIELD_VALUE_FORMULAR_PENDING = 'CHANGE_SETUP_FIELD_VALUE_FORMULAR_PENDING';
export const CHANGE_SETUP_FIELD_VALUE_FORMULAR_FULFILLED = 'CHANGE_SETUP_FIELD_VALUE_FORMULAR_FULFILLED';
export const CHANGE_SETUP_FIELD_VALUE_FORMULAR_REJECTED = 'CHANGE_SETUP_FIELD_VALUE_FORMULAR_REJECTED';
export const changeSetupFieldValueFormulas = (value, column, prop) => (dispatch) => {
  return dispatch({
    type: CHANGE_SETUP_FIELD_VALUE_FORMULAR,
    payload: { value, column, prop }
  });
};

export const UPDATE_SETUP_DIALOG_FORMULAR = 'UPDATE_SETUP_DIALOG_FORMULAR';
export const UPDATE_SETUP_DIALOG_FORMULAR_PENDING = 'UPDATE_SETUP_DIALOG_FORMULAR_PENDING';
export const UPDATE_SETUP_DIALOG_FORMULAR_FULFILLED = 'UPDATE_SETUP_DIALOG_FORMULAR_FULFILLED';
export const UPDATE_SETUP_DIALOG_FORMULAR_REJECTED = 'UPDATE_SETUP_DIALOG_FORMULAR_REJECTED';
export const updateSetupDialogFormulas = (screenName, dirtySetups, columns) => (dispatch) => {
  const updateUrl = `/SettingGrid/updateDefault`;
  return dispatch({
    type: UPDATE_SETUP_DIALOG_FORMULAR,
    payload: api.put(updateUrl, { screenName, data: dirtySetups, sortedTree: [] })
  });
};

export const MOVE_UP_COLUMN = 'MOVE_UP_COLUMN';
export const MOVE_UP_COLUMN_PENDING = 'MOVE_UP_COLUMN_PENDING';
export const MOVE_UP_COLUMN_FULFILLED = 'MOVE_UP_COLUMN_FULFILLED';
export const MOVE_UP_COLUMN_REJECTED = 'MOVE_UP_COLUMN_REJECTED';
export const moveUpColumn = (columnId) => (dispatch) => {
  return dispatch({
    type: MOVE_UP_COLUMN,
    payload: columnId
  });
};

export const MOVE_DOWN_COLUMN = 'MOVE_DOWN_COLUMN';
export const MOVE_DOWN_COLUMN_PENDING = 'MOVE_DOWN_COLUMN_PENDING';
export const MOVE_DOWN_COLUMN_FULFILLED = 'MOVE_DOWN_COLUMN_FULFILLED';
export const MOVE_DOWN_COLUMN_REJECTED = 'MOVE_DOWN_COLUMN_REJECTED';
export const moveDownColumn = (columnId) => (dispatch) => {
  return dispatch({
    type: MOVE_DOWN_COLUMN,
    payload: columnId
  });
};

export const BACKUP_COLUMN = 'BACKUP_COLUMN';
export const backupColumn = () => (dispatch) => {
  setTimeout(() => {
    return dispatch({
      type: BACKUP_COLUMN
    });
  }, 500);
};
