import { formatNumberToReadableString } from './MathUtils';

export function formatedNumber(value) {
  if (value === null || value === undefined) {
    return '';
  }
  const valueWithoutComma = value.toString().replaceAll(/(,|\D)/g, '');
  const number = valueWithoutComma !== '' ? parseInt(valueWithoutComma) : '';
  const formatedNumber = formatNumberToReadableString(number);
  return formatedNumber;
}

export function convertToNumber(val) {
  if (val === null || val === undefined || val === '' || val === 0 || val === '0') {
    return 0;
  }

  // if (parseInt(val) !== NaN) {
  //     return parseInt(val);
  // }

  // if (parseInt(val) !== NaN) {
  //     return parseInt(val);
  // }

  val = val.toString();
  val = val.replaceAll(/,/g, '');
  val = Number(val);
  return val;
}

export function convertPriceNumber(val) {
  if (val === null || val === undefined) {
    return null;
  }

  // if (parseInt(val) !== NaN) {
  //     return parseInt(val);
  // }

  // if (parseInt(val) !== NaN) {
  //     return parseInt(val);
  // }

  val = val.toString();
  val = val.replaceAll(/,/g, '');
  val = parseInt(val);
  return val;
}

export const formatCurrency = (price) => {
  if (price === null || price === undefined || isNaN(price)) {
    return '';
  }
  if (!isNaN(price)) {
    price = parseInt(price);
  }
  return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};
