import api from 'HttpClient';

export const getAllVehicles = () => {
  return api.get(`/tractortrailerinfo/getAll`);
};

export const getTractorTrailerByPrimaryCompany = (params) => {
  return api.get(`/TractorTrailerInfo/getTractorTrailerByPrimaryCompany`, { params });
};

export const getVehiclesPerPage = (pageNo, pageSize) => {
  return api.get(`/tractortrailerinfo/paging/page?page=${pageNo}&pagesize=${pageSize}`);
};

export const getVehiclesByID = (id) => {
  return api.get(`/tractortrailerinfo/getById/${id}`);
};

export const createVehicles = (payload) => {
  return api.post(`tractortrailerinfo/create`, payload);
};

export const updateVehicles = (payload) => {
  return api.put(`tractortrailerinfo/update`, payload);
};

export const deleteVehicles = (id) => {
  return api.delete(`/tractortrailerinfo/deleteById/${id}`);
};

export const searchVehicles = (params, pageNo, pageSize) => {
  return api.get(`/tractortrailerinfo/search?Page=${pageNo}&PageSize=${pageSize}`, {
    params
  });
};
export const searchVehiclesAll = (params) => {
  return api.get(`/tractortrailerinfo/search?`, {
    params
  });
};

export const getVehiclesByFilters = (payload) => {
  let url = `/tractortrailerinfo/getByDepartmentId`;
  // payload.departmentsID === undefined || payload.departmentsID === null
  //   ? `/tractortrailerinfo/getByDepartmentId?transportCompanyId=${payload.transportCompanyID}&deliveryDate=${payload.departureDate}`
  //   : `/tractortrailerinfo/getByDepartmentId?transportCompanyId=${payload.transportCompanyID}&departmentId=${payload.departmentsID}&deliveryDate=${payload.departureDate}`;
  return api.get(url, { params: payload });
};

export const createVehicleDriver = (payload) => {
  return api.post(`/tractortrailerinfo/create/VehicleDriver`, payload);
};

export const filterCarByStatus = (statusId, pageNo, pageSize) => {
  return api.get(`/tractortrailerinfo/getByStatusId?statusId=${statusId}&Page=${pageNo}&PageSize=${pageSize}`);
};

export const filtersCarByDepartment = (payload, filterType) => {
  let url = `/tractortrailerinfo/getByDepartmentId/truckingdate`;
  if (filterType) {
    url = `/tractortrailerinfo/getUsedTractorTrailerByTruckingDate`;
  }
  const params = {
    transportCompanyId: payload.transportCompanyID,
    departmentId: payload.departmentsID
    // truckingDate: payload.truckingDate
  };
  // payload.truckingDate === null
  //   ? `tractortrailerinfo/getByDepartmentId/truckingdate?transportCompanyId=${payload.transportCompanyID}&departmentId=${payload.departmentsID}`
  //   : `tractortrailerinfo/getByDepartmentId/truckingdate?transportCompanyId=${payload.transportCompanyID}&departmentId=${payload.departmentsID}&truckingDate=${payload.truckingDate}`;
  return api.get(url, { params: params });
};

export const getAllVehiclesByCompany = (id) => {
  return api.get(`TractorTrailerInfo/getByDepartmentId?transportCompanyId=${id}`);
};
