import {
  VEHICLE_CATEGORY_ACTIONS
} from "actions/vehicleCategoryActions";


export default (state = {}, action) => {
  switch (action.type) {
    // get all vehicles
    case VEHICLE_CATEGORY_ACTIONS.GET_ALL_VEHICLE_CATEGORY_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case VEHICLE_CATEGORY_ACTIONS.GET_ALL_VEHICLE_CATEGORY_FULFILLED:
            
        let listAll = {
          data: action.payload.data.data
        };
      return {
        ...state,
        listAll: listAll,
        isLoading: false,
      };

    case VEHICLE_CATEGORY_ACTIONS.GET_ALL_VEHICLE_CATEGORY_REJECTED:
      return {
        ...state,
        isLoading: false,
          isMsg: true,
          msg: "Đã xảy ra lỗi"
      };

    default:
      return state;
  }
}
