import api from 'HttpClient';

export const SET_OPEN_WORKING_STATUS_MODAL = 'SET_OPEN_WORKING_STATUS_MODAL';
export const setOpenWorkingStatusModal = (data) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_WORKING_STATUS_MODAL,
    payload: data
  });
};

export const SET_CLOSE_WORKING_STATUS_MODAL = 'SET_CLOSE_WORKING_STATUS_MODAL';
export const setCloseWorkingStatusModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_WORKING_STATUS_MODAL
  });
};

export const FETCH_ALL_WORKING_STATUSES = 'FETCH_ALL_WORKING_STATUSES';
export const FETCH_ALL_WORKING_STATUSES_PENDING = 'FETCH_ALL_WORKING_STATUSES_PENDING';
export const FETCH_ALL_WORKING_STATUSES_FULFILLED = 'FETCH_ALL_WORKING_STATUSES_FULFILLED';
export const FETCH_ALL_WORKING_STATUSES_REJECTED = 'FETCH_ALL_WORKING_STATUSES_REJECTED';
export const fetchAllWorkingStatuses = (params) => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_WORKING_STATUSES,
    payload: api.get(`/WorkingStatusInfo/getAll`, { params })
  });
};

// const type of function get data per page
export const FETCH_WORKING_STATUSES = 'FETCH_WORKING_STATUSES';
export const FETCH_WORKING_STATUSES_PENDING = 'FETCH_WORKING_STATUSES_PENDING';
export const FETCH_WORKING_STATUSES_FULFILLED = 'FETCH_WORKING_STATUSES_FULFILLED';
export const FETCH_WORKING_STATUSES_REJECTED = 'FETCH_WORKING_STATUSES_REJECTED';
export const fetchWorkingStatuses = (searchPayload, page, pageSize) => (dispatch) => {
  return dispatch({
    type: FETCH_WORKING_STATUSES,
    payload: api.get(`/WorkingStatusInfo/Search`, { params: { ...searchPayload, page, pageSize: pageSize } })
  });
};

// const type of function create data
export const CREATE_WORKING_STATUS = 'CREATE_WORKING_STATUS';
export const CREATE_WORKING_STATUS_PENDING = 'CREATE_WORKING_STATUS_PENDING';
export const CREATE_WORKING_STATUS_FULFILLED = 'CREATE_WORKING_STATUS_FULFILLED';
export const CREATE_WORKING_STATUS_REJECTED = 'CREATE_WORKING_STATUS_REJECTED';
export const createWorkingStatus = (payload) => (dispatch) => {
  return dispatch({
    type: CREATE_WORKING_STATUS,
    payload: api.post(`/WorkingStatusInfo/create`, payload)
  });
};

// const type of function update data
export const UPDATE_WORKING_STATUS = 'UPDATE_WORKING_STATUS';
export const UPDATE_WORKING_STATUS_PENDING = 'UPDATE_WORKING_STATUS_PENDING';
export const UPDATE_WORKING_STATUS_FULFILLED = 'UPDATE_WORKING_STATUS_FULFILLED';
export const UPDATE_WORKING_STATUS_REJECTED = 'UPDATE_WORKING_STATUS_REJECTED';
export const updateWorkingStatus = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_WORKING_STATUS,
    payload: api.put(`/WorkingStatusInfo/update`, payload),
    meta: payload
  });
};
// const type of get deatail
export const FETCH_WORKING_STATUS_DETAIL = 'FETCH_WORKING_STATUS_DETAIL';
export const FETCH_WORKING_STATUS_DETAIL_PENDING = 'FETCH_WORKING_STATUS_DETAIL_PENDING';
export const FETCH_WORKING_STATUS_DETAIL_FULFILLED = 'FETCH_WORKING_STATUS_DETAIL_FULFILLED';
export const FETCH_WORKING_STATUS_DETAIL_REJECTED = 'FETCH_WORKING_STATUS_DETAIL_REJECTED';
export const getWorkingStatusDetail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_WORKING_STATUS_DETAIL,
    payload: api.get(`/WorkingStatusInfo/${id}`)
  });
};

// const type of delete
export const DELETE_WORKING_STATUS = 'DELETE_WORKING_STATUS';
export const DELETE_WORKING_STATUS_PENDING = 'DELETE_WORKING_STATUS_PENDING';
export const DELETE_WORKING_STATUS_FULFILLED = 'DELETE_WORKING_STATUS_FULFILLED';
export const DELETE_WORKING_STATUS_REJECTED = 'DELETE_WORKING_STATUS_REJECTED';
export const deleteWorkingStatus = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_WORKING_STATUS,
    payload: api.delete(`/WorkingStatusInfo/delete/${id}`)
  });
};
