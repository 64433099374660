import React from "react";
import { Redirect, Route } from "react-router-dom";

import MobileLayout from "./Pages/MobileWeb";

class RestrictedRoute extends React.Component {
  renderDashboardlayout() {
    const { component: Component, authUser, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          return authUser ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }}
      />
    );
  }

  renderMobileLayout() {
    const { component: Component, authUser, isDriver, ...rest } = this.props;

    // return <Route {...rest} render={(props) => <MobileLayout {...props} />} />;
    return (
      <Route
        {...rest}
        render={(props) => {
          const path = { ...props.location, pathname: "/mobile" };
          return authUser ? (
            <MobileLayout {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }}
      />
    );
  }
  render() {
    const { component: Component, authUser, isDriver, ...rest } = this.props;
    return isDriver ? this.renderMobileLayout() : this.renderDashboardlayout();
  }
}

export default RestrictedRoute;
