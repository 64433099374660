import { STATUS_ACTIONS } from "actions/operationStatus";

export default (state = {}, action) => {
    switch (action.type) {
        // get all vehicles
        case STATUS_ACTIONS.GET_ALL_OPERATION_STATUS_PENDING:
            return {
                ...state,
                isLoading: true
            };

        case STATUS_ACTIONS.GET_ALL_OPERATION_STATUS_FULFILLED:
            let allData = {
                data: action.payload.data
            };
            return {
                ...state,
                allData: allData,
                isLoading: false,
            };

        case STATUS_ACTIONS.GET_ALL_OPERATION_STATUS_REJECTED:
            return {
                ...state,
                isLoading: false,
                isMsg: true,
                msg: "Đã xảy ra lỗi"
            };

        default:
            return state;
    }
}
