import { NORM_SETTING_ACTIONS } from 'actions/norm/NormSetting';
import { isNull } from 'commons/utils';

let INIT_STATE = {
  tabs: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  pagination: {},
  isSearch: false,
  isCloseModal: false,
  reload: false,
  recipeOil: [],
  recipeCost: [],
  recipeCostRelity: [],
  recipeRouteCost: [],
  messageInput: '',
  typeInputrecipeOil: [],
  typeInputrecipeCost: [],
  typeInputrecipeCostRelity: [],
  typeInputrecipeRouteCost: [],
  note: { recipeOil: '', recipeCost: '', recipeCostRelity: '', recipeRouteCost: '' },
  contentNorm: { recipeOil: '', recipeCost: '', recipeCostRelity: '', recipeRouteCost: '' },
  formulas: {},
  functions: {}
};

function checkTypeNorm(type) {
  switch (type) {
    case 0:
      return 'recipeOil';
    case 1:
      return 'recipeCost';
    case 2:
      return 'recipeCostRelity';
    case 3:
      return 'recipeRouteCost';
    default:
      return '';
  }
}

function findKeySpecial(value) {
  let valueCheck = value + '';
  if (valueCheck.includes('%') || valueCheck.includes('.')) {
    return true;
  } else {
    return false;
  }
}

function checkValidInputNumber(value) {
  const isHasKeySpicial = findKeySpecial(value);
  const countDot = [...value].filter((el) => el === '.').length;
  const countPercent = [...value].filter((el) => el === '%').length;
  if (isHasKeySpicial) {
    if (value.indexOf('%') === 0 || value.indexOf('.') === 0) {
      return false;
    } else if (countDot === 1 || countPercent === 1) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

function mappingFormulas(settingItems) {
  const formulas = {};
  if (!isNull(settingItems)) {
    settingItems.forEach((item) => {
      const key = item.Name;
      const formulaValue = item.Formular;
      formulas[key] = formulaValue;
    });
  }
  // TODO: Will remove later START ↓↓↓↓↓↓
  // formulas['OilNorm'] = OIL_NORM_FUNC;
  // formulas['TravelFee'] = TRAVEL_FEE_FUNC;
  // formulas['TransportFee'] = TRANSPORT_FEE_FUNC;
  // TODO: Will remove later END ↑↑↑↑↑↑
  return formulas;
}

function parseFunctions(formulas) {
  if (isNull(formulas)) return {};
  const functions = {};
  Object.keys(formulas).forEach((field) => {
    if (
      [
        'CalculateTransportFee',
        'CalculateTransportFeeAndExternalTransportFee',
        'CalucateStandardOil',
        'CalculateTravelFee'
      ].includes(field)
    )
      return;
    const functionText = formulas[field];
    try {
      const func = eval(`(${functionText})`);
      functions[field] = func;
    } catch (err) {
      console.log(`%c ERROR: function set for ${field} is invalid`, 'background: #222; color: #bada55');
    }
  });
  return functions;
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case NORM_SETTING_ACTIONS.GET_DATA_NORM_SETTING_PENDING: {
      return {
        ...state,
        loading: true,
        reload: false,
        isShowMessage: false
      };
    }
    case NORM_SETTING_ACTIONS.GET_DATA_NORM_SETTING_FULFILLED: {
      const settingItems = action.payload.data.data;
      const formulas = mappingFormulas(settingItems);
      const functions = parseFunctions(formulas);
      return {
        ...state,
        loading: false,
        tabs: settingItems,
        // pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false,
        reload: false,
        formulas,
        functions
      };
    }
    case NORM_SETTING_ACTIONS.GET_DATA_NORM_SETTING_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        reload: false,
        isCloseModal: false
      };
    }

    case NORM_SETTING_ACTIONS.UPDATE_DATA_NORM_SETTING_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case NORM_SETTING_ACTIONS.UPDATE_DATA_NORM_SETTING_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Cập nhật định mức thành công !',
        isCloseModal: true,
        reload: true
      };
    }
    case NORM_SETTING_ACTIONS.UPDATE_DATA_NORM_SETTING_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message:
          action.payload.response.data.status === 400
            ? action.payload.response.data.message + ''
            : 'Không thể cập nhật định mức !',
        isCloseModal: false,
        reload: false
      };
    }

    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }

    case NORM_SETTING_ACTIONS.CHANGE_FIELD: {
      const { value, index } = action.payload;
      let valueIndexUpdate = state.tabs[index];
      let formular = valueIndexUpdate.Formular != null ? valueIndexUpdate.Formular : '';
      let dataUpdate = { ...valueIndexUpdate, Formular: formular.concat(value) };
      const dataTabsUpdate = [...state.tabs];
      dataTabsUpdate.splice(index, 1, dataUpdate);
      return {
        ...state,
        tabs: dataTabsUpdate
      };
    }

    // case NORM_SETTING_ACTIONS.CHANGE_VALUES_INPUT: {
    //   const { key } = action.payload;
    //   let field = checkTypeNorm(action.payload.type);
    //   let fieldValue = [`${state[field]}`] + '';
    //   return {
    //     ...state,
    //     [field]:
    //       key === 'Delete'
    //         ? fieldValue.substring(0, fieldValue.length - 1)
    //         : [`${state[field]}`] + '' + action.payload.value
    //   };
    // }

    case NORM_SETTING_ACTIONS.CHANGE_KEY_CACULATOR: {
      const { data } = action.payload;
      let field = checkTypeNorm(action.payload.type);
      let fieldValue = state[`${field}`].concat([data]);
      return {
        ...state,
        [field]: fieldValue
      };
    }

    case NORM_SETTING_ACTIONS.CHANGE_VALUES_INPUT: {
      const data = action.payload.value;
      const { key } = action.payload;
      let field = checkTypeNorm(action.payload.type);
      let fieldValue = state[`${field}`].concat([data]);
      let valid = checkValidInputNumber(data);
      let message = valid ? '' : 'Cần nhập đúng định dạng';
      return {
        ...state,
        [field]: message === '' && key === 'Enter' ? fieldValue : state[`${field}`],
        messageInput: message
      };
    }

    case NORM_SETTING_ACTIONS.REMOVE_ITEM: {
      const { index } = action.payload;
      console.log('index11111', index);
      let field = checkTypeNorm(action.payload.type);
      const fieldValue = state[`${field}`];
      const dataUpdate = [...fieldValue];
      dataUpdate.splice(index, 1);
      console.log('fieldValue', dataUpdate);

      return {
        ...state,
        [field]: dataUpdate
      };
    }

    case NORM_SETTING_ACTIONS.CHANGE_TYPE_INPUT: {
      const { type, tab } = action.payload;
      let field = `typeInput${tab}`;
      let fieldValue = state[`typeInput${tab}`].concat([type]);
      return {
        ...state,
        [field]: fieldValue
      };
    }

    case NORM_SETTING_ACTIONS.REMOVE_TYPE_INPUT: {
      const { index } = action.payload;
      console.log('index11111', index);
      let field = checkTypeNorm(action.payload.type);
      let fieldName = `typeInput${field}`;
      const fieldValue = state[`typeInput${field}`];
      const dataUpdate = [...fieldValue];
      dataUpdate.splice(index, 1);

      return {
        ...state,
        [fieldName]: dataUpdate
      };
    }

    case NORM_SETTING_ACTIONS.CHANGE_NOTE_NORM: {
      const { content, index } = action.payload;
      let valueIndexUpdate = state.tabs[index];
      let dataUpdate = { ...valueIndexUpdate, Description: content };
      const dataTabsUpdate = [...state.tabs];
      dataTabsUpdate.splice(index, 1, dataUpdate);
      return {
        ...state,
        tabs: dataTabsUpdate
      };
    }

    // case NORM_SETTING_ACTIONS.CHANGE_INPUT_NORM: {
    //   const { value, type } = action.payload;
    //   let field = checkTypeNorm(type);
    //   let fieldValue = state.contentNorm[field];
    //   return {
    //     ...state,
    //     contentNorm: { ...state.note, [field]: fieldValue.concat(value) }
    //   };
    // }

    case NORM_SETTING_ACTIONS.CHANGE_INPUT_NORM: {
      const { value, index, type } = action.payload;
      let valueIndexUpdate = state.tabs[index];
      let formular = type === 'field' ? value : valueIndexUpdate.Formular + '' + value;
      let dataUpdate = { ...valueIndexUpdate, Formular: formular };
      const dataTabsUpdate = [...state.tabs];
      dataTabsUpdate.splice(index, 1, dataUpdate);
      console.log('dataTabsUpdate', dataTabsUpdate);
      return {
        ...state,
        tabs: dataTabsUpdate
      };
    }

    case NORM_SETTING_ACTIONS.REMOVE_INPUT_NORM: {
      const { index } = action.payload;
      let valueIndexUpdate = state.tabs[index];
      let dataUpdate = {
        ...valueIndexUpdate,
        Formular: valueIndexUpdate.Formular.substring(0, valueIndexUpdate.Formular.length - 1)
      };
      const dataTabsUpdate = [...state.tabs];
      dataTabsUpdate.splice(index, 1, dataUpdate);
      return {
        ...state,
        tabs: dataTabsUpdate
      };
    }

    default:
      return state;
  }
};
