import {
    createAddDriverPlan,
    deleteAddDriverPlan,
    getAddDriverPlanByID,
    getAllAddDriverPlan,
    getAllTruckingFeeOfAddTrackingPlan,
    updateAddDriverPlan} from "services/adddriverplan";

// define const actions
export const ADD_DRIVER_PLAN_ACTIONS = {
    GET_ALL_TRACCKING_FEE_OF_ADD_DRIVER_PLAN: "GET_ALL_TRACCKING_FEE_OF_ADD_DRIVER_PLAN",
    GET_ALL_TRACCKING_FEE_OF_ADD_DRIVER_PLAN_PENDING: "GET_ALL_TRACCKING_FEE_OF_ADD_DRIVER_PLAN_PENDING",
    GET_ALL_TRACCKING_FEE_OF_ADD_DRIVER_PLAN_FULFILLED: "GET_ALL_TRACCKING_FEE_OF_ADD_DRIVER_PLAN_FULFILLED",
    GET_ALL_TRACCKING_FEE_OF_ADD_DRIVER_PLAN_REJECTED: "GET_ALL_TRACCKING_FEE_OF_ADD_DRIVER_PLAN_REJECTED",

    GET_ALL_ADD_DRIVER_PLAN: "GET_ALL_ADD_DRIVER_PLAN",
    GET_ALL_ADD_DRIVER_PLAN_PENDING: "GET_ALL_ADD_DRIVER_PLAN_PENDING",
    GET_ALL_ADD_DRIVER_PLAN_FULFILLED: "GET_ALL_ADD_DRIVER_PLAN_FULFILLED",
    GET_ALL_ADD_DRIVER_PLAN_REJECTED: "GET_ALL_ADD_DRIVER_PLAN_REJECTED",

    GET_ADD_DRIVER_PLAN_BY_ID: "GET_ADD_DRIVER_PLAN_BY_ID",
    GET_ADD_DRIVER_PLAN_BY_ID_PENDING: "GET_ADD_DRIVER_PLAN_BY_ID_PENDING",
    GET_ADD_DRIVER_PLAN_BY_ID_FULFILLED: "GET_ADD_DRIVER_PLAN_BY_ID_FULFILLED",
    GET_ADD_DRIVER_PLAN_BY_ID_REJECTED: "GET_ADD_DRIVER_PLAN_BY_ID_REJECTED",

    GET_ADD_DRIVER_PLAN_BY_DRIVER_PLAN_ID: "GET_ADD_DRIVER_PLAN_BY_DRIVER_PLAN_ID",
    GET_ADD_DRIVER_PLAN_BY_DRIVER_PLAN_ID_PENDING: "GET_ADD_DRIVER_PLAN_BY_DRIVER_PLAN_ID_PENDING",
    GET_ADD_DRIVER_PLAN_BY_DRIVER_PLAN_ID_FULFILLED: "GET_ADD_DRIVER_PLAN_BY_DRIVER_PLAN_ID_FULFILLED",
    GET_ADD_DRIVER_PLAN_BY_DRIVER_PLAN_ID_REJECTED: "GET_ADD_DRIVER_PLAN_BY_DRIVER_PLAN_ID_REJECTED",

    DELETE_ADD_DRIVER_PLAN: "DELETE_ADD_DRIVER_PLAN",
    DELETE_ADD_DRIVER_PLAN_PENDING: "DELETE_ADD_DRIVER_PLAN_PENDING",
    DELETE_ADD_DRIVER_PLAN_FULFILLED: "DELETE_ADD_DRIVER_PLAN_FULFILLED",
    DELETE_ADD_DRIVER_PLAN_REJECTED: "DELETE_ADD_DRIVER_PLAN_REJECTED",

    CREATE_ADD_DRIVER_PLAN: "CREATE_ADD_DRIVER_PLAN",
    CREATE_ADD_DRIVER_PLAN_PENDING: "CREATE_ADD_DRIVER_PLAN_PENDING",
    CREATE_ADD_DRIVER_PLAN_FULFILLED: "CREATE_ADD_DRIVER_PLAN_FULFILLED",
    CREATE_ADD_DRIVER_PLAN_REJECTED: "CREATE_ADD_DRIVER_PLAN_REJECTED",

    UPDATE_ADD_DRIVER_PLAN: "UPDATE_ADD_DRIVER_PLAN",
    UPDATE_ADD_DRIVER_PLAN_PENDING: "UPDATE_ADD_DRIVER_PLAN_PENDING",
    UPDATE_ADD_DRIVER_PLAN_FULFILLED: "UPDATE_ADD_DRIVER_PLAN_FULFILLED",
    UPDATE_ADD_DRIVER_PLAN_REJECTED: "UPDATE_ADD_DRIVER_PLAN_REJECTED",

    RESET_TRUCKING_FEES: 'RESET_TRUCKING_FEES'
};

// define actions
export const actionGetAllTrackingFeeOfAddDriverPlan = (
    payload,
    type
) => dispatch => {
    return dispatch({
        type: ADD_DRIVER_PLAN_ACTIONS.GET_ALL_TRACCKING_FEE_OF_ADD_DRIVER_PLAN,
        payload: getAllTruckingFeeOfAddTrackingPlan(payload, type)
    });
};

export const actionGetAllAddDriverPlan = () => dispatch => {
    return dispatch({
        type: ADD_DRIVER_PLAN_ACTIONS.GET_ALL_ADD_DRIVER_PLAN,
        payload: getAllAddDriverPlan()
    });
};

export const actionGetAddDriverPlanByID = id => dispatch => {
    return dispatch({
        type: ADD_DRIVER_PLAN_ACTIONS.GET_ADD_DRIVER_PLAN_BY_ID,
        payload: getAddDriverPlanByID(id)
    });
};

export const actionDeleteAddDriverPlan = (id, truckingPlanID) => dispatch => {
    return dispatch({
        type: ADD_DRIVER_PLAN_ACTIONS.DELETE_ADD_DRIVER_PLAN,
        payload: deleteAddDriverPlan(id),
        meta: {
            truckingPlanID: truckingPlanID
        }
    });
};

export const actionCreateAddDriverPlan = payload => dispatch => {
    let TruckingPlanId = payload.TruckingPlanId;
    return dispatch({
        type: ADD_DRIVER_PLAN_ACTIONS.CREATE_ADD_DRIVER_PLAN,
        payload: createAddDriverPlan(payload),
        meta: {
            truckingPlanID: TruckingPlanId
        }
    });
};

export const actionUpdateAddDriverPlan = payload => dispatch => {
    let TruckingPlanId = payload.TruckingPlanId;
    return dispatch({
        type: ADD_DRIVER_PLAN_ACTIONS.UPDATE_ADD_DRIVER_PLAN,
        payload: updateAddDriverPlan(payload),
        meta: {
            truckingPlanID: TruckingPlanId
        }
    });
};

export const resetTruckingFees = () => dispatch => {
    return dispatch({
        type: ADD_DRIVER_PLAN_ACTIONS.RESET_TRUCKING_FEES
    });
}