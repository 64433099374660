import { ACCOUNTING_LIST_REPAIR_ACTIONS } from 'actions/repair/accountingListRepair';
import { getErrorMessage } from 'commons/ReducerUtils';
import { DEFAULT_PAGE_SIZE } from 'const/common';

const INIT_STATE = {
  listPerPage: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE },
  isSearch: false,
  isCloseModal: false,
  reload: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACCOUNTING_LIST_REPAIR_ACTIONS.GET_ACCOUNTING_LIST_REPAIR_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case ACCOUNTING_LIST_REPAIR_ACTIONS.GET_ACCOUNTING_LIST_REPAIR_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false,
        reload: false
      };
    }
    case ACCOUNTING_LIST_REPAIR_ACTIONS.GET_ACCOUNTING_LIST_REPAIR_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        reload: false
      };
    }

    case ACCOUNTING_LIST_REPAIR_ACTIONS.SEARCH_ACCOUNTING_REPAIRING_ITEMS_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case ACCOUNTING_LIST_REPAIR_ACTIONS.SEARCH_ACCOUNTING_REPAIRING_ITEMS_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false,
        reload: false
      };
    }
    case 'SEARCH_ACCOUNTING_REPAIRING_ITEMS_REJECTED': {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: getErrorMessage(action, 'Không thể lấy được dữ liệu !'),
        isCloseModal: false,
        reload: false
      };
    }

    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }

    case ACCOUNTING_LIST_REPAIR_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        isShowMessage: false
      };
    }
    case ACCOUNTING_LIST_REPAIR_ACTIONS.SEARCH_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination
      };
    }
    case ACCOUNTING_LIST_REPAIR_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case ACCOUNTING_LIST_REPAIR_ACTIONS.UPDATE_STATUS_BEHAFT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case ACCOUNTING_LIST_REPAIR_ACTIONS.UPDATE_STATUS_BEHAFT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Cập nhật trạng thái thành công !',
        isCloseModal: true,
        reload: true
      };
    }
    case ACCOUNTING_LIST_REPAIR_ACTIONS.UPDATE_STATUS_BEHAFT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message:
          Number(action.payload.response.data.status) === 400
            ? action.payload.response.data.message + ''
            : 'Không thể cập nhật trạng thái !',
        isCloseModal: false,
        reload: true
      };
    }

    case ACCOUNTING_LIST_REPAIR_ACTIONS.UPDATE_STATUS_SETTLEMENT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case ACCOUNTING_LIST_REPAIR_ACTIONS.UPDATE_STATUS_SETTLEMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Cập nhật trạng thái thành công !',
        isCloseModal: true,
        reload: true
      };
    }
    case ACCOUNTING_LIST_REPAIR_ACTIONS.UPDATE_STATUS_SETTLEMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể cập nhật được trạng thái !',
        isCloseModal: false,
        reload: true
      };
    }

    case ACCOUNTING_LIST_REPAIR_ACTIONS.CREATE_DATA_ACT_REPAIR_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case ACCOUNTING_LIST_REPAIR_ACTIONS.CREATE_DATA_ACT_REPAIR_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isCloseModal: true,
        reload: true
      };
    }
    case ACCOUNTING_LIST_REPAIR_ACTIONS.CREATE_DATA_ACT_REPAIR_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message:
          Number(action.payload.response.data.status) === 400
            ? action.payload.response.data.message
            : 'Thêm mới dữ liệu thất bại !',
        isCloseModal: false,
        reload: true
      };
    }

    case ACCOUNTING_LIST_REPAIR_ACTIONS.UPDATE_DATA_ACT_REPAIR_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case ACCOUNTING_LIST_REPAIR_ACTIONS.UPDATE_DATA_ACT_REPAIR_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công !',
        isCloseModal: true,
        reload: true
      };
    }
    case ACCOUNTING_LIST_REPAIR_ACTIONS.UPDATE_DATA_ACT_REPAIR_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Cập nhật dữ liệu thất bại !',
        isCloseModal: false,
        reload: false
      };
    }

    default:
      return state;
  }
};
