import httpClient from 'HttpClient';
// define const actions
export const TRANSPORT_COST_ACTIONS = {
  GET_TRANSPORT_COST_PER_PAGE: 'GET_TRANSPORT_COST_PER_PAGE',
  GET_TRANSPORT_COST_PER_PAGE_PENDING: 'GET_TRANSPORT_COST_PER_PAGE_PENDING',
  GET_TRANSPORT_COST_PER_PAGE_FULFILLED: 'GET_TRANSPORT_COST_PER_PAGE_FULFILLED',
  GET_TRANSPORT_COST_PER_PAGE_REJECTED: 'GET_TRANSPORT_COST_PER_PAGE_REJECTED',

  GET_TRANSPORT_COST_BY_ID: 'GET_TRANSPORT_COST_BY_ID',
  GET_TRANSPORT_COST_BY_ID_PENDING: 'GET_TRANSPORT_COST_BY_ID_PENDING',
  GET_TRANSPORT_COST_BY_ID_FULFILLED: 'GET_TRANSPORT_COST_BY_ID_FULFILLED',
  GET_TRANSPORT_COST_BY_ID_REJECTED: 'GET_TRANSPORT_COST_BY_ID_REJECTED',

  CREATE_TRANSPORT_COST: 'CREATE_TRANSPORT_COST',
  CREATE_TRANSPORT_COST_PENDING: 'CREATE_TRANSPORT_COST_PENDING',
  CREATE_TRANSPORT_COST_FULFILLED: 'CREATE_TRANSPORT_COST_FULFILLED',
  CREATE_TRANSPORT_COST_REJECTED: 'CREATE_TRANSPORT_COST_REJECTED',

  UPDATE_TRANSPORT_COST: 'UPDATE_TRANSPORT_COST',
  UPDATE_TRANSPORT_COST_PENDING: 'UPDATE_TRANSPORT_COST_PENDING',
  UPDATE_TRANSPORT_COST_FULFILLED: 'UPDATE_TRANSPORT_COST_FULFILLED',
  UPDATE_TRANSPORT_COST_REJECTED: 'UPDATE_TRANSPORT_COST_REJECTED',

  DELETE_TRANSPORT_COST: 'DELETE_TRANSPORT_COST',
  DELETE_TRANSPORT_COST_PENDING: 'DELETE_TRANSPORT_COST_PENDING',
  DELETE_TRANSPORT_COST_FULFILLED: 'DELETE_TRANSPORT_COST_FULFILLED',
  DELETE_TRANSPORT_COST_REJECTED: 'DELETE_TRANSPORT_COST_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  SEARCH_DATA_HISTORY: 'SEARCH_DATA_HISTORY',
  SEARCH_DATA_HISTORY_PENDING: 'SEARCH_DATA_HISTORY_PENDING',
  SEARCH_DATA_HISTORY_FULFILLED: 'SEARCH_DATA_HISTORY_FULFILLED',
  SEARCH_DATA_HISTORY_REJECTED: 'SEARCH_DATA_HISTORY_REJECTED',

  GET_CUSTOMERS: 'GET_CUSTOMERS',
  GET_CUSTOMERS_PENDING: 'GET_CUSTOMERS_PENDING',
  GET_CUSTOMERS_FULFILLED: 'GET_CUSTOMERS_FULFILLED',
  GET_CUSTOMERS_REJECTED: 'GET_CUSTOMERS_REJECTED',

  GET_ROUTES: 'GET_ROUTES',
  GET_ROUTES_PENDING: 'GET_ROUTES_PENDING',
  GET_ROUTES_FULFILLED: 'GET_ROUTES_FULFILLED',
  GET_ROUTES_REJECTED: 'GET_ROUTES_REJECTED',

  GET_CONT_CATEGORY: 'GET_CONT_CATEGORY',
  GET_CONT_CATEGORY_PENDING: 'GET_CONT_CATEGORY_PENDING',
  GET_CONT_CATEGORY_FULFILLED: 'GET_CONT_CATEGORY_FULFILLED',
  GET_CONT_CATEGORY_REJECTED: 'GET_CONT_CATEGORY_REJECTED',

  CREATE_TRANSPORT_COST_V2: 'CREATE_TRANSPORT_COST_V2',
  CREATE_TRANSPORT_COST_V2_PENDING: 'CREATE_TRANSPORT_COST_V2_PENDING',
  CREATE_TRANSPORT_COST_V2_FULFILLED: 'CREATE_TRANSPORT_COST_V2_FULFILLED',
  CREATE_TRANSPORT_COST_V2_REJECTED: 'CREATE_TRANSPORT_COST_V2_REJECTED',

  TRANSPORT_FEE_PHASE: 'TRANSPORT_FEE_PHASE',
  TRANSPORT_FEE_PHASE_PENDING: 'TRANSPORT_FEE_PHASE_PENDING',
  TRANSPORT_FEE_PHASE_FULFILLED: 'TRANSPORT_FEE_PHASE_FULFILLED',
  TRANSPORT_FEE_PHASE_REJECTED: 'TRANSPORT_FEE_PHASE_REJECTED'
};

// define actions
export const actionGetTransportCostPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_ACTIONS.GET_TRANSPORT_COST_PER_PAGE,
    payload: httpClient.get(`/ContractTransportFeeInfo/managerment/paging/page?Page=${pageNo}&PageSize=${pageSize}`)
  });
};

export const actionGetTransportCostByID = (id) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_ACTIONS.GET_TRANSPORT_COST_BY_ID,
    payload: httpClient.get(`/ContractTransportFeeInfo/getById/${id}`)
  });
};

export const actionCreateTransportCost = (payload) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_ACTIONS.CREATE_TRANSPORT_COST,
    payload: httpClient.post(`/ContractTransportFeeInfo/create`, payload)
  });
};

export const actionUpdateTransportCost = (payload) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_ACTIONS.UPDATE_TRANSPORT_COST,
    payload: httpClient.put(`/ContractTransportFeeInfo/update`, payload)
  });
};

export const actionDeleteTransportCost = (id) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_ACTIONS.DELETE_TRANSPORT_COST,
    payload: httpClient.delete(`/ContractTransportFeeInfo/deleteById/${id}`)
  });
};

export const actionSearchTransportCost = (params) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/ContractTransportFeeInfo/managerment/paging/page`, {
      params
    })
  });
};

export const actionSearchTransportCostHistory = (params) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_ACTIONS.SEARCH_DATA_HISTORY,
    payload: httpClient.get(`/ContractTransportFeeInfo/managerment/paging/page`, {
      params
    })
  });
};

export const actionSearchTransportCostExcel = (params) => (dispatch) => {
  return dispatch({
    payload: httpClient.get(`/ContractTransportFeeInfo/managerment/paging/page`, {
      params
    })
  });
};

export const actionGetRoutes = () => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_ACTIONS.GET_ROUTES,
    payload: httpClient.get(`/routeinfo/getAll`)
  });
};

export const actionGetCustomers = () => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_ACTIONS.GET_CUSTOMERS,
    payload: httpClient.get(`/customerinfo/getAll`)
  });
};

export const actionGetContCategory = () => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_ACTIONS.GET_CONT_CATEGORY,
    payload: httpClient.get(`/Container/getAll`)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

//update import excel
export const actionCreateTransportCostV2 = (payload) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_ACTIONS.CREATE_TRANSPORT_COST_V2,
    payload: httpClient.post(`/ContractTransportFeeInfo/createV2`, payload)
  });
};

export const getTransportFeePhase = (payload) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_ACTIONS.TRANSPORT_FEE_PHASE,
    payload: httpClient.get('TransportFeePhase/search', { params: payload }),
    meta: payload
  });
};
