import axios from 'axios';
import httpClient from 'HttpClient';

// const auth = { Username: 'ttsonline', Password: 'qlvttoiuu' };
const Username = 'ttsonline';
const Password = 'qlvttoiuu';
const token = Buffer.from(`${Username}:${Password}`, 'utf8').toString('base64');

export const MAP_ACTIONS = {
  GET_CARS_LOCATION: 'GET_CARS_LOCATION',
  GET_CARS_LOCATION_PENDING: 'GET_CARS_LOCATION_PENDING',
  GET_CARS_LOCATION_FULFILLED: 'GET_CARS_LOCATION_FULFILLED',
  GET_CARS_LOCATION_REJECTED: 'GET_CARS_LOCATION_REJECTED',

  GET_CARS_INFO: 'GET_CARS_INFO',
  GET_CARS_INFO_PENDING: 'GET_CARS_INFO_PENDING',
  GET_CARS_INFO_FULFILLED: 'GET_CARS_INFO_FULFILLED',
  GET_CARS_INFO_REJECTED: 'GET_CARS_INFO_REJECTED'
};

export const actionsGetCarsLocation = (listCars) => (dispatch) => {
  return dispatch({
    type: MAP_ACTIONS.GET_CARS_LOCATION,
    payload: axios.get(`https://data.dvbk.vn/api/QLVT?numberplates=${listCars}`, {
      headers: {
        Authorization: `Basic ${token}`
      }
    })
  });
};

export const actionsGetCarsInfo = (customerId, tractorTrailerId) => (dispatch) => {
  return dispatch({
    type: MAP_ACTIONS.GET_CARS_INFO,
    payload: httpClient.get(
      `/tractortrailerinfo/getTractorTrailerList?customerId=${customerId}&tractorTrailerId=${tractorTrailerId}`
    )
  });
};
