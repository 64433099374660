import api from 'HttpClient';

export const SET_OPEN_CUSTOMER_PERMISSION_TYPE_MODAL = 'SET_OPEN_CUSTOMER_PERMISSION_TYPE_MODAL';
export const setOpenCustomerPermissionTypeModal = (data) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_CUSTOMER_PERMISSION_TYPE_MODAL,
    payload: data
  });
};

export const SET_CLOSE_CUSTOMER_PERMISSION_TYPE_MODAL = 'SET_CLOSE_CUSTOMER_PERMISSION_TYPE_MODAL';
export const setCloseCustomerPermissionTypeModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_CUSTOMER_PERMISSION_TYPE_MODAL
  });
};

export const FETCH_ALL_CUSTOMER_PERMISSION_TYPES = 'FETCH_ALL_CUSTOMER_PERMISSION_TYPES';
export const FETCH_ALL_CUSTOMER_PERMISSION_TYPES_PENDING = 'FETCH_ALL_CUSTOMER_PERMISSION_TYPES_PENDING';
export const FETCH_ALL_CUSTOMER_PERMISSION_TYPES_FULFILLED = 'FETCH_ALL_CUSTOMER_PERMISSION_TYPES_FULFILLED';
export const FETCH_ALL_CUSTOMER_PERMISSION_TYPES_REJECTED = 'FETCH_ALL_CUSTOMER_PERMISSION_TYPES_REJECTED';
export const fetchAllCustomerPermissionTypes = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_CUSTOMER_PERMISSION_TYPES,
    payload: api.get(`/CustomerInfo/getAllCustomerGroupInfo`)
  });
};

// const type of function get data per page
export const FETCH_CUSTOMER_PERMISSION_TYPES = 'FETCH_CUSTOMER_PERMISSION_TYPES';
export const FETCH_CUSTOMER_PERMISSION_TYPES_PENDING = 'FETCH_CUSTOMER_PERMISSION_TYPES_PENDING';
export const FETCH_CUSTOMER_PERMISSION_TYPES_FULFILLED = 'FETCH_CUSTOMER_PERMISSION_TYPES_FULFILLED';
export const FETCH_CUSTOMER_PERMISSION_TYPES_REJECTED = 'FETCH_CUSTOMER_PERMISSION_TYPES_REJECTED';
export const fetchCustomerPermissionTypes = (searchPayload, page, pageSize) => (dispatch) => {
  return dispatch({
    type: FETCH_CUSTOMER_PERMISSION_TYPES,
    payload: api.get(`/CustomerGroupInfo/paging/page`, { params: { ...searchPayload, page, pageSize: pageSize } })
  });
};
export const fetchCustomerPermissionTypesAll = (params) => (dispatch) => {
  return dispatch({
    // type: FETCH_CUSTOMER_PERMISSION_TYPES,
    payload: api.get(`/CustomerGroupInfo/paging/page`, { params })
  });
};

// const type of function create data
export const CREATE_CUSTOMER_PERMISSION_TYPE = 'CREATE_CUSTOMER_PERMISSION_TYPE';
export const CREATE_CUSTOMER_PERMISSION_TYPE_PENDING = 'CREATE_CUSTOMER_PERMISSION_TYPE_PENDING';
export const CREATE_CUSTOMER_PERMISSION_TYPE_FULFILLED = 'CREATE_CUSTOMER_PERMISSION_TYPE_FULFILLED';
export const CREATE_CUSTOMER_PERMISSION_TYPE_REJECTED = 'CREATE_CUSTOMER_PERMISSION_TYPE_REJECTED';
export const createCustomerPermissionType = (payload) => (dispatch) => {
  return dispatch({
    type: CREATE_CUSTOMER_PERMISSION_TYPE,
    payload: api.post(`/CustomerGroupInfo/create`, payload)
  });
};

// const type of function update data
export const UPDATE_CUSTOMER_PERMISSION_TYPE = 'UPDATE_CUSTOMER_PERMISSION_TYPE';
export const UPDATE_CUSTOMER_PERMISSION_TYPE_PENDING = 'UPDATE_CUSTOMER_PERMISSION_TYPE_PENDING';
export const UPDATE_CUSTOMER_PERMISSION_TYPE_FULFILLED = 'UPDATE_CUSTOMER_PERMISSION_TYPE_FULFILLED';
export const UPDATE_CUSTOMER_PERMISSION_TYPE_REJECTED = 'UPDATE_CUSTOMER_PERMISSION_TYPE_REJECTED';
export const updateCustomerPermissionType = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_CUSTOMER_PERMISSION_TYPE,
    payload: api.put(`/CustomerGroupInfo/update`, payload),
    meta: payload
  });
};
// const type of get deatail
export const FETCH_CUSTOMER_PERMISSION_TYPE_DETAIL = 'FETCH_CUSTOMER_PERMISSION_TYPE_DETAIL';
export const FETCH_CUSTOMER_PERMISSION_TYPE_DETAIL_PENDING = 'FETCH_CUSTOMER_PERMISSION_TYPE_DETAIL_PENDING';
export const FETCH_CUSTOMER_PERMISSION_TYPE_DETAIL_FULFILLED = 'FETCH_CUSTOMER_PERMISSION_TYPE_DETAIL_FULFILLED';
export const FETCH_CUSTOMER_PERMISSION_TYPE_DETAIL_REJECTED = 'FETCH_CUSTOMER_PERMISSION_TYPE_DETAIL_REJECTED';
export const getCustomerPermissionTypeDetail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_CUSTOMER_PERMISSION_TYPE_DETAIL,
    payload: api.get(`/CustomerGroupInfo/getById/${id}`)
  });
};

// const type of delete
export const DELETE_CUSTOMER_PERMISSION_TYPE = 'DELETE_CUSTOMER_PERMISSION_TYPE';
export const DELETE_CUSTOMER_PERMISSION_TYPE_PENDING = 'DELETE_CUSTOMER_PERMISSION_TYPE_PENDING';
export const DELETE_CUSTOMER_PERMISSION_TYPE_FULFILLED = 'DELETE_CUSTOMER_PERMISSION_TYPE_FULFILLED';
export const DELETE_CUSTOMER_PERMISSION_TYPE_REJECTED = 'DELETE_CUSTOMER_PERMISSION_TYPE_REJECTED';
export const deleteCustomerPermissionType = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_CUSTOMER_PERMISSION_TYPE,
    payload: api.delete(`/CustomerGroupInfo/delete/${id}`)
  });
};
