import api from 'HttpClient';

export const SET_OPEN_TRANSPORT_COST_NEW_MODAL = 'SET_OPEN_TRANSPORT_COST_NEW_MODAL';
export const setOpenTransportCostNewModal = (data, type) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_TRANSPORT_COST_NEW_MODAL,
    payload: { data, type }
  });
};

export const SET_CLOSE_TRANSPORT_COST_NEW_MODAL = 'SET_CLOSE_TRANSPORT_COST_NEW_MODAL';
export const setCloseTransportCostNewModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_TRANSPORT_COST_NEW_MODAL
  });
};

export const FETCH_ALL_TRANSPORT_COST_NEWS = 'FETCH_ALL_TRANSPORT_COST_NEWS';
export const FETCH_ALL_TRANSPORT_COST_NEWS_PENDING = 'FETCH_ALL_TRANSPORT_COST_NEWS_PENDING';
export const FETCH_ALL_TRANSPORT_COST_NEWS_FULFILLED = 'FETCH_ALL_TRANSPORT_COST_NEWS_FULFILLED';
export const FETCH_ALL_TRANSPORT_COST_NEWS_REJECTED = 'FETCH_ALL_TRANSPORT_COST_NEWS_REJECTED';
export const fetchAllTransportCostNews = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_TRANSPORT_COST_NEWS,
    payload: api.get(`/Standard/CalculateTransportFeeV2`)
  });
};

// const type of function get data per page
export const FETCH_TRANSPORT_COST_NEWS = 'FETCH_TRANSPORT_COST_NEWS';
export const FETCH_TRANSPORT_COST_NEWS_PENDING = 'FETCH_TRANSPORT_COST_NEWS_PENDING';
export const FETCH_TRANSPORT_COST_NEWS_FULFILLED = 'FETCH_TRANSPORT_COST_NEWS_FULFILLED';
export const FETCH_TRANSPORT_COST_NEWS_REJECTED = 'FETCH_TRANSPORT_COST_NEWS_REJECTED';
export const fetchTransportCostNews = (params, PageIndex, PageSize) => (dispatch) => {
  return dispatch({
    type: FETCH_TRANSPORT_COST_NEWS,
    payload: api.get(`/StandardTransportFee/Search`, { params: { ...params, Page: PageIndex, PageSize } })
  });
};

// const type of function create data
export const CREATE_TRANSPORT_COST_NEW = 'CREATE_TRANSPORT_COST_NEW';
export const CREATE_TRANSPORT_COST_NEW_PENDING = 'CREATE_TRANSPORT_COST_NEW_PENDING';
export const CREATE_TRANSPORT_COST_NEW_FULFILLED = 'CREATE_TRANSPORT_COST_NEW_FULFILLED';
export const CREATE_TRANSPORT_COST_NEW_REJECTED = 'CREATE_TRANSPORT_COST_NEW_REJECTED';
export const createTransportCostNew = (payload) => (dispatch) => {
  return dispatch({
    type: CREATE_TRANSPORT_COST_NEW,
    payload: api.post(`/StandardTransportFee/createmany`, payload)
  });
};

// const type of function update data
export const UPDATE_TRANSPORT_COST_NEW = 'UPDATE_TRANSPORT_COST_NEW';
export const UPDATE_TRANSPORT_COST_NEW_PENDING = 'UPDATE_TRANSPORT_COST_NEW_PENDING';
export const UPDATE_TRANSPORT_COST_NEW_FULFILLED = 'UPDATE_TRANSPORT_COST_NEW_FULFILLED';
export const UPDATE_TRANSPORT_COST_NEW_REJECTED = 'UPDATE_TRANSPORT_COST_NEW_REJECTED';
export const updateTransportCostNew = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_TRANSPORT_COST_NEW,
    payload: api.put(`/StandardTransportFee/update`, payload),
    meta: payload
  });
};
// const type of get deatail
export const FETCH_TRANSPORT_COST_NEW_DETAIL = 'FETCH_TRANSPORT_COST_NEW_DETAIL';
export const FETCH_TRANSPORT_COST_NEW_DETAIL_PENDING = 'FETCH_TRANSPORT_COST_NEW_DETAIL_PENDING';
export const FETCH_TRANSPORT_COST_NEW_DETAIL_FULFILLED = 'FETCH_TRANSPORT_COST_NEW_DETAIL_FULFILLED';
export const FETCH_TRANSPORT_COST_NEW_DETAIL_REJECTED = 'FETCH_TRANSPORT_COST_NEW_DETAIL_REJECTED';
export const getTransportCostNewDetail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_TRANSPORT_COST_NEW_DETAIL,
    payload: api.get(`/StandardTransportFee/${id}`)
  });
};

// const type of delete
export const DELETE_TRANSPORT_COST_NEW = 'DELETE_TRANSPORT_COST_NEW';
export const DELETE_TRANSPORT_COST_NEW_PENDING = 'DELETE_TRANSPORT_COST_NEW_PENDING';
export const DELETE_TRANSPORT_COST_NEW_FULFILLED = 'DELETE_TRANSPORT_COST_NEW_FULFILLED';
export const DELETE_TRANSPORT_COST_NEW_REJECTED = 'DELETE_TRANSPORT_COST_NEW_REJECTED';
export const deleteTransportCostNew = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_TRANSPORT_COST_NEW,
    payload: api.delete(`/StandardTransportFee/delete/${id}`)
  });
};

// const type of delete
export const FETCH_NORM_TRANSPORT_COST_NEW = 'FETCH_NORM_TRANSPORT_COST_NEW';
export const FETCH_NORM_TRANSPORT_COST_NEW_PENDING = 'FETCH_NORM_TRANSPORT_COST_NEW_PENDING';
export const FETCH_NORM_TRANSPORT_COST_NEW_FULFILLED = 'FETCH_NORM_TRANSPORT_COST_NEW_FULFILLED';
export const FETCH_NORM_TRANSPORT_COST_NEW_REJECTED = 'FETCH_NORM_TRANSPORT_COST_NEW_REJECTED';
export const fetchNormTransportCostNew = (params) => (dispatch) => {
  const payload = {
    FormulaName: 'linh tinh cũng dc',
    FormulaOrValue: 'linh tinh',
    Variables: []
  };
  Object.keys(params).forEach((VariableName, index) => {
    const Variable = {
      VariableName : VariableName,
      FormulaOrValue : params[VariableName]
    }
    payload.Variables.push(Variable)
  });
  // console.log('payload', payload);
  return dispatch({
    type: FETCH_NORM_TRANSPORT_COST_NEW,
    payload: api.post(`/Standard/CalculateTransportFeeV2`, payload)
  });
};

export const FETCH_CALCULATING_TYPE = 'FETCH_CALCULATING_TYPE';
export const FETCH_CALCULATING_TYPE_PENDING = 'FETCH_CALCULATING_TYPE_PENDING';
export const FETCH_CALCULATING_TYPE_FULFILLED = 'FETCH_CALCULATING_TYPE_FULFILLED';
export const FETCH_CALCULATING_TYPE_REJECTED = 'FETCH_CALCULATING_TYPE_REJECTED';

export const fetchCalculatingType = () => (dispatch) => {
  return dispatch({
    type: FETCH_CALCULATING_TYPE,
    payload: api.get(`/CalculatingType/getAll`)
  });
};