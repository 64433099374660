import {
  CREATE_USER_FULFILLED,
  CREATE_USER_PENDING,
  CREATE_USER_REJECTED,
  DELETE_USER_FULFILLED,
  DELETE_USER_PENDING,
  DELETE_USER_REJECTED,
  FETCH_USERS_FULFILLED,
  FETCH_USERS_PENDING,
  FETCH_USERS_REJECTED,
  GET_ALL_USER_FULFILLED,
  GET_ALL_USER_PENDING,
  GET_ALL_USER_REJECTED,
  GET_DETAIL_USER_FULFILLED,
  GET_DETAIL_USER_PENDING,
  GET_DETAIL_USER_REJECTED,
  SEARCH_USER_FULFILLED,
  SEARCH_USER_PENDING,
  SEARCH_USER_REJECTED,
  SEARCH_USERNAME_FULFILLED,
  SEARCH_USERNAME_PENDING,
  SEARCH_USERNAME_REJECTED,
  UPDATE_USER_FULFILLED,
  UPDATE_USER_PENDING,
  UPDATE_USER_REJECTED
} from 'actions/UserManagementAction';
import {
  DEFAULT_PAGE_SIZE,
  MSG_ADD_FAILURE,
  MSG_ADD_SUCCESS,
  MSG_DELETE_FAILURE,
  MSG_DELETE_SUCCESS,
  MSG_EDIT_FAILURE,
  MSG_EDIT_SUCCESS,
  MSG_GET_DETAIL_FAILURE,
  MSG_PAGING_FAILURE,
  MSG_SEARCH_FAILURE,
  MSG_TYPE_ERROR,
  MSG_TYPE_SUCCESS
} from 'const/common';

const INIT_STATE = {
  users: [],
  usersDetail: {},
  pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // get data
    case FETCH_USERS_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case FETCH_USERS_FULFILLED: {
      return {
        ...state,
        loading: false,
        users: action.payload.data.data,
        pagination: action.payload.data.pagination ? action.payload.data.pagination : INIT_STATE.pagination,
        showMessage: false
      };
    }
    case FETCH_USERS_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }

    case SEARCH_USER_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false,
        hasReceiptedSearchResult: false
      };
    }
    case SEARCH_USER_FULFILLED: {
      return {
        ...state,
        loading: false,
        users: action.payload.data.data,
        pagination: action.payload.data.pagination ? action.payload.data.pagination : INIT_STATE.pagination,
        showMessage: false,
        hasReceiptedSearchResult: true
      };
    }
    case SEARCH_USER_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: MSG_SEARCH_FAILURE,
        messageType: MSG_TYPE_ERROR,
        hasReceiptedSearchResult: true
      };
    }

    // create data
    case CREATE_USER_PENDING:
      return {
        ...state,
        loading: true
      };
    case CREATE_USER_FULFILLED:
      return {
        ...state,
        isCreate: true,
        loading: false,
        showMessage: true,
        alertMessage: MSG_ADD_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case CREATE_USER_REJECTED:
      let alertMessage = MSG_ADD_FAILURE;
      if (action.payload.response.data.status === 400) {
        alertMessage = action.payload.response.data.message;
      }
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };

    // update data
    case UPDATE_USER_PENDING:
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    case UPDATE_USER_FULFILLED:
      return {
        ...state,
        isUpdate: true,
        loading: false,
        showMessage: true,
        alertMessage: MSG_EDIT_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case UPDATE_USER_REJECTED:
      alertMessage = MSG_EDIT_FAILURE;
      if (action.payload.response.data.status === 400) {
        alertMessage = action.payload.response.data.message;
      }
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };

    // get detail
    case GET_DETAIL_USER_PENDING:
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    case GET_DETAIL_USER_FULFILLED:
      return {
        ...state,
        usersDetail: action.payload.data,
        loading: false,
        showMessage: false
      };
    case GET_DETAIL_USER_REJECTED:
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: MSG_GET_DETAIL_FAILURE,
        messageType: MSG_TYPE_ERROR
      };

    // delete data
    case DELETE_USER_PENDING:
      return {
        ...state,
        loading: true
      };
    case DELETE_USER_FULFILLED:
      return {
        ...state,
        isDelete: true,
        loading: false,
        showMessage: true,
        alertMessage: MSG_DELETE_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case DELETE_USER_REJECTED:
      alertMessage = MSG_DELETE_FAILURE;
      if (action.payload.response.data.status === 400) {
        alertMessage = action.payload.response.data.message;
      }
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };

    //getall
    case GET_ALL_USER_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case GET_ALL_USER_FULFILLED: {
      return {
        ...state,
        loading: false,
        users: action.payload.data.data,
        showMessage: false
      };
    }
    case GET_ALL_USER_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    case SEARCH_USERNAME_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case SEARCH_USERNAME_FULFILLED: {
      return {
        ...state,
        loading: false,
        users: action.payload.data.data,
        pagination: action.payload.data.pagination,
        showMessage: false
      };
    }
    case SEARCH_USERNAME_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    default:
      return state;
  }
};
