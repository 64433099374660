export default  (state = {}, action) => {
  switch (action.type) {
    case 'change_permission':
     return {
        ...state,
        data: action.payload,
      };
    default:
      return {...state};
  }
};

