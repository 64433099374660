import httpClient from "HttpClient";
// define const actions
export const UNIT_PAYMEMT_ACTIONS = {
  GET_ALL_UNIT_PAYMENT: "GET_ALL_UNIT_PAYMENT",
  GET_ALL_UNIT_PAYMENT_PENDING: "GET_ALL_UNIT_PAYMENT_PENDING",
  GET_ALL_UNIT_PAYMENT_FULFILLED: "GET_ALL_UNIT_PAYMENT_FULFILLED",
  GET_ALL_UNIT_PAYMENT_REJECTED: "GET_ALL_UNIT_PAYMENT_REJECTED",
};

// define actions

export const getAllUnitPayment = () => (dispatch) => {
  return dispatch({
    type: UNIT_PAYMEMT_ACTIONS.GET_ALL_UNIT_PAYMENT,
    payload: httpClient.get(`Unit/getAll/payment`),
  });
};
