import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { fillUpRecord, getRecordDepth } from 'commons/exportUtils';
import { convertToNumber } from 'commons/formatedNumber';
import { formatNumberToReadableString, normalizeInputToReadableString } from 'commons/MathUtils';
import { isNull, renderHeaderReportPrint, retrieveFormValues } from 'commons/utils';
import { maxLength } from 'commons/ValidateType';
import { dataImg } from 'file/logoImage';
import moment from 'moment';
import { Field, reduxForm } from 'redux-form';
import renderField from 'views/CustomInput/ValidateField';
import renderCheckboxField from 'views/FormComponent/renderCheckboxField';

import { styleExportFile } from './columRenderFile';

// const NotVAT = 1;
// const VAT = 2;
const max15 = maxLength(15);
class PrintDriverPlan extends React.PureComponent {
  onChangeTypeAndPrint = (typePrint) => {
    this.props.change('typePrint', typePrint);
    const formData = { ...this.props.formData, typePrint };
    this.props.onHandleSubmit(formData);
  };
  onChangeTypePrint = (e) => {
    this.props.change('typePrint', e.target.checked);
    if (e.target.checked) {
      const OilNormMore = this.props.initialValues.OilNormMore || 0.21;
      const ChargeNorm = this.props.initialValues.ChargeNorm || '300,000';
      this.props.change('OilNormMore', OilNormMore);
      this.props.change('ChargeNorm', ChargeNorm);
    } else {
      this.props.change('OilNormMore', null);
      this.props.change('ChargeNorm', null);
    }
  };
  render() {
    const { submitting, pristine, invalid, onHandleSubmit, formData } = this.props;
    return (
      <div>
        <CardBody>
          <Form onSubmit={this.props.handleSubmit(onHandleSubmit)}>
            <FormGroup>
              <FormGroup>
                <Field
                  name="UsingVAT"
                  type="checkbox"
                  component={renderCheckboxField}
                  label="Khách hàng lấy hóa đơn GTGT"
                  idName="IsVAT"
                  // checked={this.props.onChecked()}
                  onChange={(e) => this.onChangeTypePrint(e)}
                />
              </FormGroup>
            </FormGroup>
            {formData.UsingVAT && (
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Định mức dầu bổ sung</Label>
                    <Field
                      type="text"
                      validate={[max15]}
                      component={renderField}
                      name="OilNormMore"
                      normalize={normalizeInputToReadableString}
                      disabled={!formData.typePrint}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Định mức phí</Label>
                    <Field
                      type="text"
                      validate={[max15]}
                      component={renderField}
                      name="ChargeNorm"
                      normalize={normalizeInputToReadableString}
                      disabled={!formData.typePrint}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            <div className="float-right">
              <Button
                className="mt-2"
                color="info"
                onClick={() => this.onChangeTypeAndPrint(1)}
                disabled={invalid || submitting}
              >
                <i className="fa fa-print" aria-hidden="true">
                  {' '}
                </i>
                &nbsp; In chưa VAT
              </Button>
              {formData.UsingVAT && (
                <>
                  <Button
                    className="mt-2"
                    color="info"
                    onClick={() => this.onChangeTypeAndPrint(2)}
                    disabled={submitting || invalid || !formData.UsingVAT}
                  >
                    <i className="fa fa-print" aria-hidden="true">
                      {' '}
                    </i>
                    &nbsp; In có VAT
                  </Button>
                  <Button
                    className="mt-2"
                    color="success"
                    onClick={() => this.onChangeTypeAndPrint(0)}
                    disabled={submitting || invalid || pristine}
                  >
                    <i className="fa fa-print" aria-hidden="true">
                      {' '}
                    </i>
                    &nbsp; Lưu
                  </Button>
                </>
              )}
              <Button className="mt-2" color="danger" onClick={() => this.props.handleCloseModal()}>
                <i className="fa fa-times"> </i>&nbsp; Đóng
              </Button>
            </div>
          </Form>
        </CardBody>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const defaultValue = props.defaultValue;
  return {
    initialValues: {
      typePrint: 0,
      OilNormMore: defaultValue.Consignment?.OilNormMore || (defaultValue.Consignment?.UsingVAT ? 0.21 : null),
      ChargeNorm: defaultValue.Consignment?.ChargeNorm || (defaultValue.Consignment?.UsingVAT ? '300,000' : null),
      UsingVAT: defaultValue.Consignment?.UsingVAT,
      Id: defaultValue.ConsignmentId
    },
    formData: retrieveFormValues(state, 'PrintDriverPlan')
  };
};
export default connect(
  mapStateToProps
  // mapDispatchToProps
)(
  reduxForm({
    form: 'PrintDriverPlan'
  })(PrintDriverPlan)
);

export const LIMIT_DATE_GET_DATA_SCHEDULER = 7;
export const calculatorOilByEditForm = (payload) => {
  let result = 0;
  try {
    let distance = payload.distance === null || payload.distance === undefined ? 0 : parseInt(payload.distance);
    let returnDistance =
      payload.returnDistance === null || payload.returnDistance === undefined ? 0 : parseInt(payload.returnDistance);
    let loadStandardOil =
      payload.loadStandardOil === null || payload.loadStandardOil === undefined
        ? 0
        : parseFloat(payload.loadStandardOil);
    let nonLoadStandardOil =
      payload.nonLoadStandardOil === null || payload.nonLoadStandardOil === undefined
        ? 0
        : parseFloat(payload.nonLoadStandardOil);
    let oilPrice = payload.oilPrice === null || payload.oilPrice === undefined ? 0 : convertToNumber(payload.oilPrice);

    if (payload.isTwoWayLoad === null || payload.isTwoWayLoad === undefined || !payload.isTwoWayLoad) {
      result = oilPrice * (distance * loadStandardOil + returnDistance * nonLoadStandardOil);
      return result;
    }

    result = loadStandardOil * oilPrice * (distance + returnDistance);
    return result;
  } catch (err) {
    return result;
  }
};

export const calculatorOilByAddLocationForm = (payload) => {
  let result = 0;
  try {
    let distance = payload.distance === null || payload.distance === undefined ? 0 : parseInt(payload.distance);
    let loadStandardOil =
      payload.loadStandardOil === null || payload.loadStandardOil === undefined
        ? 0
        : parseFloat(payload.loadStandardOil);
    let nonLoadStandardOil =
      payload.nonLoadStandardOil === null || payload.nonLoadStandardOil === undefined
        ? 0
        : parseFloat(payload.nonLoadStandardOil);
    let oilPrice = payload.oilPrice === null || payload.oilPrice === undefined ? 0 : convertToNumber(payload.oilPrice);
    if (payload.isLoad === null || payload.isLoad === undefined || !payload.isLoad) {
      result = distance * nonLoadStandardOil * oilPrice;
      return result;
    }
    result = distance * loadStandardOil * oilPrice;
    return result;
  } catch (err) {
    return result;
  }
};
const alignLeftCellTable = {
  colSpan: 1,
  alignment: 'left',
  width: { wpx: 150 },
  rowSpan: 1,
  _margin: null,
  _maxWidth: 150,
  _minWidth: 150
};
const alignCenterCellTable = {
  colSpan: 3,
  alignment: 'center',
  width: { wpx: 150 },
  rowSpan: 1,
  _margin: null,
  _maxWidth: 150,
  _minWidth: 150
};
const alignRightCellTable = {
  colSpan: 1,
  alignment: 'right',
  width: { wpx: 150 },
  rowSpan: 1,
  _margin: null,
  _maxWidth: 150,
  _minWidth: 150
};
const alignLeftCellTableRowSpan = (rowSpan) => {
  return {
    colSpan: 1,
    alignment: 'left',
    width: { wpx: 150 },
    rowSpan: rowSpan,
    _margin: null,
    _maxWidth: 150,
    _minWidth: 150
  };
};
const alignRightCellTableRowSpan = (rowSpan) => {
  return {
    colSpan: 1,
    alignment: 'right',
    width: { wpx: 150 },
    rowSpan: rowSpan,
    _margin: null,
    _maxWidth: 150,
    _minWidth: 150
  };
};
const date = new Date();
const TYPE_NOT_VAT = 1;
const TYPE_INCLUDE_VAT = 2;
export const createDocDefinitionDriverPlan = (record, dataItems, moocs, payload, dataDetail, dataRoute) => {
  const ChargeNorm = convertToNumber(payload?.ChargeNorm) || 0;
  const Date = date.getDate();
  const Month = date.getMonth() + 1;
  const Year = date.getFullYear();
  const contNo =
    record.ContSerialNo1 && record.ContSerialNo2
      ? `${record.ContSerialNo1.ContSerialNo}\n${record.ContSerialNo2.ContSerialNo}`
      : record.ContSerialNo1?.ContSerialNo || record.ContSerialNo2?.ContSerialNo || '';
  const contType =
    record.ContSerialNo1 && record.ContSerialNo2
      ? `${record.ContSerialNo1.ContCategory?.Abbr}\n${record.ContSerialNo2.ContCategory?.Abbr}`
      : record.ContSerialNo1?.ContCategory?.Abbr || record.ContSerialNo2?.ContCategory?.Abbr || '';
  const Route = record.Routes[0].StartingPoint?.Address + ' - ' + record.Routes[0].Destination?.Address;
  const SemiTrailer = record.SemiTrailerId ? moocs.find((item) => item.Id === record.SemiTrailerId) : null;
  const AdditionalOil = (record.Routes || []).reduce((sum, item) => sum + item.AdditionalOil, 0);
  const Driver = record.Driver ? `${record.Driver?.FirstName} ${record.Driver?.LastName}` : '';
  const TractorTrailer = record.TractorTrailer
    ? `${record.TractorTrailer?.PrePlateNo}-${record.TractorTrailer?.PlateNo}`
    : '';
  const { typePrint = 0 } = payload;
  //start ticket amount
  const dataTicket = getDataTicket(dataRoute, record.TicketFee);
  const TravelAllowanceNotIncludeTicketAndOtherFee = record.LawFee + record.MealAllowance;

  const TotalMoney =
    (typePrint === TYPE_NOT_VAT ? TravelAllowanceNotIncludeTicketAndOtherFee : ChargeNorm) +
    record.TyreFee +
    record.TicketFee;
  // calculate  residualOil
  const oilCalculate = dataDetail
    ? dataDetail?.TotalStandardOil - (dataDetail?.PouredOil + dataDetail?.OilRemain)
    : null;
  const residualOil = oilCalculate !== null ? Math.round(oilCalculate * 10) / 10 : '';
  // contYard
  const ContYard = dataDetail?.Consignment?.ContYard?.Address;

  const AmountPaid = payload?.UsingVAT ? record.TicketFee + ChargeNorm + record.TyreFee : 0;
  const AmountPaidTable = [
    {
      text: 'Tổng đã chi từ lệnh 1',
      ...alignLeftCellTable
    },
    {
      text: AmountPaid ? formatNumberToReadableString(AmountPaid) + 'VNĐ' : '',
      ...alignRightCellTable
    }
  ];

  const RemainingAmountToBePaid = TotalMoney - AmountPaid;
  const RemainingAmountToBePaidTable = [
    {
      text: 'Còn phải chi',
      ...alignLeftCellTable
    },
    {
      text: RemainingAmountToBePaid ? formatNumberToReadableString(RemainingAmountToBePaid) + 'VNĐ' : '',
      ...alignRightCellTable
    }
  ];
  const ChargeNormTable = [
    {
      text: 'Phí định mức',
      ...alignLeftCellTable
    },
    {
      text: payload?.ChargeNorm ? formatNumberToReadableString(payload?.ChargeNorm) + 'VNĐ' : '',
      ...alignRightCellTable
    }
  ];
  const DistanceTotal = (record.LoadDistance || 0) + (record.NonLoadDistance || 0);

  const ChargeNotIncludeVATUsingVAT =
    typePrint === TYPE_INCLUDE_VAT ? null : [...RemainingAmountToBePaidTable, ...AmountPaidTable];
  const chargeTravel = [
    {
      text: 'Phí đi đường',
      ...alignLeftCellTable
    },
    {
      text: TravelAllowanceNotIncludeTicketAndOtherFee
        ? formatNumberToReadableString(TravelAllowanceNotIncludeTicketAndOtherFee) + 'VNĐ'
        : '',
      ...alignRightCellTable
    }
  ];
  const ChargeNormOrTravelCharge = typePrint === TYPE_INCLUDE_VAT ? ChargeNormTable : chargeTravel;
  const TotalMoneyTable = [
    {
      text: 'Tổng chi phí',
      ...alignLeftCellTable
    },
    {
      text: formatNumberToReadableString(TotalMoney) + 'VNĐ',
      ...alignCenterCellTable
    }
  ];
  const GroupCharge = ChargeNotIncludeVATUsingVAT ? [ChargeNotIncludeVATUsingVAT, TotalMoneyTable] : [TotalMoneyTable];
  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    content: [
      renderHeaderReportPrint(dataItems, dataImg),
      { text: 'LỆNH ĐIỀU XE ', style: 'header' },
      // ...subheader,
      {
        text: `Ngày ${Date} tháng ${Month} năm ${Year}`,
        alignment: 'center',
        margin: [0, 0, 0, 5]
      },
      {
        style: 'tableExample',
        table: {
          widths: ['25%', '25%', '25%', '25%'],
          body: [
            [
              {
                text: 'Tên lái xe',
                ...alignLeftCellTable
              },
              {
                text: Driver,
                ...alignLeftCellTable
              },
              {
                text: 'Giấy phép lái xe',
                ...alignLeftCellTable
              },
              {
                text: `${record.Driver?.DrivingLicenseNo ?? ''}`,
                ...alignRightCellTable
              }
            ],
            [
              {
                text: 'Số xe',
                ...alignLeftCellTable
              },
              {
                text: TractorTrailer,
                ...alignLeftCellTable
              },
              {
                text: 'Số mooc',
                ...alignLeftCellTable
              },
              {
                text: SemiTrailer ? SemiTrailer.SemiTrailerNo : '',
                ...alignRightCellTable
              }
            ],
            [
              {
                text: 'Loại xe',
                ...alignLeftCellTable
              },
              {
                text: record.TractorTrailer?.VehicleCategory?.VehicleName,
                ...alignLeftCellTable
              },
              {
                text: 'Số KM',
                ...alignLeftCellTable
              },
              {
                text: DistanceTotal,
                ...alignRightCellTable
              }
            ],
            [
              {
                text: 'Tuyến đường',
                ...alignLeftCellTable
              },
              {
                text: Route,
                ...alignCenterCellTable
              }
            ],
            [
              {
                text: 'Tên chủ hàng',
                ...alignLeftCellTable
              },
              {
                text: record.Consignment?.Customer?.CustomerName,
                ...alignCenterCellTable
              }
            ],
            [
              {
                text: 'Nơi lấy vỏ/Nơi hạ vỏ',
                ...alignLeftCellTable
              },
              {
                text: ContYard,
                ...alignCenterCellTable
              }
            ],
            [
              {
                text: 'Loại hàng',
                ...alignLeftCellTable
              },
              {
                text: record.GoodsCategory?.GoodsCategoryName,
                ...alignLeftCellTable
              },
              {
                text: 'Ngày vận chuyển',
                ...alignLeftCellTable
              },
              {
                text: record.DepartureDate ? moment(record.DepartureDate).format('DD/MM/YYYY') : '',
                ...alignRightCellTable
              }
            ],
            [
              {
                text: 'Số cont',
                ...alignLeftCellTable
              },
              {
                text: contNo,
                ...alignRightCellTable
              },
              {
                text: 'Loại cont',
                ...alignLeftCellTable
              },
              {
                text: contType,
                ...alignRightCellTable
              }
            ],
            [
              {
                text: typePrint === TYPE_NOT_VAT ? 'Dầu khoán' : 'Dầu tiêu hao',
                ...alignLeftCellTable
              },
              {
                text:
                  typePrint === TYPE_NOT_VAT
                    ? record.StandardOil
                    : record.StandardOil + payload?.OilNormMore * DistanceTotal,
                ...alignRightCellTable
              },
              {
                text: 'Dầu phát sinh',
                ...alignLeftCellTable
              },
              {
                text: AdditionalOil,
                ...alignRightCellTable
              }
            ],
            [
              {
                text: 'Dầu bơm',
                ...alignLeftCellTable
              },
              {
                text: dataDetail?.FuelIsNearest?.FuelAmount,
                ...alignRightCellTable
              },
              {
                text: 'Dầu còn được bơm',
                ...alignLeftCellTable
              },
              {
                text: residualOil || '',
                ...alignRightCellTable
              }
            ],
            ...dataTicket,
            [
              ...ChargeNormOrTravelCharge,
              {
                text: 'Chi phí phát sinh',
                ...alignLeftCellTable
              },
              {
                text: record.TyreFee ? formatNumberToReadableString(record.TyreFee) + 'VNĐ' : '',
                ...alignRightCellTable
              }
            ],
            ...GroupCharge,
            [
              {
                text: 'Ghi chú',
                ...alignLeftCellTable
              },
              {
                text: record.Note,
                ...alignCenterCellTable
              }
            ]
          ]
        }
      },
      // {
      //   text: '.........., ngày ..... tháng ..... năm 20.....',
      //   style: 'author'
      // },
      {
        text: `Ngày ${Date} tháng ${Month} năm ${Year}`,
        alignment: 'right',
        margin: [0, 0, 0, 5],
        italics: true
      },
      {
        margin: [0, 5, 0, 50],
        style: 'sign',
        columns: [
          {
            width: '33%',
            text: 'Người nhận tiền',
            alignment: 'center'
          },
          {
            width: '33%',
            text: 'Người lập',
            alignment: 'center'
          },
          {
            width: '33%',
            text: 'Thủ trưởng đơn vị',
            alignment: 'center'
          }
        ],
        columnGap: 10
      }
    ],
    styles: styleExportFile,
    defaultStyle: {
      // alignment: 'justify'
    }
  };
  return docDefinition;
};

const getDataTicket = (dataRoute, TicketFee) => {
  const TicketFeeFormat = TicketFee ? formatNumberToReadableString(TicketFee) + 'VNĐ' : '';
  let dataTicketAmount = [];

  dataRoute.forEach((item) => {
    if (item.Note && dataTicketAmount.length === 0) {
      dataTicketAmount.push([
        {
          text: 'Số lượng vé cầu đường',
          ...alignLeftCellTable
        },
        {
          text: item.Note,
          ...alignRightCellTable
        },
        {
          text: 'Tiền vé',
          ...alignLeftCellTable
        },
        {
          text: TicketFeeFormat,
          ...alignRightCellTable
        }
      ]);
    } else if (item.Note) {
      dataTicketAmount.push([
        {
          text: '',
          ...alignLeftCellTable
        },
        {
          text: item.Note,
          ...alignRightCellTable
        },
        {
          text: '',
          ...alignLeftCellTable
        },
        {
          text: '',
          ...alignRightCellTable
        }
      ]);
    }
  });
  const dataTicketSample = [
    [
      {
        text: 'Số lượng vé cầu đường',
        ...alignLeftCellTable
      },
      {
        text: '',
        ...alignRightCellTable
      },
      {
        text: 'Tiền vé',
        ...alignLeftCellTable
      },
      {
        text: TicketFeeFormat,
        ...alignRightCellTable
      }
    ]
  ];
  if (!dataTicketAmount?.length) {
    return dataTicketSample;
  } else if (dataTicketAmount.length === 1) {
    return dataTicketAmount;
  }
  const rowSpan = dataTicketAmount.length;
  let dataRowSpan = dataTicketAmount[0];
  dataRowSpan[0] = { ...dataRowSpan[0], rowSpan: rowSpan };
  dataRowSpan[2] = { ...dataRowSpan[2], rowSpan: rowSpan };
  dataRowSpan[3] = { ...dataRowSpan[3], rowSpan: rowSpan };
  dataTicketAmount[0] = dataRowSpan;
  return dataTicketAmount;
  //end ticket amount
};

function createPdfTableDataByRecord(displayColumnInfos, record, mapReport, indexItem) {
  const recordDepth = getRecordDepth(displayColumnInfos, record);
  const filledUpRecord = fillUpRecord(record, recordDepth, displayColumnInfos);
  let records = [];
  for (let i = 0; i < recordDepth; i++) {
    const recordText = displayColumnInfos.map((column, indexColumn) => {
      const columnIndex = column.index;
      const recordData = filledUpRecord[columnIndex];
      const columnText = {};
      let recordIndexData = '';
      let rowSpan = 1;

      const hasColumnMapRecord = mapReport && mapReport[columnIndex];
      if (i === 0) {
        recordIndexData =
          columnIndex === 'index'
            ? indexItem + 1
            : hasColumnMapRecord
            ? mapReport[columnIndex](recordData)
            : recordData;
      }
      rowSpan = columnIndex === 'Note' ? (filledUpRecord.rowSpan ? filledUpRecord.rowSpan : 1) : 1;
      columnText.text = recordIndexData;
      columnText.rowSpan = rowSpan;

      return columnText;
    });
    records.push(recordText);
  }
  return records;
}

export function createPdfTableData(displayColumnInfos, exportReports, mapReport) {
  let records = [];
  exportReports.forEach((record, i) => {
    const reportRecords = createPdfTableDataByRecord(displayColumnInfos, record, mapReport, i);
    records = [...records, ...reportRecords];
  });
  return records;
}

export function removeColumnAction(columns) {
  let columnsData = [...columns];
  const indexActionColumn = columnsData.findIndex((el) => {
    return el.ColumnName === 'actions';
  });
  if (indexActionColumn > -1) {
    columnsData.splice(indexActionColumn, 1);
  }

  return columnsData;
}

export const findTruckingPlanMerge = (driverPlanCode, truckings) => {
  if (isNull(driverPlanCode)) return false;
  const truckingPlanCodeMerge = truckings.filter((el) => !isNull(el?.JoiningTruckingPlanId))?.map((el) => el.Id);
  const isHaveTruckingPlanMerge = truckingPlanCodeMerge.includes(driverPlanCode);
  return isHaveTruckingPlanMerge;
};
