import { AGGREGATE_FUNC_NAMES } from 'const/common';

export function flatColumns(columns, withChildren, toArray) {
  const flattedColumns = traverseToFlatColumns(columns, {}, withChildren, toArray);
  return flattedColumns;
}

function traverseToFlatColumns(treeColumns, flattedColumns, withChildren, toArray) {
  if (!treeColumns || treeColumns.length === 0) return flattedColumns;
  let columns;
  if (toArray) {
    columns = [...flattedColumns];
  } else {
    columns = { ...flattedColumns };
  }
  // copy each child node
  treeColumns.forEach((treeColumn) => {
    const nodeFlatColumns = traverseToFlatColumn(treeColumn, {}, withChildren, toArray);

    if (toArray) {
      columns = [...columns, ...nodeFlatColumns];
    } else {
      columns = { ...columns, ...nodeFlatColumns };
    }
  });
  return columns;
}

function traverseToFlatColumn(treeColumn, flattedColumns, withChildren, toArray) {
  if (!treeColumn) return flattedColumns;
  let columns = { ...flattedColumns };
  //copy current node
  if (toArray) {
    columns[columns.length] = copyColumn(treeColumn, withChildren);
  } else {
    columns[`${treeColumn.ColumnName}`] = copyColumn(treeColumn, withChildren);
  }
  // copy each child node
  const treeColumnHasChildren = treeColumn && treeColumn.SysSettingGrids && treeColumn.SysSettingGrids.length > 0;
  if (treeColumnHasChildren) {
    treeColumn.SysSettingGrids.forEach((nodeColumn) => {
      const nodeFlatColumns = traverseToFlatColumn(nodeColumn, []);
      if (toArray) {
        columns = [...columns, ...nodeFlatColumns];
      } else {
        columns = { ...columns, ...nodeFlatColumns };
      }
    });
  }
  return columns;
}

export function copyColumn(treeColumn, withChildren) {
  const column = { ...treeColumn };
  if (!withChildren) {
    delete column.SysSettingGrids;
  }

  return column;
}

export function getDisplayColumns(treeColumns) {
  if (!treeColumns || treeColumns.length === 0) return [];
  const displayColumns = treeColumns
    .map((treeColumn) => {
      return getDisplayTreeColumn(treeColumn);
    })
    .filter((treeColumn) => null !== treeColumn);
  return displayColumns;
}

function isFitSizeSettingGrids(grids, size, length) {
  const totalSize = grids.reduce((prev, next) => prev + next.Size, 0);
  return {
    isFitSize: size === totalSize || size < totalSize,
    restSize: (size - totalSize) / length
  };
}

export function convertSizeColumns(colums) {
  const result = colums.map((c) => {
    const size = c.Size;
    const sysSettingGrids = c?.SysSettingGrids ?? [];
    let checkFitSize = sysSettingGrids.length
      ? isFitSizeSettingGrids(sysSettingGrids, size, sysSettingGrids.length)
      : {
          isFitSize: true,
          restSize: 0
        };
    if (!checkFitSize.isFitSize) {
      const newGrids = sysSettingGrids.map((grid) => {
        const newSize = {
          maxWidth: grid?.bodyStyle?.maxWidth + checkFitSize.restSize,
          minWidth: grid?.bodyStyle?.minWidth + checkFitSize.restSize
        };
        grid.bodyStyle = newSize;
        grid.headerStyle = newSize;
        return grid;
      });
      c.SysSettingGrids = newGrids;
    }
    return c;
  });
  return result;
}

function getDisplayTreeColumn(treeColumn) {
  if (!treeColumn || !treeColumn.DisplayStatus) return null;
  const displayChildren = treeColumn.SysSettingGrids
    ? treeColumn.SysSettingGrids.map((child) => getDisplayTreeColumn(child)).filter((child) => null !== child)
    : [];
  let widthStyle = {};
  if (treeColumn.Size) {
    const size = parseInt(treeColumn.Size);
    widthStyle = {
      bodyStyle: { maxWidth: size, minWidth: size },
      headerStyle: { maxWidth: size, minWidth: size }
    };
  }
  const displayColumns = {
    ...treeColumn,
    ...widthStyle,
    SysSettingGrids: displayChildren ? [...displayChildren] : []
  };
  return displayColumns;
}

export function moveUpColumn(treeColumns, columnName) {
  const tree = { SysSettingGrids: treeColumns };
  const changedTree = traverseTreeToMoveColumn(tree, columnName, moveUp);

  return changedTree.SysSettingGrids;
}

export function moveDownColumn(treeColumns, columnName) {
  const tree = { SysSettingGrids: treeColumns };
  const changedTree = traverseTreeToMoveColumn(tree, columnName, moveDown);

  return changedTree.SysSettingGrids;
}

function moveUp(child, index, SysSettingGrids, swapIndex) {
  if (index === swapIndex - 1) {
    return {
      ...SysSettingGrids[swapIndex],
      Dirty: true
    };
  } else if (index === swapIndex) {
    return {
      ...SysSettingGrids[swapIndex - 1],
      Dirty: true
    };
  } else {
    return child;
  }
}

function moveDown(child, index, SysSettingGrids, swapIndex) {
  if (index === swapIndex) {
    return {
      ...SysSettingGrids[swapIndex + 1],
      Dirty: true
    };
  } else if (index === swapIndex + 1) {
    return {
      ...SysSettingGrids[swapIndex],
      Dirty: true
    };
  } else {
    return child;
  }
}

function traverseTreeToMoveColumn(tree, columnName, moveFunc) {
  let changedTreeColumns = null;
  const index = findByColumnName(tree.SysSettingGrids, columnName);
  let changedChildrenTreeColumns = tree.SysSettingGrids;
  if (-1 !== index) {
    changedChildrenTreeColumns = tree.SysSettingGrids.map((child, currentIndex, SysSettingGrids) => {
      return moveFunc(child, currentIndex, SysSettingGrids, index);
    });
  } else {
    // Not found
    changedChildrenTreeColumns = tree.SysSettingGrids.map((child) =>
      traverseTreeToMoveColumn(child, columnName, moveFunc)
    );
  }
  changedTreeColumns = {
    ...tree,
    SysSettingGrids: changedChildrenTreeColumns
  };

  return changedTreeColumns;
}

function findByColumnName(treeColumns, columnName) {
  let foundIndex = -1;
  treeColumns.forEach((child, index) => {
    if (child.ColumnName === columnName) {
      foundIndex = index;
    }
  });
  return foundIndex;
}

export function convertStringToFormulaOptions(formulas) {
  if (!formulas) return [];
  const aggregateFuncs = formulas
    .toLowerCase()
    .replaceAll(/\s/g, '')
    .split(',');
  return aggregateFuncs.map((func) => {
    return {
      value: func,
      label: AGGREGATE_FUNC_NAMES[func]
    };
  });
}

export function convertFormulaOptionsToString(formulaOptions) {
  let result = [];
  if (Array.isArray(formulaOptions)) {
    result = formulaOptions.reduce((acc, formulaOption, index) => {
      if (0 === index) {
        return formulaOption.value;
      } else {
        return `${acc},${formulaOption.value}`;
      }
    }, '');
  } else {
    result = formulaOptions?.value ?? '';
  }
  return result;
}
