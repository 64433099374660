import { ADD_DRIVER_PLAN_ACTIONS } from 'actions/adddriverplan';
import { DRIVER_PLAN_ACTIONS } from 'actions/driverplan';
import { FILL_UP_FUEL_MANAGEMENT_ACTIONS } from 'actions/fillupfuelmanagement';
import { MOOCS_ACTIONS } from 'actions/mooc';
import { ORDER_MANAGEMENT_ACTIONS } from 'actions/ordermanagement';
import { PAYMENT_ON_BEHAFT_ACTIONS } from 'actions/paymentonbehaft';
import { PRODUCT_CATEGORY_ACTIONS } from 'actions/productcategory';
import { IMPORT_SPARE_PART_ACTIONS } from 'actions/repair/importSparePartManagement';
import { REPAIR_DETAILS } from 'actions/repair/repairInvoiceDetails';
import { REPAIR_ACTIONS } from 'actions/repair/repairManagement';
import { SETTLEMENT_REPAIR } from 'actions/repair/settlementRepair';
import { SPARE_PARTS_ACTIONS } from 'actions/repair/sparePartsManagement';
import { TRANSPORTATION_PLAN_ACTIONS } from 'actions/transportationplan';
import { TRUCKING_FEE_ACTIONS } from 'actions/truckingfee';
import { VEHICLE_ACTIONS } from 'actions/vehicleInfoManagementActions';
import { SHOW_ALERT, UPDATE_TRACKING_FEE } from 'const/common';
import { takeEvery } from 'redux-saga/effects';
import Emitter from 'services/emitter';

const SUCCESS_PAYLOAD = {
  isAlert: true,
  message: 'Thành công',
  typeMessage: 'success'
};

const ERROR_PAYLOAD = {
  isAlert: true,
  message: 'Đã xảy ra lỗi',
  typeMessage: 'error'
};

function* putActionShowAlertError(actions) {
  let payload = ERROR_PAYLOAD;
  switch (actions.type) {
    // transportation plan
    case TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_REJECTED:
      payload.message = actions?.payload?.response?.data?.message || 'Thêm dữ liệu thất bại';
      payload.isDisableAddConts = false;
      payload.isDisableImportExcel = true;
      break;
    case TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_LIST_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Thêm dữ liệu thất bại';
      payload.isDisableAddConts = true;
      payload.isDisableImportExcel = false;
      break;
    case TRANSPORTATION_PLAN_ACTIONS.UPDATE_TRANSPORTATION_PLAN_LIST_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Cập nhật dữ liệu thất bại';
      payload.isDisableAddConts = false;
      payload.isDisableImportExcel = true;
      break;

    // driver plan
    case DRIVER_PLAN_ACTIONS.CREATE_DRIVER_PLAN_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Thêm dữ liệu thất bại';
      payload.isDisableAddDriverPlan = false;
      payload.isDisableAddCars = true;
      break;
    case DRIVER_PLAN_ACTIONS.UPDATE_DRIVER_PLAN_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Cập nhật dữ liệu thất bại';
      payload.isDisableAddDriverPlan = false;
      payload.isDisableAddCars = true;
      break;
    case DRIVER_PLAN_ACTIONS.DELETE_DRIVER_PLAN_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Xóa dữ liệu thất bại';
      payload.isDisableAddDriverPlan = false;
      payload.isDisableAddCars = true;
      break;

    // common edit message
    case SPARE_PARTS_ACTIONS.CREATE_SPARE_PARTS_REJECTED:
      payload.message = actions?.payload?.response?.data?.message || 'Thêm dữ liệu thất bại';

      break;
    case SPARE_PARTS_ACTIONS.UPDATE_SPARE_PARTS_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Cập nhật dữ liệu thất bại';

      break;
    case SPARE_PARTS_ACTIONS.DELETE_SPARE_PARTS_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Xóa dữ liệu thất bại';

      break;

    // MOOC
    case MOOCS_ACTIONS.CREATE_MOOC_REJECTED:
      payload.message = actions?.payload?.response?.data?.message || 'Thêm dữ liệu thất bại';
      break;
    case MOOCS_ACTIONS.UPDATE_MOOC_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Cập nhật dữ liệu thất bại';
      break;
    case MOOCS_ACTIONS.DELETE_MOOC_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Xóa dữ liệu thất bại';
      break;

    //vehicle change message
    case VEHICLE_ACTIONS.CREATE_VEHICLES_REJECTED:
      payload.message = actions?.payload?.response?.data?.message || 'Thêm dữ liệu thất bại';

      break;
    case VEHICLE_ACTIONS.UPDATE_VEHICLES_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Cập nhật dữ liệu thất bại';

      break;
    case VEHICLE_ACTIONS.DELETE_VEHICLES_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Xóa dữ liệu thất bại';

      break;

    // IMPORT SPARE PART
    case IMPORT_SPARE_PART_ACTIONS.CREATE_DATA_REJECTED:
      payload.message = actions?.payload?.response?.data?.message || 'Thêm dữ liệu thất bại';
      break;
    case IMPORT_SPARE_PART_ACTIONS.UPDATE_DATA_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Cập nhật dữ liệu thất bại';
      break;
    case IMPORT_SPARE_PART_ACTIONS.DELETE_DATA_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Xóa dữ liệu thất bại';
      break;

    // PAYMENT_ON_BEHAFT_ACTIONS
    case PAYMENT_ON_BEHAFT_ACTIONS.CREATE_PAYMENT_ON_BEHAFT_REJECTED:
      payload.message = actions?.payload?.response?.data?.message || 'Thêm dữ liệu thất bại';
      break;
    case PAYMENT_ON_BEHAFT_ACTIONS.UPDATE_PAYMENT_ON_BEHAFT_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Cập nhật dữ liệu thất bại';
      break;
    case PAYMENT_ON_BEHAFT_ACTIONS.DELETE_PAYMENT_ON_BEHAFT_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Xóa dữ liệu thất bại';
      break;

    //REPAIR_DETAIL
    case REPAIR_DETAILS.CREATE_REPAIR_INVOICE_DETAILS_REJECTED:
      payload.message = actions?.payload?.response?.data?.message || 'Thêm dữ liệu thất bại';
      break;
    case REPAIR_DETAILS.UPDATE_REPAIR_INVOICE_DETAILS_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Cập nhật dữ liệu thất bại';
      break;
    case REPAIR_DETAILS.DELETE_REPAIR_INVOICE_DETAILS_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Xóa dữ liệu thất bại';
      break;
    //PRODUCT_CATEGORY_ACTIONS
    case PRODUCT_CATEGORY_ACTIONS.CREATE_PRODUCT_CATEGORY_REJECTED:
      payload.message = actions?.payload?.response?.data?.message || 'Thêm dữ liệu thất bại';
      break;
    case PRODUCT_CATEGORY_ACTIONS.UPDATE_PRODUCT_CATEGORY_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Cập nhật dữ liệu thất bại';
      break;
    case PRODUCT_CATEGORY_ACTIONS.DELETE_PRODUCT_CATEGORY_REJECTED:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Xóa dữ liệu thất bại';
      break;
    //settlement
    case SETTLEMENT_REPAIR.CREATE_SETTLEMENT_REPAIR_REJECTED:
      payload.message = actions?.payload?.response?.data?.message || 'Thêm dữ liệu thất bại';
      break;
    case SETTLEMENT_REPAIR.UPDATE_SETTLEMENT_REPAIR_PENDING:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Cập nhật dữ liệu thất bại';
      break;
    case SETTLEMENT_REPAIR.DELE:
      payload.message = payload.message = actions?.payload?.response?.data?.message || 'Xóa dữ liệu thất bại';
      break;

    // add cars
    case VEHICLE_ACTIONS.CREATE_VEHICLE_DRIVER_REJECTED:
      payload.message = 'Thêm dữ liệu thất bại';
      payload.isDisableAddCars = false;
      payload.isDisableAddDriverPlan = true;
      break;

    // common
    // switch/case doesn`t work with OR operator
    // refs: https://stackoverflow.com/questions/6476994/using-or-operator-in-javascript-switch-statement
    // create
    case ADD_DRIVER_PLAN_ACTIONS.CREATE_ADD_DRIVER_PLAN_REJECTED:
    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.CREATE_FILL_UP_FUEL_MANAGEMENT_REJECTED:
    case ORDER_MANAGEMENT_ACTIONS.CREATE_ORDER_MANAGEMENT_REJECTED:
    case REPAIR_ACTIONS.CREATE_REPAIR_REJECTED:
      payload.message = actions?.payload?.response?.data?.message || 'Thêm dữ liệu thất bại';
      break;

    // update
    case ADD_DRIVER_PLAN_ACTIONS.UPDATE_ADD_DRIVER_PLAN_REJECTED:
    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.UPDATE_FILL_UP_FUEL_MANAGEMENT_REJECTED:
    case ORDER_MANAGEMENT_ACTIONS.UPDATE_ORDER_MANAGEMENT_REJECTED:
    case REPAIR_ACTIONS.UPDATE_REPAIR_FEE_REJECTED:
    case TRUCKING_FEE_ACTIONS.UPDATE_TRUCKING_FEE_REJECTED:
    case TRUCKING_FEE_ACTIONS.UPDATE_ADDING_TRUCKING_FEE_REJECTED:
    case REPAIR_ACTIONS.UPDATE_REPAIR_REJECTED:
      payload.message = actions?.payload?.response?.data?.message || 'Cập nhật dữ liệu thất bại';
      break;

    // delete
    case ADD_DRIVER_PLAN_ACTIONS.DELETE_ADD_DRIVER_PLAN_REJECTED:
    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.DELETE_FILL_UP_FUEL_MANAGEMENT_REJECTED:
    case TRANSPORTATION_PLAN_ACTIONS.DELETE_TRANSPORTATION_PLAN_LIST_FULFILLED:
    case TRANSPORTATION_PLAN_ACTIONS.DELETE_TRANSPORTATION_PLAN_LIST_REJECTED:
      payload.message = actions?.payload?.response?.data?.message || 'Xóa dữ liệu thất bại';
      break;
    case ORDER_MANAGEMENT_ACTIONS.DELETE_ORDER_MANAGEMENT_REJECTED:
    case REPAIR_ACTIONS.DELETE_REPAIR_REJECTED:
      payload.message = actions?.payload?.response?.data?.message || 'Xóa dữ liệu thất bại';
      break;

    default:
      break;
  }
  yield Emitter.emit(SHOW_ALERT, payload);
}

function* putActionShowAlertSuccess(actions) {
  let payload = SUCCESS_PAYLOAD;
  switch (actions.type) {
    // cars
    case VEHICLE_ACTIONS.CREATE_VEHICLE_DRIVER_FULFILLED:
      payload.message = 'Thêm dữ liệu thành công';
      payload.isDisableAddCars = true;
      payload.isDisableAddDriverPlan = false;
      break;

    // driver plan
    case DRIVER_PLAN_ACTIONS.CREATE_DRIVER_PLAN_FULFILLED:
      payload.isDisableAddCars = true;
      payload.isDisableAddDriverPlan = true;
      payload.message = 'Thêm dữ liệu thành công';
      break;
    case DRIVER_PLAN_ACTIONS.UPDATE_DRIVER_PLAN_FULFILLED:
      payload.isDisableAddCars = true;
      payload.isDisableAddDriverPlan = true;
      payload.message = 'Cập nhật dữ liệu thành công';
      break;
    case DRIVER_PLAN_ACTIONS.DELETE_DRIVER_PLAN_FULFILLED:
      payload.message = 'Xóa dữ liệu thành công';
      break;

    // common
    // switch/case doesn`t work with OR operator
    // refs: https://stackoverflow.com/questions/6476994/using-or-operator-in-javascript-switch-statement
    // create
    case ADD_DRIVER_PLAN_ACTIONS.CREATE_ADD_DRIVER_PLAN_FULFILLED:
    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.CREATE_FILL_UP_FUEL_MANAGEMENT_FULFILLED:
    case TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_LIST_FULFILLED:
    case TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_FULFILLED:
      payload.isSaveAndContinue = actions?.meta?.isSaveAndContinue;
      break;
    case ORDER_MANAGEMENT_ACTIONS.CREATE_ORDER_MANAGEMENT_FULFILLED:
    case SPARE_PARTS_ACTIONS.CREATE_SPARE_PARTS_FULFILLED:
    case VEHICLE_ACTIONS.CREATE_VEHICLES_FULFILLED:
    case MOOCS_ACTIONS.CREATE_MOOC_FULFILLED:
    case IMPORT_SPARE_PART_ACTIONS.CREATE_DATA_FULFILLED:
    case PAYMENT_ON_BEHAFT_ACTIONS.CREATE_PAYMENT_ON_BEHAFT_FULFILLED:
    case REPAIR_DETAILS.CREATE_REPAIR_INVOICE_DETAILS_FULFILLED:
    case PRODUCT_CATEGORY_ACTIONS.CREATE_PRODUCT_CATEGORY_FULFILLED:
    case SETTLEMENT_REPAIR.CREATE_SETTLEMENT_REPAIR_FULFILLED:
    case REPAIR_ACTIONS.CREATE_REPAIR_FULFILLED:
      payload.message = 'Thêm dữ liệu thành công';
      break;

    // update
    case ADD_DRIVER_PLAN_ACTIONS.UPDATE_ADD_DRIVER_PLAN_FULFILLED:
    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.UPDATE_FILL_UP_FUEL_MANAGEMENT_FULFILLED:
    case TRANSPORTATION_PLAN_ACTIONS.UPDATE_TRANSPORTATION_PLAN_LIST_FULFILLED:
    case ORDER_MANAGEMENT_ACTIONS.UPDATE_ORDER_MANAGEMENT_FULFILLED:
    case SPARE_PARTS_ACTIONS.UPDATE_SPARE_PARTS_FULFILLED:
    case VEHICLE_ACTIONS.UPDATE_VEHICLES_FULFILLED:
    case MOOCS_ACTIONS.UPDATE_MOOC_FULFILLED:
    case IMPORT_SPARE_PART_ACTIONS.UPDATE_DATA_FULFILLED:
    case PAYMENT_ON_BEHAFT_ACTIONS.UPDATE_PAYMENT_ON_BEHAFT_FULFILLED:
    case REPAIR_DETAILS.UPDATE_REPAIR_INVOICE_DETAILS_FULFILLED:
    case REPAIR_ACTIONS.UPDATE_REPAIR_FEE_FULFILLED:
    case PRODUCT_CATEGORY_ACTIONS.UPDATE_PRODUCT_CATEGORY_FULFILLED:
    case TRUCKING_FEE_ACTIONS.UPDATE_TRUCKING_FEE_FULFILLED:
    case TRUCKING_FEE_ACTIONS.UPDATE_ADDING_TRUCKING_FEE_FULFILLED:
    case SETTLEMENT_REPAIR.UPDATE_SETTLEMENT_REPAIR_FULFILLED:
    case REPAIR_ACTIONS.UPDATE_REPAIR_FULFILLED:
      payload.message = 'Cập nhật dữ liệu thành công';
      break;

    // delete
    case ADD_DRIVER_PLAN_ACTIONS.DELETE_ADD_DRIVER_PLAN_FULFILLED:
    case FILL_UP_FUEL_MANAGEMENT_ACTIONS.DELETE_FILL_UP_FUEL_MANAGEMENT_FULFILLED:
    case TRANSPORTATION_PLAN_ACTIONS.DELETE_TRANSPORTATION_PLAN_LIST_FULFILLED:
    case ORDER_MANAGEMENT_ACTIONS.DELETE_ORDER_MANAGEMENT_FULFILLED:
    case SPARE_PARTS_ACTIONS.DELETE_SPARE_PARTS_FULFILLED:
    case VEHICLE_ACTIONS.DELETE_VEHICLES_FULFILLED:
    case MOOCS_ACTIONS.DELETE_MOOC_FULFILLED:
    case IMPORT_SPARE_PART_ACTIONS.DELETE_DATA_FULFILLED:
    case PAYMENT_ON_BEHAFT_ACTIONS.DELETE_PAYMENT_ON_BEHAFT_FULFILLED:
    case PRODUCT_CATEGORY_ACTIONS.DELETE_PRODUCT_CATEGORY_FULFILLED:
    case REPAIR_DETAILS.DELETE_REPAIR_INVOICE_DETAILS_FULFILLED:
    case SETTLEMENT_REPAIR.DELETE_SETTLEMENT_REPAIR_FULFILLED:
    case REPAIR_ACTIONS.DELETE_REPAIR_FULFILLED:
      payload.message = 'Xóa dữ liệu thành công';
      break;

    default:
      break;
  }
  yield Emitter.emit(SHOW_ALERT, payload);
}

function* putActionUpdateTrackingFee() {
  yield Emitter.emit(UPDATE_TRACKING_FEE, {});
}

export function* actionUpdateTrackingFee() {
  yield takeEvery(
    [ADD_DRIVER_PLAN_ACTIONS.GET_ALL_TRACCKING_FEE_OF_ADD_DRIVER_PLAN_FULFILLED],
    putActionUpdateTrackingFee
  );
}

export function* actionShowAlertSuccess() {
  yield takeEvery(
    [
      ADD_DRIVER_PLAN_ACTIONS.UPDATE_ADD_DRIVER_PLAN_FULFILLED,
      ADD_DRIVER_PLAN_ACTIONS.CREATE_ADD_DRIVER_PLAN_FULFILLED,
      ADD_DRIVER_PLAN_ACTIONS.DELETE_ADD_DRIVER_PLAN_FULFILLED,

      // order managements input/output
      ORDER_MANAGEMENT_ACTIONS.CREATE_ORDER_MANAGEMENT_FULFILLED,
      ORDER_MANAGEMENT_ACTIONS.UPDATE_ORDER_MANAGEMENT_FULFILLED,
      ORDER_MANAGEMENT_ACTIONS.DELETE_ORDER_MANAGEMENT_FULFILLED,

      // transport plan
      TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_FULFILLED,
      TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_LIST_FULFILLED,
      TRANSPORTATION_PLAN_ACTIONS.UPDATE_TRANSPORTATION_PLAN_LIST_FULFILLED,
      TRANSPORTATION_PLAN_ACTIONS.DELETE_TRANSPORTATION_PLAN_LIST_FULFILLED,

      // fill up fuel management
      FILL_UP_FUEL_MANAGEMENT_ACTIONS.CREATE_FILL_UP_FUEL_MANAGEMENT_FULFILLED,
      FILL_UP_FUEL_MANAGEMENT_ACTIONS.UPDATE_FILL_UP_FUEL_MANAGEMENT_FULFILLED,
      FILL_UP_FUEL_MANAGEMENT_ACTIONS.DELETE_FILL_UP_FUEL_MANAGEMENT_FULFILLED,

      // driver plan
      DRIVER_PLAN_ACTIONS.UPDATE_DRIVER_PLAN_FULFILLED,
      DRIVER_PLAN_ACTIONS.CREATE_DRIVER_PLAN_FULFILLED,
      DRIVER_PLAN_ACTIONS.DELETE_DRIVER_PLAN_FULFILLED,
      DRIVER_PLAN_ACTIONS.UPDATE_TRUCKING_STATUS_FULFILLED,

      // add cars
      VEHICLE_ACTIONS.CREATE_VEHICLE_DRIVER_FULFILLED,

      //repair management
      REPAIR_ACTIONS.UPDATE_REPAIR_FULFILLED,
      REPAIR_ACTIONS.CREATE_REPAIR_FULFILLED,
      REPAIR_ACTIONS.DELETE_REPAIR_FULFILLED,
      REPAIR_ACTIONS.UPDATE_REPAIR_FEE_FULFILLED,

      //SPARE PART
      SPARE_PARTS_ACTIONS.CREATE_SPARE_PARTS_FULFILLED,
      SPARE_PARTS_ACTIONS.UPDATE_SPARE_PARTS_FULFILLED,
      SPARE_PARTS_ACTIONS.DELETE_SPARE_PARTS_FULFILLED,

      //VEHICLE
      VEHICLE_ACTIONS.CREATE_VEHICLES_FULFILLED,
      VEHICLE_ACTIONS.UPDATE_VEHICLES_FULFILLED,
      VEHICLE_ACTIONS.DELETE_VEHICLES_FULFILLED,

      //MOOC
      MOOCS_ACTIONS.CREATE_MOOC_FULFILLED,
      MOOCS_ACTIONS.UPDATE_MOOC_FULFILLED,
      MOOCS_ACTIONS.DELETE_MOOC_FULFILLED,

      //SPAREPART IMPORT
      IMPORT_SPARE_PART_ACTIONS.CREATE_DATA_FULFILLED,
      IMPORT_SPARE_PART_ACTIONS.UPDATE_DATA_FULFILLED,
      IMPORT_SPARE_PART_ACTIONS.DELETE_DATA_FULFILLED,

      //PAYMENT
      PAYMENT_ON_BEHAFT_ACTIONS.CREATE_PAYMENT_ON_BEHAFT_FULFILLED,
      PAYMENT_ON_BEHAFT_ACTIONS.UPDATE_PAYMENT_ON_BEHAFT_FULFILLED,
      PAYMENT_ON_BEHAFT_ACTIONS.DELETE_PAYMENT_ON_BEHAFT_FULFILLED,

      //REPAIR DETAIL
      REPAIR_DETAILS.CREATE_REPAIR_INVOICE_DETAILS_FULFILLED,
      REPAIR_DETAILS.UPDATE_REPAIR_INVOICE_DETAILS_FULFILLED,
      REPAIR_DETAILS.DELETE_REPAIR_INVOICE_DETAILS_FULFILLED,
      //PRODUCT_CATEGORY_ACTIONS
      PRODUCT_CATEGORY_ACTIONS.CREATE_PRODUCT_CATEGORY_FULFILLED,
      PRODUCT_CATEGORY_ACTIONS.UPDATE_PRODUCT_CATEGORY_FULFILLED,
      PRODUCT_CATEGORY_ACTIONS.DELETE_PRODUCT_CATEGORY_FULFILLED,

      // TRUCKING FEE
      TRUCKING_FEE_ACTIONS.UPDATE_TRUCKING_FEE_FULFILLED,
      TRUCKING_FEE_ACTIONS.UPDATE_ADDING_TRUCKING_FEE_FULFILLED,

      //SETTLEMENT_REPAIR
      SETTLEMENT_REPAIR.CREATE_SETTLEMENT_REPAIR_FULFILLED,
      SETTLEMENT_REPAIR.UPDATE_SETTLEMENT_REPAIR_FULFILLED,
      SETTLEMENT_REPAIR.DELETE_SETTLEMENT_REPAIR_FULFILLED
    ],
    putActionShowAlertSuccess
  );
}

export function* actionShowAlertError() {
  yield takeEvery(
    [
      ADD_DRIVER_PLAN_ACTIONS.UPDATE_ADD_DRIVER_PLAN_REJECTED,
      ADD_DRIVER_PLAN_ACTIONS.CREATE_ADD_DRIVER_PLAN_REJECTED,
      ADD_DRIVER_PLAN_ACTIONS.DELETE_ADD_DRIVER_PLAN_REJECTED,

      // order managements input/output
      ORDER_MANAGEMENT_ACTIONS.CREATE_ORDER_MANAGEMENT_REJECTED,
      ORDER_MANAGEMENT_ACTIONS.UPDATE_ORDER_MANAGEMENT_REJECTED,
      ORDER_MANAGEMENT_ACTIONS.DELETE_ORDER_MANAGEMENT_REJECTED,

      // fill up fuel management
      FILL_UP_FUEL_MANAGEMENT_ACTIONS.CREATE_FILL_UP_FUEL_MANAGEMENT_REJECTED,
      FILL_UP_FUEL_MANAGEMENT_ACTIONS.UPDATE_FILL_UP_FUEL_MANAGEMENT_REJECTED,
      FILL_UP_FUEL_MANAGEMENT_ACTIONS.DELETE_FILL_UP_FUEL_MANAGEMENT_REJECTED,

      // transport plan
      TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_REJECTED,
      TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_LIST_REJECTED,
      TRANSPORTATION_PLAN_ACTIONS.UPDATE_TRANSPORTATION_PLAN_LIST_REJECTED,
      TRANSPORTATION_PLAN_ACTIONS.DELETE_TRANSPORTATION_PLAN_LIST_REJECTED,

      // add cars
      VEHICLE_ACTIONS.CREATE_VEHICLE_DRIVER_REJECTED,

      // driver plan
      DRIVER_PLAN_ACTIONS.CREATE_DRIVER_PLAN_REJECTED,
      DRIVER_PLAN_ACTIONS.UPDATE_DRIVER_PLAN_REJECTED,
      DRIVER_PLAN_ACTIONS.DELETE_DRIVER_PLAN_REJECTED,
      DRIVER_PLAN_ACTIONS.UPDATE_TRUCKING_STATUS_REJECTED,

      //repair management
      REPAIR_ACTIONS.UPDATE_REPAIR_REJECTED,
      REPAIR_ACTIONS.CREATE_REPAIR_REJECTED,
      REPAIR_ACTIONS.DELETE_REPAIR_REJECTED,
      REPAIR_ACTIONS.UPDATE_REPAIR_FEE_REJECTED,

      //spare part
      SPARE_PARTS_ACTIONS.CREATE_SPARE_PARTS_REJECTED,
      SPARE_PARTS_ACTIONS.UPDATE_SPARE_PARTS_REJECTED,
      SPARE_PARTS_ACTIONS.DELETE_SPARE_PARTS_REJECTED,

      //vehicle
      VEHICLE_ACTIONS.CREATE_VEHICLES_REJECTED,
      VEHICLE_ACTIONS.UPDATE_VEHICLES_REJECTED,
      VEHICLE_ACTIONS.DELETE_VEHICLES_REJECTED,

      //MOOC
      MOOCS_ACTIONS.CREATE_MOOC_REJECTED,
      MOOCS_ACTIONS.UPDATE_MOOC_REJECTED,
      MOOCS_ACTIONS.DELETE_MOOC_REJECTED,

      //SPAREPART IMPORT
      IMPORT_SPARE_PART_ACTIONS.CREATE_DATA_REJECTED,
      IMPORT_SPARE_PART_ACTIONS.UPDATE_DATA_REJECTED,
      IMPORT_SPARE_PART_ACTIONS.DELETE_DATA_REJECTED,

      PAYMENT_ON_BEHAFT_ACTIONS.CREATE_PAYMENT_ON_BEHAFT_REJECTED,
      PAYMENT_ON_BEHAFT_ACTIONS.UPDATE_PAYMENT_ON_BEHAFT_REJECTED,
      PAYMENT_ON_BEHAFT_ACTIONS.DELETE_PAYMENT_ON_BEHAFT_REJECTED,

      //REPAIR DETAIL
      REPAIR_DETAILS.CREATE_REPAIR_INVOICE_DETAILS_REJECTED,
      REPAIR_DETAILS.UPDATE_REPAIR_INVOICE_DETAILS_REJECTED,
      REPAIR_DETAILS.DELETE_REPAIR_INVOICE_DETAILS_REJECTED,

      //PRODUCT_CATEGORY_ACTIONS
      PRODUCT_CATEGORY_ACTIONS.CREATE_PRODUCT_CATEGORY_REJECTED,
      PRODUCT_CATEGORY_ACTIONS.UPDATE_PRODUCT_CATEGORY_REJECTED,
      PRODUCT_CATEGORY_ACTIONS.DELETE_PRODUCT_CATEGORY_REJECTED,

      // TRUCKING FEE
      TRUCKING_FEE_ACTIONS.UPDATE_TRUCKING_FEE_REJECTED,
      TRUCKING_FEE_ACTIONS.UPDATE_ADDING_TRUCKING_FEE_REJECTED,

      //SETTLEMENT_REPAIR
      SETTLEMENT_REPAIR.CREATE_SETTLEMENT_REPAIR_REJECTED,
      SETTLEMENT_REPAIR.UPDATE_SETTLEMENT_REPAIR_REJECTED,
      SETTLEMENT_REPAIR.DELETE_SETTLEMENT_REPAIR_REJECTED
    ],
    putActionShowAlertError
  );
}
