import React from 'react';

const LoadingContainer = () => {
  return (
    <div className="text-center">
      <div className="custom_loading_suspense">
        <div className="loader-container">
          <img src={require('../../assets/logsmaster.png')} alt="Logo" className="loading-logo" />
          <div className="loader-loading"></div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(LoadingContainer);
