import api from 'HttpClient';

export const SET_OPEN_WORKING_FILE_MODAL = 'SET_OPEN_WORKING_FILE_MODAL';
export const setOpenWorkingFileModal = (data) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_WORKING_FILE_MODAL,
    payload: data
  });
};

export const SET_CLOSE_WORKING_FILE_MODAL = 'SET_CLOSE_WORKING_FILE_MODAL';
export const setCloseWorkingFileModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_WORKING_FILE_MODAL
  });
};

export const FETCH_ALL_WORKING_FILES = 'FETCH_ALL_WORKING_FILES';
export const FETCH_ALL_WORKING_FILES_PENDING = 'FETCH_ALL_WORKING_FILES_PENDING';
export const FETCH_ALL_WORKING_FILES_FULFILLED = 'FETCH_ALL_WORKING_FILES_FULFILLED';
export const FETCH_ALL_WORKING_FILES_REJECTED = 'FETCH_ALL_WORKING_FILES_REJECTED';
export const fetchAllWorkingFiles = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_WORKING_FILES,
    payload: api.get(`/WorkingFileInfo/getAll`)
  });
};

// const type of function get data per page
export const FETCH_WORKING_FILES = 'FETCH_WORKING_FILES';
export const FETCH_WORKING_FILES_PENDING = 'FETCH_WORKING_FILES_PENDING';
export const FETCH_WORKING_FILES_FULFILLED = 'FETCH_WORKING_FILES_FULFILLED';
export const FETCH_WORKING_FILES_REJECTED = 'FETCH_WORKING_FILES_REJECTED';
export const fetchWorkingFiles = (searchPayload, page, pageSize) => (dispatch) => {
  return dispatch({
    type: FETCH_WORKING_FILES,
    payload: api.get(`/WorkingFileInfo/Search`, { params: { ...searchPayload, page, pageSize: pageSize } })
  });
};

// const type of function create data
export const CREATE_WORKING_FILE = 'CREATE_WORKING_FILE';
export const CREATE_WORKING_FILE_PENDING = 'CREATE_WORKING_FILE_PENDING';
export const CREATE_WORKING_FILE_FULFILLED = 'CREATE_WORKING_FILE_FULFILLED';
export const CREATE_WORKING_FILE_REJECTED = 'CREATE_WORKING_FILE_REJECTED';
export const createWorkingFile = (payload) => (dispatch) => {
  return dispatch({
    type: CREATE_WORKING_FILE,
    payload: api.post(`/WorkingFileInfo/create`, payload)
  });
};

// const type of function update data
export const UPDATE_WORKING_FILE = 'UPDATE_WORKING_FILE';
export const UPDATE_WORKING_FILE_PENDING = 'UPDATE_WORKING_FILE_PENDING';
export const UPDATE_WORKING_FILE_FULFILLED = 'UPDATE_WORKING_FILE_FULFILLED';
export const UPDATE_WORKING_FILE_REJECTED = 'UPDATE_WORKING_FILE_REJECTED';
export const updateWorkingFile = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_WORKING_FILE,
    payload: api.put(`/WorkingFileInfo/update`, payload),
    meta: payload
  });
};
// const type of get deatail
export const FETCH_WORKING_FILE_DETAIL = 'FETCH_WORKING_FILE_DETAIL';
export const FETCH_WORKING_FILE_DETAIL_PENDING = 'FETCH_WORKING_FILE_DETAIL_PENDING';
export const FETCH_WORKING_FILE_DETAIL_FULFILLED = 'FETCH_WORKING_FILE_DETAIL_FULFILLED';
export const FETCH_WORKING_FILE_DETAIL_REJECTED = 'FETCH_WORKING_FILE_DETAIL_REJECTED';
export const getWorkingFileDetail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_WORKING_FILE_DETAIL,
    payload: api.get(`/WorkingFileInfo/${id}`)
  });
};

// const type of delete
export const DELETE_WORKING_FILE = 'DELETE_WORKING_FILE';
export const DELETE_WORKING_FILE_PENDING = 'DELETE_WORKING_FILE_PENDING';
export const DELETE_WORKING_FILE_FULFILLED = 'DELETE_WORKING_FILE_FULFILLED';
export const DELETE_WORKING_FILE_REJECTED = 'DELETE_WORKING_FILE_REJECTED';
export const deleteWorkingFile = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_WORKING_FILE,
    payload: api.delete(`/WorkingFileInfo/delete/${id}`)
  });
};
