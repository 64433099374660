import httpClient from 'HttpClient';
// define const actions
export const DRIVER_PLAN_MOBILE_ACTIONS = {
  GET_DATA_DRIVER_PLAN: 'GET_DATA_DRIVER_PLAN',
  GET_DATA_DRIVER_PLAN_PENDING: 'GET_DATA_DRIVER_PLAN_PENDING',
  GET_DATA_DRIVER_PLAN_FULFILLED: 'GET_DATA_DRIVER_PLAN_FULFILLED',
  GET_DATA_DRIVER_PLAN_REJECTED: 'GET_DATA_DRIVER_PLAN_REJECTED',

  GET_DATA_BY_ID: 'GET_DATA_BY_ID',
  GET_DATA_BY_ID_PENDING: 'GET_DATA_BY_ID_PENDING',
  GET_DATA_BY_ID_FULFILLED: 'GET_DATA_BY_ID_FULFILLED',
  GET_DATA_BY_ID_REJECTED: 'GET_DATA_BY_ID_REJECTED',

  UPDATE_TRIP_NUMBER: 'UPDATE_TRIP_NUMBER',
  UPDATE_TRIP_NUMBER_PENDING: 'UPDATE_TRIP_NUMBER_PENDING',
  UPDATE_TRIP_NUMBER_FULFILLED: 'UPDATE_TRIP_NUMBER_FULFILLED',
  UPDATE_TRIP_NUMBER_REJECTED: 'UPDATE_TRIP_NUMBER_REJECTED',

  GET_FEE_BY_ID: 'GET_FEE_BY_ID',
  GET_FEE_BY_ID_PENDING: 'GET_FEE_BY_ID_PENDING',
  GET_FEE_BY_ID_FULFILLED: 'GET_FEE_BY_ID_FULFILLED',
  GET_FEE_BY_ID_REJECTED: 'GET_FEE_BY_ID_REJECTED',

  UPDATE_FEE: 'UPDATE_FEE',
  UPDATE_FEE_PENDING: 'UPDATE_FEE_PENDING',
  UPDATE_FEE_FULFILLED: 'UPDATE_FEE_FULFILLED',
  UPDATE_FEE_REJECTED: 'UPDATE_FEE_REJECTED',

  GET_STATUS_TRANSPORT: 'GET_STATUS_TRANSPORT',
  GET_STATUS_TRANSPORT_PENDING: 'GET_STATUS_TRANSPORT_PENDING',
  GET_STATUS_TRANSPORT_FULFILLED: 'GET_STATUS_TRANSPORT_FULFILLED',
  GET_STATUS_TRANSPORT_REJECTED: 'GET_STATUS_TRANSPORT_REJECTED',

  GET_PERMISSIONS_MOBILE: 'GET_PERMISSIONS_MOBILE',
  GET_PERMISSIONS_MOBILE_PENDING: 'GET_PERMISSIONS_MOBILE_PENDING',
  GET_PERMISSIONS_MOBILE_FULFILLED: 'GET_PERMISSIONS_MOBILE_FULFILLED',
  GET_PERMISSIONS_MOBILE_REJECTED: 'GET_PERMISSIONS_MOBILE_REJECTED',

  GET_TRANSPORT_FEE_BY_TRUCKING_PLAN_ID: 'GET_TRANSPORT_FEE_BY_TRUCKING_PLAN_ID',
  GET_TRANSPORT_FEE_BY_TRUCKING_PLAN_ID_PENDING: 'GET_TRANSPORT_FEE_BY_TRUCKING_PLAN_ID_PENDING',
  GET_TRANSPORT_FEE_BY_TRUCKING_PLAN_ID_FULFILLED: 'GET_TRANSPORT_FEE_BY_TRUCKING_PLAN_ID_FULFILLED',
  GET_TRANSPORT_FEE_BY_TRUCKING_PLAN_ID_REJECTED: 'GET_TRANSPORT_FEE_BY_TRUCKING_PLAN_ID_REJECTED',

  GET_CATEGORY_FEE_FOR_MOBILE: 'GET_CATEGORY_FEE_FOR_MOBILE',
  GET_CATEGORY_FEE_FOR_MOBILE_PENDING: 'GET_CATEGORY_FEE_FOR_MOBILE_PENDING',
  GET_CATEGORY_FEE_FOR_MOBILE_FULFILLED: 'GET_CATEGORY_FEE_FOR_MOBILE_FULFILLED',
  GET_CATEGORY_FEE_FOR_MOBILE_REJECTED: 'GET_CATEGORY_FEE_FOR_MOBILE_REJECTED',

  UPDATE_STATUS: 'UPDATE_STATUS'
};

// define actions
export const getListDataDriverPlan = (car, startDate, endDate, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_MOBILE_ACTIONS.GET_DATA_DRIVER_PLAN,
    payload: httpClient.get(
      `/TruckingPlan/Search/date?fromDate=${startDate}&toDate=${endDate}&value=${car}&Page=${pageNo}&PageSize=${pageSize}`
    )
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const getDataById = (id) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_MOBILE_ACTIONS.GET_DATA_BY_ID,
    payload: httpClient.get(`/TruckingPlan/getByid/${id}`)
  });
};

export const updateTripNumber = (payload) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_MOBILE_ACTIONS.UPDATE_TRIP_NUMBER,
    payload: httpClient.put(`/TruckingPlan/updateMobileApp`, payload)
  });
};

export const updateFeeData = (payload) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_MOBILE_ACTIONS.UPDATE_FEE,
    payload: httpClient.put(`/TruckingPlan/updateReceiveStatus`, payload)
  });
};

export const getFeeById = (id) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_MOBILE_ACTIONS.GET_FEE_BY_ID,
    payload: httpClient.get(`/TruckingFee/getByid/${id}`)
  });
};

export const updateStatusSubBehaft = (id, statusid) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_MOBILE_ACTIONS.UPDATE_STATUS_SUB_PAYMENT,
    payload: httpClient.get(`/ReceiptAdvancePayment/${id}/changeStatus/${statusid}`)
  });
};

export const getStatus = () => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_MOBILE_ACTIONS.GET_STATUS_TRANSPORT,
    payload: httpClient.get(`/StatusInfo/getBySatusCategoryId/3`)
  });
};

export const deleteData = (id) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_MOBILE_ACTIONS.DELETE_DATA,
    payload: httpClient.delete(`/ReceiptAdvancePayment/delete/${id}`)
  });
};

export const updateStatus = (data) => {
  return {
    type: DRIVER_PLAN_MOBILE_ACTIONS.UPDATE_STATUS,
    payload: data
  };
};
export const getPermissionsMobile = (data) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_MOBILE_ACTIONS.GET_PERMISSIONS_MOBILE,
    payload: httpClient.get('/Dashboard/getPermissionsMobile')
  });
};

export const updateTravelFeeMobileApp = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.put('/TruckingFee/updateTravelFeeMobileApp', payload)
  });
};

export const getTransportFeeByTruckingPlanId = (params) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_MOBILE_ACTIONS.GET_TRANSPORT_FEE_BY_TRUCKING_PLAN_ID,
    payload: httpClient.get('transportFee/getByTruckingPlanId', { params })
  });
};

export const getCategoryFeeForMobile = (params) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_MOBILE_ACTIONS.GET_CATEGORY_FEE_FOR_MOBILE,
    payload: httpClient.get('/CategoryFee/getForMobile', { params })
  });
};

export const createTransportFeeMobile = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.post('TransportFee/create', payload)
  });
};

export const updateTransportFeeMobile = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.put('TransportFee/update', payload)
  });
};

export const deleteTransportFeeMobile = (Id) => (dispatch) => {
  return dispatch({
    payload: httpClient.delete(`/TransportFee/delete/${Id}`)
  });
};
