import httpClient from 'HttpClient';

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const setActiveTab = (activeTab) => (dispatch) => {
  return dispatch({
    type: SET_ACTIVE_TAB,
    payload: activeTab
  });
};

export const COMMONS_ACTIONS = {
  GET_CALCULATING_CATEGORY_NORM: 'GET_CALCULATING_CATEGORY_NORM',
  GET_CALCULATING_CATEGORY_NORM_PENDING: 'GET_CALCULATING_CATEGORY_NORM_PENDING',
  GET_CALCULATING_CATEGORY_NORM_FULFILLED: 'GET_CALCULATING_CATEGORY_NORM_FULFILLED',
  GET_CALCULATING_CATEGORY_NORM_REJECTED: 'GET_CALCULATING_CATEGORY_NORM_REJECTED'
};

// define actions
export const actionGetCalculatingCategory = () => (dispatch) => {
  return dispatch({
    type: COMMONS_ACTIONS.GET_CALCULATING_CATEGORY_NORM,
    payload: httpClient.get('CalculatingCategory/getAll')
  });
};

export const VISIBLE_MODAL_COMMON_INFO = 'VISIBLE_MODAL_COMMON_INFO';
export const visibleModalCommonsInfo = (payload) => (dispatch) => {
  return dispatch({
    type: VISIBLE_MODAL_COMMON_INFO,
    payload: payload
  });
};
