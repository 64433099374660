import api from 'HttpClient';
// import dataAccessary from '../../dataAccessary.json';

export const getAllSparePart = () => {
  return api.get(`/sparepartinfo/getAll`);
};

export const getSparePartPerPage = (pageNo, pageSize) => {
  return api.get(`/sparepartinfo/paging/page?page=${pageNo}&pagesize=${pageSize}`);
  // return dataAccessary;
};

export const createSparePart = (payload) => {
  return api.post(`/sparepartinfo/create`, payload);
};
export const updateSparePart = (payload) => {
  return api.put(`/sparepartinfo/update`, payload);
};
export const deleteSparePart = (id) => {
  return api.delete(`/sparepartinfo/deleteById/${id}`);
};
export const searchSparePart = (params, pageNo, pageSize) => {
  return api.get(`/sparepartinfo/search`, { params: { ...params, Page: pageNo, PageSize: pageSize } });
};
export const getUnitOfSparepart = (id) => {
  return api.get(`Unit/getUnitByCategory?unitCategoryId=${id}`);
};
export const getSparePartById = (id) => {
  return api.get(`/sparepartinfo/getById/${id}`);
};
export const searchAllData = (params) => {
  return api.get(`/sparepartinfo/getall`, { params });
};
