import httpClient from 'HttpClient';
// define const actions
export const DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS = {
  GET_DOWNPAYMENT_OF_ODER_ACCOUNTANT_PER_PAGE: 'GET_DOWNPAYMENT_OF_ODER_ACCOUNTANT_PER_PAGE',
  GET_DOWNPAYMENT_OF_ODER_ACCOUNTANT_PER_PAGE_PENDING: 'GET_DOWNPAYMENT_OF_ODER_ACCOUNTANT_PER_PAGE_PENDING',
  GET_DOWNPAYMENT_OF_ODER_ACCOUNTANT_PER_PAGE_FULFILLED: 'GET_DOWNPAYMENT_OF_ODER_ACCOUNTANT_PER_PAGE_FULFILLED',
  GET_DOWNPAYMENT_OF_ODER_ACCOUNTANT_PER_PAGE_REJECTED: 'GET_DOWNPAYMENT_OF_ODER_ACCOUNTANT_PER_PAGE_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  UPDATE_STATUS_BEHAFT: 'UPDATE_STATUS_BEHAFT',
  UPDATE_STATUS_BEHAFT_PENDING: 'UPDATE_STATUS_BEHAFT_PENDING',
  UPDATE_STATUS_BEHAFT_FULFILLED: 'UPDATE_STATUS_BEHAFT_FULFILLED',
  UPDATE_STATUS_BEHAFT_REJECTED: 'UPDATE_STATUS_BEHAFT_REJECTED',

  UPDATE_STATUS_SETTLEMENT: 'UPDATE_STATUS_SETTLEMENT',
  UPDATE_STATUS_SETTLEMENT_PENDING: 'UPDATE_STATUS_SETTLEMENT_PENDING',
  UPDATE_STATUS_SETTLEMENT_FULFILLED: 'UPDATE_STATUS_SETTLEMENT_FULFILLED',
  UPDATE_STATUS_SETTLEMENT_REJECTED: 'UPDATE_STATUS_SETTLEMENT_REJECTED',

  CREATE_DATA_PAYMENT: 'CREATE_DATA_PAYMENT',
  CREATE_DATA_PAYMENT_PENDING: 'CREATE_DATA_PAYMENT_PENDING',
  CREATE_DATA_PAYMENT_FULFILLED: 'CREATE_DATA_PAYMENT_FULFILLED',
  CREATE_DATA_PAYMENT_REJECTED: 'CREATE_DATA_PAYMENT_REJECTED',

  UPDATE_STATUS_SUB_PAYMENT: 'UPDATE_STATUS_SUB_PAYMENT',
  UPDATE_STATUS_SUB_PAYMENT_PENDING: 'UPDATE_STATUS_SUB_PAYMENT_PENDING',
  UPDATE_STATUS_SUB_PAYMENT_FULFILLED: 'UPDATE_STATUS_SUB_PAYMENT_FULFILLED',
  UPDATE_STATUS_SUB_PAYMENT_REJECTED: 'UPDATE_STATUS_SUB_PAYMENT_REJECTED',

  GET_USER: 'GET_USER',
  GET_USER_PENDING: 'GET_USER_PENDING',
  GET_USER_FULFILLED: 'GET_USER_FULFILLED',
  GET_USER_REJECTED: 'GET_USER_REJECTED',

  DELETE_DATA: 'DELETE_DATA',
  DELETE_DATA_PENDING: 'DELETE_DATA_PENDING',
  DELETE_DATA_FULFILLED: 'DELETE_DATA_FULFILLED',
  DELETE_DATA_REJECTED: 'DELETE_DATA_REJECTED'
};

// define actions
export const getListDataPerpage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.GET_DOWNPAYMENT_OF_ODER_ACCOUNTANT_PER_PAGE,
    payload: httpClient.get(`/internalsettlement/paging/page?Page=${pageNo}&PageSize=${pageSize}`)
  });
};
export const getListDataPerpageAll = (params) => (dispatch) => {
  return dispatch({
    // type:
    //   DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.GET_DOWNPAYMENT_OF_ODER_ACCOUNTANT_PER_PAGE,
    payload: httpClient.get(`/internalsettlement/paging/page?`, { params: params })
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const actionSearch = (params, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/internalsettlement/searchV2?Page=${pageNo}&PageSize=${pageSize}`, { params })
  });
};
export const actionSearchAll = (params) => (dispatch) => {
  return dispatch({
    // type: DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/internalsettlement/searchV2?`, { params: params }) //value=${value}&Page=${pageNo}&PageSize=${pageSize}
  });
};

export const updateStatusBehaft = (consignmentId, statusid) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.UPDATE_STATUS_BEHAFT,
    payload: httpClient.get(`/advanceonconsignment/${consignmentId}/changestatus/${statusid}`)
  });
};

export const updateStatusSettlement = (consignmentId, statusid) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.UPDATE_STATUS_SETTLEMENT,
    payload: httpClient.get(`/internalsettlement/${consignmentId}/changestatus/${statusid}`)
  });
};

export const createDataPayment = (consignmentId, payload) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.CREATE_DATA_PAYMENT,
    payload: httpClient.post(`/ReceiptAdvancePayment/${consignmentId}/create`, payload)
  });
};

export const updateStatusSubBehaft = (id, statusid) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.UPDATE_STATUS_SUB_PAYMENT,
    payload: httpClient.get(`/ReceiptAdvancePayment/${id}/changeStatus/${statusid}`)
  });
};

export const getUserCus = () => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.GET_USER,
    payload: httpClient.get(`/employeeinfo/getall/cus`)
  });
};

export const deleteData = (id) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_OF_ODER_ACCOUNTANT_ACTIONS.DELETE_DATA,
    payload: httpClient.delete(`/ReceiptAdvancePayment/delete/${id}`)
  });
};
