import { SUPPLIER_MANAGEMENT_ACTIONS } from 'actions/repair/supplier';
import { DEFAULT_PAGE_SIZE } from 'const/common';

const INIT_STATE = {
  supplierPerpage: [],
  suppliers: [],
  suppliersBySparepart: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE },
  isSearch: false,
  isCloseModal: false,
  reload: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUPPLIER_MANAGEMENT_ACTIONS.GET_SUPPLIER_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case SUPPLIER_MANAGEMENT_ACTIONS.GET_SUPPLIER_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        supplierPerpage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SUPPLIER_MANAGEMENT_ACTIONS.GET_SUPPLIER_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        reload: false
      };
    }

    case SUPPLIER_MANAGEMENT_ACTIONS.UPDATE_SHOW_ALERT: {
      return {
        ...state,
        isShowMessage: false
      };
    }

    case SUPPLIER_MANAGEMENT_ACTIONS.CREATE_DATA_SPAREPART_DETAIL_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SUPPLIER_MANAGEMENT_ACTIONS.CREATE_DATA_SPAREPART_DETAIL_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isCloseModal: true,
        reload: true
      };
    }
    case SUPPLIER_MANAGEMENT_ACTIONS.CREATE_DATA_SPAREPART_DETAIL_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message:
          action.payload.response.data.status === 400
            ? action.payload.response.data.message
            : 'Thêm mới dữ liệu thất bại !',
        isCloseModal: false,
        reload: true
      };
    }

    case SUPPLIER_MANAGEMENT_ACTIONS.UPDATE_DATA_SPAREPART_DETAIL_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SUPPLIER_MANAGEMENT_ACTIONS.UPDATE_DATA_SPAREPART_DETAIL_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công !',
        isCloseModal: true,
        reload: true
      };
    }
    case SUPPLIER_MANAGEMENT_ACTIONS.UPDATE_DATA_SPAREPART_DETAIL_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Cập nhật dữ liệu thất bại !',
        isCloseModal: false,
        reload: false
      };
    }

    case SUPPLIER_MANAGEMENT_ACTIONS.DELETE_DATA_SUPPLIER_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SUPPLIER_MANAGEMENT_ACTIONS.DELETE_DATA_SUPPLIER_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Xóa dữ liệu thành công !',
        isCloseModal: true,
        reload: true
      };
    }
    case SUPPLIER_MANAGEMENT_ACTIONS.DELETE_DATA_SUPPLIER_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Xóa dữ liệu thất bại !',
        isCloseModal: false,
        reload: false
      };
    }

    case SUPPLIER_MANAGEMENT_ACTIONS.GET_SUPPLIER_ALL_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case SUPPLIER_MANAGEMENT_ACTIONS.GET_SUPPLIER_ALL_FULFILLED: {
      return {
        ...state,
        loading: false,
        suppliers: action.payload.data.data,
        isSearch: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SUPPLIER_MANAGEMENT_ACTIONS.GET_SUPPLIER_ALL_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        reload: false
      };
    }

    case SUPPLIER_MANAGEMENT_ACTIONS.GET_SUPPLIER_BY_SPAREPARTID_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case SUPPLIER_MANAGEMENT_ACTIONS.GET_SUPPLIER_BY_SPAREPARTID_FULFILLED: {
      return {
        ...state,
        loading: false,
        suppliersBySparepart: action.payload.data.data,
        isSearch: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SUPPLIER_MANAGEMENT_ACTIONS.GET_SUPPLIER_BY_SPAREPARTID_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        reload: false
      };
    }

    default:
      return state;
  }
};
