import React, { Suspense } from 'react';
import { Redirect,Route, Switch } from 'react-router-dom';
import { CFade } from '@coreui/react';
import mobileRoutes from 'mobileRoutes';

import './style.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

class HomePage extends React.Component {
  render() {
    return (
      <div style={{ background: 'white' }}>
        <Suspense fallback={loading}>
          <Switch>
            {mobileRoutes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <CFade>
                        <route.component {...props} />
                      </CFade>
                    )}
                  />
                )
              );
            })}
            <Redirect from={'/'} to="/mobile" />
          </Switch>
        </Suspense>
      </div>
    );
  }
}
export default HomePage;
