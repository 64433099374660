import httpClient from "HttpClient";
// define const actions
export const DOWNPAYMENT_SELL_DETAIL_ACTIONS = {
  GET_DOWNPAYMENT_SELL_DETAIL: "GET_DOWNPAYMENT_SELL_DETAIL",
  GET_DOWNPAYMENT_SELL_DETAIL_PENDING: "GET_DOWNPAYMENT_SELL_DETAIL_PENDING",
  GET_DOWNPAYMENT_SELL_DETAIL_FULFILLED:
    "GET_DOWNPAYMENT_SELL_DETAIL_FULFILLED",
  GET_DOWNPAYMENT_SELL_DETAIL_REJECTED: "GET_DOWNPAYMENT_SELL_DETAIL_REJECTED",
};

// define actions
export const getDataDownPayMentSellAccountant = (consigmentId) => (
  dispatch
) => {
  return dispatch({
    type: DOWNPAYMENT_SELL_DETAIL_ACTIONS.GET_DOWNPAYMENT_SELL_DETAIL,
    payload: httpClient.get(`/containerdepositmanaging/${consigmentId}`),
  });
};

export const updateShowAlert = () => {
  return {
    type: "UPDATE_SHOW_ALERT",
  };
};
