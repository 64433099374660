import { OIL_PRICES_MANAGEMENT_ACTIONS } from "actions/oilpricesmanagement";

const oilPricesManagementReducer = (state = {}, action) => {
  switch (action.type) {
    // get oil prices management per page
    case OIL_PRICES_MANAGEMENT_ACTIONS.GET_OIL_PRICES_MANAGEMENT_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
      };

    case OIL_PRICES_MANAGEMENT_ACTIONS.GET_OIL_PRICES_MANAGEMENT_PER_PAGE_FULFILLED:
      let oilPricesManagement = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
      };
      return {
        ...state,
        oilPricesManagement: oilPricesManagement,
        isLoading: false,
        isMsg: false,
      };

    case OIL_PRICES_MANAGEMENT_ACTIONS.GET_OIL_PRICES_MANAGEMENT_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "error",
        isMsg: true,
        msg: "Không thể lấy được dữ liệu",
      };

    // get oil prices management by id
    case OIL_PRICES_MANAGEMENT_ACTIONS.GET_OIL_PRICES_MANAGEMENT_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
      };

    case OIL_PRICES_MANAGEMENT_ACTIONS.GET_OIL_PRICES_MANAGEMENT_BY_ID_FULFILLED:
      return {
        ...state,
        isLoading: false,
      };

    case OIL_PRICES_MANAGEMENT_ACTIONS.GET_OIL_PRICES_MANAGEMENT_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "error",
        isMsg: true,
        msg: "Đã có lỗi xẩy ra",
      };

    // create oil prices management
    case OIL_PRICES_MANAGEMENT_ACTIONS.CREATE_OIL_PRICES_MANAGEMENT_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
      };

    case OIL_PRICES_MANAGEMENT_ACTIONS.CREATE_OIL_PRICES_MANAGEMENT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "success",
        isMsg: true,
        msg: "Thêm mới dữ liệu thành công",
      };

    case OIL_PRICES_MANAGEMENT_ACTIONS.CREATE_OIL_PRICES_MANAGEMENT_REJECTED:
      return {
        ...state,
        isLoading: true,
        typeMessage: "error",
        isMsg: true,
        msg: "Thêm mới dữ liệu thất bại",
      };

    // update oil prices management
    case OIL_PRICES_MANAGEMENT_ACTIONS.UPDATE_OIL_PRICES_MANAGEMENT_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
      };

    case OIL_PRICES_MANAGEMENT_ACTIONS.UPDATE_OIL_PRICES_MANAGEMENT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "success",
        isMsg: true,
        msg: "Cập nhật dữ liệu thành công",
      };

    case OIL_PRICES_MANAGEMENT_ACTIONS.UPDATE_OIL_PRICES_MANAGEMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "error",
        isMsg: true,
        msg: "Cập nhật dữ liệu thất bại",
      };

    // delete oil prices management
    case OIL_PRICES_MANAGEMENT_ACTIONS.DELETE_OIL_PRICES_MANAGEMENT_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
      };

    case OIL_PRICES_MANAGEMENT_ACTIONS.DELETE_OIL_PRICES_MANAGEMENT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "success",
        isMsg: true,
        msg: "Xóa dữ liệu thành công",
      };

    case OIL_PRICES_MANAGEMENT_ACTIONS.DELETE_OIL_PRICES_MANAGEMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "error",
        isMsg: true,
        msg: "Xóa dữ liệu thất bại",
      };
    //Search
    case OIL_PRICES_MANAGEMENT_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        isLoading: true,
        isMsg: false,
      };
    }
    case OIL_PRICES_MANAGEMENT_ACTIONS.SEARCH_DATA_FULFILLED: {
      let oilPricesManagement = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false,
      };
      return {
        ...state,
        isLoading: false,
        oilPricesManagement: oilPricesManagement,
        isSearch: true,
      };
    }
    case OIL_PRICES_MANAGEMENT_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        isLoading: false,
        isShowMessage: true,
        typeMessage: "error",
        msg: "Không thể lấy được dữ liệu !",
      };
    }

    case OIL_PRICES_MANAGEMENT_ACTIONS.UPDATE_OIL_PRICES_TRUCKING_PLAN_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
      };

    case OIL_PRICES_MANAGEMENT_ACTIONS.UPDATE_OIL_PRICES_TRUCKING_PLAN_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "success",
        isMsg: true,
        msg: "Cập nhật dữ liệu thành công",
      };

    case OIL_PRICES_MANAGEMENT_ACTIONS.UPDATE_OIL_PRICES_TRUCKING_PLAN_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "error",
        isMsg: true,
        msg: "Cập nhật dữ liệu thất bại",
      };

    case "UPDATE_SHOW_ALERT_OIL_MANAGEMENT":
      return {
        ...state,
        isMsg: false,
      }

    default:
      return state;
  }
};

export default oilPricesManagementReducer;
