import api from 'HttpClient';

export const SET_OPEN_APRROVAL_MODAL = 'SET_OPEN_APRROVAL_MODAL';
export const setOpenAprrovalModal = (data, type) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_APRROVAL_MODAL,
    payload: { data, type }
  });
};

export const SET_CLOSE_APRROVAL_MODAL = 'SET_CLOSE_APRROVAL_MODAL';
export const setCloseAprrovalModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_APRROVAL_MODAL
  });
};

export const FETCH_ALL_APRROVALS = 'FETCH_ALL_APRROVALS';
export const FETCH_ALL_APRROVALS_PENDING = 'FETCH_ALL_APRROVALS_PENDING';
export const FETCH_ALL_APRROVALS_FULFILLED = 'FETCH_ALL_APRROVALS_FULFILLED';
export const FETCH_ALL_APRROVALS_REJECTED = 'FETCH_ALL_APRROVALS_REJECTED';
export const fetchAllAprrovals = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_APRROVALS,
    payload: api.get(`/ApprovalInfo/getAll`)
  });
};

// const type of function get data per page
export const FETCH_APRROVALS = 'FETCH_APRROVALS';
export const FETCH_APRROVALS_PENDING = 'FETCH_APRROVALS_PENDING';
export const FETCH_APRROVALS_FULFILLED = 'FETCH_APRROVALS_FULFILLED';
export const FETCH_APRROVALS_REJECTED = 'FETCH_APRROVALS_REJECTED';
export const fetchAprrovals = (searchPayload, page, pageSize) => (dispatch) => {
  return dispatch({
    type: FETCH_APRROVALS,
    payload: api.get(`/Approved/paging/page`, { params: { ...searchPayload, page, pageSize: pageSize } })
  });
};
export const fetchAprrovalsAll = (params) => (dispatch) => {
  return dispatch({
    // type: FETCH_APRROVALS,
    payload: api.get(`/Approved/paging/page`, { params: params })
  });
};

// const type of function create data
export const CREATE_APRROVAL = 'CREATE_APRROVAL';
export const CREATE_APRROVAL_PENDING = 'CREATE_APRROVAL_PENDING';
export const CREATE_APRROVAL_FULFILLED = 'CREATE_APRROVAL_FULFILLED';
export const CREATE_APRROVAL_REJECTED = 'CREATE_APRROVAL_REJECTED';
export const createAprroval = (payload) => (dispatch) => {
  return dispatch({
    type: CREATE_APRROVAL,
    payload: api.post(`/Approved/create`, payload)
  });
};

// const type of function update data
export const UPDATE_APRROVAL = 'UPDATE_APRROVAL';
export const UPDATE_APRROVAL_PENDING = 'UPDATE_APRROVAL_PENDING';
export const UPDATE_APRROVAL_FULFILLED = 'UPDATE_APRROVAL_FULFILLED';
export const UPDATE_APRROVAL_REJECTED = 'UPDATE_APRROVAL_REJECTED';
export const updateAprroval = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_APRROVAL,
    payload: api.put(`/Approved/update`, payload),
    meta: payload
  });
};
// const type of get deatail
export const FETCH_APRROVAL_DETAIL = 'FETCH_APRROVAL_DETAIL';
export const FETCH_APRROVAL_DETAIL_PENDING = 'FETCH_APRROVAL_DETAIL_PENDING';
export const FETCH_APRROVAL_DETAIL_FULFILLED = 'FETCH_APRROVAL_DETAIL_FULFILLED';
export const FETCH_APRROVAL_DETAIL_REJECTED = 'FETCH_APRROVAL_DETAIL_REJECTED';
export const getAprrovalDetail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_APRROVAL_DETAIL,
    payload: api.get(`/Approved/${id}`)
  });
};

// const type of delete
export const DELETE_APRROVAL = 'DELETE_APRROVAL';
export const DELETE_APRROVAL_PENDING = 'DELETE_APRROVAL_PENDING';
export const DELETE_APRROVAL_FULFILLED = 'DELETE_APRROVAL_FULFILLED';
export const DELETE_APRROVAL_REJECTED = 'DELETE_APRROVAL_REJECTED';
export const deleteAprroval = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_APRROVAL,
    payload: api.delete(`/Approved/delete/${id}`)
  });
};

export const FETCH_ALL_APPROVAL_STATUSES = 'FETCH_ALL_APPROVAL_STATUSES';
export const FETCH_ALL_APPROVAL_STATUSES_PENDING = 'FETCH_ALL_APPROVAL_STATUSES_PENDING';
export const FETCH_ALL_APPROVAL_STATUSES_FULFILLED = 'FETCH_ALL_APPROVAL_STATUSES_FULFILLED';
export const FETCH_ALL_APPROVAL_STATUSES_REJECTED = 'FETCH_ALL_APPROVAL_STATUSES_REJECTED';
export const fetchAllApprovalStatuses = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_APPROVAL_STATUSES,
    payload: api.get(`/Approved/getApprovedStatus?statusGroup=1`)
  });
};

//api for signature
export const ADD_SIGNATURE = 'ADD_SIGNATURE';
export const ADD_SIGNATURE_PENDING = 'ADD_SIGNATURE_PENDING';
export const ADD_SIGNATURE_FULFILLED = 'ADD_SIGNATURE_FULFILLED';
export const ADD_SIGNATURE_REJECTED = 'ADD_SIGNATURE_REJECTED';
export const addSignature = (payload) => (dispatch) => {
  return dispatch({
    type: ADD_SIGNATURE,
    payload: api.post(`/Approved/createApprovedSign`, payload),
    meta: payload
  });
};

export const DELETE_SIGNATURE = 'DELETE_SIGNATURE';
export const DELETE_SIGNATURE_PENDING = 'DELETE_SIGNATURE_PENDING';
export const DELETE_SIGNATURE_FULFILLED = 'DELETE_SIGNATURE_FULFILLED';
export const DELETE_SIGNATURE_REJECTED = 'DELETE_SIGNATURE_REJECTED';
export const deleteSignature = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_SIGNATURE,
    payload: api.delete(`/Approved/deleteApprovedSign/${id}`)
  });
};

export const APPROVAL_GET_SPAREPART_ORDER_BY_ID = 'APPROVAL_GET_SPAREPART_ORDER_BY_ID';
export const APPROVAL_GET_SPAREPART_ORDER_BY_ID_PENDING = 'APPROVAL_GET_SPAREPART_ORDER_BY_ID_PENDING';
export const APPROVAL_GET_SPAREPART_ORDER_BY_ID_FULFILLED = 'APPROVAL_GET_SPAREPART_ORDER_BY_ID_FULFILLED';
export const APPROVAL_GET_SPAREPART_ORDER_BY_ID_REJECTED = 'APPROVAL_GET_SPAREPART_ORDER_BY_ID_REJECTED';
export const approvalGetSparePartOrderById = (id) => (dispatch) => {
  return dispatch({
    type: APPROVAL_GET_SPAREPART_ORDER_BY_ID,
    payload: api.get(`/Approved/getById/${id}`)
  });
};

export const APPROVAL_DELETE_FILE = 'APPROVAL_DELETE_FILE';
export const APPROVAL_DELETE_FILE_PENDING = 'APPROVAL_DELETE_FILE_PENDING';
export const APPROVAL_DELETE_FILE_FULFILLED = 'APPROVAL_DELETE_FILE_FULFILLED';
export const APPROVAL_DELETE_FILE_REJECTED = 'APPROVAL_DELETE_FILE_REJECTED';
export const actionDeleteFileApproved = (id, fileId) => (dispatch) => {
  return dispatch({
    type: APPROVAL_DELETE_FILE,
    payload: api.delete(`/Approved/deleteImageFile?id=${id}&fileId=${fileId}`)
  });
};
