import { PAYMENT_ON_BEHAFT_ACTIONS } from 'actions/paymentonbehaft';
import { DEFAULT_PAGE_SIZE } from 'const/common';

const INIT_STATE = {
  data: [],
  pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE }
};

const paymentOnBehaftReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // get payment on behaft per page
    case PAYMENT_ON_BEHAFT_ACTIONS.GET_PAYMENT_ON_BEHAFT_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case PAYMENT_ON_BEHAFT_ACTIONS.GET_PAYMENT_ON_BEHAFT_PER_PAGE_FULFILLED:
      let paymentOnBehaft = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        paymentOnBehaft: paymentOnBehaft,
        isLoading: false
      };

    case PAYMENT_ON_BEHAFT_ACTIONS.GET_PAYMENT_ON_BEHAFT_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // get payment on behaft by id
    case PAYMENT_ON_BEHAFT_ACTIONS.GET_PAYMENT_ON_BEHAFT_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case PAYMENT_ON_BEHAFT_ACTIONS.GET_PAYMENT_ON_BEHAFT_BY_ID_FULFILLED:
      return {
        ...state,
        isLoading: false
      };

    case PAYMENT_ON_BEHAFT_ACTIONS.GET_PAYMENT_ON_BEHAFT_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // create payment on behaft
    case PAYMENT_ON_BEHAFT_ACTIONS.CREATE_PAYMENT_ON_BEHAFT_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case PAYMENT_ON_BEHAFT_ACTIONS.CREATE_PAYMENT_ON_BEHAFT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Thêm mới dữ liệu thành công !'
      };

    case PAYMENT_ON_BEHAFT_ACTIONS.CREATE_PAYMENT_ON_BEHAFT_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Thêm mới dữ liệu thất bại'
      };

    // update payment on behaft
    case PAYMENT_ON_BEHAFT_ACTIONS.UPDATE_PAYMENT_ON_BEHAFT_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case PAYMENT_ON_BEHAFT_ACTIONS.UPDATE_PAYMENT_ON_BEHAFT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Cập nhật dữ liệu thành công'
      };

    case PAYMENT_ON_BEHAFT_ACTIONS.UPDATE_PAYMENT_ON_BEHAFT_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Cập nhật dữ liệu thất bại'
      };

    // delete payment on behaft
    case PAYMENT_ON_BEHAFT_ACTIONS.DELETE_PAYMENT_ON_BEHAFT_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case PAYMENT_ON_BEHAFT_ACTIONS.DELETE_PAYMENT_ON_BEHAFT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Xóa dữ liệu thành công'
      };

    case PAYMENT_ON_BEHAFT_ACTIONS.DELETE_PAYMENT_ON_BEHAFT_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Xóa dữ liệu thất bại'
      };

    //Search
    case PAYMENT_ON_BEHAFT_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };
    }
    case PAYMENT_ON_BEHAFT_ACTIONS.SEARCH_DATA_FULFILLED: {
      let paymentOnBehaft = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        isLoading: false,
        paymentOnBehaft: paymentOnBehaft,
        pagination: action.payload.data.pagination,
        isSearch: true
      };
    }
    case PAYMENT_ON_BEHAFT_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        isLoading: false,
        isShowMessage: true,
        typeMessage: 'error',
        msg: 'Không thể lấy được dữ liệu !'
      };
    }

    default:
      return state;
  }
};

export default paymentOnBehaftReducer;
