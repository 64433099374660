import {
    FETCH_REVENUE_COST_BY_CAR_FULFILLED,
    FETCH_REVENUE_COST_BY_CAR_PENDING,
    FETCH_REVENUE_COST_BY_CAR_REJECTED
} from "actions/RevenueCostFollowCarAction";
import {
    DEFAULT_PAGE_SIZE,
    // MSG_TYPE_SUCCESS,
    MSG_TYPE_ERROR} from "const/common";

const INIT_STATE = {
    reports: [],
    pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
    loading: false,
    showMessage: false,
    alertMessage: '',
    messageType: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // get data
        case FETCH_REVENUE_COST_BY_CAR_PENDING:
            {
                return {
                    ...state,
                    loading: true,
                    showMessage: false
                };
            }
        case FETCH_REVENUE_COST_BY_CAR_FULFILLED:
            {
                return {
                    ...state,
                    loading: false,
                    reports: action.payload.data.data,
                    pagination: action.payload.data.pagination ? action.payload.data.pagination: INIT_STATE.pagination,
                    showMessage: false
                };
            }
        case FETCH_REVENUE_COST_BY_CAR_REJECTED:
            {
                return {
                    ...state,
                    loading: false,
                    showMessage: true,
                    alertMessage: "Có lỗi, không thể lấy dữ liệu từ máy chủ. Yêu cầu thử lại",
                    messageType: MSG_TYPE_ERROR
                };
            }

        default:
            return state;
    }
};