import { FUEL_USAGE_REPORT_ACTIONS } from 'actions/report/fuelUsageReport';
import { getErrorMessage } from 'commons/ReducerUtils';
import { MSG_EDIT_FAILURE } from 'const/common';

const INIT_STATE = {
  listPerPage: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  pagination: {},
  isCloseModal: false,
  isSearch: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FUEL_USAGE_REPORT_ACTIONS.GET_FUEL_USAGE_REPORT_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case FUEL_USAGE_REPORT_ACTIONS.GET_FUEL_USAGE_REPORT_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false
      };
    }
    case FUEL_USAGE_REPORT_ACTIONS.GET_FUEL_USAGE_REPORT_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }
    case FUEL_USAGE_REPORT_ACTIONS.UPDATE_OIL_FUEL_USAGE_REPORT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case FUEL_USAGE_REPORT_ACTIONS.UPDATE_OIL_FUEL_USAGE_REPORT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false
      };
    }
    case FUEL_USAGE_REPORT_ACTIONS.UPDATE_OIL_FUEL_USAGE_REPORT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: getErrorMessage(action, MSG_EDIT_FAILURE)
      };
    }
    default:
      return state;
  }
};
