import httpClient from "HttpClient";
// define const actions
export const ADDRESS_ACTIONS = {
  GET_CITY: "GET_CITY",
  GET_CITY_PENDING: "GET_CITY_PENDING",
  GET_CITY_FULFILLED: "GET_CITY_FULFILLED",
  GET_CITY_REJECTED: "GET_CITY_REJECTED",

  GET_DISTRICT: "GET_DISTRICT",
  GET_DISTRICT_PENDING: "GET_DISTRICT_PENDING",
  GET_DISTRICT_FULFILLED: "GET_DISTRICT_FULFILLED",
  GET_DISTRICT_REJECTED: "GET_DISTRICT_REJECTED",

  GET_SUBDISTRICT: "GET_SUBDISTRICT",
  GET_SUBDISTRICT_PENDING: "GET_SUBDISTRICT_PENDING",
  GET_SUBDISTRICT_FULFILLED: "GET_SUBDISTRICT_FULFILLED",
  GET_SUBDISTRICT_REJECTED: "GET_SUBDISTRICT_REJECTED",

  GET_DEPARTURES: "GET_DEPARTURES",
  GET_DEPARTURES_PENDING: "GET_DEPARTURES_PENDING",
  GET_DEPARTURES_FULFILLED: "GET_DEPARTURES_FULFILLED",
  GET_DEPARTURES_REJECTED: "GET_DEPARTURES_REJECTED",

  GET_DESTINATIONS: "GET_DESTINATIONS",
  GET_DESTINATIONS_PENDING: "GET_DESTINATIONS_PENDING",
  GET_DESTINATIONS_FULFILLED: "GET_DESTINATIONS_FULFILLED",
  GET_DESTINATIONS_REJECTED: "GET_DESTINATIONS_REJECTED",

};

// define actions
export const getCity = () => (dispatch) => {
  return dispatch({
    type: ADDRESS_ACTIONS.GET_CITY,
    payload: httpClient.get(`/address/city`),
  });
};

export const getDistrict = (id) => (dispatch) => {
  return dispatch({
    type: ADDRESS_ACTIONS.GET_DISTRICT,
    payload: httpClient.get(`/address/getDistrictByCityId/${id}`),
  });
};

export const getSubDistrictByDistrict = (id) => (dispatch) => {
  return dispatch({
    type: ADDRESS_ACTIONS.GET_SUBDISTRICT,
    payload: httpClient.get(`/address/getSubDistrictByDistrict/${id}`),
  });
};

export const getDepartures = () => (dispatch) => {
  return dispatch({
    type: ADDRESS_ACTIONS.GET_DEPARTURES,
    payload: httpClient.get(`/address/getDepartures`),
  });
};

export const getDestinations = () => (dispatch) => {
  return dispatch({
    type: ADDRESS_ACTIONS.GET_DESTINATIONS,
    payload: httpClient.get(`/address/getDestinations`),
  });
};
