import { CUSTOMER_SETTING_FULFILLED, CUSTOMER_SETTING_PENDING, CUSTOMER_SETTING_REJECTED } from 'actions';

const initialState = {
  data: [],
  pagination: {},
  loading: false
};

const CustomerSetting = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_SETTING_PENDING: {
      return { ...state, loading: true };
    }

    case CUSTOMER_SETTING_FULFILLED: {
      const data = action?.payload?.data?.data;
      const pagination = action?.payload?.data?.pagination;
      return { ...state, data, loading: false, pagination };
    }

    case CUSTOMER_SETTING_REJECTED: {
      return { ...state, loading: false };
    }

    default:
      return { ...state };
  }
};
export default CustomerSetting;
