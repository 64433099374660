import {
  CREATE_ROUTE_ADDRESS_FULFILLED,
  CREATE_ROUTE_ADDRESS_PENDING,
  CREATE_ROUTE_ADDRESS_REJECTED,
  DELETE_ROUTE_ADDRESS_FULFILLED,
  DELETE_ROUTE_ADDRESS_PENDING,
  DELETE_ROUTE_ADDRESS_REJECTED,
  FETCH_ALL_ROUTE_ADDRESSES_FULFILLED,
  FETCH_ALL_ROUTE_ADDRESSES_PENDING,
  FETCH_ALL_ROUTE_ADDRESSES_REJECTED,
  FETCH_ROUTE_ADDRESSES_FULFILLED,
  FETCH_ROUTE_ADDRESSES_PENDING,
  FETCH_ROUTE_ADDRESSES_REJECTED,
  GET_DETAIL_ROUTE_ADDRESS_FULFILLED,
  GET_DETAIL_ROUTE_ADDRESS_PENDING,
  GET_DETAIL_ROUTE_ADDRESS_REJECTED,
  GET_ROUTE_ADDRESS_BY_PORT_CATEGORY_FULFILLED,
  GET_ROUTE_ADDRESS_BY_PORT_CATEGORY_PENDING,
  GET_ROUTE_ADDRESS_BY_PORT_CATEGORY_REJECTED,
  SEARCH_ROUTE_ADDRESS_FULFILLED,
  SEARCH_ROUTE_ADDRESS_PENDING,
  SEARCH_ROUTE_ADDRESS_REJECTED,
  UPDATE_ROUTE_ADDRESS_FULFILLED,
  UPDATE_ROUTE_ADDRESS_PENDING,
  UPDATE_ROUTE_ADDRESS_REJECTED} from "actions/RouteAddressAction";
import {
  DEFAULT_PAGE_SIZE,
  MSG_ADD_FAILURE,
  MSG_ADD_SUCCESS,
  MSG_DELETE_FAILURE,
  MSG_DELETE_SUCCESS,
  MSG_EDIT_FAILURE,
  MSG_EDIT_SUCCESS,
  MSG_GET_ALL_FAILURE,
  MSG_GET_DETAIL_FAILURE,
  MSG_PAGING_FAILURE,
  MSG_SEARCH_FAILURE,
  MSG_TYPE_ERROR,
  MSG_TYPE_SUCCESS,
} from "const/common";

const INIT_STATE = {
  addresses: [],
  goods: [],
  allAddresses: [],
  pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // get data
    case FETCH_ROUTE_ADDRESSES_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false,
      };
    }
    case FETCH_ROUTE_ADDRESSES_FULFILLED: {
      return {
        ...state,
        loading: false,
        addresses: action.payload.data.data,
        pagination: action.payload.data.pagination
          ? action.payload.data.pagination
          : INIT_STATE.pagination,
        showMessage: false,
      };
    }
    case FETCH_ROUTE_ADDRESSES_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR,
      };
    }
    case FETCH_ALL_ROUTE_ADDRESSES_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false,
      };
    }
    case FETCH_ALL_ROUTE_ADDRESSES_FULFILLED: {
      return {
        ...state,
        loading: false,
        allAddresses: action.payload.data.data,
        showMessage: false,
      };
    }
    case FETCH_ALL_ROUTE_ADDRESSES_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: MSG_GET_ALL_FAILURE,
        messageType: MSG_TYPE_ERROR,
      };
    }

    case SEARCH_ROUTE_ADDRESS_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false,
        hasReceiptedSearchResult: false,
      };
    }
    case SEARCH_ROUTE_ADDRESS_FULFILLED: {
      return {
        ...state,
        loading: false,
        addresses: action.payload.data.data,
        pagination: action.payload.data.pagination
          ? action.payload.data.pagination
          : INIT_STATE.pagination,
        showMessage: false,
        hasReceiptedSearchResult: true,
      };
    }
    case SEARCH_ROUTE_ADDRESS_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: MSG_SEARCH_FAILURE,
        messageType: MSG_TYPE_ERROR,
        hasReceiptedSearchResult: true,
      };
    }

    // create data
    case CREATE_ROUTE_ADDRESS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ROUTE_ADDRESS_FULFILLED:
      return {
        ...state,
        isCreate: true,
        loading: false,
        showMessage: true,
        alertMessage: MSG_ADD_SUCCESS,
        messageType: MSG_TYPE_SUCCESS,
      };
    case CREATE_ROUTE_ADDRESS_REJECTED:
      let alertMessage = MSG_ADD_FAILURE;
      if (action.payload.response.data.status === 400) {
        alertMessage = action.payload.response.data.message;
      }
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR,
      };

    // update data
    case UPDATE_ROUTE_ADDRESS_PENDING:
      return {
        ...state,
        loading: true,
        showMessage: false,
      };
    case UPDATE_ROUTE_ADDRESS_FULFILLED:
      return {
        ...state,
        isUpdate: true,
        loading: false,
        showMessage: true,
        alertMessage: MSG_EDIT_SUCCESS,
        messageType: MSG_TYPE_SUCCESS,
      };
    case UPDATE_ROUTE_ADDRESS_REJECTED:
      alertMessage = MSG_EDIT_FAILURE;
      if (action.payload.response.data.status === 400) {
        alertMessage = action.payload.response.data.message;
      }
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR,
      };

    // get detail
    case GET_DETAIL_ROUTE_ADDRESS_PENDING:
      return {
        ...state,
        loading: true,
        showMessage: false,
      };
    case GET_DETAIL_ROUTE_ADDRESS_FULFILLED:
      return {
        ...state,
        addressesDetail: action.payload.data,
        loading: false,
        showMessage: false,
      };
    case GET_DETAIL_ROUTE_ADDRESS_REJECTED:
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: MSG_GET_DETAIL_FAILURE,
        messageType: MSG_TYPE_ERROR,
      };

    // delete data
    case DELETE_ROUTE_ADDRESS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ROUTE_ADDRESS_FULFILLED:
      return {
        ...state,
        isDelete: true,
        loading: false,
        showMessage: true,
        alertMessage: MSG_DELETE_SUCCESS,
        messageType: MSG_TYPE_SUCCESS,
      };
    case DELETE_ROUTE_ADDRESS_REJECTED:
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: MSG_DELETE_FAILURE,
        messageType: MSG_TYPE_ERROR,
      };

    case GET_ROUTE_ADDRESS_BY_PORT_CATEGORY_PENDING:
      return {
        ...state,
        loading: true,
      };

    case GET_ROUTE_ADDRESS_BY_PORT_CATEGORY_FULFILLED:
      return {
        ...state,
        allAddresses: action.payload.data.data,
        loading: false,
        showMessage: false,
      };
    case GET_ROUTE_ADDRESS_BY_PORT_CATEGORY_REJECTED:
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: MSG_GET_ALL_FAILURE,
        messageType: MSG_TYPE_ERROR,
      };

    default:
      return state;
  }
};
