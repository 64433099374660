import { getCompanyId } from 'commons/authUtils';
import api from 'HttpClient';

import { excelHeaderFooter } from './excelActive';

//Add new group action

export const SET_OPEN_SET_UP_DIALOG_MODAL = 'SET_OPEN_SET_UP_DIALOG_MODAL';
export const setOpenSetupDialogModal = () => (dispatch) => {
  return dispatch({
    type: SET_OPEN_SET_UP_DIALOG_MODAL
  });
};
export const SET_CLOSE_SET_UP_DIALOG_MODAL = 'SET_CLOSE_SET_UP_DIALOG_MODAL';
export const setCloseSetupDialogModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_SET_UP_DIALOG_MODAL
  });
};

export const FETCH_SETUP_COLUMN = 'FETCH_SETUP_COLUMN';
export const FETCH_SETUP_COLUMN_PENDING = 'FETCH_SETUP_COLUMN_PENDING';
export const FETCH_SETUP_COLUMN_FULFILLED = 'FETCH_SETUP_COLUMN_FULFILLED';
export const FETCH_SETUP_COLUMN_REJECTED = 'FETCH_SETUP_COLUMN_REJECTED';

export const FETCH_TEMPLATE = 'FETCH_TEMPLATE';
export const FETCH_TEMPLATE_PENDING = 'FETCH_TEMPLATE_PENDING';
export const FETCH_TEMPLATE_FULFILLED = 'FETCH_TEMPLATE_FULFILLED';
export const FETCH_TEMPLATE_REJECTED = 'FETCH_TEMPLATE_REJECTED';

export const FETCH_SAVE_TEMPLATE = 'FETCH_SAVE_TEMPLATE';
export const FETCH_SAVE_TEMPLATE_PENDING = 'FETCH_SAVE_TEMPLATE_PENDING';
export const FETCH_SAVE_TEMPLATE_FULFILLED = 'FETCH_SAVE_TEMPLATE_FULFILLED';
export const FETCH_SAVE_TEMPLATE_REJECTED = 'FETCH_SAVE_TEMPLATE_REJECTED';

export const FETCH_DELETE_TEMPLATE = 'FETCH_DELETE_TEMPLATE';
export const FETCH_DELETE_TEMPLATE_PENDING = 'FETCH_DELETE_TEMPLATE_PENDING';
export const FETCH_DELETE_TEMPLATE_FULFILLED = 'FETCH_DELETE_TEMPLATE_FULFILLED';
export const FETCH_DELETE_TEMPLATE_REJECTED = 'FETCH_DELETE_TEMPLATE_REJECTED';

export const fetchTemplate = (screenName) => (dispatch) => {
  const params = {
    screenName
  };
  return dispatch({
    type: FETCH_TEMPLATE,
    payload: api.get('/ExcelTemplate/search', { params })
  });
};
export const fetchSaveTemplate = (params) => (dispatch) => {
  return dispatch({
    type: FETCH_SAVE_TEMPLATE,
    payload: api.post('/ExcelTemplate/insert', params)
  });
};
export const fetchDeleteTemplate = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_DELETE_TEMPLATE,
    payload: api.delete('/ExcelTemplate/delete/' + id)
  });
};
export const fetchSetupDialogColumns = (screenName) => (dispatch) => {
  dispatch(fetchTemplate(screenName));
  dispatch(excelHeaderFooter({ ScreenName: screenName, CompanyId: getCompanyId() }));
  return dispatch({
    type: FETCH_SETUP_COLUMN,
    payload: api.get(`/SettingGrid/getByScreen/${screenName}`)
  });
};
export const CHANGE_SETUP_FIELD_VALUE = 'CHANGE_SETUP_FIELD_VALUE';
export const CHANGE_SETUP_FIELD_VALUE_PENDING = 'CHANGE_SETUP_FIELD_VALUE_PENDING';
export const CHANGE_SETUP_FIELD_VALUE_FULFILLED = 'CHANGE_SETUP_FIELD_VALUE_FULFILLED';
export const CHANGE_SETUP_FIELD_VALUE_REJECTED = 'CHANGE_SETUP_FIELD_VALUE_REJECTED';
export const changeSetupFieldValue = (value, column, prop) => (dispatch) => {
  return dispatch({
    type: CHANGE_SETUP_FIELD_VALUE,
    payload: { value, column, prop }
  });
};

export const UPDATE_SETUP_DIALOG = 'UPDATE_SETUP_DIALOG';
export const UPDATE_SETUP_DIALOG_PENDING = 'UPDATE_SETUP_DIALOG_PENDING';
export const UPDATE_SETUP_DIALOG_FULFILLED = 'UPDATE_SETUP_DIALOG_FULFILLED';
export const UPDATE_SETUP_DIALOG_REJECTED = 'UPDATE_SETUP_DIALOG_REJECTED';
export const updateSetupDialog = (screenName, dirtySetups, columns) => (dispatch) => {
  const updateUrl = `/SettingGrid/update`;
  return dispatch({
    type: UPDATE_SETUP_DIALOG,
    payload: api.put(updateUrl, { screenName, data: dirtySetups, sortedTree: columns })
  });
};

export const MOVE_UP_COLUMN = 'MOVE_UP_COLUMN';
export const MOVE_UP_COLUMN_PENDING = 'MOVE_UP_COLUMN_PENDING';
export const MOVE_UP_COLUMN_FULFILLED = 'MOVE_UP_COLUMN_FULFILLED';
export const MOVE_UP_COLUMN_REJECTED = 'MOVE_UP_COLUMN_REJECTED';
export const moveUpColumn = (columnId) => (dispatch) => {
  return dispatch({
    type: MOVE_UP_COLUMN,
    payload: columnId
  });
};

export const MOVE_DOWN_COLUMN = 'MOVE_DOWN_COLUMN';
export const MOVE_DOWN_COLUMN_PENDING = 'MOVE_DOWN_COLUMN_PENDING';
export const MOVE_DOWN_COLUMN_FULFILLED = 'MOVE_DOWN_COLUMN_FULFILLED';
export const MOVE_DOWN_COLUMN_REJECTED = 'MOVE_DOWN_COLUMN_REJECTED';
export const moveDownColumn = (columnId) => (dispatch) => {
  return dispatch({
    type: MOVE_DOWN_COLUMN,
    payload: columnId
  });
};

export const BACKUP_COLUMN = 'BACKUP_COLUMN';
export const backupColumn = () => (dispatch) => {
  setTimeout(() => {
    return dispatch({
      type: BACKUP_COLUMN
    });
  }, 500);
};

export const SET_VALUE_COLUMNS_SETUP_DIALOG = 'SET_VALUE_COLUMNS_SETUP_DIALOG';
export const setValueColumnsSetupDialog = (columnsInfo, columns) => (dispatch) => {
  return dispatch({
    type: SET_VALUE_COLUMNS_SETUP_DIALOG,
    payload: { displayColumns: columnsInfo, columns: columns }
  });
};

export const RESET_SETUP_COLUMNS = 'RESET_SETUP_COLUMNS';
export const resetSetupColumns = () => (dispatch) => {
  return dispatch({
    type: RESET_SETUP_COLUMNS
  });
};

export const UPDATE_EMAIL_PASSWORD = 'UPDATE_EMAIL_PASSWORD';
export const UPDATE_EMAIL_PASSWORD_PENDING = 'UPDATE_EMAIL_PASSWORD_PENDING';
export const UPDATE_EMAIL_PASSWORD_FULFILLED = 'UPDATE_EMAIL_PASSWORD_FULFILLED';
export const UPDATE_EMAIL_PASSWORD_REJECTED = 'UPDATE_EMAIL_PASSWORD_REJECTED';
export const updateEmailPassword = (data) => (dispatch) => {
  return dispatch({
    type: UPDATE_EMAIL_PASSWORD,
    payload: api.put('/email/updatePassword', data)
  });
};
