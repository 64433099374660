import { CUSTOMER_TYPE } from 'const/common';
import api from 'HttpClient';

// const type of function get data per page
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_PENDING = 'FETCH_CUSTOMERS_PENDING';
export const FETCH_CUSTOMERS_FULFILLED = 'FETCH_CUSTOMERS_FULFILLED';
export const FETCH_CUSTOMERS_REJECTED = 'FETCH_CUSTOMERS_REJECTED';
export const fetchCustomers = (page, size, customerType) => (dispatch) => {
  return dispatch({
    type: FETCH_CUSTOMERS,
    payload: api.get(`/customerinfo/paging/page?customerType=${customerType}&page=${page}&pagesize=${size}`)
    // payload: api.get(`/customer`)
  });
};
// const type of function get data per page
export const FETCH_ALL_CUSTOMERS = 'FETCH_ALL_CUSTOMERS';
export const FETCH_ALL_CUSTOMERS_PENDING = 'FETCH_ALL_CUSTOMERS_PENDING';
export const FETCH_ALL_CUSTOMERS_FULFILLED = 'FETCH_ALL_CUSTOMERS_FULFILLED';
export const FETCH_ALL_CUSTOMERS_REJECTED = 'FETCH_ALL_CUSTOMERS_REJECTED';
export const fetchAllCustomers = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_CUSTOMERS,
    payload: api.get(`/customerinfo/getByCustomerType/${CUSTOMER_TYPE.CUSTOMER}`)
  });
};

// const type of function create data
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const CREATE_CUSTOMER_PENDING = 'CREATE_CUSTOMER_PENDING';
export const CREATE_CUSTOMER_FULFILLED = 'CREATE_CUSTOMER_FULFILLED';
export const CREATE_CUSTOMER_REJECTED = 'CREATE_CUSTOMER_REJECTED';
export const createCustomer = (customer) => (dispatch) => {
  return dispatch({
    type: CREATE_CUSTOMER,
    payload: api.post(`/customerinfo/create`, customer)
  });
};

// const type of function update data
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_PENDING = 'UPDATE_CUSTOMER_PENDING';
export const UPDATE_CUSTOMER_FULFILLED = 'UPDATE_CUSTOMER_FULFILLED';
export const UPDATE_CUSTOMER_REJECTED = 'UPDATE_CUSTOMER_REJECTED';
export const updateCustomer = (customer) => (dispatch) => {
  return dispatch({
    type: UPDATE_CUSTOMER,
    payload: api.put(`/customerinfo/update`, customer)
  });
};

// const type of get deatail
export const GET_DETAIL_CUSTOMER = 'GET_DETAIL_CUSTOMER';
export const GET_DETAIL_CUSTOMER_PENDING = 'GET_DETAIL_CUSTOMER_PENDING';
export const GET_DETAIL_CUSTOMER_FULFILLED = 'GET_DETAIL_CUSTOMER_FULFILLED';
export const GET_DETAIL_CUSTOMER_REJECTED = 'GET_DETAIL_CUSTOMER_REJECTED';
export const getDetailCustomer = (id) => (dispatch) => {
  return dispatch({
    type: GET_DETAIL_CUSTOMER,
    payload: api.get(`/customerinfo/getById/${id}`)
  });
};

// const type of delete
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_PENDING = 'DELETE_CUSTOMER_PENDING';
export const DELETE_CUSTOMER_FULFILLED = 'DELETE_CUSTOMER_FULFILLED';
export const DELETE_CUSTOMER_REJECTED = 'DELETE_CUSTOMER_REJECTED';
export const deleteCustomer = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_CUSTOMER,
    payload: api.delete(`/customerinfo/delete/${id}`)
  });
};

// const type of delete
export const SEARCH_CUSTOMER = 'SEARCH_CUSTOMER';
export const SEARCH_CUSTOMER_PENDING = 'SEARCH_CUSTOMER_PENDING';
export const SEARCH_CUSTOMER_FULFILLED = 'SEARCH_CUSTOMER_FULFILLED';
export const SEARCH_CUSTOMER_REJECTED = 'SEARCH_CUSTOMER_REJECTED';
export const searchCustomer = (searchKey, page, pageSize, customerType) => (dispatch) => {
  return dispatch({
    type: SEARCH_CUSTOMER,
    payload: { searchKey, page, pageSize, customerType }
  });
};

export const searchCustomerAll = (params) => (dispatch) => {
  return dispatch({
    // type: SEARCH_CUSTOMER,
    payload: api.get('/customerinfo/search', {
      params
    })
  });
};

// get all
export const GET_ALL_CUSTOMER = 'GET_ALL_CUSTOMER';
export const GET_ALL_CUSTOMER_PENDING = 'GET_ALL_CUSTOMER_PENDING';
export const GET_ALL_CUSTOMER_FULFILLED = 'GET_ALL_CUSTOMER_FULFILLED';
export const GET_ALL_CUSTOMER_REJECTED = 'GET_ALL_CUSTOMER_REJECTED';
export const actionGetAllCustomers = () => (dispatch) => {
  return dispatch({
    type: GET_ALL_CUSTOMER,
    payload: api.get(`/customerinfo/getAll`)
  });
};

//get good category
export const GET_GOOD_CATEGORY = 'GET_GOOD_CATEGORY';
export const GET_GOOD_CATEGORY_PENDING = 'GET_GOOD_CATEGORY_PENDING';
export const GET_GOOD_CATEGORY_FULFILLED = 'GET_GOOD_CATEGORY_FULFILLED';
export const GET_GOOD_CATEGORY_REJECTED = 'GET_GOOD_CATEGORY_REJECTED';
export const actionGetGoodCategory = () => (dispatch) => {
  return dispatch({
    type: GET_GOOD_CATEGORY,
    payload: api.get(`/GoodsCategoryInfo/getAll`)
  });
};

//get customer type
export const GET_CUSTOMER_TYPE = 'GET_CUSTOMER_TYPE';
export const GET_CUSTOMER_TYPE_PENDING = 'GET_CUSTOMER_TYPE_PENDING';
export const GET_CUSTOMER_TYPE_FULFILLED = 'GET_CUSTOMER_TYPE_FULFILLED';
export const GET_CUSTOMER_TYPE_REJECTED = 'GET_CUSTOMER_TYPE_REJECTED';
export const actionGetCustomerType = () => (dispatch) => {
  return dispatch({
    type: GET_CUSTOMER_TYPE,
    payload: api.get(`/CustomerType/getAll`)
  });
};

export const GET_ALL_CUSTOMER_BY_TYPE = 'GET_ALL_CUSTOMER_BY_TYPE';
export const GET_ALL_CUSTOMER_BY_TYPE_PENDING = 'GET_ALL_CUSTOMER_BY_TYPE_PENDING';
export const GET_ALL_CUSTOMER_BY_TYPE_FULFILLED = 'GET_ALL_CUSTOMER_BY_TYPE_FULFILLED';
export const GET_ALL_CUSTOMER_BY_TYPE_REJECTED = 'GET_ALL_CUSTOMER_BY_TYPE_REJECTED';
export const actionGetAllCustomersByType = (type) => (dispatch) => {
  return dispatch({
    type: GET_ALL_CUSTOMER_BY_TYPE,
    payload: api.get(`/customerinfo/getByCustomerType/${type}`)
  });
};

export const GET_ALL_CUSTOMER_BY_TYPE_ALL = 'GET_ALL_CUSTOMER_BY_TYPE_ALL';
export const GET_ALL_CUSTOMER_BY_TYPE_ALL_PENDING = 'GET_ALL_CUSTOMER_BY_TYPE_ALL_PENDING';
export const GET_ALL_CUSTOMER_BY_TYPE_ALL_FULFILLED = 'GET_ALL_CUSTOMER_BY_TYPE_ALL_FULFILLED';
export const GET_ALL_CUSTOMER_BY_TYPE_ALL_REJECTED = 'GET_ALL_CUSTOMER_BY_TYPE_ALL_REJECTED';
export const actionGetAllCustomersByTypeAll = (types) => (dispatch) => {
  return dispatch({
    type: GET_ALL_CUSTOMER_BY_TYPE_ALL,
    payload: api.get(`/customerinfo/getByCustomerTypeAll`, { params: { customerType: types } })
  });
};

export const UPLOAD_IMG_ALL = 'GET_ALL_CUSTOMER_BY_TYPE_ALL';
export const UPLOAD_IMG_ALL_PENDING = 'GET_ALL_CUSTOMER_BY_TYPE_ALL_PENDING';
export const UPLOAD_IMG_ALL_FULFILLED = 'GET_ALL_CUSTOMER_BY_TYPE_ALL_FULFILLED';
export const UPLOAD_IMG_ALL_REJECTED = 'GET_ALL_CUSTOMER_BY_TYPE_ALL_REJECTED';
export const actionUploadImgALl = (id, formData) => (dispatch) => {
  return dispatch({
    type: GET_ALL_CUSTOMER_BY_TYPE_ALL,
    payload: api.post(`/Consignment/uploadMany/${id}`, formData)
  });
};

export const SET_CUSTOMER_SEARCH_PARAMS = 'SET_CUSTOMER_SEARCH_PARAMS';
export const setCustomerSearchParams = (search) => (dispatch) => {
  return dispatch({
    type: SET_CUSTOMER_SEARCH_PARAMS,
    payload: search
  });
};

export const GET_CONSIGNMENT_BY_CUSTOMER_ID = 'GET_CONSIGNMENT_BY_CUSTOMER_ID';
export const GET_CONSIGNMENT_BY_CUSTOMER_ID_PENDING = 'GET_CONSIGNMENT_BY_CUSTOMER_ID_PENDING';
export const GET_CONSIGNMENT_BY_CUSTOMER_ID_FULFILLED = 'GET_CONSIGNMENT_BY_CUSTOMER_ID_FULFILLED';
export const GET_CONSIGNMENT_BY_CUSTOMER_ID_REJECTED = 'GET_CONSIGNMENT_BY_CUSTOMER_ID_REJECTED';
export const getConsignmentByCustomerID = (customerID, fromDate, toDate) => (dispatch) => {
  return dispatch({
    type: GET_CONSIGNMENT_BY_CUSTOMER_ID,
    payload: api.get(
      `/Consignment/getByCustomerForCombobox?customerId=${customerID}&Fromdate=${fromDate}&ToDate=${toDate}`
    )
  });
};

// export const GET_COUNT_BY_CUSTOMER_ID = 'GET_ COUNT_BY_CUSTOMER_ID';
// export const GET_COUNT_BY_CUSTOMER_ID_PENDING = 'GET_ COUNT_BY_CUSTOMER_ID_PENDING';
// export const GET_COUNT_BY_CUSTOMER_ID_FULFILLED = 'GET_ COUNT_BY_CUSTOMER_ID_FULFILLED';
// export const GET_COUNT_BY_CUSTOMER_ID_REJECTED = 'GET_COUNT_BY_CUSTOMER_ID_REJECTED';
// export const getCountByCustomerId = (customerID) => (dispatch) => {
//   return dispatch({
//     type: GET_COUNT_BY_CUSTOMER_ID,
//     payload: api.get(`/Consignment/CountByCustomerId/${customerID}`)
//   });
// };
