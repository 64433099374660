import {
  CREATE_CUSTOMER_PERMISSION_TYPE_FULFILLED,
  CREATE_CUSTOMER_PERMISSION_TYPE_PENDING,
  CREATE_CUSTOMER_PERMISSION_TYPE_REJECTED,
  DELETE_CUSTOMER_PERMISSION_TYPE_FULFILLED,
  DELETE_CUSTOMER_PERMISSION_TYPE_PENDING,
  DELETE_CUSTOMER_PERMISSION_TYPE_REJECTED,
  FETCH_ALL_CUSTOMER_PERMISSION_TYPES_FULFILLED,
  FETCH_ALL_CUSTOMER_PERMISSION_TYPES_PENDING,
  FETCH_ALL_CUSTOMER_PERMISSION_TYPES_REJECTED,
  FETCH_CUSTOMER_PERMISSION_TYPE_DETAIL_FULFILLED,
  FETCH_CUSTOMER_PERMISSION_TYPE_DETAIL_PENDING,
  FETCH_CUSTOMER_PERMISSION_TYPE_DETAIL_REJECTED,
  FETCH_CUSTOMER_PERMISSION_TYPES_FULFILLED,
  FETCH_CUSTOMER_PERMISSION_TYPES_PENDING,
  FETCH_CUSTOMER_PERMISSION_TYPES_REJECTED,
  SET_CLOSE_CUSTOMER_PERMISSION_TYPE_MODAL,
  SET_OPEN_CUSTOMER_PERMISSION_TYPE_MODAL,
  UPDATE_CUSTOMER_PERMISSION_TYPE_FULFILLED,
  UPDATE_CUSTOMER_PERMISSION_TYPE_PENDING,
  UPDATE_CUSTOMER_PERMISSION_TYPE_REJECTED} from 'actions';
import { getErrorMessage } from 'commons/ReducerUtils';
import {
  DEFAULT_PAGE_SIZE,
  MSG_ADD_FAILURE,
  MSG_ADD_SUCCESS,
  MSG_DELETE_FAILURE,
  MSG_DELETE_SUCCESS,
  MSG_EDIT_FAILURE,
  MSG_EDIT_SUCCESS,
  MSG_GET_DETAIL_FAILURE,
  MSG_PAGING_FAILURE,
  MSG_TYPE_ERROR,
  MSG_TYPE_SUCCESS} from 'const/common';
const INIT_STATE = {
  //combobox: get all
  allCustomerPermissionTypes: [],
  // paging
  customerPermissionTypes: [],
  pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
  listLoading: false,

  // add/edit modal
  isOpenFormModal: false, // Show/hidden update modal
  customerPermissionType: {},
  formLoading: false,
  //delete
  deleteLoading: false,
  // other
  showMessage: false,
  alertMessage: '',
  formAlertMessage: '',
  messageType: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region update modal
    case SET_OPEN_CUSTOMER_PERMISSION_TYPE_MODAL: {
      console.log('reducer open form modal');
      return {
        ...state,
        isOpenFormModal: true,
        customerPermissionType: action.payload
      };
    }
    case SET_CLOSE_CUSTOMER_PERMISSION_TYPE_MODAL: {
      return {
        ...state,
        isOpenFormModal: false,
        customerPermissionType: INIT_STATE.customerPermissionType
      };
    }
    //#endregion

    //#region getall
    case FETCH_ALL_CUSTOMER_PERMISSION_TYPES_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }
    case FETCH_ALL_CUSTOMER_PERMISSION_TYPES_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        allCustomerPermissionTypes: action.payload.data.data,
        showMessage: false
      };
    }
    case FETCH_ALL_CUSTOMER_PERMISSION_TYPES_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region paging
    case FETCH_CUSTOMER_PERMISSION_TYPES_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }

    case FETCH_CUSTOMER_PERMISSION_TYPES_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        customerPermissionTypes: action.payload.data.data ?? INIT_STATE.customerPermissionTypes,
        pagination: action.payload.data.pagination ?? INIT_STATE.pagination,
        showMessage: false
      };
    }
    case FETCH_CUSTOMER_PERMISSION_TYPES_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region create data
    case CREATE_CUSTOMER_PERMISSION_TYPE_PENDING:
      return {
        ...state,
        formLoading: true
      };
    case CREATE_CUSTOMER_PERMISSION_TYPE_FULFILLED: {
      const customerPermissionType = action.payload.data;
      const customerPermissionTypes = state.customerPermissionTypes.map((item) => {
        return item.Id === customerPermissionType.Id ? customerPermissionType : item;
      });
      return {
        ...state,
        customerPermissionTypes: [...customerPermissionTypes],
        formLoading: false,
        showMessage: true,
        formAlertMessage: MSG_ADD_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case CREATE_CUSTOMER_PERMISSION_TYPE_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_ADD_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region update data
    case UPDATE_CUSTOMER_PERMISSION_TYPE_PENDING:
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    case UPDATE_CUSTOMER_PERMISSION_TYPE_FULFILLED: {
      const customerPermissionType = action.meta;
      let customerPermissionTypes = [...state.customerPermissionTypes];
      customerPermissionTypes = customerPermissionTypes.map((item) => {
        return item.Id === customerPermissionType.Id ? customerPermissionType : item;
      });
      return {
        ...state,
        customerPermissionTypes,
        formLoading: false,
        showMessage: true,
        formAlertMessage: MSG_EDIT_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case UPDATE_CUSTOMER_PERMISSION_TYPE_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_EDIT_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region  get detail
    case FETCH_CUSTOMER_PERMISSION_TYPE_DETAIL_PENDING:
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    case FETCH_CUSTOMER_PERMISSION_TYPE_DETAIL_FULFILLED:
      return {
        ...state,
        customerPermissionType: action.payload.data,
        formLoading: false,
        showMessage: false
      };
    case FETCH_CUSTOMER_PERMISSION_TYPE_DETAIL_REJECTED:
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        alertMessage: MSG_GET_DETAIL_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    //#endregion

    //#region delete data
    case DELETE_CUSTOMER_PERMISSION_TYPE_PENDING:
      return {
        ...state,
        deleteLoading: true
      };
    case DELETE_CUSTOMER_PERMISSION_TYPE_FULFILLED:
      return {
        ...state,
        isDelete: true,
        deleteLoading: false,
        showMessage: true,
        alertMessage: MSG_DELETE_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case DELETE_CUSTOMER_PERMISSION_TYPE_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_DELETE_FAILURE);
      return {
        ...state,
        deleteLoading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    default:
      return state;
  }
};
