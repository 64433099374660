import {
  SEARCH_ROUTE_ADDRESS,
  SEARCH_ROUTE_ADDRESS_FULFILLED,
  SEARCH_ROUTE_ADDRESS_PENDING,
  SEARCH_ROUTE_ADDRESS_REJECTED
} from 'actions/RouteAddressAction';
import httpClient from 'HttpClient';
import { call, put, takeLatest } from 'redux-saga/effects';
// import qs from "querystring";

// (TTS)LyLV 2020/04/08 Watcher START
export function* watchRouteAddressSearch() {
  yield takeLatest(SEARCH_ROUTE_ADDRESS, routeAddressSearch);
}
// (TTS)LyLV 2020/04/08 Watcher END

// (TTS)LyLV 2020/04/08 SAGA START
// saga worker sigin

export function* routeAddressSearch(action) {
  // console.log("auth sagas routeAddressSearch");
  yield put({ type: SEARCH_ROUTE_ADDRESS_PENDING, payload: action.payload });
  try {
    const response = yield call(executeSearch, action);
    yield put({
      type: SEARCH_ROUTE_ADDRESS_FULFILLED,
      payload: response
    });
  } catch (error) {
    // console.log("auth sagas routeAddressSearch reject");
    yield put({ type: SEARCH_ROUTE_ADDRESS_REJECTED, payload: error });
  }
}

async function executeSearch(action) {
  const params = {
    ...action.payload.searchKey,
    page: action.payload.page,
    pageSize: action.payload.pageSize
    // searchMode: action.payload.searchMode
  };
  const response = await httpClient.get('/RouteAddress/search', {
    params: params
  });
  return response;
}

// (TTS)LyLV 2020/04/08 SAGA END
