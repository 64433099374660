import api from "HttpClient/index";

export const createAddDriverPlan = payload => {
    return api.post(`/AddingTruckingPlan/create`, payload);
};

export const updateAddDriverPlan = payload => {
    return api.put(`/AddingTruckingPlan/update`, payload);
};

export const deleteAddDriverPlan = id => {
    return api.delete(`/AddingTruckingPlan/delete/${id}`);
};

export const getAddDriverPlanByID = id => {
    return api.get(`/AddingTruckingPlan/getbyId/${id}`);
};

export const getAllAddDriverPlan = () => {
    return api.get(`/AddingTruckingPlan/getAll`);
};

export const getAllAddDriverPlanByDriverPlanID = driverPlanID => {
    return api.get(
        `/AddingTruckingPlan/getByTruckingPlanId?truckingPlanId=${driverPlanID}`
    );
};

export const getAllTruckingFeeOfAddTrackingPlan = (payload, type) => {
    // if is type null get all trucking fee of driver plan
    // if is`t type null get all adding trucking fee of detail driver plan

    // let url =
    //     type === null || type === undefined ?
    //     `/AddingTruckingPlan/getAllTruckingFee` :
    //     `/AddingTruckingPlan/getAddingTruckingFee`;
    const url = `/AddingTruckingPlan/getAddingTruckingFee`;
    return api.post(url, payload);
};