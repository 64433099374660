import httpClient from 'HttpClient';
// define const actions
export const DELIVERY_DETAIL_REPORT_ACTIONS = {
  GET_DELIVERY_DETAIL_DATA: 'GET_DELIVERY_DETAIL_DATA',
  GET_DELIVERY_DETAIL_DATA_PENDING: 'GET_DELIVERY_DETAIL_DATA_PENDING',
  GET_DELIVERY_DETAIL_DATA_FULFILLED: 'GET_DELIVERY_DETAIL_DATA_FULFILLED',
  GET_DELIVERY_DETAIL_DATA_REJECTED: 'GET_DELIVERY_DETAIL_DATA_REJECTED',

  GET_CONSIMENT_DELIVERY_DETAIL: 'GET_CONSIMENT_DELIVERY_DETAIL',
  GET_CONSIMENT_DELIVERY_DETAIL_PENDING: 'GET_CONSIMENT_DELIVERY_DETAIL_PENDING',
  GET_CONSIMENT_DELIVERY_DETAIL_FULFILLED: 'GET_CONSIMENT_DELIVERY_DETAIL_FULFILLED',
  GET_CONSIMENT_DELIVERY_DETAIL_REJECTED: 'GET_CONSIMENT_DELIVERY_DETAIL_REJECTED'
};

// define actions
export const getDeliveryDetailDate = (consignmentId, date) => (dispatch) => {
  const params = {
    fromDate: date?.fromDate ?? '',
    toDate: date?.toDate ?? ''
  };
  return dispatch({
    type: DELIVERY_DETAIL_REPORT_ACTIONS.GET_DELIVERY_DETAIL_DATA,
    payload: httpClient.get(`DeliveryDetailReport/TruckingPlan/getDeliveryDetailList/${consignmentId}`, {
      params: params
    })
  });
};
export const getDeliveryDetailDateAlls = (params) => (dispatch) => {
  const consignmentId = params.idParams;
  const payload = {
    fromDate: params?.fromDate ?? '',
    toDate: params?.toDate ?? ''
  };
  return dispatch({
    // type: DELIVERY_DETAIL_REPORT_ACTIONS.GET_DELIVERY_DETAIL_DATA,
    payload: httpClient.get(`DeliveryDetailReport/TruckingPlan/getDeliveryDetailList/${consignmentId}`, {
      params: payload
    })
  });
};

export const getDeliveryDetailDateAll = (params) => (dispatch) => {
  return dispatch({
    // type: DELIVERY_DETAIL_REPORT_ACTIONS.GET_DELIVERY_DETAIL_DATA,
    payload: httpClient.get(`DeliveryDetailReport/TruckingPlan/getDeliveryDetailList/`, { params: params })
  });
};

export const getConsimentCodeDeliveryDetailReport = () => (dispatch) => {
  return dispatch({
    type: DELIVERY_DETAIL_REPORT_ACTIONS.GET_CONSIMENT_DELIVERY_DETAIL,
    payload: httpClient.get(`/sparepartinfo/getAll`)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};
