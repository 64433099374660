import {
    REPAIR_DETAILS
} from "actions/repair/repairInvoiceDetails";
import {REPAIR_ACTIONS} from 'actions/repair/repairManagement';
import {SETTLEMENT_REPAIR} from "actions/repair/settlementRepair"
import {
    put,
    takeEvery,
} from 'redux-saga/effects';
import {
    getRepairVoiceDetailById
} from "services/repair/repairInvoiceDetails";
import {getRepairingById} from "services/repair/repairManagement";

const ACTIONS = [
    REPAIR_DETAILS.CREATE_REPAIR_INVOICE_DETAILS_FULFILLED,
    REPAIR_DETAILS.CREATE_REPAIR_INVOICE_DETAILS_REJECTED,

    REPAIR_DETAILS.UPDATE_REPAIR_INVOICE_DETAILS_FULFILLED,
    REPAIR_DETAILS.UPDATE_REPAIR_INVOICE_DETAILS_REJECTED,

    REPAIR_DETAILS.DELETE_REPAIR_INVOICE_DETAILS_FULFILLED,
    REPAIR_DETAILS.DELETE_REPAIR_INVOICE_DETAILS_REJECTED,

    SETTLEMENT_REPAIR.CREATE_SETTLEMENT_REPAIR_FULFILLED,
    SETTLEMENT_REPAIR.UPDATE_SETTLEMENT_REPAIR_FULFILLED,
    SETTLEMENT_REPAIR.DELETE_SETTLEMENT_REPAIR_FULFILLED

];

function* putActionUpdateData(actions) {
    yield put({
        type: REPAIR_DETAILS.GET_REPAIR_INVOICE_DETAILS_BY_ID,
        payload: getRepairVoiceDetailById(actions.meta.RepairingId)
    })
}


function* putActiongGetDataRepair(actions) {
    yield put({
        type: REPAIR_ACTIONS.GET_REPAIR_BY_ID,
        payload: getRepairingById(actions.meta.RepairingId)
    })
}

export function* actionUpdateRepairDetailData() {
    yield takeEvery(ACTIONS, putActionUpdateData);
    yield takeEvery(ACTIONS, putActiongGetDataRepair);
}

