import { ROAD_FEE_ACTIONS } from "actions/roadFeeManagement";
import { getErrorMessage } from "commons/ReducerUtils";

const RoadFeeReducer = (state = {}, action) => {
  switch (action.type) {
    //get all
    case ROAD_FEE_ACTIONS.GET_ALL_ROAD_FEE_PENDING:
      return {
        ...state,
        isLoading: true,
      };

    case ROAD_FEE_ACTIONS.GET_ALL_ROAD_FEE_FULFILLED:
      let roadFee = {
        data: action.payload.data.data,
      };
      return {
        ...state,
        roadFee: roadFee,
        isLoading: false,
      };

    case ROAD_FEE_ACTIONS.GET_ALL_ROAD_FEE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: "Đã xảy ra lỗi",
      };

    // get routers per page
    case ROAD_FEE_ACTIONS.GET_ROAD_FEE_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true,
      };

    case ROAD_FEE_ACTIONS.GET_ROAD_FEE_PER_PAGE_FULFILLED:
      let roadFeePerPage = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false,
      };
      return {
        ...state,
        roadFeePerPage: roadFeePerPage,
        isLoading: false,
      };

    case ROAD_FEE_ACTIONS.GET_ROAD_FEE_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "error",
        isMsg: true,
        msg: "Đã xảy ra lỗi",
      };

    // get  by id
    case ROAD_FEE_ACTIONS.GET_ROAD_FEE_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
      };

    case ROAD_FEE_ACTIONS.GET_ROAD_FEE_BY_ID_FULFILLED:
      return {
        ...state,
        isLoading: false,
      };

    case ROAD_FEE_ACTIONS.GET_ROAD_FEE_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "error",
        isMsg: true,
        msg: "Đã xảy ra lỗi",
      };

    // create
    case ROAD_FEE_ACTIONS.CREATE_ROAD_FEE_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
      };

    case ROAD_FEE_ACTIONS.CREATE_ROAD_FEE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "success",
        isMsg: true,
        msg: "Tạo dữ liệu thành công",
      };

    case ROAD_FEE_ACTIONS.CREATE_ROAD_FEE_REJECTED: {
      let alertMessage = "Tạo dữ liệu thất bại";
      if (action.payload.response.data.status === 400) {
        alertMessage = getErrorMessage(action);
      }
      return {
        ...state,
        isLoading: false,
        typeMessage: "error",
        isMsg: true,
        msg: alertMessage,
      };
    }
    // update routers
    case ROAD_FEE_ACTIONS.UPDATE_ROAD_FEE_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
      };

    case ROAD_FEE_ACTIONS.UPDATE_ROAD_FEE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: "Cập nhật dữ liệu thành công",
        typeMessage: "success",
      };

    case ROAD_FEE_ACTIONS.UPDATE_ROAD_FEE_REJECTED: {
      let alertMessage = "Cập nhật dữ liệu thất bại";
      if (action.payload.response.data.status === 400) {
        alertMessage = getErrorMessage(action);
      }
      return {
        ...state,
        isLoading: false,
        typeMessage: "error",
        isMsg: true,
        msg: alertMessage
      };
    }

    // delete routers
    case ROAD_FEE_ACTIONS.DELETE_ROAD_FEE_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false,
      };

    case ROAD_FEE_ACTIONS.DELETE_ROAD_FEE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: "success",
        isMsg: true,
        msg: "Xóa dữ liệu thành công",
      };

    case ROAD_FEE_ACTIONS.DELETE_ROAD_FEE_REJECTED:

      return {
        ...state,
        isLoading: false,
        typeMessage: "error",
        isMsg: true,
        msg: "Đã xảy ra lỗi",
      };

    //Search
    case ROAD_FEE_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        isLoading: true,
        isMsg: false,
      };
    }
    case ROAD_FEE_ACTIONS.SEARCH_DATA_FULFILLED: {
      let roadFeePerPage = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false,
      };
      return {
        ...state,
        isLoading: false,
        roadFeePerPage: roadFeePerPage,
        pagination: action.payload.data.pagination,
        isSearch: true,
      };
    }
    case ROAD_FEE_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        isLoading: false,
        isShowMessage: true,
        typeMessage: "error",
        msg: "Không thể lấy được dữ liệu !",
      };
    }

    default:
      return state;
  }
};

export default RoadFeeReducer;
