import { IMPORT_SPARE_PART_ACTIONS } from 'actions/repair/importSparePartManagement';
import { put, takeEvery } from 'redux-saga/effects';
import { getImportSparePartPerPage } from 'services/repair/importSparePartManagement';

function* putActionUpdateData(action) {
  const pageSize = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE);
  yield put({
    type: IMPORT_SPARE_PART_ACTIONS.GET_DATA_PER_PAGE,
    payload: getImportSparePartPerPage(1, pageSize, action.meta.IsReceived)
  });
}

export function* actionUpdateImportData() {
  yield takeEvery(
    [
      // IMPORT_SPARE_PART_ACTIONS.UPDATE_DATA_FULFILLED,
      // IMPORT_SPARE_PART_ACTIONS.UPDATE_DATA_REJECTED,
      // IMPORT_SPARE_PART_ACTIONS.UPDATE_DATA_FULFILLED,
      // IMPORT_SPARE_PART_ACTIONS.UPDATE_DATA_REJECTED,
      // IMPORT_SPARE_PART_ACTIONS.CREATE_DATA_FULFILLED,
      // IMPORT_SPARE_PART_ACTIONS.CREATE_DATA_REJECTED,
      // IMPORT_SPARE_PART_ACTIONS.DELETE_DATA_FULFILLED,
      // IMPORT_SPARE_PART_ACTIONS.DELETE_DATA_REJECTED,
      // IMPORT_SPARE_PART_ACTIONS.CREATE_NEW_APPROVED_FULFILLED,
      // IMPORT_SPARE_PART_ACTIONS.CREATE_NEW_APPROVED_REJECTED,
      // IMPORT_SPARE_PART_ACTIONS.UPDATE_APPROVAL_FULFILLED,
      // IMPORT_SPARE_PART_ACTIONS.UPDATE_APPROVAL_REJECTED
    ],
    putActionUpdateData
  );
}
