import { TRANSPORT_COMPANY_MANAGEMENT_ACTIONS } from "actions/transportCompanyManagement";

const INIT_STATE = {
  listPerPage: [],
  loading: false,
  typeMessage: "",
  message: "",
  isShowMessage: false,
  pagination: {},
  isCloseModal: false,
  isSearch: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.GET_DATA_COMPANY_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.GET_DATA_COMPANY_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false,
      };
    }
    case TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.GET_DATA_COMPANY_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        isCloseModal: false,
        message: "Không thể lấy được dữ liệu !",
      };
    }

    case TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.CREATE_DATA_COMPANY_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
      };
    }
    case TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.CREATE_DATA_COMPANY_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: "success",
        message: "Thêm mới dữ liệu thành công !",
        isShowMessage: true,
        isCloseModal: true,
      };
    }
    case TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.CREATE_DATA_COMPANY_REJECTED: {
      const messageError = action.payload.response.data.message + "";
      let check = action.payload.response.data.status === "400";
      return {
        ...state,
        loading: false,
        message: check ? messageError : "Thêm dữ liệu thất bại",
        isShowMessage: true,
        typeMessage: "error",
        isCloseModal: false,
      };
    }

    case TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.DELETE_INFO_COMPANY_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.DELETE_INFO_COMPANY_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: "success",
        message: "Xóa dữ liệu thành công",
        isShowMessage: true,
      };
    }
    case TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.DELETE_INFO_COMPANY_REJECTED: {
      return {
        ...state,
        loading: false,
        message: "Xóa dữ liệu thất bại",
        isShowMessage: true,
        typeMessage: "error",
      };
    }

    case TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.UPDATE_INFO_COMPANY_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
      };
    }
    case TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.UPDATE_INFO_COMPANY_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: "success",
        message: "Cập nhật dữ liệu thành công",
        isShowMessage: true,
        isCloseModal: true,
      };
    }
    case TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.UPDATE_INFO_COMPANY_REJECTED: {
      const messageError = action.payload.response.data.message + "";
      let check = action.payload.response.data.status === "400";
      return {
        ...state,
        loading: false,
        message: check ? messageError : "Cập nhật dữ liệu thất bại",
        isShowMessage: true,
        typeMessage: "error",
        isCloseModal: false,
      };
    }

    case "UPDATE_SHOW_ALERT": {
      return {
        ...state,
        isShowMessage: false,
      };
    }

    case TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.SEARCH_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: true,
      };
    }
    case TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }

    default:
      return state;
  }
};
