import httpClient from 'HttpClient';

// define const actions
export const FEE_CATEGORIES_ACTIONS = {
  GET_ALL_FEES: 'GET_ALL_FEES',
  GET_ALL_FEES_PENDING: 'GET_ALL_FEES_PENDING',
  GET_ALL_FEES_FULFILLED: 'GET_ALL_FEES_FULFILLED',
  GET_ALL_FEES_REJECTED: 'GET_ALL_FEES_REJECTED',

  GET_FEES_BY_TYPE: 'GET_FEES_BY_TYPE',
  GET_FEES_BY_TYPE_PENDING: 'GET_FEES_BY_TYPE_PENDING',
  GET_FEES_BY_TYPE_FULFILLED: 'GET_FEES_BY_TYPE_FULFILLED',
  GET_FEES_BY_TYPE_REJECTED: 'GET_FEES_BY_TYPE_REJECTED'
};

// define actions

export const actionGetAllFees = () => (dispatch) => {
  return dispatch({
    type: FEE_CATEGORIES_ACTIONS.GET_ALL_FEES,
    payload: httpClient.get(`/CategoryFee/getByTypeId/3`)
  });
};

export const actionGetFeesByType = (id) => (dispatch) => {
  return dispatch({
    type: FEE_CATEGORIES_ACTIONS.GET_FEES_BY_TYPE,
    payload: httpClient.get(`/CategoryFee/getByTypeId/${id}`)
  });
};
