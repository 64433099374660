import { PORT_DEPOT_ACTIONS } from 'actions/portdepot';
import {
  ORDER_MNG_IN_COMMON_OTHER_ADDRESSES,
  ORDER_MNG_IN_CONTAINER_YARD_TYPE,
  ORDER_MNG_IN_DESTINATION_TYPE,
  ORDER_MNG_IN_SAVE_GOOD_TYPE
} from 'const/common';

const INIT_STATE = {
  portDepot: { data: [] },
  portDepot1: { data: [] },
  portDepot2: { data: [] },
  destinationData: { data: [] },
  otherAddresses: []
};

const portDepotReducer = (state = INIT_STATE, action) => {
  let portDepot = {};
  switch (action.type) {
    // get all port depot
    case PORT_DEPOT_ACTIONS.GET_ALL_PORT_DEPOT_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case PORT_DEPOT_ACTIONS.GET_ALL_PORT_DEPOT_FULFILLED:
      portDepot = {
        data: action.payload.data.data
      };
      return {
        ...state,
        portDepot: portDepot,
        isLoading: false
      };

    case PORT_DEPOT_ACTIONS.GET_ALL_PORT_DEPOT_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // get port deport by custom branch
    case PORT_DEPOT_ACTIONS.GET_PORT_DEPOT_CUSTOM_BRANCH_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case PORT_DEPOT_ACTIONS.GET_PORT_DEPOT_CUSTOM_BRANCH_FULFILLED:
      portDepot = {
        data: action.payload.data.data
      };
      let result = {};
      if (action.meta === ORDER_MNG_IN_SAVE_GOOD_TYPE) {
        result = {
          portDepot: portDepot
        };
      } else if (action.meta === ORDER_MNG_IN_CONTAINER_YARD_TYPE) {
        result = {
          portDepot1: portDepot
        };
      } else if (action.meta === ORDER_MNG_IN_DESTINATION_TYPE) {
        result = {
          portDepot2: portDepot
        };
      } else if (action.meta === ORDER_MNG_IN_COMMON_OTHER_ADDRESSES) {
        result = {
          otherAddresses: action.payload.data.data
        };
      } else {
        result = {
          portDepot: portDepot,
          portDepot1: portDepot
        };
      }
      return {
        ...state,
        ...result,
        isLoading: false
      };

    case PORT_DEPOT_ACTIONS.GET_PORT_DEPOT_CUSTOM_BRANCH_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // get port deport of custom branch Id
    case PORT_DEPOT_ACTIONS.GET_PORT_OF_CUSTOM_BRANCH_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case PORT_DEPOT_ACTIONS.GET_PORT_OF_CUSTOM_BRANCH_FULFILLED:
      return {
        ...state,
        portDepot: action.payload.data.data,
        isLoading: false
      };

    case PORT_DEPOT_ACTIONS.GET_PORT_OF_CUSTOM_BRANCH_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    default:
      return state;
  }
};

export default portDepotReducer;
