import httpClient from 'HttpClient';
// define const actions
export const STANDARD_OIL_MANAGEMENT_ACTIONS = {
  GET_STANDARD_OIL_MANAGEMENT_PER_PAGE: 'GET_STANDARD_OIL_MANAGEMENT_PER_PAGE',
  GET_STANDARD_OIL_MANAGEMENT_PER_PAGE_PENDING: 'GET_STANDARD_OIL_MANAGEMENT_PER_PAGE_PENDING',
  GET_STANDARD_OIL_MANAGEMENT_PER_PAGE_FULFILLED: 'GET_STANDARD_OIL_MANAGEMENT_PER_PAGE_FULFILLED',
  GET_STANDARD_OIL_MANAGEMENT_PER_PAGE_REJECTED: 'GET_STANDARD_OIL_MANAGEMENT_PER_PAGE_REJECTED',

  UPDATE_STANDARD_OIL_MANAGEMENT: 'UPDATE_STANDARD_OIL_MANAGEMENT',
  UPDATE_STANDARD_OIL_MANAGEMENT_PENDING: 'UPDATE_STANDARD_OIL_MANAGEMENT_PENDING',
  UPDATE_STANDARD_OIL_MANAGEMENT_FULFILLED: 'UPDATE_STANDARD_OIL_MANAGEMENT_FULFILLED',
  UPDATE_STANDARD_OIL_MANAGEMENT_REJECTED: 'UPDATE_STANDARD_OIL_MANAGEMENT_REJECTED',

  UPDATE_STANDARD_OIL_MANAGEMENT_V2: 'UPDATE_STANDARD_OIL_MANAGEMENT_V2',
  UPDATE_STANDARD_OIL_MANAGEMENT_V2_PENDING: 'UPDATE_STANDARD_OIL_MANAGEMENT_V2_PENDING',
  UPDATE_STANDARD_OIL_MANAGEMENT_V2_FULFILLED: 'UPDATE_STANDARD_OIL_MANAGEMENT_V2_FULFILLED',
  UPDATE_STANDARD_OIL_MANAGEMENT_V2_REJECTED: 'UPDATE_STANDARD_OIL_MANAGEMENT_V2_REJECTED'
};

// define actions
export const getDataPerPage = (params) => (dispatch) => {
  return dispatch({
    type: STANDARD_OIL_MANAGEMENT_ACTIONS.GET_STANDARD_OIL_MANAGEMENT_PER_PAGE,
    payload: httpClient.get(`/TruckingStandardOil/Search/date`, { params })
  });
};
export const getDataPerPageAll = (params) => (dispatch) => {
  return dispatch({
    // type: STANDARD_OIL_MANAGEMENT_ACTIONS.GET_STANDARD_OIL_MANAGEMENT_PER_PAGE,
    payload: httpClient.get(`/TruckingStandardOil/Search/date`, { params })
  });
};

export const updateStandardOil = (data, displayStatus) => (dispatch) => {
  return dispatch({
    type: STANDARD_OIL_MANAGEMENT_ACTIONS.UPDATE_STANDARD_OIL_MANAGEMENT,
    payload: httpClient.put(`/TruckingStandardOil/update?standardOilDisplayStatus=${displayStatus}`, data)
  });
};

export const updateStandardOilv2 = (data, displayStatus) => (dispatch) => {
  return dispatch({
    type: STANDARD_OIL_MANAGEMENT_ACTIONS.UPDATE_STANDARD_OIL_MANAGEMENT_V2,
    payload: httpClient.put(`/TruckingStandardOil/updateV2?standardOilDisplayStatus=${displayStatus}`, data)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};
