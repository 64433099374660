import {
  PAYMENT_ON_BEHAFT_ACTIONS
} from "actions/paymentonbehaft";
import {
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  getPaymentOnBehaftPerPage,
} from "services/paymentonbehaft";

function* putActionUpdateData() {
  const pageSize = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE);
  yield put({
    type: PAYMENT_ON_BEHAFT_ACTIONS.GET_PAYMENT_ON_BEHAFT_PER_PAGE,
    payload: getPaymentOnBehaftPerPage(1, pageSize)
  })
}

export function* actionUpdatePaymentOnBehaftData() {
  yield takeEvery([PAYMENT_ON_BEHAFT_ACTIONS.UPDATE_PAYMENT_ON_BEHAFT_FULFILLED, PAYMENT_ON_BEHAFT_ACTIONS.CREATE_PAYMENT_ON_BEHAFT_FULFILLED], putActionUpdateData);
}
