import {
  createListContainer,
  createTransportationPlan,
  createTransportationPlanList,
  deleteTransportationPlanList,
  getAllTransportationPlan,
  getGetContSerialListByConsignmentID,
  getImageConsignment,
  getTranportationPlanByID,
  getTranportationPlanPerPage,
  getTransportationPlanListByConsignmentID,
  getTransportationPlanListByConsignmentIDPerPage,
  searchTranportationPlan,
  updateContDetail,
  updateTransportationPlanList
} from 'services/transportationplan';

// define const actions
export const TRANSPORTATION_PLAN_ACTIONS = {
  GET_TRANSPORTATION_PLAN_PER_PAGE: 'GET_TRANSPORTATION_PLAN_PER_PAGE',
  GET_TRANSPORTATION_PLAN_PER_PAGE_PENDING: 'GET_TRANSPORTATION_PLAN_PER_PAGE_PENDING',
  GET_TRANSPORTATION_PLAN_PER_PAGE_FULFILLED: 'GET_TRANSPORTATION_PLAN_PER_PAGE_FULFILLED',
  GET_TRANSPORTATION_PLAN_PER_PAGE_REJECTED: 'GET_TRANSPORTATION_PLAN_PER_PAGE_REJECTED',

  GET_TRANSPORTATION_PLAN_BY_ID: 'GET_TRANSPORTATION_PLAN_BY_ID',
  GET_TRANSPORTATION_PLAN_BY_ID_PENDING: 'GET_TRANSPORTATION_PLAN_BY_ID_PENDING',
  GET_TRANSPORTATION_PLAN_BY_ID_FULFILLED: 'GET_TRANSPORTATION_PLAN_BY_ID_FULFILLED',
  GET_TRANSPORTATION_PLAN_BY_ID_REJECTED: 'GET_TRANSPORTATION_PLAN_BY_ID_REJECTED',

  SEARCH_TRANSPORTATION_PLAN_PER_PAGE: 'SEARCH_TRANSPORTATION_PLAN_PER_PAGE',
  SEARCH_TRANSPORTATION_PLAN_PER_PAGE_PENDING: 'SEARCH_TRANSPORTATION_PLAN_PER_PAGE_PENDING',
  SEARCH_TRANSPORTATION_PLAN_PER_PAGE_FULFILLED: 'SEARCH_TRANSPORTATION_PLAN_PER_PAGE_FULFILLED',
  SEARCH_TRANSPORTATION_PLAN_PER_PAGE_REJECTED: 'SEARCH_TRANSPORTATION_PLAN_PER_PAGE_REJECTED',

  GET_ALL_TRANSPORTATION_PLAN: 'GET_ALL_TRANSPORTATION_PLAN',
  GET_ALL_TRANSPORTATION_PLAN_PENDING: 'GET_ALL_TRANSPORTATION_PLAN_PENDING',
  GET_ALL_TRANSPORTATION_PLAN_FULFILLED: 'GET_ALL_TRANSPORTATION_PLAN_FULFILLED',
  GET_ALL_TRANSPORTATION_PLAN_REJECTED: 'GET_ALL_TRANSPORTATION_PLAN_REJECTED',

  GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID: 'GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID',
  GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_PENDING: 'GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_PENDING',
  GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_FULFILLED: 'GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_FULFILLED',
  GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_REJECTED: 'GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_REJECTED',

  GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_PER_PAGE: 'GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_PER_PAGE',
  GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_PER_PAGE_PENDING:
    'GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_PER_PAGE_PENDING',
  GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_PER_PAGE_FULFILLED:
    'GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_PER_PAGE_FULFILLED',
  GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_PER_PAGE_REJECTED:
    'GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_PER_PAGE_REJECTED',

  CREATE_TRANSPORTATION_PLAN: 'CREATE_TRANSPORTATION_PLAN',
  CREATE_TRANSPORTATION_PLAN_PENDING: 'CREATE_TRANSPORTATION_PLAN_PENDING',
  CREATE_TRANSPORTATION_PLAN_FULFILLED: 'CREATE_TRANSPORTATION_PLAN_FULFILLED',
  CREATE_TRANSPORTATION_PLAN_REJECTED: 'CREATE_TRANSPORTATION_PLAN_REJECTED',

  CREATE_CONTAINER_LIST: 'CREATE_CONTAINER_LIST',

  CREATE_TRANSPORTATION_PLAN_LIST: 'CREATE_TRANSPORTATION_PLAN_LIST',
  CREATE_TRANSPORTATION_PLAN_LIST_PENDING: 'CREATE_TRANSPORTATION_PLAN_LIST_PENDING',
  CREATE_TRANSPORTATION_PLAN_LIST_FULFILLED: 'CREATE_TRANSPORTATION_PLAN_LIST_FULFILLED',
  CREATE_TRANSPORTATION_PLAN_LIST_REJECTED: 'CREATE_TRANSPORTATION_PLAN_LIST_REJECTED',

  UPDATE_TRANSPORTATION_PLAN_LIST: 'UPDATE_TRANSPORTATION_PLAN_LIST',
  UPDATE_TRANSPORTATION_PLAN_LIST_PENDING: 'UPDATE_TRANSPORTATION_PLAN_LIST_PENDING',
  UPDATE_TRANSPORTATION_PLAN_LIST_FULFILLED: 'UPDATE_TRANSPORTATION_PLAN_LIST_FULFILLED',
  UPDATE_TRANSPORTATION_PLAN_LIST_REJECTED: 'UPDATE_TRANSPORTATION_PLAN_LIST_REJECTED',

  DELETE_TRANSPORTATION_PLAN_LIST: 'DELETE_TRANSPORTATION_PLAN_LIST',
  DELETE_TRANSPORTATION_PLAN_LIST_PENDING: 'DELETE_TRANSPORTATION_PLAN_LIST_PENDING',
  DELETE_TRANSPORTATION_PLAN_LIST_FULFILLED: 'DELETE_TRANSPORTATION_PLAN_LIST_FULFILLED',
  DELETE_TRANSPORTATION_PLAN_LIST_REJECTED: 'DELETE_TRANSPORTATION_PLAN_LIST_REJECTED',

  UPDATE_CONT_DETAIL: 'UPDATE_CONT_DETAIL',
  UPDATE_CONT_DETAIL_PENDING: 'UPDATE_CONT_DETAIL_PENDING',
  UPDATE_CONT_DETAIL_FULFILLED: 'UPDATE_CONT_DETAIL_FULFILLED',
  UPDATE_CONT_DETAIL_REJECTED: 'UPDATE_CONT_DETAIL_REJECTED',

  GET_IMAGE_CONSIGNMENT_BY_ID: 'GET_IMAGE_CONSIGNMENT_BY_ID',
  GET_IMAGE_CONSIGNMENT_BY_ID_PENDING: 'GET_IMAGE_CONSIGNMENT_BY_ID_PENDING',
  GET_IMAGE_CONSIGNMENT_BY_ID_FULFILLED: 'GET_IMAGE_CONSIGNMENT_BY_ID_FULFILLED',
  GET_IMAGE_CONSIGNMENT_BY_ID_REJECTED: 'GET_IMAGE_CONSIGNMENT_BY_ID_REJECTED',

  GET_CONT_SERIAL_BY_CONSIGNMENTID: 'GET_CONT_SERIAL_BY_CONSIGNMENTID',
  GET_CONT_SERIAL_BY_CONSIGNMENTID_PENDING: 'GET_CONT_SERIAL_BY_CONSIGNMENTID_PENDING',
  GET_CONT_SERIAL_BY_CONSIGNMENTID_FULFILLED: 'GET_CONT_SERIAL_BY_CONSIGNMENTID_FULFILLED',
  GET_CONT_SERIAL_BY_CONSIGNMENTID_REJECTED: 'GET_CONT_SERIAL_BY_CONSIGNMENTID_REJECTED'
};

// define actions
export const actionGetTranportationPlanPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: TRANSPORTATION_PLAN_ACTIONS.GET_TRANSPORTATION_PLAN_PER_PAGE,
    payload: getTranportationPlanPerPage(pageNo, pageSize)
  });
};

export const actionGetTranportationPlanByID = (id) => (dispatch) => {
  return dispatch({
    type: TRANSPORTATION_PLAN_ACTIONS.GET_TRANSPORTATION_PLAN_BY_ID,
    payload: getTranportationPlanByID(id)
  });
};

export const actionSearchTranportationPlan = (payload) => (dispatch) => {
  return dispatch({
    type: TRANSPORTATION_PLAN_ACTIONS.SEARCH_TRANSPORTATION_PLAN_PER_PAGE,
    payload: searchTranportationPlan(payload)
  });
};

export const actionSearchTranportationPlanAll = (payload) => (dispatch) => {
  return dispatch({
    payload: searchTranportationPlan(payload)
  });
};

export const actionGetAllTransportationPlan = () => (dispatch) => {
  return dispatch({
    type: TRANSPORTATION_PLAN_ACTIONS.GET_ALL_TRANSPORTATION_PLAN,
    payload: getAllTransportationPlan()
  });
};

export const actionGetTransportationPlanListByConsignmentID = (id) => (dispatch) => {
  return dispatch({
    type: TRANSPORTATION_PLAN_ACTIONS.GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID,
    payload: getTransportationPlanListByConsignmentID(id)
  });
};

export const actionGetTransportationPlanListByConsignmentIDPerPage = (id, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: TRANSPORTATION_PLAN_ACTIONS.GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_PER_PAGE,
    payload: getTransportationPlanListByConsignmentIDPerPage(id, pageNo, pageSize)
  });
};

export const actionCreateTransportationPlan = (payload) => (dispatch) => {
  let consignmentID = payload.ConsignmentId;
  const { isSaveAndContinue = null } = payload;
  return dispatch({
    type: TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN,
    payload: createTransportationPlan(payload),
    meta: {
      consignmentID: consignmentID,
      isSaveAndContinue
    }
  });
};

export const actionCreateListContainer = (payload) => (dispatch) => {
  return dispatch({
    type: TRANSPORTATION_PLAN_ACTIONS.CREATE_CONTAINER_LIST,
    payload: createListContainer(payload)
  });
};

export const actionCreateTransportationPlanList = (payload) => (dispatch) => {
  let consignmentID = payload.ConsignmentId;
  return dispatch({
    type: TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_LIST,
    payload: createTransportationPlanList(payload),
    meta: {
      consignmentID: consignmentID
    }
  });
};

export const actionUpdateTransportationPlanList = (payload) => (dispatch) => {
  let consignmentID = payload.ConsignmentId;
  return dispatch({
    type: TRANSPORTATION_PLAN_ACTIONS.UPDATE_TRANSPORTATION_PLAN_LIST,
    payload: updateTransportationPlanList(payload),
    meta: {
      consignmentID: consignmentID
    }
  });
};

export const actionDeleteTransportationPlanList = (id, consignmentID) => (dispatch) => {
  return dispatch({
    type: TRANSPORTATION_PLAN_ACTIONS.DELETE_TRANSPORTATION_PLAN_LIST,
    payload: deleteTransportationPlanList(id),
    meta: {
      consignmentID: consignmentID
    }
  });
};

export const actionUpdateContDetail = (payload, searchPayload) => (dispatch) => {
  return dispatch({
    type: TRANSPORTATION_PLAN_ACTIONS.UPDATE_CONT_DETAIL,
    payload: updateContDetail(payload),
    meta: searchPayload
  });
};

export const actionGetImageConsignment = (id) => (dispatch) => {
  return dispatch({
    type: TRANSPORTATION_PLAN_ACTIONS.GET_IMAGE_CONSIGNMENT_BY_ID,
    payload: getImageConsignment(id)
  });
};

export const actionGetContSerialListByConsignmentID = (id) => (dispatch) => {
  return dispatch({
    type: TRANSPORTATION_PLAN_ACTIONS.GET_CONT_SERIAL_BY_CONSIGNMENTID,
    payload: getGetContSerialListByConsignmentID(id)
  });
};
