import httpClient from 'HttpClient';
import { deleteItem } from 'services/oilNorm';
// define const actions
export const OIL_NORM_ACTIONS = {
  GET_OIL_NORM_PER_PAGE: 'GET_OIL_NORM_PER_PAGE',
  GET_OIL_NORM_PER_PAGE_PENDING: 'GET_OIL_NORM_PER_PAGE_PENDING',
  GET_OIL_NORM_PER_PAGE_FULFILLED: 'GET_OIL_NORM_PER_PAGE_FULFILLED',
  GET_OIL_NORM_PER_PAGE_REJECTED: 'GET_OIL_NORM_PER_PAGE_REJECTED',

  GET_OIL_NORM_BY_ID: 'GET_OIL_NORM_BY_ID',
  GET_OIL_NORM_BY_ID_PENDING: 'GET_OIL_NORM_BY_ID_PENDING',
  GET_OIL_NORM_BY_ID_FULFILLED: 'GET_OIL_NORM_BY_ID_FULFILLED',
  GET_OIL_NORM_BY_ID_REJECTED: 'GET_OIL_NORM_BY_ID_REJECTED',

  CREATE_OIL_NORM: 'CREATE_OIL_NORM',
  CREATE_OIL_NORM_PENDING: 'CREATE_OIL_NORM_PENDING',
  CREATE_OIL_NORM_FULFILLED: 'CREATE_OIL_NORM_FULFILLED',
  CREATE_OIL_NORM_REJECTED: 'CREATE_OIL_NORM_REJECTED',

  UPDATE_OIL_NORM: 'UPDATE_OIL_NORM',
  UPDATE_OIL_NORM_PENDING: 'UPDATE_OIL_NORM_PENDING',
  UPDATE_OIL_NORM_FULFILLED: 'UPDATE_OIL_NORM_FULFILLED',
  UPDATE_OIL_NORM_REJECTED: 'UPDATE_OIL_NORM_REJECTED',

  DELETE_OIL_NORM: 'DELETE_OIL_NORM',
  DELETE_OIL_NORM_PENDING: 'DELETE_OIL_NORM_PENDING',
  DELETE_OIL_NORM_FULFILLED: 'DELETE_OIL_NORM_FULFILLED',
  DELETE_OIL_NORM_REJECTED: 'DELETE_OIL_NORM_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  GET_GOOD_TYPE: 'GET_GOOD_TYPE',
  GET_GOOD_TYPE_PENDING: 'GET_GOOD_TYPE_PENDING',
  GET_GOOD_TYPE_FULFILLED: 'GET_GOOD_TYPE_FULFILLED',
  GET_GOOD_TYPE_REJECTED: 'GET_GOOD_TYPE_REJECTED',

  GET_ROUTES: 'GET_ROUTES',
  GET_ROUTES_PENDING: 'GET_ROUTES_PENDING',
  GET_ROUTES_FULFILLED: 'GET_ROUTES_FULFILLED',
  GET_ROUTES_REJECTED: 'GET_ROUTES_REJECTED',

  GET_CARS: 'GET_CARS',
  GET_CARS_PENDING: 'GET_CARS_PENDING',
  GET_CARS_FULFILLED: 'GET_CARS_FULFILLED',
  GET_CARS_REJECTED: 'GET_CARS_REJECTED',

  GET_TRUCKING_TYPE_ALL: 'GET_TRUCKING_TYPE_ALL',
  GET_TRUCKING_TYPE_ALL_PENDING: 'GET_TRUCKING_TYPE_ALL_PENDING',
  GET_TRUCKING_TYPE_ALL_FULFILLED: 'GET_TRUCKING_TYPE_ALL_FULFILLED',
  GET_TRUCKING_TYPE_ALL_REJECTED: 'GET_TRUCKING_TYPE_ALL_REJECTED',

  ACTION_GET_SETTING_INFO: 'ACTION_GET_SETTING_INFO',
  ACTION_GET_SETTING_INFO_PENDING: 'ACTION_GET_SETTING_INFO_PENDING',
  ACTION_GET_SETTING_INFO_FULFILLED: 'ACTION_GET_SETTING_INFO_FULFILLED',
  ACTION_GET_SETTING_INFO_REJECTED: 'ACTION_GET_SETTING_INFO_REJECTED'
};

// define actions
export const actionGetOilNormPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: OIL_NORM_ACTIONS.GET_OIL_NORM_PER_PAGE,
    payload: httpClient.get(`/standardoilInfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`)
  });
};
// export const actionGetOilNormPerAll = (pageNo, pageSize) => (dispatch) => {
//   return dispatch({
//     payload: httpClient.get(`/standardoilInfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`)
//   });
// };

export const actionGetOilNormByID = (id) => (dispatch) => {
  return dispatch({
    type: OIL_NORM_ACTIONS.GET_OIL_NORM_BY_ID,
    payload: httpClient.get(`/standardoilInfo/getById/${id}`)
  });
};

export const actionCreateOilNorm = (payload) => (dispatch) => {
  return dispatch({
    type: OIL_NORM_ACTIONS.CREATE_OIL_NORM,
    payload: httpClient.post(`/standardoilInfo/create`, payload)
  });
};

export const actionUpdateOilNorm = (payload) => (dispatch) => {
  return dispatch({
    type: OIL_NORM_ACTIONS.UPDATE_OIL_NORM,
    payload: httpClient.put(`/standardoilInfo/update`, payload)
  });
};

export const actionDeleteOilNorm = (id) => (dispatch) => {
  return dispatch({
    type: OIL_NORM_ACTIONS.DELETE_OIL_NORM,
    payload: deleteItem(id)
  });
};

export const actionGetRoutes = () => (dispatch) => {
  return dispatch({
    type: OIL_NORM_ACTIONS.GET_ROUTES,
    payload: httpClient.get(`/routeinfo/getAll`)
  });
};

export const actionGetGoodType = () => (dispatch) => {
  return dispatch({
    type: OIL_NORM_ACTIONS.GET_GOOD_TYPE,
    payload: httpClient.get(`/goodsinfo/getAll`)
  });
};

export const actionGetCars = () => (dispatch) => {
  return dispatch({
    type: OIL_NORM_ACTIONS.GET_CARS,
    payload: httpClient.get(`/tractortrailerinfo/getAll`)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const actionSearchOilNorm = (params, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: OIL_NORM_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/standardoilInfo/search?Page=${pageNo}&PageSize=${pageSize}`, { params })
  });
};
export const actionSearchOilNormAll = (params, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    payload: httpClient.get(`/standardoilInfo/search`, { params })
  });
};

export const actionGetTruckingType = () => (dispatch) => {
  return dispatch({
    type: OIL_NORM_ACTIONS.GET_TRUCKING_TYPE_ALL,
    payload: httpClient.get(`/truckingtypeinfo/getAll`)
  });
};

export const actionGetSettingInfoByGroupId = (groupId) => (dispatch) => {
  return dispatch({
    type: OIL_NORM_ACTIONS.ACTION_GET_SETTING_INFO,
    payload: httpClient.get(`/OptionSetting/getByGroupId/${groupId}`)
  });
};
