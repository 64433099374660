import httpClient from 'HttpClient';

export const CUSTOMER_OUTPUT = {
  GET_REVENUE_BY_CAR_REPORT: 'GET_REVENUE_BY_CAR_REPORT',
  GET_REVENUE_BY_CAR_REPORT_PENDING: 'GET_REVENUE_BY_CAR_REPORT_PENDING',
  GET_REVENUE_BY_CAR_REPORT_FULFILLED: 'GET_REVENUE_BY_CAR_REPORT_FULFILLED',
  GET_REVENUE_BY_CAR_REPORT_REJECTED: 'GET_REVENUE_BY_CAR_REPORT_REJECTED'
};

export const actionGetCustomerOutput = (params) => (dispatch) => {
  return dispatch({
    type: CUSTOMER_OUTPUT.GET_REVENUE_BY_CAR_REPORT,
    payload: httpClient.get(`RevenueByCustomer/AOEReport`, { params })
  });
};

export const actionGetCustomerOutputAll = (params) => (dispatch) => {
  return dispatch({
    // type: CUSTOMER_OUTPUT.GET_REVENUE_BY_CAR_REPORT,
    payload: httpClient.get(`RevenueByCustomer/AOEReport`, { params })
  });
};
