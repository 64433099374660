import { ADVANCE_MANAGEMENT } from 'actions/accounting/advanceManagement';
import { getErrorMessage } from 'commons/ReducerUtils';
import { MSG_ADD_FAILURE, MSG_DELETE_FAILURE, MSG_EDIT_FAILURE } from 'const/common';

const INIT_STATE = {
  reports: [],
  pagination: { pageNo: 1, pageSize: process.env.REACT_APP_DEFAULT_PAGE_SIZE, totalRecord: 0 },
  loading: false,
  showMessage: false,
  alertMessage: '',
  messageType: '',
  consignmentsList: [],
  AdvanceReportPermission: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADVANCE_MANAGEMENT.CREATE_ADVANCE_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case ADVANCE_MANAGEMENT.CREATE_ADVANCE_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        isCloseModal: false,
        alertMessage: 'Thêm mới dữ liệu thành công!'
      };
    }
    case ADVANCE_MANAGEMENT.CREATE_ADVANCE_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: getErrorMessage(action, MSG_ADD_FAILURE),
        isCloseModal: false
      };
    }

    case ADVANCE_MANAGEMENT.UPDATE_ADVANCE_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case ADVANCE_MANAGEMENT.UPDATE_ADVANCE_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        isCloseModal: false,
        alertMessage: 'Cập nhật dữ liệu thành công!'
      };
    }
    case ADVANCE_MANAGEMENT.UPDATE_ADVANCE_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: getErrorMessage(action, MSG_EDIT_FAILURE),
        isCloseModal: false
      };
    }

    case ADVANCE_MANAGEMENT.DELETE_REPORT_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case ADVANCE_MANAGEMENT.DELETE_REPORT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        isCloseModal: false,
        alertMessage: 'Xoá dữ liệu thành công!'
      };
    }
    case ADVANCE_MANAGEMENT.DELETE_REPORT_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: getErrorMessage(action, MSG_DELETE_FAILURE),
        isCloseModal: false
      };
    }

    case ADVANCE_MANAGEMENT.DELETE_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case ADVANCE_MANAGEMENT.DELETE_PAYMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        isCloseModal: false,
        alertMessage: 'Xoá dữ liệu thành công!'
      };
    }
    case ADVANCE_MANAGEMENT.DELETE_PAYMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: getErrorMessage(action, MSG_DELETE_FAILURE),
        isCloseModal: false
      };
    }

    case ADVANCE_MANAGEMENT.CONFIRM_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case ADVANCE_MANAGEMENT.CONFIRM_PAYMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        isCloseModal: false,
        alertMessage: 'Cập nhật dữ liệu thành công!'
      };
    }
    case ADVANCE_MANAGEMENT.CONFIRM_PAYMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: getErrorMessage(action, MSG_EDIT_FAILURE),
        isCloseModal: false
      };
    }

    case ADVANCE_MANAGEMENT.CREATE_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case ADVANCE_MANAGEMENT.CREATE_PAYMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        isCloseModal: false,
        alertMessage: 'Thêm mới dữ liệu thành công!'
      };
    }
    case ADVANCE_MANAGEMENT.CREATE_PAYMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: getErrorMessage(action, MSG_ADD_FAILURE),
        isCloseModal: false
      };
    }

    case ADVANCE_MANAGEMENT.UPDATE_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case ADVANCE_MANAGEMENT.UPDATE_PAYMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        isCloseModal: false,
        alertMessage: 'Cập nhật dữ liệu thành công!'
      };
    }
    case ADVANCE_MANAGEMENT.UPDATE_PAYMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: getErrorMessage(action, MSG_EDIT_FAILURE),
        isCloseModal: false
      };
    }

    //accounting
    case ADVANCE_MANAGEMENT.CREATE_ACCOUNTING_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case ADVANCE_MANAGEMENT.CREATE_ACCOUNTING_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        isCloseModal: false,
        alertMessage: 'Thêm mới dữ liệu thành công!'
      };
    }
    case ADVANCE_MANAGEMENT.CREATE_ACCOUNTING_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: getErrorMessage(action, MSG_ADD_FAILURE),
        isCloseModal: false
      };
    }

    case ADVANCE_MANAGEMENT.UPDATE_ACCOUNTING_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case ADVANCE_MANAGEMENT.UPDATE_ACCOUNTING_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        isCloseModal: false,
        alertMessage: 'Cập nhật dữ liệu thành công!'
      };
    }
    case ADVANCE_MANAGEMENT.UPDATE_ACCOUNTING_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: getErrorMessage(action, MSG_EDIT_FAILURE),
        isCloseModal: false
      };
    }

    case ADVANCE_MANAGEMENT.DELETE_ACCOUNTING_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case ADVANCE_MANAGEMENT.DELETE_ACCOUNTING_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        isCloseModal: false,
        alertMessage: 'Xoá dữ liệu thành công!'
      };
    }
    case ADVANCE_MANAGEMENT.DELETE_ACCOUNTING_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: getErrorMessage(action, MSG_DELETE_FAILURE),
        isCloseModal: false
      };
    }
    case ADVANCE_MANAGEMENT.GET_ADVANCE_REPORT_PERMISSION: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case ADVANCE_MANAGEMENT.GET_ADVANCE_REPORT_PERMISSION_FULFILLED: {
      return {
        ...state,
        AdvanceReportPermission: action.payload.data.data
      };
    }
    case ADVANCE_MANAGEMENT.GET_ADVANCE_REPORT_PERMISSION_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: getErrorMessage(action, MSG_DELETE_FAILURE),
        isCloseModal: false
      };
    }
    default:
      return state;
  }
};
