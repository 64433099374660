import httpClient from 'HttpClient';
// define const actions
export const FUEL_USAGE_REPORT_ACTIONS = {
  GET_FUEL_USAGE_REPORT_PER_PAGE: 'GET_FUEL_USAGE_REPORT_PER_PAGE',
  GET_FUEL_USAGE_REPORT_PER_PAGE_PENDING: 'GET_FUEL_USAGE_REPORT_PER_PAGE_PENDING',
  GET_FUEL_USAGE_REPORT_PER_PAGE_FULFILLED: 'GET_FUEL_USAGE_REPORT_PER_PAGE_FULFILLED',
  GET_FUEL_USAGE_REPORT_PER_PAGE_REJECTED: 'GET_FUEL_USAGE_REPORT_PER_PAGE_REJECTED',

  UPDATE_OIL_FUEL_USAGE_REPORT: 'UPDATE_OIL_FUEL_USAGE_REPORT',
  UPDATE_OIL_FUEL_USAGE_REPORT_PENDING: 'UPDATE_OIL_FUEL_USAGE_REPORT_PENDING',
  UPDATE_OIL_FUEL_USAGE_REPORT_FULFILLED: 'UPDATE_OIL_FUEL_USAGE_REPORT_FULFILLED',
  UPDATE_OIL_FUEL_USAGE_REPORT_REJECTED: 'UPDATE_OIL_FUEL_USAGE_REPORT_REJECTED'
};

// define actions
export const getDataPerPage = (params) => (dispatch) => {
  return dispatch({
    type: FUEL_USAGE_REPORT_ACTIONS.GET_FUEL_USAGE_REPORT_PER_PAGE,
    payload: httpClient.get(`/fuelusagereport`, { params })
  });
};
export const getDataPerPageAll = (payload) => (dispatch) => {
  return dispatch({
    // type: FUEL_USAGE_REPORT_ACTIONS.GET_FUEL_USAGE_REPORT_PER_PAGE,
    payload: httpClient.get(
      `/fuelusagereport`,
      { params: payload }
      //?TractorTrailerId=${tractorTrailerId}&monthYear=${monthYear}&Page=${pageNo}&Pagesize=${pageSize}`
    )
  });
};
export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const updateOilFuelUsageReport = (monthYear, tractorTrailerId, payload) => (dispatch) => {
  return dispatch({
    type: FUEL_USAGE_REPORT_ACTIONS.UPDATE_OIL_FUEL_USAGE_REPORT,
    payload: httpClient.put(
      `/FuelUsageReport/UpdateOil?monthYear=${monthYear}&tractorTrailerId=${tractorTrailerId}`,
      payload
    )
  });
};
