import {
  BACKUP_COLUMN,
  CHANGE_SETUP_FIELD_VALUE_FULFILLED,
  // CHANGE_SETUP_FIELD_VALUE,
  CHANGE_SETUP_FIELD_VALUE_PENDING,
  FETCH_SAVE_TEMPLATE_FULFILLED,
  FETCH_SETUP_COLUMN_FULFILLED,
  FETCH_SETUP_COLUMN_PENDING,
  FETCH_SETUP_COLUMN_REJECTED,
  FETCH_TEMPLATE_FULFILLED,
  MOVE_DOWN_COLUMN_FULFILLED,
  MOVE_DOWN_COLUMN_PENDING,
  MOVE_UP_COLUMN_FULFILLED,
  MOVE_UP_COLUMN_PENDING,
  RESET_SETUP_COLUMNS,
  SET_CLOSE_SET_UP_DIALOG_MODAL,
  // FETCH_SETUP_COLUMN,
  SET_OPEN_SET_UP_DIALOG_MODAL,
  SET_VALUE_COLUMNS_SETUP_DIALOG,
  UPDATE_SETUP_DIALOG_FULFILLED,
  UPDATE_SETUP_DIALOG_PENDING,
  UPDATE_SETUP_DIALOG_REJECTED
} from 'actions/setupDialogAction';
import { extractDisplayColumnInfo } from 'commons/columnTreeUtils';
import { convertSizeColumns, flatColumns, getDisplayColumns, moveDownColumn, moveUpColumn } from 'commons/setupDialogUtils';
import {
  ALL_FORMULAS_OPTIONS,
  MSG_EDIT_FAILURE,
  MSG_EDIT_SUCCESS,
  MSG_GET_ALL_FAILURE,
  MSG_TYPE_ERROR,
  MSG_TYPE_SUCCESS
} from 'const/common';
// import {
//     // flatTreePermission,
//     findPermission,
//     changeFieldPermission,
//     changeRowPermission
// } from "../commons/groupPermissionUtils";

function changeFieldForColumn(column, columnId, prop, value) {
  if (column.ColumnName === columnId) {
    const children =
      column.SysSettingGrids?.map((child) => changeFieldForColumn(child, child.ColumnName, prop, value)) ?? [];

    return {
      ...column,
      Dirty: true,
      [prop]: value,
      SysSettingGrids: children
    };
  }
  const children = column.SysSettingGrids?.map((child) => changeFieldForColumn(child, columnId, prop, value)) ?? [];
  const childrenAreDirty = children?.some((child) => child.Dirty) ?? false;
  const result = {
    ...column,
    SysSettingGrids: children,
    Dirty: childrenAreDirty ? childrenAreDirty : column.Dirty ?? false
  };
  if (prop === 'DisplayStatus' && childrenAreDirty) {
    const currentDisplayStatus = children?.some((child) => child.DisplayStatus) ?? false;
    result[prop] = currentDisplayStatus;
  }
  return result;
}

function changeFieldForColumns(columns, columnId, prop, value) {
  const changedColumns = columns.map((column) => {
    return changeFieldForColumn(column, columnId, prop, value);
  });
  return changedColumns;
}

const INIT_STATE = {
  columns: [],
  displayColumns: [],
  displayColumnInfos: extractDisplayColumnInfo([]),
  flattedColumns: {},
  pristine: true,
  sorted: false,
  submitting: false,
  showMessage: false,
  alertMessage: '',
  messageType: '',
  template: [],
  allFormulas: ALL_FORMULAS_OPTIONS.filter((_, index) => index === 0)
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //update modal
    case SET_OPEN_SET_UP_DIALOG_MODAL: {
      return {
        ...state,
        isShowSetupModal: true
      };
    }
    case SET_CLOSE_SET_UP_DIALOG_MODAL: {
      return {
        ...state,
        isShowSetupModal: false
      };
    }

    case FETCH_SETUP_COLUMN_PENDING: {
      return {
        ...state,
        loading: true,
        fetchColumnsLoading: true
      };
    }

    case FETCH_SETUP_COLUMN_FULFILLED: {
      const columns = action.payload.data.data;
      const originalColumns = JSON.parse(JSON.stringify(columns));
      const displayColumns = getDisplayColumns(originalColumns);
      const displayColumsNew = convertSizeColumns(displayColumns)
      const displayColumnInfos = extractDisplayColumnInfo(displayColumns);
      let flattedColumns = state.pristine ? flatColumns(columns, true) : state.flattedColumns;
      return {
        ...state,
        loading: false,
        fetchColumnsLoading: false,
        columns: columns,
        originalColumns: originalColumns,
        displayColumns: displayColumsNew,
        displayColumnInfos: displayColumnInfos,
        flattedColumns: flattedColumns
      };
    }
    case FETCH_TEMPLATE_FULFILLED: {
      const template = action.payload?.data?.data;
      return {
        ...state,
        template
      };
    }
    case FETCH_SAVE_TEMPLATE_FULFILLED: {
      return {
        ...state,
        showMessage: true,
        alertMessage: MSG_EDIT_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case FETCH_SETUP_COLUMN_REJECTED: {
      return {
        ...state,
        loading: false,
        fetchColumnsLoading: false,
        showMessage: true,
        alertMessage: MSG_GET_ALL_FAILURE,
        messageType: MSG_TYPE_ERROR,
        flattedColumns: flatColumns(state.columns, true)
      };
    }

    case CHANGE_SETUP_FIELD_VALUE_PENDING: {
      return {
        ...state
      };
    }
    case CHANGE_SETUP_FIELD_VALUE_FULFILLED: {
      const columnId = action.payload.column.ColumnName;
      // let changedColumns = findAllChangedNode(
      //   state.flattedColumns,
      //   columnId,
      //   action.payload.prop,
      //   action.payload.value
      // );

      // let flattedColumns = updateFlattedColumn(state.flattedColumns, changedColumns, action);
      const updatedColumns = changeFieldForColumns(state.columns, columnId, action.payload.prop, action.payload.value);
      const flattedColumns = flatColumns(updatedColumns, true);

      return {
        ...state,
        columns: updatedColumns,
        flattedColumns: flattedColumns,
        pristine: false,
        loading: false
      };
    }

    case UPDATE_SETUP_DIALOG_PENDING: {
      return {
        ...state,
        loading: true,
        saving: true,
        submitting: true
      };
    }

    case UPDATE_SETUP_DIALOG_FULFILLED: {
      return {
        ...state,
        loading: false,
        saving: false,
        submitting: false,
        pristine: true,
        showMessage: true,
        alertMessage: MSG_EDIT_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case UPDATE_SETUP_DIALOG_REJECTED: {
      return {
        ...state,
        loading: false,
        saving: false,
        submitting: false,
        showMessage: true,
        alertMessage: MSG_EDIT_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }

    case MOVE_UP_COLUMN_PENDING: {
      return {
        ...state
      };
    }

    case MOVE_UP_COLUMN_FULFILLED: {
      const changedColumns = moveUpColumn(state.columns, action.payload);
      const flattedColumns = flatColumns(changedColumns, true);
      return {
        ...state,
        columns: changedColumns,
        flattedColumns: flattedColumns,
        loading: false,
        pristine: false,
        sorted: true
      };
    }

    case MOVE_DOWN_COLUMN_PENDING: {
      return {
        ...state
        // loading: true
      };
    }
    case MOVE_DOWN_COLUMN_FULFILLED: {
      const changedColumns = moveDownColumn(state.columns, action.payload);
      const flattedColumns = flatColumns(changedColumns, true);
      return {
        ...state,
        columns: changedColumns,
        flattedColumns: flattedColumns,
        loading: false,
        pristine: false,
        sorted: true
      };
    }

    case BACKUP_COLUMN: {
      const columns = state.originalColumns;
      const originalColumns = JSON.parse(JSON.stringify(columns));
      const displayColumns = getDisplayColumns(originalColumns);
      const displayColumsNew = convertSizeColumns(displayColumns)
      const displayColumnInfos = extractDisplayColumnInfo(displayColumns);
      let flattedColumns = flatColumns(columns, true);
      return {
        ...state,
        loading: false,
        fetchColumnsLoading: false,
        columns: columns,
        originalColumns: originalColumns,
        displayColumns: displayColumsNew,
        displayColumnInfos: displayColumnInfos,
        pristine: true,
        flattedColumns: flattedColumns
      };
    }
    case SET_VALUE_COLUMNS_SETUP_DIALOG: {
      const displayColumnsPayload = action.payload.displayColumns;
      const columnsRenderSetupDialog = action.payload.columns;
      const displayColumns = getDisplayColumns(displayColumnsPayload);
      const displayColumnInfos = extractDisplayColumnInfo(displayColumns);

      return {
        ...state,
        loading: false,
        fetchColumnsLoading: false,
        columns: columnsRenderSetupDialog,
        displayColumns: displayColumns,
        displayColumnInfos: displayColumnInfos,
        pristine: true
        // flattedColumns: flatColumns(displayColumnsPayload, true)
      };
    }
    case RESET_SETUP_COLUMNS: {
      return {
        ...state,
        loading: false,
        fetchColumnsLoading: false,
        columns: [],
        originalColumns: [],
        displayColumns: [],
        displayColumnInfos: [],
        flattedColumns: []
      };
    }

    default:
      return state;
  }
};
