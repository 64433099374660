import { COMMONS_ACTIONS, SET_ACTIVE_TAB, VISIBLE_MODAL_COMMON_INFO } from 'actions';
const INIT_STATE = {
  // list active tab
  activeTab: 0,
  calculatingCategories: [],
  modalCommonsInfo: { visible: false, title: '' }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region list activeTab START
    case SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload
      };
    }
    case COMMONS_ACTIONS.GET_CALCULATING_CATEGORY_NORM_PENDING: {
      return {
        ...state
      };
    }
    case COMMONS_ACTIONS.GET_CALCULATING_CATEGORY_NORM_FULFILLED: {
      return {
        ...state,
        calculatingCategories: action.payload?.data?.data ?? []
      };
    }
    case COMMONS_ACTIONS.GET_CALCULATING_CATEGORY_NORM_REJECTED: {
      return {
        ...state
      };
    }
    case VISIBLE_MODAL_COMMON_INFO: {
      return {
        ...state,
        modalCommonsInfo: action.payload
      };
    }
    //#region list activeTab END

    default:
      return state;
  }
};
