import { TRANSPORT_FEE_PHASE } from 'actions/transportFeePhase';
import { PHASE_CATEGORY_ID } from 'const/common';

const dataMessageExist = ['Dữ liệu đã tồn tại'];
const INIT_STATE = {
  dataCustomer: [],
  transportFeePhase: [],
  transportFee: [],
  outSideRentalFee: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TRANSPORT_FEE_PHASE.GET_CUSTOMER_BY_TYPE_TRANSPORT_FEE_PHASE_FULFILLED: {
      return { ...state, dataCustomer: action?.payload?.data?.data ?? [] };
    }
    case TRANSPORT_FEE_PHASE.TRANSPORT_FEE_PHASE_FULFILLED: {
      const transportFeePhase =
        action?.payload?.data?.data.map((item) => ({ ...item, value: item.Id, label: item.PhaseName })) ?? [];
      return {
        ...state,
        transportFeePhase: transportFeePhase,
        transportFee:
          action?.meta?.PhaseCategoryId === PHASE_CATEGORY_ID.TRANSPORT_FEE ? transportFeePhase : state.transportFee,
        outSideRentalFee:
          action?.meta?.PhaseCategoryId === PHASE_CATEGORY_ID.OUTSIDE_RENTAL_FEES
            ? transportFeePhase
            : state.outSideRentalFee
      };
    }
    default:
      return state;
  }
};
