import httpClient from 'HttpClient';
// define const actions
export const SUPPLIER_MANAGEMENT_ACTIONS = {
  GET_SUPPLIER_PER_PAGE: 'GET_SUPPLIER_PER_PAGE',
  GET_SUPPLIER_PER_PAGE_PENDING: 'GET_SUPPLIER_PER_PAGE_PENDING',
  GET_SUPPLIER_PER_PAGE_FULFILLED: 'GET_SUPPLIER_PER_PAGE_FULFILLED',
  GET_SUPPLIER_PER_PAGE_REJECTED: 'GET_SUPPLIER_PER_PAGE_REJECTED',

  //   SEARCH_ACCOUNTING_REPAIRING_ITEMS: 'SEARCH_ACCOUNTING_REPAIRING_ITEMS',
  //   SEARCH_ACCOUNTING_REPAIRING_ITEMS_PENDING: 'SEARCH_ACCOUNTING_REPAIRING_ITEMS_PENDING',
  //   SEARCH_ACCOUNTING_REPAIRING_ITEMS_FULFILLED: 'SEARCH_ACCOUNTING_REPAIRING_ITEMS_FULFILLED',
  //   SEARCH_ACCOUNTING_REPAIRING_ITEMS_REJECTED: 'SEARCH_ACCOUNTING_REPAIRING_ITEMS_REJECTED',

  GET_SUPPLIER_ALL: 'GET_SUPPLIER_ALL',
  GET_SUPPLIER_ALL_PENDING: 'GET_SUPPLIER_ALL_PENDING',
  GET_SUPPLIER_ALL_FULFILLED: 'GET_SUPPLIER_ALL_FULFILLED',
  GET_SUPPLIER_ALL_REJECTED: 'GET_SUPPLIER_ALL_REJECTED',

  CREATE_DATA_SPAREPART_DETAIL: 'CREATE_DATA_SPAREPART_DETAIL',
  CREATE_DATA_SPAREPART_DETAIL_PENDING: 'CREATE_DATA_SPAREPART_DETAIL_PENDING',
  CREATE_DATA_SPAREPART_DETAIL_FULFILLED: 'CREATE_DATA_SPAREPART_DETAIL_FULFILLED',
  CREATE_DATA_SPAREPART_DETAIL_REJECTED: 'CREATE_DATA_SPAREPART_DETAIL_REJECTED',

  UPDATE_DATA_SPAREPART_DETAIL: 'UPDATE_DATA_SPAREPART_DETAIL',
  UPDATE_DATA_SPAREPART_DETAIL_PENDING: 'UPDATE_DATA_SPAREPART_DETAIL_PENDING',
  UPDATE_DATA_SPAREPART_DETAIL_FULFILLED: 'UPDATE_DATA_SPAREPART_DETAIL_FULFILLED',
  UPDATE_DATA_SPAREPART_DETAIL_REJECTED: 'UPDATE_DATA_SPAREPART_DETAIL_REJECTED',

  DELETE_DATA_SUPPLIER: 'DELETE_DATA_SUPPLIER',
  DELETE_DATA_SUPPLIER_PENDING: 'DELETE_DATA_SUPPLIER_PENDING',
  DELETE_DATA_SUPPLIER_FULFILLED: 'DELETE_DATA_SUPPLIER_FULFILLED',
  DELETE_DATA_SUPPLIER_REJECTED: 'DELETE_DATA_SUPPLIER_REJECTED',

  GET_SUPPLIER_BY_SPAREPARTID: 'GET_SUPPLIER_BY_SPAREPARTID',
  GET_SUPPLIER_BY_SPAREPARTID_PENDING: 'GET_SUPPLIER_BY_SPAREPARTID_PENDING',
  GET_SUPPLIER_BY_SPAREPARTID_FULFILLED: 'GET_SUPPLIER_BY_SPAREPARTID_FULFILLED',
  GET_SUPPLIER_BY_SPAREPARTID_REJECTED: 'GET_SUPPLIER_BY_SPAREPARTID_REJECTED',

  UPDATE_SHOW_ALERT: 'UPDATE_SHOW_ALERT'
};

// define actions
export const getDataSparepartDetailPerpage = (value, sparePartInfoId, pageNo, pageSize) => (dispatch) => {
  const payload = {
    // value: value,
    sparePartInfoId: sparePartInfoId,
    page: pageNo,
    pagesize: pageSize
  };
  return dispatch({
    type: SUPPLIER_MANAGEMENT_ACTIONS.GET_SUPPLIER_PER_PAGE,
    payload: httpClient.get(`/SparePartDetail/paging/PageBySparePartInfo?`, { params: payload })
  });
};

// define actions
export const updateShowAlert = () => {
  return {
    type: SUPPLIER_MANAGEMENT_ACTIONS.UPDATE_SHOW_ALERT
  };
};

export const createDataSparepartDetail = (payload) => (dispatch) => {
  return dispatch({
    type: SUPPLIER_MANAGEMENT_ACTIONS.CREATE_DATA_SPAREPART_DETAIL,
    payload: httpClient.post(`/AdvancePaymentManaging/create`, payload)
  });
};

export const updateDataSparepartDetail = (payload) => (dispatch) => {
  return dispatch({
    type: SUPPLIER_MANAGEMENT_ACTIONS.UPDATE_DATA_SPAREPART_DETAIL,
    payload: httpClient.put(`/SparePartDetail/update`, payload)
  });
};

export const deleteDataSparepartDetail = (payload) => (dispatch) => {
  return dispatch({
    type: SUPPLIER_MANAGEMENT_ACTIONS.UPDATE_DATA_SPAREPART_DETAIL,
    payload: httpClient.delete(`/AdvancePaymentManaging/update`, payload)
  });
};

export const getAllSuppier = (id) => (dispatch) => {
  return dispatch({
    type: SUPPLIER_MANAGEMENT_ACTIONS.GET_SUPPLIER_ALL,
    payload: httpClient.get(`/customerinfo/getByCustomerType/${id}`)
  });
};

export const getSuppierBySparepart = (id) => (dispatch) => {
  return dispatch({
    type: SUPPLIER_MANAGEMENT_ACTIONS.GET_SUPPLIER_BY_SPAREPARTID,
    payload: httpClient.get(`/customerinfo/getBySparePart/${id}`)
  });
};