import * as coreuiIcons from '@coreui/icons'

import { logo } from './logo'
import { logoNegative } from './logo-negative'
import { sygnet } from './sygnet'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative
}, {...coreuiIcons})
