// Import the functions you need from the SDKs you need

import Axios from 'axios';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const vapidKey = 'BIfArU7Gu7ojFsEiWW2SswPdr-f3fjyMM25yabsp_7C_jjPRQGpDF3MUNllfHAmcpyYoXt0XN_PmwkTqK0mBNk4';

const firebaseConfig = {
  apiKey: 'AIzaSyD8Dd84kfzKIozd463qoikwIqa39lIOKQc',
  authDomain: 'logsmaster-notification.firebaseapp.com',
  projectId: 'logsmaster-notification',
  storageBucket: 'logsmaster-notification.appspot.com',
  messagingSenderId: '440813640093',
  appId: '1:440813640093:web:e3635bba6ed0a4469c3774',
  measurementId: 'G-PC5ZHGDV3C'
};

const httpClient = Axios.create({
  // baseURL: `http://localhost:5008/api`,
  baseURL: `https://notifyws.logsmaster.com.vn/api`,
  timeout: 5000
});

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const messaging = getMessaging(firebaseApp);

export const getFirebaseToken = async () => {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      return getToken(messaging, { vapidKey })
        .then((currentToken) => {
          if (currentToken) {
            console.log('current token for client: ', currentToken);
            localStorage.setItem('fcmToken', currentToken);
            return currentToken;
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
          } else {
            console.log('No registration token available. Request permission to generate one.');
            return null;
            // shows on the UI that permission is required
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          return null;
          // catch error while creating client token
        });
    }
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const registerTopicFirebase = async (payload) => {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      return getToken(messaging, { vapidKey })
        .then((currentToken) => {
          if (currentToken) {
            console.log('current token for client: ', currentToken);
            localStorage.setItem('fcmToken', currentToken);
            httpClient.post('/token/register', {
              ...payload,
              fb_token: currentToken
            });
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
    }
  });
};
export const NOTIFICATION_WS_URLS = {
  SEND_TO_USER: '/commons/sendToUser',
  SEND_TO_GROUP: '/commons/sendToGroup'
};

export const pushNotification = (payload, endpoint) => {
  const { title, message, data, send_user_ids, group_user_ids } = payload;
  const notification = {
    mess: {
      title: `Logsmaster - ${title}`,
      body: message
    },
    data,
    user_id: +localStorage.getItem('employeeInfoId'),
    db_id: +localStorage.getItem('companyId'),
    send_user_ids: send_user_ids ?? [],
    group_user_ids: group_user_ids ?? []
  };
  httpClient.post(endpoint, notification);
};

export const unsubcribeToken = () => {
  const token = localStorage.getItem('fcmToken');
  httpClient.post(`/token/unSubscribe`, {
    fb_token: token,
    user_id: +localStorage.getItem('employeeInfoId'),
    db_id: +localStorage.getItem('companyId')
  });
};
export { messaging, analytics };

export default firebaseApp;
