import httpClient from 'HttpClient';
// define const actions
export const SETTING_INFO_ACTIONS = {
  GET_SETTING_INFO_BY_GROUP: 'GET_SETTING_INFO_BY_GROUP',
  GET_SETTING_INFO_BY_GROUP_PENDING: 'GET_SETTING_INFO_BY_GROUP_PENDING',
  GET_SETTING_INFO_BY_GROUP_FULFILLED: 'GET_SETTING_INFO_BY_GROUP_FULFILLED',
  GET_SETTING_INFO_BY_GROUP_REJECTED: 'GET_SETTING_INFO_BY_GROUP_REJECTED',

  GET_ALL_SETTING_INFO: 'GET_ALL_SETTING_INFO',
  GET_ALL_SETTING_INFO_PENDING: 'GET_ALL_SETTING_INFO_PENDING',
  GET_ALL_SETTING_INFO_FULFILLED: 'GET_ALL_SETTING_INFO_FULFILLED',
  GET_ALL_SETTING_INFO_REJECTED: 'GET_ALL_SETTING_INFO_REJECTED',

  UPDATE_COMPANY_LOGO: 'UPDATE_COMPANY_LOGO',
  UPDATE_COMPANY_LOGO_PENDING: 'UPDATE_COMPANY_LOGO_PENDING',
  UPDATE_COMPANY_LOGO_FULFILLED: 'UPDATE_COMPANY_LOGO_FULFILLED',
  UPDATE_COMPANY_LOGO_REJECTED: 'UPDATE_COMPANY_LOGO_REJECTED',

  UPDATE_COMPANY_NAMES: 'UPDATE_COMPANY_NAMES',
  UPDATE_COMPANY_NAMES_PENDING: 'UPDATE_COMPANY_NAMES_PENDING',
  UPDATE_COMPANY_NAMES_FULFILLED: 'UPDATE_COMPANY_NAMES_FULFILLED',
  UPDATE_COMPANY_NAMES_REJECTED: 'UPDATE_COMPANY_NAMES_REJECTED'
};

// define actions
export const getSettingInfoByGroupId = (groupId) => (dispatch) => {
  return dispatch({
    type: SETTING_INFO_ACTIONS.GET_SETTING_INFO_BY_GROUP,
    payload: httpClient.get(`/OptionSetting/getByGroupId/${groupId}`)
  });
};

export const getAllSettingInfo = () => (dispatch) => {
  return dispatch({
    type: SETTING_INFO_ACTIONS.GET_ALL_SETTING_INFO,
    payload: httpClient.get(`/OptionSetting/getAll`)
  });
};

export const updateLogo = (data) => (dispatch) => {
  return dispatch({
    type: SETTING_INFO_ACTIONS.UPDATE_COMPANY_LOGO,
    payload: httpClient.put('/OptionSetting/UpdateCompanyLogo', data)
  });
};
export const updateCompanyInfo = (params) => (dispatch) => {
  return dispatch({
    type: SETTING_INFO_ACTIONS.UPDATE_COMPANY_LOGO,
    payload: httpClient.put('/OptionSetting/UpdateByNames', params)
  });
};
