import {
  createMooc,
  deleteMooc,
  getAllMoocs,
  getMoocById,
  getMoocsPerPage,
  getMoocsPerPageAll,
  searchMoocs,
  typeRomooc,
  updateMooc
} from 'services/mooc';

// define const actions
export const MOOCS_ACTIONS = {
  GET_ALL_MOOCS: 'GET_ALL_MOOCS',
  GET_ALL_MOOCS_PENDING: 'GET_ALL_MOOCS_PENDING',
  GET_ALL_MOOCS_FULFILLED: 'GET_ALL_MOOCS_FULFILLED',
  GET_ALL_MOOCS_REJECTED: 'GET_ALL_MOOCS_REJECTED',

  GET_MOOC_BY_ID: 'GET_MOOC_BY_ID',
  GET_MOOC_BY_ID_PENDING: 'GET_MOOC_BY_ID_PENDING',
  GET_MOOC_BY_ID_FULFILLED: 'GET_MOOC_BY_ID_FULFILLED',
  GET_MOOC_BY_ID_REJECTED: 'GET_MOOC_BY_ID_REJECTED',

  GET_MOOCS_PER_PAGE: 'GET_MOOCS_PER_PAGE',
  GET_MOOCS_PER_PAGE_PENDING: 'GET_MOOCS_PER_PAGE_PENDING',
  GET_MOOCS_PER_PAGE_FULFILLED: 'GET_MOOCS_PER_PAGE_FULFILLED',
  GET_MOOCS_PER_PAGE_REJECTED: 'GET_MOOCS_PER_PAGE_REJECTED',

  CREATE_MOOC: 'CREATE_MOOC',
  CREATE_MOOC_PENDING: 'CREATE_MOOC_PENDING',
  CREATE_MOOC_FULFILLED: 'CREATE_MOOC_FULFILLED',
  CREATE_MOOC_REJECTED: 'CREATE_MOOC_REJECTED',

  UPDATE_MOOC: 'UPDATE_MOOC',
  UPDATE_MOOC_PENDING: 'UPDATE_MOOC_PENDING',
  UPDATE_MOOC_FULFILLED: 'UPDATE_MOOC_FULFILLED',
  UPDATE_MOOC_REJECTED: 'UPDATE_MOOC_REJECTED',

  DELETE_MOOC: 'DELETE_MOOC',
  DELETE_MOOC_PENDING: 'DELETE_MOOC_PENDING',
  DELETE_MOOC_FULFILLED: 'DELETE_MOOC_FULFILLED',
  DELETE_MOOC_REJECTED: 'DELETE_MOOC_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  GET_TYPE_ROMOOC: 'GET_TYPE_ROMOOC',
  GET_TYPE_ROMOOC_PENDING: 'GET_TYPE_ROMOOC_PENDING',
  GET_TYPE_ROMOOC_FULFILLED: 'GET_TYPE_ROMOOC_FULFILLED',
  GET_TYPE_ROMOOC_REJECTED: 'GET_TYPE_ROMOOC_REJECTED'
};

// define actions
export const actionGetAllMoocs = () => (dispatch) => {
  return dispatch({
    type: MOOCS_ACTIONS.GET_ALL_MOOCS,
    payload: getAllMoocs()
  });
};

export const actionGetMoocsPerPage = (page, size) => (dispatch) => {
  return dispatch({
    type: MOOCS_ACTIONS.GET_MOOCS_PER_PAGE,
    payload: getMoocsPerPage(page, size)
  });
};
export const actionGetMoocsPerPageAll = (params) => (dispatch) => {
  return dispatch({
    // type: MOOCS_ACTIONS.GET_MOOCS_PER_PAGE,
    payload: getMoocsPerPageAll(params)
  });
};

export const actionGetMoocByID = (id) => (dispatch) => {
  return dispatch({
    type: MOOCS_ACTIONS.GET_MOOC_BY_ID,
    payload: getMoocById(id)
  });
};

export const actionCreateMooc = (payload) => (dispatch) => {
  return dispatch({
    type: MOOCS_ACTIONS.CREATE_MOOC,
    payload: createMooc(payload)
  });
};

export const actionUpdateMooc = (payload) => (dispatch) => {
  return dispatch({
    type: MOOCS_ACTIONS.UPDATE_MOOC,
    payload: updateMooc(payload)
  });
};

export const actionDeleteMooc = (id) => (dispatch) => {
  return dispatch({
    type: MOOCS_ACTIONS.DELETE_MOOC,
    payload: deleteMooc(id)
  });
};

export const actionSearchData = (value, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: MOOCS_ACTIONS.SEARCH_DATA,
    payload: searchMoocs(value, pageNo, pageSize)
  });
};
export const actionSearchDataAll = (params) => (dispatch) => {
  return dispatch({
    // type: MOOCS_ACTIONS.SEARCH_DATA,
    payload: searchMoocs(params)
  });
};

export const getTypeRomooc = () => (dispatch) => {
  return dispatch({
    type: MOOCS_ACTIONS.GET_TYPE_ROMOOC,
    payload: typeRomooc()
  });
};
