import { SHIPPING_LINE_ACTIONS } from "actions/shippingLine";
import { put, takeEvery } from "redux-saga/effects";
import { getShippingLinePerPage } from "services/shippingLine";

function* putActionUpdateData() {
  yield put({
    type: SHIPPING_LINE_ACTIONS.GET_SHIPPING_LINE_PER_PAGE,
    payload: getShippingLinePerPage(
      1,
      parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE)
    ),
  });
}

export function* actionUpdateShippingLineData() {
  yield takeEvery(
    [
      SHIPPING_LINE_ACTIONS.UPDATE_SHIPPING_LINE_FULFILLED,
      SHIPPING_LINE_ACTIONS.CREATE_SHIPPING_LINE_FULFILLED,
      //   SHIPPING_LINE_ACTIONS.DELETE_SHIPPING_LINE_FULFILLED,
    ],
    putActionUpdateData
  );
}
