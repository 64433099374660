import { EMPLOYEES_ACTIONS } from 'actions';

const INIT_STATE = {
  listPerPage: [],
  listJobPosition: [],
  listTransportCompany: [],
  loading: false,
  loadingSelect: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  pagination: {},
  isCloseModal: false,
  listAll: [],
  listCity: [],
  listDistrict: [],
  listSubDistrict: [],
  isSearch: false,
  listFuelManager: [],
  accountant: [],
  customerService: [],
  employee: {},
  employeeByDepartment: [],
  banks: []
};

const dataMessageExist = ['Mã nhân viên đã tồn tại', 'Số chứng minh đã tồn tại', 'Số bằng lái xe đã tồn tại'];

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EMPLOYEES_ACTIONS.GET_EMPLOYEES_BY_TRANSPORT_COMPANY_ID_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case EMPLOYEES_ACTIONS.GET_EMPLOYEES_BY_TRANSPORT_COMPANY_ID_FULFILLED: {
      return {
        ...state,
        loading: false,
        employeesByTransportCompanyID: action.payload.data.data,
        isSearch: false
      };
    }
    case EMPLOYEES_ACTIONS.GET_EMPLOYEES_BY_TRANSPORT_COMPANY_ID_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case EMPLOYEES_ACTIONS.GET_EMPLOYEES_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case EMPLOYEES_ACTIONS.GET_EMPLOYEES_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false
      };
    }
    case EMPLOYEES_ACTIONS.GET_EMPLOYEES_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case EMPLOYEES_ACTIONS.CREATE_EMPLOYEES_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case EMPLOYEES_ACTIONS.CREATE_EMPLOYEES_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isShowMessage: true,
        isCloseModal: true
      };
    }
    case EMPLOYEES_ACTIONS.CREATE_EMPLOYEES_REJECTED: {
      const messageError = action.payload.response.data.message + '';
      let check = dataMessageExist.includes(messageError);
      return {
        ...state,
        loading: false,
        message: check ? messageError : 'Thêm dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false
      };
    }

    case EMPLOYEES_ACTIONS.DELETE_EMPLOYEES_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case EMPLOYEES_ACTIONS.DELETE_EMPLOYEES_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Xóa dữ liệu thành công',
        isShowMessage: true
      };
    }
    case EMPLOYEES_ACTIONS.DELETE_EMPLOYEES_REJECTED: {
      console.log(action.payload.response.data.status);
      return {
        ...state,
        loading: false,
        message:
          action.payload.response.data.status === '500'
            ? 'Không thể xóa nhân viên đang hoạt động'
            : 'Xóa dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error'
      };
    }

    case EMPLOYEES_ACTIONS.UPDATE_EMPLOYEES_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case EMPLOYEES_ACTIONS.UPDATE_EMPLOYEES_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công',
        isShowMessage: true,
        isCloseModal: true
      };
    }
    case EMPLOYEES_ACTIONS.UPDATE_EMPLOYEES_REJECTED: {
      const messageError = action.payload.response.data.message + '';
      let check = dataMessageExist.includes(messageError);
      return {
        ...state,
        loading: false,
        message: check ? messageError : 'Cập nhật dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false
      };
    }

    case EMPLOYEES_ACTIONS.GET_ALL_JOB_POSITION_FULFILLED: {
      return {
        ...state,
        loading: false,
        listJobPosition: action.payload.data.data
      };
    }

    case EMPLOYEES_ACTIONS.GET_ALL_TRANSPORT_COMPANY_FULFILLED: {
      return {
        ...state,
        loading: false,
        listTransportCompany: action.payload.data.data
      };
    }

    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }

    case EMPLOYEES_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case EMPLOYEES_ACTIONS.SEARCH_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: true
      };
    }
    case EMPLOYEES_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case EMPLOYEES_ACTIONS.GET_ALL_EMPLOYEES_FULFILLED: {
      return {
        ...state,
        loading: false,
        listAll: action.payload.data.data
      };
    }
    case EMPLOYEES_ACTIONS.GET_CITY_PENDING: {
      return {
        ...state,
        loadingSelect: true,
        isShowMessage: false
      };
    }
    case EMPLOYEES_ACTIONS.GET_CITY_FULFILLED: {
      return {
        ...state,
        loadingSelect: false,
        listCity: action.payload.data.data,
        loading: false,
        isShowMessage: false
      };
    }
    case EMPLOYEES_ACTIONS.GET_CITY_REJECTED: {
      return {
        ...state,
        loadingSelect: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Có lỗi xảy ra !'
      };
    }
    case EMPLOYEES_ACTIONS.GET_DISTRICT_PENDING: {
      return {
        ...state,
        loadingSelect: true,
        isShowMessage: false
      };
    }
    case EMPLOYEES_ACTIONS.GET_DISTRICT_FULFILLED: {
      return {
        ...state,
        loadingSelect: false,
        listDistrict: action.payload.data.data,
        loading: false,
        isShowMessage: false
      };
    }
    case EMPLOYEES_ACTIONS.GET_DISTRICT_REJECTED: {
      return {
        ...state,
        loadingSelect: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Có lỗi xảy ra !'
      };
    }
    case EMPLOYEES_ACTIONS.GET_SUBDISTRICT_PENDING: {
      return {
        ...state,
        loadingSelect: true,
        isShowMessage: false
      };
    }
    case EMPLOYEES_ACTIONS.GET_SUBDISTRICT_FULFILLED: {
      return {
        ...state,
        loadingSelect: false,
        listSubDistrict: action.payload.data.data,
        loading: false,
        isShowMessage: false
      };
    }
    case EMPLOYEES_ACTIONS.GET_SUBDISTRICT_REJECTED: {
      return {
        ...state,
        loadingSelect: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Có lỗi xảy ra !'
      };
    }

    case EMPLOYEES_ACTIONS.GET_ALL_FUEL_MANAGER_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case EMPLOYEES_ACTIONS.GET_ALL_FUEL_MANAGER_FULFILLED: {
      return {
        ...state,
        loading: false,
        listFuelManager: action.payload.data.data
      };
    }
    case EMPLOYEES_ACTIONS.GET_ALL_FUEL_MANAGER_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case EMPLOYEES_ACTIONS.RESET_DISTRICT: {
      return {
        ...state,
        listDistrict: []
      };
    }

    case EMPLOYEES_ACTIONS.RESET_SUBDISTRICT: {
      return {
        ...state,
        listSubDistrict: []
      };
    }

    case EMPLOYEES_ACTIONS.GET_EMPLOYEES_BY_POSITION_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case EMPLOYEES_ACTIONS.GET_EMPLOYEES_BY_POSITION_FULFILLED: {
      return {
        ...state,
        loading: false,
        listAll: action.payload.data.data,
        accountant: action.meta === 4 ? action.payload.data.data : action.payload.data.data,
        customerService: action.meta === 7 ? action.payload.data.data : state.customerService
      };
    }
    case EMPLOYEES_ACTIONS.GET_EMPLOYEES_BY_POSITION_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }
    case EMPLOYEES_ACTIONS.SEARCH_DATA_V2_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case EMPLOYEES_ACTIONS.SEARCH_DATA_V2_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: true
      };
    }
    case EMPLOYEES_ACTIONS.SEARCH_DATA_V2_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case EMPLOYEES_ACTIONS.GET_EMPLOYEES_BY_ID_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case EMPLOYEES_ACTIONS.GET_EMPLOYEES_BY_ID_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        employee: action.payload.data.data
      };
    }
    case EMPLOYEES_ACTIONS.GET_EMPLOYEES_BY_ID_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }
    case EMPLOYEES_ACTIONS.GET_USER_BY_DEPARTMENT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case EMPLOYEES_ACTIONS.GET_USER_BY_DEPARTMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        employeeByDepartment: action.payload.data.data
      };
    }
    case EMPLOYEES_ACTIONS.GET_USER_BY_DEPARTMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }
    case EMPLOYEES_ACTIONS.GET_ALL_BANK_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case EMPLOYEES_ACTIONS.GET_ALL_BANK_FULFILLED: {
      return {
        ...state,
        loading: false,
        banks: action.payload.data.data
      };
    }
    case EMPLOYEES_ACTIONS.GET_ALL_BANK_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    default:
      return state;
  }
};
