import {
  ADD_USERS_TO_WORK_GROUP_FULFILLED,
  ADD_USERS_TO_WORK_GROUP_PENDING,
  ADD_USERS_TO_WORK_GROUP_REJECTED,
  CREATE_WORK_GROUP_FULFILLED,
  CREATE_WORK_GROUP_PENDING,
  CREATE_WORK_GROUP_REJECTED,
  DELETE_WORK_GROUP_FULFILLED,
  DELETE_WORK_GROUP_PENDING,
  DELETE_WORK_GROUP_REJECTED,
  FETCH_ALL_WORK_GROUPS_FULFILLED,
  FETCH_ALL_WORK_GROUPS_PENDING,
  FETCH_ALL_WORK_GROUPS_REJECTED,
  FETCH_USERS_NOT_IN_WORK_GROUP_FULFILLED,
  FETCH_USERS_NOT_IN_WORK_GROUP_PENDING,
  FETCH_USERS_NOT_IN_WORK_GROUP_REJECTED,
  FETCH_WORK_GROUP_DETAIL_FULFILLED,
  FETCH_WORK_GROUP_DETAIL_PENDING,
  FETCH_WORK_GROUP_DETAIL_REJECTED,
  FETCH_WORK_GROUP_USERS_FULFILLED,
  //Group users
  FETCH_WORK_GROUP_USERS_PENDING,
  FETCH_WORK_GROUP_USERS_REJECTED,
  FETCH_WORK_GROUPS_FULFILLED,
  FETCH_WORK_GROUPS_PENDING,
  FETCH_WORK_GROUPS_REJECTED,
  GET_WORK_GROUP_BY_USER_ID_FULFILLED,
  GET_WORK_GROUP_BY_USER_ID_PENDING,
  GET_WORK_GROUP_BY_USER_ID_REJECTED,
  REMOVE_USER_TO_WORK_GROUP_FULFILLED,
  REMOVE_USER_TO_WORK_GROUP_PENDING,
  REMOVE_USER_TO_WORK_GROUP_REJECTED,
  SET_CLOSE_WORK_GROUP_MODAL,
  SET_OPEN_WORK_GROUP_MODAL,
  UPDATE_WORK_GROUP_FULFILLED,
  UPDATE_WORK_GROUP_PENDING,
  UPDATE_WORK_GROUP_REJECTED
} from 'actions';
import { getErrorMessage } from 'commons/ReducerUtils';
import {
  DEFAULT_PAGE_SIZE,
  MSG_ADD_FAILURE,
  MSG_ADD_SUCCESS,
  MSG_DELETE_FAILURE,
  MSG_DELETE_SUCCESS,
  MSG_EDIT_FAILURE,
  MSG_EDIT_SUCCESS,
  MSG_GET_DETAIL_FAILURE,
  MSG_PAGING_FAILURE,
  MSG_TYPE_ERROR,
  MSG_TYPE_SUCCESS
} from 'const/common';
const INIT_STATE = {
  //combobox: get all
  allWorkGroups: [],
  // paging
  workGroups: [],
  pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
  listLoading: false,

  // add/edit modal
  isOpenFormModal: false, // Show/hidden update modal
  workGroup: {},
  formLoading: false,
  //delete
  deleteLoading: false,
  // other
  showMessage: false,
  alertMessage: '',
  formAlertMessage: '',
  messageType: '',
  // Work group users
  workGroupUsers: [],
  workGroupUsersPagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
  workGroupUsersLoading: false,
  usersNotInWorkGroup: [],
  usersNotInWorkGroupLoading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region update modal
    case SET_OPEN_WORK_GROUP_MODAL: {
      return {
        ...state,
        isOpenFormModal: true,
        workGroup: action.payload
      };
    }
    case SET_CLOSE_WORK_GROUP_MODAL: {
      return {
        ...state,
        isOpenFormModal: false,
        workGroup: INIT_STATE.workGroup
      };
    }
    //#endregion

    //#region getall
    case FETCH_ALL_WORK_GROUPS_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }
    case FETCH_ALL_WORK_GROUPS_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        allWorkGroups: action.payload.data.data,
        showMessage: false
      };
    }
    case FETCH_ALL_WORK_GROUPS_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region paging
    case FETCH_WORK_GROUPS_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }

    case FETCH_WORK_GROUPS_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        workGroups: action.payload.data.data ?? INIT_STATE.workGroups,
        pagination: action.payload.data.pagination ?? INIT_STATE.pagination,
        showMessage: false
      };
    }
    case FETCH_WORK_GROUPS_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region create data
    case CREATE_WORK_GROUP_PENDING:
      return {
        ...state,
        formLoading: true
      };
    case CREATE_WORK_GROUP_FULFILLED: {
      const workGroup = action.payload.data;
      const workGroups = state.workGroups.map((item) => {
        return item.Id === workGroup.Id ? workGroup : item;
      });
      return {
        ...state,
        workGroups: [...workGroups],
        formLoading: false,
        showMessage: true,
        formAlertMessage: MSG_ADD_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case CREATE_WORK_GROUP_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_ADD_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region update data
    case UPDATE_WORK_GROUP_PENDING:
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    case UPDATE_WORK_GROUP_FULFILLED: {
      const workGroup = action.meta;
      let workGroups = [...state.workGroups];
      workGroups = workGroups.map((item) => {
        return item.Id === workGroup.Id ? workGroup : item;
      });

      return {
        ...state,
        workGroups,
        formLoading: false,
        showMessage: true,
        formAlertMessage: MSG_EDIT_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case UPDATE_WORK_GROUP_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_EDIT_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region  get detail
    case FETCH_WORK_GROUP_DETAIL_PENDING:
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    case FETCH_WORK_GROUP_DETAIL_FULFILLED:
      return {
        ...state,
        workGroup: action.payload.data,
        formLoading: false,
        showMessage: false
      };
    case FETCH_WORK_GROUP_DETAIL_REJECTED:
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        alertMessage: MSG_GET_DETAIL_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    //#endregion

    //#region delete data
    case DELETE_WORK_GROUP_PENDING:
      return {
        ...state,
        deleteLoading: true
      };
    case DELETE_WORK_GROUP_FULFILLED:
      return {
        ...state,
        isDelete: true,
        deleteLoading: false,
        showMessage: true,
        alertMessage: MSG_DELETE_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case DELETE_WORK_GROUP_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_DELETE_FAILURE);
      return {
        ...state,
        deleteLoading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }

    case FETCH_WORK_GROUP_USERS_PENDING:
      return {
        ...state,
        groupUsersLoading: true
      };
    case FETCH_WORK_GROUP_USERS_FULFILLED:
      return {
        ...state,
        workGroupUsersLoading: false,
        workGroupUsers: action.payload.data.data,
        workGroupUsersPagination: action.payload.data.pagination
      };
    case FETCH_WORK_GROUP_USERS_REJECTED:
      return {
        ...state,
        workGroupUsersLoading: false,
        workGroupUsers: [],
        workGroupUsersPagination: INIT_STATE.workGroupUsersPagination,
        alertMessage: 'Có lỗi, không thể tải thông tin người dùng của nhóm này'
      };

    case FETCH_USERS_NOT_IN_WORK_GROUP_PENDING:
      return {
        ...state,
        usersNotInWorkGroupLoading: true
      };
    case FETCH_USERS_NOT_IN_WORK_GROUP_FULFILLED:
      return {
        ...state,
        usersNotInWorkGroupLoading: false,
        usersNotInWorkGroup: action.payload.data.data
      };
    case FETCH_USERS_NOT_IN_WORK_GROUP_REJECTED:
      return {
        ...state,
        usersNotInWorkGroupLoading: false,
        usersNotInWorkGroup: [],
        alertMessage: 'Có lỗi, không thể tải thông tin người dùng chưa thuộc nhóm này'
      };

    case ADD_USERS_TO_WORK_GROUP_PENDING:
      return {
        ...state,
        usersNotInWorkGroupLoading: true
      };
    case ADD_USERS_TO_WORK_GROUP_FULFILLED:
      return {
        ...state,
        usersNotInWorkGroupLoading: false,
        alertMessage: 'Đã thêm người dùng vào nhóm thành công'
      };
    case ADD_USERS_TO_WORK_GROUP_REJECTED:
      return {
        ...state,
        usersNotInWorkGroupLoading: false,
        alertMessage: 'Có lỗi, không thể thêm người dùng vào nhóm này'
      };

    case REMOVE_USER_TO_WORK_GROUP_PENDING:
      return {
        ...state,
        usersNotInWorkGroupLoading: true
      };
    case REMOVE_USER_TO_WORK_GROUP_FULFILLED:
      return {
        ...state,
        usersNotInWorkGroupLoading: false,
        alertMessage: 'Đã Xóa dữ liệu thành công người dùng khỏi nhóm.'
      };
    case REMOVE_USER_TO_WORK_GROUP_REJECTED:
      return {
        ...state,
        usersNotInWorkGroupLoading: false,
        alertMessage: 'Có lỗi, không thể xóa người dùng khỏi nhóm'
      };
    //#endregion

    //#get work group by user
    case GET_WORK_GROUP_BY_USER_ID_PENDING:
      return {
        ...state,
        listLoading: true
      };
    case GET_WORK_GROUP_BY_USER_ID_FULFILLED:
      return {
        ...state,
        workGroups: action.payload.data.data,
        listLoading: false
      };
    case GET_WORK_GROUP_BY_USER_ID_REJECTED:
      return {
        ...state,
        alertMessage: getErrorMessage(action, 'Đã xảy ra lỗi'),
        listLoading: false
      };

    default:
      return state;
  }
};
