import httpClient from 'HttpClient';
// define const actions
export const EXPIRATION_DATE_ACTIONS = {
  GET_DATA_LIST_PER_PAGE: 'GET_DATA_LIST_PER_PAGE',
  GET_DATA_LIST_PER_PAGE_PENDING: 'GET_DATA_LIST_PER_PAGE_PENDING',
  GET_DATA_LIST_PER_PAGE_FULFILLED: 'GET_DATA_LIST_PER_PAGE_FULFILLED',
  GET_DATA_LIST_PER_PAGE_REJECTED: 'GET_DATA_LIST_PER_PAGE_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  DELETE_DATA: 'DELETE_DATA',
  DELETE_DATA_PENDING: 'DELETE_DATA_PENDING',
  DELETE_DATA_FULFILLED: 'DELETE_DATA_FULFILLED',
  DELETE_DATA_REJECTED: 'DELETE_DATA_REJECTED',

  CREATE_DATA: 'CREATE_DATA',
  CREATE_DATA_PENDING: 'CREATE_DATA_PENDING',
  CREATE_DATA_FULFILLED: 'CREATE_DATA_FULFILLED',
  CREATE_DATA_REJECTED: 'CREATE_DATA_REJECTED',

  UPDATE_DATA: 'UPDATE_DATA',
  UPDATE_DATA_PENDING: 'UPDATE_DATA_PENDING',
  UPDATE_DATA_FULFILLED: 'UPDATE_DATA_FULFILLED',
  UPDATE_DATA_REJECTED: 'UPDATE_DATA_REJECTED',

  GET_OLD_SERIAL: 'GET_OLD_SERIAL',
  GET_OLD_SERIAL_PENDING: 'GET_OLD_SERIAL_PENDING',
  GET_OLD_SERIAL_FULFILLED: 'GET_OLD_SERIAL_FULFILLED',
  GET_OLD_SERIAL_REJECTED: 'GET_OLD_SERIAL_REJECTED'
};

// define actions
export const getListDataPerpage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: EXPIRATION_DATE_ACTIONS.GET_DATA_LIST_PER_PAGE,
    payload: httpClient.get(`/ReplacementHistory/paging/page?Page=${pageNo}&PageSize=${pageSize}`)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

// export const actionSearch = (value, pageNo, pageSize) => (dispatch) => {
//   return dispatch({
//     type: EXPIRATION_DATE_ACTIONS.SEARCH_DATA,
//     payload: httpClient.get(
//       `/ReplacementHistory/Search?Value=${value}&Page=${pageNo}&PageSize=${pageSize}`
//     ),
//   });
// };
export const actionSearch = (params) => (dispatch) => {
  let url = '/ReplacementHistory/Search';
  return dispatch({
    type: EXPIRATION_DATE_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(url, { params })
  });
};
export const actionSearchAll = (params) => (dispatch) => {
  let url = '/ReplacementHistory/Search';
  return dispatch({
    // type: EXPIRATION_DATE_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(url, { params })
  });
};

export const deleteData = (id) => (dispatch) => {
  return dispatch({
    type: EXPIRATION_DATE_ACTIONS.DELETE_DATA,
    payload: httpClient.delete(`/ReplacementHistory/delete/${id}`)
  });
};

export const createData = (payload) => (dispatch) => {
  return dispatch({
    type: EXPIRATION_DATE_ACTIONS.CREATE_DATA,
    payload: httpClient.post(`/ReplacementHistory/create`, payload)
  });
};

export const updateData = (payload) => (dispatch) => {
  return dispatch({
    type: EXPIRATION_DATE_ACTIONS.UPDATE_DATA,
    payload: httpClient.put(`/ReplacementHistory/update`, payload)
  });
};

export const getOldSerialNo = (carId, sparepartId, moocId, tyrePositionId) => (dispatch) => {
  const params = {
    tractorTrailerId: carId,
    sparePartId: sparepartId,
    semiTrailerId: moocId,
    tyrePositionId: tyrePositionId
  };
  return dispatch({
    type: EXPIRATION_DATE_ACTIONS.GET_OLD_SERIAL,
    payload: httpClient.get(`ReplacementHistory/getSparePartSerialNo?`, { params: params })
  });
};
