import api from 'HttpClient';

// const type of function send data
export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_PENDING = 'SEND_EMAIL_PENDING';
export const SEND_EMAIL_FULFILLED = 'SEND_EMAIL_FULFILLED';
export const SEND_EMAIL_REJECTED = 'SEND_EMAIL_REJECTED';
export const sendEmail = (payload) => (dispatch) => {
  return dispatch({
    type: SEND_EMAIL,
    payload: api.post(`/Email/send`, payload)
  });
};

export const SET_OPEN_EMAIL_MODAL = 'SET_OPEN_EMAIL_MODAL';
export const setOpenEmailModal = (data) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_EMAIL_MODAL,
    payload: { data }
  });
};

export const SET_CLOSE_EMAIL_MODAL = 'SET_CLOSE_EMAIL_MODAL';
export const setCloseEmailModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_EMAIL_MODAL
  });
};

export const SET_LOADING_EMAIL = 'SET_LOADING_EMAIL';
export const setLoadingEmail = () => (dispatch) => {
  return dispatch({
    type: SET_LOADING_EMAIL
  });
};

export const FETCH_TEMPLATE_EMAIL = 'FETCH_TEMPLATE_EMAIL';
export const FETCH_TEMPLATE_EMAIL_PENDING = 'FETCH_TEMPLATE_EMAIL_PENDING';
export const FETCH_TEMPLATE_EMAIL_FULFILLED = 'FETCH_TEMPLATE_EMAIL_FULFILLED';
export const FETCH_TEMPLATE_EMAIL_REJECTED = 'FETCH_TEMPLATE_EMAIL_REJECTED';
export const fetchTemplateEmail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_TEMPLATE_EMAIL,
    payload: api.get(`/Email/GetTemplate`)
  });
};

export const SET_OPEN_SELECT_IMAGE_MODAL = 'SET_OPEN_SELECT_IMAGE_MODAL';
export const setOpenSelectImageModal = () => (dispatch) => {
  return dispatch({
    type: SET_OPEN_SELECT_IMAGE_MODAL
  });
};

export const SET_CLOSE_SELECT_IMAGE_MODAL = 'SET_CLOSE_SELECT_IMAGE_MODAL';
export const setCloseSelectImageModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_SELECT_IMAGE_MODAL
  });
};

export const FETCH_IMAGE_FROM_SERVER = 'FETCH_IMAGE_FROM_SERVER';
export const FETCH_IMAGE_FROM_SERVER_PENDING = 'FETCH_IMAGE_FROM_SERVER_PENDING';
export const FETCH_IMAGE_FROM_SERVER_FULFILLED = 'FETCH_IMAGE_FROM_SERVER_FULFILLED';
export const FETCH_IMAGE_FROM_SERVER_REJECTED = 'FETCH_IMAGE_FROM_SERVER_REJECTED';
export const fetchImageEmail = (url, funcProcessDataImage) => (dispatch) => {
  return dispatch({
    type: FETCH_IMAGE_FROM_SERVER,
    payload: api.get(url),
    meta: {
      funcProcessDataImage
    }
  });
};

export const UPDATE_IMAGES_EMAIL = 'UPDATE_IMAGES_EMAIL';
export const setImagesEmail = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_IMAGES_EMAIL,
    payload
  });
};
