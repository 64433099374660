import {
  getAllPortDepots,
  getPortDepotIfoOfCustombrach,
  getPortDepotsByCustomBranch} from "services/portdepot";

// define const actions
export const PORT_DEPOT_ACTIONS = {
  GET_ALL_PORT_DEPOT: "GET_ALL_PORT_DEPOT",
  GET_ALL_PORT_DEPOT_PENDING: "GET_ALL_PORT_DEPOT_PENDING",
  GET_ALL_PORT_DEPOT_FULFILLED: "GET_ALL_PORT_DEPOT_FULFILLED",
  GET_ALL_PORT_DEPOT_REJECTED: "GET_ALL_PORT_DEPOT_REJECTED",

  GET_PORT_DEPOT_CUSTOM_BRANCH: "GET_PORT_DEPOT_CUSTOM_BRANCH",
  GET_PORT_DEPOT_CUSTOM_BRANCH_PENDING: "GET_PORT_DEPOT_CUSTOM_BRANCH_PENDING",
  GET_PORT_DEPOT_CUSTOM_BRANCH_FULFILLED: "GET_PORT_DEPOT_CUSTOM_BRANCH_FULFILLED",
  GET_PORT_DEPOT_CUSTOM_BRANCH_REJECTED: "GET_PORT_DEPOT_CUSTOM_BRANCH_REJECTED",

  GET_PORT_OF_CUSTOM_BRANCH : "GET_PORT_OF_CUSTOM_BRANCH",
  GET_PORT_OF_CUSTOM_BRANCH_PENDING: "GET_PORT_OF_CUSTOM_BRANCH_PENDING",
  GET_PORT_OF_CUSTOM_BRANCH_FULFILLED: "GET_PORT_OF_CUSTOM_BRANCH_FULFILLED",
  GET_PORT_OF_CUSTOM_BRANCH_REJECTED: "GET_PORT_OF_CUSTOM_BRANCH_REJECTED",
};


// define actions
export const actionGetAllPortDepots = () => dispatch => {
  return dispatch({
    type: PORT_DEPOT_ACTIONS.GET_ALL_PORT_DEPOT,
    payload: getAllPortDepots()
  });
};

export const actionGetPortDepotsByCustomBranch = (id, type) => dispatch => {
  return dispatch({
    type: PORT_DEPOT_ACTIONS.GET_PORT_DEPOT_CUSTOM_BRANCH,
    payload: getPortDepotsByCustomBranch(id),
    meta: type
  });
};

export const actionGetPortDepotIfoOfCustombrach = (id) => dispatch => {
  return dispatch({
    type: PORT_DEPOT_ACTIONS.GET_PORT_OF_CUSTOM_BRANCH,
    payload: getPortDepotIfoOfCustombrach(id)
  })
}
