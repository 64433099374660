import {
  ADD_SIGNATURE_FULFILLED,
  ADD_SIGNATURE_PENDING,
  ADD_SIGNATURE_REJECTED,
  APPROVAL_DELETE_FILE_FULFILLED,
  APPROVAL_DELETE_FILE_PENDING,
  APPROVAL_DELETE_FILE_REJECTED,
  APPROVAL_GET_SPAREPART_ORDER_BY_ID_FULFILLED,
  APPROVAL_GET_SPAREPART_ORDER_BY_ID_PENDING,
  APPROVAL_GET_SPAREPART_ORDER_BY_ID_REJECTED,
  CREATE_APRROVAL_FULFILLED,
  CREATE_APRROVAL_PENDING,
  CREATE_APRROVAL_REJECTED,
  DELETE_APRROVAL_FULFILLED,
  DELETE_APRROVAL_PENDING,
  DELETE_APRROVAL_REJECTED,
  DELETE_SIGNATURE_FULFILLED,
  DELETE_SIGNATURE_PENDING,
  DELETE_SIGNATURE_REJECTED,
  FETCH_ALL_APPROVAL_STATUSES_FULFILLED,
  FETCH_ALL_APPROVAL_STATUSES_PENDING,
  FETCH_ALL_APPROVAL_STATUSES_REJECTED,
  FETCH_ALL_APRROVALS_FULFILLED,
  FETCH_ALL_APRROVALS_PENDING,
  FETCH_ALL_APRROVALS_REJECTED,
  FETCH_APRROVAL_DETAIL_FULFILLED,
  FETCH_APRROVAL_DETAIL_PENDING,
  FETCH_APRROVAL_DETAIL_REJECTED,
  FETCH_APRROVALS_FULFILLED,
  FETCH_APRROVALS_PENDING,
  FETCH_APRROVALS_REJECTED,
  SET_CLOSE_APRROVAL_MODAL,
  SET_OPEN_APRROVAL_MODAL,
  UPDATE_APRROVAL_FULFILLED,
  UPDATE_APRROVAL_PENDING,
  UPDATE_APRROVAL_REJECTED} from 'actions';
import { getErrorMessage } from 'commons/ReducerUtils';
import {
  DEFAULT_PAGE_SIZE,
  MSG_ADD_FAILURE,
  MSG_ADD_SUCCESS,
  MSG_DELETE_FAILURE,
  MSG_DELETE_SUCCESS,
  MSG_EDIT_FAILURE,
  MSG_EDIT_SUCCESS,
  MSG_GET_DETAIL_FAILURE,
  MSG_PAGING_FAILURE,
  MSG_TYPE_ERROR,
  MSG_TYPE_SUCCESS} from 'const/common';
const INIT_STATE = {
  //combobox: get all
  allAprrovals: [],
  // paging
  aprrovals: [],
  pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
  listLoading: false,

  // add/edit modal
  isOpenFormModal: false, // Show/hidden update modal
  aprroval: {},
  formLoading: false,
  //delete
  deleteLoading: false,
  // other
  showMessage: false,
  alertMessage: '',
  formAlertMessage: '',
  messageType: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region update modal
    case SET_OPEN_APRROVAL_MODAL: {
      return {
        ...state,
        isOpenFormModal: true,
        aprroval: action.payload.data,
        type: action.payload.type
      };
    }
    case SET_CLOSE_APRROVAL_MODAL: {
      return {
        ...state,
        isOpenFormModal: false,
        aprroval: INIT_STATE.aprroval
      };
    }
    //#endregion

    //#region getall
    case FETCH_ALL_APRROVALS_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }
    case FETCH_ALL_APRROVALS_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        allAprrovals: action.payload.data.data,
        showMessage: false
      };
    }
    case FETCH_ALL_APRROVALS_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region paging
    case FETCH_APRROVALS_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }

    case FETCH_APRROVALS_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        aprrovals: action.payload.data.data ?? INIT_STATE.aprrovals,
        pagination: action.payload.data.pagination ?? INIT_STATE.pagination,
        showMessage: false
      };
    }
    case FETCH_APRROVALS_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region create data
    case CREATE_APRROVAL_PENDING:
      return {
        ...state,
        formLoading: true
      };
    case CREATE_APRROVAL_FULFILLED: {
      const aprroval = action.payload.data;
      const aprrovals = state.aprrovals.map((item) => {
        return item.Id === aprroval.Id ? aprroval : item;
      });
      return {
        ...state,
        aprrovals: [...aprrovals],
        formLoading: false,
        showMessage: true,
        formAlertMessage: MSG_ADD_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case CREATE_APRROVAL_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_ADD_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region update data
    case UPDATE_APRROVAL_PENDING:
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    case UPDATE_APRROVAL_FULFILLED: {
      const aprroval = action.meta;
      let aprrovals = [...state.aprrovals];
      aprrovals = aprrovals.map((item) => {
        return item.Id === aprroval.Id ? aprroval : item;
      });
      return {
        ...state,
        aprrovals,
        formLoading: false,
        showMessage: true,
        formAlertMessage: MSG_EDIT_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case UPDATE_APRROVAL_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_EDIT_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region  get detail
    case FETCH_APRROVAL_DETAIL_PENDING:
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    case FETCH_APRROVAL_DETAIL_FULFILLED:
      return {
        ...state,
        aprroval: action.payload.data,
        formLoading: false,
        showMessage: false
      };
    case FETCH_APRROVAL_DETAIL_REJECTED:
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        alertMessage: MSG_GET_DETAIL_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    //#endregion

    //#region delete data
    case DELETE_APRROVAL_PENDING:
      return {
        ...state,
        deleteLoading: true
      };
    case DELETE_APRROVAL_FULFILLED:
      return {
        ...state,
        isDelete: true,
        deleteLoading: false,
        showMessage: true,
        alertMessage: MSG_DELETE_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case DELETE_APRROVAL_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_DELETE_FAILURE) || action?.payload?.response?.data?.message;
      return {
        ...state,
        deleteLoading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }

    //#region getall statuses
    case FETCH_ALL_APPROVAL_STATUSES_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }
    case FETCH_ALL_APPROVAL_STATUSES_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        allApprovalStatuses: action.payload.data.data,
        showMessage: false
      };
    }
    case FETCH_ALL_APPROVAL_STATUSES_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //add signature for approvement form
    case ADD_SIGNATURE_PENDING:
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    case ADD_SIGNATURE_FULFILLED: {
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        alertMessage: MSG_EDIT_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case ADD_SIGNATURE_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_ADD_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }

    // delete signature
    case DELETE_SIGNATURE_PENDING:
      return {
        ...state,
        deleteLoading: true
      };
    case DELETE_SIGNATURE_FULFILLED:
      return {
        ...state,
        isDelete: true,
        deleteLoading: false,
        showMessage: true,
        alertMessage: MSG_DELETE_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case DELETE_SIGNATURE_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_DELETE_FAILURE);
      return {
        ...state,
        deleteLoading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    
    //#region SparePartOrder getById
    case APPROVAL_GET_SPAREPART_ORDER_BY_ID_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }
    case APPROVAL_GET_SPAREPART_ORDER_BY_ID_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        sparePartOrder: action.payload.data.data,
        showMessage: false
      };
    }
    case APPROVAL_GET_SPAREPART_ORDER_BY_ID_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    // delete files
    case APPROVAL_DELETE_FILE_PENDING:
      return {
        ...state,
        listLoading: false,
        showMessage: false
      };

    case APPROVAL_DELETE_FILE_FULFILLED:
      return {
        ...state,
        listLoading: false,
        messageType: 'success',
        showMessage: false,
        // msg: 'Xóa dữ liệu thành công'
      };

    case APPROVAL_DELETE_FILE_REJECTED:
      return {
        ...state,
        listLoading: false,
        messageType: 'error',
        showMessage: true,
        formAlertMessage: 'Cập nhật dữ liệu thất bại'
      };
    default:
      return state;
  }
};
