import { DOWNPAYMENT_ORDER_ACTIONS } from 'actions/downPaymentOfOrder';
import { getValue } from 'commons/SelectUtils';
import { isNull } from 'commons/utils';
import _ from 'lodash';
const INIT_STATE = {
  listData: [],
  listUnit: [],
  listFeeType: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  isCloseModal: false,
  isUpdateSubmit: true,
  statusDownPayment: {},
  extension: {},
  isReload: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DOWNPAYMENT_ORDER_ACTIONS.GET_LIST_DOWNPAYMENT_ORDER_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.GET_LIST_DOWNPAYMENT_ORDER_FULFILLED: {
      return {
        ...state,
        loading: false,
        listData: action.payload.data.data.data,
        isSearch: false,
        isCloseModal: false,
        // isUpdateSubmit: true,
        statusDownPayment: action.payload.data.data.status,
        extension: action.payload.data.data.extension,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.GET_LIST_DOWNPAYMENT_ORDER_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.CREATE_DOWN_PAYMENT_ORDER_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.CREATE_DOWN_PAYMENT_ORDER_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isShowMessage: true,
        isCloseModal: true,
        isReload: true
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.CREATE_DOWN_PAYMENT_ORDER_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Tạo dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.DELETE_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.DELETE_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Xóa dữ liệu thành công',
        isShowMessage: true,
        isReload: true
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.DELETE_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Xóa dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.UPDATE_DOWN_PAYMENT_ORDER_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.UPDATE_DOWN_PAYMENT_ORDER_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công',
        isShowMessage: true,
        isCloseModal: true,
        isUpdateSubmit: false,
        isReload: true
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.UPDATE_DOWN_PAYMENT_ORDER_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Cập nhật dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        isReload: false
      };
    }
    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.CREATE_TEMPLATE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.CREATE_TEMPLATE_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isShowMessage: true,
        isCloseModal: true,
        isReload: true
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.CREATE_TEMPLATE_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Tạo dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        isReload: false
      };
    }

    case DOWNPAYMENT_ORDER_ACTIONS.GET_UNIT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.GET_UNIT_FULFILLED: {
      return {
        ...state,
        loading: false,
        listUnit: action.payload.data.data,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.GET_UNIT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Có lỗi xảy ra !',
        isReload: false
      };
    }

    case DOWNPAYMENT_ORDER_ACTIONS.GET_FEE_TYPE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.GET_FEE_TYPE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listFeeType: action.payload.data.data,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.GET_FEE_TYPE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Có lỗi xảy ra !',
        isReload: false
      };
    }

    // case DOWNPAYMENT_ORDER_ACTIONS.CHANGE_DATA_EDIT_TABEL: {
    //   const data = action.payload;
    //   let item = _.find(state.listData, function (item) {
    //     return item.Id === data.Id;
    //   });
    //   let field = action.meta.fieldName;
    //   let error = { ...item.error, ...action.meta?.error };
    //   let itemUpdate = [{ ...item, ...data, error: error }];
    //   console.log("item update", state.listData);
    //   let dataUpdate = state.listData.map(
    //     (obj) => itemUpdate.find((o) => o.Id === obj.Id) || obj
    //   );
    //   return {
    //     ...state,
    //     loading: false,
    //     listData: [...dataUpdate],
    //     isUpdateSubmit: action.meta.message !== undefined ? false : true,
    //   };
    // }

    case DOWNPAYMENT_ORDER_ACTIONS.CHANGE_DATA_EDIT_TABEL: {
      const { fieldName, isConditionQuantityWeight, checkValueIsBoolean, value } = action.meta;
      let dataUpdated = [];
      if (isConditionQuantityWeight === 2 && fieldName === 'Quantity') {
        const data = action.payload;
        let item = _.find(state.listData, function (item) {
          return item.Id === data.Id;
        });
        let error = { ...item.error, ...action.meta?.error };
        let result = state.listData.map((el) => ({
          ...el,
          Quantity: el.ConditionQuantityWeightId === 2 ? data.Quantity : el.Quantity,
          error: el.ConditionQuantityWeightId === 2 ? error : el.error,
          isChange: el.ConditionQuantityWeightId === 2 ? true : false,
          TotalAmount:
            parseInt(el.UnitPrice ? el.UnitPrice : 0) *
            parseInt(el.ConditionQuantityWeightId === 2 ? data.Quantity : el.Quantity)
        }));

        dataUpdated = result;
      } else if (isConditionQuantityWeight === 2 && fieldName === 'Note') {
        const data = action.payload;
        let item = _.find(state.listData, function (item) {
          return item.Id === data.Id;
        });
        let error = { ...item.error, ...action.meta?.error };
        let result = state.listData.map((el) => ({
          ...el,
          Note: el.ConditionQuantityWeightId === 2 ? data.Note : el.Note,
          error: el.ConditionQuantityWeightId === 2 ? error : el.error,
          isChange: el.ConditionQuantityWeightId === 2 ? true : false
        }));

        dataUpdated = result;
      } else {
        const data =
          typeof value === checkValueIsBoolean ? { ...action.payload, CheckSettlement: value } : action.payload;
        let item = _.find(state.listData, function (item) {
          return item.Id === data.Id;
        });
        let error = { ...item.error, ...action.meta?.error };
        let itemUpdate = [{ ...item, ...data, error: error }];
        let dataUpdate = state.listData.map((obj) => itemUpdate.find((o) => o.Id === obj.Id) || obj);
        dataUpdated = [...dataUpdate];
      }
      return {
        ...state,
        loading: false,
        listData: dataUpdated,
        isUpdateSubmit: action.meta.message ? false : true
      };
    }

    case DOWNPAYMENT_ORDER_ACTIONS.ON_KEY_UP_FIELD: {
      const data = action.payload;
      let item = _.find(state.listData, function (item) {
        return item.Id === data.Id;
      });
      let isFocusTotal = data.isFocusTotal;
      let dataUpdateFocus =
        !isFocusTotal && item
          ? {
              ...data,
              TotalAmount: parseInt(item.UnitPrice) * parseInt(item.Quantity),
              ContractTotalAmount: parseInt(item.ContractUnitPrice) * parseInt(item.Quantity)
            }
          : {
              ...data
            };
      let itemUpdate = [{ ...item, ...dataUpdateFocus }];
      let dataUpdate = state.listData.map((obj) => itemUpdate.find((o) => o.Id === obj.Id) || obj);
      return {
        ...state,
        loading: false,
        listData: [...dataUpdate]
      };
    }

    // change editable field unit price contract
    case DOWNPAYMENT_ORDER_ACTIONS.CHANGE_DATA_EDIT_UNITPRICE_CONTRACT: {
      const { fieldName, isConditionQuantityWeight, checkValueIsBoolean, value } = action.meta;
      let dataUpdated = [];
      if (isConditionQuantityWeight === 2 && fieldName === 'Quantity') {
        const data = action.payload;
        let item = _.find(state.listData, function (item) {
          return item.Id === data.Id;
        });
        let error = { ...item.error, ...action.meta?.error };
        let result = state.listData.map((el) => ({
          ...el,
          Quantity: el.ConditionQuantityWeightId === 2 ? data.Quantity : el.Quantity,
          error: el.ConditionQuantityWeightId === 2 ? error : el.error,
          isChange: el.ConditionQuantityWeightId === 2 ? true : false,
          ContractTotalAmount:
            parseInt(el.ContractUnitPrice ? el.ContractUnitPrice : 0) *
            parseInt(el.ConditionQuantityWeightId === 2 ? data.Quantity : el.Quantity)
        }));

        dataUpdated = result;
      } else {
        const data =
          typeof value === checkValueIsBoolean ? { ...action.payload, CheckSettlement: value } : action.payload;
        let item = _.find(state.listData, function (item) {
          return item.Id === data.Id;
        });
        let error = { ...item.error, ...action.meta?.error };
        let itemUpdate = [{ ...item, ...data, error: error }];
        let dataUpdate = state.listData.map((obj) => itemUpdate.find((o) => o.Id === obj.Id) || obj);
        dataUpdated = [...dataUpdate];
      }
      return {
        ...state,
        loading: false,
        listData: dataUpdated,
        isUpdateSubmit: action.meta.message ? false : true
      };
    }

    //add in many down payment order
    case DOWNPAYMENT_ORDER_ACTIONS.GET_LIST_IN_PAYMENT_ADDED_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.GET_LIST_IN_PAYMENT_ADDED_FULFILLED: {
      return {
        ...state,
        loading: false,
        listData: action.payload.data.data.data,
        isSearch: false,
        isCloseModal: false,
        // isUpdateSubmit: true,
        statusDownPayment: action.payload.data.data.status,
        extension: action.payload.data.data.extension,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.GET_LIST_IN_PAYMENT_ADDED_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        isReload: false
      };
    }

    case DOWNPAYMENT_ORDER_ACTIONS.CREATE_ITEM_PAYMENT_ADDED_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.CREATE_ITEM_PAYMENT_ADDED_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isShowMessage: true,
        isCloseModal: true,
        isReload: true
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.CREATE_ITEM_PAYMENT_ADDED_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Tạo dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        isReload: false
      };
    }

    case DOWNPAYMENT_ORDER_ACTIONS.GET_LIST_FEES_BY_INSTALMENTS_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.GET_LIST_FEES_BY_INSTALMENTS_FULFILLED: {
      return {
        ...state,
        loading: false,
        listData: action.payload.data.data.data,
        isSearch: false,
        isCloseModal: false,
        // isUpdateSubmit: true,
        statusDownPayment: action.payload.data.data.status,
        extension: action.payload.data.data.extension,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.GET_LIST_FEES_BY_INSTALMENTS_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        isReload: false
      };
    }
    case DOWNPAYMENT_ORDER_ACTIONS.CHANGE_ALL_ACCOUNTANT: {
      const { accountantSelected, advanceDate } = action.payload;
      const accountantId = getValue(accountantSelected);
      const res = [...state.listData];
      const itemsNotChangeAccountant = res.filter((el) => isNull(el.AccountantId) && !el.IsSave);
      const dataUpdated = itemsNotChangeAccountant.map((el) => {
        return {
          ...el,
          AccountantId: accountantId ? accountantId : el.AccountantId,
          AdvanceDate: isNull(el.AdvanceDate) && advanceDate ? advanceDate : el.AdvanceDate,
          isChange: true
        };
      });
      let results = state.listData.map((obj) => dataUpdated.find((o) => o.Id === obj.Id) || obj);
      return {
        ...state,
        isUpdateSubmit: true,
        listData: [...results]
      };
    }

    case DOWNPAYMENT_ORDER_ACTIONS.CHANGE_SELECT_ACTION: {
      const { item, field, option } = action.payload;
      const { Id } = item;
      const value = getValue(option);
      const res = [...state.listData];
      const itemUpdate = res.find((el) => el.Id === Id);
      const itemUpdateIndex = res.findIndex((el) => el.Id === Id);
      const itemUpdated = { ...itemUpdate, [field]: value, isChange: true };
      let dataUpdated = [...res];
      dataUpdated.splice(itemUpdateIndex, 1, itemUpdated);
      return {
        ...state,
        isUpdateSubmit: true,
        listData: [...dataUpdated]
      };
    }

    default:
      return state;
  }
};
