import api from 'HttpClient/index';

export const getProductCategoryPerPage = (pageNo, pageSize) => {
  return api.get(`/goodsinfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`);
};

export const getProductCategoryPerPageAll = (params) => {
  return api.get(`/goodsinfo/paging/page`, { params });
};

export const getAllProductCategory = () => {
  return api.get(`/goodsinfo/getAll`);
};

export const getProductCategoryByID = (id) => {
  return api.get(`/goodsinfo/getById/${id}`);
};

export const createProductCategory = (payload) => {
  return api.post(`/goodsinfo/create`, payload);
};

export const updateProductCategory = (payload) => {
  return api.put(`/goodsinfo/update`, payload);
};

export const deleteProductCategory = (id) => {
  return api.delete(`/goodsinfo/deleteById/${id}`);
};

export const searchProductCategory = (value, pageNo, pageSize, goodsCategoryId) => {
  const params = {
    value: value,
    Page: pageNo,
    PageSize: pageSize,
    goodsCategoryId: goodsCategoryId
  };
  return api.get(`/goodsinfo/search`, { params: params });
};

export const searchProductCategoryAll = (params) => {
  return api.get(`/goodsinfo/search`, { params });
};
