import { UNIT_TYPE_ACTIONS } from 'actions/unittype';

const INIT_STATE = {
  unitType: { data: [] }
};

const unitTypeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // get all goods type
    case UNIT_TYPE_ACTIONS.GET_ALL_UNIT_TYPE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case UNIT_TYPE_ACTIONS.GET_ALL_UNIT_TYPE_FULFILLED: {
      const unitType = {
        data: action.payload.data.data
      };
      return {
        ...state,
        unitType: unitType,
        isLoading: false
      };
    }

    case UNIT_TYPE_ACTIONS.GET_ALL_UNIT_TYPE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // get unit type by category
    case UNIT_TYPE_ACTIONS.GET_UNIT_TYPE_BY_CATEGORY_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case UNIT_TYPE_ACTIONS.GET_UNIT_TYPE_BY_CATEGORY_FULFILLED: {
      const unitType = {
        data: action.payload.data.data
      };
      return {
        ...state,
        unitType: unitType,
        isLoading: false
      };
    }

    case UNIT_TYPE_ACTIONS.GET_UNIT_TYPE_BY_CATEGORY_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    default:
      return state;
  }
};

export default unitTypeReducer;
