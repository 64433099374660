import api from 'HttpClient';

export const getAllRepair = () => {
  return api.get(`/repairing/getAll`);
};

export const getRepairPerPage = (pageNo, pageSize) => {
  return api.get(`/repairing/paging/page?page=${pageNo}&pagesize=${pageSize}`);
};

export const createRepair = (payload) => {
  return api.post(`/repairing/create`, payload);
};
export const createRepairs = (payload) => {
  return api.post(`/repairing/createMany`, payload);
};
export const updateRepair = (payload) => {
  return api.put(`/repairing/update`, payload);
};
export const deleteRepair = (id) => {
  return api.delete(`/repairing/deleteById/${id}`);
};
export const getRepairingById = (id) => {
  return api.get(`/repairing/getById/${id}`);
};

export const searchData = (payload) => {
  //   return api.get(`/repairing/search?value=${payload.value}&page=${payload.pageNo}&pageSize=${payload.pageSize}`);
  const payloadSearch = {
    ...payload,
    page: payload?.page,
    pagesize: payload?.pageSize,
    Fromdate: payload?.fromDate,
    Todate: payload?.toDate,
    dateMode: payload?.dateMode,
    searchMode: payload?.searchMode,
    EmployeeCode: payload?.EmployeesCode
  };
  return api.get(`/repairing/search`, { params: payloadSearch });
};

export const updateRepairFee = (payload, type) => {
  if (type === 'payment') {
    return api.put(`/repairingdetail/updateRepairFee`, payload);
  } else {
    return api.put(`/RepairingDetail/updateActualRepairFee`, payload);
  }
};

export const getEmployees = () => {
  return api.get(`/employeeinfo/getAll/RepairManager`);
};
