import httpClient from 'HttpClient';
import moment from 'moment';

export const NOTIFICATION_ACTIONS = {
  GET_NOTIFICATION_PER_PAGE: 'GET_NOTIFICATION_PER_PAGE',
  GET_NOTIFICATION_PER_PAGE_PENDING: 'GET_NOTIFICATION_PER_PAGE_PENDING',
  GET_NOTIFICATION_PER_PAGE_FULFILLED: 'GET_NOTIFICATION_PER_PAGE_FULFILLED',
  GET_NOTIFICATION_PER_PAGE_REJECTED: 'GET_NOTIFICATION_PER_PAGE_REJECTED',

  CREATE_NOTIFICATION: 'CREATE_NOTIFICATION',
  CREATE_NOTIFICATION_PENDING: 'CREATE_NOTIFICATION_PENDING',
  CREATE_NOTIFICATION_FULFILLED: 'CREATE_NOTIFICATION_FULFILLED',
  CREATE_NOTIFICATION_REJECTED: 'CREATE_NOTIFICATION_REJECTED',

  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  UPDATE_NOTIFICATION_PENDING: 'UPDATE_NOTIFICATION_PENDING',
  UPDATE_NOTIFICATION_FULFILLED: 'UPDATE_NOTIFICATION_FULFILLED',
  UPDATE_NOTIFICATION_REJECTED: 'UPDATE_NOTIFICATION_REJECTED',

  DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
  DELETE_NOTIFICATION_PENDING: 'DELETE_NOTIFICATION_PENDING',
  DELETE_NOTIFICATION_FULFILLED: 'DELETE_NOTIFICATION_FULFILLED',
  DELETE_NOTIFICATION_REJECTED: 'DELETE_NOTIFICATION_REJECTED'
};

export const actionGetNotificationByPage = (payload) => (dispatch) => {
  return dispatch({
    type: NOTIFICATION_ACTIONS.GET_NOTIFICATION_PER_PAGE,
    payload: httpClient.get(`/notification/paging/page`, { params: payload })
  });
};
export const actionGetNotificationByPageAll = (payload) => (dispatch) => {
  return dispatch({
    // type: NOTIFICATION_ACTIONS.GET_NOTIFICATION_PER_PAGE,
    payload: httpClient.get(`/notification/paging/page`, { params: payload })
  });
};

export const actionCreateNotification = (payload) => (dispatch) => {
  return dispatch({
    type: NOTIFICATION_ACTIONS.CREATE_NOTIFICATION,
    payload: httpClient.post(`/notification/create`, payload)
  });
};

export const actionUpdateNotification = (payload) => (dispatch) => {
  return dispatch({
    type: NOTIFICATION_ACTIONS.UPDATE_NOTIFICATION,
    payload: httpClient.put(`/notification/update`, payload)
  });
};

export const actionDeleteNotification = (id) => (dispatch) => {
  return dispatch({
    type: NOTIFICATION_ACTIONS.DELETE_NOTIFICATION,
    payload: httpClient.delete(`/notification/delete/${id}`)
  });
};

// const type of function get data per page
export const FETCH_WORK_NOTIFICATIONS = 'FETCH_WORK_NOTIFICATIONS';
export const FETCH_WORK_NOTIFICATIONS_PENDING = 'FETCH_WORK_NOTIFICATIONS_PENDING';
export const FETCH_WORK_NOTIFICATIONS_FULFILLED = 'FETCH_WORK_NOTIFICATIONS_FULFILLED';
export const FETCH_WORK_NOTIFICATIONS_REJECTED = 'FETCH_WORK_NOTIFICATIONS_REJECTED';
export const fetchWorkNotifications = () => (dispatch) => {
  const now = moment().format('YYYY-MM-DD');
  return dispatch({
    type: FETCH_WORK_NOTIFICATIONS,
    payload: httpClient.get(`/WorkingInfo/Work`, { params: { fromDate: now, toDate: now } })
  });
};
