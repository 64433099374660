import api from "HttpClient";

// const type of function get data per page
export const FETCH_SETTING = "FETCH_SETTING";
export const FETCH_SETTING_PENDING = "FETCH_SETTING_PENDING";
export const FETCH_SETTING_FULFILLED = "FETCH_SETTING_FULFILLED";
export const FETCH_SETTING_REJECTED = "FETCH_SETTING_REJECTED";
export const fetchSetting = (screenName) => dispatch => {
    return dispatch({
        type: FETCH_SETTING,
        payload: api.get(`/SysFormFieldSetting/getByScreenName`, { params: { screenName }})
    });
};