import api from 'HttpClient';

export const getDataSettlementRepair = id => {
    return api.get(`/RepairingSettlement/${id}/getList`)
}

export const createDataSettlementRepair = (payload) => {
    return api.post(`/RepairingSettlement/${payload.RepairingId}/create?isOutSource=${payload.OutSource}`, payload)
}

export const updateDataSettlementRepair = (payload) => {
    return api.put(`/RepairingSettlement/${payload.RepairingId}/update?isOutSource=${payload.OutSource}`, payload)
}

export const deleteDataSettlementRepair = (repairingDetailId,repairingID, OutSource) => {
    return api.delete(`/RepairingSettlement/deleteById/${repairingDetailId}?isOutSource=${OutSource}`)
}
