import { SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS } from 'actions/settlementFollowCustomer';
import _ from 'lodash';
const INIT_STATE = {
  listData: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  statusSettlementCus: {},
  extension: {},
  weightSummary: {},
  isUpdateSubmit: true,
  reload: false,
  summaryDatas: [],
  consignmentInfos: [],
  dataHasNotBillInfo: [],
  dataCustomersettlement: []
};

const indexReferencefieldRelated = {
  ActualUnitPrice: 0,
  ActualTotalAmount: 0,
  ContractUnitPrice: 1,
  ContractTotalAmount: 1
};
const fieldRelated = [
  {
    unitPriceField: 'ActualUnitPrice',
    totalAmountField: 'ActualTotalAmount'
  },
  {
    unitPriceField: 'ContractUnitPrice',
    totalAmountField: 'ContractTotalAmount'
  }
];

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.GET_LIST_SETTLEMENT_FOLLOW_CUSTOMER_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.GET_LIST_SETTLEMENT_FOLLOW_CUSTOMER_FULFILLED: {
      return {
        ...state,
        loading: false,
        listData: action.payload.data?.data?.data?.data ?? [],
        isSearch: false,
        isCloseModal: false,
        statusSettlementCus: action.payload.data?.data?.status ?? [],
        extension: action.payload.data?.data?.extension ?? [],
        weightSummary: action.payload.data?.data?.data?.weightSummary ?? [],
        reload: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.GET_LIST_SETTLEMENT_FOLLOW_CUSTOMER_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        message: 'Không thể lấy được dữ liệu !',
        reload: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.CREATE_TEMPLATE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.CREATE_TEMPLATE_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isShowMessage: false,
        isCloseModal: true,
        reload: true
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.CREATE_TEMPLATE_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Tạo dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        reload: false
      };
    }

    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }

    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.CHANGE_DATA_EDIT_TABEL_FIELD_SETTLEMENT: {
      const { checkValueIsBoolean, value } = action.meta;
      if (Array.isArray(action.payload)) {
        let dataUpdated = [];
        const datas = !checkValueIsBoolean
          ? action.payload
          : action.payload.map((item) => ({ ...item, CheckSettlement: value }));
        let dataWithError = state.listData.map((dataFormState) => {
          let data = _.find(datas, function (item) {
            return item.Id === dataFormState.Id;
          });
          let error = { ...dataFormState?.error, ...action.meta?.error };
          return data ? { ...dataFormState, ...data, error: error } : dataFormState;
        });

        dataUpdated = dataWithError;
        return {
          ...state,
          loading: false,
          listData: [...dataUpdated],
          isUpdateSubmit: action.meta.message !== undefined ? false : true
        };
      } else {
        let dataUpdated = [];
        const data = !checkValueIsBoolean ? action.payload : { ...action.payload, CheckSettlement: value };
        let item = _.find(state.listData, function (item) {
          return item.Id === data.Id;
        });
        let error = { ...item.error, ...action.meta?.error };
        let itemUpdate = [{ ...item, ...data, error: error }];
        let result = state.listData.map((obj) => itemUpdate.find((o) => o.Id === obj.Id) || obj);
        dataUpdated = [...result];
        return {
          ...state,
          loading: false,
          listData: [...dataUpdated],
          isUpdateSubmit: action.meta.message !== undefined ? false : true
        };
      }
    }

    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.ON_KEY_UP_SETTLEMENT: {
      const data = action.payload;
      const indexFieldRelated = indexReferencefieldRelated[data.fieldName];
      const fieldRelatedForProcess = fieldRelated[indexFieldRelated];
      const totalField = fieldRelatedForProcess?.totalAmountField;
      const unitField = fieldRelatedForProcess?.unitPriceField;
      let item = _.find(state.listData, function (item) {
        return item.Id === data.Id;
      });
      let IsDeliveryWeightCalculating = item?.IsDeliveryWeightCalculating;
      let weight = IsDeliveryWeightCalculating ? item?.DeliveryWeight : item?.ReceiveWeight;
      let isTruckingFee = item?.FeeCategoryId === 4; // TYPE_IS_CUOC_VC
      let isFocusTotal = data?.isFocusTotal;
      let dataUpdateFocus =
        !isFocusTotal && item
          ? {
              // sửa đơn giá
              ...data,
              [totalField]:
                parseInt(item[unitField]) *
                Number(
                  data.type === 'TYPE_CONT'
                    ? 1
                    : item?.ActualQuantity
                    ? isTruckingFee
                      ? weight
                      : item?.ActualQuantity
                    : 0
                ).toFixed(2)
            }
          : {
              // sửa thành tiền
              ...data,
              [unitField]:
                item[totalField] /
                Number(
                  data.type === 'TYPE_CONT'
                    ? 1
                    : item?.ActualQuantity
                    ? isTruckingFee
                      ? weight
                      : item?.ActualQuantity
                    : 0
                ).toFixed(2)
            };

      let itemUpdate = [{ ...item, ...dataUpdateFocus }];
      let dataUpdate = state.listData.map((obj) => itemUpdate.find((o) => o.Id === obj.Id) || obj);
      return {
        ...state,
        loading: false,
        listData: [...dataUpdate]
      };
    }

    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.UPDATE_SETTLEMENT_FOLLOW_CUSTUMER_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.UPDATE_SETTLEMENT_FOLLOW_CUSTUMER_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công',
        isShowMessage: true,
        isCloseModal: true,
        reload: true
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.UPDATE_SETTLEMENT_FOLLOW_CUSTUMER_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Cập nhật dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        reload: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.CREATE_DATA_SETTLEMENT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.CREATE_DATA_SETTLEMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isShowMessage: true,
        isCloseModal: true,
        reload: true
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.CREATE_DATA_SETTLEMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Tạo dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        reload: false
      };
    }

    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.GET_SUMMARY_DATA_EX_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.GET_SUMMARY_DATA_EX_FULFILLED: {
      return {
        ...state,
        loading: false,
        summaryDatas: action.payload.data.data,
        isSearch: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.GET_SUMMARY_DATA_EX_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        message: 'Không thể lấy được dữ liệu !',
        reload: false
      };
    }

    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.GET_CONSIGNMENT_INFO_BY_ID_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.GET_CONSIGNMENT_INFO_BY_ID_FULFILLED: {
      return {
        ...state,
        loading: false,
        consignmentInfos: action.payload.data.data,
        isSearch: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.GET_CONSIGNMENT_INFO_BY_ID_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        message: 'Không thể lấy được dữ liệu !',
        reload: false
      };
    }

    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.UPDATE_CONSIMENT_INFO_EXTENDS_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.UPDATE_CONSIMENT_INFO_EXTENDS_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công',
        isShowMessage: true,
        isCloseModal: true,
        reload: true
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.UPDATE_CONSIMENT_INFO_EXTENDS_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Cập nhật dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        reload: false
      };
    }

    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.SET_DEFAULT_VALUE_PROPS: {
      return {
        ...state,
        listData: action.payload
      };
    }

    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.DELETE_DATA_SETTLEMENT_CUSTOMER_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.DELETE_DATA_SETTLEMENT_CUSTOMER_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Xóa dữ liệu thành công !',
        isShowMessage: true,
        isCloseModal: true,
        reload: true
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.DELETE_DATA_SETTLEMENT_CUSTOMER_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Xóa dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        reload: false
      };
    }

    //TODO: 2021/06/04 Chinhnv get settlement customer has not bill info
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.FILTER_DATA_NOT_HAS_BILL_INFO_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.FILTER_DATA_NOT_HAS_BILL_INFO_FULFILLED: {
      return {
        ...state,
        loading: false,
        dataHasNotBillInfo: action.payload.data?.data ?? []
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.FILTER_DATA_NOT_HAS_BILL_INFO_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.CUSTOMER_SET_ELEMENT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.CUSTOMER_SET_ELEMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        dataCustomersettlement: action.payload.data?.data ?? []
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.CUSTOMER_SET_ELEMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    default:
      return state;
  }
};
