import api from 'HttpClient';

export const SET_OPEN_WORKING_HISTORY_MODAL = 'SET_OPEN_WORKING_HISTORY_MODAL';
export const setOpenWorkingHistoryModal = (data) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_WORKING_HISTORY_MODAL,
    payload: data
  });
};

export const SET_CLOSE_WORKING_HISTORY_MODAL = 'SET_CLOSE_WORKING_HISTORY_MODAL';
export const setCloseWorkingHistoryModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_WORKING_HISTORY_MODAL
  });
};

export const FETCH_ALL_WORKING_HISTORIES = 'FETCH_ALL_WORKING_HISTORIES';
export const FETCH_ALL_WORKING_HISTORIES_PENDING = 'FETCH_ALL_WORKING_HISTORIES_PENDING';
export const FETCH_ALL_WORKING_HISTORIES_FULFILLED = 'FETCH_ALL_WORKING_HISTORIES_FULFILLED';
export const FETCH_ALL_WORKING_HISTORIES_REJECTED = 'FETCH_ALL_WORKING_HISTORIES_REJECTED';
export const fetchAllWorkingHistories = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_WORKING_HISTORIES,
    payload: api.get(`/WorkingHistoryInfo/getAll`)
  });
};

// const type of function get data per page
export const FETCH_WORKING_HISTORIES = 'FETCH_WORKING_HISTORIES';
export const FETCH_WORKING_HISTORIES_PENDING = 'FETCH_WORKING_HISTORIES_PENDING';
export const FETCH_WORKING_HISTORIES_FULFILLED = 'FETCH_WORKING_HISTORIES_FULFILLED';
export const FETCH_WORKING_HISTORIES_REJECTED = 'FETCH_WORKING_HISTORIES_REJECTED';
export const fetchWorkingHistories = (searchPayload, page, pageSize) => (dispatch) => {
  return dispatch({
    type: FETCH_WORKING_HISTORIES,
    payload: api.get(`/WorkingHistoryInfo/Search`, { params: { ...searchPayload, page, pageSize: pageSize } })
  });
};

// const type of function create data
export const CREATE_WORKING_HISTORY = 'CREATE_WORKING_HISTORY';
export const CREATE_WORKING_HISTORY_PENDING = 'CREATE_WORKING_HISTORY_PENDING';
export const CREATE_WORKING_HISTORY_FULFILLED = 'CREATE_WORKING_HISTORY_FULFILLED';
export const CREATE_WORKING_HISTORY_REJECTED = 'CREATE_WORKING_HISTORY_REJECTED';
export const createWorkingHistory = (payload) => (dispatch) => {
  return dispatch({
    type: CREATE_WORKING_HISTORY,
    payload: api.post(`/WorkingHistoryInfo/create`, payload)
  });
};

// const type of function update data
export const UPDATE_WORKING_HISTORY = 'UPDATE_WORKING_HISTORY';
export const UPDATE_WORKING_HISTORY_PENDING = 'UPDATE_WORKING_HISTORY_PENDING';
export const UPDATE_WORKING_HISTORY_FULFILLED = 'UPDATE_WORKING_HISTORY_FULFILLED';
export const UPDATE_WORKING_HISTORY_REJECTED = 'UPDATE_WORKING_HISTORY_REJECTED';
export const updateWorkingHistory = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_WORKING_HISTORY,
    payload: api.put(`/WorkingHistoryInfo/update`, payload),
    meta: payload
  });
};
// const type of get deatail
export const FETCH_WORKING_HISTORY_DETAIL = 'FETCH_WORKING_HISTORY_DETAIL';
export const FETCH_WORKING_HISTORY_DETAIL_PENDING = 'FETCH_WORKING_HISTORY_DETAIL_PENDING';
export const FETCH_WORKING_HISTORY_DETAIL_FULFILLED = 'FETCH_WORKING_HISTORY_DETAIL_FULFILLED';
export const FETCH_WORKING_HISTORY_DETAIL_REJECTED = 'FETCH_WORKING_HISTORY_DETAIL_REJECTED';
export const getWorkingHistoryDetail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_WORKING_HISTORY_DETAIL,
    payload: api.get(`/WorkingHistoryInfo/${id}`)
  });
};

// const type of delete
export const DELETE_WORKING_HISTORY = 'DELETE_WORKING_HISTORY';
export const DELETE_WORKING_HISTORY_PENDING = 'DELETE_WORKING_HISTORY_PENDING';
export const DELETE_WORKING_HISTORY_FULFILLED = 'DELETE_WORKING_HISTORY_FULFILLED';
export const DELETE_WORKING_HISTORY_REJECTED = 'DELETE_WORKING_HISTORY_REJECTED';
export const deleteWorkingHistory = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_WORKING_HISTORY,
    payload: api.delete(`/WorkingHistoryInfo/delete/${id}`)
  });
};
