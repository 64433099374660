import {getAllEquipmentCategory} from "services/repair/equipmentCategory";

//EquipmentCategory
export const EQUIPMENT_CATEGORY = {
    GET_ALL_EQUIPMENT_CATEGORY : "GET_ALL_EQUIPMENT_CATEGORY",
    GET_ALL_EQUIPMENT_CATEGORY_PENDING: "GET_ALL_EQUIPMENT_CATEGORY_PENDING",
    GET_ALL_EQUIPMENT_CATEGORY_FULFILLED: "GET_ALL_EQUIPMENT_CATEGORY_FULFILLED",
    GET_ALL_EQUIPMENT_CATEGORY_REJECTED: "GET_ALL_EQUIPMENT_CATEGORY_REJECTED",
}

export const actionGetAllEquipmentCategory = () => dispatch => {
    return dispatch({
        type: EQUIPMENT_CATEGORY.GET_ALL_EQUIPMENT_CATEGORY,
        payload: getAllEquipmentCategory()
    })
}