import httpClient from 'HttpClient';
import {
  getAllDepartment,
  getDepartmentWithTractorTrailer,
  getPaymentCode,
  getVehicleByDepartment
} from 'services/departmentInfo';

// define const actions
export const DEPARTMENT_ACTIONS = {
  GET_ALL_DEPARTMENT: 'GET_ALL_DEPARTMENT',
  GET_ALL_DEPARTMENT_PENDING: 'GET_ALL_DEPARTMENT_PENDING',
  GET_ALL_DEPARTMENT_FULFILLED: 'GET_ALL_DEPARTMENT_FULFILLED',
  GET_ALL_DEPARTMENT_REJECTED: 'GET_ALL_DEPARTMENT_REJECTED',

  GET_DEPARTMENT_WITH_TRACTOR_TRAILER: 'GET_DEPARTMENT_WITH_TRACTOR_TRAILER',
  GET_DEPARTMENT_WITH_TRACTOR_TRAILER_PENDING: 'GET_DEPARTMENT_WITH_TRACTOR_TRAILER_PENDING',
  GET_DEPARTMENT_WITH_TRACTOR_TRAILER_FULFILLED: 'GET_DEPARTMENT_WITH_TRACTOR_TRAILER_FULFILLED',
  GET_DEPARTMENT_WITH_TRACTOR_TRAILER_REJECTED: 'GET_DEPARTMENT_WITH_TRACTOR_TRAILER_REJECTED',

  GET_VEHICLE_BY_DEPARTMENT: 'GET_VEHICLE_BY_DEPARTMENT',
  GET_VEHICLE_BY_DEPARTMENT_PENDING: 'GET_VEHICLE_BY_DEPARTMENT_PENDING',
  GET_VEHICLE_BY_DEPARTMENT_FULFILLED: 'GET_VEHICLE_BY_DEPARTMENT_FULFILLED',
  GET_VEHICLE_BY_DEPARTMENT_REJECTED: 'GET_VEHICLE_BY_DEPARTMENT_REJECTED',

  GET_PAYMENT_CODE: 'GET_PAYMENT_CODE',
  GET_PAYMENT_CODE_PENDING: 'GET_PAYMENT_CODE_PENDING',
  GET_PAYMENT_CODE_FULFILLED: 'GET_PAYMENT_CODE_FULFILLED',
  GET_PAYMENT_CODE_REJECTED: 'GET_PAYMENT_CODE_REJECTED',

  CREATE_NEW_DEPARTMENT: 'CREATE_NEW_DEPARTMENT',
  CREATE_NEW_DEPARTMENT_PENDING: 'CREATE_NEW_DEPARTMENT_PENDING',
  CREATE_NEW_DEPARTMENT_FULFILLED: 'CREATE_NEW_DEPARTMENT_FULFILLED',
  CREATE_NEW_DEPARTMENT_REJECTED: 'CREATE_NEW_DEPARTMENT_REJECTED'
};

// define actions
export const actionGetAllDepartment = () => (dispatch) => {
  return dispatch({
    type: DEPARTMENT_ACTIONS.GET_ALL_DEPARTMENT,
    payload: getAllDepartment()
  });
};

export const actionGetDepartmentWithTractorTrailer = () => (dispatch) => {
  return dispatch({
    type: DEPARTMENT_ACTIONS.GET_DEPARTMENT_WITH_TRACTOR_TRAILER,
    payload: getDepartmentWithTractorTrailer()
  });
};
// get vehicle by department;
export const actionGetVehicleByDepartment = (id) => (dispatch) => {
  return dispatch({
    type: DEPARTMENT_ACTIONS.GET_VEHICLE_BY_DEPARTMENT,
    payload: getVehicleByDepartment(id)
  });
};

//get payment code by employees
export const getPaymentCodeByEmployees = (id) => (dispatch) => {
  return dispatch({
    type: DEPARTMENT_ACTIONS.GET_PAYMENT_CODE,
    payload: getPaymentCode(id)
  });
};

export const createNewDepartment = (payload) => (dispatch) => {
  return dispatch({
    type: DEPARTMENT_ACTIONS.CREATE_NEW_DEPARTMENT,
    payload: httpClient.post(`/DepartmentInfo/create`, payload)
  });
};
