import { CUSTOM_FEE_ACTIONS } from 'actions/customfee';
import { getErrorMessage } from 'commons/ReducerUtils';
import { MSG_ADD_FAILURE, MSG_DELETE_FAILURE, MSG_EDIT_FAILURE } from 'const/common';

// const dataMessageExist = ["Dữ liệu đã tồn tại"];

const customFeeReducer = (state = {}, action) => {
  switch (action.type) {
    // get custom fee per page
    case CUSTOM_FEE_ACTIONS.GET_CUSTOM_FEE_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case CUSTOM_FEE_ACTIONS.GET_CUSTOM_FEE_PER_PAGE_FULFILLED: {
      let customFee = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data
      };
      return {
        ...state,
        customFee: customFee,
        isLoading: false,
        isMsg: false
      };
    }

    case CUSTOM_FEE_ACTIONS.GET_CUSTOM_FEE_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: 'Đã xảy ra lỗi'
      };

    // get custom fee by id
    case CUSTOM_FEE_ACTIONS.GET_CUSTOM_FEE_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case CUSTOM_FEE_ACTIONS.GET_CUSTOM_FEE_BY_ID_FULFILLED:
      return {
        ...state,
        isLoading: false
      };

    case CUSTOM_FEE_ACTIONS.GET_CUSTOM_FEE_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: 'Đã xảy ra lỗi'
      };

    // create custom fee
    case CUSTOM_FEE_ACTIONS.CREATE_CUSTOM_FEE_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case CUSTOM_FEE_ACTIONS.CREATE_CUSTOM_FEE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'success',
        msg: 'Thêm dữ liệu thành công'
      };

    case CUSTOM_FEE_ACTIONS.CREATE_CUSTOM_FEE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: getErrorMessage(action, MSG_ADD_FAILURE)
      };

    // update custom fee
    case CUSTOM_FEE_ACTIONS.UPDATE_CUSTOM_FEE_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case CUSTOM_FEE_ACTIONS.UPDATE_CUSTOM_FEE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'success',
        msg: 'Cập nhật dữ liệu thành công'
      };

    case CUSTOM_FEE_ACTIONS.UPDATE_CUSTOM_FEE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: getErrorMessage(action, MSG_EDIT_FAILURE)
      };

    // delete custom fee
    case CUSTOM_FEE_ACTIONS.DELETE_CUSTOM_FEE_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case CUSTOM_FEE_ACTIONS.DELETE_CUSTOM_FEE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'success',
        msg: 'Xóa dữ liệu thành công'
      };

    case CUSTOM_FEE_ACTIONS.DELETE_CUSTOM_FEE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: getErrorMessage(action, MSG_DELETE_FAILURE)
      };

    //Search
    case CUSTOM_FEE_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };
    }
    case CUSTOM_FEE_ACTIONS.SEARCH_DATA_FULFILLED: {
      let customFee = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        isLoading: false,
        customFee: customFee,
        pagination: action.payload.data.pagination,
        isSearch: true
      };
    }
    case CUSTOM_FEE_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        isLoading: false,
        isShowMessage: true,
        typeMessage: 'error',
        msg: 'Không thể lấy được dữ liệu !'
      };
    }

    default:
      return state;
  }
};

export default customFeeReducer;
