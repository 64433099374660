import httpClient from "HttpClient";

export const NORM_MANAGEMENT_TYPE = {
  NORM_MANAGEMENT: 'NORM_MANAGEMENT',
  NORM_MANAGEMENT_PENDING: 'NORM_MANAGEMENT_PENDING',
  NORM_MANAGEMENT_FULFILLED: 'NORM_MANAGEMENT_FULFILLED',
  NORM_MANAGEMENT_REJECTED: 'NORM_MANAGEMENT_REJECTED',
}

export const actionGetNormManagement = (payload) => (dispatch) => {
  return dispatch({
    type: NORM_MANAGEMENT_TYPE.NORM_MANAGEMENT,
    payload: httpClient.get(`/ContractFeeRateInfo/paging/page`, { params: payload })
  });
};

export const actionGetNormManagementAll = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.get(`/ContractFeeRateInfo/paging/page`, { params: payload })
  });
};

export const actionCreateContractFeeRateInfo = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.post(`/ContractFeeRateInfo/create`, payload )
  });
};

export const actionCreateManyContractFeeRateInfo = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.post(`ContractFeeRateInfo/CreateMany`, payload )
  });
};

export const actionUpdateContractFeeRateInfo = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.put(`/ContractFeeRateInfo/update`, payload )
  });
};


export const actionDeleteContractFeeRateInfoById = (Id) => (dispatch) => {
  return dispatch({
    payload: httpClient.delete(`/ContractFeeRateInfo/deleteById/${Id}` )
  });
};

