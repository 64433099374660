import {
  CREATE_USER_MANUAL_FULFILLED,
  CREATE_USER_MANUAL_PENDING,
  CREATE_USER_MANUAL_REJECTED,
  DELETE_USER_MANUAL_FULFILLED,
  DELETE_USER_MANUAL_PENDING,
  DELETE_USER_MANUAL_REJECTED,
  FETCH_ALL_USER_MANUALS_FULFILLED,
  FETCH_ALL_USER_MANUALS_PENDING,
  FETCH_ALL_USER_MANUALS_REJECTED,
  FETCH_USER_MANUAL_DETAIL_FULFILLED,
  FETCH_USER_MANUAL_DETAIL_PENDING,
  FETCH_USER_MANUAL_DETAIL_REJECTED,
  FETCH_USER_MANUALS_FULFILLED,
  FETCH_USER_MANUALS_PENDING,
  FETCH_USER_MANUALS_REJECTED,
  SET_CLOSE_USER_MANUAL_MODAL,
  SET_OPEN_USER_MANUAL_MODAL,
  UPDATE_USER_MANUAL_FULFILLED,
  UPDATE_USER_MANUAL_PENDING,
  UPDATE_USER_MANUAL_REJECTED,
} from 'actions';
import { getErrorMessage } from 'commons/ReducerUtils';
import {
  DEFAULT_PAGE_SIZE,
  MSG_ADD_FAILURE,
  MSG_ADD_SUCCESS,
  MSG_DELETE_FAILURE,
  MSG_DELETE_SUCCESS,
  MSG_EDIT_FAILURE,
  MSG_EDIT_SUCCESS,
  MSG_GET_DETAIL_FAILURE,
  MSG_PAGING_FAILURE,
  MSG_TYPE_ERROR,
  MSG_TYPE_SUCCESS} from 'const/common';
const INIT_STATE = {
  //combobox: get all
  allUserManuals: [],
  // paging
  userManuals: [],
  pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
  listLoading: false,

  // add/edit modal
  isOpenFormModal: false, // Show/hidden update modal
  userManual: {},
  formLoading: false,
  //delete
  deleteLoading: false,
  // other
  showMessage: false,
  alertMessage: '',
  formAlertMessage: '',
  messageType: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region update modal
    case SET_OPEN_USER_MANUAL_MODAL: {
      const isOpenFormModal = state.userManuals && state.userManuals.length
      return {
        ...state,
        userManual: action.payload.data,
        type: action.payload.type,
        isOpenFormModal,
      };
    }
    case SET_CLOSE_USER_MANUAL_MODAL: {
      return {
        ...state,
        isOpenFormModal: false,
        userManual: INIT_STATE.userManual
      };
    }
    //#endregion

    //#region getall
    case FETCH_ALL_USER_MANUALS_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }
    case FETCH_ALL_USER_MANUALS_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        allUserManuals: action.payload.data.data,
        showMessage: false
      };
    }
    case FETCH_ALL_USER_MANUALS_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region paging
    case FETCH_USER_MANUALS_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }

    case FETCH_USER_MANUALS_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        userManuals: action.payload.data.data ?? INIT_STATE.userManuals,
        pagination: action.payload.data.pagination ?? INIT_STATE.pagination,
        showMessage: false
      };
    }
    case FETCH_USER_MANUALS_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region create data
    case CREATE_USER_MANUAL_PENDING:
      return {
        ...state,
        formLoading: true
      };
    case CREATE_USER_MANUAL_FULFILLED: {
      const userManual = action.payload.data;
      const userManuals = state.userManuals.map((item) => {
        return item.Id === userManual.Id ? userManual : item;
      });
      return {
        ...state,
        userManuals: [...userManuals],
        formLoading: false,
        showMessage: true,
        formAlertMessage: MSG_ADD_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case CREATE_USER_MANUAL_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_ADD_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region update data
    case UPDATE_USER_MANUAL_PENDING:
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    case UPDATE_USER_MANUAL_FULFILLED: {
      const userManual = action.meta;
      let userManuals = [...state.userManuals];
      userManuals = userManuals.map((item) => {
        return item.Id === userManual.Id ? userManual : item;
      });
      return {
        ...state,
        userManuals,
        formLoading: false,
        showMessage: true,
        formAlertMessage: MSG_EDIT_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case UPDATE_USER_MANUAL_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_EDIT_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region  get detail
    case FETCH_USER_MANUAL_DETAIL_PENDING:
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    case FETCH_USER_MANUAL_DETAIL_FULFILLED:
      return {
        ...state,
        userManual: action.payload.data,
        formLoading: false,
        showMessage: false
      };
    case FETCH_USER_MANUAL_DETAIL_REJECTED:
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        alertMessage: MSG_GET_DETAIL_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    //#endregion

    //#region delete data
    case DELETE_USER_MANUAL_PENDING:
      return {
        ...state,
        deleteLoading: true
      };
    case DELETE_USER_MANUAL_FULFILLED:
      return {
        ...state,
        isDelete: true,
        deleteLoading: false,
        showMessage: true,
        alertMessage: MSG_DELETE_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case DELETE_USER_MANUAL_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_DELETE_FAILURE) || action?.payload?.response?.data?.message;
      return {
        ...state,
        deleteLoading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }

    default:
      return state;
  }
};
