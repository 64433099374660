import api from 'HttpClient';

export const getAllMoocs = () => {
  return api.get(`/semitrailerinfo/getAll`);
};

export const getMoocsPerPage = (pageNo, pageSize) => {
  return api.get(`/semitrailerinfo/paging/page?page=${pageNo}&pagesize=${pageSize}`);
};
export const getMoocsPerPageAll = (params) => {
  return api.get(`/semitrailerinfo/paging/page`, { params });
};

export const getMoocById = (id) => {
  return api.get(`/semitrailerinfo/getById/${id}`);
};

export const createMooc = (payload) => {
  return api.post(`/semitrailerinfo/create`, payload);
};

export const updateMooc = (payload) => {
  return api.put(`/semitrailerinfo/update`, payload);
};

export const deleteMooc = (id) => {
  return api.delete(`/semitrailerinfo/deleteById/${id}`);
};

export const searchMoocs = (params, pageNo, pageSize) => {
  return api.get(`/semitrailerinfo/search?Page=${pageNo}&PageSize=${pageSize}`, { params });
};

export const typeRomooc = () => {
  return api.get(`/SemiTrailerCategory/getAll`);
};
