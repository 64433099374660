import { EXPIRATION_DATE_ACTIONS } from "actions/repair/expirationDate";

const INIT_STATE = {
  listPerPage: [],
  oldSerialNos: [],
  loading: false,
  typeMessage: "",
  message: "",
  isShowMessage: false,
  pagination: {},
  isSearch: false,
  isCloseModal: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EXPIRATION_DATE_ACTIONS.GET_DATA_LIST_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case EXPIRATION_DATE_ACTIONS.GET_DATA_LIST_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false,
      };
    }
    case EXPIRATION_DATE_ACTIONS.GET_DATA_LIST_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
        isCloseModal: false,
      };
    }

    case "UPDATE_SHOW_ALERT": {
      return {
        ...state,
        isShowMessage: false,
      };
    }

    case EXPIRATION_DATE_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        isShowMessage: false,
      };
    }
    case EXPIRATION_DATE_ACTIONS.SEARCH_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
      };
    }
    case EXPIRATION_DATE_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }

    case EXPIRATION_DATE_ACTIONS.DELETE_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case EXPIRATION_DATE_ACTIONS.DELETE_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: "success",
        message: "Xóa dữ liệu thành công",
        isShowMessage: true,
      };
    }
    case EXPIRATION_DATE_ACTIONS.DELETE_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        message: "Xóa dữ liệu thất bại",
        isShowMessage: true,
        typeMessage: "error",
      };
    }

    case EXPIRATION_DATE_ACTIONS.CREATE_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
      };
    }
    case EXPIRATION_DATE_ACTIONS.CREATE_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "success",
        message: "Thêm mới dữ liệu thành công !",
        isCloseModal: true,
      };
    }
    case EXPIRATION_DATE_ACTIONS.CREATE_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message:
          action.payload.response.data.status === 400
            ? action.payload.response.data.message
            : "Thêm mới dữ liệu thất bại !",
        isCloseModal: false,
      };
    }

    case EXPIRATION_DATE_ACTIONS.UPDATE_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
      };
    }
    case EXPIRATION_DATE_ACTIONS.UPDATE_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "success",
        message: "Cập nhật dữ liệu thành công !",
        isCloseModal: true,
      };
    }
    case EXPIRATION_DATE_ACTIONS.UPDATE_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message:
          action.payload.response.data.status === 400
            ? action.payload.response.data.message
            : "Cập nhật dữ liệu thất bại !",
        isCloseModal: false,
      };
    }

    case EXPIRATION_DATE_ACTIONS.GET_OLD_SERIAL_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case EXPIRATION_DATE_ACTIONS.GET_OLD_SERIAL_FULFILLED: {
      return {
        ...state,
        loading: false,
        oldSerialNos: action.payload.data.data,
        isSearch: false,
        isCloseModal: false,
      };
    }
    case EXPIRATION_DATE_ACTIONS.GET_OLD_SERIAL_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
        isCloseModal: false,
      };
    }

    default:
      return state;
  }
};
