import api from 'HttpClient/index';

export const getPaymentOnBehaftPerPage = (pageNo, pageSize) => {
  return api.get(`/PaymentOnBehalfInfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`);
};
export const getPaymentOnBehaftPerPageAll = (params) => {
  return api.get(`/PaymentOnBehalfInfo/paging/page`, { params });
};

export const getAllPaymentOnBehaft = () => {
  return api.get(`/PaymentOnBehalfInfo/getAll`);
};

export const getPaymentOnBehaftByID = (id) => {
  return api.get(`/PaymentOnBehalfInfo/getById/${id}`);
};

export const createPaymentOnBehaft = (payload) => {
  return api.post(`/PaymentOnBehalfInfo/create`, payload);
};

export const updatePaymentOnBehaft = (payload) => {
  return api.put(`/PaymentOnBehalfInfo/update`, payload);
};

export const deletePaymentOnBehaft = (id) => {
  return api.delete(`/PaymentOnBehalfInfo/delete/${id}`);
};

export const searchData = (payload) => {
  return api.get(`/PaymentOnBehalfInfo/search`, { params: payload });
};
