import httpClient from 'HttpClient';

export const HISTORY = {
  GET_HISTORY: 'GET_HISTORY',
  GET_HISTORY_PENDING: 'GET_HISTORY_PENDING',
  GET_HISTORY_FULFILLED: 'GET_HISTORY_FULFILLED',
  GET_HISTORY_REJECTED: 'GET_HISTORY_REJECTED'
};

export const actionGetChangesHistory = (params) => (dispatch) => {
  return dispatch({
    type: HISTORY.GET_HISTORY,
    payload: httpClient.get(`/ChangesHistory`, { params })
  });
};
