import { SETTLEMENT_OF_ORDER_ACTIONS } from 'actions/settlementOfOrderManagement';
import _ from 'lodash';
const INIT_STATE = {
  listDataSettlement: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  isCloseModal: false,
  isUpdateSubmit: true,
  statusSettlementOrder: {},
  extension: {},
  reload: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SETTLEMENT_OF_ORDER_ACTIONS.GET_LIST_SETTLEMENT_OF_ORDER_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.GET_LIST_SETTLEMENT_OF_ORDER_FULFILLED: {
      return {
        ...state,
        loading: false,
        listDataSettlement: action.payload.data.data.data,
        isSearch: false,
        isCloseModal: false,
        statusSettlementOrder: action.payload.data.data.status,
        extension: action.payload.data.data.extension,
        reload: false,
        isShowMessage: false
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.GET_LIST_SETTLEMENT_OF_ORDER_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        message: 'Không thể lấy được dữ liệu !',
        reload: false
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.CREATE_TEMPLATE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.CREATE_TEMPLATE_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isShowMessage: true,
        isCloseModal: true,
        reload: true
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.CREATE_TEMPLATE_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Tạo dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        reload: false
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.CREATE_SETTLEMENT_ORDER_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.CREATE_SETTLEMENT_ORDER_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isShowMessage: true,
        isCloseModal: true,
        reload: true
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.CREATE_SETTLEMENT_ORDER_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Thêm mới dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        reload: false
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.CHANGE_FEE_CATEGORY_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.CHANGE_FEE_CATEGORY_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Cập nhật thành công !',
        isShowMessage: true,
        isCloseModal: true,
        reload: true
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.CHANGE_FEE_CATEGORY_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Cập nhật thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        reload: false
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.UPDATE_SETTLEMENT_OF_ORDER_MANAGEMENT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.UPDATE_SETTLEMENT_OF_ORDER_MANAGEMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công',
        isShowMessage: true,
        isCloseModal: true,
        reload: true
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.UPDATE_SETTLEMENT_OF_ORDER_MANAGEMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Cập nhật dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        reload: false
      };
    }
    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.DELETE_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.DELETE_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Xóa dữ liệu thành công',
        isShowMessage: true,
        reload: true
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.DELETE_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Xóa dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        reload: false
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.CHANGE_DATA_EDIT_TABEL_FIELD: {
      const { checkValueIsBoolean, value, isConditionQuantityWeight, fieldName, valueIsTypeBoolean } = action.meta;

      let dataUpdated = [];
      if (isConditionQuantityWeight === 2 && fieldName === 'ActualQuantity') {
        const data = action.payload;
        let item = _.find(state.listDataSettlement, function (item) {
          return item.Id === data.Id;
        });
        let error = { ...item.error, ...action.meta?.error };
        let result = state.listDataSettlement.map((el) => ({
          ...el,
          ActualQuantity: el.ConditionQuantityWeightId === 2 ? data.ActualQuantity : el.ActualQuantity,
          error: el.ConditionQuantityWeightId === 2 ? error : el.error,
          isChange: el.ConditionQuantityWeightId === 2 ? true : false,
          ActualTotalAmount:
            parseInt(el.ActualUnitPrice ? el.ActualUnitPrice : 0) *
            parseInt(el.ConditionQuantityWeightId === 2 ? data.ActualQuantity : el.ActualQuantity)
        }));

        dataUpdated = result;
      } else {
        const data = !valueIsTypeBoolean ? action.payload : { ...action.payload, CheckSettlement: value };
        let item = _.find(state.listDataSettlement, function (item) {
          return item.Id === data.Id;
        });
        let error = { ...item.error, ...action.meta?.error };
        let itemUpdate = [{ ...item, ...data, error: error }];
        let result = state.listDataSettlement.map((obj) => itemUpdate.find((o) => o.Id === obj.Id) || obj);
        dataUpdated = [...result];
      }
      return {
        ...state,
        loading: false,
        listDataSettlement: [...dataUpdated],
        isUpdateSubmit: action.meta.message !== undefined ? false : true
      };
    }

    case SETTLEMENT_OF_ORDER_ACTIONS.CHANGE_DATA_EDIT_UNIT_PRICE_CONTRACT: {
      const { checkValueIsBoolean, value, isConditionQuantityWeight, fieldName, valueIsTypeBoolean } = action.meta;

      let dataUpdated = [];
      if (isConditionQuantityWeight === 2 && fieldName === 'ActualQuantity') {
        const data = action.payload;
        let item = _.find(state.listDataSettlement, function (item) {
          return item.Id === data.Id;
        });
        let error = { ...item.error, ...action.meta?.error };
        let result = state.listDataSettlement.map((el) => ({
          ...el,
          ActualQuantity: el.ConditionQuantityWeightId === 2 ? data.ActualQuantity : el.ActualQuantity,
          error: el.ConditionQuantityWeightId === 2 ? error : el.error,
          isChange: el.ConditionQuantityWeightId === 2 ? true : false,
          ActualTotalAmount:
            parseInt(el.ContractUnitPrice ? el.ContractUnitPrice : 0) *
            parseInt(el.ConditionQuantityWeightId === 2 ? data.ActualQuantity : el.ActualQuantity)
        }));

        dataUpdated = result;
      } else {
        const data = !valueIsTypeBoolean ? action.payload : { ...action.payload, CheckSettlement: value };
        let item = _.find(state.listDataSettlement, function (item) {
          return item.Id === data.Id;
        });
        let error = { ...item.error, ...action.meta?.error };
        let itemUpdate = [{ ...item, ...data, error: error }];
        let result = state.listDataSettlement.map((obj) => itemUpdate.find((o) => o.Id === obj.Id) || obj);
        dataUpdated = [...result];
      }

      return {
        ...state,
        loading: false,
        listDataSettlement: [...dataUpdated],
        isUpdateSubmit: action.meta.message !== undefined ? false : true
      };
    }

    case SETTLEMENT_OF_ORDER_ACTIONS.ON_KEY_UP: {
      const data = action.payload;
      let item = _.find(state.listDataSettlement, function (item) {
        return item.Id === data.Id;
      });
      let isFocusTotal = data.isFocusTotal;
      let dataUpdateFocus =
        !isFocusTotal && item
          ? {
              ...data,
              ActualTotalAmount: parseInt(item.ActualUnitPrice) * parseInt(item.ActualQuantity),
              ContractTotalAmount: parseInt(item.ContractUnitPrice) * parseInt(item.ActualQuantity)
            }
          : {
              ...data
            };
      let itemUpdate = [{ ...item, ...dataUpdateFocus }];
      let dataUpdate = state.listDataSettlement.map((obj) => itemUpdate.find((o) => o.Id === obj.Id) || obj);
      return {
        ...state,
        loading: false,
        listDataSettlement: [...dataUpdate]
      };
    }

    case SETTLEMENT_OF_ORDER_ACTIONS.REFRESH_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.REFRESH_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        reload: true,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công !'
      };
    }
    case SETTLEMENT_OF_ORDER_ACTIONS.REFRESH_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        reload: false
      };
    }
    default:
      return state;
  }
};
