import {
  CAP_NHAT_MOTH_YEAR,
  CAP_NHAT_NGAY,
  CHANGE_CHECK_BOX_ALL_TIME_SHEET_TYPE,
  CHANGE_CHECK_BOX_TIME_SHEET_TYPE,
  CHANGE_TIMEKEEPING_TIME_SHEET_TYPE,
  DAILY_TIMKEEPING_TYPE_FULFILLED,
  MOTHLYSALARY_TIMKEEPING_TYPE_FULFILLED,
  SELECT_TIME_KEEPING_TYPE,
  STATUS_INFO_TYPE_FULFILLED,
  TIMEKEEPING_ALL_TIME_SHEET_TYPE,
  TOGGLE_REPORT_FORM_TYPE
} from 'actions/TimeSheetAction';
import _ from 'lodash';
import moment from 'moment';

const INIT_STATE = {
  data: [],
  day: null,
  checkBoxAll: false,
  typeOfTimekeeping: [],
  timekeeping: null,
  data2: [],

  //tháng
  monthYear: moment().format('yyyy-MM'),

  //modal
  showReportForm: false
};

const TimeSheetReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_REPORT_FORM_TYPE: {
      state.showReportForm = !state.showReportForm;
      return { ...state };
    }
    case CHANGE_CHECK_BOX_TIME_SHEET_TYPE: {
      const index = state.data.findIndex((item) => item.employeeId === action.data.employeeId);
      state.data[index].checkbox = !state.data[index].checkbox;
      return { ...state };
    }
    case CHANGE_CHECK_BOX_ALL_TIME_SHEET_TYPE: {
      const result = state.data.map((item) => {
        return { ...item, checkbox: !state.checkBoxAll };
      });
      state.data = result;
      return { ...state, checkBoxAll: !state.checkBoxAll };
    }
    case CHANGE_TIMEKEEPING_TIME_SHEET_TYPE: {
      return { ...state, timekeeping: action.data };
    }
    case SELECT_TIME_KEEPING_TYPE: {
      const index = state.data.findIndex((item) => item.employeeId === action.data.item.employeeId);
      state.data[index].workingTypeId = Number(action?.data?.value?.Id);
      state.data[index].WorkingTypeAbbr = action?.data?.value?.WorkingTypeAbbr;
      // const index2 = state.typeOfTimekeeping.findIndex((item) => item.Id === Number(action.data.value));
      // state.data[index].WorkingTypeAbbr = state.typeOfTimekeeping[index2].WorkingTypeAbbr
      return { ...state };
    }
    case TIMEKEEPING_ALL_TIME_SHEET_TYPE: {
      const result = state.data.map((item) => {
        if (item.checkbox === true) {
          return {
            ...item,
            workingTypeId: Number(state.timekeeping?.Id),
            WorkingTypeAbbr: state.timekeeping?.WorkingTypeAbbr
          };
        } else {
          return { ...item };
        }
      });
      state.data = result;
      return { ...state };
    }
    case MOTHLYSALARY_TIMKEEPING_TYPE_FULFILLED: {
      state.data2 = action.payload.data.Result;
      return { ...state };
    }
    case DAILY_TIMKEEPING_TYPE_FULFILLED: {
      const newArr = action.payload.data.Result.map((item) => {
        return {
          ...item,
          fullName: item.FirstName + ' ' + item.LastName,
          checkbox: false
        };
      });
      const repeatFilter = _.uniqBy(newArr, 'employeeId');
      return { ...state, data: repeatFilter, checkBoxAll: false };
    }
    case CAP_NHAT_NGAY: {
      return { ...state, day: action.data };
    }
    case CAP_NHAT_MOTH_YEAR: {
      return { ...state, monthYear: action.data };
    }
    case STATUS_INFO_TYPE_FULFILLED: {
      const typeOfTimekeeping = action.payload.data.data;
      return { ...state, typeOfTimekeeping: typeOfTimekeeping };
    }
    default:
      return state;
  }
};

export default TimeSheetReducer;
