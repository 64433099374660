import {
    SEARCH_USER,
    SEARCH_USER_FULFILLED,
    SEARCH_USER_PENDING,
    SEARCH_USER_REJECTED
} from "actions/UserManagementAction";
import httpClient from "HttpClient";
import { call, put, takeLatest } from "redux-saga/effects";
// import qs from "querystring";

// (TTS)LyLV 2020/04/08 Watcher START
export function* watchUserSearch() {
    yield takeLatest(SEARCH_USER, userSearch);

}
// (TTS)LyLV 2020/04/08 Watcher END

// (TTS)LyLV 2020/04/08 SAGA START
// saga worker sigin

export function* userSearch(action) {
    // console.log("auth sagas userSearch");
    yield put({ type: SEARCH_USER_PENDING, payload: action.payload });
    try {
        const response = yield call(executeSearch, action);
        yield put({
            type: SEARCH_USER_FULFILLED,
            payload: response
        });
    } catch (error) {
        // console.log("auth sagas userSearch reject");
        yield put({ type: SEARCH_USER_REJECTED, payload: error });
    }
}

async function executeSearch(action) {
    const params = {
        value: action.payload.searchKey,
        page: action.payload.page,
        pageSize: action.payload.pageSize
    }
    const response = await httpClient.get(
        "/User/search", {
            params: params
          }
    );
    return response;
}

// (TTS)LyLV 2020/04/08 SAGA END