import httpClient from 'HttpClient';
import api from 'HttpClient';
import {
  createDriverPlan,
  DailyTransportPlanInfo,
  deleteDriverPlan,
  deleteDriverPlanNew,
  getDriverPlanByID,
  getDriverPlanPerPage,
  updateDriverPlan
} from 'services/driverplan';

// define const actions
export const DRIVER_PLAN_ACTIONS = {
  SEARCH_DRIVER_PLAN_PER_PAGE: 'SEARCH_DRIVER_PLAN_PER_PAGE',
  SEARCH_DRIVER_PLAN_PER_PAGE_PENDING: 'SEARCH_DRIVER_PLAN_PER_PAGE_PENDING',
  SEARCH_DRIVER_PLAN_PER_PAGE_FULFILLED: 'SEARCH_DRIVER_PLAN_PER_PAGE_FULFILLED',
  SEARCH_DRIVER_PLAN_PER_PAGE_REJECTED: 'SEARCH_DRIVER_PLAN_PER_PAGE_REJECTED',

  GET_DRIVER_PLAN_PER_PAGE: 'GET_DRIVER_PLAN_PER_PAGE',
  GET_DRIVER_PLAN_PER_PAGE_PENDING: 'GET_DRIVER_PLAN_PER_PAGE_PENDING',
  GET_DRIVER_PLAN_PER_PAGE_FULFILLED: 'GET_DRIVER_PLAN_PER_PAGE_FULFILLED',
  GET_DRIVER_PLAN_PER_PAGE_REJECTED: 'GET_DRIVER_PLAN_PER_PAGE_REJECTED',

  GET_DRIVER_PLAN_BY_ID: 'GET_DRIVER_PLAN_BY_ID',
  GET_DRIVER_PLAN_BY_ID_PENDING: 'GET_DRIVER_PLAN_BY_ID_PENDING',
  GET_DRIVER_PLAN_BY_ID_FULFILLED: 'GET_DRIVER_PLAN_BY_ID_FULFILLED',
  GET_DRIVER_PLAN_BY_ID_REJECTED: 'GET_DRIVER_PLAN_BY_ID_REJECTED',

  DELETE_DRIVER_PLAN: 'DELETE_DRIVER_PLAN',
  DELETE_DRIVER_PLAN_PENDING: 'DELETE_DRIVER_PLAN_PENDING',
  DELETE_DRIVER_PLAN_FULFILLED: 'DELETE_DRIVER_PLAN_FULFILLED',
  DELETE_DRIVER_PLAN_REJECTED: 'DELETE_DRIVER_PLAN_REJECTED',

  CREATE_DRIVER_PLAN: 'CREATE_DRIVER_PLAN',
  CREATE_DRIVER_PLAN_PENDING: 'CREATE_DRIVER_PLAN_PENDING',
  CREATE_DRIVER_PLAN_FULFILLED: 'CREATE_DRIVER_PLAN_FULFILLED',
  CREATE_DRIVER_PLAN_REJECTED: 'CREATE_DRIVER_PLAN_REJECTED',

  UPDATE_DRIVER_PLAN: 'UPDATE_DRIVER_PLAN',
  UPDATE_DRIVER_PLAN_PENDING: 'UPDATE_DRIVER_PLAN_PENDING',
  UPDATE_DRIVER_PLAN_FULFILLED: 'UPDATE_DRIVER_PLAN_FULFILLED',
  UPDATE_DRIVER_PLAN_REJECTED: 'UPDATE_DRIVER_PLAN_REJECTED',

  SAVE_FAST_DISPATCHER_NO_CONSIGNMENT: 'SAVE_FAST_DISPATCHER_NO_CONSIGNMENT',
  SAVE_FAST_DISPATCHER_NO_CONSIGNMENT_PENDING: 'SAVE_FAST_DISPATCHER_NO_CONSIGNMENT_PENDING',
  SAVE_FAST_DISPATCHER_NO_CONSIGNMENT_FULFILLED: 'SAVE_FAST_DISPATCHER_NO_CONSIGNMENT_FULFILLED',
  SAVE_FAST_DISPATCHER_NO_CONSIGNMENT_REJECTED: 'SAVE_FAST_DISPATCHER_NO_CONSIGNMENT_REJECTED',

  GET_TRUCKING_PLAN_COMMAND_BY_ID: 'GET_TRUCKING_PLAN_COMMAND_BY_ID',
  GET_TRUCKING_PLAN_COMMAND_BY_ID_PENDING: 'GET_TRUCKING_PLAN_COMMAND_BY_ID_PENDING',
  GET_TRUCKING_PLAN_COMMAND_BY_ID_FULFILLED: 'GET_TRUCKING_PLAN_COMMAND_BY_ID_FULFILLED',
  GET_TRUCKING_PLAN_COMMAND_BY_ID_REJECTED: 'GET_TRUCKING_PLAN_COMMAND_BY_ID_REJECTED',

  UPDATE_TRUCKING_STATUS: 'UPDATE_TRUCKING_STATUS',
  UPDATE_TRUCKING_STATUS_PENDING: 'UPDATE_TRUCKING_STATUS_PENDING',
  UPDATE_TRUCKING_STATUS_FULFILLED: 'UPDATE_TRUCKING_STATUS_FULFILLED',
  UPDATE_TRUCKING_STATUS_REJECTED: 'UPDATE_TRUCKING_STATUS_REJECTED',

  UPDATE_INFO_VAT: 'UPDATE_INFO_VAT',
  UPDATE_INFO_VAT_PENDING: 'UPDATE_INFO_VAT_PENDING',
  UPDATE_INFO_VAT_FULFILLED: 'UPDATE_INFO_VAT_FULFILLED',
  UPDATE_INFO_VAT_REJECTED: 'UPDATE_INFO_VAT_REJECTED',

  GET_SUMMARY_CAR_DAY: 'GET_SUMMARY_CAR_DAY',
  GET_SUMMARY_CAR_DAY_PENDING: 'GET_SUMMARY_CAR_DAY_PENDING',
  GET_SUMMARY_CAR_DAY_FULFILLED: 'GET_SUMMARY_CAR_DAY_FULFILLED',
  GET_SUMMARY_CAR_DAY_REJECTED: 'GET_SUMMARY_CAR_DAY_REJECTED',

  GET_DRIVER_PLAN_BY_ID_V2: 'GET_DRIVER_PLAN_BY_ID_V2',
  GET_DRIVER_PLAN_BY_ID_V2_PENDING: 'GET_DRIVER_PLAN_BY_ID_V2_PENDING',
  GET_DRIVER_PLAN_BY_ID_V2_FULFILLED: 'GET_DRIVER_PLAN_BY_ID_V2_FULFILLED',
  GET_DRIVER_PLAN_BY_ID_V2_REJECTED: 'GET_DRIVER_PLAN_BY_ID_V2_REJECTED',
  RESET_SEARCH_DRIVER_PLAN_PER_PAGE: 'RESET_SEARCH_DRIVER_PLAN_PER_PAGE',

  CONFIRM_UPDATE_PLAN: 'CONFIRM_UPDATE_PLAN',
  CONFIRM_UPDATE_PLAN_PENDING: 'CONFIRM_UPDATE_PLAN_PENDING',
  CONFIRM_UPDATE_PLAN_FULFILLED: 'CONFIRM_UPDATE_PLAN_FULFILLED',
  CONFIRM_UPDATE_PLAN_REJECTED: 'CONFIRM_UPDATE_PLAN_REJECTED',

  GET_TRACTOR_TRAILER_BY_EMPLOYEE: 'GET_TRACTOR_TRAILER_BY_EMPLOYEE',
  GET_TRACTOR_TRAILER_BY_EMPLOYEE_PENDING: 'GET_TRACTOR_TRAILER_BY_EMPLOYEE_PENDING',
  GET_TRACTOR_TRAILER_BY_EMPLOYEE_FULFILLED: 'GET_TRACTOR_TRAILER_BY_EMPLOYEE_FULFILLED',
  GET_TRACTOR_TRAILER_BY_EMPLOYEE_REJECTED: 'GET_TRACTOR_TRAILER_BY_EMPLOYEE_REJECTED',

  LINK_SCREEN_CONSIGNMENT: 'LINK_SCREEN_CONSIGNMENT',
  UPDATE_MANY_TRUCKING_PLAN: 'UPDATE_MANY_TRUCKING_PLAN'
};

// define actions
export const actionSearchDriverPlanPerPage = (payload, screenType, pageNo, pageSize, cancelToken) => (dispatch) => {
  payload.Page = pageNo;
  payload.PageSize = pageSize;
  const searchUrl = `/TruckingPlan/Search/date`;
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.SEARCH_DRIVER_PLAN_PER_PAGE,
    payload: api.get(searchUrl, { params: payload, cancelToken: cancelToken ? cancelToken.token : null })
  });
};

export const actionSearchDriverPlanPerPageAll = (payload) => (dispatch) => {
  const searchUrl = `/TruckingPlan/Search/date`;
  return dispatch({
    // type: DRIVER_PLAN_ACTIONS.SEARCH_DRIVER_PLAN_PER_PAGE,
    payload: api.get(searchUrl, { params: payload })
  });
};

export const actionGetSummaryCarInDay = (payload, screenType, pageNo, pageSize) => (dispatch) => {
  const params = {
    Page: pageNo,
    PageSize: pageSize,
    modeDate: payload.modeDate,
    fromDate: payload.fromDate,
    toDate: payload.toDate
  };

  const searchUrl = `/TruckingPlan/count`;
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.GET_SUMMARY_CAR_DAY,
    payload: api.get(searchUrl, { params: params })
  });
};

export const actionGetDriverPlanPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.GET_DRIVER_PLAN_PER_PAGE,
    payload: getDriverPlanPerPage(pageNo, pageSize)
  });
};

export const actionGetDriverPlanByID = (id) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.GET_DRIVER_PLAN_BY_ID,
    payload: getDriverPlanByID(id)
  });
};

export const actionDeleteDriverPlan = (id, currentPage) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.DELETE_DRIVER_PLAN,
    payload: deleteDriverPlan(id),
    meta: {
      currentPage: currentPage
    }
  });
};

export const actionCreateDriverPlan = (payload) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.CREATE_DRIVER_PLAN,
    payload: createDriverPlan(payload),
    meta: {
      currentPage: payload.currentPage
    }
  });
};

export const actionUpdateDriverPlan = (payload) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.UPDATE_DRIVER_PLAN,
    payload: updateDriverPlan(payload),
    meta: {
      currentPage: payload.currentPage
    }
  });
};

export const actionUpdateDailyTransportPlanInfo = (payload) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.UPDATE_DRIVER_PLAN,
    payload: DailyTransportPlanInfo(payload),
    meta: {
      currentPage: payload.currentPage
    }
  });
};

export const actionUpdateManyTruckingPlan = (payload) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.UPDATE_DRIVER_PLAN,
    payload: httpClient.put('/DailyTransportPlanInfo/UpdateManyTruckingPlan', payload)
  });
};

export const SET_DRIVER_PLAN_SEARCH_PARAMS = 'SET_DRIVER_PLAN_SEARCH_PARAMS';
export const setSearchParams = (search) => (dispatch) => {
  return dispatch({
    type: SET_DRIVER_PLAN_SEARCH_PARAMS,
    payload: search
  });
};

export const SET_ROUTES = 'SET_ROUTES';
export const setRoutes = (routes) => (dispatch) => {
  return dispatch({
    type: SET_ROUTES,
    payload: routes
  });
};

export const SET_CONT_INFO = 'SET_CONT_INFO';
export const setContInfo = (contInfos) => (dispatch) => {
  return dispatch({
    type: SET_CONT_INFO,
    payload: contInfos
  });
};

// define actions
export const saveFastDispatcherForm = (payload) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.SAVE_FAST_DISPATCHER_NO_CONSIGNMENT,
    payload: api.post(`/TruckingPlan/createnonconsignment`, payload)
  });
};

export const getTruckingPlanCommand = (id) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.GET_TRUCKING_PLAN_COMMAND_BY_ID,
    payload: api.get(`TruckingPlan/GetTruckingPlanCommand?TruckingPlanId=${id}`)
  });
};

export const updatetruckingStatus = (payload) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.UPDATE_TRUCKING_STATUS,
    payload: api.put(`/TruckingPlan/update/status`, payload)
  });
};

export const updateInfoVAT = (payload) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.UPDATE_INFO_VAT,
    payload: api.put(`/TruckingPlan/updatePrintStatus`, payload)
  });
};

export const actionDeleteDriverPlanNew = (id, currentPage) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.DELETE_DRIVER_PLAN,
    payload: deleteDriverPlanNew(id),
    meta: {
      currentPage: currentPage
    }
  });
};

//dieu xe ghep
export const actionGetDriverPlanByIDV2 = (id) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.GET_DRIVER_PLAN_BY_ID_V2,
    payload: api.get(`/truckingPlan/getByIdV2/${id}`)
  });
};
export const resetSearchDriverPlanPerPage = () => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.RESET_SEARCH_DRIVER_PLAN_PER_PAGE,
    payload: []
  });
};
//confirmPlan
export const actionConfirmUpdatePlan = (payload) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.CONFIRM_UPDATE_PLAN,
    payload: api.put(`TruckingPlan/update/confirmPlan`, payload)
  });
};

export const actionGetConSignmentCodeId = (data) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.LINK_SCREEN_CONSIGNMENT,
    payload: data
  });
};

export const actionUpdateTractorTrailer = (payload) => (dispatch) => {
  return dispatch({
    payload: api.put('TruckingPlan/update/tractorTrailer', payload)
  });
};

export const actionGetTractorTrailerByEmployee = (params) => (dispatch) => {
  return dispatch({
    type: DRIVER_PLAN_ACTIONS.GET_TRACTOR_TRAILER_BY_EMPLOYEE,
    payload: api.get('TractorTrailerInfo/getByEmployee', { params })
  });
};

export const updatePhotoTruckingPlan = (Id, payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.post(`/TruckingPlan/upload/${Id}`, payload)
  });
};

export const uploadFileTruckingPlan = (Id, payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.post(`/Truckingplan/uploadFile/${Id}`, payload)
  });
};

export const deletePhotoTruckingPlan = (Id) => (dispatch) => {
  return dispatch({
    payload: httpClient.delete(`truckingplan/deleteFile/${Id}`)
  });
};
