import { AGGREGATE_FUNCS, OIL_REMAIN_REPORT_TYPE } from 'const/common';

import * as MyMath from './MathUtils';

export function findHeight(node, childrenLabel = 'SysSettingGrids') {
  if (!node[childrenLabel] || 0 === node[childrenLabel].length) {
    return 1;
  } else {
    const childrenHeights = node[childrenLabel].map((child) => findHeight(child));
    const maxHeight = Math.max(...childrenHeights);
    return maxHeight + 1;
  }
}

export function findBreadth(node, childrenLabel = 'SysSettingGrids') {
  if (!node[childrenLabel]) {
    return 1;
  } else {
    const childrenBreadths = node[childrenLabel].map((child) => findBreadth(child));
    const sumBreadth = childrenBreadths.reduce((a, b) => a + b, 0);
    return sumBreadth;
  }
}

export function fillColSpanForColumns(columns) {
  const colSpanColumns = columns.map((column) => {
    return fillColSpanForColumn(column);
  });
  return colSpanColumns;
}

function fillColSpanForColumn(column, childrenLabel = 'SysSettingGrids') {
  let colSpanColumn = column;
  if (!column[childrenLabel] || 0 === column[childrenLabel].length) {
    colSpanColumn = {
      ...column,
      colSpan: 1
    };
  } else {
    const colSpanColumnChildren = column[childrenLabel].map((child) => {
      return fillColSpanForColumn(child);
    });
    colSpanColumn = {
      ...column,
      colSpan: colSpanColumnChildren.reduce((acc, element) => acc + element.colSpan, 0),
      [childrenLabel]: colSpanColumnChildren
    };
  }
  return colSpanColumn;
}

export function fillRowSpanForColumns(columns, childrenLabel = 'SysSettingGrids') {
  const tree = {
    [childrenLabel]: columns
  };
  const height = findHeight(tree);
  const rowSpanTree = fillRowSpanForColumn(tree, height);
  return rowSpanTree[childrenLabel];
}

function fillRowSpanForColumn(tree, height, childrenLabel = 'SysSettingGrids') {
  if (!tree[childrenLabel] || 0 === tree[childrenLabel].length)
    return {
      ...tree,
      rowSpan: height
    };
  const treeChildren = tree[childrenLabel].map((child) => fillRowSpanForColumn(child, height - 1));
  const result = {
    ...tree,
    [childrenLabel]: treeChildren,
    rowSpan: 1
  };
  return result;
}

export function findAllLeaves(tree, leaves, childrenLabel = 'SysSettingGrids') {
  if (!tree[childrenLabel] || tree[childrenLabel].length === 0) {
    leaves[leaves.length] = tree;
  } else {
    tree[childrenLabel].forEach((child) => {
      findAllLeaves(child, leaves);
    });
  }
}

/*
 * Create display column with index information.
 * For example: columnTree: [ {stt}, {xe, ['ma xe', 'chu xe']}]
 * The output is:
 * [{stt}, {'ma xe'}, {'chu xe'}]
 */
export function extractDisplayColumnInfo(columns, childrenLabel = 'SysSettingGrids') {
  const leaves = [];
  const tree = { [childrenLabel]: columns };
  findAllLeaves(tree, leaves);
  return leaves;
}

/*
 * Create multi-level array column from columnTree.
 * For example: columnTree: [ {stt}, {xe, ['ma xe', 'chu xe']}]
 * The output is:
 * [{stt, rowSpan: 2, colSpan: 1}, {xe, rowSpan: 1, colSpan: 2}, {""}],
 * [{""}, {'ma xe'}, {'chu xe'}]
 */
export function decodeColumnsTree(columns, childrenLabel = 'SysSettingGrids') {
  const colSpanColumns = fillColSpanForColumns(columns);
  const colAndRowSpanColumns = fillRowSpanForColumns(colSpanColumns);
  const height = findHeight({ [childrenLabel]: colAndRowSpanColumns }) - 1;
  const result = []; // columns headers
  const fill = []; // Index flag to check where the column header tier "level" filled to which index
  for (let i = 0; i < height; i++) {
    result[i] = [];
    fill[i] = -1;
  }
  traverseToDecodeColumnsTree(colAndRowSpanColumns, result, fill, 0, height);
  return result;
}

function traverseToDecodeColumnsTree(columns, result, fill, level, height, childrenLabel = 'SysSettingGrids') {
  const childrenQueue = [];
  if (level >= height) return;
  else if (!columns || columns.length === 0) {
    childrenQueue.push(null);
    return;
  }
  columns.forEach((column, index) => {
    if (column === null) {
      result[level].push({ label: '', index: '' });
      childrenQueue.push(null);
    } else {
      const colSpan = column.colSpan ? column.colSpan : 1;
      for (let i = 0; i < colSpan; i++) {
        if (i === 0) {
          result[level].push(column);
        } else {
          result[level].push({ label: '', index: '' });
        }
      }
      if (!column[childrenLabel] || 0 === column[childrenLabel].length) {
        childrenQueue.push(null);
      } else {
        childrenQueue.push(...column[childrenLabel]);
      }
    }
  });
  traverseToDecodeColumnsTree(childrenQueue, result, fill, level + 1, height);
}

/*
 * Get all columns need to execute aggregate function
 */
export function extractAggregateColumns(displayColumnInfos) {
  const aggregateColumns = [];
  displayColumnInfos.forEach((column) => {
    const aggregates = column.Formula;
    if (aggregates) {
      const aggregateFuncs = aggregates
        .toLowerCase()
        .replaceAll(/\s/g, '')
        .split(',');
      const validAggregateFuncs = getValidAggregateFuncs(aggregateFuncs);
      if (validAggregateFuncs.length > 0) {
        column.aggregateFuncs = validAggregateFuncs;
        aggregateColumns.push(column);
      }
    }
  });
  return aggregateColumns;
}

function getValidAggregateFuncs(aggregateFuncs) {
  const validAggregateFuncs = [];
  aggregateFuncs.forEach((func) => {
    if (AGGREGATE_FUNCS.includes(func)) {
      validAggregateFuncs.push(func);
    }
  });
  return validAggregateFuncs;
}

/*
 * From aggregate function list up in each column, this function calculate base on provied aggr function
 */
export function extractDataForAggregateColumns(aggregateColumns, reports) {
  //   console.log('extractDataForAggregateColumns START', aggregateColumns, reports);
  const aggregateResults = [];
  const data = [];
  for (let i = 0; i < aggregateColumns.length; i++) {
    aggregateResults[i] = [];
    data[i] = [];
  }
  aggregateColumns.forEach((aggregateColumn, index) => {
    reports.forEach((report) => {
      data[index].push(report[aggregateColumn.index]);
    });
    aggregateColumns[index].data = data[index];
  });
  aggregateColumns.forEach((aggregateColumn, index) => {
    aggregateColumn.aggregateFuncs.forEach((funcName) => {
      const aggregateColumnData = aggregateColumn.data.reduce((acc, item) => {
        if (Array.isArray(item)) {
          return [...acc, ...item];
        } else {
          return [...acc, item];
        }
      }, []);
      const result = MyMath[funcName].call(this, ...aggregateColumnData);
      aggregateResults[index].push(result);
      aggregateColumns[index].aggregateResults = aggregateResults[index];
    });
  });
  //   console.log('extractDataForAggregateColumns end', aggregateColumns, reports);

  return aggregateColumns;
}

export function addAlignmentStyleForDisplayColumns(props, displayColumns) {
  const mapReport = props.mapReport;
  if (!mapReport) return displayColumns;
  const displayColumnInfosWithAlignment = displayColumns.map((column) => {
    const alignmentColumn = addAlignmentStyleForDisplayColumn(mapReport, column);

    return alignmentColumn;
  });
  return displayColumnInfosWithAlignment;
}

function addAlignmentStyleForDisplayColumn(mapReport, column, childrenLabel = 'SysSettingGrids') {
  const index = column.index;
  let alignmentColumn = column;
  if (mapReport[index] && mapReport[index].name === MyMath.formatNumberToReadableString.name) {
    alignmentColumn = addRightAlignemnt(alignmentColumn);
  }
  // set alignment for children
  const alignmentChildren = addRightAlignmentForChildren(mapReport, alignmentColumn);
  return {
    ...alignmentColumn,
    [childrenLabel]: [...alignmentChildren]
  };
}

function addRightAlignmentForChildren(mapReport, column, childrenLabel = 'SysSettingGrids') {
  const children = column[childrenLabel];
  let alignmentChildren = [];
  if (children && children.length > 0) {
    alignmentChildren = children.map((child) => {
      return addAlignmentStyleForDisplayColumn(mapReport, child);
    });
  }
  return alignmentChildren;
}

function addRightAlignemnt(column) {
  // Add body classname
  let bodyClassName = column.bodyClassName;
  if (bodyClassName) {
    bodyClassName += ' text-right';
  } else {
    bodyClassName = 'text-right';
  }
  const alignmentColumn = {
    ...column,
    bodyClassName: bodyClassName
  };
  return alignmentColumn;
}

export function addProperty(displayColumns = [], settings) {
  displayColumns.forEach((column) => {
    addPropertyToDisplayColumn(column, settings);
  });
  return displayColumns;
}

function addPropertyToDisplayColumn(column, settings, childrenLabel = 'SysSettingGrids') {
  Object.keys(settings).forEach((field) => {
    if (field === column.index || field === 'all') {
      Object.assign(column, settings[field]);
    }
  });
  const children = column[childrenLabel];
  if (children) {
    children.forEach((child) => addPropertyToDisplayColumn(child, settings));
  }
}

// export function addProperty(displayColumns = [], settings) {
//   console.log('addProperty START', deepCopy(displayColumns), settings);
//   const displayColumnsWithAddedProperty = displayColumns.map((column) => {
//     return addPropertyToDisplayColumn(column, settings);
//   });
//   console.log('addProperty END', displayColumns);
//   return displayColumnsWithAddedProperty;
// }

// function addPropertyToDisplayColumn(column, settings) {
//   console.log('addPropertyToDisplayColumn START', column, settings);
//   let columnWithAddedProperty = { ...column };
//   Object.keys(settings).forEach((field) => {
//     if (field === 'all' || field === column.index) {
//       Object.assign(columnWithAddedProperty, settings[field]);
//     }
//   });
//   const children = column.SysSettingGrids;
//   let childrenWithAddedProperty = children;
//   if (children) {
//     childrenWithAddedProperty = addProperty(children, settings);
//   }
//   columnWithAddedProperty = {
//     ...columnWithAddedProperty,
//     SysSettingGrids: [...childrenWithAddedProperty]
//   };
//   console.log('addPropertyToDisplayColumn END', columnWithAddedProperty);
//   return columnWithAddedProperty;
// }

export function extractColumns(data, preIndexColumnsName, keyName, keyValueColumnIndex) {
  //   console.log('extractColumns START', data, preIndexColumnsName, keyName, keyValueColumnIndex);
  if (data && data.length === 0) return [];
  let columns = [];
  data.forEach((item) => {
    const isOuter = item.IsOuter;
    const itemIsImport = item.ReportType === OIL_REMAIN_REPORT_TYPE.IMPORT;
    if (!itemIsImport && !isOuter) {
      const columnsLable = item[`${keyName}`];
      const columnIndex = getColumnIndex(item, preIndexColumnsName, keyValueColumnIndex);
      let foundColumn = findColumn(columns, columnIndex, preIndexColumnsName, keyValueColumnIndex);
      if (!foundColumn) {
        foundColumn = createNewColumn(columnIndex, columnsLable);
        columns.push(foundColumn);
      }
    }
  });
  //   console.log('extractColumns END', columns);
  return columns;
}

function getColumnIndex(column, preIndexColumnsName, keyValueColumnIndex) {
  const index = column[`${keyValueColumnIndex}`];
  const result = column.indexColumn ? column.indexColumn : `${preIndexColumnsName}${index}`;
  return result;
}

function createNewColumn(columnIndex, labelName) {
  return {
    indexColumn: columnIndex,
    label: labelName
  };
}

function findColumn(columns, columnIndex, preIndexColumnsName, keyValueColumnIndex) {
  let result = null;
  for (let i = 0; i < columns.length; i++) {
    const currentColumn = columns[i];
    const currentColumnIndex = getColumnIndex(currentColumn, preIndexColumnsName, keyValueColumnIndex);
    if (currentColumnIndex === columnIndex) {
      result = currentColumn;
    }
  }
  return result;
}
/*
 * From aggregate function list up in each column, this function calculate base on provied aggr function
 */
export function extractDataForAggregateColumnsCustom(aggregateColumns, reports) {
  // console.log('extractDataForAggregateColumns START', aggregateColumns, reports);
  const aggregateResults = [];
  const data = [];
  for (let i = 0; i < aggregateColumns.length; i++) {
    aggregateResults[i] = [];
    data[i] = [];
  }
  aggregateColumns.forEach((aggregateColumn, index) => {
    reports.forEach((report) => {
      if (report.Sum) {
        data[index].push(report[aggregateColumn.index]);
      }
    });
    aggregateColumns[index].data = data[index];
  });
  aggregateColumns.forEach((aggregateColumn, index) => {
    aggregateColumn.aggregateFuncs.forEach((funcName) => {
      const aggregateColumnData = aggregateColumn.data.reduce((acc, item) => {
        if (Array.isArray(item)) {
          return [...acc, ...item];
        } else {
          return [...acc, item];
        }
      }, []);
      const result = MyMath[funcName].call(this, ...aggregateColumnData);
      aggregateResults[index].push(result);
      aggregateColumns[index].aggregateResults = aggregateResults[index];
    });
  });
  // console.log('extractDataForAggregateColumns end', aggregateColumns, reports);

  return aggregateColumns;
}

function isRoot(item, parentLabel) {
  const parentVal = item[parentLabel];
  const result = parentVal === undefined || parentVal === null || parentVal === -1;
  return result;
}

/**
 * find all children by parent Id from an array
 * @param {*} array source array
 * @param {*} rootEvalFunc function to evaluate which node to root
 * @param {*} parentLabel parentId label, default to ParentId
 * @param {*} nodeValLabel node value label, default = Id
 * @returns a list of node which has parentId
 */
function findChildrenByParentId(array, rootEvalFunc, parentLabel) {
  const result = [];
  array.forEach((item) => {
    let isChild = false;
    if (typeof rootEvalFunc === 'function') {
      isChild = rootEvalFunc.call(this, item, parentLabel);
    } else {
      isChild = rootEvalFunc === item[parentLabel];
    }
    if (isChild) {
      result.push(item);
    }
  });
  return result;
}

export function makeTree(
  array,
  defaultLabel = { parentLabel: 'ParentId', nodeValLabel: 'Id', childrenLabel: 'children' },
  rootEvalFunc = isRoot
) {
  const { parentLabel, nodeValLabel, childrenLabel } = defaultLabel;
  const nodes = [...array];
  const roots = findChildrenByParentId(array, rootEvalFunc, parentLabel, nodeValLabel);
  roots.forEach((root, index) => {
    const children = findChildrenByParentId(array, root[nodeValLabel], parentLabel);
    root[childrenLabel] = children;
    nodes[index].traverved = true;
  });
  nodes.forEach((node) => {
    if (!node.traverved) {
      roots.push(node);
    }
  });
  return roots;
}
