import { CHANGE_TIME_OUT, RESET_TIME_OUT } from 'actions/authAction';
import axios from 'axios';
import { getAccessToken } from 'commons/authUtils';
import { SESSION_TIMEOUT_SEC, TOKEN_EXPIRED_DATE, TOKEN_REAL_EXPIRED_DATE } from 'const/common';
import { store } from 'store';

const httpClient = axios.create({
  baseURL: `${process.env.REACT_APP_HOST_URL}/api` /// nhớ đổi lại nha tuân
  // baseURL: `https://tts_soft.tts-vn.com:5005/api`
});
let timer = null;
httpClient.defaults.headers.common['apikey'] = getAccessToken();
const debouncedDispatchTimeOut = (url, data, params) => {
  clearTimeout(timer);
  timer = setTimeout(() => {
    store.dispatch({ type: CHANGE_TIME_OUT, payload: { url, data, params } });
  }, 1000);
};

httpClient.interceptors.request.use((request) => {
  const path = request.url.replace(request.baseURL, '');
  if ('/authentication/login' !== path) {
    const tokenExpired = localStorage.getItem(TOKEN_EXPIRED_DATE);
    const tokenExpiredDate = new Date(tokenExpired);
    const now = new Date();
    if (tokenExpiredDate.getTime() < now.getTime()) {
      localStorage.clear();
      store.dispatch({ type: RESET_TIME_OUT });
      window.location.href = window.location.pathname + window.location.search + window.location.hash;
    } else {
      tokenExpiredDate.setTime(now.getTime() + SESSION_TIMEOUT_SEC * 1000);
      const newTokenExpiredDateString = tokenExpiredDate.toISOString();
      debouncedDispatchTimeOut(path, request.data, request.params);
      localStorage.setItem(TOKEN_EXPIRED_DATE, newTokenExpiredDateString);
    }
  }
  return request;
});

// this is on my app.js file
httpClient.interceptors.response.use(
  function (response) {
    // console.log('response', response);
    return response;
  },
  function (error) {
    // console.log('reponse error', error, error.response);
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      // Go to login page
      const tokenExpired = localStorage.getItem(TOKEN_REAL_EXPIRED_DATE);
      const tokenExpiredDate = new Date(tokenExpired);
      if (tokenExpiredDate.getTime() < new Date().getTime()) {
        localStorage.clear();
        window.location.href = '/login';
      } else {
        // const currentLocation = window.location.pathname + window.location.search + window.location.hash;
        // if (!currentLocation.includes('?backUrl=')) {
        //   const encodedUrl = encodeURIComponent(currentLocation);
        //   window.location.href = `/401?backUrl=${encodedUrl}`;
        // }
      }
    }
    return Promise.reject(error);
  }
);

export default httpClient;
