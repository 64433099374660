import {
  VEHICLE_ACTIONS
} from "actions/vehicleInfoManagementActions";
import {
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  getVehiclesPerPage,
} from "services/vehicle";

function* putActionUpdateData(actions) {
  const pageSize = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE);
  yield put({
    type: VEHICLE_ACTIONS.GET_VEHICLES_PER_PAGE,
    payload: getVehiclesPerPage(1, pageSize)
  })
}

export function* actionUpdateVehiclesData() {
  yield takeEvery([VEHICLE_ACTIONS.UPDATE_VEHICLES_FULFILLED, VEHICLE_ACTIONS.CREATE_VEHICLES_FULFILLED,
  // VEHICLE_ACTIONS.DELETE_VEHICLES_FULFILLED
], putActionUpdateData);
}