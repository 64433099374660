import {
    CHANGE_SETUP_FIELD_VALUE,
    CHANGE_SETUP_FIELD_VALUE_FULFILLED,
    CHANGE_SETUP_FIELD_VALUE_PENDING,
    CHANGE_SETUP_FIELD_VALUE_REJECTED,
    MOVE_DOWN_COLUMN,
    MOVE_DOWN_COLUMN_FULFILLED,
    MOVE_DOWN_COLUMN_PENDING,
    MOVE_DOWN_COLUMN_REJECTED,
    MOVE_UP_COLUMN,
    MOVE_UP_COLUMN_FULFILLED,
    MOVE_UP_COLUMN_PENDING,
    MOVE_UP_COLUMN_REJECTED,
} from "actions/setupDialogAction";
import { call, put, takeLatest } from "redux-saga/effects";

// (TTS)LyLV 2020/04/08 Watcher START
export function* watchChangeSetupField() {
    yield takeLatest(CHANGE_SETUP_FIELD_VALUE, processChangeSetupField);
}
export function* watchMoveUpColumn() {
    yield takeLatest(MOVE_UP_COLUMN, processMoveUpColumn);
}
export function* watchMoveDownColumn() {
    yield takeLatest(MOVE_DOWN_COLUMN, processMoveDownColumn);
}
// (TTS)LyLV 2020/04/08 Watcher END

// (TTS)LyLV 2020/04/08 SAGA START
// saga worker sigin
function wait(ms) {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    })
}

function* processChangeSetupField(action) {
    // console.log("auth sagas userSignin");
    yield put({ type: CHANGE_SETUP_FIELD_VALUE_PENDING, payload: action.payload });
    try {
        yield call(wait, 100);
        yield put({ type: CHANGE_SETUP_FIELD_VALUE_FULFILLED, payload: action.payload });
    }
    catch(err){
        yield put({ type: CHANGE_SETUP_FIELD_VALUE_REJECTED, payload: action.payload });
    }
}

function* processMoveUpColumn(action) {
    // console.log("auth sagas userSignin");
    yield put({ type: MOVE_UP_COLUMN_PENDING, payload: action.payload });
    try {
        yield call(wait, 100);
        yield put({ type: MOVE_UP_COLUMN_FULFILLED, payload: action.payload });
    }
    catch(err){
        yield put({ type: MOVE_UP_COLUMN_REJECTED, payload: action.payload });
    }
}

function* processMoveDownColumn(action) {
    // console.log("auth sagas userSignin");
    yield put({ type: MOVE_DOWN_COLUMN_PENDING, payload: action.payload });
    try {
        yield call(wait, 100);
        yield put({ type: MOVE_DOWN_COLUMN_FULFILLED, payload: action.payload });
    }
    catch(err){
        yield put({ type: MOVE_DOWN_COLUMN_REJECTED, payload: action.payload });
    }
}



// (TTS)LyLV 2020/04/08 SAGA END