import {
    CUSTOM_BRANCH_ACTIONS
  } from "actions/custombranch";
  
  
  const customBranchReducer = (state = {}, action) => {
    switch (action.type) {
      // get all goods type
      case CUSTOM_BRANCH_ACTIONS.GET_ALL_CUSTOM_BRANCH_PENDING:
        return {
          ...state,
          isLoading: true
        };
  
      case CUSTOM_BRANCH_ACTIONS.GET_ALL_CUSTOM_BRANCH_FULFILLED:
        let customBranch = {
          data: action.payload.data.data
        };
        return {
          ...state,
          customBranch: customBranch,
            isLoading: false,
        };
  
      case CUSTOM_BRANCH_ACTIONS.GET_ALL_CUSTOM_BRANCH_REJECTED:
        return {
          ...state,
          isLoading: false,
            isMsg: true,
            msg: "Đã xảy ra lỗi"
        };
  
      default:
        return state;
    }
  };
  
  export default customBranchReducer;