import api from 'HttpClient';

export const SHIPPING_LIST_REPORT = {
  SHIPPING_LIST_REPORT_TYPE: 'SHIPPING_LIST_REPORT_TYPE',
  SHIPPING_LIST_REPORT_TYPE_PENDING: 'SHIPPING_LIST_REPORT_TYPE_PENDING',
  SHIPPING_LIST_REPORT_TYPE_FULFILLED: 'SHIPPING_LIST_REPORT_TYPE_FULFILLED',
  SHIPPING_LIST_REPORT_TYPE_REJECTED: 'SHIPPING_LIST_REPORT_TYPE_REJECTED'
};

export const getShippingListReportAction = (payload) => (dispatch) => {
  return dispatch({
    type: SHIPPING_LIST_REPORT.SHIPPING_LIST_REPORT_TYPE,
    payload: api.get(`/ShippingListReport/Antin`, { params: payload })
  });
};

export const getShippingListReportActionAll = (params) => (dispatch) => {
  return dispatch({
    payload: api.get(`/ShippingListReport/Antin`, { params })
  });
};
