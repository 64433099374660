import { IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS } from "actions/report/importRepairPayment";

const INIT_STATE = {
  listPerPage: [],
  listSparepart: [],
  listAll: [],
  loading: false,
  typeMessage: "",
  message: "",
  isShowMessage: false,
  pagination: {},
  isCloseModal: false,
  isSearch: false,
  isReload: false,
  listDepartment: [],
  listCar: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.GET_IMPORT_REPAIR_PAYMENT_REPORT_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.GET_IMPORT_REPAIR_PAYMENT_REPORT_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isReload: false,
      };
    }
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.GET_IMPORT_REPAIR_PAYMENT_REPORT_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
        isReload: false,
      };
    }

    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.GET_ALL_IMPORT_REPAIR_PAYMENT_REPORT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isReload: false,
      };
    }
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.GET_ALL_IMPORT_REPAIR_PAYMENT_REPORT_FULFILLED: {
      return {
        ...state,
        loading: false,
        listSparepart: action.payload.data.data,
        isReload: false,
      };
    }
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.GET_ALL_IMPORT_REPAIR_PAYMENT_REPORT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }

    case "UPDATE_SHOW_ALERT": {
      return {
        ...state,
        isShowMessage: false,
      };
    }

    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.SEARCH_DATA_REPORT_IMPORT_REPAIR_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.SEARCH_DATA_REPORT_IMPORT_REPAIR_PAYMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
      };
    }
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.SEARCH_DATA_REPORT_IMPORT_REPAIR_PAYMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.UPDATE_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
      };
    }
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.UPDATE_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "success",
        message: "Cập nhật dữ liệu thành công !",
        isCloseModal: true,
      };
    }
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.UPDATE_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể cập nhật dữ liệu!",
        isCloseModal: false,
      };
    }
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.GET_ALL_REPORT_IMPORT_REPAIR_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.GET_ALL_REPORT_IMPORT_REPAIR_PAYMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        listAll: action.payload.data.data,
      };
    }
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.GET_ALL_REPORT_IMPORT_REPAIR_PAYMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }

    //
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.GET_DEPARTMENT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isReload: false,
      };
    }
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.GET_DEPARTMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        listDepartment: action.payload.data.data,
        isReload: false,
      };
    }
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.GET_DEPARTMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }

    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.GET_CAR_FILTER_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isReload: false,
      };
    }
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.GET_CAR_FILTER_FULFILLED: {
      return {
        ...state,
        loading: false,
        listCar: action.payload.data.data,
        isReload: false,
      };
    }
    case IMPORT_REPAIR_PAYMENT_REPORT_ACTIONS.GET_CAR_FILTER_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }

    default:
      return state;
  }
};
