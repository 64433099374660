import api from 'HttpClient/index';

export const getOilPricesManagementPerPage = (pageNo, pageSize) => {
  return api.get(`/OilPriceInfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`);
};
export const getOilPricesManagementPerPageAll = (params) => {
  return api.get(`/OilPriceInfo/paging/page`, { params });
};

export const getAllOilPricesManagement = () => {
  return api.get(`/OilPriceInfo/getAll`);
};

export const getOilPricesManagementByID = (id) => {
  return api.get(`/OilPriceInfo/getById/${id}`);
};

export const createOilPricesManagement = (payload) => {
  return api.post(`/OilPriceInfo/create`, payload);
};

export const updateOilPricesManagement = (payload) => {
  return api.put(`/OilPriceInfo/update`, payload);
};

export const deleteOilPricesManagement = (id) => {
  return api.delete(`/OilPriceInfo/delete/${id}`);
};
export const searchData = (paramsSearch, pageNo, pageSize) => {
  return api.get(
    `/OilPriceInfo/search?fromDate=${paramsSearch.startDate}&toDate=${paramsSearch.endDate}&Page=${pageNo}&PageSize=${pageSize}&OilCategoryId=${paramsSearch.OilCategoryId}`
  );
};
export const searchDataAll = (params) => {
  return api.get(`/OilPriceInfo/search`, { params });
};
