import { DRIVER_ADVANCE_MANAGEMENT } from 'actions/driverAdvanceManagement';

const INIT_STATE = {
  reports: [],
  pagination: { pageNo: 1, pageSize: process.env.REACT_APP_DEFAULT_PAGE_SIZE, totalRecord: 0 },
  loading: false,
  showMessage: false,
  alertMessage: '',
  messageType: '',
  consignmentsList: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DRIVER_ADVANCE_MANAGEMENT.CREATE_DRIVER_ADVANCE_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case DRIVER_ADVANCE_MANAGEMENT.CREATE_DRIVER_ADVANCE_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        isCloseModal: false,
        alertMessage: 'Thêm mới dữ liệu thành công!'
      };
    }
    case DRIVER_ADVANCE_MANAGEMENT.CREATE_DRIVER_ADVANCE_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: 'Thêm mới dữ liệu thất bại!',
        isCloseModal: false
      };
    }

    case DRIVER_ADVANCE_MANAGEMENT.UPDATE_DRIVER_ADVANCE_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case DRIVER_ADVANCE_MANAGEMENT.UPDATE_DRIVER_ADVANCE_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        isCloseModal: false,
        alertMessage: 'Cập nhật dữ liệu thành công!'
      };
    }
    case DRIVER_ADVANCE_MANAGEMENT.UPDATE_DRIVER_ADVANCE_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: 'Cập nhật dữ liệu thất bại!',
        isCloseModal: false
      };
    }

    case DRIVER_ADVANCE_MANAGEMENT.DELETE_DRIVER_REPORT_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case DRIVER_ADVANCE_MANAGEMENT.DELETE_DRIVER_REPORT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        isCloseModal: false,
        alertMessage: 'Xoá dữ liệu thành công!'
      };
    }
    case DRIVER_ADVANCE_MANAGEMENT.DELETE_DRIVER_REPORT_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: 'Xóa dữ liệu thất bại!',
        isCloseModal: false
      };
    }

    case DRIVER_ADVANCE_MANAGEMENT.DELETE_DRIVER_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case DRIVER_ADVANCE_MANAGEMENT.DELETE_DRIVER_PAYMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        isCloseModal: false,
        alertMessage: 'Xoá dữ liệu thành công!'
      };
    }
    case DRIVER_ADVANCE_MANAGEMENT.DELETE_DRIVER_PAYMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: 'Xóa dữ liệu thất bại!',
        isCloseModal: false
      };
    }

    case DRIVER_ADVANCE_MANAGEMENT.CONFIRM_DRIVER_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case DRIVER_ADVANCE_MANAGEMENT.CONFIRM_DRIVER_PAYMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        isCloseModal: false,
        alertMessage: 'Cập nhật dữ liệu thành công!'
      };
    }
    case DRIVER_ADVANCE_MANAGEMENT.CONFIRM_DRIVER_PAYMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: action.payload.message,
        isCloseModal: false
      };
    }

    case DRIVER_ADVANCE_MANAGEMENT.CREATE_DRIVER_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case DRIVER_ADVANCE_MANAGEMENT.CREATE_DRIVER_PAYMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        isCloseModal: false,
        alertMessage: 'Thêm mới dữ liệu thành công!'
      };
    }
    case DRIVER_ADVANCE_MANAGEMENT.CREATE_DRIVER_PAYMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: 'Thêm mới dữ liệu thất bại!',
        isCloseModal: false
      };
    }

    case DRIVER_ADVANCE_MANAGEMENT.UPDATE_DRIVER_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case DRIVER_ADVANCE_MANAGEMENT.UPDATE_DRIVER_PAYMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        isCloseModal: false,
        alertMessage: 'Cập nhật dữ liệu thành công!'
      };
    }
    case DRIVER_ADVANCE_MANAGEMENT.UPDATE_DRIVER_PAYMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        messageType: 'error',
        alertMessage: 'Cập nhật dữ liệu thất bại!',
        isCloseModal: false
      };
    }

    default:
      return state;
  }
};
