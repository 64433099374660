import { TRUCKING_TYPE_ACTIONS } from "actions/truckingType";

const INIT_STATE = {
    truckingTypes: [],
    isLoading: false,
    isMsg: false,
    msg: ""
}

const truckingTypeReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        // get all goods type
        case TRUCKING_TYPE_ACTIONS.GET_ALL_TRUCKING_TYPE_PENDING:
            return {
                ...state,
                isLoading: true
            };

        case TRUCKING_TYPE_ACTIONS.GET_ALL_TRUCKING_TYPE_FULFILLED:
            return {
                ...state,
                truckingTypes: action.payload.data.data,
                isLoading: false
            };

        case TRUCKING_TYPE_ACTIONS.GET_ALL_TRUCKING_TYPE_REJECTED:
            return {
                ...state,
                isLoading: false,
                isMsg: true,
                msg: "Đã xảy ra lỗi"
            };

        default:
            return state;
    }
};

export default truckingTypeReducer;