import httpClient from "HttpClient";


export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_PENDING = "CHANGE_PASSWORD_PENDING";
export const CHANGE_PASSWORD_FULFILLED = "CHANGE_PASSWORD_FULFILLED";
export const CHANGE_PASSWORD_REJECTED = "CHANGE_PASSWORD_REJECTED";

export const changePassword = (payload) => dispatch => {
    return dispatch({
        type: CHANGE_PASSWORD,
        payload: httpClient.post(`/authentication/changepassword`, payload)
    });
};