import {
  CREATE_TRANSPORT_COST_NEW_FULFILLED,
  CREATE_TRANSPORT_COST_NEW_PENDING,
  CREATE_TRANSPORT_COST_NEW_REJECTED,
  DELETE_TRANSPORT_COST_NEW_FULFILLED,
  DELETE_TRANSPORT_COST_NEW_PENDING,
  DELETE_TRANSPORT_COST_NEW_REJECTED,
  FETCH_ALL_TRANSPORT_COST_NEWS_FULFILLED,
  FETCH_ALL_TRANSPORT_COST_NEWS_PENDING,
  FETCH_ALL_TRANSPORT_COST_NEWS_REJECTED,
  FETCH_CALCULATING_TYPE_FULFILLED,
  FETCH_CALCULATING_TYPE_PENDING,
  FETCH_CALCULATING_TYPE_REJECTED,
  FETCH_NORM_TRANSPORT_COST_NEW_FULFILLED,
  FETCH_NORM_TRANSPORT_COST_NEW_PENDING,
  FETCH_NORM_TRANSPORT_COST_NEW_REJECTED,
  FETCH_TRANSPORT_COST_NEW_DETAIL_FULFILLED,
  FETCH_TRANSPORT_COST_NEW_DETAIL_PENDING,
  FETCH_TRANSPORT_COST_NEW_DETAIL_REJECTED,
  FETCH_TRANSPORT_COST_NEWS_FULFILLED,
  FETCH_TRANSPORT_COST_NEWS_PENDING,
  FETCH_TRANSPORT_COST_NEWS_REJECTED,
  SET_CLOSE_TRANSPORT_COST_NEW_MODAL,
  SET_OPEN_TRANSPORT_COST_NEW_MODAL,
  UPDATE_TRANSPORT_COST_NEW_FULFILLED,
  UPDATE_TRANSPORT_COST_NEW_PENDING,
  UPDATE_TRANSPORT_COST_NEW_REJECTED,
} from 'actions';
import { getErrorMessage } from 'commons/ReducerUtils';
import {
  DEFAULT_PAGE_SIZE,
  MSG_ADD_FAILURE,
  MSG_ADD_SUCCESS,
  MSG_DELETE_FAILURE,
  MSG_DELETE_SUCCESS,
  MSG_EDIT_FAILURE,
  MSG_EDIT_SUCCESS,
  MSG_GET_DETAIL_FAILURE,
  MSG_PAGING_FAILURE,
  MSG_TYPE_ERROR,
  MSG_TYPE_SUCCESS} from 'const/common';
const INIT_STATE = {
  //combobox: get all
  allTransportCostNews: [],
  // paging
  transportCostNews: [],
  pagination: { PageIndex: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
  listLoading: false,

  // add/edit modal
  isOpenFormModal: false, // Show/hidden update modal
  transportCostNew: {},
  formLoading: false,
  //delete
  deleteLoading: false,
  // other
  showMessage: false,
  alertMessage: '',
  formAlertMessage: '',
  messageType: '',
  norm : {},
  calculatingType : []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region update modal
    case SET_OPEN_TRANSPORT_COST_NEW_MODAL: {
      return {
        ...state,
        isOpenFormModal: true,
        transportCostNew: action.payload.data,
        type: action.payload.type
      };
    }
    case SET_CLOSE_TRANSPORT_COST_NEW_MODAL: {
      return {
        ...state,
        isOpenFormModal: false,
        transportCostNew: INIT_STATE.transportCostNew
      };
    }
    //#endregion

    //#region getall
    case FETCH_ALL_TRANSPORT_COST_NEWS_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }
    case FETCH_ALL_TRANSPORT_COST_NEWS_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        allTransportCostNews: action.payload.data.data,
        showMessage: false
      };
    }
    case FETCH_ALL_TRANSPORT_COST_NEWS_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region paging
    case FETCH_TRANSPORT_COST_NEWS_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }

    case FETCH_TRANSPORT_COST_NEWS_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        transportCostNews: action.payload.data.data ?? INIT_STATE.transportCostNews,
        pagination: action.payload.data.pagination ?? INIT_STATE.pagination,
        showMessage: false
      };
    }
    case FETCH_TRANSPORT_COST_NEWS_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region create data
    case CREATE_TRANSPORT_COST_NEW_PENDING:
      return {
        ...state,
        formLoading: true
      };
    case CREATE_TRANSPORT_COST_NEW_FULFILLED: {
      const transportCostNew = action.payload.data;
      const transportCostNews = state.transportCostNews.map((item) => {
        return item.Id === transportCostNew.Id ? transportCostNew : item;
      });
      return {
        ...state,
        transportCostNews: [...transportCostNews],
        formLoading: false,
        showMessage: true,
        formAlertMessage: MSG_ADD_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case CREATE_TRANSPORT_COST_NEW_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_ADD_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region update data
    case UPDATE_TRANSPORT_COST_NEW_PENDING:
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    case UPDATE_TRANSPORT_COST_NEW_FULFILLED: {
      const transportCostNew = action.meta;
      let transportCostNews = [...state.transportCostNews];
      transportCostNews = transportCostNews.map((item) => {
        return item.Id === transportCostNew.Id ? transportCostNew : item;
      });
      return {
        ...state,
        transportCostNews,
        formLoading: false,
        showMessage: true,
        formAlertMessage: MSG_EDIT_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case UPDATE_TRANSPORT_COST_NEW_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_EDIT_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region  get detail
    case FETCH_TRANSPORT_COST_NEW_DETAIL_PENDING:
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    case FETCH_TRANSPORT_COST_NEW_DETAIL_FULFILLED:
      return {
        ...state,
        transportCostNew: action.payload.data,
        formLoading: false,
        showMessage: false
      };
    case FETCH_TRANSPORT_COST_NEW_DETAIL_REJECTED:
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        alertMessage: MSG_GET_DETAIL_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    //#endregion

    //#region delete data
    case DELETE_TRANSPORT_COST_NEW_PENDING:
      return {
        ...state,
        deleteLoading: true
      };
    case DELETE_TRANSPORT_COST_NEW_FULFILLED:
      return {
        ...state,
        isDelete: true,
        deleteLoading: false,
        showMessage: true,
        alertMessage: MSG_DELETE_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case DELETE_TRANSPORT_COST_NEW_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_DELETE_FAILURE) || action?.payload?.response?.data?.message;
      return {
        ...state,
        deleteLoading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }

    //#region fetch norm
    case FETCH_NORM_TRANSPORT_COST_NEW_PENDING: {
      return {
        ...state,
        showMessage: false
      };
    }

    case FETCH_NORM_TRANSPORT_COST_NEW_FULFILLED: {
      return {
        ...state,
        norm: action.payload.data.data ?? INIT_STATE.norm,
        showMessage: false
      };
    }
    case FETCH_NORM_TRANSPORT_COST_NEW_REJECTED: {
      return {
        ...state,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region fetch calculating type
    case FETCH_CALCULATING_TYPE_PENDING: {
      return {
        ...state,
        showMessage: false
      };
    }

    case FETCH_CALCULATING_TYPE_FULFILLED: {
      return {
        ...state,
        calculatingType: action.payload.data.data ?? INIT_STATE.calculatingType,
        showMessage: false
      };
    }
    case FETCH_CALCULATING_TYPE_REJECTED: {
      return {
        ...state,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    default:
      return state;
  }
};
