import api from 'HttpClient';
import moment from 'moment';

export const SET_OPEN_DAILY_TRANSPORT_PLAN_MODAL = 'SET_OPEN_DAILY_TRANSPORT_PLAN_MODAL';
export const setOpenDailyTransportPlanModal = (data) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_DAILY_TRANSPORT_PLAN_MODAL,
    payload: data
  });
};

export const SET_CLOSE_DAILY_TRANSPORT_PLAN_MODAL = 'SET_CLOSE_DAILY_TRANSPORT_PLAN_MODAL';
export const setCloseDailyTransportPlanModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_DAILY_TRANSPORT_PLAN_MODAL
  });
};

export const FETCH_ALL_DAILY_TRANSPORT_PLANS = 'FETCH_ALL_DAILY_TRANSPORT_PLANS';
export const FETCH_ALL_DAILY_TRANSPORT_PLANS_PENDING = 'FETCH_ALL_DAILY_TRANSPORT_PLANS_PENDING';
export const FETCH_ALL_DAILY_TRANSPORT_PLANS_FULFILLED = 'FETCH_ALL_DAILY_TRANSPORT_PLANS_FULFILLED';
export const FETCH_ALL_DAILY_TRANSPORT_PLANS_REJECTED = 'FETCH_ALL_DAILY_TRANSPORT_PLANS_REJECTED';
export const fetchAllDailyTransportPlans = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_DAILY_TRANSPORT_PLANS,
    payload: api.get(`/DailyTransportPlanInfo/getAll`)
  });
};

// const type of function get data per page
export const FETCH_DAILY_TRANSPORT_PLANS = 'FETCH_DAILY_TRANSPORT_PLANS';
export const FETCH_DAILY_TRANSPORT_PLANS_PENDING = 'FETCH_DAILY_TRANSPORT_PLANS_PENDING';
export const FETCH_DAILY_TRANSPORT_PLANS_FULFILLED = 'FETCH_DAILY_TRANSPORT_PLANS_FULFILLED';
export const FETCH_DAILY_TRANSPORT_PLANS_REJECTED = 'FETCH_DAILY_TRANSPORT_PLANS_REJECTED';
export const fetchDailyTransportPlans = (searchPayload, page, pageSize) => (dispatch) => {
  const now = moment().format('YYYY/MM/DD');
  return dispatch({
    type: FETCH_DAILY_TRANSPORT_PLANS,
    payload: api.get(`/DailyTransportPlanInfo/paging/page`, {
      params: { ...searchPayload, page, pageSize: pageSize }
    })
  });
};
export const fetchDailyTransportPlansAll = (params) => (dispatch) => {
  return dispatch({
    // type: FETCH_DAILY_TRANSPORT_PLANS,
    payload: api.get(`/DailyTransportPlanInfo/paging/page`, {
      params: { ...params }
    })
  });
};

export const FETCH_DAILY_TRANSPORT_PLANS_V2 = 'FETCH_DAILY_TRANSPORT_PLANS_V2';
export const FETCH_DAILY_TRANSPORT_PLANS_V2_PENDING = 'FETCH_DAILY_TRANSPORT_PLANS_V2_PENDING';
export const FETCH_DAILY_TRANSPORT_PLANS_V2_FULFILLED = 'FETCH_DAILY_TRANSPORT_PLANS_V2_FULFILLED';
export const FETCH_DAILY_TRANSPORT_PLANS_V2_REJECTED = 'FETCH_DAILY_TRANSPORT_PLANS_V2_REJECTED';
export const fetchDailyTransportPlansV2 = (searchPayload, page, pageSize) => (dispatch) => {
  const now = moment().format('YYYY/MM/DD');
  return dispatch({
    type: FETCH_DAILY_TRANSPORT_PLANS_V2,
    payload: api.get(`/DailyTransportPlanInfo/paging/pageV2`, {
      params: { ...searchPayload, page, pageSize: pageSize }
    })
  });
};
export const fetchDailyTransportPlansV2All = (params) => (dispatch) => {
  return dispatch({
    // type: FETCH_DAILY_TRANSPORT_PLANS_V2,
    payload: api.get(`/DailyTransportPlanInfo/paging/pageV2`, {
      params: { ...params }
    })
  });
};

// const type of function create data
export const CREATE_DAILY_TRANSPORT_PLAN = 'CREATE_DAILY_TRANSPORT_PLAN';
export const CREATE_DAILY_TRANSPORT_PLAN_PENDING = 'CREATE_DAILY_TRANSPORT_PLAN_PENDING';
export const CREATE_DAILY_TRANSPORT_PLAN_FULFILLED = 'CREATE_DAILY_TRANSPORT_PLAN_FULFILLED';
export const CREATE_DAILY_TRANSPORT_PLAN_REJECTED = 'CREATE_DAILY_TRANSPORT_PLAN_REJECTED';
export const createDailyTransportPlan = (payload) => (dispatch) => {
  return dispatch({
    type: CREATE_DAILY_TRANSPORT_PLAN,
    payload: api.post(`/DailyTransportPlanInfo/create`, payload)
  });
};

// const type of function update data
export const UPDATE_DAILY_TRANSPORT_PLAN = 'UPDATE_DAILY_TRANSPORT_PLAN';
export const UPDATE_DAILY_TRANSPORT_PLAN_PENDING = 'UPDATE_DAILY_TRANSPORT_PLAN_PENDING';
export const UPDATE_DAILY_TRANSPORT_PLAN_FULFILLED = 'UPDATE_DAILY_TRANSPORT_PLAN_FULFILLED';
export const UPDATE_DAILY_TRANSPORT_PLAN_REJECTED = 'UPDATE_DAILY_TRANSPORT_PLAN_REJECTED';
export const updateDailyTransportPlan = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_DAILY_TRANSPORT_PLAN,
    payload: api.put(`/DailyTransportPlanInfo/updateDailyTransportPlanInfo`, payload),
    meta: payload
  });
};
// const type of get deatail
export const FETCH_DAILY_TRANSPORT_PLAN_DETAIL = 'FETCH_DAILY_TRANSPORT_PLAN_DETAIL';
export const FETCH_DAILY_TRANSPORT_PLAN_DETAIL_PENDING = 'FETCH_DAILY_TRANSPORT_PLAN_DETAIL_PENDING';
export const FETCH_DAILY_TRANSPORT_PLAN_DETAIL_FULFILLED = 'FETCH_DAILY_TRANSPORT_PLAN_DETAIL_FULFILLED';
export const FETCH_DAILY_TRANSPORT_PLAN_DETAIL_REJECTED = 'FETCH_DAILY_TRANSPORT_PLAN_DETAIL_REJECTED';
export const getDailyTransportPlanDetail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_DAILY_TRANSPORT_PLAN_DETAIL,
    payload: api.get(`/DailyTransportPlanInfo/${id}`)
  });
};

// const type of delete
export const DELETE_DAILY_TRANSPORT_PLAN = 'DELETE_DAILY_TRANSPORT_PLAN';
export const DELETE_DAILY_TRANSPORT_PLAN_PENDING = 'DELETE_DAILY_TRANSPORT_PLAN_PENDING';
export const DELETE_DAILY_TRANSPORT_PLAN_FULFILLED = 'DELETE_DAILY_TRANSPORT_PLAN_FULFILLED';
export const DELETE_DAILY_TRANSPORT_PLAN_REJECTED = 'DELETE_DAILY_TRANSPORT_PLAN_REJECTED';
export const deleteDailyTransportPlan = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_DAILY_TRANSPORT_PLAN,
    payload: api.delete(`/DailyTransportPlanInfo/delete/${id}`)
  });
};

// const type of function update amount tripments
export const UPDATE_DAILY_TRANSPORT_PLAN_INFO = 'UPDATE_DAILY_TRANSPORT_PLAN_INFO';
export const UPDATE_DAILY_TRANSPORT_PLAN_INFO_PENDING = 'UPDATE_DAILY_TRANSPORT_PLAN_INFO_PENDING';
export const UPDATE_DAILY_TRANSPORT_PLAN_INFO_FULFILLED = 'UPDATE_DAILY_TRANSPORT_PLAN_INFO_FULFILLED';
export const UPDATE_DAILY_TRANSPORT_PLAN_INFO_REJECTED = 'UPDATE_DAILY_TRANSPORT_PLAN_INFO_REJECTED';
export const updateDailyTransportPlanInfo = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_DAILY_TRANSPORT_PLAN_INFO,
    payload: api.put(`/DailyTransportPlanInfo/updateDailyTransportPlanInfo`, payload),
    meta: payload
  });
};
