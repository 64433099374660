import { ANNUAL_FEE_ACTIONS } from 'actions/report/annualFees';
import { getErrorMessage } from 'commons/ReducerUtils';
import {
  MSG_ADD_FAILURE,
  MSG_ADD_SUCCESS,
  MSG_DELETE_FAILURE,
  MSG_DELETE_SUCCESS,
  MSG_EDIT_FAILURE,
  MSG_EDIT_SUCCESS,
  MSG_SEARCH_FAILURE,
  MSG_TYPE_ERROR,
  MSG_TYPE_SUCCESS
} from 'const/common';

const INIT_STATE = {
  listPerPage: [],
  isShowMessage: false,
  pagination: {},
  typeMessage: '',
  isCloseModal: false,
  loading: false,
  isSearch: false,
  alertMessage: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ANNUAL_FEE_ACTIONS.GET_ANNUAL_FEE_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case ANNUAL_FEE_ACTIONS.GET_ANNUAL_FEE_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false
      };
    }
    case ANNUAL_FEE_ACTIONS.GET_ANNUAL_FEE_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        isCloseModal: false,
        typeMessage: 'error',
        alertMessage:
          action.payload && action.payload.data && action.payload.data.message
            ? action.payload.data.message
            : 'Không thể lấy được dữ liệu !'
      };
    }

    case ANNUAL_FEE_ACTIONS.CREATE_ANNUAL_FEE_PENDING:
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    case ANNUAL_FEE_ACTIONS.CREATE_ANNUAL_FEE_FULFILLED:
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        isSearch: false,
        alertMessage: MSG_ADD_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case ANNUAL_FEE_ACTIONS.CREATE_ANNUAL_FEE_REJECTED: {
      let alertMessage = getErrorMessage(action, MSG_ADD_FAILURE);
      console.log('🚀 ~ file: annualFees.js ~ line 76 ~ alertMessage', alertMessage);
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }

    case ANNUAL_FEE_ACTIONS.UPDATE_ANNUAL_FEE_PENDING:
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    case ANNUAL_FEE_ACTIONS.UPDATE_ANNUAL_FEE_FULFILLED:
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        isSearch: false,
        alertMessage: MSG_EDIT_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case ANNUAL_FEE_ACTIONS.UPDATE_ANNUAL_FEE_REJECTED: {
      let alertMessage = getErrorMessage(action, MSG_EDIT_FAILURE);
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }

    case ANNUAL_FEE_ACTIONS.DELETE_ANNUAL_FEE_PENDING:
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    case ANNUAL_FEE_ACTIONS.DELETE_ANNUAL_FEE_FULFILLED:
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        isSearch: false,
        alertMessage: MSG_DELETE_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case ANNUAL_FEE_ACTIONS.DELETE_ANNUAL_FEE_REJECTED: {
      let alertMessage = getErrorMessage(action, MSG_DELETE_FAILURE);
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }

    case ANNUAL_FEE_ACTIONS.SEARCH_ANNUAL_FEE_PENDING:
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    case ANNUAL_FEE_ACTIONS.SEARCH_ANNUAL_FEE_FULFILLED:
      return {
        ...state,
        listPerPage: action.payload.data.data,
        loading: false,
        isSearch: true,
        pagination: action.payload.data.pagination
      };
    case ANNUAL_FEE_ACTIONS.SEARCH_ANNUAL_FEE_REJECTED: {
      let alertMessage = getErrorMessage(action, MSG_SEARCH_FAILURE);
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }

    case ANNUAL_FEE_ACTIONS.GET_ALL_PROVIDER_PENDING:
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    case ANNUAL_FEE_ACTIONS.GET_ALL_PROVIDER_FULFILLED:
      return {
        ...state,
        listProvider: action.payload.data.data,
        loading: false,
        isSearch: false
      };
    case ANNUAL_FEE_ACTIONS.GET_ALL_PROVIDER_REJECTED: {
      let alertMessage = getErrorMessage(action, MSG_SEARCH_FAILURE);
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }

    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }

    default:
      return state;
  }
};
