import httpClient from "HttpClient";
// import { ACCESS_TOKEN } from '../const/common';

export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_PENDING = "SIGNIN_USER_PENDING";
export const SIGNIN_USER_FULFILLED = "SIGNIN_USER_FULFILLED";
export const SIGNIN_USER_REJECTED = "SIGNIN_USER_REJECTED";

export const signinUser = (user) => dispatch => {
    return dispatch({
        type: SIGNIN_USER,
        payload: httpClient.post(`/authentication/login`, user),
        // payload: user,
        meta: { user }
    });
};

export const SIGNIN_DRIVER = "SIGNIN_DRIVER";
export const SIGNIN_DRIVER_PENDING = "SIGNIN_DRIVER_PENDING";
export const SIGNIN_DRIVER_FULFILLED = "SIGNIN_DRIVER_FULFILLED";
export const SIGNIN_DRIVER_REJECTED = "SIGNIN_DRIVER_REJECTED";

export const signinDriver = (user) => dispatch => {
    return dispatch({
        type: SIGNIN_DRIVER,
        payload: httpClient.post(`/authentication/loginApp`, user),
        // payload: user,
        meta: { user }
    });
};

export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_PENDING = "SIGNOUT_USER_PENDING";
export const SIGNOUT_USER_FULFILLED = "SIGNOUT_USER_FULFILLED";
export const SIGNOUT_USER_REJECTED = "SIGNOUT_USER_REJECTED";
export const signoutUser = () => dispatch => {
    // const accessToken = localStorage.getItem(ACCESS_TOKEN);
    localStorage.clear();
    const url = "/authentication/logout";
    return dispatch({
        type: SIGNOUT_USER,
        payload: httpClient.post(`${url}`)
    });
}

export const CHANGE_TIME_OUT = 'CHANGE_TIME_OUT';

export const changeTimeOut = () => dispatch => {
    return dispatch({
        type: CHANGE_TIME_OUT
    });
}

export const RESET_TIME_OUT = 'RESET_TIME_OUT';

export const resetTimeOut = () => dispatch => {
    return dispatch({
        type: RESET_TIME_OUT
    });
}