import { SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS } from 'actions/accounting/settlementFollowCustomer';
import { INTERRUPT_API } from 'const/common';

const INIT_STATE = {
  listPerPage: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  pagination: {},
  isSearch: false,
  summary: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.GET_SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.GET_SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.GET_SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }

    case SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        isShowMessage: false,
        loading: true
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.SEARCH_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        summary: action.payload.data.summary
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.SEARCH_DATA_REJECTED: {
      // eslint-disable-next-line no-case-declarations
      const loading = action?.payload?.message === INTERRUPT_API ? true : false;

      return {
        ...state,
        loading: loading
        // isShowMessage: true,
        // typeMessage: 'error',
        // message: 'Không thể lấy được dữ liệu !'
      };
    }

    case SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.UPDATE_DATA_SETTLEMENT_CUSTOMER_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.UPDATE_DATA_SETTLEMENT_CUSTOMER_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công',
        isShowMessage: true,
        isCloseModal: true
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.UPDATE_DATA_SETTLEMENT_CUSTOMER_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Cập nhật dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false
      };
    }

    case SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.UPDATE_STATUS_SETTLEMENT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.UPDATE_STATUS_SETTLEMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công',
        isShowMessage: true,
        isCloseModal: true
      };
    }
    case SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.UPDATE_STATUS_SETTLEMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Cập nhật dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false
      };
    }

    default:
      return state;
  }
};
