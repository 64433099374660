import httpClient from 'HttpClient';
// import { checkValueSelect } from "../commons/ValidateType";
// define const actions
export const DOWNPAYMENT_ORDER_LIST_ACTIONS = {
  GET_LIST_DOWNPAYMENT_ORDER: 'GET_LIST_DOWNPAYMENT_ORDER',
  GET_LIST_DOWNPAYMENT_ORDER_PENDING: 'GET_LIST_DOWNPAYMENT_ORDER_PENDING',
  GET_LIST_DOWNPAYMENT_ORDER_FULFILLED: 'GET_LIST_DOWNPAYMENT_ORDER_FULFILLED',
  GET_LIST_DOWNPAYMENT_ORDER_REJECTED: 'GET_LIST_DOWNPAYMENT_ORDER_REJECTED',

  CREATE_TEMPLATE_ITEM: 'CREATE_TEMPLATE_ITEM',
  CREATE_TEMPLATE_ITEM_PENDING: 'CREATE_TEMPLATE_ITEM_PENDING',
  CREATE_TEMPLATE_ITEM_FULFILLED: 'CREATE_TEMPLATE_ITEM_FULFILLED',
  CREATE_TEMPLATE_ITEM_REJECTED: 'CREATE_TEMPLATE_ITEM_REJECTED',

  DELETE_DATA: 'DELETE_DATA',
  DELETE_DATA_PENDING: 'DELETE_DATA_PENDING',
  DELETE_DATA_FULFILLED: 'DELETE_DATA_FULFILLED',
  DELETE_DATA_REJECTED: 'DELETE_DATA_REJECTED',

  GET_ALL_INSTALMENT_CATEGORY: 'GET_ALL_INSTALMENT_CATEGORY',
  GET_ALL_INSTALMENT_CATEGORY_PENDING: 'GET_ALL_INSTALMENT_CATEGORY_PENDING',
  GET_ALL_INSTALMENT_CATEGORY_FULFILLED: 'GET_ALL_INSTALMENT_CATEGORY_FULFILLED',
  GET_ALL_INSTALMENT_CATEGORY_REJECTED: 'GET_ALL_INSTALMENT_CATEGORY_REJECTED',

  CREATE_INSTALMENT_BY_CATEGORY: 'CREATE_INSTALMENT_BY_CATEGORY',
  CREATE_INSTALMENT_BY_CATEGORY_PENDING: 'CREATE_INSTALMENT_BY_CATEGORY_PENDING',
  CREATE_INSTALMENT_BY_CATEGORY_FULFILLED: 'CREATE_INSTALMENT_BY_CATEGORY_FULFILLED',
  CREATE_INSTALMENT_BY_CATEGORY_REJECTED: 'CREATE_INSTALMENT_BY_CATEGORY_REJECTED',

  GET_INSTALMENT_FEES: 'GET_INSTALMENT_FEES',
  GET_INSTALMENT_FEES_PENDING: 'GET_INSTALMENT_FEES_PENDING',
  GET_INSTALMENT_FEES_FULFILLED: 'GET_INSTALMENT_FEES_FULFILLED',
  GET_INSTALMENT_FEES_REJECTED: 'GET_INSTALMENT_FEES_REJECTED',

  GET_INSTALMENT_BY_ID: 'GET_INSTALMENT_BY_ID',
  GET_INSTALMENT_BY_ID_PENDING: 'GET_INSTALMENT_BY_ID_PENDING',
  GET_INSTALMENT_BY_ID_FULFILLED: 'GET_INSTALMENT_BY_ID_FULFILLED',
  GET_INSTALMENT_BY_ID_REJECTED: 'GET_INSTALMENT_BY_ID_REJECTED'
};

// define actions
export const getListPaymentOrder = (consigmentId) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_ORDER_LIST_ACTIONS.GET_LIST_DOWNPAYMENT_ORDER,
    payload: httpClient.get(`/Instalments/getAll/${consigmentId}`)
  });
};

export const createItemListPayment = (consigmentid) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_ORDER_LIST_ACTIONS.CREATE_TEMPLATE_ITEM,
    payload: httpClient.post(`/Instalments/create/${consigmentid}`)
  });
};

export const deleteItemListPayment = (id) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_ORDER_LIST_ACTIONS.DELETE_DATA,
    payload: httpClient.delete(`/Instalments/delete/${id}`)
  });
};

export const getAllInstalmentsCategoryInfo = () => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_ORDER_LIST_ACTIONS.GET_ALL_INSTALMENT_CATEGORY,
    payload: httpClient.get(`/InstalmentsCategoryInfo/getAll`)
  });
};

export const createInstalmentByCategory = (consignmentId, instalmentsCategoryId) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_ORDER_LIST_ACTIONS.CREATE_INSTALMENT_BY_CATEGORY,
    payload: httpClient.post(`/Instalments/create/${consignmentId}/instalmentscategory/${instalmentsCategoryId}`)
  });
};

export const getInstalmentFees = (consignmentId, instalmentsCategoryId) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_ORDER_LIST_ACTIONS.GET_INSTALMENT_FEES,
    payload: httpClient.get(`/advanceonconsignment/Create/${consignmentId}/instalments/${instalmentsCategoryId}`)
  });
};

export const getInstalmentById = (instalmentId) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_ORDER_LIST_ACTIONS.GET_INSTALMENT_BY_ID,
    payload: httpClient.get(`/Instalments/getById/${instalmentId}`)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};
