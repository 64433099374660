import httpClient from "HttpClient";

// define const actions
export const SAFE_LOAD_OF_SEMI_TRAILER_ACTIONS = {
    GET_DATA_SAFE_LOAD_OF_SEMI_TRAILER: "GET_DATA_SAFE_LOAD_OF_SEMI_TRAILER",
    GET_DATA_SAFE_LOAD_OF_SEMI_TRAILER_PENDING: "GET_DATA_SAFE_LOAD_OF_SEMI_TRAILER_PENDING",
    GET_DATA_SAFE_LOAD_OF_SEMI_TRAILER_FULFILLED: "GET_DATA_SAFE_LOAD_OF_SEMI_TRAILER_FULFILLED",
    GET_DATA_SAFE_LOAD_OF_SEMI_TRAILER_REJECTED: "GET_DATA_SAFE_LOAD_OF_SEMI_TRAILER_REJECTED",

};

// define actions
export const actionGetSafeLoadOfSemiTrailer = () => (dispatch) => {
    return dispatch({
        type: SAFE_LOAD_OF_SEMI_TRAILER_ACTIONS.GET_DATA_SAFE_LOAD_OF_SEMI_TRAILER,
        payload: httpClient.get(`/TruckLoadSafe/report`),
    });
};

export const updateShowAlert = () => {
    return {
        type: 'UPDATE_SHOW_ALERT'
    };
};