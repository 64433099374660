import { CUSTOMER_TYPE } from 'const/common';
import httpClient from 'HttpClient';
import { deleteItem, getEmployeesByTransportComanyID, getEmployeesByTransportComanyIDV2 } from 'services/employees';
// define const actions
export const EMPLOYEES_ACTIONS = {
  GET_EMPLOYEES_BY_TRANSPORT_COMPANY_ID: 'GET_EMPLOYEES_BY_TRANSPORT_COMPANY_ID',
  GET_EMPLOYEES_BY_TRANSPORT_COMPANY_ID_PENDING: 'GET_EMPLOYEES_BY_TRANSPORT_COMPANY_ID_PENDING',
  GET_EMPLOYEES_BY_TRANSPORT_COMPANY_ID_FULFILLED: 'GET_EMPLOYEES_BY_TRANSPORT_COMPANY_ID_FULFILLED',
  GET_EMPLOYEES_BY_TRANSPORT_COMPANY_ID_REJECTED: 'GET_EMPLOYEES_BY_TRANSPORT_COMPANY_ID_REJECTED',

  GET_EMPLOYEES_PER_PAGE: 'GET_EMPLOYEES_PER_PAGE',
  GET_EMPLOYEES_PER_PAGE_PENDING: 'GET_EMPLOYEES_PER_PAGE_PENDING',
  GET_EMPLOYEES_PER_PAGE_FULFILLED: 'GET_EMPLOYEES_PER_PAGE_FULFILLED',
  GET_EMPLOYEES_PER_PAGE_REJECTED: 'GET_EMPLOYEES_PER_PAGE_REJECTED',

  GET_EMPLOYEES_BY_ID: 'GET_EMPLOYEES_BY_ID',
  GET_EMPLOYEES_BY_ID_PENDING: 'GET_EMPLOYEES_BY_ID_PENDING',
  GET_EMPLOYEES_BY_ID_FULFILLED: 'GET_EMPLOYEES_BY_ID_FULFILLED',
  GET_EMPLOYEES_BY_ID_REJECTED: 'GET_EMPLOYEES_BY_ID_REJECTED',

  CREATE_EMPLOYEES: 'CREATE_EMPLOYEES',
  CREATE_EMPLOYEES_PENDING: 'CREATE_EMPLOYEES_PENDING',
  CREATE_EMPLOYEES_FULFILLED: 'CREATE_EMPLOYEES_FULFILLED',
  CREATE_EMPLOYEES_REJECTED: 'CREATE_EMPLOYEES_REJECTED',

  UPDATE_EMPLOYEES: 'UPDATE_EMPLOYEES',
  UPDATE_EMPLOYEES_PENDING: 'UPDATE_EMPLOYEES_PENDING',
  UPDATE_EMPLOYEES_FULFILLED: 'UPDATE_EMPLOYEES_FULFILLED',
  UPDATE_EMPLOYEES_REJECTED: 'UPDATE_EMPLOYEES_REJECTED',

  DELETE_EMPLOYEES: 'DELETE_EMPLOYEES',
  DELETE_EMPLOYEES_PENDING: 'DELETE_EMPLOYEES_PENDING',
  DELETE_EMPLOYEES_FULFILLED: 'DELETE_EMPLOYEES_FULFILLED',
  DELETE_EMPLOYEES_REJECTED: 'DELETE_EMPLOYEES_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  GET_ALL_TRANSPORT_COMPANY: 'GET_ALL_TRANSPORT_COMPANY',
  GET_ALL_TRANSPORT_COMPANY_FULFILLED: 'GET_ALL_TRANSPORT_COMPANY_FULFILLED',

  GET_ALL_JOB_POSITION: 'GET_ALL_JOB_POSITION',
  GET_ALL_JOB_POSITION_FULFILLED: 'GET_ALL_JOB_POSITION_FULFILLED',

  GET_ALL_EMPLOYEES: 'GET_ALL_EMPLOYEES',
  GET_ALL_EMPLOYEES_FULFILLED: 'GET_ALL_EMPLOYEES_FULFILLED',

  GET_CITY: 'GET_CITY',
  GET_CITY_PENDING: 'GET_CITY_PENDING',
  GET_CITY_FULFILLED: 'GET_CITY_FULFILLED',
  GET_CITY_REJECTED: 'GET_CITY_REJECTED',

  GET_DISTRICT: 'GET_DISTRICT',
  GET_DISTRICT_PENDING: 'GET_DISTRICT_PENDING',
  GET_DISTRICT_FULFILLED: 'GET_DISTRICT_FULFILLED',
  GET_DISTRICT_REJECTED: 'GET_DISTRICT_REJECTED',

  GET_SUBDISTRICT: 'GET_SUBDISTRICT',
  GET_SUBDISTRICT_PENDING: 'GET_SUBDISTRICT_PENDING',
  GET_SUBDISTRICT_FULFILLED: 'GET_SUBDISTRICT_FULFILLED',
  GET_SUBDISTRICT_REJECTED: 'GET_SUBDISTRICT_REJECTED',

  GET_ALL_FUEL_MANAGER: 'GET_ALL_FUEL_MANAGER',
  GET_ALL_FUEL_MANAGER_PENDING: 'GET_ALL_FUEL_MANAGER_PENDING',
  GET_ALL_FUEL_MANAGER_FULFILLED: 'GET_ALL_FUEL_MANAGER_FULFILLED',
  GET_ALL_FUEL_MANAGER_REJECTED: 'GET_ALL_FUEL_MANAGER_REJECTED',

  GET_EMPLOYEES_BY_POSITION: 'GET_EMPLOYEES_BY_POSITION',
  GET_EMPLOYEES_BY_POSITION_PENDING: 'GET_EMPLOYEES_BY_POSITION_PENDING',
  GET_EMPLOYEES_BY_POSITION_FULFILLED: 'GET_EMPLOYEES_BY_POSITION_FULFILLED',
  GET_EMPLOYEES_BY_POSITION_REJECTED: 'GET_EMPLOYEES_BY_POSITION_REJECTED',

  RESET_DISTRICT: 'RESET_DISTRICT',
  RESET_SUBDISTRICT: 'RESET_SUBDISTRICT',

  SEARCH_DATA_V2: 'SEARCH_DATA_V2',
  SEARCH_DATA_V2_PENDING: 'SEARCH_DATA_V2_PENDING',
  SEARCH_DATA_V2_FULFILLED: 'SEARCH_DATA_V2_FULFILLED',
  SEARCH_DATA_V2_REJECTED: 'SEARCH_DATA_V2_REJECTED',

  GET_USER_BY_DEPARTMENT: 'GET_USER_BY_DEPARTMENT',
  GET_USER_BY_DEPARTMENT_PENDING: 'GET_USER_BY_DEPARTMENT_PENDING',
  GET_USER_BY_DEPARTMENT_FULFILLED: 'GET_USER_BY_DEPARTMENT_FULFILLED',
  GET_USER_BY_DEPARTMENT_REJECTED: 'GET_USER_BY_DEPARTMENT_REJECTED',

  GET_ALL_BANK: 'GET_ALL_BANK',
  GET_ALL_BANK_PENDING: 'GET_ALL_BANK_PENDING',
  GET_ALL_BANK_FULFILLED: 'GET_ALL_BANK_FULFILLED',
  GET_ALL_BANK_REJECTED: 'GET_USER_BY_DGET_ALL_BANK_REJECTEDEPARTMENT_REJECTED'
};

// define actions
export const actionGetEmployeesByTransportCompanyID = (id) => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.GET_EMPLOYEES_BY_TRANSPORT_COMPANY_ID,
    payload: getEmployeesByTransportComanyID(id)
  });
};
export const actionGetEmployeesByTransportCompanyIDV2 = (id) => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.GET_EMPLOYEES_BY_TRANSPORT_COMPANY_ID,
    payload: getEmployeesByTransportComanyIDV2(id)
  });
};
export const actionGetEmployeesPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.GET_EMPLOYEES_PER_PAGE,
    payload: httpClient.get(`/employeeinfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`)
  });
};

export const actionGetEmployeesByID = (id) => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.GET_EMPLOYEES_BY_ID,
    payload: httpClient.get(`/employeeinfo/getById/${id}`)
  });
};

export const actionCreateEmployees = (payload) => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.CREATE_EMPLOYEES,
    payload: httpClient.post(`/employeeinfo/create`, payload)
  });
};

export const actionUpdateEmployees = (payload) => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.UPDATE_EMPLOYEES,
    meta: { Id: payload.Id },
    payload: httpClient.put(`/employeeinfo/update`, payload)
  });
};

export const actionDeleteEmployees = (id) => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.DELETE_EMPLOYEES,
    meta: { Id: id },
    payload: deleteItem(id)
  });
};

export const getJobPosition = () => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.GET_ALL_JOB_POSITION,
    payload: httpClient.get(`/jobpositioninfo/getAll`)
  });
};

export const getTransportCompany =
  (type = CUSTOMER_TYPE.TRANSPORT_COMPANY) =>
  (dispatch) => {
    return dispatch({
      type: EMPLOYEES_ACTIONS.GET_ALL_TRANSPORT_COMPANY,
      payload: httpClient.get(`/customerinfo/getByCustomerType/${type}`)
    });
  };

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const actionSearchEmployees = (value, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/employeeinfo/search?value=${value}&Page=${pageNo}&PageSize=${pageSize}`)
  });
};

export const getAll = () => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.GET_ALL_EMPLOYEES,
    payload: httpClient.get(`/employeeinfo/getAll`)
  });
};

export const getCity = () => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.GET_CITY,
    payload: httpClient.get(`/address/city`)
  });
};

export const getDistrict = (id) => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.GET_DISTRICT,
    payload: httpClient.get(`/address/getDistrictByCityId/${id}`)
  });
};

export const getSubDistrictByDistrict = (id) => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.GET_SUBDISTRICT,
    payload: httpClient.get(`/address/getSubDistrictByDistrict/${id}`)
  });
};

export const getAllFuelManager = () => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.GET_ALL_FUEL_MANAGER,
    payload: httpClient.get(`/employeeinfo/getAll/FuelManager`)
  });
};

export const resetDistrict = () => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.RESET_DISTRICT
  });
};

export const resetSubDistrict = () => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.RESET_SUBDISTRICT
  });
};

export const getEmployeesByPosition = (positionId) => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.GET_EMPLOYEES_BY_POSITION,
    payload: httpClient.get(`/employeeinfo/getEmployeeGroupByJobPosition?jobPostionIds=${positionId}`),
    meta: positionId
  });
};
export const getEmployeesAll = (positionId) => (dispatch) => {
  return dispatch({
    // type: EMPLOYEES_ACTIONS.GET_EMPLOYEES_BY_POSITION,
    payload: httpClient.get(`/employeeinfo/getAll`),
    meta: positionId
  });
};
export const actionSearchEmployeesV2 = (params, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.SEARCH_DATA_V2,
    payload: httpClient.get(`/employeeinfo/search?Page=${pageNo}&PageSize=${pageSize}`, { params })
  });
};
export const actionSearchEmployeesV2All = (params) => (dispatch) => {
  return dispatch({
    payload: httpClient.get('/employeeinfo/search', { params })
  });
};

export const getEmployeeByDepartment = (payload) => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.GET_USER_BY_DEPARTMENT,
    payload: httpClient.get(`/EmployeeInfo/EmployeeByDepartment/${payload}`)
  });
};

export const getBanks = () => (dispatch) => {
  return dispatch({
    type: EMPLOYEES_ACTIONS.GET_ALL_BANK,
    payload: httpClient.get(`/EmployeeInfo/getAll/Bank`)
  });
};
