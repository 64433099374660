import { VEHICLE_ACTIONS } from 'actions/vehicleInfoManagementActions';
import { getErrorMessage } from 'commons/ReducerUtils';

const INIT_STATE = {
  allData: {
    data: []
  },
  brands: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // get vehicle by department
    case VEHICLE_ACTIONS.FILTERS_CAR_BY_DEPARTMENT_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case VEHICLE_ACTIONS.FILTERS_CAR_BY_DEPARTMENT_FULFILLED: {
      let vehiclesByDepartment = {
        data: action.payload.data.data
      };
      return {
        ...state,
        vehiclesByDepartment: vehiclesByDepartment,
        isLoading: false
      };
    }

    case VEHICLE_ACTIONS.FILTERS_CAR_BY_DEPARTMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi',
        typeMessage: 'error'
      };

    // create vehicle driver
    case VEHICLE_ACTIONS.CREATE_VEHICLE_DRIVER_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case VEHICLE_ACTIONS.CREATE_VEHICLE_DRIVER_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Tạo dữ liệu thành công'
      };

    case VEHICLE_ACTIONS.CREATE_VEHICLE_DRIVER_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: getErrorMessage(action, 'Tạo dữ liệu thất bại')
      };

    // get vehicle by filters
    case VEHICLE_ACTIONS.GET_VEHICLES_BY_FILTERS_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case VEHICLE_ACTIONS.GET_VEHICLES_BY_FILTERS_FULFILLED: {
      let vehiclesByFilters = {
        data: action.payload.data.data
      };
      return {
        ...state,
        vehiclesByFilters: vehiclesByFilters,
        isLoading: false
      };
    }

    case VEHICLE_ACTIONS.GET_VEHICLES_BY_FILTERS_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: getErrorMessage(action, 'Đã xảy ra lỗi'),
        typeMessage: 'error'
      };

    //get all
    case VEHICLE_ACTIONS.GET_ALL_VEHICLES_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case VEHICLE_ACTIONS.GET_ALL_VEHICLES_FULFILLED: {
      let allData = {
        data: action.payload.data.data
      };
      return {
        ...state,
        allData: allData,
        isLoading: false,
        isMsg: false,
      };
    }

    case VEHICLE_ACTIONS.GET_ALL_VEHICLES_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    //get all
    case VEHICLE_ACTIONS.GET_INTERNAL_VEHICLES_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case VEHICLE_ACTIONS.GET_INTERNAL_VEHICLES_FULFILLED: {
      let allData = {
        data: action.payload.data.data
      };
      return {
        ...state,
        allData: allData,
        isLoading: false
      };
    }

    case VEHICLE_ACTIONS.GET_INTERNAL_VEHICLES_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };
    // get per page
    case VEHICLE_ACTIONS.GET_VEHICLES_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case VEHICLE_ACTIONS.GET_VEHICLES_PER_PAGE_FULFILLED: {
      let vehiclesData = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isFillter: false
      };
      return {
        ...state,
        vehiclesData: vehiclesData,
        isLoading: false
      };
    }

    case VEHICLE_ACTIONS.GET_VEHICLES_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: getErrorMessage(action, 'Đã xảy ra lỗi'),
        typeMessage: 'error'
      };

    // get  by id
    case VEHICLE_ACTIONS.GET_VEHICLES_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case VEHICLE_ACTIONS.GET_VEHICLES_BY_ID_FULFILLED:
      return {
        ...state,
        isLoading: false
      };

    case VEHICLE_ACTIONS.GET_VEHICLES_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: getErrorMessage(action, 'Đã xảy ra lỗi'),
        typeMessage: 'error'
      };

    // create vehicle
    case VEHICLE_ACTIONS.CREATE_VEHICLES_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case VEHICLE_ACTIONS.CREATE_VEHICLES_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Tạo dữ liệu thành công'
      };

    case VEHICLE_ACTIONS.CREATE_VEHICLES_REJECTED:
      return {
        ...state,
        isLoading: true,
        typeMessage: 'error',
        isMsg: true,
        msg: getErrorMessage(action, 'Đã xảy ra lỗi')
      };

    // update
    case VEHICLE_ACTIONS.UPDATE_VEHICLES_PENDING:
      return {
        ...state,
        isMsg: false,
        isLoading: true
      };

    case VEHICLE_ACTIONS.UPDATE_VEHICLES_FULFILLED:
      //   console.log("UPDATE_VEHICLES_FULFILLED", action);

      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Sửa dữ liệu thành công',
        typeMessage: 'success'
      };

    case VEHICLE_ACTIONS.UPDATE_VEHICLES_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Sửa dữ liệu thất bại',
        typeMessage: 'error'
      };

    // delete
    case VEHICLE_ACTIONS.DELETE_VEHICLES_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case VEHICLE_ACTIONS.DELETE_VEHICLES_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Xóa dữ liệu thành công',
        typeMessage: 'success'
      };

    case VEHICLE_ACTIONS.DELETE_VEHICLES_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi',
        typeMessage: 'error'
      };

    //Search
    case VEHICLE_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };
    }
    case VEHICLE_ACTIONS.SEARCH_DATA_FULFILLED: {
      let vehiclesData = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        isLoading: false,
        vehiclesData: vehiclesData,
        pagination: action.payload.data.pagination,
        isSearch: true
      };
    }
    case VEHICLE_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        isLoading: false,
        isShowMessage: true,
        typeMessage: 'error',
        msg: 'Không thể lấy được dữ liệu !'
      };
    }

    //filter car by status
    case VEHICLE_ACTIONS.FILTER_CAR_BY_STATUS_PENDING: {
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };
    }
    case VEHICLE_ACTIONS.FILTER_CAR_BY_STATUS_FULFILLED: {
      let vehiclesData = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false,
        isFillter: true
      };
      return {
        ...state,
        isLoading: false,
        vehiclesData: vehiclesData,
        pagination: action.payload.data.pagination,
        isSearch: true
      };
    }
    case VEHICLE_ACTIONS.FILTER_CAR_BY_STATUS_REJECTED: {
      return {
        ...state,
        isLoading: false,
        isShowMessage: true,
        typeMessage: 'error',
        msg: 'Không thể lấy được dữ liệu !'
      };
    }

    //get all
    case VEHICLE_ACTIONS.GET_VEHICLES_BY_TRANSPORT_COMPANY_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case VEHICLE_ACTIONS.GET_VEHICLES_BY_TRANSPORT_COMPANY_ID_FULFILLED: {
      let allData = {
        data: action.payload.data.data
      };
      return {
        ...state,
        allData: allData,
        isLoading: false
      };
    }

    case VEHICLE_ACTIONS.GET_VEHICLES_BY_TRANSPORT_COMPANY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    //get all brand
    case VEHICLE_ACTIONS.GET_ALL_BRANDS_VEHICLE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case VEHICLE_ACTIONS.GET_ALL_BRANDS_VEHICLE_FULFILLED: {
      let brands = action.payload.data.data;
      return {
        ...state,
        brands: brands,
        isLoading: false
      };
    }

    case VEHICLE_ACTIONS.GET_ALL_BRANDS_VEHICLE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    //get by company
    case VEHICLE_ACTIONS.GET_VEHICLES_BY_COMPANY_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case VEHICLE_ACTIONS.GET_VEHICLES_BY_COMPANY_FULFILLED: {
      let allData = {
        data: action.payload.data.data
      };
      return {
        ...state,
        allData: allData,
        isLoading: false
      };
    }

    case VEHICLE_ACTIONS.GET_VEHICLES_BY_COMPANY_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    default:
      return state;
  }
};
