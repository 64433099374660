import { STANDARD_OIL_MANAGEMENT_ACTIONS } from 'actions/standardOilManagement';
import { findTruckingPlanMerge } from 'Pages/DriverPlan/utils';

const INIT_STATE = {
  listPerPage: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  pagination: {},
  isCloseModal: false,
  isSearch: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STANDARD_OIL_MANAGEMENT_ACTIONS.GET_STANDARD_OIL_MANAGEMENT_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case STANDARD_OIL_MANAGEMENT_ACTIONS.GET_STANDARD_OIL_MANAGEMENT_PER_PAGE_FULFILLED: {
      const data = action.payload.data.data;
      const pagination = action.payload.data.pagination
      const listPerPage = data.map((item) => {
        const truckingPlanMerge = findTruckingPlanMerge(item?.Id, data);
        return { ...item, truckingPlanMerge };
      });
      const listResult = pagination.pageNo === 1 ? listPerPage : state.listPerPage.concat(listPerPage);
      return {
        ...state,
        loading: false,
        listPerPage: listResult,
        pagination: action.payload.data.pagination,
        isSearch: false
      };
    }
    case STANDARD_OIL_MANAGEMENT_ACTIONS.GET_STANDARD_OIL_MANAGEMENT_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case STANDARD_OIL_MANAGEMENT_ACTIONS.UPDATE_STANDARD_OIL_MANAGEMENT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case STANDARD_OIL_MANAGEMENT_ACTIONS.UPDATE_STANDARD_OIL_MANAGEMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công !'
      };
    }
    case STANDARD_OIL_MANAGEMENT_ACTIONS.UPDATE_STANDARD_OIL_MANAGEMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể cập nhật dữ liệu !'
      };
    }

    case STANDARD_OIL_MANAGEMENT_ACTIONS.UPDATE_STANDARD_OIL_MANAGEMENT_V2_PENDING: {
      return {
        ...state,
        isShowMessage: false
      };
    }
    case STANDARD_OIL_MANAGEMENT_ACTIONS.UPDATE_STANDARD_OIL_MANAGEMENT_V2_FULFILLED: {
      return {
        ...state,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công !'
      };
    }
    case STANDARD_OIL_MANAGEMENT_ACTIONS.UPDATE_STANDARD_OIL_MANAGEMENT_V2_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể cập nhật dữ liệu !'
      };
    }

    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }

    default:
      return state;
  }
};
