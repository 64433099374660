import api from 'HttpClient/index';

export const getTruckingFeePerPage = (pageNo, pageSize) => {
  return api.get(`/TruckingFee/report/paging/page?Page=${pageNo}&PageSize=${pageSize}`);
};

export const getTruckingFeeByID = (id) => {
  return api.get(`/TruckingFee/getById/${id}`);
};

export const updateTruckingFee = (payload) => {
  return api.put(`/TruckingFee/update`, payload);
};
export const updateTruckingFeeTruckingFee = (payload) => {
  return api.put(`/TruckingFee/update/truckingfee`, payload);
};
export const updateTruckingFeeTravelFee = (payload) => {
  return api.put(`/TruckingFee/update/travelfee`, payload);
};

export const updateAddingTruckingFee = (payload) => {
  return api.put(`/AddingTruckingFee/update`, payload);
};
export const updateAddingTruckingFeeTruckingFee = (payload) => {
  return api.put(`/AddingTruckingFee/update/truckingfee`, payload);
};
export const updateAddingTruckingFeeTravelFee = (payload) => {
  return api.put(`/AddingTruckingFee/update/travelfee`, payload);
};
