import React, { memo, useCallback, useEffect, useState } from 'react';
import { InputAdapter, TextMask } from 'react-text-mask-hoc';
import { Input } from 'reactstrap';
import { debounce } from 'commons/utils';

function RenderField({
  input,
  label,
  type,
  meta,
  style,
  className,
  disabled,
  mask,
  showOnUnTouch,
  placeholder,
  ...props
}) {
  const { value, ...i } = input;
  const [text, setText] = useState('');
  useEffect(() => {
    setText(value);
  }, [value]);
  const warning = meta.warning || props.warning;
  const changedValue = useCallback(
    debounce((value) => {
      input.onChange(value);
    }, 300),
    []
  );
  const res = { label, type, meta, style, className, disabled, mask, showOnUnTouch, placeholder };
  return (
    <>
      {mask ? (
        <TextMask {...input} {...props} {...res} Component={InputAdapter} className="form-control" />
      ) : (
        <Input
          {...i}
          {...props}
          size="large"
          // defaultValue={value}
          value={text}
          placeholder={label || placeholder}
          onChange={(e) => {
            e.persist();
            setText(e.target.value);
            changedValue(e);
          }}
          type={type}
          style={style}
          className={className}
          disabled={disabled}
        />
      )}
      {(showOnUnTouch || meta?.touched) &&
        ((meta?.error && (
          <p invalid="true" style={{ color: 'red', fontSize: 14 }}>
            {typeof meta?.error === 'object' ? meta?.error?.message : meta?.error}
          </p>
        )) ||
          (warning && <p className="text-warning">{warning}</p>))}{' '}
    </>
  );
}
const renderField = RenderField;
export default memo(renderField);
