import httpClient from 'HttpClient';

export const BACKUP = {
  BACKUP_DATA: 'BACKUP_DATA',
  BACKUP_DATA_PENDING: 'BACKUP_DATA_PENDING',
  BACKUP_DATA_FULFILLED: 'BACKUP_DATA_FULFILLED',
  BACKUP_DATA_REJECTED: 'BACKUP_DATA_REJECTED'
};
export const RESTORE = {
  RESTORE_DATA: 'RESTORE_DATA',
  RESTORE_DATA_PENDING: 'RESTORE_DATA_PENDING',
  RESTORE_DATA_FULFILLED: 'RESTORE_DATA_FULFILLED',
  RESTORE_DATA_REJECTED: 'RESTORE_DATA_REJECTED'
};
export const REST_DATA = 'REST_DATA';
export const getDataBackup = () => (dispatch) => {
  return dispatch({
    type: BACKUP.BACKUP_DATA,
    payload: httpClient.get(`BackupAndRestore/Backup`, {
      responseType: 'blob' // important,
    })
  });
};

export const restoreData = (params) => (dispatch) => {
  return dispatch({
    type: RESTORE.RESTORE_DATA,
    payload: httpClient.post(`BackupAndRestore/restore`, params)
  });
};
export const restState = () => (dispatch) => {
  return dispatch({
    type: REST_DATA
  });
};
