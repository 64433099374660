import api from 'HttpClient';

export const SET_OPEN_WORK_GROUP_MODAL = 'SET_OPEN_WORK_GROUP_MODAL';
export const setOpenWorkGroupModal = (data) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_WORK_GROUP_MODAL,
    payload: data
  });
};

export const SET_CLOSE_WORK_GROUP_MODAL = 'SET_CLOSE_WORK_GROUP_MODAL';
export const setCloseWorkGroupModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_WORK_GROUP_MODAL
  });
};

export const FETCH_ALL_WORK_GROUPS = 'FETCH_ALL_WORK_GROUPS';
export const FETCH_ALL_WORK_GROUPS_PENDING = 'FETCH_ALL_WORK_GROUPS_PENDING';
export const FETCH_ALL_WORK_GROUPS_FULFILLED = 'FETCH_ALL_WORK_GROUPS_FULFILLED';
export const FETCH_ALL_WORK_GROUPS_REJECTED = 'FETCH_ALL_WORK_GROUPS_REJECTED';
export const fetchAllWorkGroups = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_WORK_GROUPS,
    payload: api.get(`/WorkGroupInfo/getAll`)
  });
};

// const type of function get data per page
export const FETCH_WORK_GROUPS = 'FETCH_WORK_GROUPS';
export const FETCH_WORK_GROUPS_PENDING = 'FETCH_WORK_GROUPS_PENDING';
export const FETCH_WORK_GROUPS_FULFILLED = 'FETCH_WORK_GROUPS_FULFILLED';
export const FETCH_WORK_GROUPS_REJECTED = 'FETCH_WORK_GROUPS_REJECTED';
export const fetchWorkGroups = (searchPayload, page, pageSize) => (dispatch) => {
  return dispatch({
    type: FETCH_WORK_GROUPS,
    payload: api.get(`/WorkGroupInfo/Search`, { params: { ...searchPayload, page, pageSize: pageSize } })
  });
};

// const type of function create data
export const CREATE_WORK_GROUP = 'CREATE_WORK_GROUP';
export const CREATE_WORK_GROUP_PENDING = 'CREATE_WORK_GROUP_PENDING';
export const CREATE_WORK_GROUP_FULFILLED = 'CREATE_WORK_GROUP_FULFILLED';
export const CREATE_WORK_GROUP_REJECTED = 'CREATE_WORK_GROUP_REJECTED';
export const createWorkGroup = (payload) => (dispatch) => {
  return dispatch({
    type: CREATE_WORK_GROUP,
    payload: api.post(`/WorkGroupInfo/create`, payload)
  });
};

// const type of function update data
export const UPDATE_WORK_GROUP = 'UPDATE_WORK_GROUP';
export const UPDATE_WORK_GROUP_PENDING = 'UPDATE_WORK_GROUP_PENDING';
export const UPDATE_WORK_GROUP_FULFILLED = 'UPDATE_WORK_GROUP_FULFILLED';
export const UPDATE_WORK_GROUP_REJECTED = 'UPDATE_WORK_GROUP_REJECTED';
export const updateWorkGroup = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_WORK_GROUP,
    payload: api.put(`/WorkGroupInfo/update`, payload),
    meta: payload
  });
};
// const type of get deatail
export const FETCH_WORK_GROUP_DETAIL = 'FETCH_WORK_GROUP_DETAIL';
export const FETCH_WORK_GROUP_DETAIL_PENDING = 'FETCH_WORK_GROUP_DETAIL_PENDING';
export const FETCH_WORK_GROUP_DETAIL_FULFILLED = 'FETCH_WORK_GROUP_DETAIL_FULFILLED';
export const FETCH_WORK_GROUP_DETAIL_REJECTED = 'FETCH_WORK_GROUP_DETAIL_REJECTED';
export const getWorkGroupDetail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_WORK_GROUP_DETAIL,
    payload: api.get(`/WorkGroupInfo/${id}`)
  });
};

// const type of delete
export const DELETE_WORK_GROUP = 'DELETE_WORK_GROUP';
export const DELETE_WORK_GROUP_PENDING = 'DELETE_WORK_GROUP_PENDING';
export const DELETE_WORK_GROUP_FULFILLED = 'DELETE_WORK_GROUP_FULFILLED';
export const DELETE_WORK_GROUP_REJECTED = 'DELETE_WORK_GROUP_REJECTED';
export const deleteWorkGroup = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_WORK_GROUP,
    payload: api.delete(`/WorkGroupInfo/delete/${id}`)
  });
};

export const FETCH_WORK_GROUP_USERS = 'FETCH_WORK_GROUP_USERS';
export const FETCH_WORK_GROUP_USERS_PENDING = 'FETCH_WORK_GROUP_USERS_PENDING';
export const FETCH_WORK_GROUP_USERS_FULFILLED = 'FETCH_WORK_GROUP_USERS_FULFILLED';
export const FETCH_WORK_GROUP_USERS_REJECTED = 'FETCH_WORK_GROUP_USERS_REJECTED';
export const fetchWorkGroupUsers = (groupId, page, size) => (dispatch) => {
  return dispatch({
    type: FETCH_WORK_GROUP_USERS,
    payload: api.get(`/WorkGroupInfo/${groupId}/UsersIn`, { params: { page, pageSize: size } })
  });
};

export const FETCH_USERS_NOT_IN_WORK_GROUP = 'FETCH_USERS_NOT_IN_WORK_GROUP';
export const FETCH_USERS_NOT_IN_WORK_GROUP_PENDING = 'FETCH_USERS_NOT_IN_WORK_GROUP_PENDING';
export const FETCH_USERS_NOT_IN_WORK_GROUP_FULFILLED = 'FETCH_USERS_NOT_IN_WORK_GROUP_FULFILLED';
export const FETCH_USERS_NOT_IN_WORK_GROUP_REJECTED = 'FETCH_USERS_NOT_IN_WORK_GROUP_REJECTED';
export const fetchUsersNotInWorkGroup = (groupId) => (dispatch) => {
  return dispatch({
    type: FETCH_USERS_NOT_IN_WORK_GROUP,
    payload: api.get(`/WorkGroupInfo/${groupId}/UsersOut`)
  });
};

export const ADD_USERS_TO_WORK_GROUP = 'ADD_USERS_TO_WORK_GROUP';
export const ADD_USERS_TO_WORK_GROUP_PENDING = 'ADD_USERS_TO_WORK_GROUP_PENDING';
export const ADD_USERS_TO_WORK_GROUP_FULFILLED = 'ADD_USERS_TO_WORK_GROUP_FULFILLED';
export const ADD_USERS_TO_WORK_GROUP_REJECTED = 'ADD_USERS_TO_WORK_GROUP_REJECTED';
export const addUsersToWorkGroup = (groupId, userIds) => (dispatch) => {
  return dispatch({
    type: ADD_USERS_TO_WORK_GROUP,
    payload: api.post(`/WorkGroupInfo/${groupId}/AddUsers`, userIds)
  });
};

export const REMOVE_USER_TO_WORK_GROUP = 'REMOVE_USER_TO_WORK_GROUP';
export const REMOVE_USER_TO_WORK_GROUP_PENDING = 'REMOVE_USER_TO_WORK_GROUP_PENDING';
export const REMOVE_USER_TO_WORK_GROUP_FULFILLED = 'REMOVE_USER_TO_WORK_GROUP_FULFILLED';
export const REMOVE_USER_TO_WORK_GROUP_REJECTED = 'REMOVE_USER_TO_WORK_GROUP_REJECTED';
export const removeUserFromWorkGroup = (groupId, userId) => (dispatch) => {
  return dispatch({
    type: REMOVE_USER_TO_WORK_GROUP,
    payload: api.delete(`/WorkGroupInfo/${groupId}/Delete/${userId}`)
  });
};

export const GET_WORK_GROUP_BY_USER_ID = 'GET_WORK_GROUP_BY_USER_ID';
export const GET_WORK_GROUP_BY_USER_ID_PENDING = 'GET_WORK_GROUP_BY_USER_ID_PENDING';
export const GET_WORK_GROUP_BY_USER_ID_FULFILLED = 'GET_WORK_GROUP_BY_USER_ID_FULFILLED';
export const GET_WORK_GROUP_BY_USER_ID_REJECTED = 'GET_WORK_GROUP_BY_USER_ID_REJECTED';
export const getWorkGroupByUser = (userId) => (dispatch) => {
  return dispatch({
    type: GET_WORK_GROUP_BY_USER_ID,
    payload: api.get(`/WorkGroupInfo/getWorkGroupByUser/${userId}`)
  });
};
