import { CAR_DETAIL } from 'actions/CarDetail';

const INIT_STATE = {
  data: {},
  truckings: []
};

const CarHistory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CAR_DETAIL.GET_DETAIL_TRACTOR_TRAILER_FULFILLED:
      const data = action?.payload?.data?.data?.[0] ?? {};
      const truckings = action?.payload?.data?.trucking ?? [];
      return { ...state, data, truckings };
    default:
      return state;
  }
};

export default CarHistory;
