import { DEPOSIT_SHELL_ACTIONS } from "actions/depositShell";

const dataMessageExist = ["shipping line and container-Dữ liệu đã tồn tại"];

const INIT_STATE = {
  listPerPage: [],
  goods: [],
  listContCategory: [],
  listShippingLine: [],
  loading: false,
  typeMessage: "",
  message: "",
  isShowMessage: false,
  pagination: {},
  isCloseModal: false,
  isSearch: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DEPOSIT_SHELL_ACTIONS.GET_DEPOSIT_SHELL_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case DEPOSIT_SHELL_ACTIONS.GET_DEPOSIT_SHELL_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false,
      };
    }
    case DEPOSIT_SHELL_ACTIONS.GET_DEPOSIT_SHELL_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
        isCloseModal: false,
      };
    }

    case DEPOSIT_SHELL_ACTIONS.CREATE_DEPOSIT_SHELL_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
      };
    }
    case DEPOSIT_SHELL_ACTIONS.CREATE_DEPOSIT_SHELL_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: "success",
        message: "Thêm mới dữ liệu thành công !",
        isShowMessage: true,
        isCloseModal: true,
      };
    }
    case DEPOSIT_SHELL_ACTIONS.CREATE_DEPOSIT_SHELL_REJECTED:
      const messageError = action.payload.response.data.message + "";
      let check = dataMessageExist.includes(messageError);
      return {
        ...state,
        loading: false,
        //   message: messageError,
        message: check ? "Dữ liệu đã tồn tại !" : "Thêm dữ liệu thất bại",
        isShowMessage: true,
        typeMessage: "error",
        isCloseModal: false,
      };

    case DEPOSIT_SHELL_ACTIONS.DELETE_DEPOSIT_SHELL_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case DEPOSIT_SHELL_ACTIONS.DELETE_DEPOSIT_SHELL_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: "success",
        message: "Xóa dữ liệu thành công",
        isShowMessage: true,
      };
    }
    case DEPOSIT_SHELL_ACTIONS.DELETE_DEPOSIT_SHELL_REJECTED: {
      return {
        ...state,
        loading: false,
        message: "Xóa dữ liệu thất bại",
        isShowMessage: true,
        typeMessage: "error",
      };
    }

    case DEPOSIT_SHELL_ACTIONS.UPDATE_DEPOSIT_SHELL_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
      };
    }
    case DEPOSIT_SHELL_ACTIONS.UPDATE_DEPOSIT_SHELL_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: "success",
        message: "Cập nhật dữ liệu thành công",
        isShowMessage: true,
        isCloseModal: true,
      };
    }
    case DEPOSIT_SHELL_ACTIONS.UPDATE_DEPOSIT_SHELL_REJECTED: {
      const messageError = action.payload.response.data.message + "";
      let check = dataMessageExist.includes(messageError);
      return {
        ...state,
        loading: false,
        message: check ? "Dữ liệu đã tồn tại !" : "Cập nhật dữ liệu thất bại",
        isShowMessage: true,
        typeMessage: "error",
        isCloseModal: false,
      };
    }

    case DEPOSIT_SHELL_ACTIONS.GET_CONT_CATEGORY_FULFILLED: {
      return {
        ...state,
        loading: false,
        listContCategory: action.payload.data.data,
      };
    }

    case DEPOSIT_SHELL_ACTIONS.GET_SHIPPING_LINE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listShippingLine: action.payload.data.data,
      };
    }

    case "UPDATE_SHOW_ALERT": {
      return {
        ...state,
        isShowMessage: false,
      };
    }

    case DEPOSIT_SHELL_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case DEPOSIT_SHELL_ACTIONS.SEARCH_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
      };
    }
    case DEPOSIT_SHELL_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }
    case DEPOSIT_SHELL_ACTIONS.GET_GOOD_CATEGORY_PENDING:
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };

    case DEPOSIT_SHELL_ACTIONS.GET_GOOD_CATEGORY_FULFILLED:
      let goods = {
        data: action.payload.data.data,
      };
      return {
        ...state,
        loading: false,
        goods: goods,
      };

    case DEPOSIT_SHELL_ACTIONS.GET_GOOD_CATEGORY_REJECTED:
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };

    default:
      return state;
  }
};
