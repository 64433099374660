import { ACCESS_TOKEN, COMPANY_ID, COMPANY_NAME, IS_ADMIN,USERNAME } from 'const/common';
import Cookies from 'js-cookie';

import { isNull } from './utils';

export function getAuthUser() {
  let authUser = localStorage.getItem(USERNAME);
  if (
    authUser !== undefined &&
    authUser !== null &&
    authUser !== 'undefined' &&
    authUser !== 'null' &&
    authUser !== ''
  ) {
    return authUser;
  } else {
    localStorage.clear();
    return null;
  }
}

export function getAccessToken() {
  let accessToken = localStorage.getItem(ACCESS_TOKEN);
  if (
    accessToken !== undefined &&
    accessToken !== null &&
    accessToken !== 'undefined' &&
    accessToken !== 'null' &&
    accessToken !== ''
  ) {
    return accessToken;
  } else {
    localStorage.clear();
    return '';
  }
}

export function getCompanyId() {
  let companyId = localStorage.getItem(COMPANY_ID);
  if (
    companyId !== undefined &&
    companyId !== null &&
    companyId !== 'undefined' &&
    companyId !== 'null' &&
    companyId !== ''
  ) {
    return parseInt(companyId);
  } else {
    return 1;
  }
}

export function getIsAdmin() {
  let isAdmin = localStorage.getItem(IS_ADMIN);
  if (isAdmin !== undefined && isAdmin !== null && isAdmin !== 'undefined' && isAdmin !== 'null' && isAdmin !== '') {
    return isAdmin;
  } else {
    return false;
  }
}

export function getCompanyName() {
  let companyName = localStorage.getItem(COMPANY_NAME);
  if (
    companyName !== undefined &&
    companyName !== null &&
    companyName !== 'undefined' &&
    companyName !== 'null' &&
    companyName !== ''
  ) {
    return companyName;
  } else {
    return '';
  }
}

export function getSavedValue(key, defaultValue) {
  let value = Cookies.get(key);
  if (isNull(value)) {
    value = defaultValue;
  }
  return value;
}

export function saveValue(key, value, meta) {
  Cookies.set(key, value, meta ? meta : { expires: 7 });
}

export function removeKey(key) {
  Cookies.remove(key);
}
