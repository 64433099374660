import { UNIT_PAYMEMT_ACTIONS } from "actions/unitTypePayment";

const INIT_STATE = {
  unitType: {},
  loading: false,
  typeMessage: "",
  message: "",
  isShowMessage: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UNIT_PAYMEMT_ACTIONS.GET_ALL_UNIT_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case UNIT_PAYMEMT_ACTIONS.GET_ALL_UNIT_PAYMENT_FULFILLED: {
      let result = {
        data: action.payload.data.data,
      };
      return {
        ...state,
        loading: false,
        unitType: result,
      };
    }
    case UNIT_PAYMEMT_ACTIONS.GET_ALL_UNIT_PAYMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }
    default:
      return state;
  }
};
