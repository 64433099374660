import api from 'HttpClient';

export const CAR_DETAIL = {
  GET_DETAIL_TRACTOR_TRAILER: 'GET_DETAIL_TRACTOR_TRAILER',
  GET_DETAIL_TRACTOR_TRAILER_PENDING: 'GET_DETAIL_TRACTOR_TRAILER_PENDING',
  GET_DETAIL_TRACTOR_TRAILER_FULFILLED: 'GET_DETAIL_TRACTOR_TRAILER_FULFILLED',
  GET_DETAIL_TRACTOR_TRAILER_REJECTED: 'GET_DETAIL_TRACTOR_TRAILER_REJECTED'
};

export const getDetailTractorTrailer = (params) => (dispatch) => {
  return dispatch({
    type: CAR_DETAIL.GET_DETAIL_TRACTOR_TRAILER,
    payload: api.get(`/TractorTrailerInfo/getDetailTractorTrailer`, { params })
  });
};
