import { PRODUCT_CATEGORY_ACTIONS } from 'actions/productcategory';
import { DEFAULT_PAGE_SIZE } from 'const/common';

const INIT_STATE = {
  pagingProductCategory: {
    data: [],
    pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE }
  }
};

const productCategoryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // get all product category
    case PRODUCT_CATEGORY_ACTIONS.GET_ALL_PRODUCT_CATEGORY_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case PRODUCT_CATEGORY_ACTIONS.GET_ALL_PRODUCT_CATEGORY_FULFILLED:
      const productCategory = {
        data: action.payload.data.data
      };
      return {
        ...state,
        productCategory: productCategory,
        isLoading: false
      };

    case PRODUCT_CATEGORY_ACTIONS.GET_ALL_PRODUCT_CATEGORY_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi',
        typeMessage: 'error'
      };

    // get product category per page
    case PRODUCT_CATEGORY_ACTIONS.GET_PRODUCT_CATEGORY_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case PRODUCT_CATEGORY_ACTIONS.GET_PRODUCT_CATEGORY_PER_PAGE_FULFILLED:
      const productCategory2 = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data
      };
      return {
        ...state,
        pagingProductCategory: productCategory2,
        isLoading: false
      };

    case PRODUCT_CATEGORY_ACTIONS.GET_PRODUCT_CATEGORY_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi',
        typeMessage: 'error'
      };

    // get product category by id
    case PRODUCT_CATEGORY_ACTIONS.GET_PRODUCT_CATEGORY_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case PRODUCT_CATEGORY_ACTIONS.GET_PRODUCT_CATEGORY_BY_ID_FULFILLED:
      return {
        ...state,
        isLoading: false
      };

    case PRODUCT_CATEGORY_ACTIONS.GET_PRODUCT_CATEGORY_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi',
        typeMessage: 'error'
      };

    // create product category
    case PRODUCT_CATEGORY_ACTIONS.CREATE_PRODUCT_CATEGORY_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case PRODUCT_CATEGORY_ACTIONS.CREATE_PRODUCT_CATEGORY_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Tạo dữ liệu hành công',
        typeMessage: 'success'
      };

    case PRODUCT_CATEGORY_ACTIONS.CREATE_PRODUCT_CATEGORY_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Tạo dữ liệu thất bại',
        typeMessage: 'error'
      };

    // update product category
    case PRODUCT_CATEGORY_ACTIONS.UPDATE_PRODUCT_CATEGORY_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case PRODUCT_CATEGORY_ACTIONS.UPDATE_PRODUCT_CATEGORY_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Sửa dữ liệu thành công',
        typeMessage: 'success'
      };

    case PRODUCT_CATEGORY_ACTIONS.UPDATE_PRODUCT_CATEGORY_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Sửa dữ liệu thất bại',
        typeMessage: 'error'
      };

    // delete product category
    case PRODUCT_CATEGORY_ACTIONS.DELETE_PRODUCT_CATEGORY_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case PRODUCT_CATEGORY_ACTIONS.DELETE_PRODUCT_CATEGORY_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Xóa dữ liệu thành công',
        typeMessage: 'success'
      };

    case PRODUCT_CATEGORY_ACTIONS.DELETE_PRODUCT_CATEGORY_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Xóa dữ liệu thất bại',
        typeMessage: 'error'
      };
    //search
    case PRODUCT_CATEGORY_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };
    }
    case PRODUCT_CATEGORY_ACTIONS.SEARCH_DATA_FULFILLED: {
      let productCategory = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        isLoading: false,
        pagingProductCategory: productCategory,
        pagination: action.payload.data.pagination,
        isSearch: true
      };
    }
    case PRODUCT_CATEGORY_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        isLoading: false,
        isShowMessage: true,
        typeMessage: 'error',
        msg: 'Không thể lấy được dữ liệu !'
      };
    }

    default:
      return state;
  }
};

export default productCategoryReducer;
