import httpClient from 'HttpClient';
import {
  createVehicleDriver,
  createVehicles,
  deleteVehicles,
  filterCarByStatus,
  filtersCarByDepartment,
  getAllVehicles,
  getAllVehiclesByCompany,
  getTractorTrailerByPrimaryCompany,
  getVehiclesByFilters,
  getVehiclesByID,
  getVehiclesPerPage,
  searchVehicles,
  searchVehiclesAll,
  updateVehicles
} from 'services/vehicle';

// define const actions
export const VEHICLE_ACTIONS = {
  CREATE_VEHICLE_DRIVER: 'CREATE_VEHICLE_DRIVER',
  CREATE_VEHICLE_DRIVER_PENDING: 'CREATE_VEHICLE_DRIVER_PENDING',
  CREATE_VEHICLE_DRIVER_FULFILLED: 'CREATE_VEHICLE_DRIVER_FULFILLED',
  CREATE_VEHICLE_DRIVER_REJECTED: 'CREATE_VEHICLE_DRIVER_REJECTED',

  GET_ALL_VEHICLES: 'GET_ALL_VEHICLES',
  GET_ALL_VEHICLES_PENDING: 'GET_ALL_VEHICLES_PENDING',
  GET_ALL_VEHICLES_FULFILLED: 'GET_ALL_VEHICLES_FULFILLED',
  GET_ALL_VEHICLES_REJECTED: 'GET_ALL_VEHICLES_REJECTED',

  GET_VEHICLES_PER_PAGE: 'GET_VEHICLES_PER_PAGE',
  GET_VEHICLES_PER_PAGE_PENDING: 'GET_VEHICLES_PER_PAGE_PENDING',
  GET_VEHICLES_PER_PAGE_FULFILLED: 'GET_VEHICLES_PER_PAGE_FULFILLED',
  GET_VEHICLES_PER_PAGE_REJECTED: 'GET_VEHICLES_PER_PAGE_REJECTED',

  GET_VEHICLES_BY_ID: 'GET_VEHICLES_BY_ID',
  GET_VEHICLES_BY_ID_PENDING: 'GET_VEHICLES_BY_ID_PENDING',
  GET_VEHICLES_BY_ID_FULFILLED: 'GET_VEHICLES_BY_ID_FULFILLED',
  GET_VEHICLES_BY_ID_REJECTED: 'GET_VEHICLES_BY_ID_REJECTED',

  CREATE_VEHICLES: 'CREATE_VEHICLES',
  CREATE_VEHICLES_PENDING: 'CREATE_VEHICLES_PENDING',
  CREATE_VEHICLES_FULFILLED: 'CREATE_VEHICLES_FULFILLED',
  CREATE_VEHICLES_REJECTED: 'CREATE_VEHICLES_REJECTED',

  UPDATE_VEHICLES: 'UPDATE_VEHICLES',
  UPDATE_VEHICLES_PENDING: 'UPDATE_VEHICLES_PENDING',
  UPDATE_VEHICLES_FULFILLED: 'UPDATE_VEHICLES_FULFILLED',
  UPDATE_VEHICLES_REJECTED: 'UPDATE_VEHICLES_REJECTED',

  DELETE_VEHICLES: 'DELETE_VEHICLES',
  DELETE_VEHICLES_PENDING: 'DELETE_VEHICLES_PENDING',
  DELETE_VEHICLES_FULFILLED: 'DELETE_VEHICLES_FULFILLED',
  DELETE_VEHICLES_REJECTED: 'DELETE_VEHICLES_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  GET_VEHICLES_BY_FILTERS: 'GET_VEHICLES_BY_FILTERS',
  GET_VEHICLES_BY_FILTERS_PENDING: 'GET_VEHICLES_BY_FILTERS_PENDING',
  GET_VEHICLES_BY_FILTERS_FULFILLED: 'GET_VEHICLES_BY_FILTERS_FULFILLED',
  GET_VEHICLES_BY_FILTERS_REJECTED: 'GET_VEHICLES_BY_FILTERS_REJECTED',

  FILTER_CAR_BY_STATUS: 'FILTER_CAR_BY_STATUS',
  FILTER_CAR_BY_STATUS_PENDING: 'FILTER_CAR_BY_STATUS_PENDING',
  FILTER_CAR_BY_STATUS_FULFILLED: 'FILTER_CAR_BY_STATUS_FULFILLED',
  FILTER_CAR_BY_STATUS_REJECTED: 'FILTER_CAR_BY_STATUS_REJECTED',

  FILTERS_CAR_BY_DEPARTMENT: 'FILTERS_CAR_BY_DEPARTMENT',
  FILTERS_CAR_BY_DEPARTMENT_PENDING: 'FILTERS_CAR_BY_DEPARTMENT_PENDING',
  FILTERS_CAR_BY_DEPARTMENT_FULFILLED: 'FILTERS_CAR_BY_DEPARTMENT_FULFILLED',
  FILTERS_CAR_BY_DEPARTMENT_REJECTED: 'FILTERS_CAR_BY_DEPARTMENT_REJECTED',

  GET_VEHICLES_BY_TRANSPORT_COMPANY_ID: 'GET_VEHICLES_BY_TRANSPORT_COMPANY_ID',
  GET_VEHICLES_BY_TRANSPORT_COMPANY_ID_PENDING: 'GET_VEHICLES_BY_TRANSPORT_COMPANY_ID_PENDING',
  GET_VEHICLES_BY_TRANSPORT_COMPANY_ID_FULFILLED: 'GET_VEHICLES_BY_TRANSPORT_COMPANY_ID_FULFILLED',
  GET_VEHICLES_BY_TRANSPORT_COMPANY_ID_REJECTED: 'GET_VEHICLES_BY_TRANSPORT_COMPANY_ID_REJECTED',

  GET_ALL_BRANDS_VEHICLE: 'GET_ALL_BRANDS_VEHICLE',
  GET_ALL_BRANDS_VEHICLE_PENDING: 'GET_ALL_BRANDS_VEHICLE_PENDING',
  GET_ALL_BRANDS_VEHICLE_FULFILLED: 'GET_ALL_BRANDS_VEHICLE_FULFILLED',
  GET_ALL_BRANDS_VEHICLE_REJECTED: 'GET_ALL_BRANDS_VEHICLE_REJECTED',
  GET_INTERNAL_VEHICLES: 'GET_INTERNAL_VEHICLES',
  GET_INTERNAL_VEHICLES_PENDING: 'GET_INTERNAL_VEHICLES_PENDING',
  GET_INTERNAL_VEHICLES_FULFILLED: 'GET_INTERNAL_VEHICLES_FULFILLED',
  GET_INTERNAL_VEHICLES_REJECTED: 'GET_INTERNAL_VEHICLES_REJECTED',

  GET_VEHICLES_BY_COMPANY: 'GET_VEHICLES_BY_COMPANY',
  GET_VEHICLES_BY_COMPANY_PENDING: 'GET_VEHICLES_BY_COMPANY_PENDING',
  GET_VEHICLES_BY_COMPANY_FULFILLED: 'GET_VEHICLES_BY_COMPANY_FULFILLED',
  GET_VEHICLES_BY_COMPANY_REJECTED: 'GET_VEHICLES_BY_COMPANY_REJECTED'
};

// define actions

export const actionCreateVehicleDriver = (payload) => (dispatch) => {
  return dispatch({
    type: VEHICLE_ACTIONS.CREATE_VEHICLE_DRIVER,
    payload: createVehicleDriver(payload)
  });
};

export const actionGetAllVehicles = () => (dispatch) => {
  return dispatch({
    type: VEHICLE_ACTIONS.GET_ALL_VEHICLES,
    payload: getAllVehicles()
  });
};

export const actionGetTractorTrailerByPrimaryCompany = (params) => (dispatch) => {
  return dispatch({
    type: VEHICLE_ACTIONS.GET_ALL_VEHICLES,
    payload: getTractorTrailerByPrimaryCompany(params)
  });
};

export const actionGetVehiclesPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: VEHICLE_ACTIONS.GET_VEHICLES_PER_PAGE,
    payload: getVehiclesPerPage(pageNo, pageSize)
  });
};

export const actionGetVehiclesByID = (id) => (dispatch) => {
  return dispatch({
    type: VEHICLE_ACTIONS.GET_VEHICLES_BY_ID,
    payload: getVehiclesByID(id)
  });
};

export const actionCreateVehicles = (payload) => (dispatch) => {
  return dispatch({
    type: VEHICLE_ACTIONS.CREATE_VEHICLES,
    payload: createVehicles(payload),
    meta: {
      currentPage: payload.currentPage
    }
  });
};

export const actionUpdateVehicles = (payload) => (dispatch) => {
  return dispatch({
    type: VEHICLE_ACTIONS.UPDATE_VEHICLES,
    payload: updateVehicles(payload),
    meta: {
      currentPage: payload.currentPage
    }
  });
};

export const actionDeleteVehicles = (id, currentPage) => (dispatch) => {
  return dispatch({
    type: VEHICLE_ACTIONS.DELETE_VEHICLES,
    payload: deleteVehicles(id),
    meta: {
      currentPage: currentPage
    }
  });
};

export const actionSearchVehicles = (params, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: VEHICLE_ACTIONS.SEARCH_DATA,
    payload: searchVehicles(params, pageNo, pageSize)
  });
};
export const actionSearchVehiclesAll = (params) => (dispatch) => {
  return dispatch({
    // type: VEHICLE_ACTIONS.SEARCH_DATA,
    payload: searchVehiclesAll(params)
  });
};

export const actionGetVehiclesByFilters = (payload) => (dispatch) => {
  return dispatch({
    type: VEHICLE_ACTIONS.GET_VEHICLES_BY_FILTERS,
    payload: getVehiclesByFilters(payload)
  });
};

export const actionFilterCarByStatus = (statusId, pageSize, pageNo) => (dispatch) => {
  return dispatch({
    type: VEHICLE_ACTIONS.FILTER_CAR_BY_STATUS,
    payload: filterCarByStatus(statusId, pageSize, pageNo)
  });
};

export const actionFiltersCarByDepartment = (payload, filterType) => (dispatch) => {
  return dispatch({
    type: VEHICLE_ACTIONS.FILTERS_CAR_BY_DEPARTMENT,
    payload: filtersCarByDepartment(payload, filterType)
  });
};

export const actionGetVehiclesByTransportCompany = (transportCompanyId) => (dispatch) => {
  return dispatch({
    type: VEHICLE_ACTIONS.GET_VEHICLES_BY_TRANSPORT_COMPANY_ID,
    payload: httpClient.get(`/tractortrailerinfo/getTractorTrailerByTransportCompanyId`, {
      params: { transportCompanyId: transportCompanyId }
    })
  });
};

export const actionGetAllBrandsVehicle = () => (dispatch) => {
  return dispatch({
    type: VEHICLE_ACTIONS.GET_ALL_BRANDS_VEHICLE,
    payload: httpClient.get(`/brandinfo/getall`)
  });
};
export const getInteralVehicles = () => (dispatch) => {
  return dispatch({
    type: VEHICLE_ACTIONS.GET_INTERNAL_VEHICLES,
    payload: httpClient.get(`/TractorTrailerInfo/getTractorTrailerByPrimaryCompany?isPrimaryCompany=true`)
  });
};

export const actionGetAllVehiclesByCompany = (id) => (dispatch) => {
  return dispatch({
    type: VEHICLE_ACTIONS.GET_VEHICLES_BY_COMPANY,
    payload: getAllVehiclesByCompany(id)
  });
};
