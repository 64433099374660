import {
    CREATE_PERMISSION_GROUP_BY_CUSTOMER_FULFILLED,
    CREATE_PERMISSION_GROUP_BY_CUSTOMER_PENDING,
    CREATE_PERMISSION_GROUP_BY_CUSTOMER_REJECTED,
    DELETE_PERMISSION_GROUP_BY_CUSTOMER_FULFILLED,
    DELETE_PERMISSION_GROUP_BY_CUSTOMER_PENDING,
    DELETE_PERMISSION_GROUP_BY_CUSTOMER_REJECTED,
    FETCH_ALL_PERMISSION_GROUP_BY_CUSTOMERS_FULFILLED,
    FETCH_ALL_PERMISSION_GROUP_BY_CUSTOMERS_PENDING,
    FETCH_ALL_PERMISSION_GROUP_BY_CUSTOMERS_REJECTED,
    FETCH_PERMISSION_GROUP_BY_CUSTOMER_DETAIL_FULFILLED,
    FETCH_PERMISSION_GROUP_BY_CUSTOMER_DETAIL_PENDING,
    FETCH_PERMISSION_GROUP_BY_CUSTOMER_DETAIL_REJECTED,
    FETCH_PERMISSION_GROUP_BY_CUSTOMERS_FULFILLED,
    FETCH_PERMISSION_GROUP_BY_CUSTOMERS_PENDING,
    FETCH_PERMISSION_GROUP_BY_CUSTOMERS_REJECTED,
    SET_CLOSE_PERMISSION_GROUP_BY_CUSTOMER_MODAL,
    SET_OPEN_PERMISSION_GROUP_BY_CUSTOMER_MODAL,
    UPDATE_PERMISSION_GROUP_BY_CUSTOMER_FULFILLED,
    UPDATE_PERMISSION_GROUP_BY_CUSTOMER_PENDING,
    UPDATE_PERMISSION_GROUP_BY_CUSTOMER_REJECTED,
  } from 'actions';
  import { getErrorMessage } from 'commons/ReducerUtils';
  import {
    DEFAULT_PAGE_SIZE,
    MSG_ADD_FAILURE,
    MSG_ADD_SUCCESS,
    MSG_DELETE_FAILURE,
    MSG_DELETE_SUCCESS,
    MSG_EDIT_FAILURE,
    MSG_EDIT_SUCCESS,
    MSG_GET_DETAIL_FAILURE,
    MSG_PAGING_FAILURE,
    MSG_TYPE_ERROR,
    MSG_TYPE_SUCCESS  } from 'const/common';
  const INIT_STATE = {
    //combobox: get all
    allPermissionGroupByCustomers: [],
    // paging
    permissionGroupByCustomers: [],
    pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
    listLoading: false,
  
    // add/edit modal
    isOpenFormModal: false, // Show/hidden update modal
    permissionGroupByCustomer: {},
    formLoading: false,
    //delete
    deleteLoading: false,
    // other
    showMessage: false,
    alertMessage: '',
    formAlertMessage: '',
    messageType: ''
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      //#region update modal
      case SET_OPEN_PERMISSION_GROUP_BY_CUSTOMER_MODAL: {
        return {
          ...state,
          isOpenFormModal: true,
          permissionGroupByCustomer: action.payload.data,
          type: action.payload.type
        };
      }
      case SET_CLOSE_PERMISSION_GROUP_BY_CUSTOMER_MODAL: {
        return {
          ...state,
          isOpenFormModal: false,
          permissionGroupByCustomer: INIT_STATE.permissionGroupByCustomer
        };
      }
      //#endregion
  
      //#region getall
      case FETCH_ALL_PERMISSION_GROUP_BY_CUSTOMERS_PENDING: {
        return {
          ...state,
          listLoading: true,
          showMessage: false
        };
      }
      case FETCH_ALL_PERMISSION_GROUP_BY_CUSTOMERS_FULFILLED: {
        return {
          ...state,
          listLoading: false,
          allPermissionGroupByCustomers: action.payload.data.data,
          showMessage: false
        };
      }
      case FETCH_ALL_PERMISSION_GROUP_BY_CUSTOMERS_REJECTED: {
        return {
          ...state,
          listLoading: false,
          showMessage: true,
          alertMessage: MSG_PAGING_FAILURE,
          messageType: MSG_TYPE_ERROR
        };
      }
      //#endregion
  
      //#region paging
      case FETCH_PERMISSION_GROUP_BY_CUSTOMERS_PENDING: {
        return {
          ...state,
          listLoading: true,
          showMessage: false
        };
      }
  
      case FETCH_PERMISSION_GROUP_BY_CUSTOMERS_FULFILLED: {
        return {
          ...state,
          listLoading: false,
          permissionGroupByCustomers: action.payload.data.data ?? INIT_STATE.permissionGroupByCustomers,
          pagination: action.payload.data.pagination ?? INIT_STATE.pagination,
          showMessage: false
        };
      }
      case FETCH_PERMISSION_GROUP_BY_CUSTOMERS_REJECTED: {
        return {
          ...state,
          listLoading: false,
          showMessage: true,
          alertMessage: MSG_PAGING_FAILURE,
          messageType: MSG_TYPE_ERROR
        };
      }
      //#endregion
  
      //#region create data
      case CREATE_PERMISSION_GROUP_BY_CUSTOMER_PENDING:
        return {
          ...state,
          formLoading: true
        };
      case CREATE_PERMISSION_GROUP_BY_CUSTOMER_FULFILLED: {
        const permissionGroupByCustomer = action.payload.data;
        const permissionGroupByCustomers = state.permissionGroupByCustomers.map((item) => {
          return item.Id === permissionGroupByCustomer.Id ? permissionGroupByCustomer : item;
        });
        return {
          ...state,
          permissionGroupByCustomers: [...permissionGroupByCustomers],
          formLoading: false,
          showMessage: true,
          formAlertMessage: MSG_ADD_SUCCESS,
          messageType: MSG_TYPE_SUCCESS
        };
      }
      case CREATE_PERMISSION_GROUP_BY_CUSTOMER_REJECTED: {
        const alertMessage = getErrorMessage(action, MSG_ADD_FAILURE);
        return {
          ...state,
          formLoading: false,
          showMessage: true,
          formAlertMessage: alertMessage,
          messageType: MSG_TYPE_ERROR
        };
      }
      //#endregion
  
      //#region update data
      case UPDATE_PERMISSION_GROUP_BY_CUSTOMER_PENDING:
        return {
          ...state,
          formLoading: true,
          showMessage: false
        };
      case UPDATE_PERMISSION_GROUP_BY_CUSTOMER_FULFILLED: {
        const permissionGroupByCustomer = action.meta;
        let permissionGroupByCustomers = [...state.permissionGroupByCustomers];
        permissionGroupByCustomers = permissionGroupByCustomers.map((item) => {
          return item.Id === permissionGroupByCustomer.Id ? permissionGroupByCustomer : item;
        });
        return {
          ...state,
          permissionGroupByCustomers,
          formLoading: false,
          showMessage: true,
          formAlertMessage: MSG_EDIT_SUCCESS,
          messageType: MSG_TYPE_SUCCESS
        };
      }
      case UPDATE_PERMISSION_GROUP_BY_CUSTOMER_REJECTED: {
        const alertMessage = getErrorMessage(action, MSG_EDIT_FAILURE);
        return {
          ...state,
          formLoading: false,
          showMessage: true,
          formAlertMessage: alertMessage,
          messageType: MSG_TYPE_ERROR
        };
      }
      //#endregion
  
      //#region  get detail
      case FETCH_PERMISSION_GROUP_BY_CUSTOMER_DETAIL_PENDING:
        return {
          ...state,
          formLoading: true,
          showMessage: false
        };
      case FETCH_PERMISSION_GROUP_BY_CUSTOMER_DETAIL_FULFILLED:
        return {
          ...state,
          permissionGroupByCustomer: action.payload.data,
          formLoading: false,
          showMessage: false
        };
      case FETCH_PERMISSION_GROUP_BY_CUSTOMER_DETAIL_REJECTED:
        return {
          ...state,
          formLoading: false,
          showMessage: true,
          alertMessage: MSG_GET_DETAIL_FAILURE,
          messageType: MSG_TYPE_ERROR
        };
      //#endregion
  
      //#region delete data
      case DELETE_PERMISSION_GROUP_BY_CUSTOMER_PENDING:
        return {
          ...state,
          deleteLoading: true
        };
      case DELETE_PERMISSION_GROUP_BY_CUSTOMER_FULFILLED:
        return {
          ...state,
          isDelete: true,
          deleteLoading: false,
          showMessage: true,
          alertMessage: MSG_DELETE_SUCCESS,
          messageType: MSG_TYPE_SUCCESS
        };
      case DELETE_PERMISSION_GROUP_BY_CUSTOMER_REJECTED: {
        const alertMessage = getErrorMessage(action, MSG_DELETE_FAILURE) || action?.payload?.response?.data?.message;
        return {
          ...state,
          deleteLoading: false,
          showMessage: true,
          alertMessage: alertMessage,
          messageType: MSG_TYPE_ERROR
        };
      }
      //#endregion
  
      default:
        return state;
    }
  };
  