import { DRIVER_PLAN_MOBILE_ACTIONS } from 'actions/mobile/driverPlan';

const INIT_STATE = {
  listPerPage: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  pagination: {},
  isSearch: false,
  isCloseModal: false,
  item: {},
  status: [],
  isReload: false,
  fee: {},
  permissionsMobile: [],
  transportFee: [],
  categoryFee: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DRIVER_PLAN_MOBILE_ACTIONS.GET_CATEGORY_FEE_FOR_MOBILE_FULFILLED: {
      return {
        ...state,
        loading: false,
        categoryFee: action?.payload?.data?.data ?? []
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.GET_TRANSPORT_FEE_BY_TRUCKING_PLAN_ID_FULFILLED: {
      return {
        ...state,
        loading: false,
        transportFee: action?.payload?.data?.data?.Result ?? []
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.GET_PERMISSIONS_MOBILE_FULFILLED: {
      return {
        ...state,
        loading: false,
        permissionsMobile: action?.payload?.data?.data?.[0]?.AllowedFunctions
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.GET_DATA_DRIVER_PLAN_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.GET_DATA_DRIVER_PLAN_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false,
        isReload: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.GET_DATA_DRIVER_PLAN_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        isReload: false
      };
    }

    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }

    case DRIVER_PLAN_MOBILE_ACTIONS.GET_DATA_BY_ID_PENDING: {
      return {
        ...state,
        isShowMessage: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.GET_DATA_BY_ID_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        item: action.payload.data.data,
        isReload: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.GET_DATA_BY_ID_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isReload: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.GET_FEE_BY_ID_PENDING: {
      return {
        ...state,
        isShowMessage: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.GET_FEE_BY_ID_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        fee: action.payload.data.data,
        isReload: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.GET_FEE_BY_ID_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isReload: false
      };
    }

    case DRIVER_PLAN_MOBILE_ACTIONS.UPDATE_TRIP_NUMBER_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.UPDATE_TRIP_NUMBER_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công !',
        isCloseModal: true,
        isReload: true
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.UPDATE_TRIP_NUMBER_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Cập nhật dữ liệu thất bại !',
        isCloseModal: false,
        isReload: false
      };
    }

    case DRIVER_PLAN_MOBILE_ACTIONS.UPDATE_FEE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.UPDATE_FEE_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công !',
        isCloseModal: true,
        isReload: true
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.UPDATE_FEE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Cập nhật dữ liệu thất bại !',
        isCloseModal: false,
        isReload: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.CREATE_DATA_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.CREATE_DATA_PAYMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isCloseModal: true
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.CREATE_DATA_PAYMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message:
          action.payload.response.data.status === '400'
            ? action.payload.response.data.message
            : 'Thêm mới dữ liệu thất bại !',
        isCloseModal: false
      };
    }

    case DRIVER_PLAN_MOBILE_ACTIONS.UPDATE_STATUS_SUB_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.UPDATE_STATUS_SUB_PAYMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Cập nhật trạng thái tạm ứng thành công !',
        isCloseModal: true
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.UPDATE_STATUS_SUB_PAYMENT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể cập nhật trạng thái tạm ứng !',
        isCloseModal: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.GET_STATUS_TRANSPORT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.GET_STATUS_TRANSPORT_FULFILLED: {
      return {
        ...state,
        loading: false,
        status: action.payload.data.data,
        isSearch: false,
        isCloseModal: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.GET_STATUS_TRANSPORT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.DELETE_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.DELETE_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Xóa dữ liệu thành công',
        isShowMessage: true,
        isCloseModal: true
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.DELETE_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        message:
          action.payload.response.data.status === '400' ? action.payload.response.data.message : 'Xóa dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false
      };
    }
    case DRIVER_PLAN_MOBILE_ACTIONS.UPDATE_STATUS: {
      const data = action.payload;
      const dataUpdate = state.status.map((el, i) => {
        return {
          ...el,
          className:
            i === data.index + 1 && (data.isSelected === false || data.isSelected === undefined)
              ? 'selected'
              : i === data.index + 1
              ? ''
              : el.className,
          selected: i === data.index + 1 ? (data.isSelected === undefined ? true : !data.isSelected) : el.selected
        };
      });

      return {
        ...state,
        status: dataUpdate
      };
    }

    default:
      return state;
  }
};
