import { TRUCKING_FEE_ACTIONS } from 'actions/truckingfee';
import { getErrorMessage } from 'commons/ReducerUtils';
import { DEFAULT_PAGE_SIZE, INTERRUPT_API, MSG_EDIT_FAILURE, MSG_TYPE_ERROR, MSG_TYPE_SUCCESS } from 'const/common';

const INIT_STATE = {
  truckingFee: {
    pagination: { pageSize: DEFAULT_PAGE_SIZE, pageNo: 1 },
    data: []
  },
  truckingFeeByID: {},
  alertMessage: '',
  newestFee: {},
  showMessage: false,
  typeMessage: '',
  isLoading: false,
  isLoadingFetchList: false
};

const truckingFeeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // get trucking fee per page
    case TRUCKING_FEE_ACTIONS.GET_TRUCKING_FEE_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRUCKING_FEE_ACTIONS.GET_TRUCKING_FEE_PER_PAGE_FULFILLED: {
      let truckingFee = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data
      };
      return {
        ...state,
        truckingFee: truckingFee,
        isLoading: false
      };
    }

    case TRUCKING_FEE_ACTIONS.GET_TRUCKING_FEE_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false
      };

    // get trucking fee by id
    case TRUCKING_FEE_ACTIONS.GET_TRUCKING_FEE_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRUCKING_FEE_ACTIONS.GET_TRUCKING_FEE_BY_ID_FULFILLED: {
      let truckingFeeByID = action.payload.data.data ? action.payload.data.data : INIT_STATE.truckingFeeByID;
      return {
        ...state,
        truckingFeeByID: truckingFeeByID,
        isLoading: false
      };
    }

    case TRUCKING_FEE_ACTIONS.GET_TRUCKING_FEE_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false
      };

    // update trucking fee
    case TRUCKING_FEE_ACTIONS.UPDATE_TRUCKING_FEE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRUCKING_FEE_ACTIONS.UPDATE_TRUCKING_FEE_FULFILLED:
      return {
        ...state,
        isLoading: false
      };

    case TRUCKING_FEE_ACTIONS.UPDATE_TRUCKING_FEE_REJECTED:
      return {
        ...state,
        isLoading: false
      };

    // update adding trucking fee
    case TRUCKING_FEE_ACTIONS.UPDATE_ADDING_TRUCKING_FEE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRUCKING_FEE_ACTIONS.UPDATE_ADDING_TRUCKING_FEE_FULFILLED:
      return {
        ...state,
        isLoading: false
      };

    case TRUCKING_FEE_ACTIONS.UPDATE_ADDING_TRUCKING_FEE_REJECTED:
      return {
        ...state,
        isLoading: false
      };

    case TRUCKING_FEE_ACTIONS.SEARCH_TRUCKING_FEE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRUCKING_FEE_ACTIONS.SEARCH_TRUCKING_FEE_FULFILLED: {
      let searchTruckingFee = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data
      };
      return {
        ...state,
        truckingFee: searchTruckingFee,
        isLoading: false
      };
    }

    case TRUCKING_FEE_ACTIONS.SEARCH_TRUCKING_FEE_REJECTED:
      return {
        ...state,
        isLoading: false
      };

    case TRUCKING_FEE_ACTIONS.GET_TRUCKING_FEE_BY_CONSIGNMENT_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRUCKING_FEE_ACTIONS.GET_TRUCKING_FEE_BY_CONSIGNMENT_ID_FULFILLED: {
      let truckingFeeByConsignmentId = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data
      };
      return {
        ...state,
        truckingFee: truckingFeeByConsignmentId,
        isLoading: false
      };
    }

    case TRUCKING_FEE_ACTIONS.GET_TRUCKING_FEE_BY_CONSIGNMENT_ID_REJECTED:
      return {
        ...state,
        isLoading: false
      };

    case TRUCKING_FEE_ACTIONS.FETCH_NEWEST_TRUCKING_FEE_FULFILLED:
      return {
        ...state,
        newestFee: action.payload.data.data,
        alertMessage: 'Lấy thông tin thành công. Bạn có muốn cập nhật thông tin mới?',
        showMessage: true,
        messageType: MSG_TYPE_SUCCESS
      };

    case TRUCKING_FEE_ACTIONS.FETCH_NEWEST_TRUCKING_FEE_REJECTED: {
      const alertMessage = getErrorMessage(action, 'Không thể lấy thông tin tham khảo');
      return {
        ...state,
        alertMessage: alertMessage,
        showMessage: true,
        messageType: MSG_TYPE_ERROR
      };
    }

    case TRUCKING_FEE_ACTIONS.SEARCH_BY_FIELD_TRUCKING_FEE_PENDING:
      return {
        ...state,
        isLoadingFetchList: true
      };

    case TRUCKING_FEE_ACTIONS.SEARCH_BY_FIELD_TRUCKING_FEE_FULFILLED: {
      const data =
        action.payload.data.pagination.pageNo === 1
          ? action.payload.data.data
          : state.truckingFee.data.concat(action.payload.data.data);
      let searchByFieldTruckingFee = {
        pagination: action.payload.data.pagination,
        data: data
      };
      return {
        ...state,
        truckingFee: searchByFieldTruckingFee,
        isLoadingFetchList: false
      };
    }

    case TRUCKING_FEE_ACTIONS.SEARCH_BY_FIELD_TRUCKING_FEE_REJECTED:
      // eslint-disable-next-line no-case-declarations
      const isLoadingFetchList = action?.payload?.message === INTERRUPT_API ? true : false;
      return {
        ...state,
        isLoadingFetchList: isLoadingFetchList
      };

    case TRUCKING_FEE_ACTIONS.SEARCH_ABNORMAL_BY_FIELD_TRUCKING_FEE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRUCKING_FEE_ACTIONS.SEARCH_ABNORMAL_BY_FIELD_TRUCKING_FEE_FULFILLED: {
      let searchByFieldTruckingFee = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data
      };
      return {
        ...state,
        truckingFee: searchByFieldTruckingFee,
        isLoading: false
      };
    }

    case TRUCKING_FEE_ACTIONS.SEARCH_ABNORMAL_BY_FIELD_TRUCKING_FEE_REJECTED:
      return {
        ...state,
        isLoading: false
      };

    case TRUCKING_FEE_ACTIONS.UPDATE_DATA_TABLE: {
      let truckingFee = {
        pagination: state.truckingFee.pagination,
        data: action.payload.data
      };
      return {
        ...state,
        truckingFee: truckingFee,
        isLoading: false
      };
    }

    //updatepayment
    case TRUCKING_FEE_ACTIONS.UPDATE_PAYMENT_TRUCKING_FEE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRUCKING_FEE_ACTIONS.UPDATE_PAYMENT_TRUCKING_FEE_FULFILLED:
      return {
        ...state,
        isLoading: false
      };

    case TRUCKING_FEE_ACTIONS.UPDATE_PAYMENT_TRUCKING_FEE_REJECTED:
      return {
        ...state,
        isLoading: false
      };

    //updatepayment
    case TRUCKING_FEE_ACTIONS.UPDATE_EDITABLE_TRUCKING_FEE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRUCKING_FEE_ACTIONS.UPDATE_EDITABLE_TRUCKING_FEE_FULFILLED:
      return {
        ...state,
        isLoading: false
      };

    case TRUCKING_FEE_ACTIONS.UPDATE_EDITABLE_TRUCKING_FEE_REJECTED:
      return {
        ...state,
        isLoading: false
      };
    // update cont weight
    case TRUCKING_FEE_ACTIONS.UPDATE_CONT_WEIGHT_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRUCKING_FEE_ACTIONS.UPDATE_CONT_WEIGHT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        showMessage: true,
        alertMessage: 'Cập nhật dữ liệu thành công',
        typeMessage: 'success'
      };

    case TRUCKING_FEE_ACTIONS.UPDATE_CONT_WEIGHT_REJECTED:
      const alertMessage = getErrorMessage(action, MSG_EDIT_FAILURE);
      return {
        ...state,
        isLoading: false,
        showMessage: true,
        alertMessage,
        typeMessage: 'error'
      };

    case TRUCKING_FEE_ACTIONS.UPDATE_FULL_STANDARD_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRUCKING_FEE_ACTIONS.UPDATE_FULL_STANDARD_FULFILLED:
      return {
        ...state,
        isLoading: false
      };

    case TRUCKING_FEE_ACTIONS.UPDATE_FULL_STANDARD_REJECTED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default truckingFeeReducer;
