import {
    FETCH_SETTING_FULFILLED,
    FETCH_SETTING_PENDING,
    FETCH_SETTING_REJECTED
} from "actions/FieldSettingAction";
import { getErrorMessage } from "commons/ReducerUtils";
import {
    MSG_TYPE_ERROR
} from "const/common";

function simplifySetting(action) {
    const settings = {};
    const data = action.payload.data.data;
    if (data) {
        data.reduce((acc, element) => {
            const columnName = element.ColumnName;
            const columnValue = element.DisplayStatus;
            acc[columnName] = columnValue;
            return acc;
        }, settings);
    }
    return settings;
}

const INIT_STATE = {
    settings: {},
    originalSettings: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // get data
        case FETCH_SETTING_PENDING:
            {
                return {
                    ...state,
                    loading: true,
                    showMessage: false
                };
            }
        case FETCH_SETTING_FULFILLED:
            {
                const settings = simplifySetting(action);
                return {
                    ...state,
                    loading: false,
                    settings: settings,
                    originalSettings: action.payload.data.data ? action.payload.data.data : [],
                    showMessage: false
                };
            }
        case FETCH_SETTING_REJECTED:
            {
                let alertMessage = getErrorMessage(action, 'Không thể lấy dữ liệu setting');
                return {
                    ...state,
                    loading: false,
                    settings: INIT_STATE.settings,
                    originalSettings: INIT_STATE.originalSettings,
                    showMessage: true,
                    alertMessage: alertMessage,
                    messageType: MSG_TYPE_ERROR
                };
            }
        default:
            return state;
    }
};