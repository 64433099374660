import httpClient from 'HttpClient';
export const DRIVER_MANY_PLAN_ACTIONS = {
  GET_FIRST_DATA_TABLE_CONT: 'GET_FIRST_DATA_TABLE_CONT',
  CHANGE_ITEM_CONT: 'CHANGE_ITEM_CONT',
  CHANGE_TRANSPORT_DATE: 'CHANGE_TRANSPORT_DATE',
  CHANGE_CAR: 'CHANGE_CAR',
  RESET_CONT: 'RESET_CONT',

  GET_FIRST_DATA_TABLE_NOT_CONT: 'GET_FIRST_DATA_TABLE_NOT_CONT',
  CHANGE_ITEM_TRIP_NUMBER: 'CHANGE_ITEM_TRIP_NUMBER',
  CHANGE_TRANSPORT_DATE_NOT_CONT: 'CHANGE_TRANSPORT_DATE_NOT_CONT',
  CHANGE_CAR_NOT_CONT: 'CHANGE_CAR_NOT_CONT',
  RESET_CONT_NOT_CONT: 'RESET_CONT_NOT_CONT',

  GET_FIRST_DATA_PRODUCT_OTHER: 'GET_FIRST_DATA_PRODUCT_OTHER',
  CHANGE_INPUT_CONT_NO: 'CHANGE_INPUT_CONT_NO',
  CHANGE_TRANSPORT_DATE_PRODUCT_OTHER: 'CHANGE_TRANSPORT_DATE_PRODUCT_OTHER',
  CHANGE_CAR_PRODUCT_OTHER: 'CHANGE_CAR_PRODUCT_OTHER',
  RESET_INFO_GOOD_OTHER: 'RESET_INFO_GOOD_OTHER',

  CREATE_PLAN: 'CREATE_PLAN',
  CREATE_PLAN_PENDING: 'CREATE_PLAN_PENDING',
  CREATE_PLAN_FULFILLED: 'CREATE_PLAN_FULFILLED',
  CREATE_PLAN_REJECTED: 'CREATE_PLAN_REJECTED',

  GET_ALL_CAR: 'GET_ALL_CAR',
  GET_ALL_CAR_PENDING: 'GET_ALL_CAR_PENDING',
  GET_ALL_CAR_FULFILLED: 'GET_ALL_CAR_FULFILLED',
  GET_ALL_CAR_REJECTED: 'GET_ALL_CAR_REJECTED',

  GET_SCREEN_SETTING: 'GET_SCREEN_SETTING',
  GET_SCREEN_SETTING_PENDING: 'GET_SCREEN_SETTING_PENDING',
  GET_SCREEN_SETTING_FULFILLED: 'GET_SCREEN_SETTING_FULFILLED',
  GET_SCREEN_SETTING_REJECTED: 'GET_SCREEN_SETTING_REJECTED',

  CHANGE_CUSTOMERS: 'CHANGE_CUSTOMERS',
  CHANGE_CUSTOMERS_PENDING: 'CHANGE_CUSTOMERS_PENDING',
  CHANGE_CUSTOMERS_FULFILLED: 'CHANGE_CUSTOMERS_FULFILLED',
  CHANGE_CUSTOMERS_REJECTED: 'CHANGE_CUSTOMERS_REJECTED'
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const getFirstDataCont = () => {
  return {
    type: DRIVER_MANY_PLAN_ACTIONS.GET_FIRST_DATA_TABLE_CONT
  };
};

export const getFirstDataNotCont = () => {
  return {
    type: DRIVER_MANY_PLAN_ACTIONS.GET_FIRST_DATA_TABLE_NOT_CONT
  };
};

export const changeItemCont = (dataChanged, index, listContNotChange) => {
  const data = {
    dataChanged: dataChanged,
    index: index,
    listContNotChange: listContNotChange
  };
  return {
    type: DRIVER_MANY_PLAN_ACTIONS.CHANGE_ITEM_CONT,
    payload: data
  };
};

export const changeTripNumber = (value, index) => {
  const data = {
    value: value,
    index: index
  };
  return {
    type: DRIVER_MANY_PLAN_ACTIONS.CHANGE_ITEM_TRIP_NUMBER,
    payload: data
  };
};

export const changeTransportDate = (value) => {
  return {
    type: DRIVER_MANY_PLAN_ACTIONS.CHANGE_TRANSPORT_DATE,
    payload: value
  };
};

export const changeTransportDateNotCont = (value) => {
  return {
    type: DRIVER_MANY_PLAN_ACTIONS.CHANGE_TRANSPORT_DATE_NOT_CONT,
    payload: value
  };
};

export const resetContInfo = () => {
  return {
    type: DRIVER_MANY_PLAN_ACTIONS.RESET_CONT
  };
};

export const getAllCar = () => {
  return {
    type: DRIVER_MANY_PLAN_ACTIONS.GET_ALL_CAR,
    payload: httpClient.get('/tractortrailerinfo/getAll/trucking')
  };
};

export const changeInfoCar = (value) => {
  return {
    type: DRIVER_MANY_PLAN_ACTIONS.CHANGE_CAR,
    payload: value
  };
};

export const changeInfoCarNotCont = (value) => {
  return {
    type: DRIVER_MANY_PLAN_ACTIONS.CHANGE_CAR_NOT_CONT,
    payload: value
  };
};

export const getScreenSetting = () => {
  return {
    type: DRIVER_MANY_PLAN_ACTIONS.GET_SCREEN_SETTING,
    payload: httpClient.get('/SysFormFieldSetting/getByScreenName?screenName=DriverPlan')
  };
};

export const creatPlan = (payload) => (dispatch) => {
  return dispatch({
    type: DRIVER_MANY_PLAN_ACTIONS.CREATE_PLAN,
    payload: httpClient.post(`/TruckingPlan/createAll`, payload)
  });
};
/// doi api ke hoach dieu xe
export const creatPlanDailytrucking = (payload) => (dispatch) => {
  return dispatch({
    type: DRIVER_MANY_PLAN_ACTIONS.CREATE_PLAN,
    payload: httpClient.post(`/DailyTransportPlanInfo/createAllTruckingplan`, payload)
  });
};
// action cho hang le
export const getFirstDataProductOther = () => {
  return {
    type: DRIVER_MANY_PLAN_ACTIONS.GET_FIRST_DATA_PRODUCT_OTHER
  };
};

export const changeInfoCarProductOther = (value) => {
  return {
    type: DRIVER_MANY_PLAN_ACTIONS.CHANGE_CAR_PRODUCT_OTHER,
    payload: value
  };
};

export const changeInputContNo = (value, index) => {
  const data = {
    value: value,
    index: index
  };
  return {
    type: DRIVER_MANY_PLAN_ACTIONS.CHANGE_INPUT_CONT_NO,
    payload: data
  };
};

export const changeTransportDateProductOther = (value) => {
  return {
    type: DRIVER_MANY_PLAN_ACTIONS.CHANGE_TRANSPORT_DATE_PRODUCT_OTHER,
    payload: value
  };
};

// export const creatPlan = ( payload) => (dispatch) => {
//          return dispatch({
//            type: DOWNPAYMENT_ORDER_ACTIONS.CREATE_DOWN_PAYMENT_ORDER,
//            payload: httpClient.post(
//              `/advanceonconsignment/${consigmentId}/create`,
//              payload
//            ),
//          });
//        };

// update chủ xe

export const changeCustomers = (dataChanged) => {
  return {
    type: DRIVER_MANY_PLAN_ACTIONS.CHANGE_CUSTOMERS,
    payload: dataChanged
  };
};
