import {
    CHANGE_PASSWORD_FULFILLED,
    CHANGE_PASSWORD_PENDING,
    CHANGE_PASSWORD_REJECTED
} from "actions/changePasswordAction";
import { getErrorMessage } from "commons/ReducerUtils";
import { MSG_TYPE_ERROR, MSG_TYPE_SUCCESS } from "const/common";

const INIT_STATE = {
    loading: false,
    showMessage: false,
    alertMessage: '',
    messageType: ""
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // get data
        case CHANGE_PASSWORD_PENDING:
        {
            return {
                ...state,
                loading: true
            };
        }
        case CHANGE_PASSWORD_FULFILLED:
        {
            return {
                ...state,
                loading: false,
                showMessage: true,
                alertMessage: 'Thay đổi mật khẩu thành công.',
                messageType: MSG_TYPE_SUCCESS
            };
        }
        case CHANGE_PASSWORD_REJECTED:
        {
            let alertMessage = getErrorMessage(action, "Lỗi. Không thể đổi mật khẩu");
            return {
                ...state,
                loading: false,
                showMessage: true,
                alertMessage: alertMessage,
                messageType: MSG_TYPE_ERROR
            };
        }
        default:
            return state;
    }
};
