import {
  FETCH_SIDEBAR_NAV_FULFILLED,
  FETCH_SIDEBAR_NAV_PENDING,
  FETCH_SIDEBAR_NAV_REJECTED,
  RESET_SIDEBAR_NAV
} from 'actions/navAction';
import { setLocalStorageItem } from 'commons/localStorageUtils';
import { NAV_MENU } from 'const/localStorageKey';

const DEFAULT_SIDEBAR_NAV = [];
const INIT_STATE = {
  loading: false,
  // nav: _nav,
  nav: DEFAULT_SIDEBAR_NAV,
  alertMessage: '',
  showMessage: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // get data
    case FETCH_SIDEBAR_NAV_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }

    case FETCH_SIDEBAR_NAV_FULFILLED: {
      setLocalStorageItem(NAV_MENU.NAV_S, JSON.stringify(action.payload?.data?.data));
      return {
        ...state,
        loading: false,
        nav: action.payload.data.data
        // nav: INIT_STATE.nav,
      };
    }

    case FETCH_SIDEBAR_NAV_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: 'Không thể lấy danh sách chức năng'
      };
    }

    case RESET_SIDEBAR_NAV: {
      return {
        ...state,
        nav: DEFAULT_SIDEBAR_NAV
      };
    }

    default:
      return state;
  }
};
