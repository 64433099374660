import { DEBT2_TYPE } from 'actions/Debt2';

const INIT_STATE = {
  data: [],
  dataIncurred: [],
  dataDebtsIncurred: [],
  dataAmountIncurred: [],
  loading: false,
  typeMessage: 'success',
  message: '',
  isShowMessage: false,
  debtsPaymentData: [],
  banks: [],
  bankAccount: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DEBT2_TYPE.SEARCH_DEBT2_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case DEBT2_TYPE.SEARCH_DEBT2_REJECTED: {
      return {
        ...state,
        data: action?.payload?.data?.data ?? [],
        loading: false
      };
    }
    case DEBT2_TYPE.SEARCH_DEBT2_FULFILLED: {
      return {
        ...state,
        data: action?.payload?.data?.data ?? [],
        loading: false
      };
    }
    case DEBT2_TYPE.LOADDING_DEBT_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case DEBT2_TYPE.LOADDING_DEBT_REJECTED: {
      return {
        ...state,
        loading: false
      };
    }
    case DEBT2_TYPE.LOADDING_DEBT_FULFILLED: {
      return {
        ...state,
        loading: false
      };
    }
    case DEBT2_TYPE.GET_INCURRED_ALL_DEBT_FULFILLED: {
      return {
        ...state,
        dataIncurred: action?.payload?.data?.data ?? []
      };
    }

    case DEBT2_TYPE.GET_AMOUNT_INCURRED_BY_ID_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case DEBT2_TYPE.GET_AMOUNT_INCURRED_BY_ID_FULFILLED: {
      return {
        ...state,
        dataAmountIncurred: action?.payload?.data?.data ?? [],
        loading: false
      };
    }
    case DEBT2_TYPE.GET_AMOUNT_INCURRED_BY_ID_REJECTED: {
      return {
        ...state,
        loading: false
      };
    }

    case DEBT2_TYPE.GET_DEBTS_INCURREND_BY_ID_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case DEBT2_TYPE.GET_DEBTS_INCURREND_BY_ID_FULFILLED: {
      return {
        ...state,
        dataDebtsIncurred: action?.payload?.data?.data ?? [],
        loading: false
      };
    }
    case DEBT2_TYPE.GET_DEBTS_INCURREND_BY_ID_REJECTED: {
      return {
        ...state,
        loading: false
      };
    }

    case DEBT2_TYPE.GET_DEBTS_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case DEBT2_TYPE.GET_DEBTS_PAYMENT_FULFILLED: {
      return {
        ...state,
        loading: false,
        debtsPaymentData: action?.payload?.data?.data ?? []
      };
    }
    case DEBT2_TYPE.GET_DEBTS_PAYMENT_REJECTED: {
      return {
        ...state,
        loading: false
      };
    }
    case DEBT2_TYPE.CREATE_DEBT_PAYMENT_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case DEBT2_TYPE.CREATE_DEBT_PAYMENT_FULFILLED: {
      return {
        ...state,
        typeMessage: 'success',
        message: 'Thêm chi phí thành công',
        isShowMessage: true
      };
    }
    case DEBT2_TYPE.CREATE_DEBT_PAYMENT_REJECTED: {
      return {
        ...state,
        typeMessage: 'error',
        message: 'Thêm chi phí thất bại',
        isShowMessage: true
      };
    }
    case DEBT2_TYPE.GET_DEBT_FUND_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case DEBT2_TYPE.GET_DEBT_FUND_FULFILLED: {
      return {
        ...state,
        data: action?.payload?.data?.data ?? [],
        loading: false
      };
    }
    case DEBT2_TYPE.GET_DEBT_FUND_REJECTED: {
      return {
        ...state,
        loading: false
      };
    }
    case DEBT2_TYPE.GET_DEBT_BANK_INFO_FOR_FUNDS_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case DEBT2_TYPE.GET_DEBT_BANK_INFO_FOR_FUNDS_FULFILLED: {
      return {
        ...state,
        data: action?.payload?.data?.data ?? [],
        loading: false
      };
    }
    case DEBT2_TYPE.GET_DEBT_BANK_INFO_FOR_FUNDS_REJECTED: {
      return {
        ...state,
        loading: false
      };
    }

    case DEBT2_TYPE.GET_DEBT_ALL_BANK_FULFILLED: {
      return {
        ...state,
        banks: action?.payload?.data?.data ?? []
      };
    }

    case DEBT2_TYPE.GET_DEBT_BANK_ACCOUNT_FULFILLED: {
      return {
        ...state,
        bankAccount: action?.payload?.data?.data ?? []
      };
    }

    default:
      return state;
  }
};
