import httpClient from 'HttpClient';
// define const actions
export const CONSIGNMENT_REPORT_ACTIONS = {
  GET_CONSIGNMENT_REPORT_PER_PAGE: 'GET_CONSIGNMENT_REPORT_PER_PAGE',
  GET_CONSIGNMENT_REPORT_PER_PAGE_PENDING: 'GET_CONSIGNMENT_REPORT_PER_PAGE_PENDING',
  GET_CONSIGNMENT_REPORT_PER_PAGE_FULFILLED: 'GET_CONSIGNMENT_REPORT_PER_PAGE_FULFILLED',
  GET_CONSIGNMENT_REPORT_PER_PAGE_REJECTED: 'GET_CONSIGNMENT_REPORT_PER_PAGE_REJECTED',

  GET_CONSIGNMENT_BY_MONTH: 'GET_CONSIGNMENT_BY_MONTH',
  GET_CONSIGNMENT_BY_MONTH_PENDING: 'GET_CONSIGNMENT_BY_MONTH_PENDING',
  GET_CONSIGNMENT_BY_MONTH_FULFILLED: 'GET_CONSIGNMENT_BY_MONTH_FULFILLED',
  GET_CONSIGNMENT_BY_MONTH_REJECTED: 'GET_CONSIGNMENT_BY_MONTH_REJECTED',

  GET_CONSIGNMENT_BY_DAY: 'GET_CONSIGNMENT_BY_DAY',
  GET_CONSIGNMENT_BY_DAY_PENDING: 'GET_CONSIGNMENT_BY_DAY_PENDING',
  GET_CONSIGNMENT_BY_DAY_FULFILLED: 'GET_CONSIGNMENT_BY_DAY_FULFILLED',
  GET_CONSIGNMENT_BY_DAY_REJECTED: 'GET_CONSIGNMENT_BY_DAY_REJECTED'
};

// define actions
export const getDataPerPage = (params) => (dispatch) => {
  return dispatch({
    type: CONSIGNMENT_REPORT_ACTIONS.GET_CONSIGNMENT_REPORT_PER_PAGE,
    payload: httpClient.get(`/consignment/report`, { params })
  });
};
export const getDataPerPageAll = (params) => (dispatch) => {
  return dispatch({
    // type: CONSIGNMENT_REPORT_ACTIONS.GET_CONSIGNMENT_REPORT_PER_PAGE,
    payload: httpClient.get(`/consignment/report`, { params })
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const getConsignmentByMonth = (monthYear) => (dispatch) => {
  return dispatch({
    type: CONSIGNMENT_REPORT_ACTIONS.GET_CONSIGNMENT_BY_MONTH,
    payload: httpClient.get(`/Consignment/GetConsignmentBaseOnTruckingPlanByMonth?MonthYear=${monthYear}`)
  });
};

export const getConsignmentByDay = (fromDate, toDate) => (dispatch) => {
  return dispatch({
    type: CONSIGNMENT_REPORT_ACTIONS.GET_CONSIGNMENT_BY_DAY,
    payload: httpClient.get(`Consignment/GetConsignmentHasTruckingPlan?fromdate=${fromDate}&todate=${toDate}`)
  });
};
