import { MAP_ACTIONS } from 'actions/map';
import { MSG_TYPE_ERROR } from 'const/common';

const INIT_STATE = {
  listPosition: [],
  listCars: [],
  isLoading: false,
  typeMessage: '',
  message: '',
  pagination: {},
  isCloseModal: false,
  isSearch: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MAP_ACTIONS.GET_CARS_LOCATION_PENDING:
      return {
        ...state,
        isLoading: true,
        isSearch: false
      };

    case MAP_ACTIONS.GET_CARS_LOCATION_FULFILLED:
      return {
        ...state,
        listPosition: action.payload.data,
        isLoading: false,
        isSearch: false
      };

    case MAP_ACTIONS.GET_CARS_LOCATION_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        msg: MSG_TYPE_ERROR,
        isSearch: false
      };

    case MAP_ACTIONS.GET_CARS_INFO_PENDING:
      return {
        ...state,
        isLoading: true,
        isSearch: false
      };

    case MAP_ACTIONS.GET_CARS_INFO_FULFILLED:
      return {
        ...state,
        listCars: action.payload.data.data,
        isLoading: false,
        isSearch: false
      };

    case MAP_ACTIONS.GET_CARS_INFO_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        msg: MSG_TYPE_ERROR,
        isSearch: false
      };

    default:
      return state;
  }
};
