import { INPUT_FUEL_MANAGEMENT_ACTIONS } from 'actions/inputFuelManagement';

const INIT_STATE = {
  listPerPage: [],
  totalOilRemaining: '',
  isLoading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  pagination: {},
  isCloseModal: false,
  isSearch: false
};

const inputFuelManagementReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // get fill up fuel management per page
    case INPUT_FUEL_MANAGEMENT_ACTIONS.GET_INPUT_FUEL_MANAGEMENT_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case INPUT_FUEL_MANAGEMENT_ACTIONS.GET_INPUT_FUEL_MANAGEMENT_PER_PAGE_FULFILLED:
      return {
        ...state,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isShowMessage: false,
        isLoading: false
      };
    case INPUT_FUEL_MANAGEMENT_ACTIONS.GET_INPUT_FUEL_MANAGEMENT_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isShowMessage: true,
        message: 'Đã xảy ra lỗi'
      };

    case INPUT_FUEL_MANAGEMENT_ACTIONS.UPDATE_INPUT_FUEL_MANAGEMENT_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case INPUT_FUEL_MANAGEMENT_ACTIONS.UPDATE_INPUT_FUEL_MANAGEMENT_FULFILLED:
      return {
        ...state,
        typeMessage: 'success',
        isShowMessage: true,
        message: 'Cập nhật thành công',
        isLoading: false
      };
    case INPUT_FUEL_MANAGEMENT_ACTIONS.UPDATE_INPUT_FUEL_MANAGEMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isShowMessage: true,
        message: 'Đã xảy ra lỗi'
      };

    case INPUT_FUEL_MANAGEMENT_ACTIONS.DELETE_INPUT_FUEL_MANAGEMENT_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case INPUT_FUEL_MANAGEMENT_ACTIONS.DELETE_INPUT_FUEL_MANAGEMENT_FULFILLED:
      return {
        ...state,
        typeMessage: 'success',
        isShowMessage: true,
        message: 'Xóa thành công',
        isLoading: false
      };
    case INPUT_FUEL_MANAGEMENT_ACTIONS.DELETE_INPUT_FUEL_MANAGEMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isShowMessage: true,
        message: 'Đã xảy ra lỗi'
      };

    case INPUT_FUEL_MANAGEMENT_ACTIONS.CREATE_INPUT_FUEL_MANAGEMENT_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case INPUT_FUEL_MANAGEMENT_ACTIONS.CREATE_INPUT_FUEL_MANAGEMENT_FULFILLED:
      return {
        ...state,
        typeMessage: 'success',
        isShowMessage: true,
        message: 'Tạo mới thành công',
        isLoading: false
      };
    case INPUT_FUEL_MANAGEMENT_ACTIONS.CREATE_INPUT_FUEL_MANAGEMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isShowMessage: true,
        message: 'Đã xảy ra lỗi'
      };

    case INPUT_FUEL_MANAGEMENT_ACTIONS.SEARCH_INPUT_FUEL_MANAGEMENT_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case INPUT_FUEL_MANAGEMENT_ACTIONS.SEARCH_INPUT_FUEL_MANAGEMENT_PER_PAGE_FULFILLED:
      return {
        ...state,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isShowMessage: false,
        isLoading: false
      };
    case INPUT_FUEL_MANAGEMENT_ACTIONS.SEARCH_INPUT_FUEL_MANAGEMENT_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isShowMessage: true,
        message: 'Đã xảy ra lỗi'
      };

    case INPUT_FUEL_MANAGEMENT_ACTIONS.GET_TOTAL_OIL_REMAINING_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case INPUT_FUEL_MANAGEMENT_ACTIONS.GET_TOTAL_OIL_REMAINING_FULFILLED:
      return {
        ...state,
        totalOilRemaining: action.payload.data.data,
        isShowMessage: false,
        isLoading: false
      };
    case INPUT_FUEL_MANAGEMENT_ACTIONS.GET_TOTAL_OIL_REMAINING_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isShowMessage: true,
        message: 'Không thể lấy tổng dầu'
      };

    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }

    default:
      return state;
  }
};

export default inputFuelManagementReducer;
