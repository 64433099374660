import api from 'HttpClient/index';

export const getTranportationPlanPerPage = (pageNo, pageSize) => {
  return api.get(`/ConsignmentContainer/paging/page?Page=${pageNo}&PageSize=${pageSize}`);
};

export const getTranportationPlanByID = (id) => {
  return api.get(`/ConsignmentContainer/getById/${id}`);
};

export const searchTranportationPlan = (payload) => {
  const params = {
    ...payload
  };
  // return api.get(
  //     `/ConsignmentContainer/paging/page?searchValue=&page=${payload.pageNo}&pageSize=${payload.pageSize}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&consignmentId=&status=${payload.statusId}&consignmentCode=${payload.consignmentCode}&contNumber=${payload.contNumber}`
  // );,
  return api.get(`/ConsignmentContainer/paging/page`, { params: params });
};

export const getAllTransportationPlan = () => {
  return api.get(`/ConsignmentContainer/getAll`);
};

export const getTransportationPlanListByConsignmentID = (id) => {
  return api.get(`/ConsignmentContainer/getByConsignmentId/?consignmentId=${id}`);
};

export const getTransportationPlanListByConsignmentIDPerPage = (id, pageNo, pageSize) => {
  return api.get(
    `/ConsignmentContainer/paging/page/consignment?consignmentId=${id}&Page=${pageNo}&PageSize=${pageSize}`
  );
};

export const createTransportationPlan = (payload) => {
  return api.post(`/ConsignmentContainer/create`, payload);
};

export const createListContainer = (payload) => {
  return api.post('/ConsignmentContainer/createMany', payload.containers);
};

export const createTransportationPlanList = (payload) => {
  return api.post(`/ConsignmentContainer/createAll`, payload);
};

export const updateTransportationPlanList = (payload) => {
  return api.put(`/ConsignmentContainer/update`, payload);
};

export const deleteTransportationPlanList = (id) => {
  return api.delete(`/ConsignmentContainer/delete/${id}`);
};
export const updateContDetail = (payload) => {
  return api.put(`/ConsignmentContainer/updateStatus`, payload);
};
export const getImageConsignment = (id) => {
  return api.get(`/Consignment/getResource/${id}`);
};

export const getGetContSerialListByConsignmentID = (id) => {
  return api.get(`ConsignmentContainer/HaveNotSeaTransportYet/${id}`);
};
