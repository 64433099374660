export const columnHeader = [
  {
    text: "STT",
    width: { wpx: "auto" },
    style: "title_table",
  },
  {
    text: "Ngày trả hàng",
    width: { wpx: "auto" },
    style: "title_table",
  },
  {
    text: "Mã lô",
    width: { wpx: "auto" },
    style: "title_table",
  },
  {
    text: "Địa chỉ nhận hàng",
    width: { wpx: "auto" },
    style: "title_table",
  },
  {
    text: "Số Cont",
    width: { wpx: "auto" },
    style: "title_table",
  },
  {
    text: "Loại Cont",
    width: { wpx: "auto" },
    style: "title_table",
  },
  //   {
  //     text: "Số Cont 2",
  //     width: { wpx: "auto" },
  //     style: "title_table",
  //   },
  //   {
  //     text: "Loại Cont 2",
  //     width: { wpx: "auto" },
  //     style: "title_table",
  //   },
  {
    text: "Nơi hạ vỏ",
    width: { wpx: "auto" },
    style: "title_table",
  },
  {
    text: "Chủ xe",
    width: { wpx: "auto" },
    style: "title_table",
  },
  {
    text: "Số xe",
    width: { wpx: "auto" },
    style: "title_table",
  },
  {
    text: "Tên lái xe",
    width: { wpx: "auto" },
    style: "title_table",
  },
  {
    text: "Ghi chú",
    width: { wpx: "auto" },
    style: "title_table",
  },
];

export const styleExportFile = {
  header: {
    fontSize: 13,
    bold: true,
    margin: [0, 50, 5, 10],
    alignment: "center",
  },
  subheader: {
    fontSize: 14,
    bold: true,
    margin: [100, 0, 0, 5],
    alignment: "left",
  },
  tableExample: {
    margin: [0, 5, 0, 15],
  },
  tableOpacityExample: {
    margin: [0, 5, 0, 15],
    fillColor: "blue",
    fillOpacity: 0.3,
  },
  tableHeader: {
    bold: true,
    fontSize: 13,
    color: "black",
  },
  author: {
    italics: true,
    alignment: "right",
  },
  title: {
    fontSize: 16,
    bold: true,
    margin: [100, 0, 0, 5],
    alignment: "left",
  },
  fee: {
    bold: true,
  },
  title_table: {
    bold: true,
  },
  total_field: {
    italics: true,
    bold: true,
    alignment: "right",
  },
  total_field_title: {
    italics: true,
    bold: true,
  },
  fomat_currency: {
    alignment: "right",
  },
};

export const columns = [
  {
    index: "no",
  },
  {
    index: "DeliveryDate",
  },
  {
    index: "Consignment",
  },
  {
    index: "ShipmentAddress",
  },
  {
    index: "ContSerialNo1",
  },
  {
    index: "ContCategory1",
  },
  //   {
  //     index: "ContSerialNo2",
  //   },
  //   {
  //     index: "ContCategory2",
  //   },
  {
    index: "ContEndAddress",
  },
  {
    index: "TransportCompany",
  },
  {
    index: "TractorTrailer",
  },
  {
    index: "Driver",
  },
  {
    index: "Note",
  },
];
