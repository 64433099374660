import httpClient from "HttpClient";
export const getOilNormPerPage = (pageNo, pageSize) => {
  return httpClient.get(
    `/standardoilInfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`
  );
};

export const deleteItem = (id) => {
  return httpClient.delete(`/standardoilInfo/deleteById/${id}`);
};
