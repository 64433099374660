import { SPAREPART_DETAIL_MG_ACTIONS } from 'actions/repair/sparepartDetail';
import { DEFAULT_PAGE_SIZE } from 'const/common';

const INIT_STATE = {
  listPerPage: [],
  spareparts: [],
  detailByReceivings: [],
  positionTypes: [],
  sparepartDetails: [],
  sparepartInfoDefault: {},
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE },
  isSearch: false,
  isCloseModal: false,
  reload: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SPAREPART_DETAIL_MG_ACTIONS.GET_SPAREPART_DETAIL_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.GET_SPAREPART_DETAIL_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.GET_SPAREPART_DETAIL_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        reload: false
      };
    }

    case SPAREPART_DETAIL_MG_ACTIONS.GET_SPAREPART_BY_RECEIVINGID_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.GET_SPAREPART_BY_RECEIVINGID_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        detailByReceivings: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.GET_SPAREPART_BY_RECEIVINGID_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        reload: false
      };
    }

    case SPAREPART_DETAIL_MG_ACTIONS.UPDATE_SHOW_ALERT: {
      return {
        ...state,
        isShowMessage: false
      };
    }

    case SPAREPART_DETAIL_MG_ACTIONS.CREATE_DATA_SPAREPART_DETAIL_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.CREATE_DATA_SPAREPART_DETAIL_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isCloseModal: true,
        reload: true
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.CREATE_DATA_SPAREPART_DETAIL_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message:
          action.payload.response.data.status === 400
            ? action.payload.response.data.message
            : 'Thêm mới dữ liệu thất bại !',
        isCloseModal: false,
        reload: true
      };
    }

    case SPAREPART_DETAIL_MG_ACTIONS.UPDATE_DATA_SPAREPART_DETAIL_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.UPDATE_DATA_SPAREPART_DETAIL_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công !',
        isCloseModal: true,
        reload: true
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.UPDATE_DATA_SPAREPART_DETAIL_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Cập nhật dữ liệu thất bại !',
        isCloseModal: false,
        reload: false
      };
    }

    case SPAREPART_DETAIL_MG_ACTIONS.DELETE_DATA_SPAREPART_DETAIL_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.DELETE_DATA_SPAREPART_DETAIL_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'success',
        message: 'Xóa dữ liệu thành công !',
        isCloseModal: true,
        reload: true
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.DELETE_DATA_SPAREPART_DETAIL_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Xóa dữ liệu thất bại !',
        isCloseModal: false,
        reload: false
      };
    }

    case SPAREPART_DETAIL_MG_ACTIONS.GET_SPAREPART_DETAIL_BY_SPAREPART_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.GET_SPAREPART_DETAIL_BY_SPAREPART_FULFILLED: {
      return {
        ...state,
        loading: false,
        spareparts: action.payload.data.data,
        isSearch: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.GET_SPAREPART_DETAIL_BY_SPAREPART_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        reload: false
      };
    }

    case SPAREPART_DETAIL_MG_ACTIONS.GET_POSITION_TYPE_REPAIR_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.GET_POSITION_TYPE_REPAIR_FULFILLED: {
      return {
        ...state,
        loading: false,
        positionTypes: action.payload.data.data,
        isSearch: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.GET_POSITION_TYPE_REPAIR_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        reload: false
      };
    }

    case SPAREPART_DETAIL_MG_ACTIONS.GET_DETAIL_BY_PROVIDER_AND_SPAREPART_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.GET_DETAIL_BY_PROVIDER_AND_SPAREPART_FULFILLED: {
      return {
        ...state,
        loading: false,
        sparepartDetails: action.payload.data.data,
        isSearch: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.GET_DETAIL_BY_PROVIDER_AND_SPAREPART_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        reload: false
      };
    }

    case SPAREPART_DETAIL_MG_ACTIONS.GET_INFO_SPAREPART_DEFAULT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.GET_INFO_SPAREPART_DEFAULT_FULFILLED: {
      return {
        ...state,
        loading: false,
        sparepartInfoDefault: action.payload.data.data,
        isSearch: false,
        isCloseModal: false,
        reload: false
      };
    }
    case SPAREPART_DETAIL_MG_ACTIONS.GET_INFO_SPAREPART_DEFAULT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        reload: false
      };
    }

    case SPAREPART_DETAIL_MG_ACTIONS.RESET_SPAREPART_DETAIL: {
      return {
        ...state,
        listPerPage: []
      };
    }

    default:
      return state;
  }
};
