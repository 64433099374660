import { WAREHOUSE_EXPORT_ACTIONS } from 'actions/warehouseExport';
import { getErrorMessage } from 'commons/ReducerUtils';

const INIT_STATE = {
  listPerPage: [],
  listAllStore: [],
  orderDetail: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  pagination: {},
  isCloseModal: false,
  isSearch: false,
  billNotes: [],
  listSparepartDetail: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case WAREHOUSE_EXPORT_ACTIONS.GET_WAREHOUSE_EXPORT_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case WAREHOUSE_EXPORT_ACTIONS.GET_WAREHOUSE_EXPORT_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false
      };
    }
    case WAREHOUSE_EXPORT_ACTIONS.GET_WAREHOUSE_EXPORT_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        isCloseModal: false,
        typeMessage: 'error',
        message:
          action.payload && action.payload.data && action.payload.data.message
            ? action.payload.data.message
            : 'Không thể lấy được dữ liệu !'
      };
    }

    case WAREHOUSE_EXPORT_ACTIONS.GET_ALL_WAREHOUSE_STORE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case WAREHOUSE_EXPORT_ACTIONS.GET_ALL_WAREHOUSE_STORE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listAllStore: action.payload.data.data,
        isSearch: false
      };
    }
    case WAREHOUSE_EXPORT_ACTIONS.GET_ALL_WAREHOUSE_STORE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        isCloseModal: false,
        typeMessage: 'error',
        message:
          action.payload && action.payload.data && action.payload.data.message
            ? action.payload.data.message
            : 'Không thể lấy được dữ liệu kho hàng!'
      };
    }

    case WAREHOUSE_EXPORT_ACTIONS.GET_BY_ORDER_ID_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case WAREHOUSE_EXPORT_ACTIONS.GET_BY_ORDER_ID_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        orderDetail: action.payload.data.data
      };
    }
    case WAREHOUSE_EXPORT_ACTIONS.GET_BY_ORDER_ID_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        isCloseModal: false,
        typeMessage: 'error',
        message:
          action.payload && action.payload.data && action.payload.data.message
            ? action.payload.data.message
            : 'Không thể lấy được dữ liệu !'
      };
    }

    case WAREHOUSE_EXPORT_ACTIONS.CREATE_WAREHOUSE_EXPORT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case WAREHOUSE_EXPORT_ACTIONS.CREATE_WAREHOUSE_EXPORT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        isCloseModal: false,
        typeMessage: 'info',
        isSearch: false,
        message: 'Tạo dữ liệu thành công'
      };
    }
    case WAREHOUSE_EXPORT_ACTIONS.CREATE_WAREHOUSE_EXPORT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        isCloseModal: false,
        typeMessage: 'error',
        message: getErrorMessage(action, 'Không thể tạo dữ liệu xuất kho !')
      };
    }

    case WAREHOUSE_EXPORT_ACTIONS.UPDATE_WAREHOUSE_EXPORT_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case WAREHOUSE_EXPORT_ACTIONS.UPDATE_WAREHOUSE_EXPORT_FULFILLED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        isCloseModal: false,
        typeMessage: 'info',
        isSearch: false,
        message: 'Cập nhật dữ liệu thành công'
      };
    }
    case WAREHOUSE_EXPORT_ACTIONS.UPDATE_WAREHOUSE_EXPORT_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        isCloseModal: false,
        typeMessage: 'error',
        message: action.payload?.response?.data?.message
          ? action.payload.response.data.message
          : 'Không thể cập nhật dữ liệu !'
      };
    }

    case WAREHOUSE_EXPORT_ACTIONS.GET_BILL_NOTE_BY_SPAREPART_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case WAREHOUSE_EXPORT_ACTIONS.GET_BILL_NOTE_BY_SPAREPART_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: false,
        billNotes: action.payload.data.data
      };
    }
    case WAREHOUSE_EXPORT_ACTIONS.GET_BILL_NOTE_BY_SPAREPART_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        isCloseModal: false,
        typeMessage: 'error',
        message:
          action.payload && action.payload.data && action.payload.data.message
            ? action.payload.data.message
            : 'Không thể lấy được dữ liệu !'
      };
    }

    case WAREHOUSE_EXPORT_ACTIONS.SEARCH_BY_SPAREPART_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case WAREHOUSE_EXPORT_ACTIONS.SEARCH_BY_SPAREPART_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination
      };
    }
    case WAREHOUSE_EXPORT_ACTIONS.SEARCH_BY_SPAREPART_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        isCloseModal: false,
        typeMessage: 'error',
        message:
          action.payload && action.payload.data && action.payload.data.message
            ? action.payload.data.message
            : 'Không thể lấy được dữ liệu !'
      };
    }

    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }

    case WAREHOUSE_EXPORT_ACTIONS.GET_ALL_SPAREPARTDETAIL_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case WAREHOUSE_EXPORT_ACTIONS.GET_ALL_SPAREPARTDETAIL_FULFILLED: {
      return {
        ...state,
        loading: false,
        listSparepartDetail: action.payload.data.data
      };
    }
    case WAREHOUSE_EXPORT_ACTIONS.GET_ALL_SPAREPARTDETAIL_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        isCloseModal: false,
        typeMessage: 'error',
        message:
          action.payload && action.payload.data && action.payload.data.message
            ? action.payload.data.message
            : 'Không thể lấy được dữ liệu !'
      };
    }
    case WAREHOUSE_EXPORT_ACTIONS.DELETE_WAREHOUSE_EXPORT_PENDING:
      return {
        ...state,
        isLoading: true,
        isShowMessage: false
      };

    case WAREHOUSE_EXPORT_ACTIONS.DELETE_WAREHOUSE_EXPORT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isShowMessage: true,
        message: 'Xóa dữ liệu thành công',
        typeMessage: 'success'
      };

    case WAREHOUSE_EXPORT_ACTIONS.DELETE_WAREHOUSE_EXPORT_REJECTED:
      return {
        ...state,
        isLoading: false,
        isShowMessage: true,
        message: getErrorMessage(action,"Xóa dữ liệu thất bại"),
        typeMessage: 'error'
      };

    default:
      return state;
  }
};
