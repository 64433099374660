import api from 'HttpClient';

export const getAllDepartment = () => {
  return api.get(`/departmentInfo/getall`);
};

export const getDepartmentWithTractorTrailer = () => {
  return api.get(`/DepartmentInfo/getWithTractorTrailer`);
};

export const getVehicleByDepartment = (id) => {
  // return api.get(`/tractortrailerinfo/getTractorTrailerByDepartmentId?departmentId=${id}`)
  return api.get(`/tractortrailerinfo/getTractorTrailerByDepartmentId?departmentId=${id}`);
};

export const getPaymentCode = (id) => {
  // return api.get(`/tractortrailerinfo/getTractorTrailerByDepartmentId?departmentId=${id}`)
  return api.get(`/AdvancePaymentManaging/getByEmpployeeId/${id}`);
};
