import api from 'HttpClient';
export const CUSTOMER_SETTING = 'CUSTOMER_SETTING';
export const CUSTOMER_SETTING_PENDING = 'CUSTOMER_SETTING_PENDING';
export const CUSTOMER_SETTING_FULFILLED = 'CUSTOMER_SETTING_FULFILLED';
export const CUSTOMER_SETTING_REJECTED = 'CUSTOMER_SETTING_REJECTED';
export const customerSetting = (params) => (dispatch) => {
  return dispatch({
    type: CUSTOMER_SETTING,
    payload: api.get('/HIstory/Search', { params })
  });
};
