import {
  FETCH_WARNING_CARS_FULFILLED,
  FETCH_WARNING_CARS_PENDING,
  FETCH_WARNING_CARS_REJECTED,
  FETCH_WARNING_REPAIRING_PARTS_FULFILLED,
  FETCH_WARNING_REPAIRING_PARTS_PENDING,
  FETCH_WARNING_REPAIRING_PARTS_REJECTED,
  GET_FUEL_USAGE_REPORT_FULFILLED,
  GET_LATEST_CONSIGNMENT_AND_TRUCKING_FULFILLED,
  GET_LATEST_CONSIGNMENT_AND_TRUCKING_PENDING,
  GET_LATEST_CONSIGNMENT_AND_TRUCKING_REJECTED,
  GET_PERMISSIONS_FULFILLED,
  GET_REAL_TIME_TRACTRORTRAILER_FULFILLED,
  GET_REPORT_PAYMENT_FULFILLED,
  GET_REVENUE_BY_DAY_FULFILLED,
  GET_SEMI_TRAILER_WEIGHT_FULFILLED,
  GET_TRUCKING_PLAN_GROUPED_FULFILLED
} from 'actions/dashboardAction';
import { getErrorMessage } from 'commons/ReducerUtils';
import { DEFAULT_PAGE_SIZE } from 'const/common';

const INIT_STATE = {
  latestConsignmentTruckingDatasets: [],
  warningCars: [],
  warningCarsPagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
  warningCarsLoading: false,
  warningRepairingParts: [],
  warningRepairingPartsPagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
  warningRepairingPartsLoading: false,
  alertMessage: '',
  RealTimeTractorTrailer: [],
  TruckingPlanGrouped: [],
  RevenueByDay: [],
  FuelUsageReport: [],
  ReportPayment: [],
  SemiTrailerWeight: [],
  Permissions: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_WARNING_CARS_PENDING: {
      return {
        ...state,
        warningCarsLoading: true
      };
    }
    case FETCH_WARNING_CARS_FULFILLED: {
      return {
        ...state,
        warningCars: action.payload.data.data,
        warningCarsPagination: action.payload.data.pagination,
        warningCarsLoading: false
      };
    }
    case FETCH_WARNING_CARS_REJECTED: {
      return {
        ...state,
        warningCarsLoading: false,
        alertMessage: getErrorMessage(action)
      };
    }

    case FETCH_WARNING_REPAIRING_PARTS_PENDING: {
      return {
        ...state,
        warningRepairingPartsLoading: true
      };
    }
    case FETCH_WARNING_REPAIRING_PARTS_FULFILLED: {
      return {
        ...state,
        warningRepairingParts: action.payload.data.data,
        warningRepairingPartsPagination: action.payload.data.pagination,
        warningRepairingPartsLoading: false
      };
    }
    case FETCH_WARNING_REPAIRING_PARTS_REJECTED: {
      return {
        ...state,
        warningRepairingPartsLoading: false,
        alertMessage: getErrorMessage(action)
      };
    }

    case GET_LATEST_CONSIGNMENT_AND_TRUCKING_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_LATEST_CONSIGNMENT_AND_TRUCKING_FULFILLED: {
      return {
        ...state,
        latestConsignmentTruckingDatasets: action.payload.data.data,
        loading: false
      };
    }
    case GET_LATEST_CONSIGNMENT_AND_TRUCKING_REJECTED: {
      return {
        ...state,
        loading: false,
        alertMessage: getErrorMessage(action)
      };
    }
    case GET_REAL_TIME_TRACTRORTRAILER_FULFILLED: {
      if (action.payload.data.status === 200) {
        return {
          ...state,
          RealTimeTractorTrailer: action.payload.data.data
        };
      } else {
        return { ...state };
      }
    }
    case GET_TRUCKING_PLAN_GROUPED_FULFILLED: {
      if (action.payload.data.status === 200) {
        return {
          ...state,
          TruckingPlanGrouped: action.payload.data.data
        };
      } else {
        return { ...state };
      }
    }
    case GET_REVENUE_BY_DAY_FULFILLED: {
      if (action.payload.data.status === 200) {
        return {
          ...state,
          RevenueByDay: action.payload.data.data
        };
      } else {
        return { ...state };
      }
    }
    case GET_REPORT_PAYMENT_FULFILLED: {
      if (action.payload.data.status === 200) {
        return {
          ...state,
          ReportPayment: action.payload.data.data
        };
      } else {
        return { ...state };
      }
    }
    case GET_FUEL_USAGE_REPORT_FULFILLED: {
      if (action.payload.data.status === 200) {
        return {
          ...state,
          FuelUsageReport: action.payload.data.data
        };
      } else {
        return { ...state };
      }
    }
    case GET_SEMI_TRAILER_WEIGHT_FULFILLED: {
      if (action.payload.data.status === 200) {
        return {
          ...state,
          SemiTrailerWeight: action.payload.data.data
        };
      } else {
        return { ...state };
      }
    }
    case GET_PERMISSIONS_FULFILLED: {
      if (action.payload.data.status === 200) {
        return {
          ...state,
          Permissions: action.payload.data.data[0].AllowedFunctions.map((item) => item.Id)
        };
      } else {
        return { ...state };
      }
    }

    default:
      return state;
  }
};
