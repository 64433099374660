
import { getAllOperationStatus } from 'services/operationStatus';

export const STATUS_ACTIONS = {
    GET_ALL_OPERATION_STATUS: "GET_ALL_OPERATION_STATUS",
    GET_ALL_OPERATION_STATUS_PENDING: "GET_ALL_OPERATION_STATUS_PENDING",
    GET_ALL_OPERATION_STATUS_FULFILLED: "GET_ALL_OPERATION_STATUS_FULFILLED",
    GET_ALL_OPERATION_STATUS_REJECTED: "GET_ALL_OPERATION_STATUS_REJECTED",
}
//OperationStatus:
export const actionGetAllOperationStatus = () => dispatch => {
    return dispatch({
        type: STATUS_ACTIONS.GET_ALL_OPERATION_STATUS,
        payload: getAllOperationStatus()
    })
}