import { all } from 'redux-saga/effects';

import { actionUpdateAddDriverPlanData } from './adddriverplan';
import { watchCustomerSearch } from './customerSaga';
import { actionUpdateCustomFeeData } from './customfee';
import { actionUpdateDepositShellData } from './depositShell';
import { actionShowAlertError, actionShowAlertSuccess, actionUpdateTrackingFee } from './emitter';
import { actionUpdateEmployeesData } from './employees';
import { actionUpdateFullUpFuelManagementData } from './fillupfuelmanagement';
import { watchChangePermission, watchChangePermissionRow } from './groupSaga';
import { actionUpdateMoocsData } from './mooc';
import { actionUpdateOilNormData } from './oilNorm';
import { actionUpdateOilPricesManagementData } from './oilpricesmanagement';
import { actionUpdatePaymentOnBehaftData } from './paymentonbehaft';
import { actionUpdateProductCategoryData } from './productcategory';
import { actionUpdateImportData } from './repair/importSparePartManagement';
// import { actionUpdateDriverPlanData } from "./driverplan";
import { actionUpdateRepairDetailData } from './repair/repairInvoiceDetails';
import { actionUpdateRepairData } from './repair/repairManagement';
import { actionUpdateSettlementRepairData } from './repair/settlementRepair';
import { actionUpdateSparePartData } from './repair/sparePartsManagement';
import { actionUpdateRoadFeeData } from './roadFeeManagement';
import { watchRouteAddressSearch } from './RouteAddressSaga';
import { actionUpdateRoutersData } from './router';
import { watchChangeSetupFieldFormulas } from './setupDialogFormulasSaga';
// import {
//     actionUpdateOrderManagementInputData,
//     actionUpdateOrderManagementOuputData
// } from "./ordermanagement";
import { watchChangeSetupField, watchMoveDownColumn, watchMoveUpColumn } from './setupDialogSaga';
import { actionUpdateShippingLineData } from './shippingLine';
import { actionUpdateTransportationPlanListData } from './transportationplan';
import { actionUpdateTransportCostData } from './transportCost';
import { actionUpdateTransportCostRealityData } from './transportCostReality';
import { actionUpdateAddingTruckingFeeData, actionUpdateTruckingFeeData } from './truckingfee';
import { watchUserSearch } from './userManagementSaga';
import { actionUpdateVehiclesData } from './vehicle';
import { actionUpdateWarehouseInfoData } from './warehouseInfo';

export default function* rootSaga() {
  yield all([
    actionUpdateTruckingFeeData(),
    actionUpdateAddingTruckingFeeData(),

    actionUpdateRoutersData(),
    actionUpdateProductCategoryData(),
    actionUpdateVehiclesData(),
    actionUpdateEmployeesData(),
    watchUserSearch(),
    actionUpdateDepositShellData(),
    actionUpdateCustomFeeData(),
    actionUpdateShippingLineData(),
    actionUpdateOilPricesManagementData(),
    actionUpdateMoocsData(),
    actionUpdateWarehouseInfoData(),
    actionUpdatePaymentOnBehaftData(),
    actionUpdateRoadFeeData(),
    actionUpdateOilNormData(),
    actionUpdateTransportCostData(),
    // actionUpdateRepairData(), // thiếu hàm khi gọi takeEvery
    actionUpdateSparePartData(),
    actionUpdateFullUpFuelManagementData(),
    actionUpdateImportData(),
    watchChangePermission(),
    watchChangePermissionRow(),
    actionUpdateTransportationPlanListData(),
    watchCustomerSearch(),
    // actionUpdateOrderManagementInputData(),
    watchCustomerSearch(),
    watchChangeSetupField(),
    watchMoveUpColumn(),
    watchMoveDownColumn(),
    // actionUpdateDriverPlanData(),
    // actionUpdateOrderManagementOuputData(),
    actionUpdateRepairDetailData(),
    actionUpdateAddDriverPlanData(),
    actionShowAlertError(),
    actionShowAlertSuccess(),
    actionUpdateTrackingFee(),
    actionUpdateTransportCostRealityData(),
    actionUpdateSettlementRepairData(),

    watchRouteAddressSearch(),
    watchChangeSetupFieldFormulas()
  ]);
}
