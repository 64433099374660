import React from 'react';
import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

// import CIcon from '@coreui/icons-react';
import './style.css';
const propTypes = {
  children: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  button: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  popover: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  buttonText: PropTypes.string,
  confirmTitle: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  okIcon: PropTypes.string,
  cancelIcon: PropTypes.string
};

const defaultProps = {};

class DeletePopConfirm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      id: `x${Math.floor(Math.random() * 99999999)}x${Math.floor(Math.random() * 99999999)}`
    };
  }

  componentDidMount() {}

  setPopoverOpen() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  render() {
    const {
      onOk,
      onCancel,
      button,
      popover,
      buttonText,
      confirmTitle,
      okText,
      cancelText,
      okIcon,
      cancelIcon,
      disabled,
      className
    } = this.props;
    return (
      <div>
        <Tooltip title="Xóa dữ liệu">
          <Button
            id={this.state.id}
            type="button"
            {...button}
            className={className}
            disabled={disabled}
            // size="sm"
          >
            {/* <CIcon name="cilTrash" /> */}
            <i className="fa fa-trash" aria-hidden="true"></i> {buttonText}
          </Button>

          {!disabled ? (
            <Popover
              placement="bottom"
              isOpen={this.state.popoverOpen}
              target={this.state.id}
              toggle={() => this.setPopoverOpen()}
              trigger="legacy"
              {...popover}
            >
              <PopoverHeader>{confirmTitle}</PopoverHeader>
              <PopoverBody>
                <Button
                  disabled={disabled}
                  color="success"
                  onClick={() => {
                    onOk();
                    this.setPopoverOpen();
                  }}
                >
                  {' '}
                  <i className={okIcon} aria-hidden="true"></i> {okText}{' '}
                </Button>{' '}
                <Button
                  color="danger"
                  onClick={() => {
                    this.setPopoverOpen();
                    onCancel();
                  }}
                >
                  {' '}
                  <i className={cancelIcon} aria-hidden="true"></i> {cancelText}{' '}
                </Button>
              </PopoverBody>
            </Popover>
          ) : null}
        </Tooltip>
      </div>
    );
  }
}

DeletePopConfirm.propTypes = propTypes;
DeletePopConfirm.defaultProps = defaultProps;

export default DeletePopConfirm;
