import api from 'HttpClient/index';

export const searchFillUpFuelManagementPerPage = (payload, pageNo, pageSize) => {
  return api.get(`/Fueling/search`, { params: payload });
};

export const getFillUpFuelManagementPerPage = (pageNo, pageSize) => {
  return api.get(`/Fueling/paging/page?Page=${pageNo}&PageSize=${pageSize}`);
};

export const getFillUpFuelManagementByID = (id) => {
  return api.get(`/Fueling/getById/${id}`);
};

export const createFillUpFuelManagement = (payload) => {
  return api.post(`/Fueling/create`, payload);
};

export const updateFillUpFuelManagement = (payload) => {
  return api.put(`/Fueling/update`, payload);
};

export const deleteFillUpFuelManagement = (id) => {
  return api.delete(`/Fueling/delete/${id}`);
};
