import {
  CHANGE_TIME_OUT,
  RESET_TIME_OUT,
  SIGNIN_USER_FULFILLED,
  SIGNIN_USER_PENDING,
  SIGNIN_USER_REJECTED,
  SIGNOUT_USER_FULFILLED,
  SIGNOUT_USER_PENDING,
  SIGNOUT_USER_REJECTED
} from 'actions/authAction';
import { getAccessToken, getAuthUser, getCompanyId, getCompanyName, getIsAdmin } from 'commons/authUtils';
import { getErrorMessage } from 'commons/ReducerUtils';
import {
  ACCESS_TOKEN,
  COMPANY_ID,
  COMPANY_NAME,
  DISPLAY_NAME,
  EMPLOYEE_FULL_NAME,
  EMPLOYEE_INFO_ID,
  LICENSE_EXPIRE_DATE,
  SESSION_TIMEOUT_SEC,
  SIGNATURE_IMAGE_URL,
  TOKEN_EXPIRED_DATE,
  TOKEN_REAL_EXPIRED_DATE,
  USER_ID,
  USERNAME
} from 'const/common';
import httpClient from 'HttpClient';

const INIT_STATE = {
  loading: false,
  authUser: getAuthUser(),
  accessToken: getAccessToken(),
  companyId: getCompanyId(),
  companyName: getCompanyName(),
  IsAdmin: getIsAdmin(),
  [LICENSE_EXPIRE_DATE]: localStorage.getItem(LICENSE_EXPIRE_DATE),
  [USER_ID]: localStorage.getItem(USER_ID),
  [EMPLOYEE_INFO_ID]: localStorage.getItem(EMPLOYEE_INFO_ID),
  [EMPLOYEE_FULL_NAME]: localStorage.getItem(EMPLOYEE_FULL_NAME)
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // get data
    case SIGNIN_USER_PENDING:
    case SIGNOUT_USER_PENDING: {
      delete httpClient.defaults.headers.common['apikey'];
      return {
        ...state,
        loading: true
      };
    }
    case SIGNIN_USER_FULFILLED: {
      const token = action.payload.data.token;
      delete httpClient.defaults.headers.common['apikey'];
      httpClient.defaults.headers.common['apikey'] = token;
      localStorage.setItem(ACCESS_TOKEN, token);
      localStorage.setItem(USERNAME, action.meta.user.username);
      localStorage.setItem(DISPLAY_NAME, action.payload.data[DISPLAY_NAME]);
      localStorage.setItem(SIGNATURE_IMAGE_URL, action.payload.data?.[SIGNATURE_IMAGE_URL] ?? '');
      const companyId = action.payload.data[COMPANY_ID];
      let companyObj = {};
      if (companyId && companyId !== '') {
        localStorage.setItem(COMPANY_ID, companyId);
        companyObj = {
          ...companyObj,
          companyId: companyId
        };
      }
      const companyName = action.payload.data[COMPANY_NAME];
      if (companyName && companyName !== '') {
        localStorage.setItem(COMPANY_NAME, companyName);
        companyObj = {
          ...companyObj,
          companyName: companyName
        };
      }
      const expiredDate = new Date();
      expiredDate.setTime(expiredDate.getTime() + SESSION_TIMEOUT_SEC * 1000);
      localStorage.setItem(TOKEN_EXPIRED_DATE, expiredDate.toISOString());
      localStorage.setItem(TOKEN_REAL_EXPIRED_DATE, action.payload.data[TOKEN_EXPIRED_DATE]);

      //define is Driver
      const isDriver = action.payload.data.isDriver;
      localStorage.setItem('isDriver', isDriver);
      // abbrCompanyName
      const abbrCompanyName = action.payload.data.abbrCompanyName;
      localStorage.setItem('abbrCompanyName', abbrCompanyName);
      // admin role
      const IsAdmin = action.payload.data?.adminRole;
      localStorage.setItem('IsAdmin', IsAdmin);
      // LisenseKeyExpires
      const LisenseKeyExpires = action.payload.data?.lisenseKeyExpires;
      localStorage.setItem(LICENSE_EXPIRE_DATE, LisenseKeyExpires);
      // user id
      const UserId = action.payload.data?.userId;
      localStorage.setItem(USER_ID, UserId);
      //employeeInfoId
      const employeeInfoId = action.payload.data?.employeeInfoId;
      localStorage.setItem(EMPLOYEE_INFO_ID, employeeInfoId);
      // full name
      const employeeFullName = action.payload.data?.fullName;
      localStorage.setItem(EMPLOYEE_FULL_NAME, employeeFullName);
      return {
        ...state,
        loading: false,
        authUser: action.meta.user.username,
        [DISPLAY_NAME]: action.payload.data[DISPLAY_NAME],
        accessToken: token,
        ...companyObj,
        refreshTimeOut: 1,
        IsAdmin: IsAdmin,
        [LICENSE_EXPIRE_DATE]: LisenseKeyExpires,
        [USER_ID]: UserId,
        [EMPLOYEE_INFO_ID]: employeeInfoId,
        [EMPLOYEE_FULL_NAME]: employeeFullName
      };
    }
    case SIGNIN_USER_REJECTED: {
      let alertMessage = getErrorMessage(action, 'Lỗi. Không thể login. Hãy kiểm tra lại tên đăng nhập và mật khẩu');

      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: 'error'
      };
    }
    case SIGNOUT_USER_FULFILLED: {
      return {
        ...state,
        loading: false,
        authUser: '',
        accessToken: ''
      };
    }
    case SIGNOUT_USER_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: 'Lỗi. Không thể logout',
        messageType: 'error'
      };
    }
    case CHANGE_TIME_OUT: {
      const newRefreshTimeOut = state.refreshTimeOut ? state.refreshTimeOut + 1 : 1;
      return {
        ...state,
        refreshTimeOut: newRefreshTimeOut
      };
    }

    case RESET_TIME_OUT: {
      return {
        ...state,
        refreshTimeOut: 0
      };
    }

    default:
      return state;
  }
};
