import httpClient from 'HttpClient';
// define const actions
export const OUT_SOURCE_TRANSPORT_COST_ACTIONS = {
  GET_OUT_SOURCE_TRANSPORT_COST_PER_PAGE: 'GET_OUT_SOURCE_TRANSPORT_COST_PER_PAGE',
  GET_OUT_SOURCE_TRANSPORT_COST_PER_PAGE_PENDING: 'GET_OUT_SOURCE_TRANSPORT_COST_PER_PAGE_PENDING',
  GET_OUT_SOURCE_TRANSPORT_COST_PER_PAGE_FULFILLED: 'GET_OUT_SOURCE_TRANSPORT_COST_PER_PAGE_FULFILLED',
  GET_OUT_SOURCE_TRANSPORT_COST_PER_PAGE_REJECTED: 'GET_OUT_SOURCE_TRANSPORT_COST_PER_PAGE_REJECTED',

  GET_OUT_SOURCE_TRANSPORT_COST_BY_ID: 'GET_OUT_SOURCE_TRANSPORT_COST_BY_ID',
  GET_OUT_SOURCE_TRANSPORT_COST_BY_ID_PENDING: 'GET_OUT_SOURCE_TRANSPORT_COST_BY_ID_PENDING',
  GET_OUT_SOURCE_TRANSPORT_COST_BY_ID_FULFILLED: 'GET_OUT_SOURCE_TRANSPORT_COST_BY_ID_FULFILLED',
  GET_OUT_SOURCE_TRANSPORT_COST_BY_ID_REJECTED: 'GET_OUT_SOURCE_TRANSPORT_COST_BY_ID_REJECTED',

  CREATE_OUT_SOURCE_TRANSPORT_COST: 'CREATE_OUT_SOURCE_TRANSPORT_COST',
  CREATE_OUT_SOURCE_TRANSPORT_COST_PENDING: 'CREATE_OUT_SOURCE_TRANSPORT_COST_PENDING',
  CREATE_OUT_SOURCE_TRANSPORT_COST_FULFILLED: 'CREATE_OUT_SOURCE_TRANSPORT_COST_FULFILLED',
  CREATE_OUT_SOURCE_TRANSPORT_COST_REJECTED: 'CREATE_OUT_SOURCE_TRANSPORT_COST_REJECTED',

  UPDATE_OUT_SOURCE_TRANSPORT_COST: 'UPDATE_OUT_SOURCE_TRANSPORT_COST',
  UPDATE_OUT_SOURCE_TRANSPORT_COST_PENDING: 'UPDATE_OUT_SOURCE_TRANSPORT_COST_PENDING',
  UPDATE_OUT_SOURCE_TRANSPORT_COST_FULFILLED: 'UPDATE_OUT_SOURCE_TRANSPORT_COST_FULFILLED',
  UPDATE_OUT_SOURCE_TRANSPORT_COST_REJECTED: 'UPDATE_OUT_SOURCE_TRANSPORT_COST_REJECTED',

  DELETE_OUT_SOURCE_TRANSPORT_COST: 'DELETE_OUT_SOURCE_TRANSPORT_COST',
  DELETE_OUT_SOURCE_TRANSPORT_COST_PENDING: 'DELETE_OUT_SOURCE_TRANSPORT_COST_PENDING',
  DELETE_OUT_SOURCE_TRANSPORT_COST_FULFILLED: 'DELETE_OUT_SOURCE_TRANSPORT_COST_FULFILLED',
  DELETE_OUT_SOURCE_TRANSPORT_COST_REJECTED: 'DELETE_OUT_SOURCE_TRANSPORT_COST_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  GET_CUSTOMERS: 'GET_CUSTOMERS',
  GET_CUSTOMERS_PENDING: 'GET_CUSTOMERS_PENDING',
  GET_CUSTOMERS_FULFILLED: 'GET_CUSTOMERS_FULFILLED',
  GET_CUSTOMERS_REJECTED: 'GET_CUSTOMERS_REJECTED',

  GET_ROUTES: 'GET_ROUTES',
  GET_ROUTES_PENDING: 'GET_ROUTES_PENDING',
  GET_ROUTES_FULFILLED: 'GET_ROUTES_FULFILLED',
  GET_ROUTES_REJECTED: 'GET_ROUTES_REJECTED',

  GET_CONT_CATEGORY: 'GET_CONT_CATEGORY',
  GET_CONT_CATEGORY_PENDING: 'GET_CONT_CATEGORY_PENDING',
  GET_CONT_CATEGORY_FULFILLED: 'GET_CONT_CATEGORY_FULFILLED',
  GET_CONT_CATEGORY_REJECTED: 'GET_CONT_CATEGORY_REJECTED',

  CREATE_OUT_SOURCE_TRANSPORT_COST_V2: 'CREATE_OUT_SOURCE_TRANSPORT_COST_V2',
  CREATE_OUT_SOURCE_TRANSPORT_COST_V2_PENDING: 'CREATE_OUT_SOURCE_TRANSPORT_COST_V2_PENDING',
  CREATE_OUT_SOURCE_TRANSPORT_COST_V2_FULFILLED: 'CREATE_OUT_SOURCE_TRANSPORT_COST_V2_FULFILLED',
  CREATE_OUT_SOURCE_TRANSPORT_COST_V2_REJECTED: 'CREATE_OUT_SOURCE_TRANSPORT_COST_V2_REJECTED'
};

// define actions
export const actionGetOutSourceTransportCostPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: OUT_SOURCE_TRANSPORT_COST_ACTIONS.GET_OUT_SOURCE_TRANSPORT_COST_PER_PAGE,
    payload: httpClient.get(`/ExternalTransportFeeInfo/managerment/paging/page?Page=${pageNo}&PageSize=${pageSize}`)
  });
};

export const actionGetOutSourceTransportCostByID = (id) => (dispatch) => {
  return dispatch({
    type: OUT_SOURCE_TRANSPORT_COST_ACTIONS.GET_OUT_SOURCE_TRANSPORT_COST_BY_ID,
    payload: httpClient.get(`/ExternalTransportFeeInfo/getById/${id}`)
  });
};

export const actionCreateOutSourceTransportCost = (payload) => (dispatch) => {
  return dispatch({
    type: OUT_SOURCE_TRANSPORT_COST_ACTIONS.CREATE_OUT_SOURCE_TRANSPORT_COST,
    payload: httpClient.post(`/ExternalTransportFeeInfo/create`, payload)
  });
};

export const actionUpdateOutSourceTransportCost = (payload) => (dispatch) => {
  return dispatch({
    type: OUT_SOURCE_TRANSPORT_COST_ACTIONS.UPDATE_OUT_SOURCE_TRANSPORT_COST,
    payload: httpClient.put(`/ExternalTransportFeeInfo/update`, payload)
  });
};

export const actionDeleteOutSourceTransportCost = (id) => (dispatch) => {
  return dispatch({
    type: OUT_SOURCE_TRANSPORT_COST_ACTIONS.DELETE_OUT_SOURCE_TRANSPORT_COST,
    payload: httpClient.delete(`/ExternalTransportFeeInfo/deleteById/${id}`)
  });
};

export const actionSearchOutSourceTransportCost = (params, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: OUT_SOURCE_TRANSPORT_COST_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/ExternalTransportFeeInfo/managerment/paging/page?Page=${pageNo}&PageSize=${pageSize}`, {
      params
    })
  });
};
export const actionSearchOutSourceTransportCostAll = (params) => (dispatch) => {
  return dispatch({
    payload: httpClient.get(`/ExternalTransportFeeInfo/managerment/paging/page`, { params })
  });
};

export const actionGetRoutes = () => (dispatch) => {
  return dispatch({
    type: OUT_SOURCE_TRANSPORT_COST_ACTIONS.GET_ROUTES,
    payload: httpClient.get(`/routeinfo/getAll`)
  });
};

export const actionGetCustomers = () => (dispatch) => {
  return dispatch({
    type: OUT_SOURCE_TRANSPORT_COST_ACTIONS.GET_CUSTOMERS,
    payload: httpClient.get(`/customerinfo/getAll`)
  });
};

export const actionGetContCategory = () => (dispatch) => {
  return dispatch({
    type: OUT_SOURCE_TRANSPORT_COST_ACTIONS.GET_CONT_CATEGORY,
    payload: httpClient.get(`/Container/getAll`)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

//update import excel
export const actionCreateOutSourceTransportCostV2 = (payload) => (dispatch) => {
  return dispatch({
    type: OUT_SOURCE_TRANSPORT_COST_ACTIONS.CREATE_OUT_SOURCE_TRANSPORT_COST_V2,
    payload: httpClient.post(`/ExternalTransportFeeInfo/createV2`, payload)
  });
};

export const actionCheckExistContractTransportFeeInfo = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.post(`ContractTransportFeeInfo/CheckExist`, payload)
  });
};

export const actionCheckExistExternalTransportFeeInfo = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.post(`ExternalTransportFeeInfo/CheckExist`, payload)
  });
};
