import React, { useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { onMessageListener } from 'commons/notificationConfigUtils';
import LoadingContainer from 'views/LoadingContainer';

import ErrorBoundary from './Pages/ErrorBoundary';
import RestrictedRoute from './RestrictedRoute';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const DriverLogin = React.lazy(() => import('./views/Pages/Login/DriverLogin'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const Page401 = React.lazy(() => import('./views/Pages/Page401/Page401'));
const StickyExample = React.lazy(() => import('./Pages/StickyExampleOuter'));
const UserCard = React.lazy(() => import('./Pages/UserCardInfo'));
const ChangePasswordSuccess = React.lazy(() => import('./Pages/ChangePassword/changePasswordSuccess'));

const App = (props) => {
  const [isFetchMess, setFetchMess] = useState(false);
  const renderHtmlContentMess = (mess) => {
    return (
      <>
        <h6>{mess.title}</h6>
        <p>{mess.body}</p>
      </>
    );
  };

  onMessageListener().then((el) => {
    // console.log('🚀 ~ file: App.js ~ line 39 ~ App ~ onMessageListener ~ el', el);
    const { notification } = el;
    setFetchMess(true);
    toast.info(renderHtmlContentMess(notification), {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
    setTimeout(() => setFetchMess(false), 5000);
  });

  const { authUser } = props;
  const isTrue = (value) => value === true || value === 'true';
  const isDriver = isTrue(localStorage.getItem('isDriver'));
  return (
    <BrowserRouter>
      <ErrorBoundary>
        {/*<OverlayWrapper active={props.navLoading}>*/}
        <React.Suspense fallback={<LoadingContainer />}>
          <Switch>
            <Route exact path="/driver-login" name="Login Page" render={(props) => <DriverLogin {...props} />} />
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route exact path="/register" name="Register Page" render={(props) => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route exact path="/401" name="Page 401" render={(props) => <Page401 {...props} />} />
            <Route path="/example" name="Sticky Example" render={(props) => <StickyExample {...props} />} />
            <Route path="/user-card-info" name="User card" render={(props) => <UserCard {...props} />} />
            <Route
              exact
              path="/change-password-success"
              name="Đổi mật khẩu thành công"
              render={(props) => <ChangePasswordSuccess {...props} />}
            />
            <RestrictedRoute path="/" authUser={authUser} isDriver={isDriver} component={TheLayout} />

            {/* <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} /> */}
          </Switch>
        </React.Suspense>
        {/*</OverlayWrapper>*/}
      </ErrorBoundary>
    </BrowserRouter>
  );
};

function mapStateToProps(state) {
  return {
    authUser: state.authStore.authUser,
    navLoading: state.navStore.loading
  };
}

export default connect(mapStateToProps, {})(App);
