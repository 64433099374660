import { ROUTE_COST_FEE_ACTIONS } from 'actions/routeCostFee';
const INIT_STATE = {
  routeCostFees: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  isCloseModal: false,
  isUpdateSubmit: true,
  reload: false,
  pagination: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ROUTE_COST_FEE_ACTIONS.GET_LIST_ROUTE_COST_FEE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        reload: false
      };
    }
    case ROUTE_COST_FEE_ACTIONS.GET_LIST_ROUTE_COST_FEE_FULFILLED: {
      return {
        ...state,
        loading: false,
        routeCostFees: action.payload.data.data,
        isSearch: false,
        isCloseModal: false,
        pagination: action.payload.data.pagination,
        reload: false
      };
    }
    case ROUTE_COST_FEE_ACTIONS.GET_LIST_ROUTE_COST_FEE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isCloseModal: false,
        reload: false
      };
    }
    case ROUTE_COST_FEE_ACTIONS.UPDATE_PRICE_ROUTE_COST_FEE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case ROUTE_COST_FEE_ACTIONS.UPDATE_PRICE_ROUTE_COST_FEE_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công !',
        isShowMessage: true,
        isCloseModal: true,
        reload: true
      };
    }
    case ROUTE_COST_FEE_ACTIONS.UPDATE_PRICE_ROUTE_COST_FEE_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Cập nhật dữ liệu thất bại !',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        reload: false
      };
    }
    case ROUTE_COST_FEE_ACTIONS.UPDATE_ROUTE_COST_FEE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
        reload: false
      };
    }
    case ROUTE_COST_FEE_ACTIONS.UPDATE_ROUTE_COST_FEE_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Cập nhật dữ liệu thành công',
        isShowMessage: true,
        isCloseModal: true,
        isUpdateSubmit: false,
        reload: true
      };
    }
    case ROUTE_COST_FEE_ACTIONS.UPDATE_ROUTE_COST_FEE_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Cập nhật dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false,
        reload: false
      };
    }
    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }
    case ROUTE_COST_FEE_ACTIONS.CHANGE_DATA_EDIT_TABEL_FIELD_ROUTE_COST_FEE: {
      let resultUpdate = [];
      const data = action.payload;
      const itemEditable = state.routeCostFees[data.indexArray];
      const arrayFeeNeedUpdate = itemEditable.TransportFees;
      const itemFeeNeedUpdate = arrayFeeNeedUpdate[data.indexFee];
      const itemFeeUpdated = [{ ...itemFeeNeedUpdate, ...data }];
      const arrayFeeNeddUpdated = arrayFeeNeedUpdate.map((obj) => itemFeeUpdated.find((o) => o.Id === obj.Id) || obj);
      const itemEditableUpdated = { ...itemEditable, TransportFees: [...arrayFeeNeddUpdated] };
      const dataUpdate = state.routeCostFees.map((obj) => [itemEditableUpdated].find((o) => o.Id === obj.Id) || obj);
      resultUpdate = [...dataUpdate];

      return {
        ...state,
        loading: false,
        routeCostFees: [...resultUpdate],
        isUpdateSubmit: action.meta.message !== undefined ? false : true
      };
    }

    case ROUTE_COST_FEE_ACTIONS.ADD_FEES_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        // isCloseModal: false,
        reload: false
      };
    }
    case ROUTE_COST_FEE_ACTIONS.ADD_FEES_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isShowMessage: true,
        // isCloseModal: true,
        reload: true
      };
    }
    case ROUTE_COST_FEE_ACTIONS.ADD_FEES_REJECTED: {
      return {
        ...state,
        loading: false,
        message: 'Thêm mới dữ liệu thất bại !',
        isShowMessage: true,
        typeMessage: 'error',
        // isCloseModal: false,
        reload: false
      };
    }

    default:
      return state;
  }
};
