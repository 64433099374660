import api from 'HttpClient';

export const SET_OPEN_QUOTATION_DETAIL_MODAL = 'SET_OPEN_QUOTATION_DETAIL_MODAL';
export const setOpenQuotationDetailModal = (data, type) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_QUOTATION_DETAIL_MODAL,
    payload: { data, type }
  });
};

export const SET_OPEN_HISTORY_DETAIL_MODAL = 'SET_OPEN_HISTORY_DETAIL_MODAL';
export const setOpenHistoryModal = (Id, type) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_HISTORY_DETAIL_MODAL,
    payload: Id
  });
};

export const SET_CLOSE_HISTORY_DETAIL_MODAL = 'SET_CLOSE_HISTORY_DETAIL_MODAL';
export const setCloseHistoryModal = (data, type) => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_HISTORY_DETAIL_MODAL
  });
};

export const SET_CLOSE_QUOTATION_DETAIL_MODAL = 'SET_CLOSE_QUOTATION_DETAIL_MODAL';
export const setCloseQuotationDetailModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_QUOTATION_DETAIL_MODAL
  });
};

export const FETCH_ALL_QUOTATION_DETAILS = 'FETCH_ALL_QUOTATION_DETAILS';
export const FETCH_ALL_QUOTATION_DETAILS_PENDING = 'FETCH_ALL_QUOTATION_DETAILS_PENDING';
export const FETCH_ALL_QUOTATION_DETAILS_FULFILLED = 'FETCH_ALL_QUOTATION_DETAILS_FULFILLED';
export const FETCH_ALL_QUOTATION_DETAILS_REJECTED = 'FETCH_ALL_QUOTATION_DETAILS_REJECTED';
export const fetchAllQuotationDetails = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_QUOTATION_DETAILS,
    payload: api.get(`/QuotationDetailInfo/getAll`)
  });
};

// const type of function get data per page
export const FETCH_QUOTATION_DETAILS = 'FETCH_QUOTATION_DETAILS';
export const FETCH_QUOTATION_DETAILS_PENDING = 'FETCH_QUOTATION_DETAILS_PENDING';
export const FETCH_QUOTATION_DETAILS_FULFILLED = 'FETCH_QUOTATION_DETAILS_FULFILLED';
export const FETCH_QUOTATION_DETAILS_REJECTED = 'FETCH_QUOTATION_DETAILS_REJECTED';
export const fetchQuotationDetails = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_QUOTATION_DETAILS,
    payload: api.get(`/QuotationInfo/${id}/getQuotationDetail`),
    meta: { id }
  });
};

// const type of function create data
export const CREATE_QUOTATION_DETAIL = 'CREATE_QUOTATION_DETAIL';
export const CREATE_QUOTATION_DETAIL_PENDING = 'CREATE_QUOTATION_DETAIL_PENDING';
export const CREATE_QUOTATION_DETAIL_FULFILLED = 'CREATE_QUOTATION_DETAIL_FULFILLED';
export const CREATE_QUOTATION_DETAIL_REJECTED = 'CREATE_QUOTATION_DETAIL_REJECTED';
export const createQuotationDetail = (id, payload) => (dispatch) => {
  return dispatch({
    type: CREATE_QUOTATION_DETAIL,
    payload: api.post(`/QuotationInfo/${id}/CreateQuotationDetail`, payload)
  });
};

// const type of function update data
export const UPDATE_QUOTATION_DETAIL = 'UPDATE_QUOTATION_DETAIL';
export const UPDATE_QUOTATION_DETAIL_PENDING = 'UPDATE_QUOTATION_DETAIL_PENDING';
export const UPDATE_QUOTATION_DETAIL_FULFILLED = 'UPDATE_QUOTATION_DETAIL_FULFILLED';
export const UPDATE_QUOTATION_DETAIL_REJECTED = 'UPDATE_QUOTATION_DETAIL_REJECTED';
export const updateQuotationDetail = (id, payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_QUOTATION_DETAIL,
    payload: api.put(`/QuotationInfo/${id}/UpdateQuotationDetail`, payload),
    meta: payload
  });
};
// const type of get deatail
export const FETCH_QUOTATION_DETAIL_DETAIL = 'FETCH_QUOTATION_DETAIL_DETAIL';
export const FETCH_QUOTATION_DETAIL_DETAIL_PENDING = 'FETCH_QUOTATION_DETAIL_DETAIL_PENDING';
export const FETCH_QUOTATION_DETAIL_DETAIL_FULFILLED = 'FETCH_QUOTATION_DETAIL_DETAIL_FULFILLED';
export const FETCH_QUOTATION_DETAIL_DETAIL_REJECTED = 'FETCH_QUOTATION_DETAIL_DETAIL_REJECTED';
export const getQuotationDetailDetail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_QUOTATION_DETAIL_DETAIL,
    payload: api.get(`/QuotationDetailInfo/${id}`)
  });
};

// const type of delete
export const DELETE_QUOTATION_DETAIL = 'DELETE_QUOTATION_DETAIL';
export const DELETE_QUOTATION_DETAIL_PENDING = 'DELETE_QUOTATION_DETAIL_PENDING';
export const DELETE_QUOTATION_DETAIL_FULFILLED = 'DELETE_QUOTATION_DETAIL_FULFILLED';
export const DELETE_QUOTATION_DETAIL_REJECTED = 'DELETE_QUOTATION_DETAIL_REJECTED';
export const deleteQuotationDetail = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_QUOTATION_DETAIL,
    payload: api.delete(`/QuotationInfo/deleteQuotationDetail/${id}`)
  });
};

export const SET_OPEN_QUOTATION_DETAIL_APPROVAL_MODAL = 'SET_OPEN_QUOTATION_DETAIL_APPROVAL_MODAL';
export const setOpenQuotationDetailApprovalModal = (data) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_QUOTATION_DETAIL_APPROVAL_MODAL,
    payload: data
  });
};

export const SET_CLOSE_QUOTATION_DETAIL_APPROVAL_MODAL = 'SET_CLOSE_QUOTATION_DETAIL_APPROVAL_MODAL';
export const setCloseQuotationDetailApprovalModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_QUOTATION_DETAIL_APPROVAL_MODAL
  });
};

export const DELETE_FILE_QUOTATION_DETAIL = 'DELETE_FILE_QUOTATION_DETAIL';
export const DELETE_FILE_QUOTATION_DETAIL_PENDING = 'DELETE_FILE_QUOTATION_DETAIL_PENDING';
export const DELETE_FILE_QUOTATION_DETAIL_FULFILLED = 'DELETE_FILE_QUOTATION_DETAIL_FULFILLED';
export const DELETE_FILE_QUOTATION_DETAIL_REJECTED = 'DELETE_FILE_QUOTATION_DETAIL_REJECTED';
export const actionDeleteFileQuotationDetail = (fileId) => (dispatch) => {
  return dispatch({
    type: DELETE_FILE_QUOTATION_DETAIL,
    payload: api.delete(`/QuotationInfo/deleteComparisonResource/${fileId}`)
  });
};

export const RESET_QUOTATION_DETAIL = 'RESET_QUOTATION_DETAIL';
export const resetQuotationDetail = () => (dispatch) => {
  return dispatch({
    type: RESET_QUOTATION_DETAIL
    // payload: { data, type }
  });
};

export const QUOTATION_DETAIL_LOGS = 'QUOTATION_DETAIL_LOGS';
export const QUOTATION_DETAIL_LOGS_PENDING = 'QUOTATION_DETAIL_LOGS_PENDING';
export const QUOTATION_DETAIL_LOGS_FULFILLED = 'QUOTATION_DETAIL_LOGS_FULFILLED';
export const QUOTATION_DETAIL_LOGS_REJECTED = 'QUOTATION_DETAIL_LOGS_REJECTED';
export const actionQuotationDetailLogs = (fileId) => (dispatch) => {
  return dispatch({
    type: QUOTATION_DETAIL_LOGS,
    payload: api.get(`/QuotationDetailLogs/getById/${fileId}`)
  });
};
