import { CLOSE_HEADER, OPEN_HEADER, TOGGLE_HEADER } from 'actions';
const INIT_STATE = {
  isOpen: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region paging
    case TOGGLE_HEADER: {
      return {
        ...state,
        isOpen: !state.isOpen
      };
    }
    case OPEN_HEADER: {
      return {
        ...state,
        isOpen: false
      };
    }
    case CLOSE_HEADER: {
      return {
        ...state,
        isOpen: true
      };
    }

    default:
      return state;
  }
};
