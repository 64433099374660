import {
  PORT_CATEGORY_ACTIONS
} from "actions/portcategory";


const portCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    // get all goods type
    case PORT_CATEGORY_ACTIONS.GET_ALL_PORT_CATEGORY_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case PORT_CATEGORY_ACTIONS.GET_ALL_PORT_CATEGORY_FULFILLED:
      let portCategory = {
        data: action.payload.data.data
      };
      return {
        ...state,
        portCategory: portCategory,
          isLoading: false,
      };

    case PORT_CATEGORY_ACTIONS.GET_ALL_PORT_CATEGORY_REJECTED:
      return {
        ...state,
        isLoading: false,
          isMsg: true,
          msg: "Đã xảy ra lỗi"
      };

    default:
      return state;
  }
};

export default portCategoryReducer;
