import {
  createRoadFee,
  deleteRoadFee,
  getAllRoadFee,
  getRoadFeeById,
  getRoadFeePerPage,
  getRoadFeePerPageAll,
  searchData,
  searchDataAll,
  updateRoadFee
} from 'services/roadFeeManagement';

// define const actions
export const ROAD_FEE_ACTIONS = {
  GET_ALL_ROAD_FEE: 'GET_ALL_ROAD_FEE',
  GET_ALL_ROAD_FEE_PENDING: 'GET_ALL_ROAD_FEE_PENDING',
  GET_ALL_ROAD_FEE_FULFILLED: 'GET_ALL_ROAD_FEE_FULFILLED',
  GET_ALL_ROAD_FEE_REJECTED: 'GET_ALL_ROAD_FEE_REJECTED',

  GET_ROAD_FEE_BY_ID: 'GET_ROAD_FEE_BY_ID',
  GET_ROAD_FEE_BY_ID_PENDING: 'GET_ROAD_FEE_BY_ID_PENDING',
  GET_ROAD_FEE_BY_ID_FULFILLED: 'GET_ROAD_FEE_BY_ID_FULFILLED',
  GET_ROAD_FEE_BY_ID_REJECTED: 'GET_ROAD_FEE_BY_ID_REJECTED',

  GET_ROAD_FEE_PER_PAGE: 'GET_ROAD_FEE_PER_PAGE',
  GET_ROAD_FEE_PER_PAGE_PENDING: 'GET_ROAD_FEE_PER_PAGE_PENDING',
  GET_ROAD_FEE_PER_PAGE_FULFILLED: 'GET_ROAD_FEE_PER_PAGE_FULFILLED',
  GET_ROAD_FEE_PER_PAGE_REJECTED: 'GET_ROAD_FEE_PER_PAGE_REJECTED',

  CREATE_ROAD_FEE: 'CREATE_ROAD_FEE',
  CREATE_ROAD_FEE_PENDING: 'CREATE_ROAD_FEE_PENDING',
  CREATE_ROAD_FEE_FULFILLED: 'CREATE_ROAD_FEE_FULFILLED',
  CREATE_ROAD_FEE_REJECTED: 'CREATE_ROAD_FEE_REJECTED',

  UPDATE_ROAD_FEE: 'UPDATE_ROAD_FEE',
  UPDATE_ROAD_FEE_PENDING: 'UPDATE_ROAD_FEE_PENDING',
  UPDATE_ROAD_FEE_FULFILLED: 'UPDATE_ROAD_FEE_FULFILLED',
  UPDATE_ROAD_FEE_REJECTED: 'UPDATE_ROAD_FEE_REJECTED',

  DELETE_ROAD_FEE: 'DELETE_ROAD_FEE',
  DELETE_ROAD_FEE_PENDING: 'DELETE_ROAD_FEE_PENDING',
  DELETE_ROAD_FEE_FULFILLED: 'DELETE_ROAD_FEE_FULFILLED',
  DELETE_ROAD_FEE_REJECTED: 'DELETE_ROAD_FEE_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED'
};

// define actions
export const actionGetAllRoadFee = () => (dispatch) => {
  return dispatch({
    type: ROAD_FEE_ACTIONS.GET_ALL_ROAD_FEE,
    payload: getAllRoadFee()
  });
};

export const actionGetRoadFeePerPage = (page, size) => (dispatch) => {
  return dispatch({
    type: ROAD_FEE_ACTIONS.GET_ROAD_FEE_PER_PAGE,
    payload: getRoadFeePerPage(page, size)
  });
};
export const actionGetRoadFeePerPageAll = (params) => (dispatch) => {
  return dispatch({
    // type: ROAD_FEE_ACTIONS.GET_ROAD_FEE_PER_PAGE,
    payload: getRoadFeePerPageAll(params)
  });
};

export const actionGetRoadFeeByID = (id) => (dispatch) => {
  return dispatch({
    type: ROAD_FEE_ACTIONS.GET_ROAD_FEE_BY_ID,
    payload: getRoadFeeById(id)
  });
};

export const actionCreateRoadFee = (payload) => (dispatch) => {
  return dispatch({
    type: ROAD_FEE_ACTIONS.CREATE_ROAD_FEE,
    payload: createRoadFee(payload)
  });
};

export const actionUpdateRoadFee = (payload) => (dispatch) => {
  return dispatch({
    type: ROAD_FEE_ACTIONS.UPDATE_ROAD_FEE,
    payload: updateRoadFee(payload)
  });
};

export const actionDeleteRoadFee = (id) => (dispatch) => {
  return dispatch({
    type: ROAD_FEE_ACTIONS.DELETE_ROAD_FEE,
    payload: deleteRoadFee(id)
  });
};

export const actionSearchData = (value, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: ROAD_FEE_ACTIONS.SEARCH_DATA,
    payload: searchData(value, pageNo, pageSize)
  });
};
export const actionSearchDataAll = (params) => (dispatch) => {
  return dispatch({
    payload: searchDataAll(params)
  });
};
