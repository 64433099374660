import {
  createProductCategory,
  deleteProductCategory,
  getAllProductCategory,
  getProductCategoryByID,
  getProductCategoryPerPage,
  getProductCategoryPerPageAll,
  searchProductCategory,
  searchProductCategoryAll,
  updateProductCategory
} from 'services/productcategory';

// define const actions
export const PRODUCT_CATEGORY_ACTIONS = {
  GET_ALL_PRODUCT_CATEGORY: 'GET_ALL_PRODUCT_CATEGORY',
  GET_ALL_PRODUCT_CATEGORY_PENDING: 'GET_ALL_PRODUCT_CATEGORY_PENDING',
  GET_ALL_PRODUCT_CATEGORY_FULFILLED: 'GET_ALL_PRODUCT_CATEGORY_FULFILLED',
  GET_ALL_PRODUCT_CATEGORY_REJECTED: 'GET_ALL_PRODUCT_CATEGORY_REJECTED',

  GET_PRODUCT_CATEGORY_PER_PAGE: 'GET_PRODUCT_CATEGORY_PER_PAGE',
  GET_PRODUCT_CATEGORY_PER_PAGE_PENDING: 'GET_PRODUCT_CATEGORY_PER_PAGE_PENDING',
  GET_PRODUCT_CATEGORY_PER_PAGE_FULFILLED: 'GET_PRODUCT_CATEGORY_PER_PAGE_FULFILLED',
  GET_PRODUCT_CATEGORY_PER_PAGE_REJECTED: 'GET_PRODUCT_CATEGORY_PER_PAGE_REJECTED',

  GET_PRODUCT_CATEGORY_BY_ID: 'GET_PRODUCT_CATEGORY_BY_ID',
  GET_PRODUCT_CATEGORY_BY_ID_PENDING: 'GET_PRODUCT_CATEGORY_BY_ID_PENDING',
  GET_PRODUCT_CATEGORY_BY_ID_FULFILLED: 'GET_PRODUCT_CATEGORY_BY_ID_FULFILLED',
  GET_PRODUCT_CATEGORY_BY_ID_REJECTED: 'GET_PRODUCT_CATEGORY_BY_ID_REJECTED',

  CREATE_PRODUCT_CATEGORY: 'CREATE_PRODUCT_CATEGORY',
  CREATE_PRODUCT_CATEGORY_PENDING: 'CREATE_PRODUCT_CATEGORY_PENDING',
  CREATE_PRODUCT_CATEGORY_FULFILLED: 'CREATE_PRODUCT_CATEGORY_FULFILLED',
  CREATE_PRODUCT_CATEGORY_REJECTED: 'CREATE_PRODUCT_CATEGORY_REJECTED',

  UPDATE_PRODUCT_CATEGORY: 'UPDATE_PRODUCT_CATEGORY',
  UPDATE_PRODUCT_CATEGORY_PENDING: 'UPDATE_PRODUCT_CATEGORY_PENDING',
  UPDATE_PRODUCT_CATEGORY_FULFILLED: 'UPDATE_PRODUCT_CATEGORY_FULFILLED',
  UPDATE_PRODUCT_CATEGORY_REJECTED: 'UPDATE_PRODUCT_CATEGORY_REJECTED',

  DELETE_PRODUCT_CATEGORY: 'DELETE_PRODUCT_CATEGORY',
  DELETE_PRODUCT_CATEGORY_PENDING: 'DELETE_PRODUCT_CATEGORY_PENDING',
  DELETE_PRODUCT_CATEGORY_FULFILLED: 'DELETE_PRODUCT_CATEGORY_FULFILLED',
  DELETE_PRODUCT_CATEGORY_REJECTED: 'DELETE_PRODUCT_CATEGORY_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED'
};

// define actions
export const actionGetAllProductCategory = () => (dispatch) => {
  return dispatch({
    type: PRODUCT_CATEGORY_ACTIONS.GET_ALL_PRODUCT_CATEGORY,
    payload: getAllProductCategory()
  });
};

export const actionGetProductCategoryPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: PRODUCT_CATEGORY_ACTIONS.GET_PRODUCT_CATEGORY_PER_PAGE,
    payload: getProductCategoryPerPage(pageNo, pageSize)
  });
};
export const actionGetProductCategoryPerPageAll = (params) => (dispatch) => {
  return dispatch({
    // type: PRODUCT_CATEGORY_ACTIONS.GET_PRODUCT_CATEGORY_PER_PAGE,
    payload: getProductCategoryPerPageAll(params)
  });
};

export const actionGetProductCategoryByID = (id) => (dispatch) => {
  return dispatch({
    type: PRODUCT_CATEGORY_ACTIONS.GET_PRODUCT_CATEGORY_BY_ID,
    payload: getProductCategoryByID(id)
  });
};

export const actionCreateProductCategory = (payload) => (dispatch) => {
  return dispatch({
    type: PRODUCT_CATEGORY_ACTIONS.CREATE_PRODUCT_CATEGORY,
    payload: createProductCategory(payload)
  });
};

export const actionUpdateProductCategory = (payload) => (dispatch) => {
  return dispatch({
    type: PRODUCT_CATEGORY_ACTIONS.UPDATE_PRODUCT_CATEGORY,
    payload: updateProductCategory(payload)
  });
};

export const actionDeleteProductCategory = (id) => (dispatch) => {
  return dispatch({
    type: PRODUCT_CATEGORY_ACTIONS.DELETE_PRODUCT_CATEGORY,
    payload: deleteProductCategory(id)
  });
};

export const actionSearchProductCategory = (value, pageSize, pageNo, goodsCategoryId) => (dispatch) => {
  return dispatch({
    type: PRODUCT_CATEGORY_ACTIONS.SEARCH_DATA,
    payload: searchProductCategory(value, pageSize, pageNo, goodsCategoryId)
  });
};
export const actionSearchProductCategoryAll = (params) => (dispatch) => {
  return dispatch({
    // type: PRODUCT_CATEGORY_ACTIONS.SEARCH_DATA,
    payload: searchProductCategoryAll(params)
  });
};
