import { STATUS_INFO_ACTIONS } from 'actions/statusinfo';

const INIT_STATE = {
  statusInfoCont: { data: [] },
  statusInfoContNo: { data: [] },
  statusInfoDriverPlan: { data: [] },
  receiveStatuses: [],
  quotationDetailStatuses: [],
  allStatus: [],
  transportCostFeeStatusWithDriverAction: []
};

const statusInfoReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // get status info of cont
    case STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_CONT_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_CONT_FULFILLED: {
      let statusInfoCont = {
        data: action.payload.data.data
      };
      return {
        ...state,
        statusInfoCont: statusInfoCont,
        isLoading: false
      };
    }

    case STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_CONT_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // get status info of driver plan
    case STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_DRIVER_PLAN_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_DRIVER_PLAN_FULFILLED: {
      let statusInfoDriverPlan = {
        data: action.payload.data.data
      };
      return {
        ...state,
        statusInfoDriverPlan: statusInfoDriverPlan,
        isLoading: false
      };
    }

    case STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_DRIVER_PLAN_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // get status of cont no
    case STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_CONT_NO_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_CONT_NO_FULFILLED: {
      let statusInfoCont = {
        data: action.payload.data.data
      };
      return {
        ...state,
        statusInfoContNo: statusInfoCont,
        isLoading: false
      };
    }

    case STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_CONT_NO_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };
    //STATUS repair
    case STATUS_INFO_ACTIONS.GET_STATUS_REPAIR_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case STATUS_INFO_ACTIONS.GET_STATUS_REPAIR_FULFILLED: {
      let statusRepair = {
        data: action.payload.data.data
      };
      return {
        ...state,
        statusRepair: statusRepair,
        isLoading: false
      };
    }

    case STATUS_INFO_ACTIONS.GET_STATUS_REPAIR_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    //STATUS car
    case STATUS_INFO_ACTIONS.GET_STATUS_CAR_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case STATUS_INFO_ACTIONS.GET_STATUS_CAR_FULFILLED:
      return {
        ...state,
        statusCar: action.payload.data.data,
        isLoading: false
      };

    case STATUS_INFO_ACTIONS.GET_STATUS_CAR_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    //STATUS car
    case STATUS_INFO_ACTIONS.GET_RECEIVE_STATUS_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case STATUS_INFO_ACTIONS.GET_RECEIVE_STATUS_FULFILLED:
      return {
        ...state,
        receiveStatuses: action.payload.data.data,
        isLoading: false
      };

    case STATUS_INFO_ACTIONS.GET_RECEIVE_STATUS_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    //STATUS Quotation
    case STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_QUOTATION_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_QUOTATION_FULFILLED:
      return {
        ...state,
        quotationStatuses: action.payload.data.data,
        isLoading: false
      };

    case STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_QUOTATION_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    //STATUS Quotation detail
    case STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_QUOTATION_DETAIL_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_QUOTATION_DETAIL_FULFILLED:
      return {
        ...state,
        quotationDetailStatuses: action.payload.data.data,
        isLoading: false
      };

    case STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_QUOTATION_DETAIL_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    //STATUS Quotation detail
    case STATUS_INFO_ACTIONS.GET_STATUS_TRANSPORT_COST_PAYMENT_WITH_DRIVER_ACTIONS_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case STATUS_INFO_ACTIONS.GET_STATUS_TRANSPORT_COST_PAYMENT_WITH_DRIVER_ACTIONS_FULFILLED:
      return {
        ...state,
        transportCostFeeStatusWithDriverAction: action.payload.data.data,
        isLoading: false
      };

    case STATUS_INFO_ACTIONS.GET_STATUS_TRANSPORT_COST_PAYMENT_WITH_DRIVER_ACTIONS_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    //STATUS all status
    case STATUS_INFO_ACTIONS.GET_ALL_STATUS_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case STATUS_INFO_ACTIONS.GET_ALL_STATUS_FULFILLED:
      return {
        ...state,
        allStatus: action.payload.data.data,
        isLoading: false
      };

    case STATUS_INFO_ACTIONS.GET_ALL_STATUS_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    default:
      return state;
  }
};

export default statusInfoReducer;
