import {   FETCH_WORK_NOTIFICATIONS_FULFILLED,
  FETCH_WORK_NOTIFICATIONS_PENDING,
  FETCH_WORK_NOTIFICATIONS_REJECTED,
NOTIFICATION_ACTIONS } from 'actions/notification';
import { MSG_PAGING_FAILURE, MSG_TYPE_ERROR } from 'const/common';

const INIT_STATE = {
  listPerPage: [],
  message: '',
  isSearch: false,
  pagination: {},
  works: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_ACTIONS.GET_NOTIFICATION_PER_PAGE_PENDING:
      return {
        ...state
      };
    case NOTIFICATION_ACTIONS.GET_NOTIFICATION_PER_PAGE_FULFILLED:
      return {
        ...state,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination
      };
    case NOTIFICATION_ACTIONS.GET_NOTIFICATION_PER_PAGE_REJECTED:
      return {
        ...state,
        message: 'Không thể lấy được dữ liệu!'
      };

    case NOTIFICATION_ACTIONS.CREATE_NOTIFICATION_PENDING:
      return {
        ...state
      };
    case NOTIFICATION_ACTIONS.CREATE_NOTIFICATION_FULFILLED:
      return {
        ...state,
        message: 'Thêm mới dữ liệu thành công!'
      };
    case NOTIFICATION_ACTIONS.CREATE_NOTIFICATION_REJECTED:
      return {
        ...state,
        message: 'Không thể thêm mới dữ liệu!'
      };

    case NOTIFICATION_ACTIONS.UPDATE_NOTIFICATION_PENDING:
      return {
        ...state
      };
    case NOTIFICATION_ACTIONS.UPDATE_NOTIFICATION_FULFILLED:
      return {
        ...state,
        message: 'Thêm mới dữ liệu thành công!'
      };
    case NOTIFICATION_ACTIONS.UPDATE_NOTIFICATION_REJECTED:
      return {
        ...state,
        message: 'Không thể thêm mới dữ liệu!'
      };

    case NOTIFICATION_ACTIONS.DELETE_NOTIFICATION_PENDING:
      return {
        ...state
      };
    case NOTIFICATION_ACTIONS.DELETE_NOTIFICATION_FULFILLED:
      return {
        ...state,
        message: 'Xóa dữ liệu thành công!'
      };
    case NOTIFICATION_ACTIONS.DELETE_NOTIFICATION_REJECTED:
      return {
        ...state,
        message: 'Không thể xóa dữ liệu!'
      };
    
    //#region paging
    case FETCH_WORK_NOTIFICATIONS_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }

    case FETCH_WORK_NOTIFICATIONS_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        works: action.payload.data.data ?? INIT_STATE.works,
        showMessage: false
      };
    }
    case FETCH_WORK_NOTIFICATIONS_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    default:
      return state;
  }
};
