import httpClient from 'HttpClient';
import {
  createOrderManagement,
  deleteOrderExportManagement,
  deleteOrderManagement,
  getConsignmentByConsignmentId,
  getCustomsClearanceCompletedByOrderManagementID,
  getOrderManagementByCustomsClearanceCompleted,
  getOrderManagementByCustomsClearanceCompletedNew,
  getOrderManagementInputByID,
  getOrderManagementOutputByID,
  getOrderManagementPerPage,
  getSeaTransportConsignment,
  searchOrderManagementInputPerPage,
  searchOrderManagementOutputPerPage,
  updateOrderManagement,
  updateOrderManagementDailytrucking
} from 'services/ordermanagement';
// define const actions
export const ORDER_MANAGEMENT_ACTIONS = {
  GET_ORDER_MANAGEMENT_BY_CUSTOMS_CLEARANCE_COMPLETED: 'GET_ORDER_MANAGEMENT_BY_CUSTOMS_CLEARANCE_COMPLETED',
  GET_ORDER_MANAGEMENT_BY_CUSTOMS_CLEARANCE_COMPLETED_PENDING:
    'GET_ORDER_MANAGEMENT_BY_CUSTOMS_CLEARANCE_COMPLETED_PENDING',
  GET_ORDER_MANAGEMENT_BY_CUSTOMS_CLEARANCE_COMPLETED_FULFILLED:
    'GET_ORDER_MANAGEMENT_BY_CUSTOMS_CLEARANCE_COMPLETED_FULFILLED',
  GET_ORDER_MANAGEMENT_BY_CUSTOMS_CLEARANCE_COMPLETED_REJECTED:
    'GET_ORDER_MANAGEMENT_BY_CUSTOMS_CLEARANCE_COMPLETED_REJECTED',

  GET_CUSTOMS_CLEARANCE_COMPLETED_BY_ORDER_MANAGEMENT_ID: 'GET_CUSTOMS_CLEARANCE_COMPLETED_BY_ORDER_MANAGEMENT_ID',
  GET_CUSTOMS_CLEARANCE_COMPLETED_BY_ORDER_MANAGEMENT_ID_PENDING:
    'GET_CUSTOMS_CLEARANCE_COMPLETED_BY_ORDER_MANAGEMENT_ID_PENDING',
  GET_CUSTOMS_CLEARANCE_COMPLETED_BY_ORDER_MANAGEMENT_ID_FULFILLED:
    'GET_CUSTOMS_CLEARANCE_COMPLETED_BY_ORDER_MANAGEMENT_ID_FULFILLED',
  GET_CUSTOMS_CLEARANCE_COMPLETED_BY_ORDER_MANAGEMENT_ID_REJECTED:
    'GET_CUSTOMS_CLEARANCE_COMPLETED_BY_ORDER_MANAGEMENT_ID_REJECTED',

  // getCustomsClearanceCompletedByOrderManagementID

  // input
  GET_ORDER_MANAGEMENT_INPUT_PER_PAGE: 'GET_ORDER_MANAGEMENT_INPUT_PER_PAGE',
  GET_ORDER_MANAGEMENT_INPUT_PER_PAGE_PENDING: 'GET_ORDER_MANAGEMENT_INPUT_PER_PAGE_PENDING',
  GET_ORDER_MANAGEMENT_INPUT_PER_PAGE_FULFILLED: 'GET_ORDER_MANAGEMENT_INPUT_PER_PAGE_FULFILLED',
  GET_ORDER_MANAGEMENT_INPUT_PER_PAGE_REJECTED: 'GET_ORDER_MANAGEMENT_INPUT_PER_PAGE_REJECTED',

  GET_ORDER_MANAGEMENT_INPUT_BY_ID: 'GET_ORDER_MANAGEMENT_INPUT_BY_ID',
  GET_ORDER_MANAGEMENT_INPUT_BY_ID_PENDING: 'GET_ORDER_MANAGEMENT_INPUT_BY_ID_PENDING',
  GET_ORDER_MANAGEMENT_INPUT_BY_ID_FULFILLED: 'GET_ORDER_MANAGEMENT_INPUT_BY_ID_FULFILLED',
  GET_ORDER_MANAGEMENT_INPUT_BY_ID_REJECTED: 'GET_ORDER_MANAGEMENT_INPUT_BY_ID_REJECTED',

  SEARCH_ORDER_MANAGEMENT_INPUT: 'SEARCH_ORDER_MANAGEMENT_INPUT',
  SEARCH_ORDER_MANAGEMENT_INPUT_PENDING: 'SEARCH_ORDER_MANAGEMENT_INPUT_PENDING',
  SEARCH_ORDER_MANAGEMENT_INPUT_FULFILLED: 'SEARCH_ORDER_MANAGEMENT_INPUT_FULFILLED',
  SEARCH_ORDER_MANAGEMENT_INPUT_REJECTED: 'SEARCH_ORDER_MANAGEMENT_INPUT_REJECTED',
  // end input

  // output
  GET_ORDER_MANAGEMENT_OUTPUT_PER_PAGE: 'GET_ORDER_MANAGEMENT_OUTPUT_PER_PAGE',
  GET_ORDER_MANAGEMENT_OUTPUT_PER_PAGE_PENDING: 'GET_ORDER_MANAGEMENT_OUTPUT_PER_PAGE_PENDING',
  GET_ORDER_MANAGEMENT_OUTPUT_PER_PAGE_FULFILLED: 'GET_ORDER_MANAGEMENT_OUTPUT_PER_PAGE_FULFILLED',
  GET_ORDER_MANAGEMENT_OUTPUT_PER_PAGE_REJECTED: 'GET_ORDER_MANAGEMENT_OUTPUT_PER_PAGE_REJECTED',

  GET_ORDER_MANAGEMENT_OUTPUT_BY_ID: 'GET_ORDER_MANAGEMENT_OUTPUT_BY_ID',
  GET_ORDER_MANAGEMENT_OUTPUT_BY_ID_PENDING: 'GET_ORDER_MANAGEMENT_OUTPUT_BY_ID_PENDING',
  GET_ORDER_MANAGEMENT_OUTPUT_BY_ID_FULFILLED: 'GET_ORDER_MANAGEMENT_OUTPUT_BY_ID_FULFILLED',
  GET_ORDER_MANAGEMENT_OUTPUT_BY_ID_REJECTED: 'GET_ORDER_MANAGEMENT_OUTPUT_BY_ID_REJECTED',

  SEARCH_ORDER_MANAGEMENT_OUTPUT: 'SEARCH_ORDER_MANAGEMENT_OUTPUT',
  SEARCH_ORDER_MANAGEMENT_OUTPUT_PENDING: 'SEARCH_ORDER_MANAGEMENT_OUTPUT_PENDING',
  SEARCH_ORDER_MANAGEMENT_OUTPUT_FULFILLED: 'SEARCH_ORDER_MANAGEMENT_OUTPUT_FULFILLED',
  SEARCH_ORDER_MANAGEMENT_OUTPUT_REJECTED: 'SEARCH_ORDER_MANAGEMENT_OUTPUT_REJECTED',
  // end output

  DELETE_ORDER_MANAGEMENT: 'DELETE_ORDER_MANAGEMENT',
  DELETE_ORDER_MANAGEMENT_PENDING: 'DELETE_ORDER_MANAGEMENT_PENDING',
  DELETE_ORDER_MANAGEMENT_FULFILLED: 'DELETE_ORDER_MANAGEMENT_FULFILLED',
  DELETE_ORDER_MANAGEMENT_REJECTED: 'DELETE_ORDER_MANAGEMENT_REJECTED',

  CREATE_ORDER_MANAGEMENT: 'CREATE_ORDER_MANAGEMENT',
  CREATE_ORDER_MANAGEMENT_PENDING: 'CREATE_ORDER_MANAGEMENT_PENDING',
  CREATE_ORDER_MANAGEMENT_FULFILLED: 'CREATE_ORDER_MANAGEMENT_FULFILLED',
  CREATE_ORDER_MANAGEMENT_REJECTED: 'CREATE_ORDER_MANAGEMENT_REJECTED',

  UPDATE_ORDER_MANAGEMENT: 'UPDATE_ORDER_MANAGEMENT',
  UPDATE_ORDER_MANAGEMENT_PENDING: 'UPDATE_ORDER_MANAGEMENT_PENDING',
  UPDATE_ORDER_MANAGEMENT_FULFILLED: 'UPDATE_ORDER_MANAGEMENT_FULFILLED',
  UPDATE_ORDER_MANAGEMENT_REJECTED: 'UPDATE_ORDER_MANAGEMENT_REJECTED',

  GET_ALL_AREA: 'GET_ALL_AREA',
  GET_ALL_AREA_PENDING: 'GET_ALL_AREA_PENDING',
  GET_ALL_AREA_FULFILLED: 'GET_ALL_AREA_FULFILLED',
  GET_ALL_AREA_REJECTED: 'GET_ALL_AREA_REJECTED',

  RESET_DATA: 'RESET_DATA',

  GET_SEA_TRANSPORT_CONSIGNMENT: 'GET_SEA_TRANSPORT_CONSIGNMENT',
  GET_SEA_TRANSPORT_CONSIGNMENT_PENDING: 'GET_SEA_TRANSPORT_CONSIGNMENT_PENDING',
  GET_SEA_TRANSPORT_CONSIGNMENT_FULFILLED: 'GET_SEA_TRANSPORT_CONSIGNMENT_FULFILLED',
  GET_SEA_TRANSPORT_CONSIGNMENT_REJECTED: 'GET_SEA_TRANSPORT_CONSIGNMENT_REJECTED',

  UPDATE_TOTAL_WEIGHT_OF_CONSIGNMENT: 'UPDATE_TOTAL_WEIGHT_OF_CONSIGNMENT'
};

// define actions
// input
export const actionGetOrderManagementInputPerPage = (pageNo, pageSize, type) => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_INPUT_PER_PAGE,
    payload: getOrderManagementPerPage(pageNo, pageSize, type)
  });
};

export const actionSearchOrderManagementInputPerPage = (payload, pageNo, pageSize, type) => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.SEARCH_ORDER_MANAGEMENT_INPUT,
    payload: searchOrderManagementInputPerPage(payload, pageNo, pageSize, type)
  });
};
export const actionSearchOrderManagementInputPerPageALL = (payload, pageNo, pageSize, type) => (dispatch) => {
  return dispatch({
    // type: ORDER_MANAGEMENT_ACTIONS.SEARCH_ORDER_MANAGEMENT_INPUT,
    payload: searchOrderManagementInputPerPage(payload, pageNo, pageSize, type)
  });
};
export const actionSearchOrderManagementInputPerPageAll = (payload, pageNo, pageSize, type) => (dispatch) => {
  return dispatch({
    payload: searchOrderManagementInputPerPage(payload, pageNo, pageSize, type)
  });
};

export const actionGetOrderManagementInputByID = (id) => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_INPUT_BY_ID,
    payload: getOrderManagementInputByID(id)
  });
};
// end input

// output
export const actionGetOrderManagementOutputPerPage = (pageNo, pageSize, type) => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_OUTPUT_PER_PAGE,
    payload: getOrderManagementPerPage(pageNo, pageSize, type)
  });
};

export const actionSearchOrderManagementOutputPerPage = (payload, pageNo, pageSize, type) => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.SEARCH_ORDER_MANAGEMENT_OUTPUT,
    payload: searchOrderManagementOutputPerPage(payload, pageNo, pageSize, type)
  });
};

export const actionGetOrderManagementOutputByID = (id) => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_OUTPUT_BY_ID,
    payload: getOrderManagementOutputByID(id)
  });
};
// end output

export const actionDeleteOrderManagementByID = (id, currentPage) => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.DELETE_ORDER_MANAGEMENT,
    payload: deleteOrderManagement(id),
    meta: {
      currentPage: currentPage
    }
  });
};
export const actionDeleteOrderExportManagementByID = (id, currentPage) => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.DELETE_ORDER_MANAGEMENT,
    payload: deleteOrderExportManagement(id),
    meta: {
      currentPage: currentPage
    }
  });
};
export const actionCreateOrderManagement = (payload, type) => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.CREATE_ORDER_MANAGEMENT,
    payload: createOrderManagement(payload, type),
    meta: {
      currentPage: payload.currentPage
    }
  });
};

export const actionUpdateOrderManagement = (payload, type) => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.UPDATE_ORDER_MANAGEMENT,
    payload: updateOrderManagement(payload, type),
    meta: {
      currentPage: payload.currentPage
    }
  });
};

export const actionUpdateOrderManagementDailytrucking = (payload, type) => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.UPDATE_ORDER_MANAGEMENT,
    payload: updateOrderManagementDailytrucking(payload, type),
    meta: {
      currentPage: payload.currentPage
    }
  });
};

export const actionGetOrderManagementByCustomsClearanceCompleted = (searchValue, pagesize) => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_BY_CUSTOMS_CLEARANCE_COMPLETED,
    payload: getOrderManagementByCustomsClearanceCompleted(searchValue, pagesize)
  });
};

export const actionGetOrderManagementByCustomsClearanceCompletedNew = (params) => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_BY_CUSTOMS_CLEARANCE_COMPLETED,
    payload: getOrderManagementByCustomsClearanceCompletedNew(params)
  });
};

export const actionGetCustomsClearanceCompletedByOrderManagementID = (id) => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.GET_CUSTOMS_CLEARANCE_COMPLETED_BY_ORDER_MANAGEMENT_ID,
    payload: getCustomsClearanceCompletedByOrderManagementID(id),
    meta: id
  });
};
export const actiongetConsignmentByConsignmentId = (id) => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.GET_CUSTOMS_CLEARANCE_COMPLETED_BY_ORDER_MANAGEMENT_ID,
    payload: getConsignmentByConsignmentId(id),
    meta: id
  });
};

export const getAreas = () => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.GET_ALL_AREA,
    payload: httpClient.get('/areainfo/getall')
  });
};

export const resetData = () => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.RESET_DATA
  });
};

export const actionGetSeaTransportConsignment = (id) => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.GET_SEA_TRANSPORT_CONSIGNMENT,
    payload: getSeaTransportConsignment()
  });
};

export const actionUpdateTotalWeightConsignment = (payload) => (dispatch) => {
  return dispatch({
    type: ORDER_MANAGEMENT_ACTIONS.UPDATE_TOTAL_WEIGHT_OF_CONSIGNMENT,
    payload: httpClient.put('/Consignment/update/totalWeight', payload)
  });
};
