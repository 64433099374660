import api from 'HttpClient';

export const SET_OPEN_WORKING_PRIORITY_MODAL = 'SET_OPEN_WORKING_PRIORITY_MODAL';
export const setOpenWorkingPriorityModal = (data) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_WORKING_PRIORITY_MODAL,
    payload: data
  });
};

export const SET_CLOSE_WORKING_PRIORITY_MODAL = 'SET_CLOSE_WORKING_PRIORITY_MODAL';
export const setCloseWorkingPriorityModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_WORKING_PRIORITY_MODAL
  });
};

export const FETCH_ALL_WORKING_PRIORITIES = 'FETCH_ALL_WORKING_PRIORITIES';
export const FETCH_ALL_WORKING_PRIORITIES_PENDING = 'FETCH_ALL_WORKING_PRIORITIES_PENDING';
export const FETCH_ALL_WORKING_PRIORITIES_FULFILLED = 'FETCH_ALL_WORKING_PRIORITIES_FULFILLED';
export const FETCH_ALL_WORKING_PRIORITIES_REJECTED = 'FETCH_ALL_WORKING_PRIORITIES_REJECTED';
export const fetchAllWorkingPriorities = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_WORKING_PRIORITIES,
    payload: api.get(`/WorkingPriorityInfo/getAll`)
  });
};

// const type of function get data per page
export const FETCH_WORKING_PRIORITIES = 'FETCH_WORKING_PRIORITIES';
export const FETCH_WORKING_PRIORITIES_PENDING = 'FETCH_WORKING_PRIORITIES_PENDING';
export const FETCH_WORKING_PRIORITIES_FULFILLED = 'FETCH_WORKING_PRIORITIES_FULFILLED';
export const FETCH_WORKING_PRIORITIES_REJECTED = 'FETCH_WORKING_PRIORITIES_REJECTED';
export const fetchWorkingPriorities = (searchPayload, page, pageSize) => (dispatch) => {
  return dispatch({
    type: FETCH_WORKING_PRIORITIES,
    payload: api.get(`/WorkingPriorityInfo/Search`, { params: { ...searchPayload, page, pageSize: pageSize } })
  });
};

// const type of function create data
export const CREATE_WORKING_PRIORITY = 'CREATE_WORKING_PRIORITY';
export const CREATE_WORKING_PRIORITY_PENDING = 'CREATE_WORKING_PRIORITY_PENDING';
export const CREATE_WORKING_PRIORITY_FULFILLED = 'CREATE_WORKING_PRIORITY_FULFILLED';
export const CREATE_WORKING_PRIORITY_REJECTED = 'CREATE_WORKING_PRIORITY_REJECTED';
export const createWorkingPriority = (payload) => (dispatch) => {
  return dispatch({
    type: CREATE_WORKING_PRIORITY,
    payload: api.post(`/WorkingPriorityInfo/create`, payload)
  });
};

// const type of function update data
export const UPDATE_WORKING_PRIORITY = 'UPDATE_WORKING_PRIORITY';
export const UPDATE_WORKING_PRIORITY_PENDING = 'UPDATE_WORKING_PRIORITY_PENDING';
export const UPDATE_WORKING_PRIORITY_FULFILLED = 'UPDATE_WORKING_PRIORITY_FULFILLED';
export const UPDATE_WORKING_PRIORITY_REJECTED = 'UPDATE_WORKING_PRIORITY_REJECTED';
export const updateWorkingPriority = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_WORKING_PRIORITY,
    payload: api.put(`/WorkingPriorityInfo/update`, payload),
    meta: payload
  });
};
// const type of get deatail
export const FETCH_WORKING_PRIORITY_DETAIL = 'FETCH_WORKING_PRIORITY_DETAIL';
export const FETCH_WORKING_PRIORITY_DETAIL_PENDING = 'FETCH_WORKING_PRIORITY_DETAIL_PENDING';
export const FETCH_WORKING_PRIORITY_DETAIL_FULFILLED = 'FETCH_WORKING_PRIORITY_DETAIL_FULFILLED';
export const FETCH_WORKING_PRIORITY_DETAIL_REJECTED = 'FETCH_WORKING_PRIORITY_DETAIL_REJECTED';
export const getWorkingPriorityDetail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_WORKING_PRIORITY_DETAIL,
    payload: api.get(`/WorkingPriorityInfo/${id}`)
  });
};

// const type of delete
export const DELETE_WORKING_PRIORITY = 'DELETE_WORKING_PRIORITY';
export const DELETE_WORKING_PRIORITY_PENDING = 'DELETE_WORKING_PRIORITY_PENDING';
export const DELETE_WORKING_PRIORITY_FULFILLED = 'DELETE_WORKING_PRIORITY_FULFILLED';
export const DELETE_WORKING_PRIORITY_REJECTED = 'DELETE_WORKING_PRIORITY_REJECTED';
export const deleteWorkingPriority = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_WORKING_PRIORITY,
    payload: api.delete(`/WorkingPriorityInfo/delete/${id}`)
  });
};
