import httpClient from "HttpClient";
export const TRANSPORT_COMPANY_MANAGEMENT_ACTIONS = {
  GET_DATA_COMPANY_PER_PAGE: "GET_DATA_COMPANY_PER_PAGE",
  GET_DATA_COMPANY_PER_PAGE_PENDING: "GET_DATA_COMPANY_PER_PAGE_PENDING",
  GET_DATA_COMPANY_PER_PAGE_FULFILLED: "GET_DATA_COMPANY_PER_PAGE_FULFILLED",
  GET_DATA_COMPANY_PER_PAGE_REJECTED: "GET_DATA_COMPANY_PER_PAGE_REJECTED",

  CREATE_DATA_COMPANY: "CREATE_DATA_COMPANY",
  CREATE_DATA_COMPANY_PENDING: "CREATE_DATA_COMPANY_PENDING",
  CREATE_DATA_COMPANY_FULFILLED: "CREATE_DATA_COMPANY_FULFILLED",
  CREATE_DATA_COMPANY_REJECTED: "CREATE_DATA_COMPANY_REJECTED",

  UPDATE_INFO_COMPANY: "UPDATE_INFO_COMPANY",
  UPDATE_INFO_COMPANY_PENDING: "UPDATE_INFO_COMPANY_PENDING",
  UPDATE_INFO_COMPANY_FULFILLED: "UPDATE_INFO_COMPANY_FULFILLED",
  UPDATE_INFO_COMPANY_REJECTED: "UPDATE_INFO_COMPANY_REJECTED",

  DELETE_INFO_COMPANY: "DELETE_INFO_COMPANY",
  DELETE_INFO_COMPANY_PENDING: "DELETE_INFO_COMPANY_PENDING",
  DELETE_INFO_COMPANY_FULFILLED: "DELETE_INFO_COMPANY_FULFILLED",
  DELETE_INFO_COMPANY_REJECTED: "DELETE_INFO_COMPANY_REJECTED",

  SEARCH_DATA: "SEARCH_DATA",
  SEARCH_DATA_PENDING: "SEARCH_DATA_PENDING",
  SEARCH_DATA_FULFILLED: "SEARCH_DATA_FULFILLED",
  SEARCH_DATA_REJECTED: "SEARCH_DATA_REJECTED",
};

export const actionGetCompanyInfoPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.GET_DATA_COMPANY_PER_PAGE,
    payload: httpClient.get(
      `/TransportCompanyInfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`
    ),
  });
};

export const actionCreateCompanyInfo = (payload) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.CREATE_DATA_COMPANY,
    payload: httpClient.post(`/TransportCompanyInfo/create`, payload),
  });
};

export const actionUpdateCompanyInfo = (payload) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.UPDATE_INFO_COMPANY,
    meta: { Id: payload.Id },
    payload: httpClient.put(`/TransportCompanyInfo/update`, payload),
  });
};

export const actionDeleteCompanyInfo = (id) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.DELETE_INFO_COMPANY,
    payload: httpClient.delete(`/TransportCompanyInfo/delete/${id}`),
  });
};

export const updateShowAlert = () => {
  return {
    type: "UPDATE_SHOW_ALERT",
  };
};

export const actionSearchCompanyInfo = (value, pageNo, pageSize) => (
  dispatch
) => {
  return dispatch({
    type: TRANSPORT_COMPANY_MANAGEMENT_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(
      `/TransportCompanyInfo/search?value=${value}&Page=${pageNo}&PageSize=${pageSize}`
    ),
  });
};
