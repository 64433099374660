import { SHIPPING_LIST_REPORT } from 'actions/ShippingListReportAction';

const INIT_STATE = {
  data: [],
  pagination: {},
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHIPPING_LIST_REPORT.SHIPPING_LIST_REPORT_TYPE_FULFILLED: {
      return { ...state, data: action.payload.data.data, pagination: action.payload.data.pagination, loading: false };
    }
    case SHIPPING_LIST_REPORT.SHIPPING_LIST_REPORT_TYPE_PENDING: {
      return { ...state, loading: true };
    }
    case SHIPPING_LIST_REPORT.SHIPPING_LIST_REPORT_TYPE_REJECTED: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};
