import { REPAIR_DETAILS } from 'actions/repair/repairInvoiceDetails';

const RepairInvoiceDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    //get by id
    case REPAIR_DETAILS.GET_REPAIR_INVOICE_DETAILS_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case REPAIR_DETAILS.GET_REPAIR_INVOICE_DETAILS_BY_ID_FULFILLED:
      return {
        ...state,
        dataById: action.payload.data,
        isLoading: false,
        repairingStatus: action.payload.data.repairingStatus
      };

    case REPAIR_DETAILS.GET_REPAIR_INVOICE_DETAILS_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };
    // create
    case REPAIR_DETAILS.CREATE_REPAIR_INVOICE_DETAILS_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case REPAIR_DETAILS.CREATE_REPAIR_INVOICE_DETAILS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Tạo dữ liệu thành công'
      };

    case REPAIR_DETAILS.CREATE_REPAIR_INVOICE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: true,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Tạo dữ liệu thất bại'
      };

    // update
    case REPAIR_DETAILS.UPDATE_REPAIR_INVOICE_DETAILS_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case REPAIR_DETAILS.UPDATE_REPAIR_INVOICE_DETAILS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Sửa dữ liệu thành công',
        typeMessage: 'success'
      };

    case REPAIR_DETAILS.UPDATE_REPAIR_INVOICE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Sửa dữ liệu thất bại'
      };

    // delete
    case REPAIR_DETAILS.DELETE_REPAIR_INVOICE_DETAILS_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case REPAIR_DETAILS.DELETE_REPAIR_INVOICE_DETAILS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Xóa dữ liệu thành công'
      };

    case REPAIR_DETAILS.DELETE_REPAIR_INVOICE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Sửa dữ liệu thất bại'
      };

    //get all
    case REPAIR_DETAILS.GET_ALL_REPAIR_INVOICE_DETAILS_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case REPAIR_DETAILS.GET_ALL_REPAIR_INVOICE_DETAILS_FULFILLED:
      return {
        ...state,
        allData: action.payload.data.data,
        isLoading: false
      };

    case REPAIR_DETAILS.GET_ALL_REPAIR_INVOICE_DETAILS_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    case REPAIR_DETAILS.CHANGE_DATA_EDIT_TABEL_REPAIR: {
      const data = action.payload;
      let dataReturn = { ...state.dataById, ...data, error: action.meta?.error };
      return {
        ...state,
        isLoading: false,
        dataById: dataReturn,
        isUpdateSubmit: action.meta.message !== undefined ? false : true
      };
    }

    default:
      return state;
  }
};

export default RepairInvoiceDetailsReducer;
