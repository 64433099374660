import {
  CHANGE_AMOUNT_PAYMENT,
  CHANGE_PRICE_DRIVER_PAYMENT,
  CHANGE_PRICE_PAYMENT,
  FETCH_ADVANCE_REPORTS_FULFILLED,
  FETCH_ADVANCE_REPORTS_PENDING,
  FETCH_ADVANCE_REPORTS_REJECTED,
  FETCH_REPORTS_FULFILLED,
  FETCH_REPORTS_PENDING,
  FETCH_REPORTS_REJECTED,
  HANDEL_CHANGE_VALUE_INPUT_REPORT,
  HANDEL_CHANGE_VALUE_INPUT_REPORT_FOLLOW_FIELD,
  RESET_REPORT,
  SET_REPORT_SEARCH_PARAMS,
  UPDATE_NOTE,
  UPDATE_ORDER_NUM
} from 'actions/ReportAction';
import { getErrorMessage } from 'commons/ReducerUtils';
import { removeDotsAndCommas } from 'commons/ValidateEditable';
import {
  DEFAULT_PAGE_SIZE,
  MSG_PAGING_FAILURE,
  // MSG_TYPE_SUCCESS,
  MSG_TYPE_ERROR
} from 'const/common';
import moment from 'moment';
import { TYPE_NUMBER } from 'Pages/Report/BussinessResultReport/Form/utils';

const INIT_STATE = {
  reports: [],
  pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
  loading: false,
  showMessage: false,
  alertMessage: '',
  messageType: '',
  search: {
    startDate: moment().startOf('month'),
    endDate: moment(),
    fromDate: moment().startOf('month'),
    toDate: moment(),
    startDateTransportReportSearch: moment().startOf('month'),
    endDateTransportReportSearch: moment(),
    textSearch: '',
    searchValue: null,
    searchType: 1
  }
};

function calculateChangingAmount(value, item, reportsList) {
  const id = item.Id;
  const updateList = [...reportsList];
  const index = updateList.findIndex((item) => item.Id === id);
  updateList[index].Amount = value ? value : 0;
  const price = updateList[index]?.Price ? updateList[index].Price : 0;
  updateList[index].TotalMoney = value * price;
  return updateList;
}

function calculateChangingPrice(value, item, reportsList, type = null) {
  const id = item.Id;
  const updateList = [...reportsList];
  const index = updateList.findIndex((item) => item.Id === id);
  updateList[index].Price = value ? value : 0;
  const amount = updateList[index]?.Amount ? updateList[index].Amount : 0;
  updateList[index].TotalMoney = type ? value : value * amount;
  return updateList;
}

function processingHandelInput(reports, payloadData) {
  const valueEdit = payloadData.value;
  const fieldName = payloadData.fieldName;
  const indexItemEdit = payloadData.index;
  const validateMessage = payloadData.message;
  const typeInput = payloadData.type;
  const valueUpdate =
    typeInput === TYPE_NUMBER && !validateMessage ? parseInt(removeDotsAndCommas(valueEdit)) : valueEdit;
  const itemEdit = reports[indexItemEdit];
  let error = { ...itemEdit.error, [fieldName]: validateMessage };
  const itemEditUpdated = {
    ...itemEdit,
    [fieldName]: valueUpdate,
    isEdit: true,
    error: error
  };
  const reportUpdated = [...reports];
  reportUpdated.splice(indexItemEdit, 1, itemEditUpdated);
  const mapReportsUpdatedWithValidateValue = reportUpdated.map((el) => {
    return {
      ...el,
      //   error: { ...el.error, [fieldName]: validateMessage },
      isDisableBtnDelete: !el.Id ? false : true
    };
  });

  return mapReportsUpdatedWithValidateValue;
}

const changeRecordFollowField = (value, index, fieldName, reports) => {
  let Reports = reports;
  Reports[index][fieldName] = value;
  return Reports;
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // get data
    case FETCH_REPORTS_PENDING: {
      return {
        ...state,
        loading: true,
        // reports: [],
        showMessage: false
      };
    }
    case FETCH_REPORTS_FULFILLED: {
      const data =
        !action.payload.config.params.isFetchMore ||
        action?.payload?.data?.pagination?.pageNo === 1 ||
        !action?.payload?.data?.pagination
          ? action?.payload?.data?.data
          : state.reports.concat(action?.payload?.data?.data);
      return {
        ...state,
        loading: false,
        reports: data,
        pagination: action.payload.data.pagination ? action.payload.data.pagination : INIT_STATE.pagination,
        showMessage: false
      };
    }
    case FETCH_REPORTS_REJECTED: {
      let alertMessage = MSG_PAGING_FAILURE;
      if (action.payload.response.status === 400) {
        alertMessage = getErrorMessage(action);
      }
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }

    //Get data for AdvanceManagement
    case FETCH_ADVANCE_REPORTS_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case FETCH_ADVANCE_REPORTS_FULFILLED: {
      return {
        ...state,
        loading: false,
        reports: action.payload.data.data,
        pagination: action.payload.data.pagination ? action.payload.data.pagination : INIT_STATE.pagination,
        showMessage: false
      };
    }
    case FETCH_ADVANCE_REPORTS_REJECTED: {
      let alertMessage = MSG_PAGING_FAILURE;
      if (action.payload.response?.status === 400) {
        alertMessage = getErrorMessage(action);
      }
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }

    case RESET_REPORT: {
      return {
        ...INIT_STATE
      };
    }

    case SET_REPORT_SEARCH_PARAMS: {
      const payload = action.payload;
      let newSearchState = {};
      Object.keys(payload).forEach((key) => {
        newSearchState[key] = payload[key];
      });
      return {
        ...state,
        search: {
          ...state.search,
          ...newSearchState
        }
      };
    }

    case CHANGE_AMOUNT_PAYMENT: {
      const { value, item } = action.payload;
      const updatedList = calculateChangingAmount(value, item, state.reports);
      return {
        ...state,
        reports: updatedList
      };
    }

    case CHANGE_PRICE_PAYMENT: {
      const { value, item } = action.payload;
      let reportsCoppied = [...state.reports];
      const updatedList = calculateChangingPrice(value, item, reportsCoppied);
      return {
        ...state,
        reports: updatedList
      };
    }

    case UPDATE_ORDER_NUM: {
      let reportsCoppied = [...state.reports];
      const data = reportsCoppied.map((item) => {
        return { ...item, OrderNum: item.Id === action.payload.id ? action.payload.value : item.OrderNum };
      });
      return {
        ...state,
        reports: data
      };
    }

    case UPDATE_NOTE: {
      const reportsCoppied = [...state.reports];
      const data = reportsCoppied.map((item) => {
        return { ...item, Description: item.Id === action.payload.id ? action.payload.value : item.Description };
      });

      return {
        ...state,
        reports: data
      };
    }

    case HANDEL_CHANGE_VALUE_INPUT_REPORT: {
      let reports = [...state.reports];
      const payloadData = action.payload;
      const resultData = processingHandelInput(reports, payloadData);
      return {
        ...state,
        reports: resultData
      };
    }

    //tạm ứng lái xe
    case CHANGE_PRICE_DRIVER_PAYMENT: {
      const { value, item } = action.payload;
      const updatedList = calculateChangingPrice(value, item, state.reports, 'Lái xe');
      return {
        ...state,
        reports: updatedList
      };
    }
    // change record follow field
    case HANDEL_CHANGE_VALUE_INPUT_REPORT_FOLLOW_FIELD: {
      const { value, index, fieldName } = action.payload;
      const newReports = changeRecordFollowField(value, index, fieldName, state.reports);
      return {
        ...state,
        reports: newReports
      };
    }
    default:
      return state;
  }
};
