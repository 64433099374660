import {
  PRODUCT_CATEGORY_ACTIONS
} from "actions/productcategory";
import { DEFAULT_PAGE_SIZE } from 'const/common';
import {
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  getProductCategoryPerPage,
} from "services/productcategory";

function* putActionUpdateData() {
  yield put({
    type: PRODUCT_CATEGORY_ACTIONS.GET_PRODUCT_CATEGORY_PER_PAGE,
    payload: getProductCategoryPerPage(1, DEFAULT_PAGE_SIZE)
  })
}

export function* actionUpdateProductCategoryData() {
  yield takeEvery([PRODUCT_CATEGORY_ACTIONS.UPDATE_PRODUCT_CATEGORY_FULFILLED, PRODUCT_CATEGORY_ACTIONS.CREATE_PRODUCT_CATEGORY_FULFILLED], putActionUpdateData);
}
