import {
    getAllUnitType,
    getUnitTypeByCategory
} from "services/unittype";

// define const actions
export const UNIT_TYPE_ACTIONS = {
    GET_ALL_UNIT_TYPE: "GET_ALL_UNIT_TYPE",
    GET_ALL_UNIT_TYPE_PENDING: "GET_ALL_UNIT_TYPE_PENDING",
    GET_ALL_UNIT_TYPE_FULFILLED: "GET_ALL_UNIT_TYPE_FULFILLED",
    GET_ALL_UNIT_TYPE_REJECTED: "GET_ALL_UNIT_TYPE_REJECTED",

    GET_UNIT_TYPE_BY_CATEGORY: "GET_UNIT_TYPE_BY_CATEGORY",
    GET_UNIT_TYPE_BY_CATEGORY_PENDING: "GET_UNIT_TYPE_BY_CATEGORY_PENDING",
    GET_UNIT_TYPE_BY_CATEGORY_FULFILLED: "GET_UNIT_TYPE_BY_CATEGORY_FULFILLED",
    GET_UNIT_TYPE_BY_CATEGORY_REJECTED: "GET_UNIT_TYPE_BY_CATEGORY_REJECTED",
};


// define actions
export const actionGetAllUnitType = () => dispatch => {
    return dispatch({
        type: UNIT_TYPE_ACTIONS.GET_ALL_UNIT_TYPE,
        payload: getAllUnitType()
    });
};

export const actionGetUnitTypeByCategory = (id) => dispatch => {
    return dispatch({
        type: UNIT_TYPE_ACTIONS.GET_UNIT_TYPE_BY_CATEGORY,
        payload: getUnitTypeByCategory(id)
    });
};