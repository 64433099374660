import api from 'HttpClient';

export const SET_OPEN_WORKING_APPROVAL_STATUS_MODAL = 'SET_OPEN_WORKING_APPROVAL_STATUS_MODAL';
export const setOpenWorkingApprovalStatusModal = (data) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_WORKING_APPROVAL_STATUS_MODAL,
    payload: data
  });
};

export const SET_CLOSE_WORKING_APPROVAL_STATUS_MODAL = 'SET_CLOSE_WORKING_APPROVAL_STATUS_MODAL';
export const setCloseWorkingApprovalStatusModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_WORKING_APPROVAL_STATUS_MODAL
  });
};

export const FETCH_ALL_WORKING_APPROVAL_STATUSES = 'FETCH_ALL_WORKING_APPROVAL_STATUSES';
export const FETCH_ALL_WORKING_APPROVAL_STATUSES_PENDING = 'FETCH_ALL_WORKING_APPROVAL_STATUSES_PENDING';
export const FETCH_ALL_WORKING_APPROVAL_STATUSES_FULFILLED = 'FETCH_ALL_WORKING_APPROVAL_STATUSES_FULFILLED';
export const FETCH_ALL_WORKING_APPROVAL_STATUSES_REJECTED = 'FETCH_ALL_WORKING_APPROVAL_STATUSES_REJECTED';
export const fetchAllWorkingApprovalStatuses = (params) => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_WORKING_APPROVAL_STATUSES,
    payload: api.get(`/WorkingApprovalStatusInfo/getAll`, { params })
  });
};

// const type of function get data per page
export const FETCH_WORKING_APPROVAL_STATUSES = 'FETCH_WORKING_APPROVAL_STATUSES';
export const FETCH_WORKING_APPROVAL_STATUSES_PENDING = 'FETCH_WORKING_APPROVAL_STATUSES_PENDING';
export const FETCH_WORKING_APPROVAL_STATUSES_FULFILLED = 'FETCH_WORKING_APPROVAL_STATUSES_FULFILLED';
export const FETCH_WORKING_APPROVAL_STATUSES_REJECTED = 'FETCH_WORKING_APPROVAL_STATUSES_REJECTED';
export const fetchWorkingApprovalStatuses = (searchPayload, page, pageSize) => (dispatch) => {
  return dispatch({
    type: FETCH_WORKING_APPROVAL_STATUSES,
    payload: api.get(`/WorkingApprovalStatusInfo/Search`, { params: { ...searchPayload, page, pageSize: pageSize } })
  });
};

// const type of function create data
export const CREATE_WORKING_APPROVAL_STATUS = 'CREATE_WORKING_APPROVAL_STATUS';
export const CREATE_WORKING_APPROVAL_STATUS_PENDING = 'CREATE_WORKING_APPROVAL_STATUS_PENDING';
export const CREATE_WORKING_APPROVAL_STATUS_FULFILLED = 'CREATE_WORKING_APPROVAL_STATUS_FULFILLED';
export const CREATE_WORKING_APPROVAL_STATUS_REJECTED = 'CREATE_WORKING_APPROVAL_STATUS_REJECTED';
export const createWorkingApprovalStatus = (payload) => (dispatch) => {
  return dispatch({
    type: CREATE_WORKING_APPROVAL_STATUS,
    payload: api.post(`/WorkingApprovalStatusInfo/create`, payload)
  });
};

// const type of function update data
export const UPDATE_WORKING_APPROVAL_STATUS = 'UPDATE_WORKING_APPROVAL_STATUS';
export const UPDATE_WORKING_APPROVAL_STATUS_PENDING = 'UPDATE_WORKING_APPROVAL_STATUS_PENDING';
export const UPDATE_WORKING_APPROVAL_STATUS_FULFILLED = 'UPDATE_WORKING_APPROVAL_STATUS_FULFILLED';
export const UPDATE_WORKING_APPROVAL_STATUS_REJECTED = 'UPDATE_WORKING_APPROVAL_STATUS_REJECTED';
export const updateWorkingApprovalStatus = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_WORKING_APPROVAL_STATUS,
    payload: api.put(`/WorkingApprovalStatusInfo/update`, payload),
    meta: payload
  });
};
// const type of get deatail
export const FETCH_WORKING_APPROVAL_STATUS_DETAIL = 'FETCH_WORKING_APPROVAL_STATUS_DETAIL';
export const FETCH_WORKING_APPROVAL_STATUS_DETAIL_PENDING = 'FETCH_WORKING_APPROVAL_STATUS_DETAIL_PENDING';
export const FETCH_WORKING_APPROVAL_STATUS_DETAIL_FULFILLED = 'FETCH_WORKING_APPROVAL_STATUS_DETAIL_FULFILLED';
export const FETCH_WORKING_APPROVAL_STATUS_DETAIL_REJECTED = 'FETCH_WORKING_APPROVAL_STATUS_DETAIL_REJECTED';
export const getWorkingApprovalStatusDetail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_WORKING_APPROVAL_STATUS_DETAIL,
    payload: api.get(`/WorkingApprovalStatusInfo/${id}`)
  });
};

// const type of delete
export const DELETE_WORKING_APPROVAL_STATUS = 'DELETE_WORKING_APPROVAL_STATUS';
export const DELETE_WORKING_APPROVAL_STATUS_PENDING = 'DELETE_WORKING_APPROVAL_STATUS_PENDING';
export const DELETE_WORKING_APPROVAL_STATUS_FULFILLED = 'DELETE_WORKING_APPROVAL_STATUS_FULFILLED';
export const DELETE_WORKING_APPROVAL_STATUS_REJECTED = 'DELETE_WORKING_APPROVAL_STATUS_REJECTED';
export const deleteWorkingApprovalStatus = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_WORKING_APPROVAL_STATUS,
    payload: api.delete(`/WorkingApprovalStatusInfo/delete/${id}`)
  });
};
