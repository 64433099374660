import httpClient from "HttpClient";
// define const actions
export const TRANSPORT_COST_REALITY_ACTIONS = {
  GET_TRANSPORT_COST_REALITY_PER_PAGE: "GET_TRANSPORT_COST_REALITY_PER_PAGE",
  GET_TRANSPORT_COST_REALITY_PER_PAGE_PENDING:
    "GET_TRANSPORT_COST_REALITY_PER_PAGE_PENDING",
  GET_TRANSPORT_COST_REALITY_PER_PAGE_FULFILLED:
    "GET_TRANSPORT_COST_REALITY_PER_PAGE_FULFILLED",
  GET_TRANSPORT_COST_REALITY_PER_PAGE_REJECTED:
    "GET_TRANSPORT_COST_REALITY_PER_PAGE_REJECTED",

  GET_TRANSPORT_COST_REALITY_BY_ID: "GET_TRANSPORT_COST_REALITY_BY_ID",
  GET_TRANSPORT_COST_REALITY_BY_ID_PENDING:
    "GET_TRANSPORT_COST_REALITY_BY_ID_PENDING",
  GET_TRANSPORT_COST_REALITY_BY_ID_FULFILLED:
    "GET_TRANSPORT_COST_REALITY_BY_ID_FULFILLED",
  GET_TRANSPORT_COST_REALITY_BY_ID_REJECTED:
    "GET_TRANSPORT_COST_REALITY_BY_ID_REJECTED",

  CREATE_TRANSPORT_COST_REALITY: "CREATE_TRANSPORT_COST_REALITY",
  CREATE_TRANSPORT_COST_REALITY_PENDING:
    "CREATE_TRANSPORT_COST_REALITY_PENDING",
  CREATE_TRANSPORT_COST_REALITY_FULFILLED:
    "CREATE_TRANSPORT_COST_REALITY_FULFILLED",
  CREATE_TRANSPORT_COST_REALITY_REJECTED:
    "CREATE_TRANSPORT_COST_REALITY_REJECTED",

  UPDATE_TRANSPORT_COST_REALITY: "UPDATE_TRANSPORT_COST_REALITY",
  UPDATE_TRANSPORT_COST_REALITY_PENDING:
    "UPDATE_TRANSPORT_COST_REALITY_PENDING",
  UPDATE_TRANSPORT_COST_REALITY_FULFILLED:
    "UPDATE_TRANSPORT_COST_REALITY_FULFILLED",
  UPDATE_TRANSPORT_COST_REALITY_REJECTED:
    "UPDATE_TRANSPORT_COST_REALITY_REJECTED",

  DELETE_TRANSPORT_COST_REALITY: "DELETE_TRANSPORT_COST_REALITY",
  DELETE_TRANSPORT_COST_REALITY_PENDING:
    "DELETE_TRANSPORT_COST_REALITY_PENDING",
  DELETE_TRANSPORT_COST_REALITY_FULFILLED:
    "DELETE_TRANSPORT_COST_REALITY_FULFILLED",
  DELETE_TRANSPORT_COST_REALITY_REJECTED:
    "DELETE_TRANSPORT_COST_REALITY_REJECTED",

  SEARCH_DATA: "SEARCH_DATA",
  SEARCH_DATA_PENDING: "SEARCH_DATA_PENDING",
  SEARCH_DATA_FULFILLED: "SEARCH_DATA_FULFILLED",
  SEARCH_DATA_REJECTED: "SEARCH_DATA_REJECTED",

  GET_CUSTOMERS: "GET_CUSTOMERS",
  GET_CUSTOMERS_PENDING: "GET_CUSTOMERS_PENDING",
  GET_CUSTOMERS_FULFILLED: "GET_CUSTOMERS_FULFILLED",
  GET_CUSTOMERS_REJECTED: "GET_CUSTOMERS_REJECTED",

  GET_ROUTES: "GET_ROUTES",
  GET_ROUTES_PENDING: "GET_ROUTES_PENDING",
  GET_ROUTES_FULFILLED: "GET_ROUTES_FULFILLED",
  GET_ROUTES_REJECTED: "GET_ROUTES_REJECTED",

  GET_CONT_CATEGORY: "GET_CONT_CATEGORY",
  GET_CONT_CATEGORY_PENDING: "GET_CONT_CATEGORY_PENDING",
  GET_CONT_CATEGORY_FULFILLED: "GET_CONT_CATEGORY_FULFILLED",
  GET_CONT_CATEGORY_REJECTED: "GET_CONT_CATEGORY_REJECTED",
};

// define actions
export const actionGetTransportCostPerPage = (pageNo, pageSize) => (
  dispatch
) => {
  return dispatch({
    type: TRANSPORT_COST_REALITY_ACTIONS.GET_TRANSPORT_COST_REALITY_PER_PAGE,
    payload: httpClient.get(
      `/RealTransportFeeInfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`
    ),
  });
};

export const actionGetTransportCostByID = (id) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_REALITY_ACTIONS.GET_TRANSPORT_COST_REALITY_BY_ID,
    payload: httpClient.get(`/RealTransportFeeInfo/getById/${id}`),
  });
};

export const actionCreateTransportCost = (payload) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_REALITY_ACTIONS.CREATE_TRANSPORT_COST_REALITY,
    payload: httpClient.post(`/RealTransportFeeInfo/create`, payload),
  });
};

export const actionUpdateTransportCost = (payload) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_REALITY_ACTIONS.UPDATE_TRANSPORT_COST_REALITY,
    payload: httpClient.put(`/RealTransportFeeInfo/update`, payload),
  });
};

export const actionDeleteTransportCost = (id) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_REALITY_ACTIONS.DELETE_TRANSPORT_COST_REALITY,
    payload: httpClient.delete(`/RealTransportFeeInfo/deleteById/${id}`),
  });
};

export const actionSearchTransportCost = (value, pageNo, pageSize) => (
  dispatch
) => {
  return dispatch({
    type: TRANSPORT_COST_REALITY_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(
      `/RealTransportFeeInfo/search?value=${value}&Page=${pageNo}&PageSize=${pageSize}`
    ),
  });
};

export const actionGetRoutes = () => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_REALITY_ACTIONS.GET_ROUTES,
    payload: httpClient.get(`/routeinfo/getAll`),
  });
};

export const actionGetCustomers = () => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_REALITY_ACTIONS.GET_CUSTOMERS,
    payload: httpClient.get(`/customerinfo/getAll`),
  });
};

export const actionGetContCategory = () => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COST_REALITY_ACTIONS.GET_CONT_CATEGORY,
    payload: httpClient.get(`/Container/getAll`),
  });
};

export const updateShowAlert = () => {
  return {
    type: "UPDATE_SHOW_ALERT",
  };
};
