import httpClient from 'HttpClient';

// const type of function get data per page

export const DRIVER_SALARY = {
  GET_SALARY_INFO: 'GET_SALARY_INFO',
  GET_SALARY_INFO_PENDING: 'GET_SALARY_INFO_PENDING',
  GET_SALARY_INFO_FULFILLED: 'GET_SALARY_INFO_FULFILLED',
  GET_SALARY_INFO_REJECTED: 'GET_SALARY_INFO_REJECTED',

  UPDATE_SALARY_INFO: 'UPDATE_SALARY_INFO',
  UPDATE_SALARY_INFO_PENDING: 'UPDATE_SALARY_INFO_PENDING',
  UPDATE_SALARY_INFO_FULFILLED: 'UPDATE_SALARY_INFO_FULFILLED',
  UPDATE_SALARY_INFO_REJECTED: 'UPDATE_SALARY_INFO_REJECTED',

  GET_DRIVER_SALARY_DETAIL: 'GET_DRIVER_SALARY_DETAIL',
  GET_DRIVER_SALARY_DETAIL_PENDING: 'GET_DRIVER_SALARY_DETAIL_PENDING',
  GET_DRIVER_SALARY_DETAIL_FULFILLED: 'GET_DRIVER_SALARY_DETAIL_FULFILLED',
  GET_DRIVER_SALARY_DETAIL_REJECTED: 'GET_DRIVER_SALARY_DETAIL_REJECTED',

  HANDEL_CHANGE_VALUE_INPUT_DRIVER_SALARY: 'HANDEL_CHANGE_VALUE_INPUT_DRIVER_SALARY',

  LOCK_INFO_SALARY: 'LOCK_INFO_SALARY',
  LOCK_INFO_SALARY_PENDING: 'LOCK_INFO_SALARY_PENDING',
  LOCK_INFO_SALARY_FULFILLED: 'LOCK_INFO_SALARY_FULFILLED',
  LOCK_INFO_SALARY_REJECTED: 'LOCK_INFO_SALARY_REJECTED',

  HANDEL_DETAIL_DRIVER_SALARY: 'HANDEL_DETAIL_DRIVER_SALARY',

  UPDATE_COMPANY_PAID_WEIGHT_LOSS: 'UPDATE_COMPANY_PAID_WEIGHT_LOSS',
  UPDATE_COMPANY_PAID_WEIGHT_LOSS_PENDING: 'UPDATE_COMPANY_PAID_WEIGHT_LOSS_PENDING',
  UPDATE_COMPANY_PAID_WEIGHT_LOSS_FULFILLED: 'UPDATE_COMPANY_PAID_WEIGHT_LOSS_FULFILLED',
  UPDATE_COMPANY_PAID_WEIGHT_LOSS_REJECTED: 'UPDATE_COMPANY_PAID_WEIGHT_LOSS_REJECTED'
};

export const getDriverSalaryInfo = (pageNo, pageSize, month, driverId) => (dispatch) => {
  const params = { Page: pageNo, monthYear: month, PageSize: pageSize, driverId: driverId };
  return dispatch({
    type: DRIVER_SALARY.GET_SALARY_INFO,
    payload: httpClient.get(`/employeeinfo/SalarySummary`, { params: params })
  });
};
export const getDriverSalaryInfoAll = (params) => (dispatch) => {
  console.log(params);
  return dispatch({
    // type: DRIVER_SALARY.GET_SALARY_INFO,
    payload: httpClient.get(`/employeeinfo/SalarySummary`, { params: params })
  });
};

export const updateDriverSalaryInfo = (params) => (dispatch) => {
  const paramsUrl = new URLSearchParams(params).toString();
  return dispatch({
    type: DRIVER_SALARY.UPDATE_SALARY_INFO,
    payload: httpClient.put(`/employeeinfo/updatePayroll?${paramsUrl}`)
  });
};

export const handelChangeValueInputDriverSalary = (value) => (dispatch) => {
  return dispatch({
    type: DRIVER_SALARY.HANDEL_CHANGE_VALUE_INPUT_DRIVER_SALARY,
    payload: value
  });
};

export const getDriverSalaryDetail = (month, id) => (dispatch) => {
  const params = { driverId: id, monthYear: month };
  return dispatch({
    type: DRIVER_SALARY.GET_DRIVER_SALARY_DETAIL,
    payload: httpClient.get(`/TruckingPlan/GetPayroll`, { params: params })
  });
};

export const lockSalaryInfo = (month, id) => (dispatch) => {
  return dispatch({
    type: DRIVER_SALARY.LOCK_INFO_SALARY,
    payload: httpClient.put(
      `/EmployeeInfo/updateAllAlreadySalary?driverId=${id ?? ''}&monthYear=${month ?? ''}&status=true`
    )
  });
};

export const handelDetailDriverSalary = (value) => (dispatch) => {
  return dispatch({
    type: DRIVER_SALARY.HANDEL_DETAIL_DRIVER_SALARY,
    payload: value
  });
};

export const updateCompanyPaidWeightLoss = (payload) => (dispatch) => {
  return dispatch({
    type: DRIVER_SALARY.UPDATE_COMPANY_PAID_WEIGHT_LOSS,
    payload: httpClient.put(`/TruckingPlan/updateCompanyPaidWeightLoss`, payload)
  });
};
