import httpClient from 'HttpClient/index'

// define const actions
export const TRUCKING_TYPE_ACTIONS = {
    GET_ALL_TRUCKING_TYPE: "GET_ALL_TRUCKING_TYPE",
    GET_ALL_TRUCKING_TYPE_PENDING: "GET_ALL_TRUCKING_TYPE_PENDING",
    GET_ALL_TRUCKING_TYPE_FULFILLED: "GET_ALL_TRUCKING_TYPE_FULFILLED",
    GET_ALL_TRUCKING_TYPE_REJECTED: "GET_ALL_TRUCKING_TYPE_REJECTED",
};


// define actions
export const actionGetAllTruckType = () => dispatch => {
    return dispatch({
        type: TRUCKING_TYPE_ACTIONS.GET_ALL_TRUCKING_TYPE,
        payload: httpClient.get('/TruckingTypeInfo/getall')
    });
};