import { OIL_NORM_ACTIONS } from "actions/oilNorm";
import { put, takeEvery } from "redux-saga/effects";
import { getOilNormPerPage } from "services/oilNorm";

function* putActionUpdateData() {
  yield put({
    type: OIL_NORM_ACTIONS.GET_OIL_NORM_PER_PAGE,
    payload: getOilNormPerPage(
      1,
      parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE)
    ),
  });
}

export function* actionUpdateOilNormData() {
  yield takeEvery(
    [
      OIL_NORM_ACTIONS.UPDATE_OIL_NORM_FULFILLED,
      OIL_NORM_ACTIONS.CREATE_OIL_NORM_FULFILLED,
      //   OIL_NORM_ACTIONS.DELETE_OIL_NORM_FULFILLED,
    ],
    putActionUpdateData
  );
}
