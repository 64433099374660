import {
    ADD_USERS_TO_GROUP_FULFILLED,
    ADD_USERS_TO_GROUP_PENDING,
    ADD_USERS_TO_GROUP_REJECTED,
    CHANGE_PERMISSION_FIELD_FULFILLED,
    CHANGE_PERMISSION_FIELD_PENDING,
    CHANGE_PERMISSION_FIELD_REJECTED,
    CHANGE_PERMISSION_ROW_FULFILLED,
    CHANGE_PERMISSION_ROW_PENDING,
    CHANGE_PERMISSION_ROW_REJECTED,
    CREATE_GROUP_FULFILLED,
    CREATE_GROUP_PENDING,
    CREATE_GROUP_REJECTED,
    DELETE_GROUP_FULFILLED,
    DELETE_GROUP_PENDING,
    DELETE_GROUP_REJECTED,
    FETCH_GROUP_FULFILLED,
    FETCH_GROUP_PENDING,
    FETCH_GROUP_PERMISSIONS_FULFILLED,
    FETCH_GROUP_PERMISSIONS_PENDING,
    FETCH_GROUP_PERMISSIONS_REJECTED,
    FETCH_GROUP_REJECTED,
    FETCH_GROUP_USERS_FULFILLED,
    FETCH_GROUP_USERS_PENDING,
    FETCH_GROUP_USERS_REJECTED,
    FETCH_GROUPS_ALL_FULFILLED,
    FETCH_GROUPS_ALL_PENDING,
    FETCH_GROUPS_ALL_REJECTED,
    FETCH_GROUPS_FULFILLED,
    FETCH_GROUPS_PENDING,
    FETCH_GROUPS_REJECTED,
    FETCH_USERS_NOT_IN_GROUP_FULFILLED,
    FETCH_USERS_NOT_IN_GROUP_PENDING,
    FETCH_USERS_NOT_IN_GROUP_REJECTED,
    FILTER_PERMISSION,
    NEW_GROUP,
    REMOVE_USER_TO_GROUP_FULFILLED,
    REMOVE_USER_TO_GROUP_PENDING,
    REMOVE_USER_TO_GROUP_REJECTED,
    UPDATE_DIRTY_PERMISSION_FULFILLED,
    UPDATE_DIRTY_PERMISSION_PENDING,
    UPDATE_DIRTY_PERMISSION_REJECTED,
    UPDATE_GROUP_FULFILLED,
    UPDATE_GROUP_PENDING,
    UPDATE_GROUP_REJECTED,
    UPDATE_TREE_PERMISSION_FULFILLED,
    UPDATE_TREE_PERMISSION_PENDING,
    UPDATE_TREE_PERMISSION_REJECTED} from "actions/groupAction";
import {
    changeFieldPermission,
    changeRowPermission,
    findDirtyPermission,
    // flatTreePermission,
    findPermission} from "commons/groupPermissionUtils";
import {
    DEFAULT_PAGE_SIZE,
    MSG_TYPE_ERROR,
    MSG_TYPE_SUCCESS} from "const/common";



const INIT_STATE = {
    groups: [],
    group: {},
    users: [],
    usersPagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
    usersNotInGroup: [],
    usersNotInGroupPagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
    usersNotInGroupLoading: false,
    pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
    loading: false,
    treePermissions: {Branchs: []},
    flatPermissions: [],
    filteredPermissions: {Branchs: []},
    dirtyPermission: [],
    pristine: true,
    submitting: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case NEW_GROUP: {
            return {
                ...state,
                group: INIT_STATE.group
            }
        }

        case FETCH_GROUPS_ALL_PENDING: {
            return {
                ...state,
                loading: true
            };
        }
        case FETCH_GROUPS_ALL_FULFILLED: {
            return {
                ...state,
                loading: false,
                groups: action.payload.data.data
            };
        }
        case FETCH_GROUPS_ALL_REJECTED: {
            return {
                ...state,
                loading: false,
                showMessage: true,
                alertMessage: "Lỗi. Không thể lấy dữ liệu nhóm",
                messageType: MSG_TYPE_ERROR
            };
        }
        
        case FETCH_GROUPS_PENDING: {
            return {
                ...state,
                loading: true,
                showMessage: false
            };
        }
        case FETCH_GROUPS_FULFILLED: {
            return {
                ...state,
                loading: false,
                groups: action.payload.data.data,
                pagination: action.payload.data.pagination ? action.payload.data.pagination: INIT_STATE.pagination,
                showMessage: false
            };
        }
        case FETCH_GROUPS_REJECTED: {
            return {
                ...state,
                loading: false,
                showMessage: true,
                alertMessage: "Có lỗi, không thể lấy dữ liệu từ máy chủ. Yêu cầu thử lại",
                messageType: MSG_TYPE_ERROR
            };
        }

        case CREATE_GROUP_PENDING:
            return {
                ...state,
                loading: true
            };
        case CREATE_GROUP_FULFILLED:
            return {
                ...state,
                isCreate: true,
                loading: false,
                showMessage: true,
                alertMessage: "Đã tạo nhóm thành công.",
                messageType: MSG_TYPE_SUCCESS
            };
        case CREATE_GROUP_REJECTED:
            return {
                ...state,
                loading: false,
                showMessage: true,
                alertMessage: "Có lỗi, không thể tạo nhóm.",
                messageType: MSG_TYPE_ERROR
            };

            // update data
        case UPDATE_GROUP_PENDING:
            return {
                ...state,
                loading: true,
                showMessage: false
            };
        case UPDATE_GROUP_FULFILLED:
            return {
                ...state,
                isUpdate: true,
                loading: false,
                showMessage: true,
                alertMessage: "Đã cập nhật nhóm thành công.",
                messageType: MSG_TYPE_SUCCESS
            };
        case UPDATE_GROUP_REJECTED:
            return {
                ...state,
                loading: false,
                showMessage: true,
                alertMessage: "Có lỗi, không thể cập nhật thông tin nhóm.",
                messageType: MSG_TYPE_ERROR
            };

        case DELETE_GROUP_PENDING:
            return {
                ...state,
                loading: true,
                showMessage: false
            };
        case DELETE_GROUP_FULFILLED:
            return {
                ...state,
                loading: false,
                showMessage: true,
                alertMessage: "Đã xóa nhóm thành công.",
                messageType: MSG_TYPE_SUCCESS
            };
        case DELETE_GROUP_REJECTED:
            let alertMessage = "Có lỗi, không thể xóa nhóm này";
            if (action.payload.response.data.status === 400) {
                alertMessage = action.payload.response.data.message;
            }
            return {
                ...state,
                loading: false,
                showMessage: true,
                alertMessage: alertMessage,
                messageType: MSG_TYPE_ERROR
            };

        case FETCH_GROUP_PENDING:
            return {
                ...state,
                detailLoading: true,
                showMessage: false
            };
        case FETCH_GROUP_FULFILLED:
            return {
                ...state,
                detailLoading: false,
                showMessage: false,
                group: action.payload.data.data
            };
        case FETCH_GROUP_REJECTED:
            return {
                ...state,
                detailLoading: false,
                showMessage: true,
                alertMessage: "Có lỗi, không thể tải thông tin nhóm",
                messageType: MSG_TYPE_ERROR
            };

        case FETCH_GROUP_USERS_PENDING:
            return {
                ...state,
                groupUsersLoading: true,
                groupUserShowMessage: false
            };
        case FETCH_GROUP_USERS_FULFILLED:
            return {
                ...state,
                groupUsersLoading: false,
                groupUserShowMessage: false,
                users: action.payload.data.data,
                usersPagination: action.payload.data.pagination
            };
        case FETCH_GROUP_USERS_REJECTED:
            return {
                ...state,
                groupUsersLoading: false,
                groupUserShowMessage: true,
                groupUserAlertMessage: "Có lỗi, không thể tải thông tin người dùng của nhóm này",
                groupUserMessageType: MSG_TYPE_ERROR
            };

        case FETCH_USERS_NOT_IN_GROUP_PENDING:
            return {
                ...state,
                usersNotInGroupLoading: true,
                groupUserShowMessage: false
            };
        case FETCH_USERS_NOT_IN_GROUP_FULFILLED:
            return {
                ...state,
                usersNotInGroupLoading: false,
                groupUserShowMessage: false,
                usersNotInGroup: action.payload.data.data,
                usersNotInGroupPagination: action.payload.data.pagination? action.payload.data.pagination : INIT_STATE.usersNotInGroupPagination
            };
        case FETCH_USERS_NOT_IN_GROUP_REJECTED:
            return {
                ...state,
                usersNotInGroupLoading: false,
                groupUserShowMessage: true,
                groupUserAlertMessage: "Có lỗi, không thể tải thông tin người dùng chưa thuộc nhóm này",
                groupUserMessageType: MSG_TYPE_ERROR
            };

        case ADD_USERS_TO_GROUP_PENDING:
            return {
                ...state,
                usersNotInGroupLoading: true,
                groupUserShowMessage: false
            };
        case ADD_USERS_TO_GROUP_FULFILLED:
            return {
                ...state,
                usersNotInGroupLoading: false,
                groupUserShowMessage: true,
                groupUserAlertMessage: "Đã Tạo dữ liệu thành công người dùng vào nhóm",
                groupUserMessageType: MSG_TYPE_ERROR
            };
        case ADD_USERS_TO_GROUP_REJECTED:
            return {
                ...state,
                usersNotInGroupLoading: false,
                groupUserShowMessage: true,
                groupUserAlertMessage: "Có lỗi, không thể thêm người dùng vào nhóm này",
                groupUserMessageType: MSG_TYPE_ERROR
            };

        case REMOVE_USER_TO_GROUP_PENDING:
            return {
                ...state,
                usersNotInGroupLoading: true,
                groupUserShowMessage: false
            };
        case REMOVE_USER_TO_GROUP_FULFILLED:
            return {
                ...state,
                usersNotInGroupLoading: false,
                groupUserShowMessage: true,
                groupUserAlertMessage: "Đã Xóa dữ liệu thành công người dùng khỏi nhóm.",
                groupUserMessageType: MSG_TYPE_ERROR
            };
        case REMOVE_USER_TO_GROUP_REJECTED:
            return {
                ...state,
                usersNotInGroupLoading: false,
                groupUserShowMessage: true,
                groupUserAlertMessage: "Có lỗi, không thể xóa người dùng khỏi nhóm này",
                groupUserMessageType: MSG_TYPE_ERROR
            };

        case FETCH_GROUP_PERMISSIONS_PENDING:
            return {
                ...state,
                groupPermissionsLoading: true,
                groupUserShowMessage: false
            };
        case FETCH_GROUP_PERMISSIONS_FULFILLED:
            const treePermissions = action.payload.data.data ? action.payload.data.data : INIT_STATE.treePermissions;
            // const flatPermissions = flatTreePermission(treePermissions);
            const dirtyPermission = findDirtyPermission(treePermissions);
            return {
                ...state,
                groupPermissionsLoading: false,
                groupPermissionShowMessage: false,
                treePermissions: treePermissions,
                // flatPermissions: flatPermissions,
                filteredPermissions: treePermissions,
                dirtyPermission: dirtyPermission
            };
        case FETCH_GROUP_PERMISSIONS_REJECTED:
            return {
                ...state,
                groupPermissionsLoading: false,
                groupPermissionShowMessage: true,
                groupPermissionAlertMessage: "Có lỗi, không thể tải thông tin quyền",
                groupPermissionMessageType: MSG_TYPE_ERROR
            };

        case FILTER_PERMISSION:
            {
                const filteredPermission = findPermission(state.treePermissions, action.payload.FunctionId);
                return {
                    ...state,
                    filteredPermissions: filteredPermission
                };
            }
        case CHANGE_PERMISSION_FIELD_PENDING:
            {
                return {
                    ...state,
                    groupPermissionsLoading: true
                }
            }
        case CHANGE_PERMISSION_FIELD_REJECTED:
            {
                return {
                    ...state,
                    groupPermissionsLoading: false
                }
            }
        case CHANGE_PERMISSION_FIELD_FULFILLED:
            {
                const permissionId = action.payload.permission.FunctionId;
                const fieldName = action.payload.fieldName;
                const value = action.payload.checked;
                const changedPermission = changeFieldPermission(state.treePermissions, permissionId, fieldName, value);
                const filteredPermission = findPermission(changedPermission, state.filteredPermissions.FunctionId);
                const dirtyPermission = findDirtyPermission(changedPermission);
                return {
                    ...state,
                    pristine: false,
                    groupPermissionsLoading: false,
                    treePermissions: changedPermission,
                    filteredPermissions: filteredPermission,
                    dirtyPermission: dirtyPermission
                };
            }

        case CHANGE_PERMISSION_ROW_PENDING:
            {
                return {
                    ...state,
                    groupPermissionsLoading: true
                }
            }
        case CHANGE_PERMISSION_ROW_REJECTED:
            {
                return {
                    ...state,
                    groupPermissionsLoading: false
                }
            }
        case CHANGE_PERMISSION_ROW_FULFILLED:
            {
                const permissionId = action.payload.permission.FunctionId;
                const value = action.payload.checked;
                const changedPermission = changeRowPermission(state.treePermissions, permissionId, value);
                const filteredPermission = findPermission(changedPermission, state.filteredPermissions.FunctionId);
                return {
                    ...state,
                    pristine: false,
                    groupPermissionsLoading: false,
                    treePermissions: changedPermission,
                    filteredPermissions: filteredPermission
                };
            }
        
        case UPDATE_DIRTY_PERMISSION_PENDING:
            {
                return {
                    ...state,
                    groupPermissionsLoading: true,
                    submitting: true,
                }
            }
        case UPDATE_DIRTY_PERMISSION_REJECTED:
            {
                return {
                    ...state,
                    groupPermissionsLoading: false,
                    submitting: false,
                    groupPermissionShowMessage: true,
                    groupPermissionAlertMessage: "Có lỗi, không thể cập nhật quyền",
                    groupPermissionMessageType: MSG_TYPE_ERROR
                }
            }
        case UPDATE_DIRTY_PERMISSION_FULFILLED:
            {
                return {
                    ...state,
                    groupPermissionsLoading: false,
                    pristine: true,
                    submitting: false,
                    groupPermissionShowMessage: true,
                    groupPermissionAlertMessage: "Đã cập nhật quyền thành công",
                    groupPermissionMessageType: MSG_TYPE_SUCCESS
                };
            }

        case UPDATE_TREE_PERMISSION_PENDING:
            {
                return {
                    ...state,
                    groupPermissionsLoading: true,
                    submitting: true,
                }
            }
        case UPDATE_TREE_PERMISSION_REJECTED:
            {
                let groupPermissionAlertMessage = "Có lỗi, không thể cập nhật quyền";
                if (action.payload.response.status === 400) {
                    groupPermissionAlertMessage = action.payload.response.data.message || action.payload.response.data.title
                }
                return {
                    ...state,
                    groupPermissionsLoading: false,
                    submitting: false,
                    groupPermissionShowMessage: true,
                    groupPermissionAlertMessage: groupPermissionAlertMessage,
                    groupPermissionMessageType: MSG_TYPE_ERROR
                }
            }
        case UPDATE_TREE_PERMISSION_FULFILLED:
            {
                return {
                    ...state,
                    groupPermissionsLoading: false,
                    pristine: true,
                    submitting: false,
                    groupPermissionShowMessage: true,
                    groupPermissionAlertMessage: "Đã cập nhật quyền thành công",
                    groupPermissionMessageType: MSG_TYPE_SUCCESS
                };
            }
            
        default:
            return state;
    }
};
