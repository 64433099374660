import httpClient from 'HttpClient';
// import { checkValueSelect } from "../commons/ValidateType";
// define const actions
export const DOWNPAYMENT_ORDER_ACTIONS = {
  GET_LIST_DOWNPAYMENT_ORDER: 'GET_LIST_DOWNPAYMENT_ORDER',
  GET_LIST_DOWNPAYMENT_ORDER_PENDING: 'GET_LIST_DOWNPAYMENT_ORDER_PENDING',
  GET_LIST_DOWNPAYMENT_ORDER_FULFILLED: 'GET_LIST_DOWNPAYMENT_ORDER_FULFILLED',
  GET_LIST_DOWNPAYMENT_ORDER_REJECTED: 'GET_LIST_DOWNPAYMENT_ORDER_REJECTED',

  CREATE_TEMPLATE: 'CREATE_TEMPLATE',
  CREATE_TEMPLATE_PENDING: 'CREATE_TEMPLATE_PENDING',
  CREATE_TEMPLATE_FULFILLED: 'CREATE_TEMPLATE_FULFILLED',
  CREATE_TEMPLATE_REJECTED: 'CREATE_TEMPLATE_REJECTED',

  CREATE_DOWN_PAYMENT_ORDER: 'CREATE_DOWN_PAYMENT_ORDER',
  CREATE_DOWN_PAYMENT_ORDER_PENDING: 'CREATE_DOWN_PAYMENT_ORDER_PENDING',
  CREATE_DOWN_PAYMENT_ORDER_FULFILLED: 'CREATE_DOWN_PAYMENT_ORDER_FULFILLED',
  CREATE_DOWN_PAYMENT_ORDER_REJECTED: 'CREATE_DOWN_PAYMENT_ORDER_REJECTED',

  UPDATE_DOWN_PAYMENT_ORDER: 'UPDATE_DOWN_PAYMENT_ORDER',
  UPDATE_DOWN_PAYMENT_ORDER_PENDING: 'UPDATE_DOWN_PAYMENT_ORDER_PENDING',
  UPDATE_DOWN_PAYMENT_ORDER_FULFILLED: 'UPDATE_DOWN_PAYMENT_ORDER_FULFILLED',
  UPDATE_DOWN_PAYMENT_ORDER_REJECTED: 'UPDATE_DOWN_PAYMENT_ORDER_REJECTED',

  DELETE_DATA: 'DELETE_DATA',
  DELETE_DATA_PENDING: 'DELETE_DATA_PENDING',
  DELETE_DATA_FULFILLED: 'DELETE_DATA_FULFILLED',
  DELETE_DATA_REJECTED: 'DELETE_DATA_REJECTED',

  GET_UNIT: 'GET_UNIT',
  GET_UNIT_PENDING: 'GET_UNIT_PENDING',
  GET_UNIT_FULFILLED: 'GET_UNIT_FULFILLED',
  GET_UNIT_REJECTED: 'GET_UNIT_REJECTED',

  GET_FEE_TYPE: 'GET_FEE_TYPE',
  GET_FEE_TYPE_PENDING: 'GET_FEE_TYPE_PENDING',
  GET_FEE_TYPE_FULFILLED: 'GET_FEE_TYPE_FULFILLED',
  GET_FEE_TYPE_REJECTED: 'GET_FEE_TYPE_REJECTED',

  CHANGE_DATA_EDIT_TABEL: 'CHANGE_DATA_EDIT_TABEL',
  CHANGE_DATA_EDIT_TABEL_FULFILLED: 'CHANGE_DATA_EDIT_TABEL_FULFILLED',
  CHANGE_DATA_EDIT_UNITPRICE_CONTRACT: 'CHANGE_DATA_EDIT_UNITPRICE_CONTRACT',

  ON_KEY_UP_FIELD: 'ON_KEY_UP_FIELD',

  CREATE_ITEM_PAYMENT_ADDED: 'CREATE_ITEM_PAYMENT_ADDED',
  CREATE_ITEM_PAYMENT_ADDED_PENDING: 'CREATE_ITEM_PAYMENT_ADDED_PENDING',
  CREATE_ITEM_PAYMENT_ADDED_FULFILLED: 'CREATE_ITEM_PAYMENT_ADDED_FULFILLED',
  CREATE_ITEM_PAYMENT_ADDED_REJECTED: 'CREATE_ITEM_PAYMENT_ADDED_REJECTED',

  GET_LIST_IN_PAYMENT_ADDED: 'GET_LIST_IN_PAYMENT_ADDED',
  GET_LIST_IN_PAYMENT_ADDED_PENDING: 'GET_LIST_IN_PAYMENT_ADDED_PENDING',
  GET_LIST_IN_PAYMENT_ADDED_FULFILLED: 'GET_LIST_IN_PAYMENT_ADDED_FULFILLED',
  GET_LIST_IN_PAYMENT_ADDED_REJECTED: 'GET_LIST_IN_PAYMENT_ADDED_REJECTED',

  GET_LIST_FEES_BY_INSTALMENTS: 'GET_LIST_FEES_BY_INSTALMENTS',
  GET_LIST_FEES_BY_INSTALMENTS_PENDING: 'GET_LIST_FEES_BY_INSTALMENTS_PENDING',
  GET_LIST_FEES_BY_INSTALMENTS_FULFILLED: 'GET_LIST_FEES_BY_INSTALMENTS_FULFILLED',
  GET_LIST_FEES_BY_INSTALMENTS_REJECTED: 'GET_LIST_FEES_BY_INSTALMENTS_REJECTED',
  CHANGE_ALL_ACCOUNTANT: 'CHANGE_ALL_ACCOUNTANT',
  CHANGE_SELECT_ACTION: 'CHANGE_SELECT_ACTION'
};

// define actions
export const getListDownpaymentOrder = (consigmentId) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_ORDER_ACTIONS.GET_LIST_DOWNPAYMENT_ORDER,
    payload: httpClient.get(`/advanceonconsignment/getList/${consigmentId}`)
  });
};

export const createTamplate = (consigmentid) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_ORDER_ACTIONS.CREATE_TEMPLATE,
    payload: httpClient.get(`/advanceonconsignment/create/${consigmentid}`)
  });
};

export const actionCreateDownPaymentOrder = (consigmentId, payload) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_ORDER_ACTIONS.CREATE_DOWN_PAYMENT_ORDER,
    payload: httpClient.post(`/advanceonconsignment/${consigmentId}/create`, payload)
  });
};

export const actionUpdateDownPaymentOrder = (id, payload) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_ORDER_ACTIONS.UPDATE_DOWN_PAYMENT_ORDER,
    payload: httpClient.put(`/advanceonconsignment/${id}/update`, payload)
  });
};

export const actionDeleteDownPaymentOrder = (id) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_ORDER_ACTIONS.DELETE_DATA,
    payload: httpClient.delete(`/advanceonconsignment/deleteById/${id}`)
  });
};

export const getUnit = () => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_ORDER_ACTIONS.GET_UNIT,
    payload: httpClient.get(`/Unit/getAll/payment`)
  });
};

export const getFeeType = (id) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_ORDER_ACTIONS.GET_FEE_TYPE,
    payload: httpClient.get(`/SysStandardFee/getByFeeCategoryId?feeCategoryId=${id}`)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

// const booleanType = 'boolean';
// function convertStringValues(value, fieldName) {
//   const isBooleanType = typeof value === booleanType;
//   let isNumberIntergerString = (value + '').includes(',');
//   const stringField = ['Note'];
//   const regexDots = /\./g;
//   const regexComma = /\,/g;
//   let result = '';
//   if (stringField.includes(fieldName) || isBooleanType) result = value;
//   else result = !isNumberIntergerString ? value.replace(regexDots, '') : value.replace(regexComma, '');
// }

export const changeDataEditTable = (
  id,
  fieldName,
  value,
  message,
  isConditionQuantityWeight,
  acceptDecimal = false
) => {
  let checkValueIsBoolean = 'boolean';
  let check = (value + '').includes(',');
  const regexDots = /\./g;
  const regexComma = /\,/g;
  let dataValue =
    typeof value === checkValueIsBoolean
      ? value
      : !check && !acceptDecimal
      ? value.replace(regexDots, '')
      : value.replace(regexComma, '');
  let error = {
    [fieldName]: message
  };
  let data = {
    Id: id,
    [fieldName]:
      !isNaN(dataValue) && dataValue !== '' && typeof dataValue !== checkValueIsBoolean && !acceptDecimal
        ? parseInt(dataValue)
        : fieldName === 'UnitPrice' && dataValue === ''
        ? 0
        : dataValue + '',
    isChange: true
  };
  return {
    type: DOWNPAYMENT_ORDER_ACTIONS.CHANGE_DATA_EDIT_TABEL,
    meta: {
      error,
      message,
      fieldName,
      isConditionQuantityWeight,
      checkValueIsBoolean,
      value
    },
    payload: data
  };
};

export const changeDataEditUnitPriceContract = (
  id,
  fieldName,
  value,
  message,
  isConditionQuantityWeight,
  acceptDecimal = false
) => {
  let checkValueIsBoolean = 'boolean';
  let check = (value + '').includes(',');
  const regexDots = /\./g;
  const regexComma = /\,/g;
  let dataValue =
    typeof value === checkValueIsBoolean
      ? value
      : !check && !acceptDecimal
      ? value.replace(regexDots, '')
      : value.replace(regexComma, '');
  let error = {
    [fieldName]: message
  };
  let data = {
    Id: id,
    [fieldName]:
      !isNaN(dataValue) && dataValue !== '' && typeof dataValue !== checkValueIsBoolean && !acceptDecimal
        ? parseInt(dataValue)
        : fieldName === 'UnitPrice' && dataValue === ''
        ? 0
        : +dataValue + '',
    isChange: true
  };
  return {
    type: DOWNPAYMENT_ORDER_ACTIONS.CHANGE_DATA_EDIT_UNITPRICE_CONTRACT,
    meta: {
      error,
      message,
      fieldName,
      isConditionQuantityWeight,
      checkValueIsBoolean,
      value
    },
    payload: data
  };
};

export const onKeyUpActionsField = (id, isFocusTotal) => {
  let data = {
    Id: id,
    isFocus: true,
    isFocusTotal: isFocusTotal
  };
  return {
    type: DOWNPAYMENT_ORDER_ACTIONS.ON_KEY_UP_FIELD,
    payload: data
  };
};

export const getListPaymentAdded = (consignmentId, instalmentId) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_ORDER_ACTIONS.GET_LIST_IN_PAYMENT_ADDED,
    payload: httpClient.get(`/advanceonconsignment/getListInstalment/${consignmentId}?instalmentId=${instalmentId}`)
  });
};

export const createItemInPaymentAdded = (id, data) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_ORDER_ACTIONS.CREATE_ITEM_PAYMENT_ADDED,
    payload: httpClient.post(`/advanceonconsignment/${id}/createInstalment`, data)
  });
};

export const getListFeesByInstalment = (consignmentId, instalmentId) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_ORDER_ACTIONS.GET_LIST_FEES_BY_INSTALMENTS,
    payload: httpClient.get(`/advanceonconsignment/getList/${consignmentId}/instalments/${instalmentId}`)
  });
};

export const changeAllAccountant = (accountant) => {
  return {
    type: DOWNPAYMENT_ORDER_ACTIONS.CHANGE_ALL_ACCOUNTANT,
    payload: accountant
  };
};

export const changeSelectAction = (payload) => {
  return {
    type: DOWNPAYMENT_ORDER_ACTIONS.CHANGE_SELECT_ACTION,
    payload: payload
  };
};
