import httpClient from 'HttpClient';
// define const actions
export const REPAIR_FEE_NORM = {
  GET_DATA_LIST_PER_PAGE: 'GET_DATA_LIST_PER_PAGE',
  GET_DATA_LIST_PER_PAGE_PENDING: 'GET_DATA_LIST_PER_PAGE_PENDING',
  GET_DATA_LIST_PER_PAGE_FULFILLED: 'GET_DATA_LIST_PER_PAGE_FULFILLED',
  GET_DATA_LIST_PER_PAGE_REJECTED: 'GET_DATA_LIST_PER_PAGE_REJECTED',

  DELETE_DATA: 'DELETE_DATA',
  DELETE_DATA_PENDING: 'DELETE_DATA_PENDING',
  DELETE_DATA_FULFILLED: 'DELETE_DATA_FULFILLED',
  DELETE_DATA_REJECTED: 'DELETE_DATA_REJECTED',

  CREATE_DATA: 'CREATE_DATA',
  CREATE_DATA_PENDING: 'CREATE_DATA_PENDING',
  CREATE_DATA_FULFILLED: 'CREATE_DATA_FULFILLED',
  CREATE_DATA_REJECTED: 'CREATE_DATA_REJECTED',

  UPDATE_DATA: 'UPDATE_DATA',
  UPDATE_DATA_PENDING: 'UPDATE_DATA_PENDING',
  UPDATE_DATA_FULFILLED: 'UPDATE_DATA_FULFILLED',
  UPDATE_DATA_REJECTED: 'UPDATE_DATA_REJECTED'
};

// define actions
export const getRepairNormFeeAction = (params) => (dispatch) => {
  return dispatch({
    type: REPAIR_FEE_NORM.GET_DATA_LIST_PER_PAGE,
    payload: httpClient.get(`/ReparingDispatcher/search`, { params })
  });
};

export const deleteRepairNormFeeItem = (id) => (dispatch) => {
  return dispatch({
    type: REPAIR_FEE_NORM.DELETE_DATA,
    payload: httpClient.delete(`/ReparingDispatcher/delete/${id}`)
  });
};

export const createRepairNormFeeItem = (payload) => (dispatch) => {
  return dispatch({
    type: REPAIR_FEE_NORM.CREATE_DATA,
    payload: httpClient.post(`/ReparingDispatcher/create`, payload)
  });
};

export const updateRepairNormFeeItem = (payload) => (dispatch) => {
  return dispatch({
    type: REPAIR_FEE_NORM.UPDATE_DATA,
    payload: httpClient.put(`/ReparingDispatcher/update`, payload)
  });
};
