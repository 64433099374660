import { CONTAINERS_ACTIONS } from "actions/container";

const INIT_STATE = {
    containersData: {data: []}
}

const containerReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        // get all container
        case CONTAINERS_ACTIONS.GET_ALL_CONTAINERS_PENDING:
            return {
                ...state,
                isLoading: true
            };

        case CONTAINERS_ACTIONS.GET_ALL_CONTAINERS_FULFILLED:
            let containersData = {
                data: action.payload.data.data
            };
            return {
                ...state,
                containersData: containersData,
                isLoading: false
            };

        case CONTAINERS_ACTIONS.GET_ALL_CONTAINERS_REJECTED:
            return {
                ...state,
                isLoading: false,
                isMsg: true,
                msg: "Đã xảy ra lỗi"
            };

        default:
            return state;
    }
};

export default containerReducer;