import {
  ADD_ROW_REPORT_INLAND_REVUNUE,
  ADD_ROW_REPORT_MINING_YARD_REVUNUE,
  CREATE_DATA_BUSINEES_REPORT_FULFILLED,
  CREATE_DATA_BUSINEES_REPORT_PENDING,
  CREATE_DATA_BUSINEES_REPORT_REJECTED,
  DELETE_ITEM_REPORT_RESULT_FULFILLED,
  DELETE_ITEM_REPORT_RESULT_PENDING,
  DELETE_ITEM_REPORT_RESULT_REJECTED,
  FETCH_REPORTS_BUSSINESS_RESULT_FULFILLED,
  FETCH_REPORTS_BUSSINESS_RESULT_PENDING,
  FETCH_REPORTS_BUSSINESS_RESULT_REJECTED,
  GET_MINING_CHILDS_FULFILLED,
  GET_MINING_CHILDS_PENDING,
  GET_MINING_CHILDS_REJECTED,
  GET_MINING_PARENTS_FULFILLED,
  GET_MINING_PARENTS_PENDING,
  GET_MINING_PARENTS_REJECTED,
  HANDEL_CHANGE_VALUE_INPUT,
  REMOVE_ROW_REPORT_INLAND_REVUNUE,
  REMOVE_ROW_REPORT_MINING_REVUNUE,
  RESET_REPORT_BUSINESS_RESULT,
  SET_REPORT_BUSSINESS_RESULT_SEARCH_PARAMS,
  UPDATE_DATA_BUSINEES_REPORT_FULFILLED,
  UPDATE_DATA_BUSINEES_REPORT_PENDING,
  UPDATE_DATA_BUSINEES_REPORT_REJECTED
} from 'actions/ReportBusinessResult';
import { getErrorMessage } from 'commons/ReducerUtils';
import { removeDotsAndCommas } from 'commons/ValidateEditable';
import {
  DEFAULT_PAGE_SIZE,
  MSG_ADD_FAILURE,
  MSG_DELETE_FAILURE,
  MSG_EDIT_FAILURE,
  MSG_PAGING_FAILURE,
  // MSG_TYPE_SUCCESS,
  MSG_TYPE_ERROR,
  MSG_TYPE_SUCCESS
} from 'const/common';
import moment from 'moment';
import {
  BUSSINESS_RESULT_TAB,
  getColumnsDisplay,
  INLAND_REVUNUE_TAB,
  MINING_YARD_TAB,
  TYPE_NUMBER
} from 'Pages/Report/BussinessResultReport/Form/utils';

const rowDataReportInlandRevunue = {
  DateImport: initDate(),
  CustomerName: '',
  Content: '',
  TotalMoney: 0,
  TruckingHp: 0,
  LiftOnLiftOff: 0,
  DoAndCont: 0,
  Loading: 0,
  OceanFreight: 0,
  TruckingHcm: 0,
  LiftOnLiftOffHcm: 0,
  CostMerge: 0,
  CostScCont: 0,
  CostOther: 0,
  CostBenefit: 0,
  Description: null,
  isEdit: true
};

const renderRowMiningReport = (parentId, index) => {
  return {
    Id: 0,
    ParentId: parentId,
    DateImport: initDate(),
    Name: '',
    Amount: null,
    Price: null,
    TotalMoney: null,
    Description: null,
    CreatedBy: null,
    CreatedDate: null,
    ModifiedBy: null,
    ModifiedDate: null,
    Childs: [],
    CurrentIndex: index
  };
};

const INIT_STATE = {
  reports: [],
  pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
  loading: false,
  showMessage: false,
  alertMessage: '',
  messageType: '',
  search: {
    startDate: moment().startOf('month'),
    endDate: moment(),
    month: moment().format('YYYY-MM'),
    textSearch: '',
    searchValue: null
  },
  //   reportsTest: EXMAPLE_DATA_MINING_REVUNUE,
  miningParentItems: [],
  miningChildItems: [],
  isDisableBtnSave: false,
  statusActiveBtnSave: { inlandRevue: false, miningReport: false }
};

function initDate() {
  return moment().format('YYYY-MM-DD');
}

function getValueWithFieldName(fields, itemEdit) {
  const result = fields.map((el, i) => {
    return (fields[i] = itemEdit[el] || 0);
  });
  return result;
}

function caculatorBenifitInlandRevunueReport(fieldName, itemEdit, valueEdit) {
  const fieldsToCaculatorTotalFee = [
    'TruckingHp',
    'LiftOnLiftOff',
    'DoAndCont',
    'Loading',
    'OceanFreight',
    'TruckingHcm',
    'LiftOnLiftOffHcm',
    'CostMerge',
    'CostScCont',
    'CostOther'
  ];
  const isFieldCaculator = fieldsToCaculatorTotalFee.includes(fieldName);
  if (fieldName === 'TotalMoney') {
    const fieldValues = getValueWithFieldName(fieldsToCaculatorTotalFee, itemEdit);
    const total = fieldValues.reduce((accumulator, currentValue) => accumulator + currentValue);
    const benifitValue = parseInt(removeDotsAndCommas(valueEdit)) - total;
    return benifitValue === 0 ? 0 : benifitValue;
  } else {
    if (isFieldCaculator) {
      const fields = fieldsToCaculatorTotalFee.filter((el) => {
        return el !== fieldName;
      });
      const fieldValues = getValueWithFieldName(fields, itemEdit);
      const total = fieldValues.reduce((accumulator, currentValue) => accumulator + currentValue);
      const totalUpdated = total + parseInt(removeDotsAndCommas(valueEdit));
      const totalMoney = itemEdit.TotalMoney;
      const benifitValue = totalMoney - totalUpdated;
      return benifitValue === 0 ? 0 : benifitValue;
    } else {
      return itemEdit.CostBenefit;
    }
  }
}

function processingHandelInputForInlandRevunue(reports, payloadData) {
  const valueEdit = payloadData.value;
  const fieldName = payloadData.fieldName;
  const indexItemEdit = payloadData.index;
  const validateMessage = payloadData.message;
  const typeInput = payloadData.type;
  const valueUpdate =
    typeInput === TYPE_NUMBER && !validateMessage ? parseInt(removeDotsAndCommas(valueEdit)) : valueEdit;
  const itemEdit = reports[indexItemEdit];
  let error = { ...itemEdit.error, [fieldName]: validateMessage };
  const benifitValue = caculatorBenifitInlandRevunueReport(fieldName, itemEdit, valueEdit);
  const itemEditUpdated = {
    ...itemEdit,
    [fieldName]: valueUpdate,
    CostBenefit: benifitValue,
    isEdit: true,
    error: error
  };
  const reportUpdated = [...reports];
  reportUpdated.splice(indexItemEdit, 1, itemEditUpdated);
  const mapReportsUpdatedWithValidateValue = reportUpdated.map((el) => {
    return {
      ...el,
      //   error: { ...el.error, [fieldName]: validateMessage },
      isDisableBtnDelete: !el.Id ? false : true
    };
  });

  return mapReportsUpdatedWithValidateValue;
}

function getItemEditingMiningYardReport(reports, id, parentId, currentIndex) {
  let nodeFoundResult = getResultNode(reports, parentId);

  if (id) {
    if (null === nodeFoundResult) {
      const item = reports.find((el) => {
        return el.Id === id;
      });
      return item;
    } else {
      const childrens = nodeFoundResult.Childs;
      const itemUpdating = childrens.find((el) => {
        return el.Id === id;
      });
      return itemUpdating;
    }
  } else {
    const childrens = nodeFoundResult.Childs;
    const itemUpdating = childrens[currentIndex];
    return itemUpdating;
  }
}

function updateDataMiningYardReport(reports, id, parentId, currentIndex, itemUpdated) {
  let nodeFoundResult = getResultNode(reports, parentId);
  let reportData = [...reports];
  if (id) {
    if (null === nodeFoundResult) {
      const index = reports.findIndex((el) => {
        return el.Id === id;
      });
      reportData[index] = itemUpdated;
    } else {
      let childrens = nodeFoundResult.Childs;
      const index = childrens.findIndex((el) => {
        return el.Id === id;
      });
      childrens[index] = itemUpdated;
    }
  } else {
    let childrens = nodeFoundResult.Childs;
    childrens[currentIndex] = itemUpdated;
  }
  return reportData;
}

function processingHandelInputForMiningRevunue(reports, payloadData) {
  const valueEdit = payloadData.value;
  const fieldName = payloadData.fieldName;
  const validateMessage = payloadData.message;
  const typeInput = payloadData.type;
  const item = payloadData.item;
  const id = item.Id;
  const parentId = item.ParentId;
  const valueUpdate =
    typeInput === TYPE_NUMBER && !validateMessage ? parseInt(removeDotsAndCommas(valueEdit)) : valueEdit;

  let itemEdit = getItemEditingMiningYardReport(reports, id, parentId, item.CurrentIndex);
  let error = { ...itemEdit.error, [fieldName]: validateMessage };
  const fieldForCaculatorTotal = ['Price', 'Amount'];
  const isFieldForCaculatorTotal = fieldForCaculatorTotal.includes(fieldName);
  let totalMoney = itemEdit.TotalMoney;
  if (isFieldForCaculatorTotal) {
    if (fieldName === fieldForCaculatorTotal[0]) {
      totalMoney = parseInt(valueUpdate) * parseInt(itemEdit.Amount);
    } else {
      totalMoney = parseInt(valueUpdate) * parseInt(itemEdit.Price);
    }
  }
  const itemEditUpdated = {
    ...itemEdit,
    [fieldName]: valueUpdate,
    isEdit: true,
    TotalMoney: totalMoney,
    error: error
  };
  //   itemEdit = { ...itemEdit,...itemEditUpdated, };

  const reportUpdated = updateDataMiningYardReport(reports, id, parentId, item.CurrentIndex, itemEditUpdated);
  //   const mapReportsUpdatedWithValidateValue = reportUpdated.map((el) => {
  //     return {
  //       ...el,
  //       error: { ...el.error, [fieldName]: validateMessage },
  //       isDisableBtnDelete: !el.Id ? false : true
  //     };
  //   });
  //   const mapTotalMoneyValueWithReport = caculatorNode(reportUpdated);
  //   console.log('reportUpdated', mapTotalMoneyValueWithReport);
  return reportUpdated;
}

function getResultNode(reports, parentId) {
  let nodeFoundResult = null;
  reports.forEach((report) => {
    const nodeFound = findNode(report, parentId);
    if (nodeFound !== null) {
      nodeFoundResult = nodeFound;
    }
  });

  return nodeFoundResult;
}

function findNode(root, parentId) {
  if (null === root) {
    return null;
  } else if (root.Id === parentId) {
    return root;
  } else {
    let children = root.Childs;
    let result = null;
    children.forEach((child) => {
      const foundNode = findNode(child, parentId);
      if (foundNode !== null) {
        result = foundNode;
      }
    });
    return result;
  }
}

function selectProcessingFunc(tab, reports, payloadData) {
  switch (tab) {
    case BUSSINESS_RESULT_TAB:
      return;
    case INLAND_REVUNUE_TAB:
      return processingHandelInputForInlandRevunue(reports, payloadData);
    case MINING_YARD_TAB:
      return processingHandelInputForMiningRevunue(reports, payloadData);
  }
}

function getErrorValidations(reports) {
  const errors = reports.map((el) => {
    return el.error;
  });
  const filterError = errors.filter((el) => {
    return el;
  });
  return filterError;
}

function checkDisbaleBtnSave(data, tab) {
  switch (tab) {
    case BUSSINESS_RESULT_TAB:
      return;
    case INLAND_REVUNUE_TAB: {
      const errorsData = getErrorValidations(data);
      const isDisable = errorsData.some((el) => {
        return Object.values(el).some((item) => {
          return item;
        });
      });
      return isDisable;
    }
    case MINING_YARD_TAB: {
      const flatData = getColumnsDisplay(data);
      const errors = getErrorValidations(flatData);
      const isDisableBtnSave = errors.some((el) => {
        return Object.values(el).some((item) => {
          return item;
        });
      });
      return isDisableBtnSave;
    }
  }
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // get data
    case FETCH_REPORTS_BUSSINESS_RESULT_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false,
        isReload: false
      };
    }
    case FETCH_REPORTS_BUSSINESS_RESULT_FULFILLED: {
      return {
        ...state,
        loading: false,
        reports: action.payload.data.data,
        pagination: action.payload.data.pagination ? action.payload.data.pagination : INIT_STATE.pagination,
        showMessage: false,
        isReload: false,
        statusActiveBtnSave: { inlandRevue: false, miningReport: false }
      };
    }
    case FETCH_REPORTS_BUSSINESS_RESULT_REJECTED: {
      let alertMessage = MSG_PAGING_FAILURE;
      if (action.payload.response.status && action.payload.response.status === 400) {
        alertMessage = getErrorMessage(action);
      }
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR,
        isReload: false
      };
    }

    case RESET_REPORT_BUSINESS_RESULT: {
      return {
        ...INIT_STATE
      };
    }

    case SET_REPORT_BUSSINESS_RESULT_SEARCH_PARAMS: {
      const payload = action.payload;
      let newSearchState = {};
      Object.keys(payload).forEach((key) => {
        newSearchState[key] = payload[key];
      });
      return {
        ...state,
        search: {
          ...state.search,
          ...newSearchState
        }
      };
    }
    case ADD_ROW_REPORT_INLAND_REVUNUE: {
      let reports = [...state.reports];
      reports.push(rowDataReportInlandRevunue);
      return {
        ...state,
        reports: reports,
        isReload: false,
        statusActiveBtnSave: { ...state.statusActiveBtnSave, inlandRevue: true }
      };
    }

    case ADD_ROW_REPORT_MINING_YARD_REVUNUE: {
      let reports = [...state.reports];
      const parentId = action.payload;
      let nodeFoundResult = getResultNode(reports, parentId);
      const rowAdding = renderRowMiningReport(parentId, nodeFoundResult.Childs.length);
      const childsUpdating = [...nodeFoundResult.Childs, rowAdding];
      nodeFoundResult.Childs = childsUpdating;
      const reportUpdated = [...reports];
      return {
        ...state,
        reports: reportUpdated,
        isReload: false,
        statusActiveBtnSave: { ...state.statusActiveBtnSave, miningReport: true }
      };
    }

    case HANDEL_CHANGE_VALUE_INPUT: {
      let reports = [...state.reports];
      const payloadData = action.payload;
      const tabActive = payloadData.tabActive;
      const resultData = selectProcessingFunc(tabActive, reports, payloadData);
      const isDisableBtnSave = checkDisbaleBtnSave(resultData, tabActive);
      let btnSaveStatus = { ...state.statusActiveBtnSave };
      if (tabActive === INLAND_REVUNUE_TAB) {
        btnSaveStatus = { ...btnSaveStatus, inlandRevue: true };
      } else {
        btnSaveStatus = { ...btnSaveStatus, miningReport: true };
      }

      return {
        ...state,
        reports: resultData,
        isReload: false,
        isDisableBtnSave: isDisableBtnSave,
        statusActiveBtnSave: btnSaveStatus
      };
    }

    case GET_MINING_PARENTS_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false,
        isReload: false
      };
    }
    case GET_MINING_PARENTS_FULFILLED: {
      return {
        ...state,
        loading: false,
        miningParentItems: action.payload.data.data,
        pagination: action.payload.data.pagination ? action.payload.data.pagination : INIT_STATE.pagination,
        showMessage: false,
        isReload: false
      };
    }
    case GET_MINING_PARENTS_REJECTED: {
      let alertMessage = MSG_PAGING_FAILURE;
      if (action.payload.response.status && action.payload.response.status === 400) {
        alertMessage = getErrorMessage(action);
      }
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR,
        isReload: false
      };
    }

    case GET_MINING_CHILDS_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false,
        isReload: false
      };
    }
    case GET_MINING_CHILDS_FULFILLED: {
      return {
        ...state,
        loading: false,
        miningChildItems: action.payload.data.data,
        showMessage: false,
        isReload: false
      };
    }
    case GET_MINING_CHILDS_REJECTED: {
      let alertMessage = MSG_PAGING_FAILURE;
      if (action.payload.response.status && action.payload.response.status === 400) {
        alertMessage = getErrorMessage(action);
      }
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR,
        isReload: false
      };
    }

    case DELETE_ITEM_REPORT_RESULT_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false,
        isReload: false
      };
    }
    case DELETE_ITEM_REPORT_RESULT_FULFILLED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: 'Xóa dữ liệu thành công !',
        messageType: MSG_TYPE_SUCCESS,
        isReload: true
      };
    }
    case DELETE_ITEM_REPORT_RESULT_REJECTED: {
      let alertMessage = MSG_DELETE_FAILURE;
      //   if (action.payload.response.status && action.payload.response.status === 400) {
      //     alertMessage = getErrorMessage(action);
      //   }
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR,
        isReload: false
      };
    }

    case CREATE_DATA_BUSINEES_REPORT_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false,
        isReload: false
      };
    }
    case CREATE_DATA_BUSINEES_REPORT_FULFILLED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: 'Thêm dữ liệu thành công !',
        messageType: MSG_TYPE_SUCCESS,
        isReload: true
      };
    }
    case CREATE_DATA_BUSINEES_REPORT_REJECTED: {
      let alertMessage = MSG_ADD_FAILURE;
      //   if (action.payload.response.status && action.payload.response.status === 400) {
      //     alertMessage = getErrorMessage(action);
      //   }
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR,
        isReload: false
      };
    }

    case UPDATE_DATA_BUSINEES_REPORT_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false,
        isReload: false
      };
    }
    case UPDATE_DATA_BUSINEES_REPORT_FULFILLED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: 'Cập nhật dữ liệu thành công !',
        messageType: MSG_TYPE_SUCCESS,
        isReload: true
      };
    }
    case UPDATE_DATA_BUSINEES_REPORT_REJECTED: {
      let alertMessage = MSG_EDIT_FAILURE;
      //   if (action.payload.response.status && action.payload.response.status === 400) {
      //     alertMessage = getErrorMessage(action);
      //   }
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR,
        isReload: false
      };
    }

    case REMOVE_ROW_REPORT_INLAND_REVUNUE: {
      const indexRemove = action.payload;
      let reports = [...state.reports];
      reports.splice(indexRemove, 1);
      return {
        ...state,
        reports: reports,
        isReload: false
      };
    }

    case REMOVE_ROW_REPORT_MINING_REVUNUE: {
      const indexRemove = action.payload.index;
      const parentId = action.payload.parentId;
      let reports = [...state.reports];
      let nodeFoundResult = getResultNode(reports, parentId);
      const childsUpdating = [...nodeFoundResult.Childs];
      childsUpdating.splice(indexRemove, 1);
      nodeFoundResult.Childs = childsUpdating;
      const reportUpdated = [...reports];
      return {
        ...state,
        reports: reportUpdated,
        isReload: false
      };
    }

    default:
      return state;
  }
};
