const alphabetNumerical = {
    A: 10,
    B: 12,
    C: 13,
    D: 14,
    E: 15,
    F: 16,
    G: 17,
    H: 18,
    I: 19,
    J: 20,
    K: 21,
    L: 23,
    M: 24,
    N: 25,
    O: 26,
    P: 27,
    Q: 28,
    R: 29,
    S: 30,
    T: 31,
    U: 32,
    V: 34,
    W: 35,
    X: 36,
    Y: 37,
    Z: 38
};

const pattern = /^([A-Z]{3})(U|J|Z)(\d{6})(\d)$/;
const patternWithoutCheckDigit = /^([A-Z]{3})(U|J|Z)(\d{6})$/;
var errorMessages = [];
var ownerCode = [];
var registrationDigit = [];
var checkDigit = null;
// var containerNumber = "";

const empty = mixed_var => {
    var undef, key, i, len;
    var emptyValues = [undef, null, false, 0, "", "0"];

    for (i = 0, len = emptyValues.length; i < len; i++) {
        if (mixed_var === emptyValues[i]) {
            return true;
        }
    }

    if (typeof mixed_var === "object") {
        for (key in mixed_var) {
            //if (mixed_var.hasOwnProperty(key)) {
            return false;
            //}
        }
        return true;
    }

    return false;
};

const validate = containerNumber => {
    let matches = [];

    if (!empty(containerNumber) && is_string(containerNumber)) {
        matches = identify(containerNumber);

        if (count(matches) !== 5) {
            errorMessages.push("The container number is invalid");
        } else {
            let tmpCheckDigit = buildCheckDigit(matches);
            if (checkDigit != tmpCheckDigit) {
                errorMessages.push("The check digit does not match");
                matches = [];
            }
        }
    } else {
        errorMessages = { 0: "The container number must be a string" };
    }
    return matches;
};

const clearErrors = () => {
    errorMessages = [];
};

const buildCheckDigit = matches => {
    let tmpCheckDigit = "";
    let productGroupCode = "";
    if (matches[1]) {
        ownerCode = str_split(matches[1]);
    }
    if (matches[2]) {
        productGroupCode = matches[2];
    }
    if (matches[3]) {
        registrationDigit = str_split(matches[3]);
    }
    if (matches[4]) {
        checkDigit = matches[4];
    }

    // convert owner code + product group code to its numerical value
    let numericalOwnerCode = [];
    for (let i = 0; i < count(ownerCode); i++) {
        numericalOwnerCode[i] = alphabetNumerical[ownerCode[i]];
    }
    numericalOwnerCode.push(alphabetNumerical[productGroupCode]);

    // merge numerical owner code with registration digit
    let numericalCode = array_merge(numericalOwnerCode, registrationDigit);
    let sumDigit = 0;

    // check six-digit registration number and last check digit
    for (var i = 0; i < count(numericalCode); i++) {
        sumDigit += numericalCode[i] * Math.pow(2, i);
    }

    let sumDigitDiff = Math.floor(sumDigit / 11) * 11;
    tmpCheckDigit = sumDigit - sumDigitDiff;
    return tmpCheckDigit === 10 ? 0 : tmpCheckDigit;
};

const identify = (containerNumber, withoutCheckDigit) => {
    //SHS set default values for params
    withoutCheckDigit =
        typeof withoutCheckDigit !== "undefined" ? withoutCheckDigit : false;

    clearErrors();
    let matches = "";
    if (withoutCheckDigit) {
        matches = this.preg_match(
            patternWithoutCheckDigit,
            this.strtoupper(containerNumber)
        );
    } else {
        matches = preg_match(pattern, strtoupper(containerNumber));
    }
    return matches;
};

//SHS Helper functions
const is_string = param => {
    return typeof param == "string" ? true : false;
};

const preg_match = (pattern, string) => {
    var regex = new RegExp(pattern);
    return regex.exec(string);
};

const strtoupper = string => {
    return string.toUpperCase();
};

const count = array => {
    if (array == null) {
        return 0;
    } else {
        return array.length;
    }
};

// const strlen = string => {
//     return string.length;
// };

const str_split = (string, split_length) => {
    if (split_length == null) {
        split_length = 1;
    }
    if (string == null || split_length < 1) {
        return false;
    }
    string += "";
    var chunks = [],
        pos = 0,
        len = string.length;
    while (pos < len) {
        chunks.push(string.slice(pos, (pos += split_length)));
    }

    return chunks;
};

// const str_pad = (input, pad_length, pad_string, pad_type) => {
//     let half = "",
//         pad_to_go;

//     let str_pad_repeater = function(s, len) {
//         var collect = "",
//             i;

//         while (collect.length < len) {
//             collect += s;
//         }
//         collect = collect.substr(0, len);

//         return collect;
//     };

//     input += "";
//     pad_string = pad_string !== undefined ? pad_string : " ";

//     if (
//         pad_type !== "STR_PAD_LEFT" &&
//         pad_type !== "STR_PAD_RIGHT" &&
//         pad_type !== "STR_PAD_BOTH"
//     ) {
//         pad_type = "STR_PAD_RIGHT";
//     }
//     if ((pad_to_go = pad_length - input.length) > 0) {
//         if (pad_type === "STR_PAD_LEFT") {
//             input = str_pad_repeater(pad_string, pad_to_go) + input;
//         } else if (pad_type === "STR_PAD_RIGHT") {
//             input = input + str_pad_repeater(pad_string, pad_to_go);
//         } else if (pad_type === "STR_PAD_BOTH") {
//             half = str_pad_repeater(pad_string, Math.ceil(pad_to_go / 2));
//             input = half + input + half;
//             input = input.substr(0, pad_length);
//         }
//     }

//     return input;
// };

function array_merge() {
    let args = Array.prototype.slice.call(arguments),
        argl = args.length,
        arg,
        retObj = {},
        k = "",
        argil = 0,
        j = 0,
        i = 0,
        ct = 0,
        toStr = Object.prototype.toString,
        retArr = true;

    for (i = 0; i < argl; i++) {
        if (toStr.call(args[i]) !== "[object Array]") {
            retArr = false;
            break;
        }
    }

    if (retArr) {
        retArr = [];
        for (i = 0; i < argl; i++) {
            retArr = retArr.concat(args[i]);
        }
        return retArr;
    }

    for (i = 0, ct = 0; i < argl; i++) {
        arg = args[i];
        if (toStr.call(arg) === "[object Array]") {
            for (j = 0, argil = arg.length; j < argil; j++) {
                retObj[ct++] = arg[j];
            }
        } else {
            for (k in arg) {
                if (arg.hasOwnProperty(k)) {
                    if (parseInt(k, 10) + "" === k) {
                        retObj[ct++] = arg[k];
                    } else {
                        retObj[k] = arg[k];
                    }
                }
            }
        }
    }
    return retObj;
}

export const isValidContNo = containerNumber => {
    validate(containerNumber);
    if (empty(errorMessages)) {
        return true;
    }
    return false;
};