import { TRANSPORT_COST_ACTIONS } from 'actions/transportCost';
import { put, takeEvery } from 'redux-saga/effects';
import { getTransportCostPerPage } from 'services/transportCost';

function* putActionUpdateData() {
  yield put({
    type: TRANSPORT_COST_ACTIONS.GET_TRANSPORT_COST_PER_PAGE,
    payload: getTransportCostPerPage(1, parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE))
  });
}

export function* actionUpdateTransportCostData() {
  yield takeEvery(
    [
      // TRANSPORT_COST_ACTIONS.UPDATE_TRANSPORT_COST_FULFILLED,
      // TRANSPORT_COST_ACTIONS.CREATE_TRANSPORT_COST_FULFILLED,
      //   TRANSPORT_COST_ACTIONS.DELETE_TRANSPORT_COST_FULFILLED,
    ],
    putActionUpdateData
  );
}
