import { CUSTOMER_TYPE } from 'const/common';
import api from 'HttpClient';

export const getAllTransportCompany = (type) => {
  const transportCompanyType = type ?? CUSTOMER_TYPE.TRANSPORT_COMPANY;
  return api.get(`/customerinfo/getByCustomerType/${transportCompanyType}`);
};

export const getDriverByTransportCompany = (id) => {
  return api.get(`/employeeinfo/GetDriverByTransportCompanyId?transportCompanyId=${id}`);
};

export const getTransportCompanyById = (id) => {
  return api.get(`/TransportCompanyInfo/getById/${id}`);
};

export const getTransportCustomer = () => {
  return api.get(`/CustomerInfo/getByCustomerTypeAny?customerType=2`);
};

export const getTransportCustomerByType = (type) => {
  return api.get(`/CustomerInfo/getByCustomerTypeAny?customerType=${type}`);
};
