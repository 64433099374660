import React from 'react';
import Axios from 'axios';
import { SECONDARY_COLOR } from 'const/colors';
import {
  CALCULATE_BY_ROOT_ADDRESS,
  CALCULATE_BY_TRANSPORT_COST,
  CALCULATE_FROM_DETAIL_TO_EXTEND_ADDRESS,
  CONSIGNMENT_TYPE_INPUT_IDS,
  distanceUrl,
  DOWN_PAYMENT_STATUS_IS_CLOSE,
  DOWN_PAYMENT_STATUS_IS_OPEN,
  LEFT_SIDEBAR_MINIMIZE,
  LEFT_SIDEBAR_SHOW,
  REPLACE_SPECIAL_CHARACTER_URL,
  TRUCKING_STATUS_COLORS
} from 'const/common';
import { SCREEN_NAME_WITH_ROUTE } from 'const/screenNameUserManual';
import { formatDistanceToNow } from 'date-fns';
import viLocale from 'date-fns/locale/vi';
import httpClient from 'HttpClient';
import _ from 'lodash';
import moment from 'moment';
import { getFormValues } from 'redux-form';

import { extractDisplayColumnInfo } from './columnTreeUtils';
import { getObject, getValue } from './SelectUtils';
import { fommatCurrency } from './ValidateEditable';

var htmlToPdfMake = require('html-to-pdfmake');

export const formmatDateToDDMMYYYYY = (inputDate) => {
  if (inputDate === null || inputDate === undefined) {
    return '';
  }
  // let date = new Date(inputDate);
  // if (!isNaN(date.getTime())) {
  //   let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  //   let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  //   return day + '/' + month + '/' + date.getFullYear();
  // }
  let date = moment(inputDate).format('DD/MM/YYYY');
  return date !== 'Invalid date' ? date : '';
};
export const formatDateToDDMMYYHHmm = (inputDate) => {
  if (inputDate === null || inputDate === undefined) {
    return '';
  }
  let date = moment(inputDate).format('DD/MM/YYYY') + '\n' + moment(inputDate).format('HH:mm');
  return date !== 'Invalid date' ? date : '';
};

export const formatDateToDDMMYYHHmm2 = (inputDate) => {
  if (inputDate === null || inputDate === undefined) {
    return '';
  }
  let date = moment(inputDate).format('DD/MM/YYYY HH:mm');
  return date !== 'Invalid date' ? date : '';
};
export const formatDateToHHmm = (inputDate) => {
  if (inputDate === null || inputDate === undefined) {
    return '';
  }
  let date = moment(inputDate).format('HH:mm');
  return date !== 'Invalid date' ? date : '';
};
export const formmatDateToYYYYMMDD = (inputDate) => {
  if (inputDate === null || inputDate === undefined) {
    return null;
  }
  let date = moment(inputDate).format('YYYY-MM-DD');
  return date !== 'Invalid date' ? date : '';
};

export const isTrue = (data) => {
  return data === 'true' || data === true;
};

export const isNull = (data) => {
  return data === undefined || data === null;
};

export const isEmptyArray = (data) => {
  return Array.isArray(data) && data.length === 0;
};

export const isEmptyString = (data) => {
  return typeof data === 'string' && data.length === 0;
};

export const isEmptyObject = (data) => {
  return typeof data === 'object' && Object.keys(data).length === 0;
};

export const isNullOrEmpty = (data) => {
  let result = false;
  const dataIsNull = isNull(data);
  if (dataIsNull) {
    result = true;
  } else {
    const dataIsEmptyArray = isEmptyArray(data);
    const dataIsEmptyObject = isEmptyObject(data);
    const dataIsEmptyString = isEmptyString(data);
    if (dataIsEmptyArray || dataIsEmptyObject || dataIsEmptyString) {
      result = true;
    }
  }
  return result;
};

const KEY = 0;
const VALUE = 1;

export const parseUrlParams = (url) => {
  if (isNullOrEmpty(url)) return {};
  const params = {};
  const locationSearchParams = url.substring(1).split('&');
  locationSearchParams.forEach((locationSearchParam) => {
    const keyValue = locationSearchParam.split('=');
    const key = keyValue[KEY];
    const value = keyValue.length < 2 ? '' : keyValue[VALUE];
    params[key] = value;
  });
  return params;
};

const getLanguage = () =>
  navigator.userLanguage ||
  (navigator.languages && navigator.languages.length && navigator.languages[0]) ||
  navigator.language ||
  navigator.browserLanguage ||
  navigator.systemLanguage ||
  'en';

export function getDefaultBrowserDateFormatString() {
  const formatObj = new Intl.DateTimeFormat(getLanguage()).formatToParts(new Date());

  return formatObj
    .map((obj) => {
      switch (obj.type) {
        case 'day':
          return 'DD';
        case 'month':
          return 'MM';
        case 'year':
          return 'YYYY';
        default:
          return obj.value;
      }
    })
    .join('');
}

export function datetimeToDate(dateString) {
  if (!dateString || dateString.trim() === '') return '';
  return dateString.slice(0, 10);
}

export function splitByComma(string) {
  if (!string) return string;
  const htmlText = string.split(',').join(',<br />');
  const result = <span dangerouslySetInnerHTML={{ __html: htmlText }} />;
  return result;
}

export function mapValueToFieldReport(array, fieldName) {
  if (array && array.length > 0) {
    let result = array.find((el) => el.Name === fieldName);
    return result?.CurrentValue;
  } else {
    return;
  }
}

export function getObjectByList(list, fieldName, key = 'Name') {
  if (list && list.length > 0) {
    let result = list.find((el) => el[key] === fieldName);
    return result;
  } else {
    return;
  }
}

export function renderHeaderReportPrint(array, dataImg) {
  let dataImgs = mapValueToFieldReport(array, 'Report_Company_Url_Logo');
  let companyName = mapValueToFieldReport(array, 'Report_Company_Name');
  let address = mapValueToFieldReport(array, 'Report_Company_Address');
  let taxCode = mapValueToFieldReport(array, 'Report_Company_Tax_Code');
  let phone = mapValueToFieldReport(array, 'Report_Company_Phone');
  return {
    columns: [
      dataImgs && {
        image: dataImgs,
        width: 80,
        height: 80,
        margin: [30, 0, 0, 0]
      },
      [
        {
          text: companyName ? companyName : '',
          fontSize: 13,
          bold: true,
          margin: [50, 15, 0, 5],
          alignment: 'left',
          style: 'subheader'
        },
        {
          text: `Địa chỉ : ${address ? address : ''}`,

          fontSize: 10,
          bold: true,
          margin: [50, 0, 0, 5],
          alignment: 'left'
        },
        {
          text: `Mã số thuế  : ${taxCode !== undefined ? taxCode : ''}`,

          fontSize: 10,
          bold: true,
          margin: [50, 0, 0, 5],
          alignment: 'left'
        }
        // {
        //   text: `Số điện thoại : ${phone !== undefined ? phone : ''}`,
        //   style: 'subheader'
        // }
      ]
    ]
  };
}

export function renderSignPaymentSettlement() {
  return {
    margin: [0, 30, 0, 50],
    style: 'sign',
    columns: [
      {
        width: '25%',
        text: 'Người lập phiếu',
        alignment: 'center'
      },
      {
        width: '25%',
        text: 'Thương vụ',
        alignment: 'center'
      },
      {
        width: '25%',
        text: 'Kế toán',
        alignment: 'center'
      },
      {
        width: '25%',
        text: 'Giám đốc',
        alignment: 'center'
      }
    ],
    columnGap: 10
  };
}

export const dispatchFunctionMap = (functionMap) => (dispatch) => {
  const dispatchFunction = Object.keys(functionMap).reduce((acc, functionKey) => {
    const func = functionMap[functionKey];
    acc[functionKey] = (...params) => dispatch(func.call(this, ...params));
    return acc;
  }, {});
  return dispatchFunction;
};

export function valueOrDefault(formValue, defaultValue) {
  let result = formValue;
  if (isNull(result)) {
    result = defaultValue;
  }
  return result;
}

export function valueOrEmptyObject(formValues) {
  let result = formValues;
  if (isNull(result)) {
    result = {};
  }
  return result;
}

export function valueOrEmptyString(formValues) {
  let result = formValues;
  if (isNull(result)) {
    result = '';
  }
  return result;
}

export function normalizeToPositiveNumber(value) {
  let result = value;
  if (isNullOrEmpty(value)) {
    result = 0;
  } else {
    const number = parseFloat(value);
    if (number < 0) {
      result = 0;
    } else {
      result = number;
    }
  }
  return result;
}

export function retrieveFormValues(state, formName) {
  const formValues = getFormValues(formName)(state);
  let result = valueOrEmptyObject(formValues);
  return result;
}

function formatPlateNo(plateNo) {
  if (isNull(plateNo)) return null;
  const skipDotPlateNo = plateNo.split('.').join('');
  const plateNoLength = skipDotPlateNo.length;
  let result = skipDotPlateNo;
  if (plateNoLength === 5) {
    result = `${skipDotPlateNo.substr(0, 3)}.${skipDotPlateNo.substr(3)}`;
  }
  return result;
}

export function composePlateNo(tractorTrailer) {
  const prePlateNo = tractorTrailer?.PrePlateNo ?? null;
  const plateNo = tractorTrailer?.PlateNo ?? null;
  // const formatedPlateNo = formatPlateNo(plateNo);
  // const result = combineTwoValue(prePlateNo, formatedPlateNo);
  const result = combineTwoValue(prePlateNo, plateNo);
  return result;
}

export function combinePlateNo(tractorTrailer) {
  const prePlateNo = tractorTrailer?.PrePlateNo ?? null;
  const plateNo = tractorTrailer?.PlateNo ?? null;
  // const formatedPlateNo = formatPlateNo(plateNo);
  const result = combineTwoValue(prePlateNo ? prePlateNo.trim() : null, plateNo ? plateNo.trim() : null);
  return result;
}

export function composeTransportCompanyAbbr(TransportCompany, TractorTrailer) {
  // console.log('composeTransportCompany', TransportCompany, TractorTrailer)
  if (!TransportCompany) return '';
  let result = TransportCompany.PreConsignmentCode;
  const departmentAbbr = TractorTrailer?.Department?.DepartmentName ?? null;
  if (departmentAbbr) {
    if (TransportCompany.IsPrimaryCompany) {
      result = departmentAbbr;
    } else {
      result = `${result} - ${departmentAbbr}`;
    }
  }
  return result;
}

function combineTwoValue(value1, value2, seperator = '-') {
  let result = value1;
  if (value1 && value2) {
    result = `${value1}${seperator}${value2}`;
  } else if (!value1 && value2) {
    result = value2;
  }
  return result;
}

export function composeDriverName(Driver, seperator = ' ') {
  const firstName = Driver?.FirstName ?? null;
  const lastName = Driver?.LastName ?? null;
  let result = combineTwoValue(firstName, lastName, seperator);
  return result;
}

export function composeRouteName(StartingPoint, Destination) {
  const startingPointTxt = StartingPoint?.Address ?? null;
  const destinationTxt = Destination?.Address ?? null;
  let result = combineTwoValue(startingPointTxt, destinationTxt);
  return result;
}

export function getArisingPoint(routes) {
  const length = routes?.length;
  let result = null;
  for (let i = 2; i < length; i++) {
    const value = `- ${routes[i - 1]?.Destination?.Address ?? null}<br/>`;
    if (result === null) {
      result = value;
    } else {
      result += value;
    }
  }
  return <span dangerouslySetInnerHTML={{ __html: result }}></span>;
}

export function calculateVehicleQuantity(totalWeight, vehicleWeight) {
  const totalWeightNum = isNullOrEmpty(totalWeight) ? 0 : parseFloat(totalWeight);
  const vehicleWeightNum = isNullOrEmpty(vehicleWeight) ? 0 : parseFloat(vehicleWeight);
  let vehicleQuantity = totalWeightNum === 0 ? 0 : 1;
  if (vehicleWeight !== 0 && totalWeightNum !== 0) {
    const ratio = totalWeightNum / vehicleWeightNum;
    if (ratio > 1) {
      // vehicleQuantity = Math.round(ratio);
      if (ratio - parseInt(ratio) > 0) {
        vehicleQuantity = parseInt(ratio) + 1;
      } else {
        vehicleQuantity = parseInt(ratio);
      }
    }
  }
  return vehicleQuantity;
}

export function getTransportCompanyDisplayname(transportCompanyId, transportCompanies, departmentId, departments) {
  let transportCompanyName = '';
  const transportCompanyObject = getObject(transportCompanyId, transportCompanies);
  const departmentObject = getObject(departmentId, departments);

  if (transportCompanyObject?.IsPrimaryCompany && !isNull(departmentObject)) {
    transportCompanyName = departmentObject.DepartmentName;
  } else if (transportCompanyObject) {
    transportCompanyName = transportCompanyObject.PreConsignmentCode;
  }
  return transportCompanyName;
}

function getContCategoryObject(contCategory, contCategories) {
  const DEFAULT_CONT_CATEGORY = { value: null, label: '' };
  if (isNullOrEmpty(contCategory) || isNullOrEmpty(contCategories)) return DEFAULT_CONT_CATEGORY;
  let result = contCategory;
  if (typeof contCategory !== 'object') {
    const foundContCategory = contCategories.find((element) => element.Id === contCategory);
    if (foundContCategory) {
      result = { ...foundContCategory, label: foundContCategory.Abbr, value: foundContCategory.Id };
    } else {
      result = {
        ...DEFAULT_CONT_CATEGORY,
        value: contCategory
      };
    }
  }
  return result;
}

function combineToArray(...params) {
  return params;
}

export function composeContSerial(ContSerialNo1, ContSerialNo2, FullContSerialNo1, FullContSerialNo2) {
  let result = null;
  if (ContSerialNo1) {
    const contSerialNo1Label = ContSerialNo1?.ContSerialNo ?? null;
    const contSerialNo2Label = ContSerialNo2?.ContSerialNo ?? null;
    result = combineToArray(contSerialNo1Label, contSerialNo2Label);
  } else {
    result = combineToArray(FullContSerialNo1, FullContSerialNo2);
  }
  if (!result?.[1]) {
    result = result?.[0];
  }

  return result;
}

export function composeContCategory(
  ContSerialNo1,
  ContSerialNo2,
  FullContCategoryId1,
  FullContCategoryId2,
  contCategories
) {
  let result = null;
  if (ContSerialNo1) {
    const contCategoryNo1Label = ContSerialNo1?.ContCategory?.Abbr ?? null;
    const contCategoryNo2Label = ContSerialNo2?.ContCategory?.Abbr ?? null;
    result = combineToArray(contCategoryNo1Label, contCategoryNo2Label);
  } else {
    const FullContCategoryId1Obj = getContCategoryObject(FullContCategoryId1, contCategories);
    const FullContCategoryId2Obj = getContCategoryObject(FullContCategoryId2, contCategories);

    const FullContCategoryId1Label = FullContCategoryId1Obj?.label ?? null;
    const FullContCategoryId2Label = FullContCategoryId2Obj?.label ?? null;

    result = combineToArray(FullContCategoryId1Label, FullContCategoryId2Label);
  }
  if (!result?.[1]) {
    result = result?.[0];
  }

  return result;
}

export function composeContInfo(
  FullContSerialNo1,
  FullContCategoryId1,
  FullContSerialNo2,
  FullContCategoryId2,
  contCategories
) {
  const FullContCategoryId1Obj = getContCategoryObject(FullContCategoryId1, contCategories);
  const FullContCategoryId2Obj = getContCategoryObject(FullContCategoryId2, contCategories);

  const FullContCategoryId1Label =
    FullContCategoryId1Obj && FullContCategoryId1Obj.label ? FullContCategoryId1Obj.label : '';
  const FullContCategoryId2Label =
    FullContCategoryId2Obj && FullContCategoryId2Obj.label ? FullContCategoryId2Obj.label : '';

  const contLabel1 = `${valueOrDefault(FullContSerialNo1, '')}(${FullContCategoryId1Label})`;
  const contLabel2 = `${valueOrDefault(FullContSerialNo2, '')}(${FullContCategoryId2Label})`;

  let contLabel = contLabel1;

  if (FullContCategoryId2) {
    contLabel += `,${contLabel2}`;
  }
  return contLabel;
}

export function getCarOwnerName(department, transportCompany) {
  let result = '';
  if (department) {
    result = department.DepartmentName;
    // if (result && !result.startsWith('NTC')) {
    //   result = 'NTC - ' + result;
    // }
  } else if (transportCompany) {
    result = transportCompany.PreConsignmentCode;
  }
  return result;
}

export const parseConditionToString = (value, fieldName) => {
  const data = value && value.length > 0 ? value : [];
  const addFieldNameToData =
    data.length > 0
      ? data.map((el) => {
          return (fieldName + '' + el).trim();
        })
      : [];
  const parseDataToString =
    addFieldNameToData.length > 0
      ? fieldName === 'height'
        ? addFieldNameToData.join(',')
        : addFieldNameToData.join(',') + ','
      : '';
  return parseDataToString.trim();
};

export const parseStringFormulas = (string) => {
  const data =
    string.length > 0 ? (string.charAt(string.length - 1) === ',' ? string.substr(0, string.length - 1) : string) : '';
  const result = data !== '' ? '= AND (' + data.trim() + ')' : null;
  return result;
};

export const parseFormulasStringToArray = (string, fieldName) => {
  if (string === '') {
    return [];
  } else {
    const startIndex = string.indexOf('(');
    const endIndex = string.indexOf(')');
    const subStringValue = string.substr(startIndex, endIndex);
    const removeParentheses = subStringValue.substr(1, subStringValue.length - 2);
    const trimRemoveParentheses = removeParentheses.trim();
    const result = findItemWithFieldName(trimRemoveParentheses.split(','), fieldName);
    return result;
  }
};

export const findItemWithFieldName = (array, fieldName) => {
  let result = [];
  if (!array && array.length === 0) {
    result = [];
  } else {
    let length = array.length;
    for (let i = 0; i < length; i++) {
      let checkStartWithFieldName = array[i].startsWith(fieldName) || array[i].startsWith(' ' + fieldName);
      if (checkStartWithFieldName === true) result.push(array[i].replaceAll(fieldName, ''));
    }
  }
  return returnItemWithStartConditons(result, fieldName);
};

const returnItemWithStartConditons = (array, fieldName) => {
  const conditions = ['>', '<', '>=', '<=', '===', '=='];
  let result = [];
  let length = array.length;
  for (let i = 0; i < length; i++) {
    let trimItem = array[i].trim();
    let startCharacter = trimItem.substring(0, 1);
    let checkStartWithFieldName = conditions.includes(startCharacter);
    if (checkStartWithFieldName === true) result.push(array[i].replaceAll(fieldName, ''));
  }
  return result;
};

export const checkObjectSubmit = (data, dataPayload) => {
  let type = typeof data;
  let isObject = type === 'object';
  let result = data ? (isObject ? data.value : data) : null;
  return result;
};

export const checkRedirectDownpaymentTheFirst = (numberDownpayment, status) => {
  if (numberDownpayment === 0) {
    return true;
  } else {
    if (numberDownpayment === 1) {
      if (status === DOWN_PAYMENT_STATUS_IS_OPEN) {
        return true;
      } else if (status === DOWN_PAYMENT_STATUS_IS_CLOSE) {
        return false;
      }
    } else {
      return false;
    }
  }
};

export const convertTimeToDateTimeString = (time) => {
  const initDate = new Date(time);
  const dateString = formmatDateToDDMMYYYYY(time);
  const hour = initDate.getHours();
  const minute = initDate.getMinutes();
  const second = initDate.getSeconds();
  const timeString = hour + ':' + minute + ':' + second;
  return dateString + ' ' + timeString;
};

export const displayCurrency = (currency) => {
  if (currency !== undefined && currency !== null) {
    return fommatCurrency(currency);
  } else {
    return '';
  }
};

export function deepCopy(sourceObject) {
  const json = JSON.stringify(sourceObject);
  const destObject = JSON.parse(json);
  return destObject;
}

export const initDateValueForInputDate = (time) => {
  let date = new Date(time);
  let dateString = parseInt(date.getDate()) < 10 ? '0' + date.getDate() + '' : date.getDate();
  let monthString = parseInt(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) + '' : date.getMonth() + 1;
  let year = date.getFullYear();
  let result = year + '-' + monthString + '-' + dateString;
  return result;
};

export function convertObjectToArrayInObject(responses, field) {
  if (responses && responses.length === 0) return [];
  let fieldValues = [];
  let result = [];
  responses.forEach((item) => {
    const fieldValue = item[`${field}`];
    if (!fieldValues.includes(fieldValue)) {
      fieldValues.push(fieldValue);
      const sameFieldValues = findFieldValues(responses, fieldValue, field);
      result = [...result, sameFieldValues];
    }
  });
  return result;
}

function findFieldValues(data, field, fieldNameCondition) {
  const item = data.filter((el) => {
    return el[`${fieldNameCondition}`] === field;
  });
  const result = { field: item };
  return result;
}

export function totalFunc(accumulator, currentValue) {
  return parseInt(accumulator) + parseInt(currentValue);
}

export function caculatorTotalColumns(array, fieldName) {
  if ((array && array.length === 0) || !fieldName) {
    return 0;
  } else {
    let result = array.map((el) => (el[`${fieldName}`] !== null ? el[`${fieldName}`] : 0));
    let total = result.reduce(totalFunc);
    return total;
  }
}

export function convertValue(formData, payload, properties, func) {
  if (isNull(formData)) return;
  properties.forEach((prop) => {
    const fieldValue = formData[prop];
    const convertedValue = func ? func(fieldValue) : fieldValue;
    payload[prop] = convertedValue;
  });
}

export function formatConsignmentCode(customerAbbr, consignmentGoodsTypeAbbr) {
  if (isNull(customerAbbr) || isNull(consignmentGoodsTypeAbbr)) return null;
  const now = moment();
  const currentYear = now.format('YY.MM.DD');
  return `${customerAbbr}${currentYear}.`;
}

export const addDate = (dateNo) => {
  let date = new Date();
  date.setDate(date.getDate() + dateNo);
  return date;
};

export const removeDate = (dateNo) => {
  let date = new Date();
  date.setDate(date.getDate() - dateNo);
  return date;
};

export const parseDateToStringSearch = (time) => {
  let date = new Date(time);
  let dateString = parseInt(date.getDate()) < 10 ? '0' + date.getDate() + '' : date.getDate();
  let monthString = parseInt(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) + '' : date.getMonth() + 1;
  let year = date.getFullYear();
  let result = year + '-' + monthString + '-' + dateString;
  return result;
};

export const sliceDate = (date) => {
  if (date) {
    return date.slice(0, 10) + '';
  } else {
    return '';
  }
};

export function getDefaultValueCaculateByWeightSetting(settings) {
  let result = null;
  if (!settings || settings.length === 0) return null;
  const obj = settings.find((el) => {
    return el.Name === 'Calculate_Fee_By_Weight';
  });
  if (obj) {
    result = parseInt(obj.CurrentValue);
  }
  return result;
}

export function getDate(dateString) {
  if (isNull(dateString)) return null;
  const [year, month, day] = dateString
    .substring(0, 10)
    .split('-')
    .map((string) => parseInt(string));
  const [hour, minute, second] = dateString
    .substring(11, 19)
    .split(':')
    .map((string) => parseInt(string));
  const newDate = new Date(year, month - 1, day, hour, minute, second);
  return newDate;
}

export const formatDateTime = (value) => {
  return moment(value).format('YYYY-MM-DD') + '\n' + moment(value).format('HH:mm:ss');
};

export function getDateDistanceInHumanReadable(dateString) {
  if (isNullOrEmpty(dateString)) return null;
  const date = getDate(dateString);
  const distance = formatDistanceToNow(date, { locale: viLocale });
  return distance;
}

export function getFirstAndLastDateOnMonth(date) {
  const dateSelected = moment(date);
  const year = dateSelected.format('YYYY');
  const month = dateSelected.format('M');
  const firstDate = new Date(year, month - 1, 1);
  const lastDate = new Date(year, month, 0);
  const firstDateStringToSearch = initDateValueForInputDate(firstDate);
  const lastDateStringToSearch = initDateValueForInputDate(lastDate);
  const result = { firstDay: firstDateStringToSearch, lastDay: lastDateStringToSearch };
  return result;
}
export function getModeSettings(settings, groupId, name) {
  if (isNull(settings) || isEmptyArray(settings)) return null;
  const mode = settings.find((el) => {
    return el.OptionGroupId === groupId && el.Name === name;
  });
  return mode?.CurrentValue ?? null;
}

export function getFieldModeSettings(settings, groupId, name, filed) {
  if (isNull(settings) || isEmptyArray(settings)) return null;
  const mode = settings.find((el) => {
    return el.OptionGroupId === groupId && el.Name === name;
  });
  return mode?.[filed] ?? null;
}

export function debounce(func, wait = 500) {
  let timeout;
  return function () {
    var context = this,
      args = arguments;

    var executeFunction = function () {
      func.apply(context, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(executeFunction, wait);
  };
}

export function getLeftValue(sidebarShow, minimize) {
  let result = 0;
  if (minimize && sidebarShow) {
    result = LEFT_SIDEBAR_MINIMIZE;
  } else if (!minimize && sidebarShow) {
    result = LEFT_SIDEBAR_SHOW;
  }
  return result;
}

export function isFieldEqual(object1, object2) {
  if (isNull(object1) && isNull(object2)) return true;
  if (isNull(object1) !== isNull(object2)) return false;
  if (typeof object1 !== 'object') return object1 === object2;
  if (Object.keys(object1).length !== Object.keys(object2).length) return false;
  let result = true;
  Object.keys(object1).forEach((field) => {
    if (object1[field] !== object2[field]) {
      result = false;
    }
  });
  return result;
}

export function removeAccents(str) {
  if (isNull(str)) return str;
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D');
}

export function checkStatusRejectPromise(res) {
  const statusFulfilled = 200;
  if (isNullOrEmpty(res)) return true;
  const hasErr = res.includes(!statusFulfilled);
  return hasErr;
}

export const addMonthToDate = (fromDate, month) => {
  let date = new Date(fromDate);
  date.setMonth(date.getMonth() + month);
  return date;
};

export const removeDateFromDate = (fromDate, dateNo) => {
  let date = new Date(fromDate);
  date.setDate(date.getDate() - dateNo);
  return date;
};

export function monthDiff(d1, d2) {
  const startDate = parseDateToStringSearch(d1);
  const endDate = parseDateToStringSearch(d2);
  let start = startDate.split('-');
  let end = endDate.split('-');
  let startYear = parseInt(start[0]);
  let endYear = parseInt(end[0]);
  let dates = [];

  for (let i = startYear; i <= endYear; i++) {
    let endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
    let startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      let month = j + 1;
      let displayMonth = month < 10 ? '0' + month : month;
      dates.push([i, displayMonth, '01'].join('-'));
    }
  }
  return dates.length;
}

const defaultStyleForPdf = {
  th: { bold: true, fillColor: '#EEEEEE', color: 'black', background: '#EEEEEE' },
  table: { fontSize: 14 },
  span: { bold: false, fontSize: 10 }
};
const pageType = {
  A4: {
    landscape: {
      width: (30 - 2) * 38
    },
    portrait: {
      width: (21 - 2) * 38
    }
  },
  A3: {
    landscape: {
      width: (42 - 2) * 38
    },
    portrait: {
      width: (30 - 2) * 38
    }
  }
};

export const createTableDocToPdfCustomer = (
  elementId,
  defaultStyles = defaultStyleForPdf,
  size = '*',
  preIndex = 0
) => {
  const tableElement = document.getElementById(elementId);
  const defaultFontSize = defaultStyleForPdf.table.fontSize;
  const pageSize = defaultStyles.PageType.pageSize;
  const pageOrientation = defaultStyles.PageType.pageOrientation;
  const tableWidth = tableElement?.scrollWidth || tableElement?.offsetWidth || tableElement?.width;
  const pageWidth = pageType[pageSize][pageOrientation].width;
  const setSize = pageWidth < tableWidth ? (pageWidth / tableWidth) * defaultFontSize : 10;
  // console.log(
  //   elementId,
  //   'offsetWidth: ' + tableElement.offsetWidth,
  //   'scrollWidth: ' + tableElement.scrollWidth,
  //   'setSize:' + setSize,
  //   'pageWidth: ' + pageWidth,
  //   pageSize,
  //   pageOrientation,
  //   tableWidth
  // );
  defaultStyles.table.fontSize = setSize;
  const Doc = htmlToPdfMake(tableElement.outerHTML, {
    tableAutoSize: true,
    defaultStyles
  });
  Doc[0].table.widths = Doc[0].table.widths.map((i) => {
    // const s = +(pageWidth / tableWidth);
    // const w = (pageWidth / Doc[0].table?.widths?.length) * s + 5;
    // console.log(Doc[0].table?.widths?.length, s, w);
    return 100 / Doc[0].table?.widths?.length + '%';
  });
  // console.log('🚀 ~ file: utils.js ~ line 845 ~ Doc ~ Doc', Doc);
  const tablesBody = Doc.map((item) => {
    return item.table.body;
  });

  let tableWithoutPreIndex = tablesBody;
  if (preIndex) {
    tableWithoutPreIndex = tableWithoutPreIndex.map((table) => {
      return table.map((row) => {
        return row.slice(preIndex);
      });
    });
  }

  const newTableDataBody = tableWithoutPreIndex.map((table) => {
    const indexFilterRow = table.findIndex((row) => isFilterRow(row));
    let tableRemoveFilterRow = [...table];
    if (indexFilterRow > -1) {
      tableRemoveFilterRow = [...table.slice(0, indexFilterRow), ...table.slice(indexFilterRow + 1)];
    }
    return tableRemoveFilterRow;
  });

  const newTableDateStyleSummary = newTableDataBody.map((table) => {
    const tableStyle = table.map((row) => {
      if (row[0] && row[0]?.style && row[0].style.includes('row-header')) {
        return row.map((cell) => ({
          ...cell,
          ...defaultStyles['row-header']
        }));
      }
      return row;
    });
    return tableStyle;
  });

  const DocFiltered = Doc.map((item, index) => {
    // console.log(item.table.widths);
    return {
      ...item,
      table: {
        ...item.table,
        body: newTableDateStyleSummary[index],
        widths: item.table.widths.slice(preIndex)
      }
    };
  });
  if (isEmptyArray(DocFiltered)) return [];
  const tableValueMapping = [...DocFiltered];
  const results = tableValueMapping.map((el, i) => {
    const widths = el.table.widths;
    // console.log(widths);
    let widthsColumns = [];
    if (setSize >= 10) {
      for (let j = 0; j < widths.length; j++) {
        let width = 80 / (Doc[0].table?.widths?.length - 1) + '%';
        if (j === 0) {
          width = 20;
        }
        if (j > widths.length / 2) {
          width = size;
        }
        widthsColumns.push(width);
      }
    }
    return {
      ...el,
      table: {
        ...el.table,
        // widths: widthsColumns.length > 0 ? widthsColumns : el.table.widths
        widths
      }
    };
  });
  return results;
};

const isFilterRow = (row) => {
  if (row[0].nodeName !== 'TH') return false;
  const result = row.some((cell) => cell.nodeName === 'TH' && typeof cell.text !== 'string');
  return result;
};

export function getAddressCalculateNorm(route, type) {
  //   let startingPoint = null;
  //   let destinationPoint = null;
  const startingPointId = route?.StartingPointId ?? null;
  const destinationId = route?.DestinationId ?? null;
  //   const startingPointRelatedAddressId =
  //     route?.StartingPointRelatedAddressId ?? route?.StartingPoint?.RelatedAddressId ?? startingPointId;
  //   const destinationRelatedAddressId =
  //     route?.DestinationRelatedAddressId ?? route?.Destination?.RelatedAddressId ?? destinationId;

  //   if (type === CALCULATE_BY_ROOT_ADDRESS) {
  //     startingPoint = getValue(startingPointId);
  //     destinationPoint = getValue(destinationId);
  //   } else if (type === CALCULATE_BY_TRANSPORT_COST || type === CALCULATE_FROM_DETAIL_TO_EXTEND_ADDRESS) {
  //     startingPoint = getValue(startingPointRelatedAddressId);
  //     destinationPoint = getValue(destinationId);
  //   } else {
  //     startingPoint = getValue(startingPointRelatedAddressId);
  //     destinationPoint = getValue(destinationRelatedAddressId);
  //   }
  const result = { startingPoint: getValue(startingPointId), destinationPoint: getValue(destinationId) };
  return result;
}

export const processImageUrl = (urls, callback) => {
  if (!urls || !urls.length) {
    callback({
      original: '/assets/img/empty.png',
      // thumbnail: '/assets/img/empty.png',
      originalClass: 'empty-data-gallery',
      index: 0
    });
  }

  const preUrrl = process.env.REACT_APP_HOST_URL + '/';
  const dataImageUrls = urls.map((item) => preUrrl + item);
  const contentType = 'image/png';
  dataImageUrls.forEach((url, index) => {
    Axios.get(url, {
      responseType: 'arraybuffer'
    })
      .then((response) => {
        const dataBase64 = Buffer.from(response.data, 'binary').toString('base64');
        const dataUrrl = `data:${contentType};base64,${dataBase64}`;
        const image = {
          original: dataUrrl,
          thumbnail: dataUrrl,
          originalClass: 'image-original-gallery',
          index
        };
        callback(image);
      })
      .catch((err) => {
        // update when fetch data fail
        const image = {
          original: '/assets/img/no-image.png',
          thumbnail: '/assets/img/no-image.png',
          originalClass: 'image-original-gallery',
          index
        };
        callback(image);
      });
  });
};

export const toBase64 = (file) =>
  /* 2021/08/03 HuongPT convert a file to base64 */
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const convertFilesToBase64 = (files, callback) => {
  /* 2021/08/03 HuongPT convert multiple file to base64 */
  return Promise.allSettled(
    files.map((file) => {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function () {
          var binaryData = this.result;
          var base64String = window.btoa(binaryData);
          resolve(base64String);
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.readAsBinaryString(file);
      });
    })
  );
};

export function mapRouteWithScreenName(route) {
  if (!route) return;
  let routeMapping = { ...route };
  let screenName = '';
  const findRoute = SCREEN_NAME_WITH_ROUTE.find((el) => {
    return el.path === route.path;
  });
  if (findRoute) {
    screenName = findRoute.screenName;
  }
  routeMapping.screenName = screenName;
  return routeMapping;
}

export const getFileByUrlAndConvertToBase64 = async (url, callback) => {
  // return Axios.get(url, {
  //   responseType: 'arraybuffer'
  // }).then((response) => (img = Buffer.from(response.data, 'binary').toString('base64')));
  let image = await Axios.get(url, { responseType: 'arraybuffer' });
  let raw = Buffer.from(image.data).toString('base64');
  const dataUrl = 'data:' + image.headers['content-type'] + ';base64,' + raw;
  if (callback) {
    callback(dataUrl);
  }

  return dataUrl;
};
export function getDistancePromise(routes) {
  return Promise.all(
    routes.map((route, index) => {
      const startingPointId = getValue(route.StartingPointId) || undefined;
      const destinationId = getValue(route.DestinationId) || undefined;
      const params = { startingPointId, destinationId };
      return httpClient.get(distanceUrl, { params }).then((res) => {
        // console.log('process get distance response START....');
        if (res.data.data) {
          routes[index].Distance = res.data.data.Distance;
        } else {
          routes[index].Distance = 0;
        }
        // console.log('process get distance response END', routes);
        return routes[index];
      });
    })
  );
}

export function getRelatedAddressId(address) {
  if (isNull(address)) return null;
  const relatedAdd = address?.RelatedAddressId ?? null;
  return relatedAdd;
}

export function checkStatusPromise(res) {
  const status = res.map((el) => {
    return el.status;
  });
  let isError = false;
  let errors = [];
  const isHasErr = status.includes('rejected');
  if (isHasErr) {
    isError = true;
    status.forEach((status, i) => {
      if (status === 'rejected') {
        errors.push(res[i].reason);
      }
    });
  }
  const results = { isHasError: isError, errors: errors };
  return results;
}

export function renderSignDownPaymentOrder() {
  return {
    margin: [0, 30, 0, 50],
    style: 'sign',
    columns: [
      {
        width: '25%',
        text: 'Người lập phiếu',
        alignment: 'center'
      },
      {
        width: '25%',
        text: 'Phòng vận tải',
        alignment: 'center'
      },
      {
        width: '25%',
        text: 'Kế toán',
        alignment: 'center'
      },
      {
        width: '25%',
        text: 'Giám đốc',
        alignment: 'center'
      }
    ],
    columnGap: 10
  };
}

export const getTimeString = (time) => {
  if (!time) return null;
  return moment(time).format('HH:mm');
};

export const initDateStringForInput = (time, initDefault = true) => {
  const now = moment().format('YYYY-MM-DD');
  if (!time) {
    if (initDefault) {
      return now;
    }
    return null;
  }
  return moment(time).format('YYYY-MM-DD');
};

export const calculateDiffTwoDate = (fromDate, toDate) => {
  if (!fromDate || !toDate) return null;
  const duration = moment.duration(moment(toDate).diff(moment(fromDate)));
  return duration;
};

function getAddressNameInPoint(address, isGetRelatedAdd) {
  if (!address) return null;
  const parent = isGetRelatedAdd ? 'RelatedAddress' : 'Address';
  const parentAddress = 'Address';
  return address?.[parent] ? address?.[parent] : address?.[parentAddress] ?? null;
}

export function mapAddressNameWithPointType(adds, type) {
  return adds[type] ?? null;
}

export function detectPointsOnRoutes(routes, plan, isGetRelatedAdd) {
  const { consignmentGoodTypeId } = plan;
  const isInputGoodType = CONSIGNMENT_TYPE_INPUT_IDS.includes(consignmentGoodTypeId);

  if (isNullOrEmpty(routes)) return [];
  const routesNumber = routes?.length ?? 0;
  const getFieldByGoodType = isInputGoodType ? 'Destination' : 'StartingPoint';
  const routesLoad = routes.filter((el) => isTrue(el.IsLoad));
  const theFirstPoint = getAddressNameInPoint(routes[0]?.StartingPoint, isGetRelatedAdd);
  //   const theFirstPointLoad = getAddressNameInPoint(routesLoad[0]?.StartingPoint ?? null, isGetRelatedAdd);
  const theLastPoint = getAddressNameInPoint(routes[routesNumber - 1]?.Destination ?? null, isGetRelatedAdd);
  const destinationPointLoads = routesLoad.map((el) =>
    getAddressNameInPoint(el?.[getFieldByGoodType] ?? null, isGetRelatedAdd)
  );
  const deliveryPoints = destinationPointLoads?.length > 0 ? unique(destinationPointLoads).join(',') : null;
  //   const pickContainerPoint = isInputGoodType ? theLastPoint : theFirstPoint;
  //   const packingPoint = theFirstPointLoad;
  //   const points = [packingPoint, deliveryPoints, pickContainerPoint];
  // Sort : Lay hang/vo => Dong/Ha hang => Ha Vo/ Hang
  const points = [theFirstPoint, deliveryPoints, theLastPoint];
  return points;
}

export const getRoute = (routes, plan, isGetRelatedAdd) => {
  const { consignmentGoodTypeId } = plan;
  const resultRoutes = detectPointsOnRoutes(routes, plan, isGetRelatedAdd);
  if (isNullOrEmpty(resultRoutes)) return '';
  const isInputGoodType = CONSIGNMENT_TYPE_INPUT_IDS.includes(consignmentGoodTypeId);
  if (isInputGoodType) {
    return `${resultRoutes[0] ?? ''} - ${resultRoutes[1] ?? ''}`;
  } else {
    return `${resultRoutes[1] ?? ''} - ${resultRoutes[2] ?? ''}`;
  }
};

export const getStartingPoint = (routes, plan, isGetRelatedAdd) => {
  const { consignmentGoodTypeId } = plan;
  const resultRoutes = detectPointsOnRoutes(routes, plan, isGetRelatedAdd);
  if (isNullOrEmpty(resultRoutes)) return '';
  const isInputGoodType = CONSIGNMENT_TYPE_INPUT_IDS.includes(consignmentGoodTypeId);
  if (isInputGoodType) {
    return resultRoutes[0] ?? '';
  } else {
    return resultRoutes[1] ?? '';
  }
};

export const getDestination = (routes, plan, isGetRelatedAdd) => {
  const { consignmentGoodTypeId } = plan;
  const resultRoutes = detectPointsOnRoutes(routes, plan, isGetRelatedAdd);
  if (isNullOrEmpty(resultRoutes)) return '';
  const isInputGoodType = CONSIGNMENT_TYPE_INPUT_IDS.includes(consignmentGoodTypeId);
  if (isInputGoodType) {
    return resultRoutes[1] ?? '';
  } else {
    return resultRoutes[2] ?? '';
  }
};

export const groupByLodash = (arr, fieldName) => {
  const groups = _.chain(arr)
    // Group the elements of Array based on `color` property
    .groupBy(fieldName)
    // .groupBy('DriverName')
    // `key` is group's name (color), `value` is the array of objects
    .map((value, key) => ({ [fieldName]: key, groupData: value }))
    .value();
  return groups;
};

//lọc thành phần trùng trong mảng
export const unique = (arr) => {
  return Array.from(new Set(arr)); //
};

export function getPointsObjOnRoutes(routes, plan) {
  const { consignmentGoodTypeId } = plan;
  const isInputGoodType = CONSIGNMENT_TYPE_INPUT_IDS.includes(consignmentGoodTypeId);

  if (isNullOrEmpty(routes)) return [];
  const routesNumber = routes?.length ?? 0;
  const getFieldByGoodType = isInputGoodType ? 'Destination' : 'StartingPoint';
  const routesLoad = routes.filter((el) => isTrue(el.IsLoad));
  const theFirstPoint = routes[0]?.StartingPoint ?? null;
  //   const theFirstPointLoad = getAddressNameInPoint(routesLoad[0]?.StartingPoint ?? null, isGetRelatedAdd);
  const theLastPoint = routes[routesNumber - 1]?.Destination ?? null;
  const destinationPointLoads = routesLoad.map((el) => el?.[getFieldByGoodType] ?? null);
  const deliveryPoints = destinationPointLoads?.length > 0 ? destinationPointLoads[0] : null;
  //   const pickContainerPoint = isInputGoodType ? theLastPoint : theFirstPoint;
  //   const packingPoint = theFirstPointLoad;
  //   const points = [packingPoint, deliveryPoints, pickContainerPoint];
  // Sort : Lay hang/vo => Dong/Ha hang => Ha Vo/ Hang
  const points = [theFirstPoint, deliveryPoints, theLastPoint];
  return points;
}

export const getColorTruckingStatus = (truckingStatusId) => {
  if (!truckingStatusId) return SECONDARY_COLOR;
  return TRUCKING_STATUS_COLORS[truckingStatusId];
};

export const generateStyleTruckingStatus = (truckingStatusId) => {
  const styles = {
    color: 'white',
    backgroundColor: getColorTruckingStatus(truckingStatusId)
  };
  return styles;
};

export function decodeJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export const initDateTimeStringLocal = (time) => {
  if (!time) return null;
  const timeDefault = moment(time).format('YYYY-MM-DDTHH:mm');
  return timeDefault;
};

export const getContainerSerialNo = (plan) => {
  if (!plan) return null;
  const { ContSerialNo1 = null, ContSerialNo2 = null } = plan;
  const containers = [ContSerialNo1, ContSerialNo2];
  const mapValueContainers = [];
  containers.forEach((el) => {
    if (!isNull(el)) {
      const { ContSerialNo, ContCategory } = el;
      const container = ContSerialNo ?? 'CONT NO';
      const category = ContCategory?.Abbr ?? '?';
      const containerInfo = container + '' + ` (${category})`;
      mapValueContainers.push(containerInfo);
    }
  });
  return mapValueContainers.join(',');
};

export const isArrayEqual = (x, y) => {
  //   return _(x)
  //     .differenceWith(y, _.isEqual)
  //     .isEmpty();
  return _.differenceWith(x, y, _.isEqual);
};

export const isArrayEqualTest = (x, y) => {
  return JSON.stringify([...x]) === JSON.stringify([...y]);
};

export const removeSpecialCharacterUrl = (element, character = REPLACE_SPECIAL_CHARACTER_URL) => {
  return element.replace(/\//g, character);
};

export const replaceWithCharacter = (element, character = REPLACE_SPECIAL_CHARACTER_URL, overwrite = '/') => {
  return element.replaceAll(character, overwrite);
};

export const getCustomerOptionsLabel = (customer) => {
  const customerName = customer.CustomerName ?? '';
  const abbr = customer?.PreConsignmentCode ? ' ( ' + customer.PreConsignmentCode + ' )' : '';
  const label = customerName + abbr;
  return label;
};

export const getRouteNameFromListAddingFee = (routes) => {
  if (isNullOrEmpty(routes)) return '';
  let points = [];
  routes.forEach((el) => {
    const startPoint = el?.StartingPoint?.Address ?? null;
    const endPoint = el?.Destination?.Address ?? null;
    if (startPoint) points.push(startPoint);
    if (endPoint) points.push(endPoint);
  });
  const addressPoints = Array.from(new Set(points));
  return addressPoints.join(' => ');
};

export const getRouteNameFromListAddingFeeWithAllPoint = (routes) => {
  if (isNullOrEmpty(routes)) return '';
  let points = [];
  routes.forEach((el) => {
    const startPoint = el?.StartingPoint?.Address ?? 'Chưa XĐ';
    const endPoint = el?.Destination?.Address ?? 'Chưa XĐ';
    if (startPoint) points.push(startPoint);
    if (endPoint) points.push(endPoint);
  });
  return points.join(' => ');
};

export const getAddressLabelWithRelatedAdd = (add) => {
  if (isNullOrEmpty(add)) return 'Địa chỉ chưa XĐ';
  const address = add?.Address ?? 'CXĐ';
  const relatedAdd = add?.RelatedAddress ? `( ${add?.RelatedAddress} )` : '';
  return address + ' ' + relatedAdd;
};

export const getCustomerAndAbbrName = (customer) => {
  if (isNullOrEmpty(customer)) return '';
  const customerName = customer?.CustomerName ?? '-';
  const customerAbbr = customer?.PreConsignmentCode ?? null;
  return customerName + ' ' + `${customerAbbr ? `( ${customerAbbr} )` : ''}`;
};

export const getMathByFomule = (displayColumns = [], defaultFunc) => {
  if (displayColumns.length < 1) return [];
  const columns = extractDisplayColumnInfo(displayColumns);
  const listFunc = columns.reduce((total, item) => {
    if (item.Formula && item.Formula !== undefined && item.Formula.trim() !== '' && item.Formula !== '0')
      total.push({ func: defaultFunc ?? item.Formula.toLowerCase(), index: item.ColumnName });
    return total;
  }, []);
  return listFunc;
};

export const delayReq = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

export const customerFunctions = (props = {}, context = '') => {
  const x = new Function(`{${Object.keys(props).join(', ')}}`, `return ${context}`);
  const data = x({ ...props });
  return data;
};

export const onSearchByPagination = async (page, currentPage, callback) => {
  const scrollTableRef = document.querySelector('#scroll-virtualize');
  if (page < currentPage) {
    scrollTableRef.scrollTo({ behavior: 'smooth', top: 0 });
  }
  let i = page > currentPage ? currentPage + 1 : 1;
  while (i <= page) {
    //arrPromise.push(callback(i));
    await callback(i);
    i++;
  }
};

export const isVideoFile = (fileExtension) => {
  const types = ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv', 'webm', 'm4v', '3gp', 'ogv', 'mpg', 'mpeg', 'vob'];
  return types.includes(fileExtension?.trim());
};

export const isImage = (filePath) => {
  console.log('🚀 ~ isImage ~ filePath:', filePath);
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg'];
  return imageExtensions.some((ext) => filePath.endsWith(ext));
};
