import { CUSTOMER_OUTPUT } from 'actions/CustomerOutput';
import { GOODS_TYPE_ACTIONS } from 'actions/goodstype';
const INIT_STATE = {
  data: [],
  pagination: {},
  loading: false
};

const CustomerOutput = (state = INIT_STATE, action) => {
  switch (action.type) {
    // get all goods type
    case CUSTOMER_OUTPUT.GET_REVENUE_BY_CAR_REPORT_FULFILLED:
      return {
        ...state,
        data: action.payload.data.data,
        pagination: action.payload.data.pagination,
        loading: false
      };
    case CUSTOMER_OUTPUT.GET_REVENUE_BY_CAR_REPORT_PENDING:
      return {
        ...state,
        loading: true
      };
    case CUSTOMER_OUTPUT.GET_REVENUE_BY_CAR_REPORT_REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default CustomerOutput;
