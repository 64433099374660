import { SETTING_INFO_ACTIONS } from 'actions/settingInfo';
import { getModeSettings, mapValueToFieldReport } from 'commons/utils';
import { COMPANY_ID, SETTING_CONT_MODE_CHECK } from 'const/common';

const INIT_STATE = {
  dataSettings: [],
  dataItems: [],
  loading: false,
  loadingFetchAll: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  isSearch: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SETTING_INFO_ACTIONS.GET_SETTING_INFO_BY_GROUP_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case SETTING_INFO_ACTIONS.GET_SETTING_INFO_BY_GROUP_FULFILLED: {
      return {
        ...state,
        loading: false,
        dataItems: [...action.payload.data.data]
      };
    }
    case SETTING_INFO_ACTIONS.GET_SETTING_INFO_BY_GROUP_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    case SETTING_INFO_ACTIONS.GET_ALL_SETTING_INFO_PENDING: {
      return {
        ...state,
        loadingFetchAll: true,
        isShowMessage: false
      };
    }
    case SETTING_INFO_ACTIONS.GET_ALL_SETTING_INFO_FULFILLED: {
      const setting = getModeSettings(action?.payload?.data?.data, 2, SETTING_CONT_MODE_CHECK);
      // console.log('setting mode', setting);
      const dataSettings = action?.payload?.data?.data ?? [];
      localStorage.setItem(SETTING_CONT_MODE_CHECK, setting);
      const CompanyId = mapValueToFieldReport(dataSettings, 'Company_Id');
      localStorage.setItem(COMPANY_ID, CompanyId);
      return {
        ...state,
        loadingFetchAll: false,
        dataItems: [...action.payload.data.data],
        dataSettings
      };
    }
    case SETTING_INFO_ACTIONS.GET_ALL_SETTING_INFO_REJECTED: {
      return {
        ...state,
        loadingFetchAll: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !'
      };
    }

    // case SETTING_INFO_ACTIONS.SET_STORAGE_SETTING_CONT_MODE_CHECK: {
    //   debugger;
    //   const setting = getModeSettings(state.dataSettings, 2, SETTING_CONT_MODE_CHECK);
    //   console.log('setting mode', setting);
    //   localStorage.setItem(SETTING_CONT_MODE_CHECK, setting);
    //   break;
    // }

    default:
      return state;
  }
};
