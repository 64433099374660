import api from 'HttpClient';
import { getTruckingFeePerPage } from 'services/truckingfee';

// const type of function get data per page
export const FETCH_REPORTS = 'FETCH_REPORTS';
export const FETCH_REPORTS_PENDING = 'FETCH_REPORTS_PENDING';
export const FETCH_REPORTS_FULFILLED = 'FETCH_REPORTS_FULFILLED';
export const FETCH_REPORTS_REJECTED = 'FETCH_REPORTS_REJECTED';

export const FETCH_ADVANCE_REPORTS = 'FETCH_ADVANCE_REPORTS';
export const FETCH_ADVANCE_REPORTS_PENDING = 'FETCH_ADVANCE_REPORTS_PENDING';
export const FETCH_ADVANCE_REPORTS_FULFILLED = 'FETCH_ADVANCE_REPORTS_FULFILLED';
export const FETCH_ADVANCE_REPORTS_REJECTED = 'FETCH_ADVANCE_REPORTS_REJECTED';

export const RECALCULATE_ADVANCE_MONEY = 'RECALCULATE_ADVANCE_MONEY';
export const RECALCULATE_ADVANCE_MONEY_PENDING = 'RECALCULATE_ADVANCE_MONEY_PENDING';
export const RECALCULATE_ADVANCE_MONEY_FULFILLED = 'RECALCULATE_ADVANCE_MONEY_FULFILLED';
export const RECALCULATE_ADVANCE_MONEY_REJECTED = 'RECALCULATE_ADVANCE_MONEY_REJECTED';

export const CHANGE_AMOUNT_PAYMENT = 'CHANGE_AMOUNT_PAYMENT';
export const CHANGE_PRICE_PAYMENT = 'CHANGE_PRICE_PAYMENT';

export const CHANGE_PRICE_DRIVER_PAYMENT = 'CHANGE_PRICE_DRIVER_PAYMENT';

export const UPDATE_ORDER_NUM = 'UPDATE_ORDER_NUM';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const HANDEL_CHANGE_VALUE_INPUT_REPORT_FOLLOW_FIELD = 'HANDEL_CHANGE_VALUE_INPUT_REPORT_FOLLOW_FIELD';
export const fetchReports =
  (reportUrl, page, size, ...extraParams) =>
    (dispatch) => {
      let params = { page: page, pageSize: size };
      if (extraParams.length > 0) {
        const searchParams = extraParams[0];
        params = { ...params, ...searchParams };
      }
      return dispatch({
        type: FETCH_REPORTS,
        payload: api.get(`${reportUrl}`, {
          params: params
        })
      });
    };
export const fetchReportsAll = (payload) => (dispatch) => {
  const { reportUrl, ...params } = payload;
  return dispatch({
    payload: api.get(`${reportUrl}`, { params })
  });
};

export const getAntinStatisticCarReport = (params) => (dispatch) => {
  return dispatch({
    type: FETCH_REPORTS,
    payload: api.get(`RevenueByCarReport/AntinStatisticCarReport`, { params })
  });
};


export const getAntinStatisticCarReportAll = (params) => (dispatch) => {
  return dispatch({
    payload: api.get(`RevenueByCarReport/AntinStatisticCarReport`, { params })
  });
};

export const actionsUpdateFeeRate = (payload) => (dispatch) => {
  return dispatch({
    payload: api.post(`StandardBackend/updateFeeRate`, payload)
  });
};



export const fetchAdvanceReports = (reportUrl, extraParams) => (dispatch) => {
  return dispatch({
    type: FETCH_ADVANCE_REPORTS,
    payload: api.get(`${reportUrl}`, {
      params: extraParams
    })
  });
};

export const recalculateAdvanceMoney = (monthYear) => (dispatch) => {
  return dispatch({
    type: RECALCULATE_ADVANCE_MONEY,
    payload: api.put(`advancemoney/recalculateAdvanceMoneyByMonth?monthYear=${monthYear}`)
  });
};

export const fetchAdvanceReportsExcel = (extraParams) => (dispatch) => {
  return dispatch({
    payload: api.get(`${extraParams.reportUrl}`, {
      params: extraParams
    })
  });
};

export const RESET_REPORT = 'RESET_REPORT';
export const resetReport = () => (dispatch) => {
  return dispatch({
    type: RESET_REPORT
  });
};

export const SET_REPORT_SEARCH_PARAMS = 'SET_REPORT_SEARCH_PARAMS';
export const setReportSearchParams = (search) => (dispatch) => {
  return dispatch({
    type: SET_REPORT_SEARCH_PARAMS,
    payload: search
  });
};

export const actionSearchByFieldTruckingFeeReport = (params) => (dispatch) => {
  return dispatch({
    type: FETCH_REPORTS,
    payload: api.get(`/TruckingFee/SearchByField`, { params })
  });
};

export const actionGetTruckingFeePerPageReport = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: FETCH_REPORTS,
    payload: getTruckingFeePerPage(pageNo, pageSize)
  });
};

export const actionChangeAmountPayment = (value, item, reportsList) => (dispatch) => {
  return dispatch({
    type: CHANGE_AMOUNT_PAYMENT,
    payload: { value: value, item: item, reportsList: reportsList }
  });
};

export const actionChangePricePayment = (value, item, reportsList) => (dispatch) => {
  return dispatch({
    type: CHANGE_PRICE_PAYMENT,
    payload: { value: value, item: item, reportsList: reportsList }
  });
};

export const actionUpdateOrderNum = (value, id) => (dispatch) => {
  return dispatch({
    type: UPDATE_ORDER_NUM,
    payload: { value, id }
  });
};

export const actionUpdateNote = (value, id) => (dispatch) => {
  return dispatch({
    type: UPDATE_NOTE,
    payload: { value, id }
  });
};

export const HANDEL_CHANGE_VALUE_INPUT_REPORT = 'HANDEL_CHANGE_VALUE_INPUT_REPORT';
export const handelChangeValueInputReport = (value) => (dispatch) => {
  return dispatch({
    type: HANDEL_CHANGE_VALUE_INPUT_REPORT,
    payload: value
  });
};

export const actionChangePriceDriverPayment = (value, item, reportsList) => (dispatch) => {
  return dispatch({
    type: CHANGE_PRICE_DRIVER_PAYMENT,
    payload: { value: value, item: item, reportsList: reportsList }
  });
};

export const actionchangeRecordFollowField = (value, index, fieldName) => (dispatch) => {
  return dispatch({
    type: HANDEL_CHANGE_VALUE_INPUT_REPORT_FOLLOW_FIELD,
    payload: { value, index, fieldName }
  });
};

export const actionUpdateRevenueNotice = (payload) => (dispatch) => {
  return dispatch({
    payload: api.put(`/TruckingPlan/UpdateRevenueNotice`, payload)
  });
};
