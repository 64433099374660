import { ADD_DRIVER_PLAN_ACTIONS } from 'actions/adddriverplan';

const INIT_STATE = {
  trackingFee: { data: [] }
};

const addDriverPlanReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // get all trackin fee by add driver plan
    case ADD_DRIVER_PLAN_ACTIONS.GET_ALL_TRACCKING_FEE_OF_ADD_DRIVER_PLAN_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ADD_DRIVER_PLAN_ACTIONS.GET_ALL_TRACCKING_FEE_OF_ADD_DRIVER_PLAN_FULFILLED:
      let trackingFee = {
        data: action.payload.data.data
      };
      return {
        ...state,
        trackingFee: trackingFee,
        isLoading: false
      };

    case ADD_DRIVER_PLAN_ACTIONS.GET_ALL_TRACCKING_FEE_OF_ADD_DRIVER_PLAN_REJECTED:
      return {
        ...state,
        isLoading: false
      };

    // get add driver plan by driver plan id
    case ADD_DRIVER_PLAN_ACTIONS.GET_ADD_DRIVER_PLAN_BY_DRIVER_PLAN_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ADD_DRIVER_PLAN_ACTIONS.GET_ADD_DRIVER_PLAN_BY_DRIVER_PLAN_ID_FULFILLED:
      let addDriverList = {
        data: action.payload.data.data
      };
      return {
        ...state,
        addDriverList: addDriverList,
        isLoading: false,
        isMsg: false
      };

    case ADD_DRIVER_PLAN_ACTIONS.GET_ADD_DRIVER_PLAN_BY_DRIVER_PLAN_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi',
        typeMessage: 'error'
      };

    case ADD_DRIVER_PLAN_ACTIONS.GET_ALL_ADD_DRIVER_PLAN_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ADD_DRIVER_PLAN_ACTIONS.GET_ALL_ADD_DRIVER_PLAN_FULFILLED:
      let input = {
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        input: input,
        isLoading: false
      };

    case ADD_DRIVER_PLAN_ACTIONS.GET_ALL_ADD_DRIVER_PLAN_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi',
        typeMessage: 'error'
      };

    // get by id
    case ADD_DRIVER_PLAN_ACTIONS.GET_ADD_DRIVER_PLAN_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ADD_DRIVER_PLAN_ACTIONS.GET_ADD_DRIVER_PLAN_BY_ID_FULFILLED:
      let inputDetail = {
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        inputDetail: inputDetail,
        isLoading: false
      };

    case ADD_DRIVER_PLAN_ACTIONS.GET_ADD_DRIVER_PLAN_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi',
        typeMessage: 'error'
      };

    // create
    case ADD_DRIVER_PLAN_ACTIONS.CREATE_ADD_DRIVER_PLAN_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ADD_DRIVER_PLAN_ACTIONS.CREATE_ADD_DRIVER_PLAN_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Thêm mới dữ liệu thành công'
      };

    case ADD_DRIVER_PLAN_ACTIONS.CREATE_ADD_DRIVER_PLAN_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: 'Đã xảy ra lỗi'
      };

    // update
    case ADD_DRIVER_PLAN_ACTIONS.UPDATE_ADD_DRIVER_PLAN_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ADD_DRIVER_PLAN_ACTIONS.UPDATE_ADD_DRIVER_PLAN_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Thành công'
      };

    case ADD_DRIVER_PLAN_ACTIONS.UPDATE_ADD_DRIVER_PLAN_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: 'Đã xảy ra lỗi'
      };

    // delete
    case ADD_DRIVER_PLAN_ACTIONS.DELETE_ADD_DRIVER_PLAN_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ADD_DRIVER_PLAN_ACTIONS.DELETE_ADD_DRIVER_PLAN_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Thành công'
      };

    case ADD_DRIVER_PLAN_ACTIONS.DELETE_ADD_DRIVER_PLAN_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: 'Đã xảy ra lỗi'
      };

    case ADD_DRIVER_PLAN_ACTIONS.RESET_TRUCKING_FEES:
      return {
        ...state,
        trackingFee: INIT_STATE.trackingFee
      };

    default:
      return state;
  }
};

export default addDriverPlanReducer;
