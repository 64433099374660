import api from "HttpClient/index";

export const getAllPortDepots = () => {
    return api.get(`/PortDepotInfo/getAllDepot`);
};

export const getPortDepotsByCustomBranch = (id) => {
    return api.get(`/PortDepotInfo/getPortDeportByCategoryId?portCategoryId=${id}`);
};

export const getPortDepotIfoOfCustombrach = (id) => {
    return api.get(`/PortDepotinfo/getByCustombrachId/${id}`)
}