import api from 'HttpClient';

// const type of function get data per page
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_PENDING = 'FETCH_USERS_PENDING';
export const FETCH_USERS_FULFILLED = 'FETCH_USERS_FULFILLED';
export const FETCH_USERS_REJECTED = 'FETCH_USERS_REJECTED';
export const fetchUsers = (page, size) => (dispatch) => {
  return dispatch({
    type: FETCH_USERS,
    payload: api.get(`/User/paging/page?page=${page}&pagesize=${size}`)
    // payload: api.get(`/user`)
  });
};

// const type of function create data
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_PENDING = 'CREATE_USER_PENDING';
export const CREATE_USER_FULFILLED = 'CREATE_USER_FULFILLED';
export const CREATE_USER_REJECTED = 'CREATE_USER_REJECTED';
export const createUser = (payload) => (dispatch) => {
  const user = {
    ...payload
    // SysGroups: payload.SysGroups.map((group) => ({
    //   Id: group.value,
    //   GroupName: group.label
    // }))
  };
  return dispatch({
    type: CREATE_USER,
    payload: api.post(`/User/create`, payload)
  });
};

// const type of function update data
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING';
export const UPDATE_USER_FULFILLED = 'UPDATE_USER_FULFILLED';
export const UPDATE_USER_REJECTED = 'UPDATE_USER_REJECTED';
export const updateUser = (payload) => (dispatch) => {
  const user = {
    ...payload
    // SysGroups: payload.SysGroups.map((group) => ({
    //   Id: group.value,
    //   GroupName: group.label
    // }))
  };
  return dispatch({
    type: UPDATE_USER,
    payload: api.put(`/User/update`, payload)
  });
};

// const type of get deatail
export const GET_DETAIL_USER = 'GET_DETAIL_USER';
export const GET_DETAIL_USER_PENDING = 'GET_DETAIL_USER_PENDING';
export const GET_DETAIL_USER_FULFILLED = 'GET_DETAIL_USER_FULFILLED';
export const GET_DETAIL_USER_REJECTED = 'GET_DETAIL_USER_REJECTED';
export const getDetailUser = (id) => (dispatch) => {
  return dispatch({
    type: GET_DETAIL_USER,
    payload: api.get(`/user/getbyid/${id}`)
  });
};

// const type of delete
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_PENDING = 'DELETE_USER_PENDING';
export const DELETE_USER_FULFILLED = 'DELETE_USER_FULFILLED';
export const DELETE_USER_REJECTED = 'DELETE_USER_REJECTED';
export const deleteUser = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_USER,
    payload: api.delete(`/User/deleteById/${id}`)
  });
};

// const type of delete
export const SEARCH_USER = 'SEARCH_USER';
export const SEARCH_USER_PENDING = 'SEARCH_USER_PENDING';
export const SEARCH_USER_FULFILLED = 'SEARCH_USER_FULFILLED';
export const SEARCH_USER_REJECTED = 'SEARCH_USER_REJECTED';

export const GET_ALL_USER = 'GET_ALL_USER';
export const GET_ALL_USER_PENDING = 'GET_ALL_USER_PENDING';
export const GET_ALL_USER_FULFILLED = 'GET_ALL_USER_FULFILLED';
export const GET_ALL_USER_REJECTED = 'GET_ALL_USER_REJECTED';
export const actionGetAllUsers = () => (dispatch) => {
  return dispatch({
    type: GET_ALL_USER,
    payload: api.get(`/User/getAll`)
  });
};

export const SEARCH_USERNAME = 'SEARCH_USERNAME';
export const SEARCH_USERNAME_PENDING = 'SEARCH_USERNAME_PENDING';
export const SEARCH_USERNAME_FULFILLED = 'SEARCH_USERNAME_FULFILLED';
export const SEARCH_USERNAME_REJECTED = 'SEARCH_USERNAME_REJECTED';

export const searchUser = (params) => (dispatch) => {
  return dispatch({
    type: SEARCH_USERNAME,
    payload: api.get(`/User/search`, { params })
  });
};
