import httpClient from 'HttpClient';
import { deleteItem } from 'services/depositShell';
// define const actions
export const DEPOSIT_SHELL_ACTIONS = {
  GET_DEPOSIT_SHELL_PER_PAGE: 'GET_DEPOSIT_SHELL_PER_PAGE',
  GET_DEPOSIT_SHELL_PER_PAGE_PENDING: 'GET_DEPOSIT_SHELL_PER_PAGE_PENDING',
  GET_DEPOSIT_SHELL_PER_PAGE_FULFILLED: 'GET_DEPOSIT_SHELL_PER_PAGE_FULFILLED',
  GET_DEPOSIT_SHELL_PER_PAGE_REJECTED: 'GET_DEPOSIT_SHELL_PER_PAGE_REJECTED',

  GET_DEPOSIT_SHELL_BY_ID: 'GET_DEPOSIT_SHELL_BY_ID',
  GET_DEPOSIT_SHELL_BY_ID_PENDING: 'GET_DEPOSIT_SHELL_BY_ID_PENDING',
  GET_DEPOSIT_SHELL_BY_ID_FULFILLED: 'GET_DEPOSIT_SHELL_BY_ID_FULFILLED',
  GET_DEPOSIT_SHELL_BY_ID_REJECTED: 'GET_DEPOSIT_SHELL_BY_ID_REJECTED',

  CREATE_DEPOSIT_SHELL: 'CREATE_DEPOSIT_SHELL',
  CREATE_DEPOSIT_SHELL_PENDING: 'CREATE_DEPOSIT_SHELL_PENDING',
  CREATE_DEPOSIT_SHELL_FULFILLED: 'CREATE_DEPOSIT_SHELL_FULFILLED',
  CREATE_DEPOSIT_SHELL_REJECTED: 'CREATE_DEPOSIT_SHELL_REJECTED',

  UPDATE_DEPOSIT_SHELL: 'UPDATE_DEPOSIT_SHELL',
  UPDATE_DEPOSIT_SHELL_PENDING: 'UPDATE_DEPOSIT_SHELL_PENDING',
  UPDATE_DEPOSIT_SHELL_FULFILLED: 'UPDATE_DEPOSIT_SHELL_FULFILLED',
  UPDATE_DEPOSIT_SHELL_REJECTED: 'UPDATE_DEPOSIT_SHELL_REJECTED',

  DELETE_DEPOSIT_SHELL: 'DELETE_DEPOSIT_SHELL',
  DELETE_DEPOSIT_SHELL_PENDING: 'DELETE_DEPOSIT_SHELL_PENDING',
  DELETE_DEPOSIT_SHELL_FULFILLED: 'DELETE_DEPOSIT_SHELL_FULFILLED',
  DELETE_DEPOSIT_SHELL_REJECTED: 'DELETE_DEPOSIT_SHELL_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  GET_SHIPPING_LINE: 'GET_SHIPPING_LINE',
  GET_SHIPPING_LINE_FULFILLED: 'GET_SHIPPING_LINE_FULFILLED',

  GET_CONT_CATEGORY: 'GET_CONT_CATEGORY',
  GET_CONT_CATEGORY_FULFILLED: 'GET_CONT_CATEGORY_FULFILLED',
  GET_GOOD_CATEGORY: 'GET_GOOD_CATEGORY',
  GET_GOOD_CATEGORY_PENDING: 'GET_GOOD_CATEGORY_PENDING',
  GET_GOOD_CATEGORY_FULFILLED: 'GET_GOOD_CATEGORY_FULFILLED',
  GET_GOOD_CATEGORY_REJECTED: 'GET_GOOD_CATEGORY_REJECTED'
};

// define actions
export const actionGetDepositShellPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: DEPOSIT_SHELL_ACTIONS.GET_DEPOSIT_SHELL_PER_PAGE,
    payload: httpClient.get(`ContainerDepositInfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`)
  });
};
export const actionGetDepositShellPerPageAll = (params) => (dispatch) => {
  return dispatch({
    // type: DEPOSIT_SHELL_ACTIONS.GET_DEPOSIT_SHELL_PER_PAGE,
    payload: httpClient.get(`ContainerDepositInfo/paging/page`, { params })
  });
};

export const actionGetDepositShellByID = (id) => (dispatch) => {
  return dispatch({
    type: DEPOSIT_SHELL_ACTIONS.GET_DEPOSIT_SHELL_BY_ID,
    payload: httpClient.get(`/ContainerDepositInfo/getById/${id}`)
  });
};

export const actionCreateDepositShell = (payload) => (dispatch) => {
  return dispatch({
    type: DEPOSIT_SHELL_ACTIONS.CREATE_DEPOSIT_SHELL,
    payload: httpClient.post(`/ContainerDepositInfo/create`, payload)
  });
};

export const actionUpdateDepositShell = (payload) => (dispatch) => {
  return dispatch({
    type: DEPOSIT_SHELL_ACTIONS.UPDATE_DEPOSIT_SHELL,
    payload: httpClient.put(`/ContainerDepositInfo/update`, payload)
  });
};

export const actionDeleteDepositShell = (id) => (dispatch) => {
  return dispatch({
    type: DEPOSIT_SHELL_ACTIONS.DELETE_DEPOSIT_SHELL,
    payload: deleteItem(id)
  });
};

export const getShippingLine = () => (dispatch) => {
  return dispatch({
    type: DEPOSIT_SHELL_ACTIONS.GET_SHIPPING_LINE,
    payload: httpClient.get(`/shippinglineinfo/getAll`)
  });
};

export const getContCategory = () => (dispatch) => {
  return dispatch({
    type: DEPOSIT_SHELL_ACTIONS.GET_CONT_CATEGORY,
    payload: httpClient.get(`/Container/getAll`)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const actionSearchDepositShell = (value, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: DEPOSIT_SHELL_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/ContainerDepositInfo/search?value=${value}&Page=${pageNo}&PageSize=${pageSize}`)
  });
};
export const actionSearchDepositShellAll = (params) => (dispatch) => {
  return dispatch({
    // type: DEPOSIT_SHELL_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/ContainerDepositInfo/search`, { params })
  });
};

//get good category

export const actionGetGoodCategory = () => (dispatch) => {
  return dispatch({
    type: DEPOSIT_SHELL_ACTIONS.GET_GOOD_CATEGORY,
    payload: httpClient.get(`/GoodsCategoryInfo/getAll`)
  });
};
