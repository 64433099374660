import axios from 'axios';
import httpClient from 'HttpClient';
// define const actions
export const NORM_SETTING_ACTIONS = {
  GET_DATA_NORM_SETTING: 'GET_DATA_NORM_SETTING',
  GET_DATA_NORM_SETTING_PENDING: 'GET_DATA_NORM_SETTING_PENDING',
  GET_DATA_NORM_SETTING_FULFILLED: 'GET_DATA_NORM_SETTING_FULFILLED',
  GET_DATA_NORM_SETTING_REJECTED: 'GET_DATA_NORM_SETTING_REJECTED',

  UPDATE_DATA_NORM_SETTING: 'UPDATE_DATA_NORM_SETTING',
  UPDATE_DATA_NORM_SETTING_PENDING: 'UPDATE_DATA_NORM_SETTING_PENDING',
  UPDATE_DATA_NORM_SETTING_FULFILLED: 'UPDATE_DATA_NORM_SETTING_FULFILLED',
  UPDATE_DATA_NORM_SETTING_REJECTED: 'UPDATE_DATA_NORM_SETTING_REJECTED',

  CHANGE_FIELD: 'CHANGE_FIELD',
  CHANGE_VALUES_INPUT: 'CHANGE_VALUES_INPUT',
  CHANGE_KEY_CACULATOR: 'CHANGE_KEY_CACULATOR',
  REMOVE_ITEM: 'REMOVE_ITEM',
  CHANGE_TYPE_INPUT: 'CHANGE_TYPE_INPUT',
  REMOVE_TYPE_INPUT: 'REMOVE_TYPE_INPUT',
  CHANGE_NOTE_NORM: 'CHANGE_NOTE_NORM',
  CHANGE_INPUT_NORM: 'CHANGE_INPUT_NORM',
  REMOVE_INPUT_NORM: 'REMOVE_INPUT_NORM',

  DELETE_DATA_FOMULAS: 'DELETE_DATA_FOMULAS',
  DELETE_DATA_FOMULAS_PENDING: 'DELETE_DATA_FOMULAS_PENDING',
  DELETE_DATA_FOMULAS_FULFILLED: 'DELETE_DATA_FOMULAS_FULFILLED',
  DELETE_DATA_FOMULAS_REJECTED: 'DELETE_DATA_FOMULAS_REJECTED'
};

const instance = axios.create({
  baseURL: 'http://192.168.2.101:8081',
  timeout: 1000
});

// define actions
export const getDataNormSetting = (screenName) => (dispatch) => {
  return dispatch({
    type: NORM_SETTING_ACTIONS.GET_DATA_NORM_SETTING,
    payload: httpClient.get(`/SettingFormula/getAll`)
  });
};

export const updateDataNormSetting = (data) => (dispatch) => {
  return dispatch({
    type: NORM_SETTING_ACTIONS.UPDATE_DATA_NORM_SETTING,
    payload: httpClient.put(`/SettingFormula/updateList`, data)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const changeFields = (data) => {
  return {
    type: NORM_SETTING_ACTIONS.CHANGE_FIELD,
    payload: data
  };
};

export const changeValueInput = (value) => {
  return {
    type: NORM_SETTING_ACTIONS.CHANGE_VALUES_INPUT,
    payload: value
  };
};

export const changeKeyCaculator = (value) => {
  return {
    type: NORM_SETTING_ACTIONS.CHANGE_KEY_CACULATOR,
    payload: value
  };
};

export const removeItem = (value) => {
  return {
    type: NORM_SETTING_ACTIONS.REMOVE_ITEM,
    payload: value
  };
};

export const changeTypeInput = (value) => {
  return {
    type: NORM_SETTING_ACTIONS.CHANGE_TYPE_INPUT,
    payload: value
  };
};

export const removeTypeInput = (value) => {
  return {
    type: NORM_SETTING_ACTIONS.REMOVE_TYPE_INPUT,
    payload: value
  };
};

export const changeNoteNorm = (value) => {
  return {
    type: NORM_SETTING_ACTIONS.CHANGE_NOTE_NORM,
    payload: value
  };
};

export const changeInputNorm = (value) => {
  return {
    type: NORM_SETTING_ACTIONS.CHANGE_INPUT_NORM,
    payload: value
  };
};

export const changeRemoveInputNorm = (value) => {
  return {
    type: NORM_SETTING_ACTIONS.REMOVE_INPUT_NORM,
    payload: value
  };
};

const checkDeleteUrl = (screen) => {
  let url = '';
  switch (screen) {
    case 'OilNorm':
      return (url = '/standardoilInfo/deleteAll');
    case 'TransportCost':
      return (url = '/ContractTransportFeeInfo/deleteAll');
    case 'RouteCost':
      return (url = '/travelfeeinfo/deleteAll');
    default:
      return (url = '');
  }
};

export const deleteDataFomulas = (screen) => {
  const url = checkDeleteUrl(screen);
  return {
    type: NORM_SETTING_ACTIONS.DELETE_DATA_FOMULAS,
    payload: httpClient.delete(url)
  };
};
