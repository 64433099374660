import React, { useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { updateDataBusineesReport } from 'actions/ReportBusinessResult';
import { formatNumberToReadableString } from 'commons/MathUtils';
import { numberOf } from 'commons/MathUtils';
import { checkValidateString,checkValueField } from 'commons/ValidateEditable';
import { TIME_SHOW_ALERT_MESS } from 'const/common';

import './style.css';

function InputTable(props) {
  const htmlDefault = props.value ? props.value.toString() || '' : '';
  useEffect(() => {
    setHtmlValue(htmlDefault);
  }, [props.value, htmlDefault]);
  const [error, setError] = useState(null);
  const [htmlValue, setHtmlValue] = useState(null);
  const handleValidate = (value, type, fieldName) => {
    switch (type) {
      case 'price':
        return checkValueField(value, 12, true);
      case 'number':
        if (fieldName === 'OverTime') {
          return checkValueField(value, 3, false);
        }
        return checkValueField(value, 6, false);
      case 'Note':
        return checkValidateString(value, 100, false);
      default:
        return null;
    }
  };
  const handleChange = (evt) => {
    let value = props.type === 'text' ? evt.target.value : numberOf(evt.target.value);
    let error = handleValidate(evt.target.value, props.type, props.fieldName);
    const valueString = props.type === 'price' ? formatNumberToReadableString(value) : value;
    setHtmlValue(valueString);
    setError(error);
  };
  const getDataPreSubmit = (belongTo) => {
    switch (belongTo) {
      case 'RevenuesTree':
        return {
          url: 'ReportRevenue/revenueUpdate',
          data: props.reports.RevenuesTree
        };
      case 'StatisticSalaries':
        return {
          url: 'ReportRevenue/totalSalaryUpdate',
          data: props.reports.StatisticSalaries.find((item) => item.DepartmentId === props.item.DepartmentId)
        };
      case 'ExpensesTree':
        return {
          url: 'ReportRevenue/expensesUpdate',
          data: props.reports.ExpensesTree[0].Childs
        };
      default:
        break;
    }
  };
  const handleSubmit = (e) => {
    setIsFocus(false);
    if (e.target.textContent === htmlDefault) return;
    if (error) return;
    const value = props.type === 'price' ? numberOf(e.target.textContent) : e.target.textContent;
    let payload = null;
    const fieldName = props.fieldName;
    const dataPreSubmit = getDataPreSubmit(props?.belongTo);
    const isArray = Array.isArray(dataPreSubmit.data);
    if (props.item?.Parent && isArray) {
      payload = dataPreSubmit.data.map((itemParent) => {
        if (itemParent.Childs && itemParent.Childs.length) {
          const childs = itemParent.Childs.map((child) => {
            if (props.item.Id === child.Id) {
              return {
                ...child,
                [fieldName]: value
              };
            }
            return child;
          });
          return {
            ...itemParent,
            Childs: childs
          };
        }
        return itemParent;
      });
    } else if (isArray) {
      payload = dataPreSubmit.data.map((itemPayload) => {
        if (itemPayload.Id !== props?.item?.Id) return itemPayload;
        let itemCopy = itemPayload;
        itemCopy[fieldName] = value;
        return itemCopy;
      });
    } else {
      const itemSource = props.item;
      payload = dataPreSubmit.data
        ? dataPreSubmit.data
        : {
            DepartmentId: itemSource.DepartmentId,
            Month: itemSource.Month,
            Year: itemSource.Year
          };
      payload[fieldName] = value;
    }
    props
      .updateDataBusineesReport(dataPreSubmit.url, payload)
      .then((res) => {
        toast.info('Cập nhật thành công', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: TIME_SHOW_ALERT_MESS
        });
      })
      .catch((err) => {
        toast.error('Cập nhật thất bại', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: TIME_SHOW_ALERT_MESS
        });
        setHtmlValue(htmlDefault);
      });
  };
  const heightDiv = !error ? '100%' : 'calc(100% - 40px)';
  const [isFocus, setIsFocus] = useState(false);
  const style = () => {
    let style = { height: heightDiv };
    if (isFocus) style.fontSize = 25;
    if (props.type === 'price') style.textAlign = 'right';
    return style;
  };
  return (
    <>
      <ContentEditable
        html={htmlValue}
        className="edit-content-custome"
        onChange={(e) => handleChange(e)}
        onBlur={(e) => handleSubmit(e)}
        style={style()}
        onFocus={() => setIsFocus(true)}
        disabled={props.disable}
      />
      <div>{error ? <span className="error">{error}</span> : null}</div>
    </>
  );
}
const mapStateToProps = (state) => ({
  ...state,
  reports: state.businessResultReportStore.reports
});

export default connect(mapStateToProps, {
  updateDataBusineesReport: updateDataBusineesReport
  // updateAddingTruckingFee: actionUpdateAddingTruckingFee,
})(InputTable);
