import { DRIVER_MANY_PLAN_ACTIONS } from 'actions/driverManyPlan';
import moment from 'moment';
const INIT_STATE = {
  listDataCont: [],
  listDataNotCont: [],
  listDataForProductOther: [],
  listCar: [],
  screenSettings: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  isCloseModal: false,
  isUpdateSubmit: true,
  statusDownPayment: {},
  extension: {},
  listContNotChange: [],
  listContUsed: [],
  dataContUsedReturn: [],
  listCustomers: []
};

const dataAddingProductOther = [
  {
    TractorTrailerId: '',
    FullContSerialNo1: '',
    FullContCategoryId1: '',
    FullContSerialNo2: '',
    FullContCategoryId2: '',
    ContLabel1: '',
    ContLabel2: '',
    DeliveryDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    listContNotInput: []
  }
];

const filterContUsedAfterReset = (arr, index) => {
  let result = arr.filter((el) => {
    return el.index !== index;
  });
  return result;
};

const checkAddRowCont = (obj) => {
  const lengthCont = obj.cont.length;
  if (lengthCont > 0) {
    if (lengthCont === 1 || lengthCont === 2 || (lengthCont === 1 && obj.cont[0].UnitId === 6)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DRIVER_MANY_PLAN_ACTIONS.CREATE_PLAN_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false
      };
    }
    case DRIVER_MANY_PLAN_ACTIONS.CREATE_PLAN_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: 'success',
        message: 'Thêm mới dữ liệu thành công !',
        isShowMessage: true,
        isCloseModal: true
      };
    }
    case DRIVER_MANY_PLAN_ACTIONS.CREATE_PLAN_REJECTED: {
      return {
        ...state,
        loading: false,
        message:
          action.payload.response.data.status === '400' ? action.payload.response.data.message : 'Tạo dữ liệu thất bại',
        isShowMessage: true,
        typeMessage: 'error',
        isCloseModal: false
      };
    }
    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }
    case DRIVER_MANY_PLAN_ACTIONS.GET_ALL_CAR_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case DRIVER_MANY_PLAN_ACTIONS.GET_ALL_CAR_FULFILLED: {
      return {
        ...state,
        loading: false,
        listCar: action.payload.data.data
      };
    }
    case DRIVER_MANY_PLAN_ACTIONS.GET_ALL_CAR_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy dữ liệu !'
      };
    }

    case DRIVER_MANY_PLAN_ACTIONS.GET_SCREEN_SETTING_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false
      };
    }
    case DRIVER_MANY_PLAN_ACTIONS.GET_SCREEN_SETTING_FULFILLED: {
      return {
        ...state,
        loading: false,
        screenSettings: action.payload.data.data
      };
    }
    case DRIVER_MANY_PLAN_ACTIONS.GET_SCREEN_SETTING_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy dữ liệu !'
      };
    }

    case DRIVER_MANY_PLAN_ACTIONS.RESET_CONT: {
      const data = [
        {
          car: '',
          cont: [],
          transportDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
          listContChange: []
        }
      ];
      return {
        ...state,
        listDataCont: data
      };
    }

    case DRIVER_MANY_PLAN_ACTIONS.GET_FIRST_DATA_TABLE_CONT: {
      const data = [
        {
          car: '',
          cont: [],
          transportDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
          listContChange: []
        }
      ];
      return {
        ...state,
        listDataCont: data
      };
    }
    case DRIVER_MANY_PLAN_ACTIONS.CHANGE_ITEM_CONT: {
      const dataAdding = [
        {
          car: '',
          cont: [],
          transportDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
          listContChange: [],
          useListContNotChange: true
        }
      ];
      const data = action.payload;
      const dataChanged = data.dataChanged !== null ? data.dataChanged : [];
      const contUsed = [...state.listContUsed];
      const dataContUsed = contUsed.concat(
        dataChanged.map((el) => {
          return {
            ...el,
            index: data.index
          };
        })
      );
      const dataContUsedAfterReset =
        dataChanged.length === 0
          ? filterContUsedAfterReset(dataContUsed, data.index)
          : dataChanged.length === 1
          ? filterContUsedAfterReset(dataContUsed, data.index).concat(
              dataChanged.map((el) => {
                return {
                  ...el,
                  index: data.index
                };
              })
            )
          : dataContUsed;

      const dataContAfterReset =
        dataChanged.length === 0
          ? state.listContUsed.filter((el) => {
              return el.index !== data.index;
            })
          : dataContUsedAfterReset;

      let dataSetContFilter = dataContAfterReset.reduce((unique, o) => {
        if (!unique.some((obj) => obj.label === o.label && obj.value === o.value)) {
          unique.push(o);
        }
        return unique;
      }, []);

      const updateData = state.listDataCont.map((el, i) => {
        return {
          ...el,
          cont: data.index === i ? dataChanged : el.cont,
          listContChange: data.index === i ? dataChanged : el.listContChange,
          useListContNotChange: data.index === 0 && dataChanged.length === 0 ? true : el.useListContNotChange
        };
      });
      //   console.log("updateData", updateData);
      const itemTheLastInArray = updateData[updateData.length - 1];
      //   console.log("itemTheLastInArray", itemTheLastInArray);
      const checkAddCont = checkAddRowCont(itemTheLastInArray);
      const checkAddRow =
        itemTheLastInArray.car !== '' &&
        checkAddCont &&
        itemTheLastInArray.transportDate !== '' &&
        data.index === updateData.length - 1
          ? true
          : false;
      return {
        ...state,
        listDataCont:
          checkAddRow && data.listContNotChange.length >= 1 && dataChanged.length !== 0
            ? updateData.concat(dataAdding)
            : updateData,
        dataContUsedReturn: dataSetContFilter,
        listContUsed: dataContUsedAfterReset
      };
    }
    case DRIVER_MANY_PLAN_ACTIONS.CHANGE_TRANSPORT_DATE: {
      const dataAdding = [
        {
          car: '',
          cont: [],
          transportDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
          listContChange: [],
          useListContNotChange: true
        }
      ];
      const dataUpdate = state.listDataCont.map((el, i) => {
        return {
          ...el,
          transportDate: action.payload.index === i ? action.payload.date : el.transportDate
        };
      });
      const itemTheLastInArray = state.listDataCont[state.listDataCont.length - 1];
      const checkAddRow =
        itemTheLastInArray.car !== '' &&
        itemTheLastInArray.cont.length > 0 &&
        dataUpdate[dataUpdate.length - 1].transportDate !== ''
          ? true
          : false;
      return {
        ...state,
        listDataCont:
          checkAddRow && action.payload.listContNotChange.length > 0 ? dataUpdate.concat(dataAdding) : dataUpdate
      };
    }
    case DRIVER_MANY_PLAN_ACTIONS.CHANGE_CAR: {
      const dataAdding = [
        {
          car: '',
          cont: [],
          transportDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
          listContChange: [],
          useListContNotChange: true
        }
      ];
      const dataUpdate = state.listDataCont.map((el, i) => {
        return {
          ...el,
          car: action.payload.index === i ? action.payload.value : el.car
        };
      });
      const itemTheLastInArray = state.listDataCont[state.listDataCont.length - 1];
      const checkAddRow =
        dataUpdate[dataUpdate.length - 1].car !== '' &&
        itemTheLastInArray.cont.length > 0 &&
        itemTheLastInArray.transportDate !== ''
          ? true
          : false;
      return {
        ...state,
        listDataCont:
          checkAddRow && action.payload.listContNotChange.length > 0 ? dataUpdate.concat(dataAdding) : dataUpdate
      };
    }

    //define for not cont
    case DRIVER_MANY_PLAN_ACTIONS.GET_FIRST_DATA_TABLE_NOT_CONT: {
      const data = [
        {
          TractorTrailerId: '',
          ContSerialNoId1: null,
          ContSerialNoId2: null,
          DeliveryDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
          TripNumbers: ''
        }
      ];
      return {
        ...state,
        listDataNotCont: data
      };
    }
    case DRIVER_MANY_PLAN_ACTIONS.CHANGE_ITEM_TRIP_NUMBER: {
      const dataAdding = [
        {
          TractorTrailerId: '',
          ContSerialNoId1: null,
          ContSerialNoId2: null,
          DeliveryDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
          TripNumbers: ''
        }
      ];
      const data = action.payload;

      const dataUpdate = state.listDataNotCont.map((el, i) => {
        return {
          ...el,
          TripNumbers: data.index === i ? data.value : el.TripNumbers
        };
      });

      const itemTheLastInArray = state.listDataNotCont[state.listDataNotCont.length - 1];
      const checkAddRow =
        itemTheLastInArray.TractorTrailerId !== '' &&
        itemTheLastInArray.DeliveryDate !== '' &&
        dataUpdate[dataUpdate.length - 1].TripNumbers !== ''
          ? true
          : false;

      return {
        ...state,
        listDataNotCont: checkAddRow ? dataUpdate.concat(dataAdding) : dataUpdate
      };
    }
    case DRIVER_MANY_PLAN_ACTIONS.CHANGE_TRANSPORT_DATE_NOT_CONT: {
      const dataAdding = [
        {
          TractorTrailerId: '',
          ContSerialNoId1: null,
          ContSerialNoId2: null,
          DeliveryDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
          TripNumbers: ''
        }
      ];
      const dataUpdate = state.listDataNotCont.map((el, i) => {
        return {
          ...el,
          DeliveryDate: action.payload.index === i ? action.payload.value : el.DeliveryDate
        };
      });
      const itemTheLastInArray = state.listDataNotCont[state.listDataNotCont.length - 1];
      const checkAddRow =
        itemTheLastInArray.TractorTrailerId !== '' &&
        itemTheLastInArray.TripNumbers !== '' &&
        dataUpdate[dataUpdate.length - 1].DeliveryDate !== ''
          ? true
          : false;
      return {
        ...state,
        listDataNotCont: checkAddRow && action.payload.date !== '' ? dataUpdate.concat(dataAdding) : dataUpdate
      };
    }
    case DRIVER_MANY_PLAN_ACTIONS.CHANGE_CAR_NOT_CONT: {
      const dataAdding = [
        {
          TractorTrailerId: '',
          ContSerialNoId1: null,
          ContSerialNoId2: null,
          DeliveryDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
          TripNumbers: ''
        }
      ];
      const dataUpdate = state.listDataNotCont.map((el, i) => {
        return {
          ...el,
          TractorTrailerId: action.payload.index === i ? action.payload.value : el.TractorTrailerId
        };
      });
      const itemTheLastInArray = state.listDataNotCont[state.listDataNotCont.length - 1];
      const checkAddRow =
        dataUpdate[dataUpdate.length - 1].TractorTrailerId !== '' &&
        itemTheLastInArray.TripNumbers !== '' &&
        itemTheLastInArray.DeliveryDate !== ''
          ? true
          : false;
      return {
        ...state,
        listDataNotCont: checkAddRow ? dataUpdate.concat(dataAdding) : dataUpdate
      };
    }

    //define for hang le cont
    case DRIVER_MANY_PLAN_ACTIONS.GET_FIRST_DATA_PRODUCT_OTHER: {
      const data = [
        {
          TractorTrailerId: '',
          FullContSerialNo1: '',
          FullContCategoryId1: '',
          FullContSerialNo2: '',
          FullContCategoryId2: '',
          DeliveryDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
          ContLabel1: '',
          ContLabel2: '',
          listContNotInput: []
        }
      ];
      return {
        ...state,
        listDataForProductOther: data
      };
    }

    case DRIVER_MANY_PLAN_ACTIONS.CHANGE_CAR_PRODUCT_OTHER: {
      const dataUpdate = state.listDataForProductOther.map((el, i) => {
        return {
          ...el,
          TractorTrailerId: action.payload.index === i ? action.payload.value : el.TractorTrailerId
        };
      });
      const itemTheLastInArray = state.listDataForProductOther[state.listDataForProductOther.length - 1];
      const checkAddRow =
        dataUpdate[dataUpdate.length - 1].TractorTrailerId !== '' &&
        itemTheLastInArray.FullContCategoryId1 !== '' &&
        itemTheLastInArray.DeliveryDate !== ''
          ? true
          : false;
      return {
        ...state,
        listDataForProductOther: checkAddRow ? dataUpdate.concat(dataAddingProductOther) : dataUpdate
      };
    }

    case DRIVER_MANY_PLAN_ACTIONS.CHANGE_TRANSPORT_DATE_PRODUCT_OTHER: {
      const dataUpdate = state.listDataForProductOther.map((el, i) => {
        return {
          ...el,
          DeliveryDate: action.payload.index === i ? action.payload.value : el.DeliveryDate
        };
      });
      const itemTheLastInArray = state.listDataForProductOther[state.listDataForProductOther.length - 1];
      const checkAddRow =
        itemTheLastInArray.TractorTrailerId !== '' &&
        itemTheLastInArray.FullContCategoryId1 !== '' &&
        dataUpdate[dataUpdate.length - 1].DeliveryDate !== ''
          ? true
          : false;
      return {
        ...state,
        listDataForProductOther:
          checkAddRow && action.payload.date !== '' ? dataUpdate.concat(dataAddingProductOther) : dataUpdate
      };
    }

    case DRIVER_MANY_PLAN_ACTIONS.CHANGE_INPUT_CONT_NO: {
      const data = action.payload;

      const dataUpdate = state.listDataForProductOther.map((el, i) => {
        return {
          ...el,
          ContLabel1: data.index === i ? data.value.ContLabel1 : el.ContLabel1,
          ContLabel2: data.index === i ? data.value.ContLabel2 : el.ContLabel2,
          FullContCategoryId1: data.index === i ? data.value.FullContCategoryId1 : el.FullContCategoryId1,
          FullContCategoryId2: data.index === i ? data.value.FullContCategoryId2 : el.FullContCategoryId2,
          FullContSerialNo1: data.index === i ? data.value.FullContSerialNo1 : el.FullContSerialNo1,
          FullContSerialNo2: data.index === i ? data.value.FullContSerialNo2 : el.FullContSerialNo2
        };
      });

      const itemTheLastInArray = state.listDataForProductOther[state.listDataForProductOther.length - 1];
      const checkAddRow =
        itemTheLastInArray.TractorTrailerId !== '' &&
        itemTheLastInArray.DeliveryDate !== '' &&
        dataUpdate[dataUpdate.length - 1].FullContCategoryId1 !== ''
          ? true
          : false;

      return {
        ...state,
        listDataForProductOther: checkAddRow ? dataUpdate.concat(dataAddingProductOther) : dataUpdate
      };
    }

    case DRIVER_MANY_PLAN_ACTIONS.CHANGE_CUSTOMERS: {
      const data = action.payload;
      return {
        ...state,
        listCustomers: data
      };
    }

    default:
      return state;
  }
};
