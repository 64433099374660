import { DELIVERY_DETAIL_REPORT_ACTIONS } from 'actions/report/deliveryDetailReport';

const INIT_STATE = {
  details: [],
  consiments: [],
  loading: false,
  typeMessage: '',
  message: '',
  isShowMessage: false,
  isReload: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DELIVERY_DETAIL_REPORT_ACTIONS.GET_DELIVERY_DETAIL_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isReload: false
      };
    }
    case DELIVERY_DETAIL_REPORT_ACTIONS.GET_DELIVERY_DETAIL_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        details: action.payload.data.data,
        isReload: false,
        isShowMessage: false
      };
    }
    case DELIVERY_DETAIL_REPORT_ACTIONS.GET_DELIVERY_DETAIL_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isReload: false
      };
    }

    case DELIVERY_DETAIL_REPORT_ACTIONS.GET_CONSIMENT_DELIVERY_DETAIL_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isReload: false
      };
    }
    case DELIVERY_DETAIL_REPORT_ACTIONS.GET_CONSIMENT_DELIVERY_DETAIL_FULFILLED: {
      return {
        ...state,
        loading: false,
        consiments: action.payload.data.data,
        isReload: false,
        isShowMessage: false
      };
    }
    case DELIVERY_DETAIL_REPORT_ACTIONS.GET_CONSIMENT_DELIVERY_DETAIL_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: 'error',
        message: 'Không thể lấy được dữ liệu !',
        isReload: false
      };
    }

    case 'UPDATE_SHOW_ALERT': {
      return {
        ...state,
        isShowMessage: false
      };
    }
    default:
      return state;
  }
};
