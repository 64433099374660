import { SAFE_LOAD_OF_SEMI_TRAILER_ACTIONS } from 'actions/safeLoadOfSemiTrailer';
import { DEFAULT_PAGE_SIZE } from 'const/common';

const INIT_STATE = {
    loading: false,
    data: [],
    pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE },
    isSearch: false,
    startDate : '',
    endDate : ''
};

const safeLoadOfSemiTrailerReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        // get routers per page
        case SAFE_LOAD_OF_SEMI_TRAILER_ACTIONS.GET_DATA_SAFE_LOAD_OF_SEMI_TRAILER_PENDING:
            return {
                ...state,
                isLoading: true,
                isSearch: true
            };

        case SAFE_LOAD_OF_SEMI_TRAILER_ACTIONS.GET_DATA_SAFE_LOAD_OF_SEMI_TRAILER_FULFILLED:
            return {
                ...state,
                data: action.payload.data.data,
                pagination: action.payload.data.pagination,
                isLoading: false,
                isSearch: false,
            };

        case SAFE_LOAD_OF_SEMI_TRAILER_ACTIONS.GET_DATA_SAFE_LOAD_OF_SEMI_TRAILER_REJECTED:
            return {
                ...state,
                isLoading: false,
                typeMessage: 'error',
                isMsg: true,
                msg: 'Đã xảy ra lỗi'
            };

        default: return state;
    }
};

export default safeLoadOfSemiTrailerReducer;
