import { TRANSPORT_COST_REALITY_ACTIONS } from "actions/transportCostReality";

const dataMessageExist = ["Dữ liệu đã tồn tại"];

const INIT_STATE = {
  listPerPage: [],
  listContCategory: [],
  listCustomer: [],
  listRouter: [],
  loading: false,
  typeMessage: "",
  message: "",
  isShowMessage: false,
  pagination: {},
  isCloseModal: false,
  isSearch: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TRANSPORT_COST_REALITY_ACTIONS.GET_TRANSPORT_COST_REALITY_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case TRANSPORT_COST_REALITY_ACTIONS.GET_TRANSPORT_COST_REALITY_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
        isCloseModal: false,
      };
    }
    case TRANSPORT_COST_REALITY_ACTIONS.GET_TRANSPORT_COST_REALITY_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        isCloseModal: false,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }

    case TRANSPORT_COST_REALITY_ACTIONS.CREATE_TRANSPORT_COST_REALITY_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
      };
    }
    case TRANSPORT_COST_REALITY_ACTIONS.CREATE_TRANSPORT_COST_REALITY_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: "success",
        message: "Thêm mới dữ liệu thành công !",
        isShowMessage: true,
        isCloseModal: true,
      };
    }
    case TRANSPORT_COST_REALITY_ACTIONS.CREATE_TRANSPORT_COST_REALITY_REJECTED:
      const messageError = action.payload.response.data.message + "";
      let check = dataMessageExist.includes(messageError);

      return {
        ...state,
        loading: false,
        message: check ? messageError : "Thêm dữ liệu thất bại",
        isShowMessage: true,
        typeMessage: "error",
        isCloseModal: false,
      };

    case TRANSPORT_COST_REALITY_ACTIONS.DELETE_TRANSPORT_COST_REALITY_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case TRANSPORT_COST_REALITY_ACTIONS.DELETE_TRANSPORT_COST_REALITY_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: "success",
        message: "Xóa dữ liệu thành công",
        isShowMessage: true,
      };
    }
    case TRANSPORT_COST_REALITY_ACTIONS.DELETE_TRANSPORT_COST_REALITY_REJECTED: {
      return {
        ...state,
        loading: false,
        message: "Xóa dữ liệu thất bại",
        isShowMessage: true,
        typeMessage: "error",
      };
    }

    case TRANSPORT_COST_REALITY_ACTIONS.UPDATE_TRANSPORT_COST_REALITY_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
        isCloseModal: false,
      };
    }
    case TRANSPORT_COST_REALITY_ACTIONS.UPDATE_TRANSPORT_COST_REALITY_FULFILLED: {
      return {
        ...state,
        loading: false,
        typeMessage: "success",
        message: "Cập nhật dữ liệu thành công",
        isShowMessage: true,
        isCloseModal: true,
      };
    }
    case TRANSPORT_COST_REALITY_ACTIONS.UPDATE_TRANSPORT_COST_REALITY_REJECTED:
      const messageErrorCreate = action.payload.response.data.message + "";
      let checkCreate = dataMessageExist.includes(messageErrorCreate);

      return {
        ...state,
        loading: false,
        message: checkCreate ? messageErrorCreate : "Cập nhật dữ liệu thất bại",
        isShowMessage: true,
        typeMessage: "error",
        isCloseModal: false,
      };

    case TRANSPORT_COST_REALITY_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case TRANSPORT_COST_REALITY_ACTIONS.SEARCH_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
      };
    }
    case TRANSPORT_COST_REALITY_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }

    case TRANSPORT_COST_REALITY_ACTIONS.GET_CONT_CATEGORY_FULFILLED: {
      return {
        ...state,
        listContCategory: action.payload.data.data,
      };
    }

    case TRANSPORT_COST_REALITY_ACTIONS.GET_CONT_CATEGORY_REJECTED: {
      return {
        ...state,
        // loading: false,
        message: "Đã có lỗi xảy ra",
        isShowMessage: true,
        typeMessage: "error",
      };
    }

    case TRANSPORT_COST_REALITY_ACTIONS.GET_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        // loading: false,
        listCustomer: action.payload.data.data,
      };
    }

    case TRANSPORT_COST_REALITY_ACTIONS.GET_CUSTOMERS_REJECTED: {
      return {
        ...state,
        // loading: false,
        message: "Đã có lỗi xảy ra",
        isShowMessage: true,
        typeMessage: "error",
      };
    }
    case TRANSPORT_COST_REALITY_ACTIONS.GET_ROUTES_FULFILLED: {
      return {
        ...state,
        // loading: false,
        listRoute: action.payload.data.data,
      };
    }

    case TRANSPORT_COST_REALITY_ACTIONS.GET_ROUTES_REJECTED: {
      return {
        ...state,
        // loading: false,
        message: "Đã có lỗi xảy ra",
        isShowMessage: true,
        typeMessage: "error",
      };
    }

    case "UPDATE_SHOW_ALERT": {
      return {
        ...state,
        isShowMessage: false,
      };
    }
    default:
      return state;
  }
};
