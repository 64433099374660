import { REVENUE_BY_CUSTOMERS_ACTIONS } from "actions/report/revenueByCustomer";

const INIT_STATE = {
  listPerPage: [],
  listCustomer: [],
  loading: false,
  typeMessage: "",
  message: "",
  isShowMessage: false,
  pagination: {},
  isCloseModal: false,
  isSearch: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REVENUE_BY_CUSTOMERS_ACTIONS.GET_REVENUE_BY_CUSTOMERS_PER_PAGE_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case REVENUE_BY_CUSTOMERS_ACTIONS.GET_REVENUE_BY_CUSTOMERS_PER_PAGE_FULFILLED: {
      return {
        ...state,
        loading: false,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
        isSearch: false,
      };
    }
    case REVENUE_BY_CUSTOMERS_ACTIONS.GET_REVENUE_BY_CUSTOMERS_PER_PAGE_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }

    case REVENUE_BY_CUSTOMERS_ACTIONS.GET_CUSTOMERS_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case REVENUE_BY_CUSTOMERS_ACTIONS.GET_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        loading: false,
        listCustomer: action.payload.data.data,
      };
    }
    case REVENUE_BY_CUSTOMERS_ACTIONS.GET_CUSTOMERS_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }

    case "UPDATE_SHOW_ALERT": {
      return {
        ...state,
        isShowMessage: false,
      };
    }
    case REVENUE_BY_CUSTOMERS_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        loading: true,
        isShowMessage: false,
      };
    }
    case REVENUE_BY_CUSTOMERS_ACTIONS.SEARCH_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        isSearch: true,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination,
      };
    }
    case REVENUE_BY_CUSTOMERS_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        isShowMessage: true,
        typeMessage: "error",
        message: "Không thể lấy được dữ liệu !",
      };
    }

    default:
      return state;
  }
};
