import httpClient from 'HttpClient';
import moment from 'moment';
// define const actions
export const WARE_HOUSE_REPORT_ACTIONS = {
  GET_WAREHOUSE_REPORT_PER_PAGE: 'GET_WAREHOUSE_REPORT_PER_PAGE',
  GET_WAREHOUSE_REPORT_PER_PAGE_PENDING: 'GET_WAREHOUSE_REPORT_PER_PAGE_PENDING',
  GET_WAREHOUSE_REPORT_PER_PAGE_FULFILLED: 'GET_WAREHOUSE_REPORT_PER_PAGE_FULFILLED',
  GET_WAREHOUSE_REPORT_PER_PAGE_REJECTED: 'GET_WAREHOUSE_REPORT_PER_PAGE_REJECTED',

  GET_ALL_REPAIR: 'GET_ALL_REPAIR',
  GET_ALL_REPAIR_PENDING: 'GET_ALL_REPAIR_PENDING',
  GET_ALL_REPAIR_FULFILLED: 'GET_ALL_REPAIR_FULFILLED',
  GET_ALL_REPAIR_REJECTED: 'GET_ALL_REPAIR_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  UPDATE_DATA: 'UPDATE_DATA',
  UPDATE_DATA_PENDING: 'UPDATE_DATA_PENDING',
  UPDATE_DATA_FULFILLED: 'UPDATE_DATA_FULFILLED',
  UPDATE_DATA_REJECTED: 'UPDATE_DATA_REJECTED',

  GET_ALL_REPORT_WAREHOUSE: 'GET_ALL_REPORT_WAREHOUSE',
  GET_ALL_REPORT_WAREHOUSE_PENDING: 'GET_ALL_REPORT_WAREHOUSE_PENDING',
  GET_ALL_REPORT_WAREHOUSE_FULFILLED: 'GET_ALL_REPORT_WAREHOUSE_FULFILLED',
  GET_ALL_REPORT_WAREHOUSE_REJECTED: 'GET_ALL_REPORT_WAREHOUSE_REJECTED',

  CALCULATE_REMAINING_AMOUNT_BY_MONTH: 'CALCULATE_REMAINING_AMOUNT_BY_MONTH',
  CALCULATE_REMAINING_AMOUNT_BY_MONTH_PENDING: 'CALCULATE_REMAINING_AMOUNT_BY_MONTH_PENDING',
  CALCULATE_REMAINING_AMOUNT_BY_MONTH_FULFILLED: 'CALCULATE_REMAINING_AMOUNT_BY_MONTH_FULFILLED',
  CALCULATE_REMAINING_AMOUNT_BY_MONTH_REJECTED: 'CALCULATE_REMAINING_AMOUNT_BY_MONTH_REJECTED'
};

// define actions
export const getDataPerPage = (id, time, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: WARE_HOUSE_REPORT_ACTIONS.GET_WAREHOUSE_REPORT_PER_PAGE,
    payload: httpClient.get(`/SparePartReport?spartPartId=${id}&monthYear=${time}&Page=${pageNo}&Pagesize=${pageSize}`)
  });
};

export const actionCalculateRemainingAmount = (month) => (dispatch) => {
  //   const monthYear = month ?? moment().format('YYYY-MM')
  return dispatch({
    type: WARE_HOUSE_REPORT_ACTIONS.CALCULATE_REMAINING_AMOUNT_BY_MONTH,
    payload: httpClient.post(`/SparePartReport/CalculateRemainingAmount?month=${month}`)
  });
};

export const getAllSparepartInfo = () => (dispatch) => {
  return dispatch({
    type: WARE_HOUSE_REPORT_ACTIONS.GET_ALL_REPAIR,
    payload: httpClient.get(`/sparepartinfo/getAll`)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const actionSearchData =
  (spartPartId, monthYear, Page, Pagesize, CustomerId, SparePartGroupId) => (dispatch) => {
    const params = {
      spartPartId,
      monthYear,
      Page,
      Pagesize,
      CustomerId,
      SparePartGroupId
    };
    return dispatch({
      type: WARE_HOUSE_REPORT_ACTIONS.SEARCH_DATA,
      payload: httpClient.get(`/SparePartReport`, { params })
    });
  };
export const actionSearchDataAll = (params) => (dispatch) => {
  return dispatch({
    // type: WARE_HOUSE_REPORT_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/SparePartReport`, { params })
  });
};

export const getAllData = (id, time) => (dispatch) => {
  return dispatch({
    type: WARE_HOUSE_REPORT_ACTIONS.GET_ALL_REPORT_WAREHOUSE,
    payload: httpClient.get(`/SparePartReport/getList?spartPartId=${id}&monthYear=${time}`)
  });
};

export const updateData = (payload) => (dispatch) => {
  return dispatch({
    type: WARE_HOUSE_REPORT_ACTIONS.UPDATE_DATA,
    payload: httpClient.put(`/SparePartReport/update`, payload)
  });
};
