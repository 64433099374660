import { PROFIT_BY_VEHICLE } from 'actions/ProfitByVehicle';

const INIT_STATE = {
  data: [],
  pagination: {},
  loading: false
};

const ProfitByVehicleReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PROFIT_BY_VEHICLE.GET_REVENUE_BY_CAR_REPORT_FULFILLED:
      return {
        ...state,
        data: action?.payload?.data?.data,
        pagination: action?.payload?.data?.pagination,
        loading: false
      };
    case PROFIT_BY_VEHICLE.GET_REVENUE_BY_CAR_REPORT_PENDING:
      return {
        ...state,
        loading: true
      };
    case PROFIT_BY_VEHICLE.GET_REVENUE_BY_CAR_REPORT_REJECTED:
      return {
        ...state,
        loading: false
      };

    case PROFIT_BY_VEHICLE.UPDATE_REVENUE_BY_CAR_REPORT_FULFILLED:
      const data = [...state.data];
      const meta = action.meta;
      const index = data.findIndex((item) => item.Id === meta.Id);
      if (index !== -1) {
        data[index] = { ...data[index], ...meta };
      }
      return {
        ...state,
        data: [...data]
      };
    default:
      return state;
  }
};

export default ProfitByVehicleReducer;
