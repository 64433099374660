import {
  SEARCH_CUSTOMER,
  SEARCH_CUSTOMER_FULFILLED,
  SEARCH_CUSTOMER_PENDING,
  SEARCH_CUSTOMER_REJECTED
} from 'actions/customerAction';
import httpClient from 'HttpClient';
import { call, put, takeLatest } from 'redux-saga/effects';
// import qs from "querystring";

// (TTS)LyLV 2020/04/08 Watcher START
export function* watchCustomerSearch() {
  yield takeLatest(SEARCH_CUSTOMER, customerSearch);
}
// (TTS)LyLV 2020/04/08 Watcher END

// (TTS)LyLV 2020/04/08 SAGA START
// saga worker sigin

export function* customerSearch(action) {
  // console.log("auth sagas customerSearch");
  yield put({ type: SEARCH_CUSTOMER_PENDING, payload: action.payload });
  try {
    const response = yield call(executeSearch, action);
    yield put({
      type: SEARCH_CUSTOMER_FULFILLED,
      payload: response
    });
  } catch (error) {
    // console.log("auth sagas customerSearch reject");
    yield put({ type: SEARCH_CUSTOMER_REJECTED, payload: error });
  }
}

async function executeSearch(action) {
  const params = {
    customerType: action.payload.customerType,
    CustomerName: action.payload.searchKey.customerName,
    PreConsignmentCode: action.payload.searchKey.PreConsignmentCode,
    Page: action.payload.page,
    PageSize: action.payload.pageSize
  };
  const response = await httpClient.get('/customerinfo/search', {
    params: params
  });
  return response;
}

// (TTS)LyLV 2020/04/08 SAGA END
