import {
  CREATE_CUSTOMER_FULFILLED,
  CREATE_CUSTOMER_PENDING,
  CREATE_CUSTOMER_REJECTED,
  DELETE_CUSTOMER_FULFILLED,
  DELETE_CUSTOMER_PENDING,
  DELETE_CUSTOMER_REJECTED,
  FETCH_ALL_CUSTOMERS_FULFILLED,
  FETCH_ALL_CUSTOMERS_PENDING,
  FETCH_ALL_CUSTOMERS_REJECTED,
  FETCH_CUSTOMERS_FULFILLED,
  FETCH_CUSTOMERS_PENDING,
  FETCH_CUSTOMERS_REJECTED,
  GET_ALL_CUSTOMER_BY_TYPE_ALL_FULFILLED,
  GET_ALL_CUSTOMER_BY_TYPE_ALL_PENDING,
  GET_ALL_CUSTOMER_BY_TYPE_ALL_REJECTED,
  GET_ALL_CUSTOMER_BY_TYPE_FULFILLED,
  GET_ALL_CUSTOMER_BY_TYPE_PENDING,
  GET_ALL_CUSTOMER_BY_TYPE_REJECTED,
  GET_ALL_CUSTOMER_FULFILLED,
  GET_ALL_CUSTOMER_PENDING,
  GET_ALL_CUSTOMER_REJECTED,
  GET_CONSIGNMENT_BY_CUSTOMER_ID_FULFILLED,
  GET_CONSIGNMENT_BY_CUSTOMER_ID_PENDING,
  GET_CONSIGNMENT_BY_CUSTOMER_ID_REJECTED,
  GET_CUSTOMER_TYPE_FULFILLED,
  GET_CUSTOMER_TYPE_PENDING,
  GET_CUSTOMER_TYPE_REJECTED,
  GET_DETAIL_CUSTOMER_FULFILLED,
  GET_DETAIL_CUSTOMER_PENDING,
  GET_DETAIL_CUSTOMER_REJECTED,
  GET_GOOD_CATEGORY_FULFILLED,
  GET_GOOD_CATEGORY_PENDING,
  GET_GOOD_CATEGORY_REJECTED,
  SEARCH_CUSTOMER_FULFILLED,
  SEARCH_CUSTOMER_PENDING,
  SEARCH_CUSTOMER_REJECTED,
  SET_CUSTOMER_SEARCH_PARAMS,
  UPDATE_CUSTOMER_FULFILLED,
  UPDATE_CUSTOMER_PENDING,
  UPDATE_CUSTOMER_REJECTED
} from 'actions/customerAction';
import { getErrorMessage } from 'commons/ReducerUtils';
import {
  DEFAULT_PAGE_SIZE,
  MSG_ADD_FAILURE,
  MSG_ADD_SUCCESS,
  MSG_DELETE_FAILURE,
  MSG_DELETE_SUCCESS,
  MSG_EDIT_FAILURE,
  MSG_EDIT_SUCCESS,
  MSG_GET_ALL_FAILURE,
  MSG_GET_DETAIL_FAILURE,
  MSG_PAGING_FAILURE,
  MSG_SEARCH_FAILURE,
  MSG_TYPE_ERROR,
  MSG_TYPE_SUCCESS
} from 'const/common';
const INIT_STATE = {
  customers: [],
  goods: { data: [] },
  allCustomers: [],
  pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
  loading: false,
  allCustomersByType: [],
  search: {},
  consignments: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // get data
    case FETCH_CUSTOMERS_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case FETCH_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        loading: false,
        customers: action.payload.data.data,
        pagination: action.payload.data.pagination ? action.payload.data.pagination : INIT_STATE.pagination,
        showMessage: false
      };
    }
    case FETCH_CUSTOMERS_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: getErrorMessage(action, MSG_PAGING_FAILURE),
        messageType: MSG_TYPE_ERROR
      };
    }
    case FETCH_ALL_CUSTOMERS_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case FETCH_ALL_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        loading: false,
        allCustomers: action.payload.data.data,
        showMessage: false
      };
    }
    case FETCH_ALL_CUSTOMERS_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: getErrorMessage(action, MSG_GET_ALL_FAILURE),
        messageType: MSG_TYPE_ERROR
      };
    }

    case SEARCH_CUSTOMER_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false,
        hasReceiptedSearchResult: false
      };
    }
    case SEARCH_CUSTOMER_FULFILLED: {
      return {
        ...state,
        loading: false,
        customers: action.payload.data.data,
        pagination: action.payload.data.pagination ? action.payload.data.pagination : INIT_STATE.pagination,
        showMessage: false,
        hasReceiptedSearchResult: true
      };
    }
    case SEARCH_CUSTOMER_REJECTED: {
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: getErrorMessage(action, MSG_SEARCH_FAILURE),
        messageType: MSG_TYPE_ERROR,
        hasReceiptedSearchResult: true
      };
    }

    // create data
    case CREATE_CUSTOMER_PENDING:
      return {
        ...state,
        loading: true
      };
    case CREATE_CUSTOMER_FULFILLED:
      return {
        ...state,
        isCreate: true,
        loading: false,
        showMessage: true,
        alertMessage: MSG_ADD_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case CREATE_CUSTOMER_REJECTED: {
      let alertMessage = getErrorMessage(action, MSG_ADD_FAILURE);
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }

    // update data
    case UPDATE_CUSTOMER_PENDING:
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    case UPDATE_CUSTOMER_FULFILLED:
      return {
        ...state,
        isUpdate: true,
        loading: false,
        showMessage: true,
        alertMessage: MSG_EDIT_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case UPDATE_CUSTOMER_REJECTED: {
      let alertMessage = getErrorMessage(action, MSG_EDIT_FAILURE);
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }

    // get detail
    case GET_DETAIL_CUSTOMER_PENDING:
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    case GET_DETAIL_CUSTOMER_FULFILLED:
      return {
        ...state,
        customersDetail: action.payload.data.data,
        loading: false,
        showMessage: false
      };
    case GET_DETAIL_CUSTOMER_REJECTED:
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: getErrorMessage(action, MSG_GET_DETAIL_FAILURE),
        messageType: MSG_TYPE_ERROR
      };

    // delete data
    case DELETE_CUSTOMER_PENDING:
      return {
        ...state,
        loading: true
      };
    case DELETE_CUSTOMER_FULFILLED:
      return {
        ...state,
        isDelete: true,
        loading: false,
        showMessage: true,
        alertMessage: MSG_DELETE_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case DELETE_CUSTOMER_REJECTED:
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: getErrorMessage(action, MSG_DELETE_FAILURE),
        messageType: MSG_TYPE_ERROR
      };

    // get all
    case GET_ALL_CUSTOMER_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case GET_ALL_CUSTOMER_FULFILLED: {
      let customers = {
        data: action.payload.data.data
      };
      return {
        ...state,
        customers: customers,
        isLoading: false
      };
    }

    case GET_ALL_CUSTOMER_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: getErrorMessage(action, MSG_GET_ALL_FAILURE)
      };

    // get all good
    case GET_GOOD_CATEGORY_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case GET_GOOD_CATEGORY_FULFILLED: {
      let goods = {
        data: action.payload.data.data
      };
      return {
        ...state,
        goods: goods,
        isLoading: false
      };
    }

    case GET_GOOD_CATEGORY_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: getErrorMessage(action, MSG_GET_ALL_FAILURE)
      };

    //get all Customer Type
    case GET_CUSTOMER_TYPE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case GET_CUSTOMER_TYPE_FULFILLED: {
      let customerType = {
        data: action.payload.data.data
      };
      return {
        ...state,
        customerType: customerType,
        isLoading: false
      };
    }

    case GET_CUSTOMER_TYPE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: getErrorMessage(action, MSG_GET_ALL_FAILURE)
      };

    //get all customer by type
    case GET_ALL_CUSTOMER_BY_TYPE_ALL_PENDING:
    case GET_ALL_CUSTOMER_BY_TYPE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case GET_ALL_CUSTOMER_BY_TYPE_ALL_FULFILLED:
    case GET_ALL_CUSTOMER_BY_TYPE_FULFILLED: {
      let allCustomersByType = {
        data: action.payload.data.data
      };
      return {
        ...state,
        allCustomersByType: allCustomersByType,
        isLoading: false
      };
    }

    case GET_ALL_CUSTOMER_BY_TYPE_ALL_REJECTED:
    case GET_ALL_CUSTOMER_BY_TYPE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: getErrorMessage(action, MSG_GET_ALL_FAILURE)
      };

    case SET_CUSTOMER_SEARCH_PARAMS: {
      const payload = action.payload;
      let newSearchState = {};
      Object.keys(payload).forEach((key) => {
        newSearchState[key] = payload[key];
      });
      return {
        ...state,
        search: {
          ...state.search,
          ...newSearchState
        }
      };
    }

    case GET_CONSIGNMENT_BY_CUSTOMER_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case GET_CONSIGNMENT_BY_CUSTOMER_ID_FULFILLED: {
      let consignments = action.payload.data.data;
      return {
        ...state,
        consignments: consignments,
        isLoading: false
      };
    }

    case GET_CONSIGNMENT_BY_CUSTOMER_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: getErrorMessage(action, MSG_GET_ALL_FAILURE)
      };

    default:
      return state;
  }
};
