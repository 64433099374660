import { ORDER_MANAGEMENT_ACTIONS } from 'actions/ordermanagement';
import { DEFAULT_PAGE_SIZE } from 'const/common';

const INIT_STATE = {
  input: {
    pagination: {
      pageSize: DEFAULT_PAGE_SIZE,
      pageNo: 1
    },
    data: []
  },
  customsCleanrances: {
    data: [],
    ConsignmentId: null
  },
  areaList: {}
};

const orderManagementReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_BY_CUSTOMS_CLEARANCE_COMPLETED_PENDING:
      return {
        ...state,
        isLoadingGetList: true
      };

    case ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_BY_CUSTOMS_CLEARANCE_COMPLETED_FULFILLED: {
      let input = {
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        input: input,
        isLoadingGetList: false
      };
    }

    case ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_BY_CUSTOMS_CLEARANCE_COMPLETED_REJECTED:
      return {
        ...state,
        isLoadingGetList: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    //
    case ORDER_MANAGEMENT_ACTIONS.GET_CUSTOMS_CLEARANCE_COMPLETED_BY_ORDER_MANAGEMENT_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ORDER_MANAGEMENT_ACTIONS.GET_CUSTOMS_CLEARANCE_COMPLETED_BY_ORDER_MANAGEMENT_ID_FULFILLED: {
      let customsClearances = {
        data: action.payload.data.data,
        isMsg: false,
        ConsignmentId: action.meta
      };
      return {
        ...state,
        customsCleanrances: customsClearances,
        isLoading: false
      };
    }

    case ORDER_MANAGEMENT_ACTIONS.GET_CUSTOMS_CLEARANCE_COMPLETED_BY_ORDER_MANAGEMENT_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // input
    case ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_INPUT_PER_PAGE_PENDING:
      return {
        ...state,
        isLoadingGetList: true
      };

    case ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_INPUT_PER_PAGE_FULFILLED: {
      let input = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        input: input,
        isLoadingGetList: false
      };
    }

    case ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_INPUT_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoadingGetList: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // search
    case ORDER_MANAGEMENT_ACTIONS.SEARCH_ORDER_MANAGEMENT_INPUT_PENDING:
      return {
        ...state,
        isLoadingGetList: true
      };

    case ORDER_MANAGEMENT_ACTIONS.SEARCH_ORDER_MANAGEMENT_INPUT_FULFILLED: {
      let input = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        input: input,
        isLoadingGetList: false
      };
    }

    case ORDER_MANAGEMENT_ACTIONS.SEARCH_ORDER_MANAGEMENT_INPUT_REJECTED:
      return {
        ...state,
        isLoadingGetList: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // get by id
    case ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_INPUT_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_INPUT_BY_ID_FULFILLED: {
      let inputDetail = {
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        inputDetail: inputDetail,
        isLoading: false
      };
    }

    case ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_INPUT_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };
    // end input

    // output
    case ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_OUTPUT_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_OUTPUT_PER_PAGE_FULFILLED: {
      let output = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        output: output,
        isLoading: false
      };
    }

    case ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_OUTPUT_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // search
    case ORDER_MANAGEMENT_ACTIONS.SEARCH_ORDER_MANAGEMENT_OUTPUT_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ORDER_MANAGEMENT_ACTIONS.SEARCH_ORDER_MANAGEMENT_OUTPUT_FULFILLED: {
      let output = {
        pagination: action.payload.data.pagination,
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        output: output,
        isLoading: false
      };
    }

    case ORDER_MANAGEMENT_ACTIONS.SEARCH_ORDER_MANAGEMENT_OUTPUT_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // get by id
    case ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_OUTPUT_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_OUTPUT_BY_ID_FULFILLED: {
      let outputDetail = {
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        outputDetail: outputDetail,
        isLoading: false
      };
    }

    case ORDER_MANAGEMENT_ACTIONS.GET_ORDER_MANAGEMENT_OUTPUT_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };
    // end output

    // create
    case ORDER_MANAGEMENT_ACTIONS.CREATE_ORDER_MANAGEMENT_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case ORDER_MANAGEMENT_ACTIONS.CREATE_ORDER_MANAGEMENT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Thêm dữ liệu thành công'
      };

    case ORDER_MANAGEMENT_ACTIONS.CREATE_ORDER_MANAGEMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: 'Đã xảy ra lỗi'
      };

    // update
    case ORDER_MANAGEMENT_ACTIONS.UPDATE_ORDER_MANAGEMENT_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case ORDER_MANAGEMENT_ACTIONS.UPDATE_ORDER_MANAGEMENT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Thêm mới dữ liệu thành công'
      };

    case ORDER_MANAGEMENT_ACTIONS.UPDATE_ORDER_MANAGEMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: 'Đã xảy ra lỗi'
      };

    // delete
    case ORDER_MANAGEMENT_ACTIONS.DELETE_ORDER_MANAGEMENT_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case ORDER_MANAGEMENT_ACTIONS.DELETE_ORDER_MANAGEMENT_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Thêm mới dữ liệu thành công'
      };

    case ORDER_MANAGEMENT_ACTIONS.DELETE_ORDER_MANAGEMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        typeMessage: 'error',
        msg: 'Đã xảy ra lỗi'
      };

    case ORDER_MANAGEMENT_ACTIONS.GET_ALL_AREA_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ORDER_MANAGEMENT_ACTIONS.GET_ALL_AREA_FULFILLED: {
      let inputData = {
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        areaList: inputData,
        isLoading: false
      };
    }

    case ORDER_MANAGEMENT_ACTIONS.GET_ALL_AREA_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    case ORDER_MANAGEMENT_ACTIONS.RESET_DATA:
      return {
        ...state,
        input: INIT_STATE.input
      };

    case ORDER_MANAGEMENT_ACTIONS.GET_SEA_TRANSPORT_CONSIGNMENT_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case ORDER_MANAGEMENT_ACTIONS.GET_SEA_TRANSPORT_CONSIGNMENT_FULFILLED: {
      let customsCleanrances = {
        data: action.payload.data.data,
        isMsg: false
      };
      return {
        ...state,
        customsCleanrances: customsCleanrances,
        isLoading: false
      };
    }

    case ORDER_MANAGEMENT_ACTIONS.GET_SEA_TRANSPORT_CONSIGNMENT_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    default:
      return state;
  }
};

export default orderManagementReducer;
