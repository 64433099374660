import { SUMMARY_ACTIONS } from 'actions/TruckingFeeSummary';
import { getErrorMessage } from 'commons/ReducerUtils';
import {
  MSG_PAGING_FAILURE,
  // MSG_TYPE_SUCCESS,
  MSG_TYPE_ERROR} from 'const/common';

const INIT_STATE = {
  loading: false,
  showMessage: false,
  alertMessage: '',
  messageType: '',
  sumByCustomer: 0,
  sumByTransportCompany: 0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUMMARY_ACTIONS.SUM_BY_TRANSPORT_COMPANY_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case SUMMARY_ACTIONS.SUM_BY_TRANSPORT_COMPANY_FULFILLED: {
      return {
        ...state,
        loading: false,
        showMessage: false,
        sumByTransportCompany: action.payload.data?.data ? action.payload.data.data : 0
      };
    }
    case SUMMARY_ACTIONS.SUM_BY_TRANSPORT_COMPANY_REJECTED: {
      let alertMessage = MSG_PAGING_FAILURE;
      if (action.payload.response.status === 400) {
        alertMessage = getErrorMessage(action);
      }
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }

    case SUMMARY_ACTIONS.SUM_BY_CUSTOMERS_PENDING: {
      return {
        ...state,
        loading: true,
        showMessage: false
      };
    }
    case SUMMARY_ACTIONS.SUM_BY_CUSTOMERS_FULFILLED: {
      return {
        ...state,
        loading: false,
        showMessage: false,
        sumByCustomer: action.payload.data?.data ? action.payload.data.data : 0
      };
    }
    case SUMMARY_ACTIONS.SUM_BY_CUSTOMERS_REJECTED: {
      let alertMessage = MSG_PAGING_FAILURE;
      if (action.payload.response.status === 400) {
        alertMessage = getErrorMessage(action);
      }
      return {
        ...state,
        loading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }

    default:
      return state;
  }
};
