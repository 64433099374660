import {
    MOOCS_ACTIONS
} from "actions/mooc";
import {
    put,
    takeEvery,
} from 'redux-saga/effects';
import {
    getMoocsPerPage
} from "services/mooc";

function* putActionUpdateData() {
    const pageSize = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE);
    yield put({
        type: MOOCS_ACTIONS.GET_MOOCS_PER_PAGE,
        payload: getMoocsPerPage(1, pageSize)
    })
}

export function* actionUpdateMoocsData() {
    yield takeEvery([MOOCS_ACTIONS.UPDATE_MOOC_FULFILLED, MOOCS_ACTIONS.CREATE_MOOC_FULFILLED], putActionUpdateData);
}
