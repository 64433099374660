import httpClient from 'HttpClient';
import { getDriverPlanByConsignmentID } from 'services/driverplan';
import { searchOrderManagementInputPerPage } from 'services/ordermanagement';

export const SCRIPT_TOOL_ACTION = {
  UPDATE_CONSIMENT_CODE_SCRIPT_TOOL: 'UPDATE_CONSIMENT_CODE_SCRIPT_TOOL',
  UPDATE_CONSIMENT_CODE_SCRIPT_TOOL_PENDING: 'UPDATE_CONSIMENT_CODE_SCRIPT_TOOL_PENDING',
  UPDATE_CONSIMENT_CODE_SCRIPT_TOOL_FULFILLED: 'UPDATE_CONSIMENT_CODE_SCRIPT_TOOL_FULFILLED',
  UPDATE_CONSIMENT_CODE_SCRIPT_TOOL_REJECTED: 'UPDATE_CONSIMENT_CODE_SCRIPT_TOOL_REJECTED',

  SEARCH_ORDER_MANAGEMENT_INPUT_SCRIPT_TOOL: 'SEARCH_ORDER_MANAGEMENT_INPUT_SCRIPT_TOOL',
  SEARCH_ORDER_MANAGEMENT_INPUT_SCRIPT_TOOL_PENDING: 'SEARCH_ORDER_MANAGEMENT_INPUT_SCRIPT_TOOL_PENDING',
  SEARCH_ORDER_MANAGEMENT_INPUT_SCRIPT_TOOL_FULFILLED: 'SEARCH_ORDER_MANAGEMENT_INPUT_SCRIPT_TOOL_FULFILLED',
  SEARCH_ORDER_MANAGEMENT_INPUT_SCRIPT_TOOL_REJECTED: 'SEARCH_ORDER_MANAGEMENT_INPUT_SCRIPT_TOOL_REJECTED',

  UPDATE_OIL_PRICES_TRUCKING_PLAN_SCRIPT_TOOL: 'UPDATE_OIL_PRICES_TRUCKING_PLAN_SCRIPT_TOOL',
  UPDATE_OIL_PRICES_TRUCKING_PLAN_SCRIPT_TOOL_PENDING: 'UPDATE_OIL_PRICES_TRUCKING_PLAN_SCRIPT_TOOL_PENDING',
  UPDATE_OIL_PRICES_TRUCKING_PLAN_SCRIPT_TOOL_FULFILLED: 'UPDATE_OIL_PRICES_TRUCKING_PLAN_SCRIPT_TOOL_FULFILLED',
  UPDATE_OIL_PRICES_TRUCKING_PLAN_SCRIPT_TOOL_REJECTED: 'UPDATE_OIL_PRICES_TRUCKING_PLAN_SCRIPT_TOOL_REJECTED',

  GET_DRIVER_PLAN_BY_CONSIGNMENT_ID: 'GET_DRIVER_PLAN_BY_CONSIGNMENT_ID',
  GET_DRIVER_PLAN_BY_CONSIGNMENT_ID_PENDING: 'GET_DRIVER_PLAN_BY_CONSIGNMENT_ID_PENDING',
  GET_DRIVER_PLAN_BY_CONSIGNMENT_ID_FULFILLED: 'GET_DRIVER_PLAN_BY_CONSIGNMENT_ID_FULFILLED',
  GET_DRIVER_PLAN_BY_CONSIGNMENT_ID_REJECTED: 'GET_DRIVER_PLAN_BY_CONSIGNMENT_ID_REJECTED'
};

export const actionUpdateConsimentCode = (payload) => (dispatch) => {
  return dispatch({
    type: SCRIPT_TOOL_ACTION.UPDATE_CONSIMENT_CODE,
    payload: httpClient.put(`/Consignment/updateConsignmentCode`, payload)
  });
};

export const actionSearchOrderManagementInputScriptTool = (payload) => (dispatch) => {
  return dispatch({
    type: SCRIPT_TOOL_ACTION.SEARCH_ORDER_MANAGEMENT_INPUT_SCRIPT_TOOL,
    // payload: searchOrderManagementInputPerPage(payload)
    payload: httpClient.get(`/Consignment/getNonAutoCreate`, { params: payload })
  });
};

export const actionGetDriverPlanByConsignmentID = (Id) => (dispatch) => {
  return dispatch({
    type: SCRIPT_TOOL_ACTION.GET_DRIVER_PLAN_BY_CONSIGNMENT_ID,
    payload: getDriverPlanByConsignmentID(Id)
  });
};

export const actionUpdateOilPricesTruckingPlanScriptTool = (payload) => (dispatch) => {
  return dispatch({
    type: SCRIPT_TOOL_ACTION.UPDATE_OIL_PRICES_TRUCKING_PLAN_SCRIPT_TOOL,
    payload: httpClient.put('/OilPriceInfo/updateOilPriceTruckingPLan', payload)
  });
};

export const actionSplitConsignment = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.post('/consignment/createSeparateConsignment', payload)
  });
};

export const actionUpdateDiscountMoney = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.put('/TruckingFee/UpdateDiscountMoney', payload)
  });
};
