import httpClient from 'HttpClient';
// define const actions
export const SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS = {
  GET_SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_PER_PAGE: 'GET_SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_PER_PAGE',
  GET_SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_PER_PAGE_PENDING:
    'GET_SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_PER_PAGE_PENDING',
  GET_SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_PER_PAGE_FULFILLED:
    'GET_SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_PER_PAGE_FULFILLED',
  GET_SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_PER_PAGE_REJECTED:
    'GET_SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_PER_PAGE_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  UPDATE_DATA_SETTLEMENT_CUSTOMER: 'UPDATE_DATA_SETTLEMENT_CUSTOMER',
  UPDATE_DATA_SETTLEMENT_CUSTOMER_PENDING: 'UPDATE_DATA_SETTLEMENT_CUSTOMER_PENDING',
  UPDATE_DATA_SETTLEMENT_CUSTOMER_FULFILLED: 'UPDATE_DATA_SETTLEMENT_CUSTOMER_FULFILLED',
  UPDATE_DATA_SETTLEMENT_CUSTOMER_REJECTED: 'UPDATE_DATA_SETTLEMENT_CUSTOMER_REJECTED',

  UPDATE_STATUS_SETTLEMENT: 'UPDATE_STATUS_SETTLEMENT',
  UPDATE_STATUS_SETTLEMENT_PENDING: 'UPDATE_STATUS_SETTLEMENT_PENDING',
  UPDATE_STATUS_SETTLEMENT_FULFILLED: 'UPDATE_STATUS_SETTLEMENT_FULFILLED',
  UPDATE_STATUS_SETTLEMENT_REJECTED: 'UPDATE_STATUS_SETTLEMENT_REJECTED',

  TRUCKING_PRICE_BY_CONSIGNMENT: 'TRUCKING_PRICE_BY_CONSIGNMENT',
  TRUCKING_PRICE_BY_CONSIGNMENT_PENDING: 'TRUCKING_PRICE_BY_CONSIGNMENT_PENDING',
  TRUCKING_PRICE_BY_CONSIGNMENT_FULFILLED: 'TRUCKING_PRICE_BY_CONSIGNMENT_FULFILLED',
  TRUCKING_PRICE_BY_CONSIGNMENT_REJECTED: 'TRUCKING_PRICE_BY_CONSIGNMENT_REJECTED'
};

// define actions
export const getListDataPerpage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.GET_SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_PER_PAGE,
    payload: httpClient.get(`/customersettlement/paging/page?Page=${pageNo}&PageSize=${pageSize}`)
  });
};
export const getListDataPerpageAll = (params) => (dispatch) => {
  return dispatch({
    // type: SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.GET_SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_PER_PAGE,
    payload: httpClient.get(`/customersettlement/paging/page`, { params: params })
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const actionSearch = (value, pageNo, pageSize, cancelToken) => (dispatch) => {
  const params = { Page: pageNo, PageSize: pageSize, ...value };
  return dispatch({
    type: SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/customersettlement/search`, {
      params: params,
      cancelToken: cancelToken ? cancelToken.token : null
    })
  });
};
export const actionSearchAll = (params) => (dispatch) => {
  return dispatch({
    // type: SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/customersettlement/search`, { params: params })
  });
};

export const updateTruckingPriceByConsignment = (consignmentId, TruckingPriceByConsignment) => (dispatch) => {
  const params = {
    consignmentId: parseInt(consignmentId),
    TruckingPriceByConsignment: parseInt(TruckingPriceByConsignment)
  };
  return dispatch({
    type: SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.TRUCKING_PRICE_BY_CONSIGNMENT,
    payload: httpClient.put(
      `/Consignment/update/TruckingPriceByConsignment?consignmentId=${params.consignmentId}&TruckingPriceByConsignment=${params.TruckingPriceByConsignment}`
    )
  });
};

export const actionUpdateData = (payload) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.UPDATE_DATA_SETTLEMENT_CUSTOMER,
    payload: httpClient.put(`/Consignment/update/paymentAmount`, payload)
  });
};

export const actionUpdateStatusSettlement = (payload) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_FOLLOW_CUSTOMERS_ACCOUNTANT_ACTIONS.UPDATE_STATUS_SETTLEMENT,
    payload: httpClient.put(`/consignment/update/CloseConsignment`, payload)
  });
};

export const actionDuePaymentDate = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.put(`/customersettlement/updateDuePaymentDate`, payload)
  });
};

export const actionUpdateDateStrip = (payload) => (dispatch) => {
  return dispatch({
    payload: httpClient.put(`consignment/update/DateStrip`, payload)
  });
};
