import api from 'HttpClient';

// const type of function get data per page
export const FETCH_ALL_DRIVER_PLAN_TEMPLATES = 'FETCH_ALL_DRIVER_PLAN_TEMPLATES';
export const FETCH_ALL_DRIVER_PLAN_TEMPLATES_PENDING = 'FETCH_ALL_DRIVER_PLAN_TEMPLATES_PENDING';
export const FETCH_ALL_DRIVER_PLAN_TEMPLATES_FULFILLED = 'FETCH_ALL_DRIVER_PLAN_TEMPLATES_FULFILLED';
export const FETCH_ALL_DRIVER_PLAN_TEMPLATES_REJECTED = 'FETCH_ALL_DRIVER_PLAN_TEMPLATES_REJECTED';
export const fetchAllTemplates = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_DRIVER_PLAN_TEMPLATES,
    payload: api.get(`/DriverPlanTemplate/getAll`)
  });
};

// const type of function get data per page
export const CREATE_DRIVER_PLAN_TEMPLATE = 'CREATE_DRIVER_PLAN_TEMPLATE';
export const CREATE_DRIVER_PLAN_TEMPLATE_PENDING = 'CREATE_DRIVER_PLAN_TEMPLATE_PENDING';
export const CREATE_DRIVER_PLAN_TEMPLATE_FULFILLED = 'CREATE_DRIVER_PLAN_TEMPLATE_FULFILLED';
export const CREATE_DRIVER_PLAN_TEMPLATE_REJECTED = 'CREATE_DRIVER_PLAN_TEMPLATE_REJECTED';
export const createDriverPlanTemplate = (payload) => (dispatch) => {
  return dispatch({
    type: CREATE_DRIVER_PLAN_TEMPLATE,
    payload: api.post(`DriverPlanTemplate/create`, payload)
  });
};

// const type of function get data per page
export const UPSERT_DRIVER_PLAN_TEMPLATE = 'UPSERT_DRIVER_PLAN_TEMPLATE';
export const UPSERT_DRIVER_PLAN_TEMPLATE_PENDING = 'UPSERT_DRIVER_PLAN_TEMPLATE_PENDING';
export const UPSERT_DRIVER_PLAN_TEMPLATE_FULFILLED = 'UPSERT_DRIVER_PLAN_TEMPLATE_FULFILLED';
export const UPSERT_DRIVER_PLAN_TEMPLATE_REJECTED = 'UPSERT_DRIVER_PLAN_TEMPLATE_REJECTED';
export const upsertDriverPlanTemplate = (payload) => (dispatch) => {
  return dispatch({
    type: UPSERT_DRIVER_PLAN_TEMPLATE,
    payload: api.post(`DriverPlanTemplate/create`, payload)
  });
};
