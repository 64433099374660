import api from 'HttpClient';

// const type of function get data per page
export const FETCH_WARNING_CARS = 'FETCH_WARNING_CARS';
export const FETCH_WARNING_CARS_PENDING = 'FETCH_WARNING_CARS_PENDING';
export const FETCH_WARNING_CARS_FULFILLED = 'FETCH_WARNING_CARS_FULFILLED';
export const FETCH_WARNING_CARS_REJECTED = 'FETCH_WARNING_CARS_REJECTED';
export const fetchWarningCars = (type, page, size) => (dispatch) => {
  return dispatch({
    type: FETCH_WARNING_CARS,
    payload: api.get(`/Dashboard/getTractorTrailerExpiredWarning`, {
      params: {
        warningType: type,
        page: page,
        pageSize: size
      }
    })
  });
};
export const fetchWarningExpirationDate = () => (dispatch) => {
  return dispatch({
    type: FETCH_WARNING_CARS,
    payload: api.get(`/Dashboard/getTractorTrailerExpiredWarningThreeWeeksPrior`)
  });
};

export const FETCH_WARNING_REPAIRING_PARTS = 'FETCH_WARNING_REPAIRING_PARTS';
export const FETCH_WARNING_REPAIRING_PARTS_PENDING = 'FETCH_WARNING_REPAIRING_PARTS_PENDING';
export const FETCH_WARNING_REPAIRING_PARTS_FULFILLED = 'FETCH_WARNING_REPAIRING_PARTS_FULFILLED';
export const FETCH_WARNING_REPAIRING_PARTS_REJECTED = 'FETCH_WARNING_REPAIRING_PARTS_REJECTED';
export const fetchWarningRepairingParts = (type, page, size) => (dispatch) => {
  return dispatch({
    type: FETCH_WARNING_REPAIRING_PARTS,
    payload: api.get(`/Dashboard/getTractorTrailerSparePartExpirationWarning`, {
      params: {
        warningType: type,
        page: page,
        pageSize: size
      }
    })
  });
};

// const type of function get data per page
export const GET_LATEST_CONSIGNMENT_AND_TRUCKING = 'GET_LATEST_CONSIGNMENT_AND_TRUCKING';
export const GET_LATEST_CONSIGNMENT_AND_TRUCKING_PENDING = 'GET_LATEST_CONSIGNMENT_AND_TRUCKING_PENDING';
export const GET_LATEST_CONSIGNMENT_AND_TRUCKING_FULFILLED = 'GET_LATEST_CONSIGNMENT_AND_TRUCKING_FULFILLED';
export const GET_LATEST_CONSIGNMENT_AND_TRUCKING_REJECTED = 'GET_LATEST_CONSIGNMENT_AND_TRUCKING_REJECTED';
export const getLatestConsignmentAndTrucking = () => (dispatch) => {
  return dispatch({
    type: GET_LATEST_CONSIGNMENT_AND_TRUCKING,
    payload: api.get(`/Dashboard/getNewConsignmentAndTrucking`)
  });
};

export const GET_REAL_TIME_TRACTRORTRAILER = 'GET_REAL_TIME_TRACTRORTRAILER';
export const GET_REAL_TIME_TRACTRORTRAILER_PENDING = 'GET_REAL_TIME_TRACTRORTRAILER_PENDING';
export const GET_REAL_TIME_TRACTRORTRAILER_FULFILLED = 'GET_REAL_TIME_TRACTRORTRAILER_FULFILLED';
export const GET_REAL_TIME_TRACTORTRAILER_REJECTED = 'GET_REAL_TIME_TRACTRORTRAILER_REJECTED';
export const getRealTimeTractorTrailer = () => (dispatch) => {
  return dispatch({
    type: GET_REAL_TIME_TRACTRORTRAILER,
    payload: api.get(`/Dashboard/getRealTimeTractorTrailer`)
  });
};

export const GET_TRUCKING_PLAN_GROUPED = 'GET_TRUCKING_PLAN_GROUPED';
export const GET_TRUCKING_PLAN_GROUPED_PENDING = 'GET_TRUCKING_PLAN_GROUPED_PENDING';
export const GET_TRUCKING_PLAN_GROUPED_FULFILLED = 'GET_TRUCKING_PLAN_GROUPED_FULFILLED';
export const GET_TRUCKING_PLAN_GROUPED_REJECTED = 'GET_TRUCKING_PLAN_GROUPED_REJECTED';
export const getTruckingPlanGrouped = (params) => (dispatch) => {
  return dispatch({
    type: GET_TRUCKING_PLAN_GROUPED,
    payload: api.get(`/Dashboard/getTruckingPlanGrouped`, { params: params })
  });
};

export const GET_REVENUE_BY_DAY = 'GET_REVENUE_BY_DAY';
export const GET_REVENUE_BY_DAY_PENDING = 'GET_REVENUE_BY_DAY_PENDING';
export const GET_REVENUE_BY_DAY_FULFILLED = 'GET_REVENUE_BY_DAY_FULFILLED';
export const GET_REVENUE_BY_DAY_REJECTED = 'GET_REVENUE_BY_DAY_REJECTED';
export const GetRevenueByDay = () => (dispatch) => {
  return dispatch({
    type: GET_REVENUE_BY_DAY,
    payload: api.get(`/Dashboard/GetRevenueByDay`)
  });
};
export const GET_REPORT_PAYMENT = 'GET_REPORT_PAYMENT';
export const GET_REPORT_PAYMENT_PENDING = 'GET_REPORT_PAYMENT_PENDING';
export const GET_REPORT_PAYMENT_FULFILLED = 'GET_REPORT_PAYMENT_FULFILLED';
export const GET_REPORT_PAYMENT_REJECTED = 'GET_REPORT_PAYMENT_REJECTED';
export const GetReportPayment = () => (dispatch) => {
  return dispatch({
    type: GET_REPORT_PAYMENT,
    payload: api.get(`/Dashboard/GetReportPayment`)
  });
};

export const GET_FUEL_USAGE_REPORT = 'GET_FUEL_USAGE_REPORT';
export const GET_FUEL_USAGE_REPORT_PENDING = 'GET_FUEL_USAGE_REPORT_PENDING';
export const GET_FUEL_USAGE_REPORT_FULFILLED = 'GET_FUEL_USAGE_REPORT_FULFILLED';
export const GET_FUEL_USAGE_REPORT_REJECTED = 'GET_FUEL_USAGE_REPORT_REJECTED';
export const GetFuelUsageReport = () => (dispatch) => {
  return dispatch({
    type: GET_FUEL_USAGE_REPORT,
    payload: api.get(`Dashboard/GetFuelUsageReport`)
  });
};

export const GET_SEMI_TRAILER_WEIGHT = 'GET_SEMI_TRAILER_WEIGHT';
export const GET_SEMI_TRAILER_WEIGHT_PENDING = 'GET_SEMI_TRAILER_WEIGHT_PENDING';
export const GET_SEMI_TRAILER_WEIGHT_FULFILLED = 'GET_SEMI_TRAILER_WEIGHT_FULFILLED';
export const GET_SEMI_TRAILER_WEIGHT_REJECTED = 'GET_SEMI_TRAILER_WEIGHT_REJECTED';
export const GetSemiTrailerWeight = (params) => (dispatch) => {
  return dispatch({
    type: GET_SEMI_TRAILER_WEIGHT,
    payload: api.get(`Dashboard/GetSemiTrailerWeight`, {
      params: params
    })
  });
};

export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_PERMISSIONS_PENDING = 'GET_PERMISSIONS_PENDING';
export const GET_PERMISSIONS_FULFILLED = 'GET_PERMISSIONS_FULFILLED';
export const GET_PERMISSIONS_REJECTED = 'GET_PERMISSIONS_REJECTED';
export const getPermissions = (params) => (dispatch) => {
  return dispatch({
    type: GET_PERMISSIONS,
    payload: api.get(`Dashboard/getPermissions`)
  });
};
