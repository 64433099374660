import { GOODS_TYPE_ACTIONS } from 'actions/goodstype';

const INIT_STATE = {
  consignmentGoodsType: []
};

const goodsTypeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // get all goods type
    case GOODS_TYPE_ACTIONS.GET_ALL_GOODS_TYPE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case GOODS_TYPE_ACTIONS.GET_ALL_GOODS_TYPE_FULFILLED: {
      let goodsType = {
        data: action.payload.data.data
      };
      return {
        ...state,
        goodsType: goodsType,
        isLoading: false
      };
    }

    case GOODS_TYPE_ACTIONS.GET_ALL_GOODS_TYPE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    case GOODS_TYPE_ACTIONS.GET_CONSIGNMENT_GOODS_TYPE_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case GOODS_TYPE_ACTIONS.GET_CONSIGNMENT_GOODS_TYPE_BY_ID_FULFILLED: {
      let consignmentGoodsType = action.payload.data.data;
      return {
        ...state,
        consignmentGoodsType: consignmentGoodsType,
        isLoading: false
      };
    }

    case GOODS_TYPE_ACTIONS.GET_CONSIGNMENT_GOODS_TYPE_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    case GOODS_TYPE_ACTIONS.GET_ALL_CONSIGNMENT_GOODS_TYPE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case GOODS_TYPE_ACTIONS.GET_ALL_CONSIGNMENT_GOODS_TYPE_FULFILLED: {
      let consignmentGoodsType = action.payload.data.data;
      return {
        ...state,
        consignmentGoodsType: consignmentGoodsType,
        isLoading: false
      };
    }

    case GOODS_TYPE_ACTIONS.GET_ALL_CONSIGNMENT_GOODS_TYPE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };
    case GOODS_TYPE_ACTIONS.CREATE_GOODS_TYPE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Không thêm mới được loại hàng'
      };
    case GOODS_TYPE_ACTIONS.CREATE_GOODS_TYPE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Thêm loại hàng thành công'
      };
    default:
      return state;
  }
};

export default goodsTypeReducer;
