import { IMPORT_SPARE_PART_ACTIONS } from 'actions/repair/importSparePartManagement';
import { getErrorMessage } from 'commons/ReducerUtils';
import { MSG_ADD_FAILURE } from 'const/common';

const ImportSparePartReducer = (state = {}, action) => {
  switch (action.type) {
    //get all
    case IMPORT_SPARE_PART_ACTIONS.GET_ALL_DATA_IMPORT_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case IMPORT_SPARE_PART_ACTIONS.GET_ALL_DATA_IMPORT_FULFILLED:
      let allData = {
        data: action.payload.data
      };
      return {
        ...state,
        allData: allData,
        isLoading: false
      };

    case IMPORT_SPARE_PART_ACTIONS.GET_ALL_DATA_IMPORT_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    case IMPORT_SPARE_PART_ACTIONS.GET_SPAREPART_RECEIVING_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case IMPORT_SPARE_PART_ACTIONS.GET_SPAREPART_RECEIVING_BY_ID_FULFILLED:
      let sparepartReceiving = {
        data: action.payload.data
      };
      return {
        ...state,
        sparepartReceiving: sparepartReceiving,
        isLoading: false
      };

    case IMPORT_SPARE_PART_ACTIONS.GET_SPAREPART_RECEIVING_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // get per page
    case IMPORT_SPARE_PART_ACTIONS.GET_DATA_PER_PAGE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case IMPORT_SPARE_PART_ACTIONS.GET_DATA_PER_PAGE_FULFILLED:
      let dataPerPage = {
        pagination: action.payload.data.pagination,
        data: action.payload.data,
        isMsg: false
      };
      return {
        ...state,
        dataPerPage: dataPerPage,
        isLoading: false
      };

    case IMPORT_SPARE_PART_ACTIONS.GET_DATA_PER_PAGE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    // get  by id
    // case IMPORT_SPARE_PART_ACTIONS.GET_ROAD_FEE_BY_ID_PENDING:
    //   return {
    //     ...state,
    //     isLoading: true,
    //     isMsg: false,
    //   };

    // case IMPORT_SPARE_PART_ACTIONS.GET_ROAD_FEE_BY_ID_FULFILLED:
    //   return {
    //     ...state,
    //     isLoading: false
    //   };

    // case IMPORT_SPARE_PART_ACTIONS.GET_ROAD_FEE_BY_ID_REJECTED:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     typeMessage: "error",
    //     isMsg: true,
    //     msg: "Đã xảy ra lỗi"
    //   };

    // create
    case IMPORT_SPARE_PART_ACTIONS.CREATE_DATA_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case IMPORT_SPARE_PART_ACTIONS.CREATE_DATA_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Tạo dữ liệu thành công'
      };

    case IMPORT_SPARE_PART_ACTIONS.CREATE_DATA_REJECTED:
      return {
        ...state,
        isLoading: true,
        typeMessage: 'error',
        isMsg: true,
        msg: getErrorMessage(action, MSG_ADD_FAILURE)
      };

    // update routers
    case IMPORT_SPARE_PART_ACTIONS.UPDATE_DATA_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case IMPORT_SPARE_PART_ACTIONS.UPDATE_DATA_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Sửa dữ liệu thành công',
        typeMessage: 'success'
      };

    case IMPORT_SPARE_PART_ACTIONS.UPDATE_DATA_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Sửa dữ liệu thất bại'
      };

    // delete routers
    case IMPORT_SPARE_PART_ACTIONS.DELETE_DATA_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case IMPORT_SPARE_PART_ACTIONS.DELETE_DATA_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Xóa dữ liệu thành công'
      };

    case IMPORT_SPARE_PART_ACTIONS.DELETE_DATA_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Xóa dữ liệu thất bại'
      };
    //search
    case IMPORT_SPARE_PART_ACTIONS.SEARCH_DATA_PENDING: {
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };
    }
    case IMPORT_SPARE_PART_ACTIONS.SEARCH_DATA_FULFILLED: {
      let resultSearch = {
        pagination: action.payload.data.pagination,
        data: action.payload.data,
        isMsg: false
      };
      return {
        ...state,
        isLoading: false,
        dataPerPage: resultSearch,
        // pagination: action.payload.data.pagination,
        isSearch: true
      };
    }
    case IMPORT_SPARE_PART_ACTIONS.SEARCH_DATA_REJECTED: {
      return {
        ...state,
        isLoading: false,
        isShowMessage: true,
        typeMessage: 'error',
        msg: 'Không thể lấy được dữ liệu !'
      };
    }

    case IMPORT_SPARE_PART_ACTIONS.GET_APPROVED_STATUS_PENDING: {
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };
    }
    case IMPORT_SPARE_PART_ACTIONS.GET_APPROVED_STATUS_FULFILLED: {
      return {
        ...state,
        isLoading: false,
        approvedStatuses: action.payload.data.data,
        isMsg: false
      };
    }
    case IMPORT_SPARE_PART_ACTIONS.GET_APPROVED_STATUS_REJECTED: {
      return {
        ...state,
        isLoading: false,
        isShowMessage: true,
        typeMessage: 'error',
        msg: 'Không thể lấy được dữ liệu !'
      };
    }

    // create new approve
    case IMPORT_SPARE_PART_ACTIONS.CREATE_NEW_APPROVED_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case IMPORT_SPARE_PART_ACTIONS.CREATE_NEW_APPROVED_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: true,
        msg: 'Tạo đề xuất phê duyệt thành công'
      };

    case IMPORT_SPARE_PART_ACTIONS.CREATE_NEW_APPROVED_REJECTED:
      return {
        ...state,
        isLoading: true,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Tạo đề xuất phê duyệt thất bại'
      };

    case IMPORT_SPARE_PART_ACTIONS.UPDATE_APPROVAL_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case IMPORT_SPARE_PART_ACTIONS.UPDATE_APPROVAL_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Cập nhật dữ liệu thành công',
        typeMessage: 'success'
      };

    case IMPORT_SPARE_PART_ACTIONS.UPDATE_APPROVAL_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: 'Cập nhật dữ liệu thất bại'
      };
    // delete files
    case IMPORT_SPARE_PART_ACTIONS.DELETE_FILE_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case IMPORT_SPARE_PART_ACTIONS.DELETE_FILE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: false
        // msg: 'Xóa dữ liệu thành công'
      };

    case IMPORT_SPARE_PART_ACTIONS.DELETE_FILE_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: getErrorMessage(action, 'Xóa file thất bại!')
      };
    // get spare part order
    case IMPORT_SPARE_PART_ACTIONS.GET_SPAREPART_ORDER_BY_CODE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case IMPORT_SPARE_PART_ACTIONS.GET_SPAREPART_ORDER_BY_CODE_FULFILLED:
      return {
        ...state,
        sparepartOder: action.payload.data.data,
        isLoading: false
      };

    case IMPORT_SPARE_PART_ACTIONS.GET_SPAREPART_ORDER_BY_CODE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };
    default:
      return state;
  }
};

export default ImportSparePartReducer;
