import api from 'HttpClient';

export const SET_OPEN_USER_MANUAL_MODAL = 'SET_OPEN_USER_MANUAL_MODAL';
export const setOpenUserManualModal = (data, type) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_USER_MANUAL_MODAL,
    payload: { data, type }
  });
};

export const SET_CLOSE_USER_MANUAL_MODAL = 'SET_CLOSE_USER_MANUAL_MODAL';
export const setCloseUserManualModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_USER_MANUAL_MODAL
  });
};

export const FETCH_ALL_USER_MANUALS = 'FETCH_ALL_USER_MANUALS';
export const FETCH_ALL_USER_MANUALS_PENDING = 'FETCH_ALL_USER_MANUALS_PENDING';
export const FETCH_ALL_USER_MANUALS_FULFILLED = 'FETCH_ALL_USER_MANUALS_FULFILLED';
export const FETCH_ALL_USER_MANUALS_REJECTED = 'FETCH_ALL_USER_MANUALS_REJECTED';
export const fetchAllUserManuals = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_USER_MANUALS,
    payload: api.get(`/GuideImages/Screen/getAll`)
  });
};

// const type of function get data per page
export const FETCH_USER_MANUALS = 'FETCH_USER_MANUALS';
export const FETCH_USER_MANUALS_PENDING = 'FETCH_USER_MANUALS_PENDING';
export const FETCH_USER_MANUALS_FULFILLED = 'FETCH_USER_MANUALS_FULFILLED';
export const FETCH_USER_MANUALS_REJECTED = 'FETCH_USER_MANUALS_REJECTED';
export const fetchUserManuals = (screen) => (dispatch) => {
  return dispatch({
    type: FETCH_USER_MANUALS,
    payload: api.get(`/GuideImages/Screen?screen=${screen}`)
  });
};

// const type of function create data
export const CREATE_USER_MANUAL = 'CREATE_USER_MANUAL';
export const CREATE_USER_MANUAL_PENDING = 'CREATE_USER_MANUAL_PENDING';
export const CREATE_USER_MANUAL_FULFILLED = 'CREATE_USER_MANUAL_FULFILLED';
export const CREATE_USER_MANUAL_REJECTED = 'CREATE_USER_MANUAL_REJECTED';
export const createUserManual = (payload) => (dispatch) => {
  return dispatch({
    type: CREATE_USER_MANUAL,
    payload: api.post(`/GuideImages/create`, payload)
  });
};

// const type of function update data
export const UPDATE_USER_MANUAL = 'UPDATE_USER_MANUAL';
export const UPDATE_USER_MANUAL_PENDING = 'UPDATE_USER_MANUAL_PENDING';
export const UPDATE_USER_MANUAL_FULFILLED = 'UPDATE_USER_MANUAL_FULFILLED';
export const UPDATE_USER_MANUAL_REJECTED = 'UPDATE_USER_MANUAL_REJECTED';
export const updateUserManual = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_USER_MANUAL,
    payload: api.put(`/GuideImages/update`, payload),
    meta: payload
  });
};
// const type of get deatail
export const FETCH_USER_MANUAL_DETAIL = 'FETCH_USER_MANUAL_DETAIL';
export const FETCH_USER_MANUAL_DETAIL_PENDING = 'FETCH_USER_MANUAL_DETAIL_PENDING';
export const FETCH_USER_MANUAL_DETAIL_FULFILLED = 'FETCH_USER_MANUAL_DETAIL_FULFILLED';
export const FETCH_USER_MANUAL_DETAIL_REJECTED = 'FETCH_USER_MANUAL_DETAIL_REJECTED';
export const getUserManualDetail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_USER_MANUAL_DETAIL,
    payload: api.get(`/GuideImages/${id}`)
  });
};

// const type of delete
export const DELETE_USER_MANUAL = 'DELETE_USER_MANUAL';
export const DELETE_USER_MANUAL_PENDING = 'DELETE_USER_MANUAL_PENDING';
export const DELETE_USER_MANUAL_FULFILLED = 'DELETE_USER_MANUAL_FULFILLED';
export const DELETE_USER_MANUAL_REJECTED = 'DELETE_USER_MANUAL_REJECTED';
export const deleteUserManual = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_USER_MANUAL,
    payload: api.delete(`/GuideImages/delete/${id}`)
  });
};
