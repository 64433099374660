import api from 'HttpClient';

// const type of function get data per page
export const DEBT2_TYPE = {
  SEARCH_DEBT2: 'SEARCH_DEBT2',
  SEARCH_DEBT2_PENDING: 'SEARCH_DEBT2_PENDING',
  SEARCH_DEBT2_FULFILLED: 'SEARCH_DEBT2_FULFILLED',
  SEARCH_DEBT2_REJECTED: 'SEARCH_DEBT2_REJECTED',

  GET_DEBTS_INCURREND_BY_ID: 'GET_DEBTS_INCURREND_BY_ID',
  GET_DEBTS_INCURREND_BY_ID_PENDING: 'GET_DEBTS_INCURREND_BY_ID_PENDING',
  GET_DEBTS_INCURREND_BY_ID_FULFILLED: 'GET_DEBTS_INCURREND_BY_ID_FULFILLED',
  GET_DEBTS_INCURREND_BY_ID_REJECTED: 'GET_DEBTS_INCURREND_BY_ID_REJECTED',

  GET_AMOUNT_INCURRED_BY_ID: 'GET_AMOUNT_INCURRED_BY_ID',
  GET_AMOUNT_INCURRED_BY_ID_PENDING: 'GET_AMOUNT_INCURRED_BY_ID_PENDING',
  GET_AMOUNT_INCURRED_BY_ID_FULFILLED: 'GET_AMOUNT_INCURRED_BY_ID_FULFILLED',
  GET_AMOUNT_INCURRED_BY_ID_REJECTED: 'GET_AMOUNT_INCURRED_BY_ID_REJECTED',

  GET_DEBTS_PAYMENT: 'GET_DEBTS_PAYMENT',
  GET_DEBTS_PAYMENT_PENDING: 'GET_DEBTS_PAYMENT_PENDING',
  GET_DEBTS_PAYMENT_FULFILLED: 'GET_DEBTS_PAYMENT_FULFILLED',
  GET_DEBTS_PAYMENT_REJECTED: 'GET_DEBTS_PAYMENT_REJECTED',

  CREATE_DEBT_PAYMENT: 'CREATE_DEBT_PAYMENT',
  CREATE_DEBT_PAYMENT_PENDING: 'CREATE_DEBT_PAYMENT_PENDING',
  CREATE_DEBT_PAYMENT_FULFILLED: 'CREATE_DEBT_PAYMENT_FULFILLED',
  CREATE_DEBT_PAYMENT_REJECTED: 'CREATE_DEBT_PAYMENT_REJECTED',

  GET_INCURRED_ALL_DEBT: 'GET_INCURRED_ALL_DEBT',
  GET_INCURRED_ALL_DEBT_PENDING: 'GET_INCURRED_ALL_DEBT_PENDING',
  GET_INCURRED_ALL_DEBT_FULFILLED: 'GET_INCURRED_ALL_DEBT_FULFILLED',
  GET_INCURRED_ALL_DEBT_REJECTED: 'GET_INCURRED_ALL_DEBT_REJECTED',

  GET_DEBT_FUND: 'GET_DEBT_FUND',
  GET_DEBT_FUND_PENDING: 'GET_DEBT_FUND_PENDING',
  GET_DEBT_FUND_FULFILLED: 'GET_DEBT_FUND_FULFILLED',
  GET_DEBT_FUND_REJECTED: 'GET_DEBT_FUND_REJECTED',

  GET_DEBT_ALL_BANK: 'GET_DEBT_ALL_BANK',
  GET_DEBT_ALL_BANK_PENDING: 'GET_DEBT_ALL_BANK_PENDING',
  GET_DEBT_ALL_BANK_FULFILLED: 'GET_DEBT_ALL_BANK_FULFILLED',
  GET_DEBT_ALL_BANK_REJECTED: 'GET_DEBT_ALL_BANK_REJECTED',

  GET_DEBT_BANK_ACCOUNT: 'GET_DEBT_BANK_ACCOUNT',
  GET_DEBT_BANK_ACCOUNT_PENDING: 'GET_DEBT_BANK_ACCOUNT_PENDING',
  GET_DEBT_BANK_ACCOUNT_FULFILLED: 'GET_DEBT_BANK_ACCOUNT_FULFILLED',
  GET_DEBT_BANK_ACCOUNT_REJECTED: 'GET_DEBT_BANK_ACCOUNT_REJECTED',

  GET_DEBT_BANK_INFO_FOR_FUNDS: 'GET_DEBT_BANK_INFO_FOR_FUNDS',
  GET_DEBT_BANK_INFO_FOR_FUNDS_PENDING: 'GET_DEBT_BANK_INFO_FOR_FUNDS_PENDING',
  GET_DEBT_BANK_INFO_FOR_FUNDS_FULFILLED: 'GET_DEBT_BANK_INFO_FOR_FUNDS_FULFILLED',
  GET_DEBT_BANK_INFO_FOR_FUNDS_REJECTED: 'GET_DEBT_BANK_INFO_FOR_FUNDS_REJECTED',

  LOADDING_DEBT: 'LOADDING_DEBT',
  LOADDING_DEBT_PENDING: 'LOADDING_DEBT_PENDING',
  LOADDING_DEBT_FULFILLED: 'LOADDING_DEBT_FULFILLED',
  LOADDING_DEBT_REJECTED: 'LOADDING_DEBT_REJECTED'
};
export const seachDebt2Action = (params) => (dispatch) => {
  return dispatch({
    type: DEBT2_TYPE.SEARCH_DEBT2,
    payload: api.get(`/Debts/search`, {
      params: params
    })
  });
};
export const getIncurredAllDebt2Action = (params) => (dispatch) => {
  return dispatch({
    type: DEBT2_TYPE.GET_INCURRED_ALL_DEBT,
    payload: api.get(`Debts/getIncurredAll`, {
      params: params
    })
  });
};

export const seachDebt2ByQuarterAction = (params) => (dispatch) => {
  return dispatch({
    type: DEBT2_TYPE.SEARCH_DEBT2,
    payload: api.get(`/Debts/search/dateV3`, {
      params: params
    })
  });
};

export const updateDebt2Action = (payload) => (dispatch) => {
  return dispatch({
    payload: api.put(`/Debts/updateDebts`, payload)
  });
};

export const getDebtsIncurredByIdAction = (params) => (dispatch) => {
  return dispatch({
    type: DEBT2_TYPE.GET_DEBTS_INCURREND_BY_ID,
    payload: api.get(`/Debts/getDebtsIncurredById`, { params })
  });
};

export const getAmountIncurredByIdAction = (params) => (dispatch) => {
  return dispatch({
    type: DEBT2_TYPE.GET_AMOUNT_INCURRED_BY_ID,
    payload: api.get(`/Debts/getAmountIncurredById`, { params })
  });
};

export const getDebtsPayment = (params) => (dispatch) => {
  return dispatch({
    type: DEBT2_TYPE.GET_DEBTS_PAYMENT,
    payload: api.get(`/Debts/getDebtsPayment`, { params })
  });
};

export const createDebtPayment = (params) => (dispatch) => {
  return dispatch({
    type: DEBT2_TYPE.CREATE_DEBT_PAYMENT,
    payload: api.post('/Debts/insert', params)
  });
};

export const updateDebtPayment = (params) => (dispatch) => {
  return dispatch({
    payload: api.put('/Debts/update', params)
  });
};

export const deleteDebtPayment = (params) => (dispatch) => {
  return dispatch({
    payload: api.delete('/Debts/delete/' + params)
  });
};

export const updateDebtsStartPeriod = (params) => (dispatch) => {
  return dispatch({
    type: DEBT2_TYPE.LOADDING_DEBT,
    payload: api.put('/Debts/updateDebts/startPeriod', null, { params })
  });
};

export const updateLockDebts = (params) => (dispatch) => {
  return dispatch({
    type: DEBT2_TYPE.LOADDING_DEBT,
    payload: api.put('/Debts/updateDebts/lockedFunds', null, { params })
  });
};

export const updateUnLockDebts = (params) => (dispatch) => {
  return dispatch({
    type: DEBT2_TYPE.LOADDING_DEBT,
    payload: api.put('/Debts/updateDebts/unlockFunds', null, { params })
  });
};

export const updateFreightDebts = (params) => (dispatch) => {
  return dispatch({
    type: DEBT2_TYPE.LOADDING_DEBT,
    payload: api.get('Debts/createCustomerSettlement', { params })
  });
};

export const updatelockedFundsById = (Payload) => (dispatch) => {
  return dispatch({
    payload: api.put('/Debts/updateDebts/lockedFundsById', Payload)
  });
};

export const updatelockedFundsByIddateRange = (Payload) => (dispatch) => {
  return dispatch({
    payload: api.put('Debts/updateDebts/lockedFundsById/dateRange', null, { params: Payload })
  });
};

export const getDebtFund = (params) => (dispatch) => {
  return dispatch({
    type: DEBT2_TYPE.GET_DEBT_FUND,
    payload: api.get('/Debts/getDebtsPaymentByBank', { params })
  });
};

export const getDebtAllBanks = (params) => (dispatch) => {
  return dispatch({
    type: DEBT2_TYPE.GET_DEBT_ALL_BANK,
    payload: api.get('/EmployeeInfo/getAll/Bank', { params })
  });
};

export const getDebtBankAccountById = (Id) => (dispatch) => {
  return dispatch({
    type: DEBT2_TYPE.GET_DEBT_BANK_ACCOUNT,
    payload: api.get(`/BankInfoForFunds/getByBankBranchId/${Id}`)
  });
};

export const getAllBankAccount = (params) => (dispatch) => {
  return dispatch({
    type: DEBT2_TYPE.GET_DEBT_BANK_ACCOUNT,
    payload: api.get(`/BankInfoForFunds/get`, { params })
  });
};

export const createBankInfoForFunds = (payload) => (dispatch) => {
  return dispatch({
    payload: api.post(`BankInfoForFunds/insert`, payload)
  });
};

export const updateBankInfoForFunds = (payload) => (dispatch) => {
  return dispatch({
    payload: api.put(`BankInfoForFunds/update`, payload)
  });
};

export const deleteBankInfoForFunds = (Id) => (dispatch) => {
  return dispatch({
    payload: api.delete(`BankInfoForFunds/delete/${Id}`)
  });
};

export const getBankInfoForFunds = (params) => (dispatch) => {
  return dispatch({
    type: DEBT2_TYPE.GET_DEBT_BANK_INFO_FOR_FUNDS,
    payload: api.get(`/BankInfoForFunds/get`, { params })
  });
};

export const updateDebtsPaymentDate = (payload) => (dispatch) => {
  return dispatch({
    payload: api.put(`/Debts/update/debtsPaymentDate`, payload)
  });
};

export const updateCustomerSettlementByCustomerId = (params) => (dispatch) => {
  return dispatch({
    payload: api.get(`/Debts/createCustomerSettlementByCustomerId`, { params })
  });
};
