import localforage from 'localforage';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { persistReducer } from 'redux-persist';

import advanceManagement from './accouting/advanceManagement';
import downPaymentOrderAccountantReducers from './accouting/downPaymentOfOrder';
import settlementFollowCustomerActReducers from './accouting/settlementFollowCustomers';
import addDriverPlanReducer from './adddriverplan';
import addressReducers from './address';
// Phân công cong việc END
import AprrovalReducer from './AprrovalReducer';
import authReducer from './authReducer';
import backupRestore from './backupRestore';
import CarHistory from './CarDetail';
import changePasswordReducer from './changePasswordReducer';
import CommonReducer from './CommonReducer';
import containerReducer from './container';
import customBranchReducer from './custombranch';
import CustomerOutputReducer from './CustomerOutputReducer';
import CustomerPermissionTypeReducer from './CustomerPermissionTypeReducer';
import customerReducer from './customerReducer';
import CustomerServiceReducer from './CustomerServiceReducer';
import CustomerSetting from './customerSetting';
import customFeeReducer from './customfee';
import DailyTransportPlanReducer from './DailyTransportPlanReducer';
import dashboardReducer from './dashboardReducer';
import Debt2Reducer from './Debt2Reducer';
import DepartmentInfoReducer from './departmentInfo';
import depositShellReducers from './depositSell';
import downPaymentOrder from './downPaymentOrder';
import listPaymentOrder from './downPaymentOrderList';
import downPaymentSellDetailReducers from './downPaymentShellDetail';
import downPaymentSellReducers from './downPaymentShellManagement';
import driverPlanReducer from './driveplan';
import driverAdvanceManagement from './driverAdvanceManagement';
import driverManyPlan from './driverManyPlan';
import DriverPlanCostReducer from './DriverPlanCostReducer';
import DriverSalaryInfo from './driverSalaryInfo';
import EmailReducer from './EmailReducer';
import employeesReducer from './employees';
import excelStore from './excelReduce';
import feeCategoriesReducer from './feeCategoriesReducer';
import FieldSettingReducer from './FieldSettingReducer';
import fillUpFuelManagementReducer from './fillupfuelmanagement';
import goodsTypeReducer from './goodstype';
import GroupReducer from './groupReducer';
import headerReducer from './headerReducer';
import History from './History';
import HistoryReducer from './HistoryReducer';
import inputFuelManagementReducer from './inputFuelManagement';
//map
import mapReducer from './map';
import driverPlanForMobile from './mobile/driverPlan';
import RepairReducerForMobile from './mobile/RepairReducer';
import MoocReducer from './mooc';
import navReducer from './navReducer';
import normSetting from './norm/NormSetting';
import NormManagementReducer from './normManagementReducer';
import notification from './notification';
import oilCategoryReducer from './oilcategory';
import oilNormReducers from './oilNorm';
import oilPricesManagementReducer from './oilpricesmanagement';
import operationStatusReducer from './operationStatus';
import orderManagementReducer from './ordermanagement';
import outSourceTransportFeeReducer from './outSourceTransportCost';
import paymentOnBehaftReducer from './paymentonbehaft';
// nhóm quyền theo khách hàng
import PermissionGroupByCustomerReducer from './PermissionGroupByCustomerReducer';
import PermissionReducer from './permissionReducer';
import portCategoryReducer from './portcategory';
import portDepotReducer from './portdepot';
import productCategoryReducer from './productcategory';
import ProfitByVehicleReducer from './ProfitByVehicleReducer';
import accountingListRepair from './repair/accountingListRepair';
import EquipmentCategoryReducer from './repair/equipmentCategory';
import expirationDateReducers from './repair/expirationDate';
import ImportSparePartReducer from './repair/importSparePartManagement';
import QuotationDetailReducer from './repair/QuotationDetailReducer';
import QuotationReducer from './repair/QuotationReducer';
import RepairFeeNormReducer from './repair/repairFeeNorm';
import RepairInvoiceDetailsReducer from './repair/repairInvoiceDetails';
import RepairManagementReducer from './repair/repairManagement';
import settlementRepair from './repair/settlementRepair';
import sparepartDetailReducer from './repair/sparepartDetail';
import SparePartManagementReducer from './repair/sparePartsManagement';
import supplierReducer from './repair/supplier';
import annualFeesReducer from './report/annualFees';
import consignmentReport from './report/consignment';
import deliveryDetailReport from './report/deliveryDetailReport';
import fuelUsageReducers from './report/fuelUsage';
import importRepairPaymentReport from './report/importRepairPayment';
import repairReport from './report/repair';
import revenueByCustomerReducers from './report/revenueByCustomer';
import shippingManagement from './report/shippingManagement';
import wareHouseReportReducers from './report/warehouse';
import BusinessResultReportReducer from './ReportBusinessResult';
// import statusAccountingReducers from "./accouting/statusAccounting";
import ReportReducer from './ReportReducer';
import RevenueCostFollowCarReducer from './RevenueCostFollowCarReducer';
import RoadFeeReducer from './roadFeeManagement';
import RouteAddressReducer from './RouteAddressReducer';
import routeCostFeeReducer from './routeCostFee';
import routerReducer from './router';
import safeLoadOfSemiTrailerReducer from './safeLoadOfSemiTrailer';
import ScriptToolReducer from './ScriptToolReducer';
import seaNormManagement from './seaNormManagement';
import settingInfo from './settingInfo';
import settlementFollowCustomerReducers from './settlementFollowCustomer';
import settlementOrOderReducers from './settlementOrOrderManagement';
import setupDialogFormularReducer from './setupDialogFormularReducer';
import setupDialogReducer from './setupDialogReducer';
import shippingLineReducers from './shippingLine';
import ShippingListReportReducer from './ShippingListReportReducer';
import standardOilManagementReducers from './standardOilManagement';
import statusInfoReducer from './statusinfo';
import TemplateReducer from './TemplateReducer';
import ThemeOptionsReducer from './ThemeOptionsReducer';
// chấm công
import TimeSheetReducer from './TimeSheetReducer';
import transportationPlanReducer from './transportationplan';
import transportCompanyManagemenReducers from './transportCompanyManagement';
import transportCompanyReducer from './transportCompanyReducer';
import transportCostReducers from './transportCost';
import TransportCostNewReducer from './TransportCostNewReducer';
import transportCostReality from './transportCostReality';
import transportFeePhaseReducer from './transportFeePhaseReducer';
import truckingFeeReducer from './truckingfee';
import truckingFeeSummary from './TruckingFeeSummary';
import truckingTypeReducer from './truckingType';
import unitTypeReducer from './unittype';
import unitPaymentReducer from './unitTypePayment';
import UserManagementReducer from './userManagementReducer';
import UserManualReducer from './UserManualReducer';
import utilsReducer from './utils';
import vehicleCategoriesReducer from './vehicleCategoryReducer';
import vehicleInfoManagementReducer from './vehicleInfoManagementReducer';
import warehouseExportReducer from './warehouseExport';
import warehouseInfoReducers from './warehouseInfo';
// Phân công cong việc START
import WorkGroupReducer from './WorkGroupReducer';
import WorkingApprovalStatusReducer from './WorkingApprovalStatusReducer';
import WorkingFileReducer from './WorkingFileReducer';
import WorkingHistoryDetailReducer from './WorkingHistoryDetailReducer';
import WorkingHistoryReducer from './WorkingHistoryReducer';
import WorkingPriorityReducer from './WorkingPriorityReducer';
import WorkingReducer from './WorkingReducer';
import WorkingStatusReducer from './WorkingStatusReducer';
import WorkingTypeReducer from './WorkingTypeReducer';

const settingInfoPersistConfig = {
  key: 'settingInfoStore',
  storage: localforage,
  whitelist: ['dataItems']
};

const reducers = {
  commonStore: CommonReducer,
  form: formReducer,
  authStore: authReducer,
  userStore: UserManagementReducer,
  groupStore: GroupReducer,
  permissionStore: PermissionReducer,

  routersStore: routerReducer,
  productCategoryStore: productCategoryReducer,
  goodsTypeStore: goodsTypeReducer,
  customFeeStore: customFeeReducer,
  customBranchStore: customBranchReducer,
  unitTypeStore: unitTypeReducer,
  oilPricesManagementStore: oilPricesManagementReducer,
  oilCategoryStore: oilCategoryReducer,
  paymentOnBehaftStore: paymentOnBehaftReducer,
  portDepotStore: portDepotReducer,
  portCategoryStore: portCategoryReducer,
  fillUpFuelManagementStore: fillUpFuelManagementReducer,
  inputFuelManagementStore: inputFuelManagementReducer,
  transportationPlanStore: transportationPlanReducer,
  orderManagementStore: orderManagementReducer,
  scriptToolStore: ScriptToolReducer,
  statusInfoStore: statusInfoReducer,
  containerStore: containerReducer,
  utilsStore: utilsReducer,
  driverPlanStore: driverPlanReducer,
  addDriverPlanStore: addDriverPlanReducer,
  truckingFeeStore: truckingFeeReducer,

  vehicleInfoStore: vehicleInfoManagementReducer,
  vehicleCategoriesStore: vehicleCategoriesReducer,
  moocStore: MoocReducer,
  transportCompanyStore: transportCompanyReducer,
  RoadFeeStore: RoadFeeReducer,
  RepairStore: RepairManagementReducer,
  SparePartStore: SparePartManagementReducer,
  ImportSparePartStore: ImportSparePartReducer,
  operationStatusStore: operationStatusReducer,
  departmentStore: DepartmentInfoReducer,
  RepairInvoiceDetailsStore: RepairInvoiceDetailsReducer,
  EquipmentCategoryStore: EquipmentCategoryReducer,

  employeesReducer: employeesReducer,
  depositShellReducers: depositShellReducers,
  shippingLineReducers: shippingLineReducers,
  warehouseInfoReducers: warehouseInfoReducers,
  oilNormReducers: oilNormReducers,
  transportCostReducers: transportCostReducers,
  downPaymentOrder: downPaymentOrder,
  addressReducers: addressReducers,
  customerStore: customerReducer,
  settlementOrOderReducers: settlementOrOderReducers,
  downPaymentSellReducers: downPaymentSellReducers,
  downPaymentSellDetailReducers: downPaymentSellDetailReducers,
  settlementFollowCustomerReducers: settlementFollowCustomerReducers,
  downPaymentOrderAccountantReducers: downPaymentOrderAccountantReducers,
  settlementFollowCustomerActReducers: settlementFollowCustomerActReducers,
  //   statusAccountingReducers: statusAccountingReducers,

  setupDialogStore: setupDialogReducer,
  setupDialogFormularStore: setupDialogFormularReducer,
  revenueCostFollowCarStore: RevenueCostFollowCarReducer,
  reportStore: ReportReducer,
  revenueByCustomerReducers: revenueByCustomerReducers,
  fuelUsageStore: fuelUsageReducers,
  navStore: navReducer,
  unitPaymentStore: unitPaymentReducer,
  transportCostRealityStore: transportCostReality,
  settingInfoStore: persistReducer(settingInfoPersistConfig, settingInfo),
  settlementRepairStore: settlementRepair,
  accountingListRepairStore: accountingListRepair,

  addressStore: RouteAddressReducer,
  wareHouseReportReducers: wareHouseReportReducers,
  dashboardStore: dashboardReducer,
  expirationDateReducers: expirationDateReducers,
  transportCompanyManagemenStore: transportCompanyManagemenReducers,
  fieldSettingStore: FieldSettingReducer,
  standardOilManagementStore: standardOilManagementReducers,
  themeOptionsStore: ThemeOptionsReducer,
  driverManyPlanReducers: driverManyPlan,
  changePasswordStore: changePasswordReducer,
  driverPlanMobileStore: driverPlanForMobile,
  RepairMobileStore: RepairReducerForMobile,
  repairReportStore: repairReport,
  listPaymentOrderStore: listPaymentOrder,
  consignmentReportStore: consignmentReport,
  truckingTypeStore: truckingTypeReducer,
  normSettingStore: normSetting,
  warehouseExportStore: warehouseExportReducer,
  annualFeesStore: annualFeesReducer,
  feeCategoriesStore: feeCategoriesReducer,
  routeCostFeeStore: routeCostFeeReducer,
  sparepartDetailStore: sparepartDetailReducer,
  supplierStore: supplierReducer,
  truckingFeeSummaryStore: truckingFeeSummary,
  importRepairPaymentReportStore: importRepairPaymentReport,
  deliveryDetailReportStore: deliveryDetailReport,
  businessResultReportStore: BusinessResultReportReducer,
  driverPlanCostStore: DriverPlanCostReducer,
  advanceManagementStore: advanceManagement,
  notificationStore: notification,
  templateStore: TemplateReducer,
  driverAdvanceManagementStore: driverAdvanceManagement,
  CustomerServiceReducer: CustomerServiceReducer,
  ShippingListReportStore: ShippingListReportReducer,
  // Phân công công việc START
  workingHistoryDetailStore: WorkingHistoryDetailReducer,
  workGroupStore: WorkGroupReducer,
  workingStore: WorkingReducer,
  workingApprovalStatusStore: WorkingApprovalStatusReducer,
  workingFileStore: WorkingFileReducer,
  workingHistoryStore: WorkingHistoryReducer,
  workingPriorityStore: WorkingPriorityReducer,
  workingStatusStore: WorkingStatusReducer,
  workingTypeStore: WorkingTypeReducer,
  // Phân công công việc END
  aprrovalStore: AprrovalReducer,
  driverSalaryStore: DriverSalaryInfo,
  safeLoadOfSemiTrailerStore: safeLoadOfSemiTrailerReducer,
  quotationStore: QuotationReducer,
  quotationDetailStore: QuotationDetailReducer,
  dailyTransportPlanStore: DailyTransportPlanReducer,
  HistoryStore: HistoryReducer,
  PermissionGroupByCustomerStore: PermissionGroupByCustomerReducer,
  customerPermissionTypeStore: CustomerPermissionTypeReducer,
  TransportCostNewStore: TransportCostNewReducer,
  EmailStore: EmailReducer,
  UserManualStore: UserManualReducer,
  mapStore: mapReducer,
  headerStore: headerReducer,
  outSourceTransportFeeStore: outSourceTransportFeeReducer,
  RepairFeeNormReducerStore: RepairFeeNormReducer,
  TimeSheetReducer: TimeSheetReducer,
  shippingManagementStore: shippingManagement,
  seaNormManagementStore: seaNormManagement,
  excelStore: excelStore,
  HistoryEditStore: History,
  TransportFeePhaseStore: transportFeePhaseReducer,
  CustomerSettingStore: CustomerSetting,
  Debt2Store: Debt2Reducer,
  CarHistoryStore: CarHistory,
  BackupRestoreStore: backupRestore,
  ProfitByVehicleStore: ProfitByVehicleReducer,
  CustomerOutputStore: CustomerOutputReducer,
  NormManagementStore: NormManagementReducer
};

const reducer = combineReducers(reducers);
// export default rootReducer;
export default function root(state, action) {
  /*if (action.type === ADD_USER && state.userReducers.byId[action.userId].inventory <= 0)
                      return state*/
  return reducer(state, action);
}
