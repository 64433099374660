import {
    CHANGE_PERMISSION_FIELD,
    CHANGE_PERMISSION_FIELD_FULFILLED,
    CHANGE_PERMISSION_FIELD_PENDING,
    CHANGE_PERMISSION_FIELD_REJECTED,
    CHANGE_PERMISSION_ROW,
    CHANGE_PERMISSION_ROW_FULFILLED,
    CHANGE_PERMISSION_ROW_PENDING,
    CHANGE_PERMISSION_ROW_REJECTED
} from "actions/groupAction";
import { call, put, takeLatest } from "redux-saga/effects";

// (TTS)LyLV 2020/04/08 Watcher START
export function* watchChangePermission() {
    yield takeLatest(CHANGE_PERMISSION_FIELD, processChangePermission);
}
export function* watchChangePermissionRow() {
    yield takeLatest(CHANGE_PERMISSION_ROW, processChangePermissionRow);
}
// (TTS)LyLV 2020/04/08 Watcher END

// (TTS)LyLV 2020/04/08 SAGA START
// saga worker sigin
function wait(ms) {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    })
}

function* processChangePermission(action) {
    // console.log("auth sagas userSignin");
    yield put({ type: CHANGE_PERMISSION_FIELD_PENDING, payload: action.payload });
    try {
        yield call(wait, 100);
        yield put({ type: CHANGE_PERMISSION_FIELD_FULFILLED, payload: action.payload });
    }
    catch(err){
        yield put({ type: CHANGE_PERMISSION_FIELD_REJECTED, payload: action.payload });
    }
}

function* processChangePermissionRow(action) {
    // console.log("auth sagas userSignin");
    yield put({ type: CHANGE_PERMISSION_ROW_PENDING, payload: action.payload });
    try {
        yield call(wait, 100);
        yield put({ type: CHANGE_PERMISSION_ROW_FULFILLED, payload: action.payload });
    }
    catch(err){
        yield put({ type: CHANGE_PERMISSION_ROW_REJECTED, payload: action.payload });
    }
}


// (TTS)LyLV 2020/04/08 SAGA END