import httpClient from "HttpClient";
export const getDepositShellPerPage = (pageNo, pageSize) => {
  return httpClient.get(
    `/ContainerDepositInfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`
  );
};

export const deleteItem = (id) => {
  return httpClient.delete(`/ContainerDepositInfo/delete/${id}`);
};
