import { DEPOSIT_SHELL_ACTIONS } from "actions/depositShell";
import { put, takeEvery } from "redux-saga/effects";
import { getDepositShellPerPage } from "services/depositShell";

function* putActionUpdateData() {
  yield put({
    type: DEPOSIT_SHELL_ACTIONS.GET_DEPOSIT_SHELL_PER_PAGE,
    payload: getDepositShellPerPage(
      1,
      parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE)
    ),
  });
}

export function* actionUpdateDepositShellData() {
  yield takeEvery(
    [
      DEPOSIT_SHELL_ACTIONS.UPDATE_DEPOSIT_SHELL_FULFILLED,
      DEPOSIT_SHELL_ACTIONS.CREATE_DEPOSIT_SHELL_FULFILLED,
      //   DEPOSIT_SHELL_ACTIONS.DELETE_DEPOSIT_SHELL_FULFILLED,
    ],
    putActionUpdateData
  );
}
