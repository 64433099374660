import { SHIPPING_MANAGEMENT_ACTIONS } from 'actions/report/shippingmanagement';

const INIT_STATE = {
  listPerPage: [],
  message: '',
  isSearch: false,
  pagination: {},
  works: []
};

function updateDataRealTransportFeePrice(value, contId, shipmentId, list) {
  const updateList = [...list];
  const index = list.findIndex((e) => e.Id === shipmentId);
  const contIndex = list[index].ConsignmentContainer.findIndex((e) => e.Id === contId);
  updateList[index].ConsignmentContainer[contIndex].RealTransportFeePrice = value;
  updateList[index].IsUpdated = true;
  return updateList;
}

function updateDataContractTransportFeePrice(value, contId, shipmentId, list) {
  const updateList = [...list];
  const index = list.findIndex((e) => e.Id === shipmentId);
  const contIndex = list[index].ConsignmentContainer.findIndex((e) => e.Id === contId);
  updateList[index].ConsignmentContainer[contIndex].ContractTransportFeePrice = value;
  updateList[index].IsUpdated = true;
  return updateList;
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHIPPING_MANAGEMENT_ACTIONS.GET_SHIPPING_MANAGEMENT_PENDING:
      return {
        ...state
      };
    case SHIPPING_MANAGEMENT_ACTIONS.GET_SHIPPING_MANAGEMENT_FULFILLED:
      return {
        ...state,
        listPerPage: action.payload.data.data,
        pagination: action.payload.data.pagination
      };
    case SHIPPING_MANAGEMENT_ACTIONS.GET_SHIPPING_MANAGEMENT_REJECTED:
      return {
        ...state,
        message: 'Không thể lấy được dữ liệu!'
      };

    case SHIPPING_MANAGEMENT_ACTIONS.CREATE_SHIPPING_MANAGEMENT_PENDING:
      return {
        ...state
      };
    case SHIPPING_MANAGEMENT_ACTIONS.CREATE_SHIPPING_MANAGEMENT_FULFILLED:
      return {
        ...state,
        message: 'Thêm mới dữ liệu thành công!'
      };
    case SHIPPING_MANAGEMENT_ACTIONS.CREATE_SHIPPING_MANAGEMENT_REJECTED:
      return {
        ...state,
        message: 'Không thể thêm mới dữ liệu!'
      };

    case SHIPPING_MANAGEMENT_ACTIONS.UPDATE_SHIPPING_MANAGEMENT_PENDING:
      return {
        ...state
      };
    case SHIPPING_MANAGEMENT_ACTIONS.UPDATE_SHIPPING_MANAGEMENT_FULFILLED:
      return {
        ...state,
        message: 'Thêm mới dữ liệu thành công!'
      };
    case SHIPPING_MANAGEMENT_ACTIONS.UPDATE_SHIPPING_MANAGEMENT_REJECTED:
      return {
        ...state,
        message: 'Không thể thêm mới dữ liệu!'
      };

    case SHIPPING_MANAGEMENT_ACTIONS.DELETE_SHIPPING_MANAGEMENT_PENDING:
      return {
        ...state
      };
    case SHIPPING_MANAGEMENT_ACTIONS.DELETE_SHIPPING_MANAGEMENT_FULFILLED:
      return {
        ...state,
        message: 'Xóa dữ liệu thành công!'
      };
    case SHIPPING_MANAGEMENT_ACTIONS.DELETE_SHIPPING_MANAGEMENT_REJECTED:
      return {
        ...state,
        message: 'Không thể xóa dữ liệu!'
      };

    case SHIPPING_MANAGEMENT_ACTIONS.CHANGE_REAL_TRANSPORT_FEE_PRICE: {
      const { value, contId, shipmentId, list } = action.payload;
      const updatedList = updateDataRealTransportFeePrice(value, contId, shipmentId, list);
      return {
        ...state,
        listPerPage: updatedList
      };
    }

    case SHIPPING_MANAGEMENT_ACTIONS.CHANGE_CONTRACT_TRANSPORT_FEE_PRICE: {
      const { value, contId, shipmentId, list } = action.payload;
      const updatedList = updateDataContractTransportFeePrice(value, contId, shipmentId, list);
      return {
        ...state,
        listPerPage: updatedList
      };
    }

    default:
      return state;
  }
};
