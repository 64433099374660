import {
  getAllVehicleCategory
} from "services/vehiclecategory";

// define const actions
export const VEHICLE_CATEGORY_ACTIONS = {
  GET_ALL_VEHICLE_CATEGORY: "GET_ALL_VEHICLE_CATEGORY",
  GET_ALL_VEHICLE_CATEGORY_PENDING: "GET_ALL_VEHICLE_CATEGORY_PENDING",
  GET_ALL_VEHICLE_CATEGORY_FULFILLED: "GET_ALL_VEHICLE_CATEGORY_FULFILLED",
  GET_ALL_VEHICLE_CATEGORY_REJECTED: "GET_ALL_VEHICLE_CATEGORY_REJECTED",
};


// define actions
export const actionGetAllVehicleCategory = () => dispatch => {
  return dispatch({
    type: VEHICLE_CATEGORY_ACTIONS.GET_ALL_VEHICLE_CATEGORY,
    payload: getAllVehicleCategory()
  });
};
