import api from "HttpClient";

//Add new group action

export const FETCH_GROUPS_ALL = 'FETCH_GROUPS_ALL';
export const FETCH_GROUPS_ALL_PENDING = 'FETCH_GROUPS_ALL_PENDING';
export const FETCH_GROUPS_ALL_FULFILLED = 'FETCH_GROUPS_ALL_FULFILLED';
export const FETCH_GROUPS_ALL_REJECTED = 'FETCH_GROUPS_ALL_REJECTED';
export const fetchGroupsAll = () => (dispatch) => {
    return dispatch({
        type: FETCH_GROUPS_ALL,
        payload: api.get(`/GroupInfo/getAll`)
    });
};

export const NEW_GROUP = 'NEW_GROUP';
export const newGroup = () => (dispatch) => {
    return dispatch({
        type: NEW_GROUP
    });
};

export const FETCH_GROUPS = 'FETCH_GROUPS';
export const FETCH_GROUPS_PENDING = 'FETCH_GROUPS_PENDING';
export const FETCH_GROUPS_FULFILLED = 'FETCH_GROUPS_FULFILLED';
export const FETCH_GROUPS_REJECTED = 'FETCH_GROUPS_REJECTED';
export const fetchGroups = (page, size) => (dispatch) => {
    return dispatch({
        type: FETCH_GROUPS,
        payload: api.get(`/GroupInfo/paging/page?page=${page}&pageSize=${size}`)
    });
};

// const type of function create data
export const CREATE_GROUP = "CREATE_GROUP";
export const CREATE_GROUP_PENDING = "CREATE_GROUP_PENDING";
export const CREATE_GROUP_FULFILLED = "CREATE_GROUP_FULFILLED";
export const CREATE_GROUP_REJECTED = "CREATE_GROUP_REJECTED";
export const createGroup = payload => dispatch => {
    return dispatch({
        type: CREATE_GROUP,
        payload: api.post(`/GroupInfo/create`, payload)
    });
};

// const type of function update data
export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_GROUP_PENDING = "UPDATE_GROUP_PENDING";
export const UPDATE_GROUP_FULFILLED = "UPDATE_GROUP_FULFILLED";
export const UPDATE_GROUP_REJECTED = "UPDATE_GROUP_REJECTED";
export const updateGroup = payload => dispatch => {
    return dispatch({
        type: UPDATE_GROUP,
        payload: api.put(`/GroupInfo/update`, payload)
    });
};

// const type of delete
export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_PENDING = "DELETE_GROUP_PENDING";
export const DELETE_GROUP_FULFILLED = "DELETE_GROUP_FULFILLED";
export const DELETE_GROUP_REJECTED = "DELETE_GROUP_REJECTED";
export const deleteGroup = id => dispatch => {
    return dispatch({
        type: DELETE_GROUP,
        payload: api.delete(`/GroupInfo/deleteById/${id}`)
    });
};

// const type of delete
export const FETCH_GROUP = "FETCH_GROUP";
export const FETCH_GROUP_PENDING = "FETCH_GROUP_PENDING";
export const FETCH_GROUP_FULFILLED = "FETCH_GROUP_FULFILLED";
export const FETCH_GROUP_REJECTED = "FETCH_GROUP_REJECTED";
export const fetchGroup = id => dispatch => {
    return dispatch({
        type: FETCH_GROUP,
        payload: api.get(`/GroupInfo/getById/${id}`)
    });
};

export const FETCH_GROUP_USERS = 'FETCH_GROUP_USERS';
export const FETCH_GROUP_USERS_PENDING = 'FETCH_GROUP_USERS_PENDING';
export const FETCH_GROUP_USERS_FULFILLED = 'FETCH_GROUP_USERS_FULFILLED';
export const FETCH_GROUP_USERS_REJECTED = 'FETCH_GROUP_USERS_REJECTED';
export const fetchGroupUsers = (groupId, page, size) => (dispatch) => {
    return dispatch({
        type: FETCH_GROUP_USERS,
        payload: api.get(`/User/getUsersByGroupId?groupId=${groupId}&page=${page}&pageSize=${size}`)
    });
};

export const FETCH_USERS_NOT_IN_GROUP = 'FETCH_USERS_NOT_IN_GROUP';
export const FETCH_USERS_NOT_IN_GROUP_PENDING = 'FETCH_USERS_NOT_IN_GROUP_PENDING';
export const FETCH_USERS_NOT_IN_GROUP_FULFILLED = 'FETCH_USERS_NOT_IN_GROUP_FULFILLED';
export const FETCH_USERS_NOT_IN_GROUP_REJECTED = 'FETCH_USERS_NOT_IN_GROUP_REJECTED';
export const fetchUsersNotInGroup = (groupId, page, size) => (dispatch) => {
    return dispatch({
        type: FETCH_USERS_NOT_IN_GROUP,
        payload: api.get(`/GroupInfo/getExceptGroupUser?groupId=${groupId}&page=${page}&pageSize=${size}`)
    });
};

export const ADD_USERS_TO_GROUP = 'ADD_USERS_TO_GROUP';
export const ADD_USERS_TO_GROUP_PENDING = 'ADD_USERS_TO_GROUP_PENDING';
export const ADD_USERS_TO_GROUP_FULFILLED = 'ADD_USERS_TO_GROUP_FULFILLED';
export const ADD_USERS_TO_GROUP_REJECTED = 'ADD_USERS_TO_GROUP_REJECTED';
export const addUsersToGroup = (groupUsers) => (dispatch) => {
    return dispatch({
        type: ADD_USERS_TO_GROUP,
        payload: Promise.all(groupUsers.map(groupUser => api.post(`/GroupInfo/create/user`, groupUser)))
    });
};

export const REMOVE_USER_TO_GROUP = 'REMOVE_USER_TO_GROUP';
export const REMOVE_USER_TO_GROUP_PENDING = 'REMOVE_USER_TO_GROUP_PENDING';
export const REMOVE_USER_TO_GROUP_FULFILLED = 'REMOVE_USER_TO_GROUP_FULFILLED';
export const REMOVE_USER_TO_GROUP_REJECTED = 'REMOVE_USER_TO_GROUP_REJECTED';
export const removeUserFromGroup = (groupId, userId) => (dispatch) => {
    return dispatch({
        type: REMOVE_USER_TO_GROUP,
        payload: api.delete(`/GroupInfo/delete/user/${groupId}/${userId}`)
    });
};

export const FETCH_GROUP_PERMISSIONS = 'FETCH_GROUP_PERMISSIONS';
export const FETCH_GROUP_PERMISSIONS_PENDING = 'FETCH_GROUP_PERMISSIONS_PENDING';
export const FETCH_GROUP_PERMISSIONS_FULFILLED = 'FETCH_GROUP_PERMISSIONS_FULFILLED';
export const FETCH_GROUP_PERMISSIONS_REJECTED = 'FETCH_GROUP_PERMISSIONS_REJECTED';
export const fetchGroupPermissions = (groupId) => (dispatch) => {
    return dispatch({
        type: FETCH_GROUP_PERMISSIONS,
        payload: api.get(`/Permission/getTreeByGroupId/${groupId}`)
    });
};

export const FILTER_PERMISSION = 'FILTER_PERMISSION';
export const filterPermission = (permission) => (dispatch) => {
    return dispatch({
        type: FILTER_PERMISSION,
        payload: permission
    });
};

export const CHANGE_PERMISSION_FIELD = 'CHANGE_PERMISSION_FIELD';
export const CHANGE_PERMISSION_FIELD_PENDING = 'CHANGE_PERMISSION_FIELD_PENDING';
export const CHANGE_PERMISSION_FIELD_FULFILLED = 'CHANGE_PERMISSION_FIELD_FULFILLED';
export const CHANGE_PERMISSION_FIELD_REJECTED = 'CHANGE_PERMISSION_FIELD_REJECTED';
export const changePermission = (checked, permission, fieldName) => (dispatch) => {
    return dispatch({
        type: CHANGE_PERMISSION_FIELD,
        payload: {checked, permission, fieldName}
    });
};

export const CHANGE_PERMISSION_ROW = 'CHANGE_PERMISSION_ROW';
export const CHANGE_PERMISSION_ROW_PENDING = 'CHANGE_PERMISSION_ROW_PENDING';
export const CHANGE_PERMISSION_ROW_FULFILLED = 'CHANGE_PERMISSION_ROW_FULFILLED';
export const CHANGE_PERMISSION_ROW_REJECTED = 'CHANGE_PERMISSION_ROW_REJECTED';
export const changePermissionRow = (checked, permission) => (dispatch) => {
    return dispatch({
        type: CHANGE_PERMISSION_ROW,
        payload: {checked, permission}
    });
};

export const UPDATE_DIRTY_PERMISSION = 'UPDATE_DIRTY_PERMISSION';
export const UPDATE_DIRTY_PERMISSION_PENDING = 'UPDATE_DIRTY_PERMISSION_PENDING';
export const UPDATE_DIRTY_PERMISSION_FULFILLED = 'UPDATE_DIRTY_PERMISSION_FULFILLED';
export const UPDATE_DIRTY_PERMISSION_REJECTED = 'UPDATE_DIRTY_PERMISSION_REJECTED';
export const updateDirtyPermission = (permissions) => (dispatch) => {
    const permissionsLength = permissions.length;
    let updatePermissionsPromise = [];
    const updateUrl = `/permission/update`;
    for (let i = 0; i < permissionsLength; i += 10) {
        const slicedPermissions = permissions.slice(i, i + 10);
        updatePermissionsPromise = [...updatePermissionsPromise, api.put(updateUrl, slicedPermissions)]
    }
    return dispatch({
        type: UPDATE_DIRTY_PERMISSION,
        payload: Promise.all(updatePermissionsPromise)
    });
};

export const UPDATE_TREE_PERMISSION = 'UPDATE_TREE_PERMISSION';
export const UPDATE_TREE_PERMISSION_PENDING = 'UPDATE_TREE_PERMISSION_PENDING';
export const UPDATE_TREE_PERMISSION_FULFILLED = 'UPDATE_TREE_PERMISSION_FULFILLED';
export const UPDATE_TREE_PERMISSION_REJECTED = 'UPDATE_TREE_PERMISSION_REJECTED';
export const updateTreePermission = (treePermission) => (dispatch) => {
    const updateUrl = `/permission/update`;
    return dispatch({
        type: UPDATE_TREE_PERMISSION,
        payload: api.put(updateUrl, treePermission)
    });
};