const { HISTORY } = require('actions/History');

const INIT_STATE = {
  dataHistory: []
};

const History = (state = INIT_STATE, action) => {
  switch (action.type) {
    case HISTORY.GET_HISTORY_FULFILLED:
      // eslint-disable-next-line no-case-declarations
      const data = action?.payload?.data?.data.map((item) => ({
        ...item,
        NewJson: JSON.parse(item.NewJson),
        OldJson: JSON.parse(item.OldJson)
      }));
      return {
        ...state,
        dataHistory: data
      };
    default:
      return state;
  }
};

export default History;
