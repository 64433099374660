import httpClient from 'HttpClient';
import { deleteItem } from 'services/warehouseInfo';
// define const actions
export const WAREHOUSE_INFO_ACTIONS = {
  GET_WAREHOUSE_INFO_PER_PAGE: 'GET_WAREHOUSE_INFO_PER_PAGE',
  GET_WAREHOUSE_INFO_PER_PAGE_PENDING: 'GET_WAREHOUSE_INFO_PER_PAGE_PENDING',
  GET_WAREHOUSE_INFO_PER_PAGE_FULFILLED: 'GET_WAREHOUSE_INFO_PER_PAGE_FULFILLED',
  GET_WAREHOUSE_INFO_PER_PAGE_REJECTED: 'GET_WAREHOUSE_INFO_PER_PAGE_REJECTED',

  GET_WAREHOUSE_INFO_BY_ID: 'GET_WAREHOUSE_INFO_BY_ID',
  GET_WAREHOUSE_INFO_BY_ID_PENDING: 'GET_WAREHOUSE_INFO_BY_ID_PENDING',
  GET_WAREHOUSE_INFO_BY_ID_FULFILLED: 'GET_WAREHOUSE_INFO_BY_ID_FULFILLED',
  GET_WAREHOUSE_INFO_BY_ID_REJECTED: 'GET_WAREHOUSE_INFO_BY_ID_REJECTED',

  CREATE_WAREHOUSE_INFO: 'CREATE_WAREHOUSE_INFO',
  CREATE_WAREHOUSE_INFO_PENDING: 'CREATE_WAREHOUSE_INFO_PENDING',
  CREATE_WAREHOUSE_INFO_FULFILLED: 'CREATE_WAREHOUSE_INFO_FULFILLED',
  CREATE_WAREHOUSE_INFO_REJECTED: 'CREATE_WAREHOUSE_INFO_REJECTED',

  UPDATE_WAREHOUSE_INFO: 'UPDATE_WAREHOUSE_INFO',
  UPDATE_WAREHOUSE_INFO_PENDING: 'UPDATE_WAREHOUSE_INFO_PENDING',
  UPDATE_WAREHOUSE_INFO_FULFILLED: 'UPDATE_WAREHOUSE_INFO_FULFILLED',
  UPDATE_WAREHOUSE_INFO_REJECTED: 'UPDATE_WAREHOUSE_INFO_REJECTED',

  DELETE_WAREHOUSE_INFO: 'DELETE_WAREHOUSE_INFO',
  DELETE_WAREHOUSE_INFO_PENDING: 'DELETE_WAREHOUSE_INFO_PENDING',
  DELETE_WAREHOUSE_INFO_FULFILLED: 'DELETE_WAREHOUSE_INFO_FULFILLED',
  DELETE_WAREHOUSE_INFO_REJECTED: 'DELETE_WAREHOUSE_INFO_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  GET_CUSTOM: 'GET_CUSTOM',
  GET_CUSTOM_FULFILLED: 'GET_CUSTOM_FULFILLED',

  GET_PORT_CATEGORY: 'GET_PORT_CATEGORY',
  GET_PORT_CATEGORY_FULFILLED: 'GET_PORT_CATEGORY_FULFILLED',

  GET_ALL_WAREHOUSE_INFO: 'GET_ALL_WAREHOUSE_INFO',
  GET_ALL_WAREHOUSE_INFO_FULFILLED: 'GET_ALL_WAREHOUSE_INFO_FULFILLED'
};

// define actions
export const actionGetWarehouseInfoPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: WAREHOUSE_INFO_ACTIONS.GET_WAREHOUSE_INFO_PER_PAGE,
    payload: httpClient.get(`/PortDepotInfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`)
  });
};

export const actionGetWarehouseInfoByID = (id) => (dispatch) => {
  return dispatch({
    type: WAREHOUSE_INFO_ACTIONS.GET_WAREHOUSE_INFO_BY_ID,
    payload: httpClient.get(`/PortDepotInfo/getById/${id}`)
  });
};

export const actionCreateWarehouseInfo = (payload) => (dispatch) => {
  return dispatch({
    type: WAREHOUSE_INFO_ACTIONS.CREATE_WAREHOUSE_INFO,
    payload: httpClient.post(`/PortDepotInfo/create`, payload)
  });
};

export const actionUpdateWarehouseInfo = (payload) => (dispatch) => {
  return dispatch({
    type: WAREHOUSE_INFO_ACTIONS.UPDATE_WAREHOUSE_INFO,
    payload: httpClient.put(`/PortDepotInfo/update`, payload)
  });
};

export const actionDeleteWarehouseInfo = (id) => (dispatch) => {
  return dispatch({
    type: WAREHOUSE_INFO_ACTIONS.DELETE_WAREHOUSE_INFO,
    payload: deleteItem(id)
  });
};

export const getAllPortCategory = () => (dispatch) => {
  return dispatch({
    type: WAREHOUSE_INFO_ACTIONS.GET_PORT_CATEGORY,
    payload: httpClient.get(`/PortCategoryInfo/getAll`)
  });
};

export const getCustomArea = () => (dispatch) => {
  return dispatch({
    type: WAREHOUSE_INFO_ACTIONS.GET_CUSTOM,
    payload: httpClient.get(`/CustomBranchInfo/getAll`)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const actionSearchWarehouseInfo = (params, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: WAREHOUSE_INFO_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/PortDepotInfo/search`, { params: { ...params, Page: pageNo, pageSize } })
  });
};
export const actionSearchWarehouseInfoAll = (params) => (dispatch) => {
  return dispatch({
    // type: WAREHOUSE_INFO_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/PortDepotInfo/search`, { params })
  });
};

export const getAll = () => (dispatch) => {
  return dispatch({
    type: WAREHOUSE_INFO_ACTIONS.GET_ALL_WAREHOUSE_INFO,
    payload: httpClient.get(`/PortDepotInfo/getAll`)
  });
};
