import {
  COMPLETED_COLOR,
  CONFIRM_COLOR,
  DANGER_COLOR,
  PRIMARY_COLOR,
  PRINT_STANDARD_OIL_SUCCESS,
  RETURN_YARD_COLOR,
  SECONDARY_COLOR,
  TAKE_THE_SHELL_COLOR,
  WARNING_COLOR
} from 'const/colors';

export const API_METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

export const SCREEN_NAME = {
  TRANSPORT_PLAN: {
    key: 'TransportationPlan',
    value: 'Danh sách lô hàng'
  },
  DRIVER_PLAN: {
    key: 'DriverPlan',
    value: 'Kế hoạch điều xe'
  },
  DRIVER_PLAN_COST: {
    key: 'DriverPlanCost',
    value: 'Kế hoạch điều xe'
  },
  DRIVER_PLAN_COST_LUONG_GA: {
    key: 'DriverPlanCostLuongGa',
    value: 'Kế hoạch điều xe ghép'
  },
  ORDER_MANAGEMENT_INPUT: {
    key: 'OrderManagementInput',
    value: 'Thông tin lô hàng đủ'
  },
  ORDER_MANAGEMENT_OUTPUT: {
    key: 'OrderManagementOutput',
    value: 'Thông tin lô hàng xuất'
  },
  ORDER_MANAGEMENT_RETAIL: {
    key: 'OrderManagementRetail',
    value: 'Thông tin quản lý lô hàng khác'
  },
  FILL_UP_FUEL_MANAGEMENT: {
    key: 'FillUpFuelManagement',
    value: 'Thông tin quản lý dầu cấp cho xe'
  },

  TRANSPORTATION_PLAN_COST_MANAGEMENT: {
    key: 'TransportationPlanCostManagement',
    value: 'Thông tin cước vận chuyển '
  },
  DOWN_PAYMENT_OF_ORDER: {
    key: 'DownPaymentOfOrder',
    value: 'Quyết toán nội bộ'
  },
  SPAREPART_DETAIL_MANAGEMENT: {
    key: 'SparepartDetail',
    value: 'Thông tin chi tiết phụ tùng '
  },
  ANNUAL_FEES: {
    key: 'AnnualFees',
    value: 'Quản lý phí thường niên'
  },
  WAREHOUSE_INFO_MANAGEMENT: {
    key: 'WarehouseInfoManagement',
    value: 'Quản lý kho bãi cảng , depot'
  },
  SHIPPING_LINE_MANAGEMENT: {
    key: 'ShippingLineManagement',
    value: 'Quản lý hãng tàu'
  },
  PRODUCT_MANAGEMENT: {
    key: 'ProductManagement',
    value: 'Quản lý hàng hóa'
  },
  DEPOSIT_SHELL_MANAGEMENT: {
    key: 'DepositShellManagement',
    value: 'Quản lý giá cược vỏ'
  },
  OIL_PRICE_MANAGEMENT: {
    key: 'OilPriceManagement',
    value: 'Quản lý giá xăng dầu'
  },
  TRANSPORT_COMPANY_INFO_MANAGEMENT: {
    key: 'TransportCompanyInfoManagement',
    value: 'Quản lý thông tin công ty vận tải'
  },
  SETTLEMENT_FOLLOW_CUSTOMNER: {
    key: 'SettlementFollowCustomer',
    value: 'Quản lý quyết toán khách hàng'
  },
  ACCESSORY_MANAGEMENT: {
    key: 'AccessoryManagement',
    value: 'Quản lý phụ tùng'
  },
  TRANSPORTATION_PLAN_COST_MANAGEMENT_INTEGRATION: {
    key: 'TransportationPlanCostManagementIntegration',
    value: 'Quản lý cước vận chuyển'
  },
  TRANSPORTATION_PLAN_COST_TRUCKING_FEE: {
    key: 'TransportationPlanCostTruckingFee',
    value: 'Quản lý cước vận chuyển'
  },
  TRANSPORTATION_PLAN_COST_TRAVEL_FEE: {
    key: 'TransportationPlanCostTravelFee',
    value: 'Quản lý chi phí vận chuyển'
  },
  CUSTOMER_SERVICER: {
    key: 'CustomerServices',
    value: 'Dịch vụ khách hàng'
  },
  NOTIFICATIONMENT: {
    key: 'Notification',
    value: 'Quản lý thông báo'
  },
  // Phân công công việc START
  WORK_GROUP_LIST: {
    key: 'WorkGroupManagement',
    value: 'nhóm công việc'
  },
  WORKING_LIST: {
    key: 'WorkingManagement',
    value: 'công việc'
  },
  WORKING_TYPE_LIST: {
    key: 'WorkingTypeManagement',
    value: 'loại công việc'
  },
  WORKING_APPROVAL_STATUS_LIST: {
    key: 'WorkingApprovalStatusManagement',
    value: 'trạng thái công việc'
  },
  WORKING_FILE_LIST: {
    key: 'WorkingFileManagement',
    value: 'file công việc'
  },
  WORKING_HISTORY_LIST: {
    key: 'WorkingHistoryManagement',
    value: 'lịch sử công việc'
  },
  WORKING_HISTORY_DETAIL_LIST: {
    key: 'WorkingHistoryDetailManagement',
    value: 'chi tiết lịch sử công việc'
  },
  WORKING_PRIORITY_LIST: {
    key: 'WorkingPriorityManagement',
    value: 'độ ưu tiên công việc'
  },
  WORKING_STATUS_LIST: {
    key: 'WorkingStatusManagement',
    value: 'trạng thái công việc'
  },
  // Phân công công việc END
  IMPORT_ACCESSORY__MANAGEMENT_AND_APPROVAL: {
    key: 'ImportAccessoryManagementAndApproval',
    value: 'Quản lý nhập phụ tùng'
  },
  APRROVAL_LIST: {
    key: 'Approval',
    value: 'Quản lý phê duyệt'
  },
  WORK_SETTING_LIST: {
    key: 'WorkSetting',
    value: 'cài đặt'
  },
  SAFE_LOAD_OF_SEMI_TRAILER: {
    key: 'SafeLoadOfSemiTrailer',
    value: 'Báo cáo tải trọng an toàn của đầu kéo'
  },
  SEMI_TRAILER_REPORT: {
    key: 'ReportMooc',
    value: 'Báo cáo đầu kéo'
  },
  QUOTATION_LIST: {
    key: 'Quotation',
    value: 'Báo giá'
  },
  QUOTATION_DETAIL_LIST: {
    key: 'QuotationDetail',
    value: 'Chi tiết báo giá'
  },
  DAILY_TRANSPORT_PLAN_LIST: {
    key: 'DailyTransportPlanManagement',
    value: 'Kế hoạch vận chuyển'
  },
  DAILY_TRANSPORT_PLAN_SHEDULE: {
    key: 'DailyTransportPlanSchedule',
    value: 'Lịch vận chuyển'
  },
  CUSTOMER_PERMISSION_TYPE_LIST: {
    key: 'CustomerPermissionTypeManagement',
    value: 'Quản lý nhóm khách hàng'
  },
  TRANSPORT_COST_NEW_LIST: {
    key: 'TransportCostLuongGa',
    value: 'Quản lý cước vận chuyển'
  },
  TRANSPORT_COST_MANAGEMENT: {
    key: 'TransportCostManagement',
    value: 'Quản lý cước vận chuyển'
  },
  OUT_SOURCE_TRANSPORT_COST_MANAGEMENT: {
    key: 'OutSourceTransportCost',
    value: 'Quản lý cước thuê ngoài'
  },
  SHIPPING_MANAGEMENT: {
    key: 'ShippingManagement',
    value: 'Vận tải biển'
  },
  SHIPPING_LIST_REPORT: {
    key: 'ShippingListReport1',
    value: 'Bảng kê chi tiết vận chuyển'
  },
  EMPLOYEE: {
    key: 'Employee',
    value: 'Quản lý nhân viên'
  },
  CUSTOMER: {
    key: 'Customer',
    value: 'Quản lý đối tác'
  },
  DEBT131: {
    key: 'Debt131',
    value: 'Báo cáo công nợ'
  },
  DEBT331: {
    key: 'Debt331',
    value: 'Báo cáo công nợ'
  },
  DEBT_FUND: {
    key: 'DebtFund',
    value: 'Quỹ'
  },
  DEBT_BANK_INFO: {
    key: 'DebtBankInfo',
    value: 'Quản lý ngân hàng'
  },
  INCURRED_IN_PERIOD: {
    key: 'IncurredInPeriod',
    value: 'Phát sinh trong kì'
  },
  PERIOD_RESIDUAL_DEBT: {
    key: 'PeriodResidualDebt',
    value: 'Thanh toán công nợ'
  },
  PROFIT_BY_VEHICLE: {
    key: 'ProfitByVehicle',
    value: 'Báo cáo Lợi nhuận theo xe'
  },
  CUSTOMER_OUTPUT: {
    key: 'CustomerOutput',
    value: 'Báo cáo sản lượng khách hàng'
  },
  REPAIR_WORK_LIST: {
    key: 'RepairWorkList',
    value: 'Danh sách sửa chữa'
  },
  REPAIR_WORK_LIST_2: {
    key: 'RepairWorkListX2',
    value: 'Danh sách sửa chữa'
  },
  REPAIR_WORK_LIST_3: {
    key: 'RepairWorkListX3',
    value: 'Danh sách sửa chữa'
  },
  NORM_MANAGEMENT: {
    key: 'NormManagement',
    value: 'Quản lý định mức'
  },
  STATISTICS_REPORT: {
    key: 'StatisticsDepartmentReportAntin',
    value: 'Báo cáo bộ phận thống kê'
  }
};

export const UNIT_TYPE_CATEGORY = {
  GOODS: 1,
  SUB_GOODS: 2,
  OIL: 3,
  WEIGHT_UNIT: 4
};

export const STATUS_INFO = {
  CONT: 7,
  CONT_NO: 2,
  REPAIR: 6,
  DRIVER_PLAN: 3,
  CAR: 1,
  RECEIVE_STATUS: 9,
  QUOTATION: 10,
  QUOTATION_DETAIL: 11,
  FEE_FOLLOW_BY_DRIVER: 14
};

export const POST_CATEGORY = {
  PORT: 1,
  DEPORT: 2
};

export const PAGE_SIZE = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE);
export const SHOW_ALERT = 'SHOW_ALERT';
export const UPDATE_TRACKING_FEE = 'UPDATE_TRACKING_FEE';

// function get token
export const getToken = () => {
  return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWRtaW4iLCJlbWFpbCI6ImxhbWRvbmduZDk5QGdtYWlsLmNvbSIsInRyYW5zcG9ydGNvbXBhbnkiOiIxIiwiZ3JvdXBzIjoiMTAwNCIsIm5iZiI6MTU4NzA4NzY2NiwiZXhwIjoxNTg3MTc0MDY2LCJpYXQiOjE1ODcwODc2NjZ9.A0A-4PP9D2rgESwjlxTH-czNa1YsqtEXl80xtAIZUMk';
};

export const ELEMENT_NUMBER_DEFAULT_PER_PAGE = 10;
export const PAGE_NUMBER_DEFAULT = 1;

export const ACCESS_TOKEN = 'access_token';
export const USERNAME = 'username';
export const PASSWORD = 'password';
export const SIGNATURE_IMAGE_URL = 'signatureImageUrl';

export const SECRET_KEY = 'afda0a00b899ad33a026109c6b71c65e';
export const SAVE_PWD = 'SAVE_PWD';
export const COMPANY_CODE = 'companyCode';

export const DISPLAY_NAME = 'displayName';
export const COMPANY_ID = 'companyId';
export const COMPANY_NAME = 'companyName';
export const IS_ADMIN = 'IsAdmin';
export const LICENSE_EXPIRE_DATE = 'licenseExpireDate';
export const USER_ID = 'userId';
export const EMPLOYEE_INFO_ID = 'employeeInfoId';
export const EMPLOYEE_FULL_NAME = 'employeeFullName';
export const SETTING_CONT_MODE_CHECK = 'Custom_Container_Number';

export const TOKEN_EXPIRED_DATE = 'expires';
export const TOKEN_REAL_EXPIRED_DATE = 'expired_date';

export const SESSION_TIMEOUT_SEC = 1800;

export const MSG_TYPE_SUCCESS = 'success';
export const MSG_TYPE_ERROR = 'error';

export const DEFAULT_PAGE_SIZE = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE);

export const USER_STATUS_INACTIVE = 'Đã khóa';
export const USER_STATUS_ACTIVE = 'Đang hoạt động';

export const levelColor = ['#54bde3', '#9be1fa', '#d1e7f0', '#f2faf8'];
export const OPERATED_ITEM_COLOR = '#90EE90';

export const FORMULA_SUM_VALUE = 'sum';
export const FORMULA_AVG_VALUE = 'avg';
export const FORMULA_MAX_VALUE = 'max';
export const FORMULA_MIN_VALUE = 'min';
export const FORMULA_LAST_VALUE = 'last';
export const FORMULA_FIRST_VALUE = 'first';
export const FORMULA_EMPTY_VALUE = 'empty';

export const FORMULA_SUM_LABEL = 'Tổng';
export const FORMULA_AVG_LABEL = 'Trung bình';
export const FORMULA_MAX_LABEL = 'Tối đa';
export const FORMULA_MIN_LABEL = 'Tối thiểu';
export const FORMULA_LAST_LABEL = 'Cuối cùng';
export const FORMULA_FIRST_LABEL = 'Đầu tiên';
export const FORMULA_EMPTY_LABEL = 'Trống';
export const INTERRUPT_API = 'Ngắt API';

export const AGGREGATE_FUNCS = [
  FORMULA_SUM_VALUE,
  FORMULA_AVG_VALUE,
  FORMULA_MAX_VALUE,
  FORMULA_MIN_VALUE,
  FORMULA_LAST_VALUE,
  FORMULA_FIRST_VALUE,
  FORMULA_EMPTY_VALUE
];
export const AGGREGATE_FUNC_NAMES = {
  [FORMULA_SUM_VALUE]: FORMULA_SUM_LABEL,
  [FORMULA_AVG_VALUE]: FORMULA_AVG_LABEL,
  [FORMULA_MAX_VALUE]: FORMULA_MAX_LABEL,
  [FORMULA_MIN_VALUE]: FORMULA_MIN_LABEL,
  [FORMULA_LAST_VALUE]: FORMULA_LAST_LABEL,
  [FORMULA_FIRST_VALUE]: FORMULA_FIRST_LABEL,
  [FORMULA_EMPTY_VALUE]: FORMULA_EMPTY_LABEL
};

export const ALL_FORMULAS_OPTIONS = [
  {
    label: FORMULA_SUM_LABEL,
    value: FORMULA_SUM_VALUE
  },
  {
    label: FORMULA_AVG_LABEL,
    value: FORMULA_AVG_VALUE
  },
  {
    label: FORMULA_MAX_LABEL,
    value: FORMULA_MAX_VALUE
  },
  {
    label: FORMULA_MIN_LABEL,
    value: FORMULA_MIN_VALUE
  },
  {
    label: FORMULA_LAST_LABEL,
    value: FORMULA_LAST_VALUE
  },
  {
    label: FORMULA_FIRST_LABEL,
    value: FORMULA_FIRST_VALUE
  }
];

export const TABLE_HEADER_BACKGROUND_COLOR = '#20A8D8'; //'#20A8D8'; //'#7B7B7B'; //'#8497B0';//'#9BC2E6'; //'#2F75B5';
export const TABLE_HEADER_COLOR = '#ffffff';
export const MIN_TABLE_HEIGHT = 400;

export const EMPTY_SEARCH_RESULT_MSG = 'Không tìm thấy dữ liệu phù hợp theo yêu cầu';
export const EMPTY_PAGING_RESULT_MSG = 'Không có dữ liệu';

export const BROWSER_DEFAULT_DATE_FORMATS = {
  'ar-SA': 'dd/MM/yy',
  'bg-BG': 'dd.M.yyyy',
  'ca-ES': 'dd/MM/yyyy',
  'zh-TW': 'yyyy/M/d',
  'cs-CZ': 'd.M.yyyy',
  'da-DK': 'dd-MM-yyyy',
  'de-DE': 'dd.MM.yyyy',
  'el-GR': 'd/M/yyyy',
  'en-US': 'M/d/yyyy',
  'fi-FI': 'd.M.yyyy',
  'fr-FR': 'dd/MM/yyyy',
  'he-IL': 'dd/MM/yyyy',
  'hu-HU': 'yyyy. MM. dd.',
  'is-IS': 'd.M.yyyy',
  'it-IT': 'dd/MM/yyyy',
  'ja-JP': 'yyyy/MM/dd',
  'ko-KR': 'yyyy-MM-dd',
  'nl-NL': 'd-M-yyyy',
  'nb-NO': 'dd.MM.yyyy',
  'pl-PL': 'yyyy-MM-dd',
  'pt-BR': 'd/M/yyyy',
  'ro-RO': 'dd.MM.yyyy',
  'ru-RU': 'dd.MM.yyyy',
  'hr-HR': 'd.M.yyyy',
  'sk-SK': 'd. M. yyyy',
  'sq-AL': 'yyyy-MM-dd',
  'sv-SE': 'yyyy-MM-dd',
  'th-TH': 'd/M/yyyy',
  'tr-TR': 'dd.MM.yyyy',
  'ur-PK': 'dd/MM/yyyy',
  'id-ID': 'dd/MM/yyyy',
  'uk-UA': 'dd.MM.yyyy',
  'be-BY': 'dd.MM.yyyy',
  'sl-SI': 'd.M.yyyy',
  'et-EE': 'd.MM.yyyy',
  'lv-LV': 'yyyy.MM.dd.',
  'lt-LT': 'yyyy.MM.dd',
  'fa-IR': 'MM/dd/yyyy',
  'vi-VN': 'dd/MM/yyyy',
  'hy-AM': 'dd.MM.yyyy',
  'az-Latn-AZ': 'dd.MM.yyyy',
  'eu-ES': 'yyyy/MM/dd',
  'mk-MK': 'dd.MM.yyyy',
  'af-ZA': 'yyyy/MM/dd',
  'ka-GE': 'dd.MM.yyyy',
  'fo-FO': 'dd-MM-yyyy',
  'hi-IN': 'dd-MM-yyyy',
  'ms-MY': 'dd/MM/yyyy',
  'kk-KZ': 'dd.MM.yyyy',
  'ky-KG': 'dd.MM.yy',
  'sw-KE': 'M/d/yyyy',
  'uz-Latn-UZ': 'dd/MM yyyy',
  'tt-RU': 'dd.MM.yyyy',
  'pa-IN': 'dd-MM-yy',
  'gu-IN': 'dd-MM-yy',
  'ta-IN': 'dd-MM-yyyy',
  'te-IN': 'dd-MM-yy',
  'kn-IN': 'dd-MM-yy',
  'mr-IN': 'dd-MM-yyyy',
  'sa-IN': 'dd-MM-yyyy',
  'mn-MN': 'yy.MM.dd',
  'gl-ES': 'dd/MM/yy',
  'kok-IN': 'dd-MM-yyyy',
  'syr-SY': 'dd/MM/yyyy',
  'dv-MV': 'dd/MM/yy',
  'ar-IQ': 'dd/MM/yyyy',
  'zh-CN': 'yyyy/M/d',
  'de-CH': 'dd.MM.yyyy',
  'en-GB': 'dd/MM/yyyy',
  'es-MX': 'dd/MM/yyyy',
  'fr-BE': 'd/MM/yyyy',
  'it-CH': 'dd.MM.yyyy',
  'nl-BE': 'd/MM/yyyy',
  'nn-NO': 'dd.MM.yyyy',
  'pt-PT': 'dd-MM-yyyy',
  'sr-Latn-CS': 'd.M.yyyy',
  'sv-FI': 'd.M.yyyy',
  'az-Cyrl-AZ': 'dd.MM.yyyy',
  'ms-BN': 'dd/MM/yyyy',
  'uz-Cyrl-UZ': 'dd.MM.yyyy',
  'ar-EG': 'dd/MM/yyyy',
  'zh-HK': 'd/M/yyyy',
  'de-AT': 'dd.MM.yyyy',
  'en-AU': 'd/MM/yyyy',
  'es-ES': 'dd/MM/yyyy',
  'fr-CA': 'yyyy-MM-dd',
  'sr-Cyrl-CS': 'd.M.yyyy',
  'ar-LY': 'dd/MM/yyyy',
  'zh-SG': 'd/M/yyyy',
  'de-LU': 'dd.MM.yyyy',
  'en-CA': 'dd/MM/yyyy',
  'es-GT': 'dd/MM/yyyy',
  'fr-CH': 'dd.MM.yyyy',
  'ar-DZ': 'dd-MM-yyyy',
  'zh-MO': 'd/M/yyyy',
  'de-LI': 'dd.MM.yyyy',
  'en-NZ': 'd/MM/yyyy',
  'es-CR': 'dd/MM/yyyy',
  'fr-LU': 'dd/MM/yyyy',
  'ar-MA': 'dd-MM-yyyy',
  'en-IE': 'dd/MM/yyyy',
  'es-PA': 'MM/dd/yyyy',
  'fr-MC': 'dd/MM/yyyy',
  'ar-TN': 'dd-MM-yyyy',
  'en-ZA': 'yyyy/MM/dd',
  'es-DO': 'dd/MM/yyyy',
  'ar-OM': 'dd/MM/yyyy',
  'en-JM': 'dd/MM/yyyy',
  'es-VE': 'dd/MM/yyyy',
  'ar-YE': 'dd/MM/yyyy',
  'en-029': 'MM/dd/yyyy',
  'es-CO': 'dd/MM/yyyy',
  'ar-SY': 'dd/MM/yyyy',
  'en-BZ': 'dd/MM/yyyy',
  'es-PE': 'dd/MM/yyyy',
  'ar-JO': 'dd/MM/yyyy',
  'en-TT': 'dd/MM/yyyy',
  'es-AR': 'dd/MM/yyyy',
  'ar-LB': 'dd/MM/yyyy',
  'en-ZW': 'M/d/yyyy',
  'es-EC': 'dd/MM/yyyy',
  'ar-KW': 'dd/MM/yyyy',
  'en-PH': 'M/d/yyyy',
  'es-CL': 'dd-MM-yyyy',
  'ar-AE': 'dd/MM/yyyy',
  'es-UY': 'dd/MM/yyyy',
  'ar-BH': 'dd/MM/yyyy',
  'es-PY': 'dd/MM/yyyy',
  'ar-QA': 'dd/MM/yyyy',
  'es-BO': 'dd/MM/yyyy',
  'es-SV': 'dd/MM/yyyy',
  'es-HN': 'dd/MM/yyyy',
  'es-NI': 'dd/MM/yyyy',
  'es-PR': 'dd/MM/yyyy',
  'am-ET': 'd/M/yyyy',
  'tzm-Latn-DZ': 'dd-MM-yyyy',
  'iu-Latn-CA': 'd/MM/yyyy',
  'sma-NO': 'dd.MM.yyyy',
  'mn-Mong-CN': 'yyyy/M/d',
  'gd-GB': 'dd/MM/yyyy',
  'en-MY': 'd/M/yyyy',
  'prs-AF': 'dd/MM/yy',
  'bn-BD': 'dd-MM-yy',
  'wo-SN': 'dd/MM/yyyy',
  'rw-RW': 'M/d/yyyy',
  'qut-GT': 'dd/MM/yyyy',
  'sah-RU': 'MM.dd.yyyy',
  'gsw-FR': 'dd/MM/yyyy',
  'co-FR': 'dd/MM/yyyy',
  'oc-FR': 'dd/MM/yyyy',
  'mi-NZ': 'dd/MM/yyyy',
  'ga-IE': 'dd/MM/yyyy',
  'se-SE': 'yyyy-MM-dd',
  'br-FR': 'dd/MM/yyyy',
  'smn-FI': 'd.M.yyyy',
  'moh-CA': 'M/d/yyyy',
  'arn-CL': 'dd-MM-yyyy',
  'ii-CN': 'yyyy/M/d',
  'dsb-DE': 'd. M. yyyy',
  'ig-NG': 'd/M/yyyy',
  'kl-GL': 'dd-MM-yyyy',
  'lb-LU': 'dd/MM/yyyy',
  'ba-RU': 'dd.MM.yy',
  'nso-ZA': 'yyyy/MM/dd',
  'quz-BO': 'dd/MM/yyyy',
  'yo-NG': 'd/M/yyyy',
  'ha-Latn-NG': 'd/M/yyyy',
  'fil-PH': 'M/d/yyyy',
  'ps-AF': 'dd/MM/yy',
  'fy-NL': 'd-M-yyyy',
  'ne-NP': 'M/d/yyyy',
  'se-NO': 'dd.MM.yyyy',
  'iu-Cans-CA': 'd/M/yyyy',
  'sr-Latn-RS': 'd.M.yyyy',
  'si-LK': 'yyyy-MM-dd',
  'sr-Cyrl-RS': 'd.M.yyyy',
  'lo-LA': 'dd/MM/yyyy',
  'km-KH': 'yyyy-MM-dd',
  'cy-GB': 'dd/MM/yyyy',
  'bo-CN': 'yyyy/M/d',
  'sms-FI': 'd.M.yyyy',
  'as-IN': 'dd-MM-yyyy',
  'ml-IN': 'dd-MM-yy',
  'en-IN': 'dd-MM-yyyy',
  'or-IN': 'dd-MM-yy',
  'bn-IN': 'dd-MM-yy',
  'tk-TM': 'dd.MM.yy',
  'bs-Latn-BA': 'd.M.yyyy',
  'mt-MT': 'dd/MM/yyyy',
  'sr-Cyrl-ME': 'd.M.yyyy',
  'se-FI': 'd.M.yyyy',
  'zu-ZA': 'yyyy/MM/dd',
  'xh-ZA': 'yyyy/MM/dd',
  'tn-ZA': 'yyyy/MM/dd',
  'hsb-DE': 'd. M. yyyy',
  'bs-Cyrl-BA': 'd.M.yyyy',
  'tg-Cyrl-TJ': 'dd.MM.yy',
  'sr-Latn-BA': 'd.M.yyyy',
  'smj-NO': 'dd.MM.yyyy',
  'rm-CH': 'dd/MM/yyyy',
  'smj-SE': 'yyyy-MM-dd',
  'quz-EC': 'dd/MM/yyyy',
  'quz-PE': 'dd/MM/yyyy',
  'hr-BA': 'd.M.yyyy.',
  'sr-Latn-ME': 'd.M.yyyy',
  'sma-SE': 'yyyy-MM-dd',
  'en-SG': 'd/M/yyyy',
  'ug-CN': 'yyyy-M-d',
  'sr-Cyrl-BA': 'd.M.yyyy',
  'es-US': 'M/d/yyyy'
};

export const MSG_ADD_SUCCESS = 'Thêm mới dữ liệu thành công';
export const MSG_EDIT_SUCCESS = 'Cập nhật dữ liệu thành công';
export const MSG_DELETE_SUCCESS = 'Xóa dữ liệu thành công';
export const MSG_ADD_FAILURE = 'Không thể thêm mới dữ liệu';
export const MSG_EDIT_FAILURE = 'Không thể sửa dữ liệu';
export const MSG_DELETE_FAILURE = 'Không thể xóa dữ liệu';
export const MSG_PAGING_FAILURE = 'Không thể lấy dữ liệu';
export const MSG_GET_ALL_FAILURE = 'Không thể lấy dữ liệu';
export const MSG_GET_DETAIL_FAILURE = 'Không thể lấy dữ liệu';
export const MSG_SEARCH_FAILURE = 'Không thể lấy dữ liệu';

export const ORDER_MNG_IN_SAVE_GOOD_TYPE = 'SAVE_GOOD';
export const ORDER_MNG_IN_CONTAINER_YARD_TYPE = 'CONTAINER_YARD';
export const ORDER_MNG_IN_DESTINATION_TYPE = 'DESTINATION_ID';
export const ORDER_MNG_IN_COMMON_OTHER_ADDRESSES = 'ORDER_MNG_IN_COMMON_OTHER_ADDRESSES';

export const BTN_SAVE_AND_CONTINUE = 'SaveAndContinue';
export const EXPORT_EXCEL_ALL = 'exportExcelAll';
export const BTN_SAVE_AND_NO_NOTIFICATION = 'SaveAndNoNotification';
export const BTN_SAVE_DRAFT = 'SaveDraft';
export const BTN_WAIT_QUOTATION_APPROVAL = 'waitQuotationApproval';
export const ORDER_MANAGEMENT_RETAIL = 'retail';
export const ORDER_MANAGEMENT_INPUT = 'input';
export const ORDER_MANAGEMENT_ALL = 'all';

export const ORDER_MANAGEMENT_RETAIL_ID = 2;
export const ORDER_MANAGEMENT_INPUT_ID = 1;

export const TRUCKING_TYPE = {
  BULK: 1,
  CONT: 2,
  RETAIL: 3,
  // ConsignmentStatusId
  CONSIGNMENT_STATUS_ID: 8,
  // TruckingTypeId
  TRUCKING_TYPE_ID: 1
};

export const ODR_MNG_INPUT_TRUCKING_TYPE_OPTIONS = [
  { value: 1, label: 'Hàng rời' },
  { value: 2, label: 'Hàng cont' },
  { value: 3, label: 'Hàng kiện' }
];

export const CONSIGNMENT_GOOD_TYPE = {
  BUSINESS_IMPORT: 1,
  BUSINESS_EXPORT: 2,
  DOMESTIC: 3,
  INDUSTRIAL_AREA_IMPORT: 4,
  INDUSTRIAL_AREA_EXPORT: 5,
  LGE_EXPORT: 6,
  CBU: 7
};

export const FULL_CONSIGNMENT_GOOD_CATEGORIES = [
  CONSIGNMENT_GOOD_TYPE.BUSINESS_IMPORT,
  CONSIGNMENT_GOOD_TYPE.BUSINESS_EXPORT,
  CONSIGNMENT_GOOD_TYPE.DOMESTIC
];

export const SHORT_CONSIGNMENT_GOOD_CATEGORIES = [
  CONSIGNMENT_GOOD_TYPE.DOMESTIC,
  CONSIGNMENT_GOOD_TYPE.INDUSTRIAL_AREA_IMPORT,
  CONSIGNMENT_GOOD_TYPE.INDUSTRIAL_AREA_EXPORT
];

export const ODR_MNG_RETAIL_CONSIGNMENT_GOOD_TYPE_OPTIONS = [
  { value: 1, label: 'Hàng xuất' },
  { value: 2, label: 'Hàng nhập' }
];

export const GOOD_TYPE = {
  INPUT_ODR_MNG: 1,
  RETAIL_ODR_MNG: 2
};

export const EQUIPMENT_CATEGORY = {
  VAT_TU: 1,
  DAU_MO: 2,
  LOP_CU_TAN_DUNG: 3,
  BAT_DA: 4,
  SUA_CHUA: 5,
  BAO_DUONG: 6
};

export const STATUS_TYPE_ANNUAL_FEE = [
  {
    value: true,
    label: 'Áp dụng'
  },
  {
    value: false,
    label: 'Không áp dụng'
  }
];
export const ANNUAL_FEE_TYPE = {
  PHI_DANG_KIEM: 10,
  PHI_LUU_THONG_DUONG_BO: 11,
  PHI_BAO_HIEM_TDNS: 12,
  VAY_NGAN_HANG: 13,
  LAI_NGAN_HANG: 14,
  PHI_DINH_VI: 15,
  BAO_HIEM_VAT_CHAT: 16
};

export const SUA_CHUA_BAO_DUONG_FIELDS = [EQUIPMENT_CATEGORY.SUA_CHUA];

export const distanceUrl = `/routeinfo/getByStartAndEnd`;

export const OIL_NORM_KEY = 'OilNorm';
export const TRAVEL_FEE_KEY = 'TravelFee';
export const TRANSPORT_FEE_KEY = 'TransportFee';
export const OUT_SOURCE_TRANSPORT_FEE_KEY = 'OutSourceTransportFee';
export const TRUCKING_SALARY_KEY = 'TruckingSalary';
export const SEA_TRANSPORT_FEE_KEY = 'SeaTransportFee';
export const GET_NAME_CONSIMENT_KEY = 'GetNameConsignmentCode';

export const OIL_NORM_SCREEN = 'OilNorm';
export const TRAVEL_FEE_SCREEN = 'RouteCost';
export const TRANSPORT_FEE_SCREEN = 'TransportCost';
export const OUT_SOURCE_TRANSPORT_FEE_SCREEN = 'OutSourceTransportCost';
export const SEA_TRANSPORT_FEE_SCREEN = 'SeaNormManagement';

export const ORDER_MNG_IN_ADDRESS_TYPES = [
  ORDER_MNG_IN_SAVE_GOOD_TYPE,
  ORDER_MNG_IN_DESTINATION_TYPE,
  ORDER_MNG_IN_CONTAINER_YARD_TYPE
];
export const DLV_RCV_WEIGHT_CALCULATING_OPTIONS = [
  {
    value: 'true',
    label: 'Lượng lái xe giao'
  },
  {
    value: 'false',
    label: 'Lượng lái xe nhận'
  }
];

export const DOWN_PAYMENT_STATUS_IS_OPEN = 2;
export const DOWN_PAYMENT_STATUS_IS_CLOSE = 3;
export const INTERNAL_SETTLEMENT_STATUS_IS_OPEN = 2;
export const INTERNAL_SETTLEMENT_STATUS_IS_CLOSED = 3;
export const SETTLEMENT_STATUS_IS_OPEN = 2;
export const SETTLEMENT_STATUS_IS_CLOSED = 3;
export const TRUCKING_TYPE_ID_IS_CONT = 2;
export const TRUCKING_TYPE_ID_IS_NOT_CONT = 1;
export const TRUCKING_TYPE_ID_IS_HANG_KIEN = 3;

export const INSTALMENT_CATEGORY_ID_IS_NEW = 2;

export const CONSIGNMENT_STATUS_IS_CLOSE = 8;
export const CONSIGNMENT_STATUS_IS_OPEN_SETTLEMENT = 7;

export const LINK_END_DISABLE_EDIT_CONTENT = [4, 5]; //4  cước vận chuyển, 5 lưu ca

export const FIELD_NORM_ROUTE = 'Route';
export const FIELD_NORM_CAR = 'Car';
export const FIELD_NORM_IS_LOAD = 'IsLoadStandardOil';
export const FIELD_NORM_DISTANCE = 'Distance';
export const FIELD_NORM_GOOD_TYPE = 'GoodType';
export const FIELD_NORM_MOOC_TYPE = 'MoocType';
export const FIELD_NORM_PORT = 'Port';
export const FIELD_NORM_LENGTH = 'Length';
export const FIELD_NORM_WEIGHT_PER_PACKAGE = 'WeightPerPackage';
export const FIELD_NORM_WEIGHT = 'Weight';
export const FIELD_NORM_TRUCKING_TYPE = 'TruckingType';
export const FIELD_NORM_HEIGHT = 'Height';
export const FIELD_NORM_WIDTH = 'Width';
export const FIELD_NORM_CUSTOMER = 'Customer';
export const FIELD_NORM_SHIPPING_LINE = 'ShippingLine';
export const FIELD_NORM_VEHICLE_CATEGORY = 'VehicleCategoryId';
export const FIELD_NORM_CALCULATING_MODE = 'CalculatingCategoryId';

export const DEFAULT_VEHICLE_WEIGHT = 35500;

export const NORM_DEBOUNCE_TIME = 1000; //ms

export const THU_CUOC_LAI_XE_PHI_THU = 1;
export const THU_CUOC_LAI_XE_PHI_CHI = 2;

export const CONSIGNMENT_STATUS = {
  INPUTING_DATA: 4, // Đang nhập dự liệu
  TRANSPORTABLE: 6, // Có thể vận chuyển
  TRANSPORTED: 7, // Đã vận chuyển
  FINALIZED: 8 // Đã quyết toán
};

export const SETTLEMENT_ORDER_CREATED = true; // Quyết toán nội bộ đã được tạo
export const SETTLEMENT_ORDER_NOT_CREATED = false; // Quyết toán nội bộ chưa được tạo

export const CUSTOMER_TYPE = {
  CUSTOMER: 1,
  TRANSPORT_COMPANY: 2,
  CHARGE_PROVIDER: 3,
  SPAREPART_PROVIDER: 4,
  FUEL_PROVIDER: 5
};

export const EQUIPMENTCATEGORY_MAINTENANCE_ID = 7;

export const OIL_REMAIN_REPORT_TYPE = {
  IMPORT: 1,
  EXPORT: 2
};

export const DISPATCH_CAR_SCR = {
  DIEU_1_XE: 1,
  DIEU_N_XE: 2,
  DIEU_KO_LO: 3
};

export const DRIVER_SCREEN_INDEX = {
  NORMAL: 0,
  INCLUDING_COST: 1
};
export const DRIVER_SCREEN_URLs = ['/management/driver-plan', '/management/driver-plan-short'];

export const DRIVER_PLAN_TYPE = {
  NORMAL: 1,
  INCLUDING_COST: 2
};

export const MINING_YARD_REPORT_PARENTID = 7;

export const EXPIRATION_TYPE = {
  REPAIR: 1,
  ANNUAL_FEES: 2,
  EXPIRATION: 3
};

export const DRIVER_PLAN_STATUSES = {
  NOT_TRANSPORT: 9,
  RECEIVED_GOOD: 10,
  DELIVERD_GOOD: 11,
  BACKED_TO_YARD: 28
};

export const DRIVER_PLAN_HIGHLIGHT_COLORS = {
  ARISING_POINT: '#F0E68C',
  SELECT_ARISING_POINT: '#D0E98D',
  JOINING_DRIVER_PLAN: '#FFFF99',
  CHECKED: '#e6ffb3',
  TRUCKING_DRIVER_PLAN_DEFAULT_WARNING_COLOR: '#edc98a',
  CONFIRM_UPDATE_PLAN: '#ffc107'
};

export const DRIVER_PLAN_CONTEXT_MENU = {
  EDIT: 0,
  DELETE: 1,
  COPY: 2,
  COMBINE: 3
};

export const ORDER_MNG_CONTEXT_MENU = {
  DETAIL: 0,
  EDIT: 1,
  DELETE: 2,
  COPY: 3
};

//Cài đặt công việc
export const JOB_POSITION = {
  GENERAL_DIRECTOR: 1, // Giám đốc
  SALE: 2, // Kinh doanh
  TRUCKING: 3, //
  ACCOUNTANT: 4, // Kế toán
  DRIVER: 5, // Lái xe
  REPAIR: 6, // Sửa chữa
  CUS: 7, // Thương vụ
  OIL: 8, // Nhiên liệu,
  PUCHASING: 11, // Thu mua
  QUOTATION: 12 // báo giá
};
// Nhóm người dùng
export const ACCOUNTANT = 6; // Kế toán

export const All_POSTITIONS_NO_DRIVER = [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 99];

export const DEPARTMENTS = {
  REPAIR: 6 // DICH VU SUA CHUA
};

export const SCHEDULER_COLOR_NEW = '#D0A9F5';
export const SCHEDULER_COLOR_INPROGRESS = '#58D3F7';
export const SCHEDULER_COLOR_LOST = '#FF8000';
export const SCHEDULER_COLOR_TRUCKING_TYPE_IS_CONT = '#58D3F7';
export const SCHEDULER_COLOR_TRUCKING_TYPE_IS_ROI = '#FF8000';
export const SCHEDULER_COLOR_TRUCKING_TYPE_IS_KIEN = '#F781F3';
export const DRIVER_PLAN_COST_EXCEL_FIELD_DEF_INDEX = 2;
export const DRIVER_PLAN_COST_EXCEL_SELECT_FIELDS = {
  ConsignmentCode: { options: 'consignments', optionField: 'ConsignmentCode' },
  CustomerName: { options: 'customers', optionField: 'CustomerName' },
  GoodCategoryName: {
    options: 'goodCategories',
    optionField: 'GoodsCategoryName'
  },
  TruckingType: {
    options: 'truckingTypes',
    optionField: 'label'
  },
  JoiningTruckingPlanCode: {
    options: 'dispatchedDriverPlans',
    optionField: 'label'
  },
  TransportCompany: {
    options: 'transportCompanies',
    optionField: 'label'
  },
  Department: {
    options: 'departments',
    optionField: 'label'
  },
  TractorTrailer: {
    options: 'vehicles',
    optionField: 'label'
  },
  DriverName: {
    options: 'employees',
    optionField: 'label'
  },
  AssistantDriverName: {
    options: 'employees',
    optionField: 'label'
  },
  Status: {
    options: 'statuses',
    optionField: 'label'
  },
  ReceiveStatus: {
    options: 'receiveStatuses',
    optionField: 'label'
  },
  StartingPoint: {
    options: 'allAddresses',
    optionField: 'label'
  },
  Destination: {
    options: 'allAddresses',
    optionField: 'label'
  },
  FullContCategory1: {
    options: 'containers',
    optionField: 'label'
  },
  FullContCategory2: {
    options: 'containers',
    optionField: 'label'
  },
  SemiTrailer: {
    options: 'moocs',
    optionField: 'label'
  },
  TransformedSemiTrailerCategory: {
    options: 'typeMooc',
    optionField: 'label'
  }
};

export const DRIVER_PLAN_COST_EXCEL_DATE_FIELDS = ['DepartureDate', 'ReturnDate', 'TruckingDate', 'DeliveryDate'];

export const DRIVER_PLAN_COST_EXCEL_SELECT_FIELDS_MAPPING = {
  SemiTrailerId: 'SemiTrailer',
  TransformedSemiTrailerCategoryId: 'TransformedSemiTrailerCategory',
  ConsignmentId: 'ConsignmentCode',
  CustomerId: 'CustomerName',
  GoodsCategoryId: 'GoodCategoryName',
  TruckingTypeId: 'TruckingType',
  JoiningTruckingPlanId: 'JoiningTruckingPlanCode',
  TransportCompanyId: 'TransportCompany',
  GroupCarsId: 'Department',
  TractorTrailerId: 'TractorTrailer',
  EmployeeId: 'DriverName',
  AssistantDriverId: 'AssistantDriverName',
  TrunckingStatusId: 'Status',
  ReceiveStatusId: 'ReceiveStatus',
  StartingPointId: 'StartingPoint',
  DestinationId: 'Destination',
  FullContCategoryId1: 'FullContCategory1',
  FullContCategoryId2: 'FullContCategory2'
};

export const FIELDS_DEFINITIONS_ENG = [
  'index',
  'ConsignmentCode',
  'DriverPlanCode',
  'CustomerName',
  'TruckingType',
  'VesselNo',
  'BillBookingDate',
  'GoodCategoryName',
  'TruckingDate',
  'DepartureDate',
  'TractorTrailer',
  'TransportCompany',
  'DriverName',
  'SemiTrailer',
  'TransformedSemiTrailerCategory',
  'RouteName',
  'ArisingPoint',
  'ContSerialNo',
  'FullContCategory1',
  'DeliveryWeight',
  'ReceiveWeight',
  'PackageQuantity',
  'TravelAllowance',
  'TicketFee',
  'StandardOil',
  'OverTime',
  'Status',
  'RentedTruckingFee',
  'TruckingFee',
  'JoiningTruckingPlanCode',
  'ReceiveStatus',
  'Note'
];

export const NULL = 'null';
export const NOT_NULL = 'notnull';
export const ANY = 'any';

export const DRIVER_PLAN_COST_EXCEL_REQUIRED_SETTINGS = [
  {
    ConsignmentCode: NULL,
    CustomerName: NOT_NULL,
    GoodCategoryName: NOT_NULL,
    TruckingType: NOT_NULL
  },
  {
    TruckingType: 'Hàng cont',
    FullContCategory1: NOT_NULL
  },
  {
    TruckingType: 'Hàng kiện',
    TotalWeight: NOT_NULL,
    PackageQuantity: NOT_NULL
  }
];

export const ADDRESS_TYPES = {
  PORT: 1,
  DEPOT: 2,
  CFS: 3,
  DOMESTIC_PORT: 4,
  OTHER: 5
};

export const TRUCKING_STATUS = {
  CHUA_VAN_CHUYEN: 9,
  DA_NHAN_HANG: 10,
  VE_BAI: 28,
  DA_GIAO_HANG: 11,
  CANCEL: 35, // Huy chuyen
  DELAY: 36, // Bi tre
  CONFIRMED: 59, // Đã xác nhận chuyến
  DA_LAY_HANG_VO: 97 // Đã lấy hàng vỏ
};

export const TRUCKING_FEE_BY_WEIGHT_OPTIONS = [
  {
    value: 1,
    label: 'Theo chuyến'
  },
  {
    value: 2,
    label: 'Theo khối lượng chở'
  },
  {
    value: 3,
    label: 'Theo 97% khối lượng chở'
  }
];

export const TRUCKING_FEE_BY_WEIGHT = {
  BY_TRUCKING: 1,
  BY_WEIGHT: 2,
  BY_97_PERCENT_WEIGHT: 3
};

export const DAY_NUMBER_SEARCH_RANGE = 30;

export const CALCULATE_OIL_NORM_MODE = {
  ByCoefficient: '1',
  ByRoute: '2'
};

export const DISPLAY_DETAIL_INFO_SETTLEMET_CUSTOMERS_MODE = {
  Display: '1',
  None: '0'
};

export const GROUP_SETTING_INFOS = {
  Calculate_oil_norm: {
    GroupId: 4,
    Name: 'Fuel_Caculator_Type'
  },
  Display_detail_info_settlement_customers: {
    GroupId: 1,
    Name: 'Display_Detail_Info_Settlement_Customers'
  },
  Salary_Method_Type: {
    GroupId: 4,
    Name: 'Salary_Method_Type'
  },
  UnionFee_Method_Type: {
    GroupId: 4,
    Name: 'Is_Have_Union_Fee_For_Calculate_Driver_Salary'
  },
  Report_Company_Auto_Email: {
    GroupId: 2,
    Name: 'Report_Company_Auto_Email'
  },
  Average_Of_Payment_Behalf_By_Trucking: {
    GroupId: 1,
    Name: 'Average_Of_Payment_Behalf_By_Trucking'
  },
  Service_Report_By_Trucking: {
    GroupId: 1,
    Name: 'Service_Report_By_Trucking'
  },
  Receipt_Print_Type: {
    GroupId: 2,
    Name: 'Equipment_Interchange Receipt_Print_Type'
  },
  Sea_Transport_Display: {
    GroupId: 1,
    Name: 'Sea_Transport_Display'
  },
  Display_Btn_Create_Trucking_Plan_Short: {
    GroupId: 4,
    Name: 'Display_Btn_Create_Trucking_Plan_Short'
  },
  Settlemet_Report_Footer: {
    GroupId: 2,
    Name: 'SettlemetReportFooter'
  },
  Show_Transport_Cost_With_Route_Type: {
    GroupId: 4,
    Name: 'Show_Transport_Cost_With_Route_Type'
  },
  Notify_Of_Services: {
    GroupId: 2,
    Name: 'NotifyFromServices'
  },
  Transport_By_Truck: {
    GroupId: 2,
    Name: 'Transport_By_Truck'
  },
  Phase_Mode: {
    GroupId: 2,
    Name: 'Phase_Mode'
  },
  Store_Contrary_Trucking_Fee_By_Extension_Direction: {
    GroupId: 2,
    Name: 'Store_Contrary_Trucking_Fee_By_Extension_Direction'
  },
  Store_Contrary_Travel_Fee_By_Extension_Direction: {
    GroupId: 2,
    Name: 'Store_Contrary_Travel_Fee_By_Extension_Direction'
  },
  Report_Company_Url_Logo: {
    GroupId: 2,
    Name: 'Report_Company_Url_Logo'
  },
  Report_Company_Tax_Code: {
    GroupId: 2,
    Name: 'Report_Company_Tax_Code'
  },
  Report_Company_Address: {
    GroupId: 2,
    Name: 'Report_Company_Address'
  },
  Report_Company_Phone: {
    GroupId: 2,
    Name: 'Report_Company_Phone'
  },
  Report_Company_Name: {
    GroupId: 2,
    Name: 'Report_Company_Name'
  },
  Formula_Oil_Cost_At_Revenue_Screen: {
    GroupId: 2,
    Name: 'Formula_Oil_Cost_At_Revenue_Screen'
  }
};

export const DISPLAY_BTN_CREATE_TRUCKING_PLAN_SHORT = {
  Normal: '0',
  VINAHONGPHAT: '1'
};

export const EQUIPMENT_INTERCHANGE_RECEIPT_PRINT_TYPES = {
  LAMSON: 1,
  ANTIN: 2
};
export const LOCK_EDITABLE_TRANSPORT_PLAN_COST = {
  Enable: true,
  Disable: false
};

export const CALCULATE_DRIVER_SALARY_METHOD = {
  Normal: '1',
  ByRoute: '2',
  LuongGa: '3',
  MinhHai: '5',
  NgoiSaoBac: '6',
  AOE: '7'
};

export const SERVICE_REPORT_BY_TRUCKING = {
  Normal: '0',
  ByTrucking: '1'
};

export const AVERAGE_OF_PAYMENT_BEHAFT_BY_TRUCKING = {
  Normal: '0',
  ByTrucking: '1'
};

export const SEA_TRANSPORT_DISPLAY = {
  Enable: '1',
  Disable: '0'
};

export const SHOW_ROUTES_IN_TRANSPORT_FEE = {
  IsLoad: '1',
  TwoRoutes: '2'
};

export const TRANSPORT_BY_TRUCK = {
  Enable: '1',
  Disable: '0'
};
export const Phase_Mode = {
  Enable: '2',
  Disable: '1'
};

export const UPDATE_NORM_VALUES_WITH_ROUTE = {
  ROOT: '0',
  EXTENDS: '1'
};

export const BACKGROUND_DISABLE_ACTIONS = '#EFE3AF';
export const PAGE_SIZE_FOR_SETTLEMENT_CUSTOMERS_REPORT = 1000000;
export const LEFT_SIDEBAR_SHOW = 258;
export const LEFT_SIDEBAR_MINIMIZE = 50;

export const WORKING_GROUP_CUS_ID = 1; // cus
export const WORKING_GROUP_REPAIR_ID = 5; // sửa chữa

export const WORKING_TYPE_DOWN_PAYMENT_ID = 2; // tạm ứng
export const WORKING_TYPE_SETTLEMENT_ID = 3; // quyết toán
export const WORKING_TYPE_PROPOSE_ID = 5; // đề xuất

export const WORKING_PRIORITY_NORMAL_ID = 3;

export const WORKING_STATUS_NEW_ID = 1;

export const NOT_FOLLOW_BY_DECLARATION_ID = 4;

export const WORK_GROUP = {
  CUS: 1,
  REPAIR: 5,
  DIRECTOR: 9,
  PURCHASING: 8,
  QUOTATION: 12,
  QUOTATION_APPROVAL: 13,
  BROWSE_PROPOSALS: 14
};

export const TIME_SHOW_ALERT_MESS = 2000;

export const BUSINESS_CONSIGNMENT_TYPE = {
  KD_NHAP: 1,
  KD_XUAT: 2,
  NOIDIA: 3
};

export const INDUSTRY_CONSIGNMENT_TYPE = {
  NOIDIA: 3,
  KCN_NHAP: 4,
  KCN_XUAT: 5,
  XUAT_LGE: 6,
  CBU: 7
};

export const CALCULATE_BY_ROOT_ADDRESS = 1;
export const CALCULATE_BY_EXTENDS_ADDRESS = 2;
export const CALCULATE_BY_TRANSPORT_COST = 3;
export const CALCULATE_FROM_DETAIL_TO_EXTEND_ADDRESS = 4;

export const LOCAL_STORAGE_ITEM_TITLE = {
  OrderInput: 'payloadSearchOrderInput',
  OrderRetail: 'payloadSearchOrderRetail'
};

export const CLOSED_INTERNAL_SETTLEMENT_STATUS_ID = 3;

export const IS_PRIMARY_COMPANY = 1;
export const PAGE_SIZE_GET_ALL = 10000000;
export const CONT_STATUS_IS_SOLD_ID = 37;
export const ANNUAL_COMMON_FEE_IDS = [56, 57, 5, 59];
export const CONSIGNMENT_TYPE_INPUT_IDS = [1, 3, 4, 7, 8]; //a tùng
export const CONSIGNMENT_TYPE_OUTPUT_IDS = [2, 5, 6];

export const CALCULATING_CATEGORY_NORMAL_TYPE_ID = 1; // LOAI TINH CUOC CHUYEN THUONG 1 CHIEU - CHI PHI VAN CHUYEN

export const CHECK_IN_WORKING_TYPE_ID = 50;
export const APPLY_STATUS_SEA_NORM = [
  { value: true, label: 'Áp dụng' },
  { value: false, label: 'Không áp dụng' }
];

export const IS_PACKING_POINT = 0;
export const IS_DELIVERY_POINT = 1;
export const IS_PICK_CONTAINER_POINT = 2;

export const TRUCKING_STATUS_COLORS = {
  9: SECONDARY_COLOR,
  10: PRIMARY_COLOR,
  28: RETURN_YARD_COLOR,
  11: COMPLETED_COLOR,
  35: DANGER_COLOR, // Huy chuyen
  36: WARNING_COLOR, // Bi tre
  59: CONFIRM_COLOR, // Đã xác nhận chuyến
  97: TAKE_THE_SHELL_COLOR // Đã lấy hàng/vỏ
};

export const TRUCKING_STATUS_OPTIONS_FILTER = [
  { value: TRUCKING_STATUS.CHUA_VAN_CHUYEN, label: 'Chưa vận chuyển' },
  { value: TRUCKING_STATUS.DA_NHAN_HANG, label: 'Đã nhận hàng' },
  { value: TRUCKING_STATUS.DA_GIAO_HANG, label: 'Đã giao hàng' },
  { value: TRUCKING_STATUS.VE_BAI, label: 'Về bãi' },
  { value: TRUCKING_STATUS.CANCEL, label: 'Hủy chuyến' },
  { value: TRUCKING_STATUS.DELAY, label: 'Bị trễ' },
  { value: TRUCKING_STATUS.CONFIRMED, label: 'Đã nhận chuyến' },
  { value: TRUCKING_STATUS.DA_LAY_HANG_VO, label: 'Đã lấy hàng vỏ' }
];

export const PAYMENT_FEE_PIN_WITH_FEE_ID_OPTIONS = [1, 2, 3];
export const PAYMENT_STATUS_ID_TRANSPORT_CODE_WITH_DRIVER_ACTIONS = {
  REQUEST: 80,
  PAID: 83
};

export const PHI_CHI_LAI_XE_TYPE = 2;
export const PHI_THU_LAI_XE_TYPE = 1;
export const PHI_PHU_CAP_DE_XUAT_LAI_XE_TYPE = 7;

export const REPLACE_SPECIAL_CHARACTER_URL = '!';

const DEFAULT_FILL_ROW_NOTES = [{ color: '#90EE90', label: 'Phần tử chọn' }];
export const FILL_ROW_NOTES = {
  DRIVER_PLAN: [
    ...DEFAULT_FILL_ROW_NOTES,
    {
      color: DRIVER_PLAN_HIGHLIGHT_COLORS.CONFIRM_UPDATE_PLAN,
      label: 'Chưa xác nhận thay đổi KHVC'
    },
    { color: DRIVER_PLAN_HIGHLIGHT_COLORS.JOINING_DRIVER_PLAN, label: 'Chuyến kết hợp' },
    {
      color: DRIVER_PLAN_HIGHLIGHT_COLORS.TRUCKING_DRIVER_PLAN_DEFAULT_WARNING_COLOR,
      label: 'Chuyến chưa gán xe'
    },
    {
      color: 'white',
      label: 'Mã điều không lô',
      markColor: 'red'
    }
  ],
  ORDER_MN: [
    {
      color: 'white',
      label: 'Mã điều không lô',
      markColor: 'red'
    }
  ],
  STANDARD_OIL: [
    ...DEFAULT_FILL_ROW_NOTES,
    { color: DRIVER_PLAN_HIGHLIGHT_COLORS.JOINING_DRIVER_PLAN, label: 'Chuyến kết hợp' },
    { color: '#70e838', label: 'Được cấp dầu' },
    { color: PRINT_STANDARD_OIL_SUCCESS, label: 'Phiếu dầu đã in' }
  ]
};

export const SETTING_INFO_GROUPS_IDS = {
  COMPANY_INFO: 2
};

export const PHASE_CATEGORY_ID = {
  TRANSPORT_FEE: 1,
  OUTSIDE_RENTAL_FEES: 2,
  OIL_PRICE: 3,
  PHASE_CATEGORY_ID: 4,
  NORM_MANAGEMENT: 5
};

export const CONT_UNIT_ID = {
  CONT_20: 5,
  CONT_40: 6
};

export const TYPE_OF_EDIT_TRUCKING_FEE = {
  FROM_REFRESH_NORM: 2,
  FROM_INPUT: 1
};

export const WORKING_TYPE_IDS = {
  REPAIR: 6
};
