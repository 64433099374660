import {
  getAllTransportCompany,
  getDriverByTransportCompany,
  getTransportCompanyById,
  getTransportCustomer,
  getTransportCustomerByType
} from 'services/transportCompanyInfo';

// define const actions
export const TRANSPORT_COMPANY_ACTIONS = {
  GET_ALL_TRANSPORT_COMPANY: 'GET_ALL_TRANSPORT_COMPANY',
  GET_ALL_TRANSPORT_COMPANY_PENDING: 'GET_ALL_TRANSPORT_COMPANY_PENDING',
  GET_ALL_TRANSPORT_COMPANY_FULFILLED: 'GET_ALL_TRANSPORT_COMPANY_FULFILLED',
  GET_ALL_TRANSPORT_COMPANY_REJECTED: 'GET_ALL_TRANSPORT_COMPANY_REJECTED',

  //get driver by company
  GET_DRIVER_BY_TRANSPORT_COMPANY: 'GET_DRIVER_BY_TRANSPORT_COMPANY',
  GET_DRIVER_BY_TRANSPORT_COMPANY_PENDING: 'GET_DRIVER_BY_TRANSPORT_COMPANY_PENDING',
  GET_DRIVER_BY_TRANSPORT_COMPANY_FULFILLED: 'GET_DRIVER_BY_TRANSPORT_COMPANY_FULFILLED',
  GET_DRIVER_BY_TRANSPORT_COMPANY_REJECTED: 'GET_DRIVER_BY_TRANSPORT_COMPANY_REJECTED',

  //get company by id
  GET_TRANSPORT_COMPANY_BY_ID: 'GET_TRANSPORT_COMPANY_BY_ID',
  GET_TRANSPORT_COMPANY_BY_ID_PENDING: 'GET_TRANSPORT_COMPANY_BY_ID_PENDING',
  GET_TRANSPORT_COMPANY_BY_ID_FULFILLED: 'GET_TRANSPORT_COMPANY_BY_ID_FULFILLED',
  GET_TRANSPORT_COMPANY_BY_ID_REJECTED: 'GET_TRANSPORT_COMPANY_BY_ID_REJECTED',

  GET_TRANSPORT_CUSTOMER: 'GET_TRANSPORT_CUSTOMER',
  GET_TRANSPORT_CUSTOMER_PENDING: 'GET_TRANSPORT_CUSTOMER_PENDING',
  GET_TRANSPORT_CUSTOMER_REJECTED: 'GET_TRANSPORT_CUSTOMER_REJECTED',
  GET_TRANSPORT_CUSTOMER_FULFILLED: 'GET_TRANSPORT_CUSTOMER_FULFILLED',

  GET_TRANSPORT_CUSTOMER_BY_TYPE: 'GET_TRANSPORT_CUSTOMER_BY_TYPE',
  GET_TRANSPORT_CUSTOMER_BY_TYPE_PENDING: 'GET_TRANSPORT_CUSTOMER_BY_TYPE_PENDING',
  GET_TRANSPORT_CUSTOMER_BY_TYPE_REJECTED: 'GET_TRANSPORT_CUSTOMER_BY_TYPE_REJECTED',
  GET_TRANSPORT_CUSTOMER_BY_TYPE_FULFILLED: 'GET_TRANSPORT_CUSTOMER_BY_TYPE_FULFILLED'
};

// define actions
export const actionGetAllTransportCompany = (type) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COMPANY_ACTIONS.GET_ALL_TRANSPORT_COMPANY,
    payload: getAllTransportCompany(type)
  });
};

export const actionGetDriverByTransportCompany = (id) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COMPANY_ACTIONS.GET_DRIVER_BY_TRANSPORT_COMPANY,
    payload: getDriverByTransportCompany(id)
  });
};

export const actionGetTransportCompanyById = (id) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COMPANY_ACTIONS.GET_TRANSPORT_COMPANY_BY_ID,
    payload: getTransportCompanyById(id)
  });
};

export const actionGetTransportCustomer = () => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COMPANY_ACTIONS.GET_TRANSPORT_CUSTOMER,
    payload: getTransportCustomer()
  });
};

export const actionGetTransportCustomerByType = (type) => (dispatch) => {
  return dispatch({
    type: TRANSPORT_COMPANY_ACTIONS.GET_TRANSPORT_CUSTOMER_BY_TYPE,
    payload: getTransportCustomerByType(type)
  });
};
