import { SCRIPT_TOOL_ACTION } from 'actions/ScriptTool';

const initialState = {
  OrderManagementInput: [],
  Driver: [],
  Loading: false
};

const ScriptToolReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCRIPT_TOOL_ACTION.SEARCH_ORDER_MANAGEMENT_INPUT_SCRIPT_TOOL_FULFILLED: {
      return { ...state, OrderManagementInput: action.payload.data.data };
    }
    case SCRIPT_TOOL_ACTION.GET_DRIVER_PLAN_BY_CONSIGNMENT_ID_FULFILLED: {
      return { ...state, Driver: action.payload.data.data, Loading: false };
    }
    case SCRIPT_TOOL_ACTION.GET_DRIVER_PLAN_BY_CONSIGNMENT_ID_PENDING: {
      return { ...state, Loading: true };
    }
    case SCRIPT_TOOL_ACTION.GET_DRIVER_PLAN_BY_CONSIGNMENT_ID_REJECTED: {
      return { ...state, Loading: false };
    }
    default:
      return { ...state };
  }
};
export default ScriptToolReducer;
