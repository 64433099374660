import localforage from 'localforage';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import promise from 'redux-promise-middleware';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import rootReducer from './reducers';
import rootSaga from './sagas';

const persistConfig = {
  key: 'root',
  storage: localforage,
  whitelist: ['settingInfoStore'],
  stateReconciler: autoMergeLevel2 // Xem thêm tại mục "Quá trình merge".
};

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(
  persistReducer(persistConfig, rootReducer),
  composeWithDevTools(applyMiddleware(sagaMiddleware, promise, thunk))
);
// const store = createStore (rootReducer, applyMiddleware (sagaMiddleware, promise, thunk));
sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);
