import httpClient from 'HttpClient';
// define const actions
export const ACCOUNTING_LIST_REPAIR_ACTIONS = {
  GET_ACCOUNTING_LIST_REPAIR_PER_PAGE: 'GET_ACCOUNTING_LIST_REPAIR_PER_PAGE',
  GET_ACCOUNTING_LIST_REPAIR_PER_PAGE_PENDING: 'GET_ACCOUNTING_LIST_REPAIR_PER_PAGE_PENDING',
  GET_ACCOUNTING_LIST_REPAIR_PER_PAGE_FULFILLED: 'GET_ACCOUNTING_LIST_REPAIR_PER_PAGE_FULFILLED',
  GET_ACCOUNTING_LIST_REPAIR_PER_PAGE_REJECTED: 'GET_ACCOUNTING_LIST_REPAIR_PER_PAGE_REJECTED',

  SEARCH_ACCOUNTING_REPAIRING_ITEMS: 'SEARCH_ACCOUNTING_REPAIRING_ITEMS',
  SEARCH_ACCOUNTING_REPAIRING_ITEMS_PENDING: 'SEARCH_ACCOUNTING_REPAIRING_ITEMS_PENDING',
  SEARCH_ACCOUNTING_REPAIRING_ITEMS_FULFILLED: 'SEARCH_ACCOUNTING_REPAIRING_ITEMS_FULFILLED',
  SEARCH_ACCOUNTING_REPAIRING_ITEMS_REJECTED: 'SEARCH_ACCOUNTING_REPAIRING_ITEMS_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  UPDATE_STATUS_BEHAFT: 'UPDATE_STATUS_BEHAFT',
  UPDATE_STATUS_BEHAFT_PENDING: 'UPDATE_STATUS_BEHAFT_PENDING',
  UPDATE_STATUS_BEHAFT_FULFILLED: 'UPDATE_STATUS_BEHAFT_FULFILLED',
  UPDATE_STATUS_BEHAFT_REJECTED: 'UPDATE_STATUS_BEHAFT_REJECTED',

  UPDATE_STATUS_SETTLEMENT: 'UPDATE_STATUS_SETTLEMENT',
  UPDATE_STATUS_SETTLEMENT_PENDING: 'UPDATE_STATUS_SETTLEMENT_PENDING',
  UPDATE_STATUS_SETTLEMENT_FULFILLED: 'UPDATE_STATUS_SETTLEMENT_FULFILLED',
  UPDATE_STATUS_SETTLEMENT_REJECTED: 'UPDATE_STATUS_SETTLEMENT_REJECTED',

  CREATE_DATA_ACT_REPAIR: 'CREATE_DATA_ACT_REPAIR',
  CREATE_DATA_ACT_REPAIR_PENDING: 'CREATE_DATA_ACT_REPAIR_PENDING',
  CREATE_DATA_ACT_REPAIR_FULFILLED: 'CREATE_DATA_ACT_REPAIR_FULFILLED',
  CREATE_DATA_ACT_REPAIR_REJECTED: 'CREATE_DATA_ACT_REPAIR_REJECTED',

  UPDATE_DATA_ACT_REPAIR: 'UPDATE_DATA_ACT_REPAIR',
  UPDATE_DATA_ACT_REPAIR_PENDING: 'UPDATE_DATA_ACT_REPAIR_PENDING',
  UPDATE_DATA_ACT_REPAIR_FULFILLED: 'UPDATE_DATA_ACT_REPAIR_FULFILLED',
  UPDATE_DATA_ACT_REPAIR_REJECTED: 'UPDATE_DATA_ACT_REPAIR_REJECTED'
};

// define actions
export const getListDataPerpage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: ACCOUNTING_LIST_REPAIR_ACTIONS.GET_ACCOUNTING_LIST_REPAIR_PER_PAGE,
    payload: httpClient.get(`/AccountingRepair/AdvancePaymentManaging/Paging/page?Page=${pageNo}&PageSize=${pageSize}`)
  });
};

// define actions
export const searchAccountingRepairItems = (params) => (dispatch) => {
  return dispatch({
    type: ACCOUNTING_LIST_REPAIR_ACTIONS.SEARCH_ACCOUNTING_REPAIRING_ITEMS,
    payload: httpClient.get(`/AccountingRepair/AdvancePaymentManaging/search`, { params: params })
  });
};
export const searchAccountingRepairItemsAll = (params) => (dispatch) => {
  return dispatch({
    // type: ACCOUNTING_LIST_REPAIR_ACTIONS.SEARCH_ACCOUNTING_REPAIRING_ITEMS,
    payload: httpClient.get(`/AccountingRepair/AdvancePaymentManaging/search`, { params: params })
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const actionSearch = (value, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: ACCOUNTING_LIST_REPAIR_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/AccountingRepair/search?value=${value}&Page=${pageNo}&PageSize=${pageSize}`)
  });
};

export const updateStatusBehaft = (consignmentId, statusid) => (dispatch) => {
  return dispatch({
    type: ACCOUNTING_LIST_REPAIR_ACTIONS.UPDATE_STATUS_BEHAFT,
    payload: httpClient.get(`/AccountingRepair/AdvancePaymentManaging/${consignmentId}/changeStatus/${statusid}`)
  });
};

export const updateStatusSettlement = (consignmentId, statusid) => (dispatch) => {
  return dispatch({
    type: ACCOUNTING_LIST_REPAIR_ACTIONS.UPDATE_STATUS_SETTLEMENT,
    payload: httpClient.get(`/AccountingRepair/${consignmentId}/ChangeSettlementStatus/${statusid}`)
  });
};

export const createDataRepair = (payload) => (dispatch) => {
  return dispatch({
    type: ACCOUNTING_LIST_REPAIR_ACTIONS.CREATE_DATA_ACT_REPAIR,
    payload: httpClient.post(`/AccountingRepair/AdvancePaymentManaging/create`, payload)
  });
};

export const updateDataRepair = (payload) => (dispatch) => {
  return dispatch({
    type: ACCOUNTING_LIST_REPAIR_ACTIONS.UPDATE_DATA_ACT_REPAIR,
    payload: httpClient.put(`/AccountingRepair/AdvancePaymentManaging/update`, payload)
  });
};
