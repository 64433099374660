import {
  getAllCustomBranch
} from "services/custombranch";

// define const actions
export const CUSTOM_BRANCH_ACTIONS = {
  GET_ALL_CUSTOM_BRANCH: "GET_ALL_CUSTOM_BRANCH",
  GET_ALL_CUSTOM_BRANCH_PENDING: "GET_ALL_CUSTOM_BRANCH_PENDING",
  GET_ALL_CUSTOM_BRANCH_FULFILLED: "GET_ALL_CUSTOM_BRANCH_FULFILLED",
  GET_ALL_CUSTOM_BRANCH_REJECTED: "GET_ALL_CUSTOM_BRANCH_REJECTED",
};


// define actions
export const actionGetAllCustomBranch = () => dispatch => {
  return dispatch({
    type: CUSTOM_BRANCH_ACTIONS.GET_ALL_CUSTOM_BRANCH,
    payload: getAllCustomBranch()
  });
};
