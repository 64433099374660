import { SETTLEMENT_REPAIR } from "actions/repair/settlementRepair";
import { put, takeEvery } from "redux-saga/effects";
import { getDataSettlementRepair } from "services/repair/settlementRepair";

function* putActionUpdateData(actions) {
  //   const pageSize = parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE);
  //   console.log("putActionUpdateData ===", actions);

  yield put({
    type: SETTLEMENT_REPAIR.GET_ALL_DATA_SETTLEMENT,
    payload: getDataSettlementRepair(actions.meta.RepairingId),
  });
}

export function* actionUpdateSettlementRepairData() {
  yield takeEvery(
    [
      SETTLEMENT_REPAIR.UPDATE_SETTLEMENT_REPAIR_FULFILLED,
      SETTLEMENT_REPAIR.CREATE_SETTLEMENT_REPAIR_FULFILLED,
      SETTLEMENT_REPAIR.DELETE_SETTLEMENT_REPAIR_FULFILLED,
    ],
    putActionUpdateData
  );
}
