import { TRANSPORTATION_PLAN_ACTIONS } from "actions/transportationplan";
import { PAGE_SIZE } from "const/common";
import { put, takeEvery } from "redux-saga/effects";
import { getTransportationPlanListByConsignmentIDPerPage, searchTranportationPlan } from "services/transportationplan";

function* putActionUpdateData(actions) {
    yield put({
        type: TRANSPORTATION_PLAN_ACTIONS.GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_PER_PAGE,
        payload: getTransportationPlanListByConsignmentIDPerPage(
            actions.meta.consignmentID,
            1,
            PAGE_SIZE
        )
    });
}

function* putActionUpdateContData(actions) {
    yield put({
        type: TRANSPORTATION_PLAN_ACTIONS.GET_TRANSPORTATION_PLAN_LIST_BY_CONSIGNMENT_ID_PER_PAGE,
        payload: searchTranportationPlan(actions.meta)
    });
}

export function* actionUpdateTransportationPlanListData() {
    yield takeEvery(
        [
            TRANSPORTATION_PLAN_ACTIONS.UPDATE_TRANSPORTATION_PLAN_LIST_FULFILLED,
            TRANSPORTATION_PLAN_ACTIONS.UPDATE_TRANSPORTATION_PLAN_LIST_REJECTED,

            TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_FULFILLED,
            TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_REJECTED,

            TRANSPORTATION_PLAN_ACTIONS.DELETE_TRANSPORTATION_PLAN_LIST_FULFILLED,
            TRANSPORTATION_PLAN_ACTIONS.DELETE_TRANSPORTATION_PLAN_LIST_REJECTED,

            TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_LIST_FULFILLED,
            TRANSPORTATION_PLAN_ACTIONS.CREATE_TRANSPORTATION_PLAN_LIST_REJECTED
        ],
        putActionUpdateData
    );
    yield takeEvery(
        [
            TRANSPORTATION_PLAN_ACTIONS.UPDATE_CONT_DETAIL_FULFILLED,
            TRANSPORTATION_PLAN_ACTIONS.UPDATE_CONT_DETAIL_REJECTED,
        ],
        putActionUpdateContData
    );
}