import { EQUIPMENT_CATEGORY } from "actions/repair/equipmentCategory";

const EquipmentCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    //get all
    case EQUIPMENT_CATEGORY.GET_ALL_EQUIPMENT_CATEGORY_PENDING:
      return {
        ...state,
        isLoading: true,
      };

    case EQUIPMENT_CATEGORY.GET_ALL_EQUIPMENT_CATEGORY_FULFILLED:
      return {
        ...state,
        EquipmentCategory: action.payload.data.data,
        isLoading: false,
      };

    case EQUIPMENT_CATEGORY.GET_ALL_EQUIPMENT_CATEGORY_REJECTED :
      return {
        ...state,
        isLoading: false,
        typeMessage: "error",
        isMsg: true,
        msg: "Đã xảy ra lỗi",
      };

    default:
      return state;
  }
};

export default EquipmentCategoryReducer;
