import {
  CREATE_TRUCKING_PLAN_WORKING_FULFILLED,
  CREATE_TRUCKING_PLAN_WORKING_PENDING,
  CREATE_TRUCKING_PLAN_WORKING_REJECTED,
  CREATE_WORKING_FULFILLED,
  CREATE_WORKING_PENDING,
  CREATE_WORKING_REJECTED,
  DELETE_WORKING_FULFILLED,
  DELETE_WORKING_PENDING,
  DELETE_WORKING_REJECTED,
  FETCH_ALL_WORKINGS_FULFILLED,
  FETCH_ALL_WORKINGS_PENDING,
  FETCH_ALL_WORKINGS_REJECTED,
  FETCH_WORKING_DETAIL_FULFILLED,
  FETCH_WORKING_DETAIL_PENDING,
  FETCH_WORKING_DETAIL_REJECTED,
  FETCH_WORKINGS_BY_GROUP_ID_FULFILLED,
  FETCH_WORKINGS_BY_GROUP_ID_PENDING,
  FETCH_WORKINGS_BY_GROUP_ID_REJECTED,
  FETCH_WORKINGS_FULFILLED,
  FETCH_WORKINGS_PENDING,
  FETCH_WORKINGS_REJECTED,
  GET_REPAIR_STATATUS_INFO_FULFILLED,
  SET_CLOSE_WORKING_MODAL,
  SET_LIST_ACTIVE_TAB,
  SET_OPEN_WORKING_MODAL,
  UPDATE_WORKING_FULFILLED,
  UPDATE_WORKING_PENDING,
  UPDATE_WORKING_REJECTED
} from 'actions';
import { getErrorMessage } from 'commons/ReducerUtils';
import {
  DEFAULT_PAGE_SIZE,
  MSG_ADD_FAILURE,
  MSG_ADD_SUCCESS,
  MSG_DELETE_FAILURE,
  MSG_DELETE_SUCCESS,
  MSG_EDIT_FAILURE,
  MSG_EDIT_SUCCESS,
  MSG_GET_DETAIL_FAILURE,
  MSG_PAGING_FAILURE,
  MSG_TYPE_ERROR,
  MSG_TYPE_SUCCESS
} from 'const/common';
const INIT_STATE = {
  //combobox: get all
  allWorkings: [],
  // paging
  workings: [],
  pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
  listLoading: false,

  // add/edit modal
  isOpenFormModal: false, // Show/hidden update modal
  working: {},
  formLoading: false,
  //delete
  deleteLoading: false,
  // other
  showMessage: false,
  alertMessage: '',
  formAlertMessage: '',
  messageType: '',
  // list active tab
  listActiveTab: 0,
  CheckForm: [],
  Condition: [],
  ProposedPlan: [],
  ApprovalLeader: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region update modal
    case SET_OPEN_WORKING_MODAL: {
      return {
        ...state,
        isOpenFormModal: true,
        working: action.payload
      };
    }
    case SET_CLOSE_WORKING_MODAL: {
      return {
        ...state,
        isOpenFormModal: false
      };
    }
    //#endregion

    //#region list activeTab START
    case SET_LIST_ACTIVE_TAB: {
      return {
        ...state,
        listActiveTab: action.payload
      };
    }
    //#region list activeTab END

    //#region getall
    case FETCH_ALL_WORKINGS_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }
    case FETCH_ALL_WORKINGS_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        allWorkings: action.payload.data.data,
        showMessage: false
      };
    }
    case FETCH_ALL_WORKINGS_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region get by groupId
    case FETCH_WORKINGS_BY_GROUP_ID_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }

    case FETCH_WORKINGS_BY_GROUP_ID_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        workings: action.payload.data.data ?? INIT_STATE.workings,
        showMessage: false
      };
    }
    case FETCH_WORKINGS_BY_GROUP_ID_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region paging
    case FETCH_WORKINGS_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }

    case FETCH_WORKINGS_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        workings: action.payload.data.data ?? INIT_STATE.workings,
        pagination: action.payload.data.pagination ?? INIT_STATE.pagination,
        showMessage: false
      };
    }
    case FETCH_WORKINGS_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region create data
    case CREATE_WORKING_PENDING:
      return {
        ...state,
        formLoading: true
      };
    case CREATE_WORKING_FULFILLED: {
      const working = action.payload.data;
      const workings = state.workings.map((item) => {
        return item.Id === working.Id ? working : item;
      });
      return {
        ...state,
        workings: [...workings],
        formLoading: false,
        showMessage: true,
        formAlertMessage: MSG_ADD_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case CREATE_WORKING_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_ADD_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region update data
    case UPDATE_WORKING_PENDING:
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    case UPDATE_WORKING_FULFILLED: {
      const working = action.meta;
      let workings = [...state.workings];
      workings = workings.map((item) => {
        return item.Id === working.Id ? working : item;
      });
      return {
        ...state,
        workings,
        formLoading: false,
        showMessage: true,
        formAlertMessage: MSG_EDIT_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case UPDATE_WORKING_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_EDIT_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region  get detail
    case FETCH_WORKING_DETAIL_PENDING:
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    case FETCH_WORKING_DETAIL_FULFILLED:
      return {
        ...state,
        working: action.payload.data.data,
        formLoading: false,
        showMessage: false
      };
    case FETCH_WORKING_DETAIL_REJECTED:
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        alertMessage: MSG_GET_DETAIL_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    //#endregion

    //#region delete data
    case DELETE_WORKING_PENDING:
      return {
        ...state,
        deleteLoading: true
      };
    case DELETE_WORKING_FULFILLED:
      return {
        ...state,
        isDelete: true,
        deleteLoading: false,
        showMessage: true,
        alertMessage: MSG_DELETE_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case DELETE_WORKING_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_DELETE_FAILURE);
      return {
        ...state,
        deleteLoading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion
    //#region create data
    case CREATE_TRUCKING_PLAN_WORKING_PENDING:
      return {
        ...state,
        formLoading: true
      };
    case CREATE_TRUCKING_PLAN_WORKING_FULFILLED: {
      const working = action.payload.data;
      const workings = state.workings.map((item) => {
        return item.Id === working.Id ? working : item;
      });
      return {
        ...state,
        workings: [...workings],
        formLoading: false,
        showMessage: true,
        formAlertMessage: MSG_ADD_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case CREATE_TRUCKING_PLAN_WORKING_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_ADD_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    case GET_REPAIR_STATATUS_INFO_FULFILLED: {
      const data = action.payload.data.data.map(item => {
        return {
          ...item,
          value: item.Id,
          label: item.Name
        }
      })
      switch (action.meta) {
        case 1: return {
          ...state,
          CheckForm: data,
        };
        case 2: return {
          ...state,
          Condition: data,
        };
        case 3: return {
          ...state,
          ProposedPlan: data,
        };
        case 4: return {
          ...state,
          ApprovalLeader: data
        };
        default: return {
          ...state
        }
      }
    }
    //#endregion
    default:
      return state;
  }
};
