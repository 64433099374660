import { REPAIR_MOBILE_TYPE } from 'actions/mobile/RepairActions';

const INIT_STATE = {
  suggestRepair: [],
  userPosition: [],
  feeCategory: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REPAIR_MOBILE_TYPE.GET_WORKING_INFO_MOBILE_FULFILLED: {
      return {
        ...state,
        suggestRepair: action?.payload?.data?.data ?? []
      };
    }
    case REPAIR_MOBILE_TYPE.GET_USER_POSITiON_MOBILE_FULFILLED: {
      return {
        ...state,
        userPosition: action?.payload?.data?.data ?? []
      };
    }
    case REPAIR_MOBILE_TYPE.GET_BY_FEE_CATEGORY_ID_FULFILLED: {
      return {
        ...state,
        feeCategory: action?.payload?.data?.data ?? []
      };
    }
    default:
      return state;
  }
};
