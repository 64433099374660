import httpClient from 'HttpClient/index';

// define const actions
export const WAREHOUSE_EXPORT_ACTIONS = {
  GET_WAREHOUSE_EXPORT_PER_PAGE: 'GET_WAREHOUSE_EXPORT_PER_PAGE',
  GET_WAREHOUSE_EXPORT_PER_PAGE_PENDING: 'GET_WAREHOUSE_EXPORT_PER_PAGE_PENDING',
  GET_WAREHOUSE_EXPORT_PER_PAGE_FULFILLED: 'GET_WAREHOUSE_EXPORT_PER_PAGE_FULFILLED',
  GET_WAREHOUSE_EXPORT_PER_PAGE_REJECTED: 'GET_WAREHOUSE_EXPORT_PER_PAGE_REJECTED',

  GET_ALL_WAREHOUSE_STORE: 'GET_ALL_WAREHOUSE_STORE',
  GET_ALL_WAREHOUSE_STORE_PENDING: 'GET_ALL_WAREHOUSE_STORE_PENDING',
  GET_ALL_WAREHOUSE_STORE_FULFILLED: 'GET_ALL_WAREHOUSE_STORE_FULFILLED',
  GET_ALL_WAREHOUSE_STORE_REJECTED: 'GET_ALL_WAREHOUSE_STORE_REJECTED',

  GET_BY_ORDER_ID: 'GET_BY_ORDER_ID',
  GET_BY_ORDER_ID_PENDING: 'GET_BY_ORDER_ID_PENDING',
  GET_BY_ORDER_ID_FULFILLED: 'GET_BY_ORDER_ID_FULFILLED',
  GET_BY_ORDER_ID_REJECTED: 'GET_BY_ORDER_ID_REJECTED',

  CREATE_WAREHOUSE_EXPORT: 'CREATE_WAREHOUSE_EXPORT',
  CREATE_WAREHOUSE_EXPORT_PENDING: 'CREATE_WAREHOUSE_EXPORT_PENDING',
  CREATE_WAREHOUSE_EXPORT_FULFILLED: 'CREATE_WAREHOUSE_EXPORT_FULFILLED',
  CREATE_WAREHOUSE_EXPORT_REJECTED: 'CREATE_WAREHOUSE_EXPORT_REJECTED',

  UPDATE_WAREHOUSE_EXPORT: 'UPDATE_WAREHOUSE_EXPORT',
  UPDATE_WAREHOUSE_EXPORT_PENDING: 'UPDATE_WAREHOUSE_EXPORT_PENDING',
  UPDATE_WAREHOUSE_EXPORT_FULFILLED: 'UPDATE_WAREHOUSE_EXPORT_FULFILLED',
  UPDATE_WAREHOUSE_EXPORT_REJECTED: 'UPDATE_WAREHOUSE_EXPORT_REJECTED',

  DELETE_WAREHOUSE_EXPORT: 'DELETE_WAREHOUSE_EXPORT',
  DELETE_WAREHOUSE_EXPORT_PENDING: 'DELETE_WAREHOUSE_EXPORT_PENDING',
  DELETE_WAREHOUSE_EXPORT_FULFILLED: 'DELETE_WAREHOUSE_EXPORT_FULFILLED',
  DELETE_WAREHOUSE_EXPORT_REJECTED: 'DELETE_WAREHOUSE_EXPORT_REJECTED',

  GET_BILL_NOTE_BY_SPAREPART: 'GET_BILL_NOTE_BY_SPAREPART',
  GET_BILL_NOTE_BY_SPAREPART_PENDING: 'GET_BILL_NOTE_BY_SPAREPART_PENDING',
  GET_BILL_NOTE_BY_SPAREPART_FULFILLED: 'GET_BILL_NOTE_BY_SPAREPART_FULFILLED',
  GET_BILL_NOTE_BY_SPAREPART_REJECTED: 'GET_BILL_NOTE_BY_SPAREPART_REJECTED',

  GET_ALL_SPAREPARTDETAIL: "GET_ALL_SPAREPARTDETAIL",
  GET_ALL_SPAREPARTDETAIL_PENDING: "GET_ALL_SPAREPARTDETAIL_PENDING",
  GET_ALL_SPAREPARTDETAIL_FULFILLED: "GET_ALL_SPAREPARTDETAIL_FULFILLED",
  GET_ALL_SPAREPARTDETAIL_REJECTED: "GET_ALL_SPAREPARTDETAIL_REJECTED",
  
  SEARCH_BY_SPAREPART: 'SEARCH_BY_SPAREPART',
  SEARCH_BY_SPAREPART_PENDING: 'SEARCH_BY_SPAREPART_PENDING',
  SEARCH_BY_SPAREPART_FULFILLED: 'SEARCH_BY_SPAREPART_FULFILLED',
  SEARCH_BY_SPAREPART_REJECTED: 'SEARCH_BY_SPAREPART_REJECTED'
};

// define actions
export const actionGetWarehouseExportPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: WAREHOUSE_EXPORT_ACTIONS.GET_WAREHOUSE_EXPORT_PER_PAGE,
    payload: httpClient.get(`/WarehouseExport/paging/pageDetail?page=${pageNo}&pageSize=${pageSize}`)
  });
};

export const actionGetAllWarehouseStore = () => (dispatch) => {
  return dispatch({
    type: WAREHOUSE_EXPORT_ACTIONS.GET_ALL_WAREHOUSE_STORE,
    payload: httpClient.get(`/WarehouseExport/getAllWarehouseStore`)
  });
};

export const actionGetByOrderId = (orderId, sparePartId) => (dispatch) => {
  return dispatch({
    type: WAREHOUSE_EXPORT_ACTIONS.GET_ALL_WAREHOUSE_STORE,
    payload: httpClient.get(`/WarehouseExport/getById?warehouseExportOrderId=${orderId}&sparePartId=${sparePartId}`)
  });
};

export const createWarehouseExport = (payload) => (dispatch) => {
  return dispatch({
    type: WAREHOUSE_EXPORT_ACTIONS.CREATE_WAREHOUSE_EXPORT,
    payload: httpClient.post(`/WarehouseExport/create`, payload)
  });
};

export const updateWarehouseExport = (payload) => (dispatch) => {
  return dispatch({
    type: WAREHOUSE_EXPORT_ACTIONS.UPDATE_WAREHOUSE_EXPORT,
    payload: httpClient.put(`/WarehouseExport/update`, payload)
  });
};

export const getBillNoteBySparepart = (sparePartId, carId) => (dispatch) => {
  return dispatch({
    type: WAREHOUSE_EXPORT_ACTIONS.GET_BILL_NOTE_BY_SPAREPART,
    payload: httpClient.get(`/WarehouseExport/getWarehouseExportOrderBy?SparePartId=${sparePartId}&carId=${carId}`)
  });
};

export const searchBySparepart = (page, pageSize, params) => (dispatch) => {
  return dispatch({
    type: WAREHOUSE_EXPORT_ACTIONS.SEARCH_BY_SPAREPART,
    payload: httpClient.get(`/WarehouseExport/search/pageDetail`,{params : {...params, page, pageSize}})
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const getAllSparePartDetail = ({SparePartId,CustomerId}) => (dispatch) => {
  let url = `/WarehouseExport/getAllSparePartDetail?customerId=${CustomerId}&sparePartId=${SparePartId}`
  return dispatch({
    type: WAREHOUSE_EXPORT_ACTIONS.GET_ALL_SPAREPARTDETAIL,
    payload: httpClient.get(url)
  })
}

export const actionDeleteWarehouseExport = (item) => (dispatch) => {
  let url = `/WarehouseExport/deleteDetail?warehouseExportOrderId=${item.WarehouseExportOrderId}&providerId=${item.CustomerId}&sparePartId=${item.SparePartId}`
  return dispatch({
    type: WAREHOUSE_EXPORT_ACTIONS.DELETE_WAREHOUSE_EXPORT,
    payload: httpClient.delete(url)
  })
}