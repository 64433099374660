import {
  CREATE_QUOTATION_DETAIL_FULFILLED,
  CREATE_QUOTATION_DETAIL_PENDING,
  CREATE_QUOTATION_DETAIL_REJECTED,
  DELETE_FILE_QUOTATION_DETAIL_FULFILLED,
  DELETE_FILE_QUOTATION_DETAIL_PENDING,
  DELETE_FILE_QUOTATION_DETAIL_REJECTED,
  DELETE_QUOTATION_DETAIL_FULFILLED,
  DELETE_QUOTATION_DETAIL_PENDING,
  DELETE_QUOTATION_DETAIL_REJECTED,
  FETCH_ALL_QUOTATION_DETAILS_FULFILLED,
  FETCH_ALL_QUOTATION_DETAILS_PENDING,
  FETCH_ALL_QUOTATION_DETAILS_REJECTED,
  FETCH_QUOTATION_DETAIL_DETAIL_FULFILLED,
  FETCH_QUOTATION_DETAIL_DETAIL_PENDING,
  FETCH_QUOTATION_DETAIL_DETAIL_REJECTED,
  FETCH_QUOTATION_DETAILS_FULFILLED,
  FETCH_QUOTATION_DETAILS_PENDING,
  FETCH_QUOTATION_DETAILS_REJECTED,
  RESET_QUOTATION_DETAIL,
  SET_CLOSE_QUOTATION_DETAIL_APPROVAL_MODAL,
  SET_CLOSE_QUOTATION_DETAIL_MODAL,
  SET_OPEN_QUOTATION_DETAIL_APPROVAL_MODAL,
  SET_OPEN_QUOTATION_DETAIL_MODAL,
  UPDATE_QUOTATION_DETAIL_FULFILLED,
  UPDATE_QUOTATION_DETAIL_PENDING,
  UPDATE_QUOTATION_DETAIL_REJECTED,
  SET_OPEN_HISTORY_DETAIL_MODAL,
  SET_CLOSE_HISTORY_DETAIL_MODAL,
  QUOTATION_DETAIL_LOGS_FULFILLED
} from 'actions';
import { getErrorMessage } from 'commons/ReducerUtils';
import {
  DEFAULT_PAGE_SIZE,
  MSG_ADD_FAILURE,
  MSG_ADD_SUCCESS,
  MSG_DELETE_FAILURE,
  MSG_DELETE_SUCCESS,
  MSG_EDIT_FAILURE,
  MSG_EDIT_SUCCESS,
  MSG_GET_DETAIL_FAILURE,
  MSG_PAGING_FAILURE,
  MSG_TYPE_ERROR,
  MSG_TYPE_SUCCESS
} from 'const/common';
const INIT_STATE = {
  //combobox: get all
  allQuotationDetails: [],
  // paging
  quotationDetails: [],
  pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
  listLoading: false,

  // add/edit modal
  isOpenFormModal: false, // Show/hidden update modal
  isOpenHistoryFormModal: false,
  historyId: null,
  quantationHistory: [],
  quotationDetail: {},
  formLoading: false,
  isOpenApprovalFormModal: false,
  quotationDetailApproval: {},
  //delete
  deleteLoading: false,
  // other
  showMessage: false,
  alertMessage: '',
  formAlertMessage: '',
  messageType: '',
  QuotationId: '',
  QuotationSuppliers: [],
  typeForm: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region update modal
    case SET_OPEN_QUOTATION_DETAIL_MODAL: {
      console.log('reducer open form modal');
      return {
        ...state,
        isOpenFormModal: true,
        quotationDetail: action.payload.data,
        typeForm: action.payload.type ?? INIT_STATE.typeForm
      };
    }
    case SET_CLOSE_QUOTATION_DETAIL_MODAL: {
      return {
        ...state,
        isOpenFormModal: false,
        quotationDetail: INIT_STATE.quotationDetail,
        typeForm: null
      };
    }
    case SET_OPEN_HISTORY_DETAIL_MODAL: {
      return {
        ...state,
        isOpenHistoryFormModal: true,
        historyId: action.payload
      };
    }
    case SET_CLOSE_HISTORY_DETAIL_MODAL: {
      return {
        ...state,
        isOpenHistoryFormModal: false
      };
    }
    //#endregion

    //#region getall
    case FETCH_ALL_QUOTATION_DETAILS_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }
    case FETCH_ALL_QUOTATION_DETAILS_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        allQuotationDetails: action.payload.data.data,
        showMessage: false
      };
    }
    case FETCH_ALL_QUOTATION_DETAILS_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region paging
    case FETCH_QUOTATION_DETAILS_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }

    case FETCH_QUOTATION_DETAILS_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        quotationDetails: action.payload.data.data.data ?? INIT_STATE.quotationDetails,
        QuotationSuppliers: action.payload.data.data.extension ?? INIT_STATE.QuotationSuppliers,
        pagination: action.payload.data.pagination ?? INIT_STATE.pagination,
        showMessage: false,
        QuotationId: action?.meta?.id || ''
      };
    }
    case FETCH_QUOTATION_DETAILS_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region create data
    case CREATE_QUOTATION_DETAIL_PENDING:
      return {
        ...state,
        formLoading: true
      };
    case CREATE_QUOTATION_DETAIL_FULFILLED: {
      const quotationDetail = action.payload.data;
      const quotationDetails = state.quotationDetails.map((item) => {
        return item.Id === quotationDetail.Id ? quotationDetail : item;
      });
      return {
        ...state,
        quotationDetails: [...quotationDetails],
        formLoading: false,
        showMessage: true,
        formAlertMessage: MSG_ADD_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case CREATE_QUOTATION_DETAIL_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_ADD_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region update data
    case UPDATE_QUOTATION_DETAIL_PENDING:
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    case UPDATE_QUOTATION_DETAIL_FULFILLED: {
      const quotationDetail = action.meta;
      let quotationDetails = [...state.quotationDetails];
      quotationDetails = quotationDetails.map((item) => {
        return item.Id === quotationDetail.Id ? quotationDetail : item;
      });
      return {
        ...state,
        quotationDetails,
        formLoading: false,
        showMessage: true,
        formAlertMessage: MSG_EDIT_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case UPDATE_QUOTATION_DETAIL_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_EDIT_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region  get detail
    case FETCH_QUOTATION_DETAIL_DETAIL_PENDING:
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    case FETCH_QUOTATION_DETAIL_DETAIL_FULFILLED:
      return {
        ...state,
        quotationDetail: action.payload.data,
        formLoading: false,
        showMessage: false
      };
    case FETCH_QUOTATION_DETAIL_DETAIL_REJECTED:
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        alertMessage: MSG_GET_DETAIL_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    //#endregion

    //#region delete data
    case DELETE_QUOTATION_DETAIL_PENDING:
      return {
        ...state,
        deleteLoading: true
      };
    case DELETE_QUOTATION_DETAIL_FULFILLED:
      return {
        ...state,
        isDelete: true,
        deleteLoading: false,
        showMessage: true,
        alertMessage: MSG_DELETE_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case DELETE_QUOTATION_DETAIL_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_DELETE_FAILURE);
      return {
        ...state,
        deleteLoading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region update approval modal
    case SET_OPEN_QUOTATION_DETAIL_APPROVAL_MODAL: {
      console.log('reducer open form modal');
      return {
        ...state,
        isOpenApprovalFormModal: true,
        quotationDetailApproval: action.payload
      };
    }
    case SET_CLOSE_QUOTATION_DETAIL_APPROVAL_MODAL: {
      return {
        ...state,
        isOpenApprovalFormModal: false,
        quotationDetailApproval: INIT_STATE.quotationDetailApproval
      };
    }
    // delete files
    case DELETE_FILE_QUOTATION_DETAIL_PENDING:
      return {
        ...state,
        isLoading: true,
        isMsg: false
      };

    case DELETE_FILE_QUOTATION_DETAIL_FULFILLED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'success',
        isMsg: false
        // msg: 'Xóa dữ liệu thành công'
      };

    case DELETE_FILE_QUOTATION_DETAIL_REJECTED:
      return {
        ...state,
        isLoading: false,
        typeMessage: 'error',
        isMsg: true,
        msg: getErrorMessage(action, 'Xóa file thất bại!')
      };

    //logs history
    case QUOTATION_DETAIL_LOGS_FULFILLED:
      return {
        ...state,
        quantationHistory: action.payload.data.data
      };
    //#endregion
    // reset reducer
    case RESET_QUOTATION_DETAIL:
      return {
        ...INIT_STATE
      };
    default:
      return state;
  }
};
