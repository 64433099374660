import httpClient from 'HttpClient';

export const ANNUAL_FEE_ACTIONS = {
  GET_ANNUAL_FEE_PER_PAGE: 'GET_ANNUAL_FEE_PER_PAGE',
  GET_ANNUAL_FEE_PER_PAGE_PENDING: 'GET_ANNUAL_FEE_PER_PAGE_PENDING',
  GET_ANNUAL_FEE_PER_PAGE_FULFILLED: 'GET_ANNUAL_FEE_PER_PAGE_FULFILLED',
  GET_ANNUAL_FEE_PER_PAGE_REJECTED: 'GET_ANNUAL_FEE_PER_PAGE_REJECTED',

  CREATE_ANNUAL_FEE: 'CREATE_ANNUAL_FEE',
  CREATE_ANNUAL_FEE_PENDING: 'CREATE_ANNUAL_FEE_PENDING',
  CREATE_ANNUAL_FEE_FULFILLED: 'CREATE_ANNUAL_FEE_FULFILLED',
  CREATE_ANNUAL_FEE_REJECTED: 'CREATE_ANNUAL_FEE_REJECTED',

  UPDATE_ANNUAL_FEE: 'UPDATE_ANNUAL_FEE',
  UPDATE_ANNUAL_FEE_PENDING: 'UPDATE_ANNUAL_FEE_PENDING',
  UPDATE_ANNUAL_FEE_FULFILLED: 'UPDATE_ANNUAL_FEE_FULFILLED',
  UPDATE_ANNUAL_FEE_REJECTED: 'UPDATE_ANNUAL_FEE_REJECTED',

  DELETE_ANNUAL_FEE: 'DELETE_ANNUAL_FEE',
  DELETE_ANNUAL_FEE_PENDING: 'DELETE_ANNUAL_FEE_PENDING',
  DELETE_ANNUAL_FEE_FULFILLED: 'DELETE_ANNUAL_FEE_FULFILLED',
  DELETE_ANNUAL_FEE_REJECTED: 'DELETE_ANNUAL_FEE_REJECTED',

  SEARCH_ANNUAL_FEE: 'SEARCH_ANNUAL_FEE',
  SEARCH_ANNUAL_FEE_PENDING: 'SEARCH_ANNUAL_FEE_PENDING',
  SEARCH_ANNUAL_FEE_FULFILLED: 'SEARCH_ANNUAL_FEE_FULFILLED',
  SEARCH_ANNUAL_FEE_REJECTED: 'SEARCH_ANNUAL_FEE_REJECTED',

  GET_ALL_PROVIDER: 'GET_ALL_PROVIDER',
  GET_ALL_PROVIDER_PENDING: 'GET_ALL_PROVIDER_PENDING',
  GET_ALL_PROVIDER_FULFILLED: 'GET_ALL_PROVIDER_FULFILLED',
  GET_ALL_PROVIDER_REJECTED: 'GET_ALL_PROVIDER_REJECTED'
};

export const getDataPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: ANNUAL_FEE_ACTIONS.GET_ANNUAL_FEE_PER_PAGE,
    payload: httpClient.get(`/AnnualPaymentManaging/paging/page?Page=${pageNo}&PageSize=${pageSize}`)
  });
};

export const createAnnualFee = (payload) => (dispatch) => {
  return dispatch({
    type: ANNUAL_FEE_ACTIONS.CREATE_ANNUAL_FEE,
    payload: httpClient.post(`/AnnualPaymentManaging/create`, payload)
  });
};

export const updateAnnualFee = (payload) => (dispatch) => {
  return dispatch({
    type: ANNUAL_FEE_ACTIONS.UPDATE_ANNUAL_FEE,
    payload: httpClient.put(`/AnnualPaymentManaging/update`, payload)
  });
};

export const deleteAnnualFee = (id) => (dispatch) => {
  return dispatch({
    type: ANNUAL_FEE_ACTIONS.DELETE_ANNUAL_FEE,
    payload: httpClient.delete(`/AnnualPaymentManaging/delete/${id}`)
  });
};

export const searchAnnualFee = (params) => (dispatch) => {
  return dispatch({
    type: ANNUAL_FEE_ACTIONS.SEARCH_ANNUAL_FEE,
    payload: httpClient.get('/AnnualPaymentManaging/search', { params })
  });
};
export const searchAnnualFeeAll = (params) => (dispatch) => {
  return dispatch({
    // type: ANNUAL_FEE_ACTIONS.SEARCH_ANNUAL_FEE,
    payload: httpClient.get('/AnnualPaymentManaging/search', { params })
  });
};

export const getAllProvider = (id) => (dispatch) => {
  return dispatch({
    type: ANNUAL_FEE_ACTIONS.GET_ALL_PROVIDER,
    payload: httpClient.get(`/customerinfo/getByCustomerType/${id}`)
  });
};
