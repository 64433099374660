import httpClient from 'HttpClient';
// define const actions
export const DOWNPAYMENT_SELL_ACTIONS = {
  GET_DOWNPAYMENT_SELL_PER_PAGE: 'GET_DOWNPAYMENT_SELL_PER_PAGE',
  GET_DOWNPAYMENT_SELL_PER_PAGE_PENDING: 'GET_DOWNPAYMENT_SELL_PER_PAGE_PENDING',
  GET_DOWNPAYMENT_SELL_PER_PAGE_FULFILLED: 'GET_DOWNPAYMENT_SELL_PER_PAGE_FULFILLED',
  GET_DOWNPAYMENT_SELL_PER_PAGE_REJECTED: 'GET_DOWNPAYMENT_SELL_PER_PAGE_REJECTED',

  UPDATE_DOWNPAYMENT_SELL: 'UPDATE_DOWNPAYMENT_SELL',
  UPDATE_DOWNPAYMENT_SELL_PENDING: 'UPDATE_DOWNPAYMENT_SELL_PENDING',
  UPDATE_DOWNPAYMENT_SELL_FULFILLED: 'UPDATE_DOWNPAYMENT_SELL_FULFILLED',
  UPDATE_DOWNPAYMENT_SELL_REJECTED: 'UPDATE_DOWNPAYMENT_SELL_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  GET_STATUS: 'GET_STATUS',
  GET_STATUS_PENDING: 'GET_STATUS_PENDING',
  GET_STATUS_FULFILLED: 'GET_STATUS_FULFILLED',
  GET_STATUS_REJECTED: 'GET_STATUS_REJECTED',

  GET_EMPLOYEES: 'GET_EMPLOYEES',
  GET_EMPLOYEES_PENDING: 'GET_EMPLOYEES_PENDING',
  GET_EMPLOYEES_FULFILLED: 'GET_EMPLOYEES_FULFILLED',
  GET_EMPLOYEES_REJECTED: 'GET_EMPLOYEES_REJECTED',

  GET_SUMMARY: 'GET_SUMMARY',
  GET_SUMMARY_PENDING: 'GET_SUMMARY_PENDING',
  GET_SUMMARY_FULFILLED: 'GET_SUMMARY_FULFILLED',
  GET_SUMMARY_REJECTED: 'GET_SUMMARY_REJECTED'
};

// define actions
export const getListDataPerpage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_SELL_ACTIONS.GET_DOWNPAYMENT_SELL_PER_PAGE,
    payload: httpClient.get(`/containerdepositmanaging/paging/page?Page=${pageNo}&PageSize=${pageSize}`)
  });
};

export const updateData = (payload) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_SELL_ACTIONS.UPDATE_DOWNPAYMENT_SELL,
    payload: httpClient.put(`/containerdepositmanaging/update`, payload)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const actionSearch = (search, pageNo, pageSize) => (dispatch) => {
  const searchParams = {
    ...search,
    page: pageNo,
    pageSize
  };
  return dispatch({
    type: DOWNPAYMENT_SELL_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/containerdepositmanaging/search`, {
      params: searchParams
    })
  });
};
export const actionSearchAll = (params) => (dispatch) => {
  return dispatch({
    // type: DOWNPAYMENT_SELL_ACTIONS.SEARCH_DATA,
    payload: httpClient.get(`/containerdepositmanaging/search`, { params })
  });
};

export const getStatus = () => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_SELL_ACTIONS.GET_STATUS,
    payload: httpClient.get(`/StatusInfo/getBySatusCategoryId/4`)
  });
};

export const getEmployees = () => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_SELL_ACTIONS.GET_EMPLOYEES,
    payload: httpClient.get(`/employeeinfo/getAll/Officer`)
  });
};

// define actions
export const getSummary = (payload) => (dispatch) => {
  return dispatch({
    type: DOWNPAYMENT_SELL_ACTIONS.GET_SUMMARY,
    payload: httpClient.get(`/containerdepositmanaging/summary`, { params: payload })
  });
};
