import {
  createRepairInvoiceDetail,
  deleteRepairInvoiceDetail,
  getAllRepairInvoiceDetail,
  getRepairVoiceDetailById,
  updateRepairInvoiceDetail,
} from "services/repair/repairInvoiceDetails";

//Repair invoice details
export const REPAIR_DETAILS = {
  GET_REPAIR_INVOICE_DETAILS_BY_ID: "GET_REPAIR_INVOICE_DETAILS_BY_ID",
  GET_REPAIR_INVOICE_DETAILS_BY_ID_PENDING:
    "GET_REPAIR_INVOICE_DETAILS_BY_ID_PENDING",
  GET_REPAIR_INVOICE_DETAILS_BY_ID_FULFILLED:
    "GET_REPAIR_INVOICE_DETAILS_BY_ID_FULFILLED",
  GET_REPAIR_INVOICE_DETAILS_BY_ID_REJECTED:
    "GET_REPAIR_INVOICE_DETAILS_BY_ID_REJECTED",

  CREATE_REPAIR_INVOICE_DETAILS: "CREATE_REPAIR_INVOICE_DETAILS",
  CREATE_REPAIR_INVOICE_DETAILS_PENDING:
    "CREATE_REPAIR_INVOICE_DETAILS_PENDING",
  CREATE_REPAIR_INVOICE_DETAILS_FULFILLED:
    "CREATE_REPAIR_INVOICE_DETAILS_FULFILLED",
  CREATE_REPAIR_INVOICE_DETAILS_REJECTED:
    "CREATE_REPAIR_INVOICE_DETAILS_REJECTED",

  UPDATE_REPAIR_INVOICE_DETAILS: "UPDATE_REPAIR_INVOICE_DETAILS",
  UPDATE_REPAIR_INVOICE_DETAILS_PENDING:
    "UPDATE_REPAIR_INVOICE_DETAILS_PENDING",
  UPDATE_REPAIR_INVOICE_DETAILS_FULFILLED:
    "UPDATE_REPAIR_INVOICE_DETAILS_FULFILLED",
  UPDATE_REPAIR_INVOICE_DETAILS_REJECTED:
    "UPDATE_REPAIR_INVOICE_DETAILS_REJECTED",

  DELETE_REPAIR_INVOICE_DETAILS: "DELETE_REPAIR_INVOICE_DETAILS",
  DELETE_REPAIR_INVOICE_DETAILS_PENDING:
    "DELETE_REPAIR_INVOICE_DETAILS_PENDING",
  DELETE_REPAIR_INVOICE_DETAILS_FULFILLED:
    "DELETE_REPAIR_INVOICE_DETAILS_FULFILLED",
  DELETE_REPAIR_INVOICE_DETAILS_REJECTED:
    "DELETE_REPAIR_INVOICE_DETAILS_REJECTED",

  GET_ALL_REPAIR_INVOICE_DETAILS: "GET_ALL_REPAIR_INVOICE_DETAILS",
  GET_ALL_REPAIR_INVOICE_DETAILS_PENDING:
    "GET_ALL_REPAIR_INVOICE_DETAILS_PENDING",
  GET_ALL_REPAIR_INVOICE_DETAILS_FULFILLED:
    "GET_ALL_REPAIR_INVOICE_DETAILS_FULFILLED",
  GET_ALL_REPAIR_INVOICE_DETAILS_REJECTED:
    "GET_ALL_REPAIR_INVOICE_DETAILS_REJECTED",

  CHANGE_DATA_EDIT_TABEL_REPAIR: "CHANGE_DATA_EDIT_TABEL_REPAIR",
  CHANGE_DATA_EDIT_TABEL_REPAIR_FULFILLED:
    "CHANGE_DATA_EDIT_TABEL_REPAIR_FULFILLED",
};

//define action:
export const actionGetRepairVoiceDetailById = (id) => (dispatch) => {
  return dispatch({
    type: REPAIR_DETAILS.GET_REPAIR_INVOICE_DETAILS_BY_ID,
    payload: getRepairVoiceDetailById(id),
  });
};
//create
export const actionCreateRepairInvoiceDetail = (payload) => (dispatch) => {
  let RepairingId = payload.RepairingId;

  return dispatch({
    type: REPAIR_DETAILS.CREATE_REPAIR_INVOICE_DETAILS,
    payload: createRepairInvoiceDetail(payload),
    meta: {
      RepairingId: RepairingId,
    },
  });
};
//UPDATE
export const actionUpdateRepairInvoiceDetail = (payload) => (dispatch) => {
  let RepairingId = payload.RepairingId;
  return dispatch({
    type: REPAIR_DETAILS.UPDATE_REPAIR_INVOICE_DETAILS,
    payload: updateRepairInvoiceDetail(payload),
    meta: {
      RepairingId: RepairingId,
    },
  });
};
//DELETE
export const actionDeleteRepairInvoiceDetail = (
  repairingDetailId,
  repairingID,
  OutSource
) => (dispatch) => {
  return dispatch({
    type: REPAIR_DETAILS.DELETE_REPAIR_INVOICE_DETAILS,
    payload: deleteRepairInvoiceDetail(
      repairingDetailId,
      repairingID,
      OutSource
    ),
    meta: {
      RepairingId: repairingID,
    },
  });
};

//GET ALL
export const actionGetAllRepairInvoiceDetail = (id) => (dispatch) => {
  return dispatch({
    type: REPAIR_DETAILS.GET_ALL_REPAIR_INVOICE_DETAILS,
    payload: getAllRepairInvoiceDetail(id),
  });
};

export const changeDataEditTable = (fieldName, value, message) => {
  let check = value.includes(",");
  const regexDots = /\./g;
  const regexComma = /\,/g;
  let dataValue = !check
    ? value.replace(regexDots, "")
    : value.replace(regexComma, "");
  let error = {
    [fieldName]: message,
  };
  let data = {
    [fieldName]:
      !isNaN(dataValue) && dataValue !== ""
        ? parseInt(dataValue)
        : fieldName === "repairfee" && dataValue === ""
        ? 0
        : dataValue + "",
    isChange: true,
  };
  return {
    type: REPAIR_DETAILS.CHANGE_DATA_EDIT_TABEL_REPAIR,
    meta: { error, message },
    payload: data,
  };
};
