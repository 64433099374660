import httpClient from 'HttpClient';
// define const actions
export const SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS = {
  GET_LIST_SETTLEMENT_FOLLOW_CUSTOMER: 'GET_LIST_SETTLEMENT_FOLLOW_CUSTOMER',
  GET_LIST_SETTLEMENT_FOLLOW_CUSTOMER_PENDING: 'GET_LIST_SETTLEMENT_FOLLOW_CUSTOMER_PENDING',
  GET_LIST_SETTLEMENT_FOLLOW_CUSTOMER_FULFILLED: 'GET_LIST_SETTLEMENT_FOLLOW_CUSTOMER_FULFILLED',
  GET_LIST_SETTLEMENT_FOLLOW_CUSTOMER_REJECTED: 'GET_LIST_SETTLEMENT_FOLLOW_CUSTOMER_REJECTED',

  CREATE_TEMPLATE: 'CREATE_TEMPLATE',
  CREATE_TEMPLATE_PENDING: 'CREATE_TEMPLATE_PENDING',
  CREATE_TEMPLATE_FULFILLED: 'CREATE_TEMPLATE_FULFILLED',
  CREATE_TEMPLATE_REJECTED: 'CREATE_TEMPLATE_REJECTED',

  CHANGE_DATA_EDIT_TABEL_FIELD_SETTLEMENT: 'CHANGE_DATA_EDIT_TABEL_FIELD_SETTLEMENT',
  ON_KEY_UP_SETTLEMENT: 'ON_KEY_UP_SETTLEMENT',

  UPDATE_SETTLEMENT_FOLLOW_CUSTUMER: 'UPDATE_SETTLEMENT_FOLLOW_CUSTUMER',
  UPDATE_SETTLEMENT_FOLLOW_CUSTUMER_PENDING: 'UPDATE_SETTLEMENT_FOLLOW_CUSTUMER_PENDING',
  UPDATE_SETTLEMENT_FOLLOW_CUSTUMER_FULFILLED: 'UPDATE_SETTLEMENT_FOLLOW_CUSTUMER_FULFILLED',
  UPDATE_SETTLEMENT_FOLLOW_CUSTUMER_REJECTED: 'UPDATE_SETTLEMENT_FOLLOW_CUSTUMER_REJECTED',

  CREATE_DATA_SETTLEMENT: 'CREATE_DATA_SETTLEMENT',
  CREATE_DATA_SETTLEMENT_PENDING: 'CREATE_DATA_SETTLEMENT_PENDING',
  CREATE_DATA_SETTLEMENT_FULFILLED: 'CREATE_DATA_SETTLEMENT_FULFILLED',
  CREATE_DATA_SETTLEMENT_REJECTED: 'CREATE_DATA_SETTLEMENT_REJECTED',

  GET_SUMMARY_DATA_EX: 'GET_SUMMARY_DATA_EX',
  GET_SUMMARY_DATA_EX_PENDING: 'GET_SUMMARY_DATA_EX_PENDING',
  GET_SUMMARY_DATA_EX_FULFILLED: 'GET_SUMMARY_DATA_EX_FULFILLED',
  GET_SUMMARY_DATA_EX_REJECTED: 'GET_SUMMARY_DATA_EX_REJECTED',

  GET_CONSIGNMENT_INFO_BY_ID: 'GET_CONSIGNMENT_INFO_BY_ID',
  GET_CONSIGNMENT_INFO_BY_ID_PENDING: 'GET_CONSIGNMENT_INFO_BY_ID_PENDING',
  GET_CONSIGNMENT_INFO_BY_ID_FULFILLED: 'GET_CONSIGNMENT_INFO_BY_ID_FULFILLED',
  GET_CONSIGNMENT_INFO_BY_ID_REJECTED: 'GET_CONSIGNMENT_INFO_BY_ID_REJECTED',

  UPDATE_CONSIMENT_INFO_EXTENDS: 'UPDATE_CONSIMENT_INFO_EXTENDS',
  UPDATE_CONSIMENT_INFO_EXTENDS_PENDING: 'UPDATE_CONSIMENT_INFO_EXTENDS_PENDING',
  UPDATE_CONSIMENT_INFO_EXTENDS_FULFILLED: 'UPDATE_CONSIMENT_INFO_EXTENDS_FULFILLED',
  UPDATE_CONSIMENT_INFO_EXTENDS_REJECTED: 'UPDATE_CONSIMENT_INFO_EXTENDS_REJECTED',

  DELETE_DATA_SETTLEMENT_CUSTOMER: 'DELETE_DATA_SETTLEMENT_CUSTOMER',
  DELETE_DATA_SETTLEMENT_CUSTOMER_PENDING: 'DELETE_DATA_SETTLEMENT_CUSTOMER_PENDING',
  DELETE_DATA_SETTLEMENT_CUSTOMER_FULFILLED: 'DELETE_DATA_SETTLEMENT_CUSTOMER_FULFILLED',
  DELETE_DATA_SETTLEMENT_CUSTOMER_REJECTED: 'DELETE_DATA_SETTLEMENT_CUSTOMER_REJECTED',

  FILTER_DATA_NOT_HAS_BILL_INFO: 'FILTER_DATA_NOT_HAS_BILL_INFO',
  FILTER_DATA_NOT_HAS_BILL_INFO_PENDING: 'FILTER_DATA_NOT_HAS_BILL_INFO_PENDING',
  FILTER_DATA_NOT_HAS_BILL_INFO_FULFILLED: 'FILTER_DATA_NOT_HAS_BILL_INFO_FULFILLED',
  FILTER_DATA_NOT_HAS_BILL_INFO_REJECTED: 'FILTER_DATA_NOT_HAS_BILL_INFO_REJECTED',

  SET_DEFAULT_VALUE_PROPS: 'SET_DEFAULT_VALUE_PROPS',

  CHANGE_FEE_CATEGORY: 'CHANGE_FEE_CATEGORY',
  CHANGE_FEE_CATEGORY_PENDING: 'CHANGE_FEE_CATEGORY_PENDING',
  CHANGE_FEE_CATEGORY_FULFILLED: 'CHANGE_FEE_CATEGORY_FULFILLED',
  CHANGE_FEE_CATEGORY_REJECTED: 'CHANGE_FEE_CATEGORY_REJECTED',

  CUSTOMER_SET_ELEMENT: 'CUSTOMER_SET_ELEMENT',
  CUSTOMER_SET_ELEMENT_PENDING: 'CUSTOMER_SET_ELEMENT_PENDING',
  CUSTOMER_SET_ELEMENT_FULFILLED: 'CUSTOMER_SET_ELEMENT_FULFILLED',
  CUSTOMER_SET_ELEMENT_REJECTED: 'CUSTOMER_SET_ELEMENT_REJECTED'
};

// define actions
export const getList = (consigmentId) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.GET_LIST_SETTLEMENT_FOLLOW_CUSTOMER,
    payload: httpClient.get(`/customersettlement/getList/${consigmentId}`)
  });
};

export const actionChangeFeeCategoryInSettlementCustomer = (id, feeCategoryId) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.CHANGE_FEE_CATEGORY,
    payload: httpClient.put(`/AdvanceOnConsignment/${id}/changeFeeCategoryId/${feeCategoryId}`)
  });
};

export const updateShowAlert = () => {
  return {
    type: 'UPDATE_SHOW_ALERT'
  };
};

export const createTemplate = (consigmentId) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.CREATE_TEMPLATE,
    payload: httpClient.get(`/customersettlement/create/${consigmentId}`)
  });
};

const moneyField = ['ActualUnitPrice', 'ActualTotalAmount', 'ContractUnitPrice', 'ContractTotalAmount'];
const floatNumber = ['ActualQuantity'];
export const changeEditTableFieldSettlement = (id, fieldName, value, message, item, acceptDecimal) => {
  let checkValueIsBoolean = 'boolean';
  let check = (value + '').includes(',');
  const regexDots = /\./g;
  const regexComma = /\,/g;
  let dataValue =
    typeof value === checkValueIsBoolean
      ? value
      : !check && !acceptDecimal
      ? value.replace(regexDots, '')
      : value.replace(regexComma, '');

  let error = {
    [fieldName]: message
  };
  const isFloatNumber = floatNumber.includes(fieldName);
  let data = null;
  if (Array.isArray(id)) {
    data = id.map((Id) => ({
      Id: Id,
      [fieldName]:
        typeof value === checkValueIsBoolean
          ? value
          : fieldName === 'BillNo'
          ? dataValue + ''
          : isFloatNumber
          ? dataValue
          : acceptDecimal && dataValue.endsWith('.')
          ? dataValue
          : !isNaN(dataValue) && dataValue !== ''
          ? acceptDecimal
            ? Math.round(Number(dataValue) * 1000) / 1000
            : parseInt(dataValue)
          : moneyField.includes(fieldName) && dataValue === ''
          ? 0
          : dataValue + '',
      isChange: true
    }));
  } else {
    data = {
      Id: id,
      [fieldName]:
        typeof value === checkValueIsBoolean
          ? value
          : fieldName === 'BillNo'
          ? dataValue + ''
          : isFloatNumber
          ? dataValue
          : acceptDecimal && dataValue.endsWith('.')
          ? dataValue
          : !isNaN(dataValue) && dataValue !== ''
          ? acceptDecimal
            ? Math.round(Number(dataValue) * 1000) / 1000
            : parseInt(dataValue)
          : moneyField.includes(fieldName) && dataValue === ''
          ? 0
          : dataValue + '',
      isChange: true
    };
  }
  console.log(value, dataValue, data, floatNumber.includes(fieldName));

  return {
    type: SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.CHANGE_DATA_EDIT_TABEL_FIELD_SETTLEMENT,
    meta: {
      error,
      message,
      checkValueIsBoolean,
      value,
      fieldName
    },
    payload: data
  };
};

export const onKeyUpActionsFieldSettlement = (id, isFocusTotal, type, fieldName) => {
  let data = {
    Id: id,
    isFocus: true,
    isFocusTotal: isFocusTotal,
    type: type,
    fieldName
  };

  return {
    type: SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.ON_KEY_UP_SETTLEMENT,
    payload: data
  };
};

export const actionUpdateSettlementCustumers = (id, payload) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.UPDATE_SETTLEMENT_FOLLOW_CUSTUMER,
    payload: httpClient.put(`/CustomerSettlement/${id}/update`, payload)
  });
};

export const actionCreateDataSettlementCustomer = (consigmentId, payload) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.CREATE_DATA_SETTLEMENT,
    payload: httpClient.post(`/CustomerSettlement/${consigmentId}/insertCustomerSettlement`, payload)
  });
};

export const actionGetSummaryDataEx = (consigmentId) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.GET_SUMMARY_DATA_EX,
    payload: httpClient.get(`/Consignment/GetServiceFeeAndIncurredCost/${consigmentId}`)
  });
};

export const getConsignmentInfoById = (consigmentId) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.GET_CONSIGNMENT_INFO_BY_ID,
    payload: httpClient.get(`/Consignment/getById/${consigmentId}`)
  });
};

export const updateConsignmentInfoExtends = (payload) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.UPDATE_SETTLEMENT_FOLLOW_CUSTUMER,
    payload: httpClient.put(`/Consignment/update`, payload)
  });
};

export const setValueDefaultPropsDataFee = (payload) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.SET_DEFAULT_VALUE_PROPS,
    payload: payload
  });
};

export const deleteDataSettlementCustomers = (id) => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.DELETE_DATA_SETTLEMENT_CUSTOMER,
    payload: httpClient.delete(`/CustomerSettlement/delete/${id}`)
  });
};

export const filterSettlementNotHasBillInfo = () => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.FILTER_DATA_NOT_HAS_BILL_INFO,
    payload: httpClient.get(`/Consignment/getConsignmentNotUpdateBillNo`)
  });
};
export const customersettlement = () => (dispatch) => {
  return dispatch({
    type: SETTLEMENT_FOLLOW_CUSTOMER_ACTIONS.CUSTOMER_SET_ELEMENT,
    payload: httpClient.get(`/customersettlement/getExpired`)
  });
};

export const customerSettlementTotalfee = (id) => (dispatch) => {
  return dispatch({
    payload: httpClient.get(`/customerSettlement/totalfee/${id}`)
  });
};
