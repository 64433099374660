import http from 'HttpClient/index';
import {
  createOilPricesManagement,
  deleteOilPricesManagement,
  getAllOilPricesManagement,
  getOilPricesManagementByID,
  getOilPricesManagementPerPage,
  getOilPricesManagementPerPageAll,
  searchData,
  searchDataAll,
  updateOilPricesManagement
} from 'services/oilpricesmanagement';

// define const actions
export const OIL_PRICES_MANAGEMENT_ACTIONS = {
  GET_ALL_OIL_PRICES_MANAGEMENT: 'GET_ALL_OIL_PRICES_MANAGEMENT',
  GET_ALL_OIL_PRICES_MANAGEMENT_PENDING: 'GET_ALL_OIL_PRICES_MANAGEMENT_PENDING',
  GET_ALL_OIL_PRICES_MANAGEMENT_FULFILLED: 'GET_ALL_OIL_PRICES_MANAGEMENT_FULFILLED',
  GET_ALL_OIL_PRICES_MANAGEMENT_REJECTED: 'GET_ALL_OIL_PRICES_MANAGEMENT_REJECTED',

  GET_OIL_PRICES_MANAGEMENT_PER_PAGE: 'GET_OIL_PRICES_MANAGEMENT_PER_PAGE',
  GET_OIL_PRICES_MANAGEMENT_PER_PAGE_PENDING: 'GET_OIL_PRICES_MANAGEMENT_PER_PAGE_PENDING',
  GET_OIL_PRICES_MANAGEMENT_PER_PAGE_FULFILLED: 'GET_OIL_PRICES_MANAGEMENT_PER_PAGE_FULFILLED',
  GET_OIL_PRICES_MANAGEMENT_PER_PAGE_REJECTED: 'GET_OIL_PRICES_MANAGEMENT_PER_PAGE_REJECTED',

  GET_OIL_PRICES_MANAGEMENT_BY_ID: 'GET_OIL_PRICES_MANAGEMENT_BY_ID',
  GET_OIL_PRICES_MANAGEMENT_BY_ID_PENDING: 'GET_OIL_PRICES_MANAGEMENT_BY_ID_PENDING',
  GET_OIL_PRICES_MANAGEMENT_BY_ID_FULFILLED: 'GET_OIL_PRICES_MANAGEMENT_BY_ID_FULFILLED',
  GET_OIL_PRICES_MANAGEMENT_BY_ID_REJECTED: 'GET_OIL_PRICES_MANAGEMENT_BY_ID_REJECTED',

  CREATE_OIL_PRICES_MANAGEMENT: 'CREATE_OIL_PRICES_MANAGEMENT',
  CREATE_OIL_PRICES_MANAGEMENT_PENDING: 'CREATE_OIL_PRICES_MANAGEMENT_PENDING',
  CREATE_OIL_PRICES_MANAGEMENT_FULFILLED: 'CREATE_OIL_PRICES_MANAGEMENT_FULFILLED',
  CREATE_OIL_PRICES_MANAGEMENT_REJECTED: 'CREATE_OIL_PRICES_MANAGEMENT_REJECTED',

  UPDATE_OIL_PRICES_MANAGEMENT: 'UPDATE_OIL_PRICES_MANAGEMENT',
  UPDATE_OIL_PRICES_MANAGEMENT_PENDING: 'UPDATE_OIL_PRICES_MANAGEMENT_PENDING',
  UPDATE_OIL_PRICES_MANAGEMENT_FULFILLED: 'UPDATE_OIL_PRICES_MANAGEMENT_FULFILLED',
  UPDATE_OIL_PRICES_MANAGEMENT_REJECTED: 'UPDATE_OIL_PRICES_MANAGEMENT_REJECTED',

  DELETE_OIL_PRICES_MANAGEMENT: 'DELETE_OIL_PRICES_MANAGEMENT',
  DELETE_OIL_PRICES_MANAGEMENT_PENDING: 'DELETE_OIL_PRICES_MANAGEMENT_PENDING',
  DELETE_OIL_PRICES_MANAGEMENT_FULFILLED: 'DELETE_OIL_PRICES_MANAGEMENT_FULFILLED',
  DELETE_OIL_PRICES_MANAGEMENT_REJECTED: 'DELETE_OIL_PRICES_MANAGEMENT_REJECTED',

  SEARCH_DATA: 'SEARCH_DATA',
  SEARCH_DATA_PENDING: 'SEARCH_DATA_PENDING',
  SEARCH_DATA_FULFILLED: 'SEARCH_DATA_FULFILLED',
  SEARCH_DATA_REJECTED: 'SEARCH_DATA_REJECTED',

  UPDATE_OIL_PRICES_TRUCKING_PLAN: 'UPDATE_OIL_PRICES_TRUCKING_PLAN',
  UPDATE_OIL_PRICES_TRUCKING_PLAN_PENDING: 'UPDATE_OIL_PRICES_TRUCKING_PLAN_PENDING',
  UPDATE_OIL_PRICES_TRUCKING_PLAN_FULFILLED: 'UPDATE_OIL_PRICES_TRUCKING_PLAN_FULFILLED',
  UPDATE_OIL_PRICES_TRUCKING_PLAN_REJECTED: 'UPDATE_OIL_PRICES_TRUCKING_PLAN_REJECTED',

  UPDATE_OIL_PRICES_TRUCKING_PLAN_WITH_PHASE: 'UPDATE_OIL_PRICES_TRUCKING_PLAN_WITH_PHASE',
  UPDATE_OIL_PRICES_TRUCKING_PLAN_WITH_PHASE_PENDING: 'UPDATE_OIL_PRICES_TRUCKING_PLAN_WITH_PHASE_PENDING',
  UPDATE_OIL_PRICES_TRUCKING_PLAN_WITH_PHASE_FULFILLED: 'UPDATE_OIL_PRICES_TRUCKING_PLAN_WITH_PHASE_FULFILLED',
  UPDATE_OIL_PRICES_TRUCKING_PLAN_WITH_PHASE_REJECTED: 'UPDATE_OIL_PRICES_TRUCKING_PLAN_WITH_PHASE_REJECTED'
};

// define actions
export const actionGetAllOilPricesManagement = () => (dispatch) => {
  return dispatch({
    type: OIL_PRICES_MANAGEMENT_ACTIONS.GET_ALL_OIL_PRICES_MANAGEMENT,
    payload: getAllOilPricesManagement()
  });
};

export const actionGetOilPricesManagementPerPage = (pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: OIL_PRICES_MANAGEMENT_ACTIONS.GET_OIL_PRICES_MANAGEMENT_PER_PAGE,
    payload: getOilPricesManagementPerPage(pageNo, pageSize)
  });
};
export const actionGetOilPricesManagementPerPageAll = (params) => (dispatch) => {
  return dispatch({
    // type: OIL_PRICES_MANAGEMENT_ACTIONS.GET_OIL_PRICES_MANAGEMENT_PER_PAGE,
    payload: getOilPricesManagementPerPageAll(params)
  });
};

export const actionGetOilPricesManagementByID = (id) => (dispatch) => {
  return dispatch({
    type: OIL_PRICES_MANAGEMENT_ACTIONS.GET_OIL_PRICES_MANAGEMENT_BY_ID,
    payload: getOilPricesManagementByID(id)
  });
};

export const actionCreateOilPricesManagement = (payload) => (dispatch) => {
  return dispatch({
    type: OIL_PRICES_MANAGEMENT_ACTIONS.CREATE_OIL_PRICES_MANAGEMENT,
    payload: createOilPricesManagement(payload)
  });
};

export const actionUpdateOilPricesManagement = (payload) => (dispatch) => {
  return dispatch({
    type: OIL_PRICES_MANAGEMENT_ACTIONS.UPDATE_OIL_PRICES_MANAGEMENT,
    payload: updateOilPricesManagement(payload)
  });
};

export const actionDeleteOilPricesManagement = (id) => (dispatch) => {
  return dispatch({
    type: OIL_PRICES_MANAGEMENT_ACTIONS.DELETE_OIL_PRICES_MANAGEMENT,
    payload: deleteOilPricesManagement(id)
  });
};

export const actionSearchData = (paramsSearch, pageNo, pageSize) => (dispatch) => {
  return dispatch({
    type: OIL_PRICES_MANAGEMENT_ACTIONS.SEARCH_DATA,
    payload: searchData(paramsSearch, pageNo, pageSize)
  });
};
export const actionSearchDataAll = (params) => (dispatch) => {
  return dispatch({
    // type: OIL_PRICES_MANAGEMENT_ACTIONS.SEARCH_DATA,
    payload: searchDataAll(params)
  });
};

export const actionUpdateOilPricesTruckingPlan = (payload) => (dispatch) => {
  return dispatch({
    type: OIL_PRICES_MANAGEMENT_ACTIONS.UPDATE_OIL_PRICES_TRUCKING_PLAN,
    payload: http.put('/OilPriceInfo/updateOilPriceTruckingPLan', payload)
  });
};

export const actionUpdateOilPricesWithPhase = (payload) => (dispatch) => {
  return dispatch({
    type: OIL_PRICES_MANAGEMENT_ACTIONS.UPDATE_OIL_PRICES_TRUCKING_PLAN_WITH_PHASE,
    payload: http.put('OilPriceInfo/updateOilPriceTruckingPLanWithPhase', payload)
  });
};

export const actionUpdateShowAlert = () => (dispatch) => {
  return dispatch({
    type: 'UPDATE_SHOW_ALERT_OIL_MANAGEMENT'
  });
};
