import { TRANSPORT_COMPANY_ACTIONS } from 'actions/transportCompanyInfo';

const INIT_STATE = {
  transportCompany: {
    data: []
  }
};

const transportCompanyReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    // get all goods type
    case TRANSPORT_COMPANY_ACTIONS.GET_ALL_TRANSPORT_COMPANY_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRANSPORT_COMPANY_ACTIONS.GET_ALL_TRANSPORT_COMPANY_FULFILLED: {
      let transportCompany = {
        data: action.payload.data.data
      };
      return {
        ...state,
        transportCompany: transportCompany,
        isLoading: false
      };
    }

    case TRANSPORT_COMPANY_ACTIONS.GET_ALL_TRANSPORT_COMPANY_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };
    //get driver by company:
    case TRANSPORT_COMPANY_ACTIONS.GET_DRIVER_BY_TRANSPORT_COMPANY_PENDING: {
      return {
        ...state,
        loadingSelect: true,
        isMsg: false
      };
    }
    case TRANSPORT_COMPANY_ACTIONS.GET_DRIVER_BY_TRANSPORT_COMPANY_FULFILLED: {
      return {
        ...state,
        loadingSelect: false,
        drivers: action.payload.data.data,
        isLoading: false,
        isMsg: false
      };
    }
    case TRANSPORT_COMPANY_ACTIONS.GET_DRIVER_BY_TRANSPORT_COMPANY_REJECTED: {
      return {
        ...state,
        loadingSelect: false,
        isMsg: true,
        typeMessage: 'error',
        message: 'Có lỗi xảy ra !'
      };
    }

    //get by id
    case TRANSPORT_COMPANY_ACTIONS.GET_TRANSPORT_COMPANY_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRANSPORT_COMPANY_ACTIONS.GET_TRANSPORT_COMPANY_BY_ID_FULFILLED:
      return {
        ...state,
        dataById: action.payload.data.data,
        isLoading: false
      };

    case TRANSPORT_COMPANY_ACTIONS.GET_TRANSPORT_COMPANY_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    case TRANSPORT_COMPANY_ACTIONS.GET_TRANSPORT_CUSTOMER_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRANSPORT_COMPANY_ACTIONS.GET_TRANSPORT_CUSTOMER_FULFILLED:
      let transportCompany = {
        data: action.payload.data.data
      };
      return {
        ...state,
        transportCompany: transportCompany,
        isLoading: false
      };

    case TRANSPORT_COMPANY_ACTIONS.GET_TRANSPORT_CUSTOMER_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    case TRANSPORT_COMPANY_ACTIONS.GET_TRANSPORT_CUSTOMER_BY_TYPE_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TRANSPORT_COMPANY_ACTIONS.GET_TRANSPORT_CUSTOMER_BY_TYPE_FULFILLED:
      let transportCompany1 = {
        data: action.payload.data.data
      };
      return {
        ...state,
        transportCompany: transportCompany1,
        isLoading: false
      };

    case TRANSPORT_COMPANY_ACTIONS.GET_TRANSPORT_CUSTOMER_BY_TYPE_REJECTED:
      return {
        ...state,
        isLoading: false,
        isMsg: true,
        msg: 'Đã xảy ra lỗi'
      };

    default:
      return state;
  }
};

export default transportCompanyReducer;
