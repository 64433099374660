import { NORM_MANAGEMENT_TYPE } from "actions/normmanagementAction";
const INIT_STATE = {
    data: [],
    pagination:{},
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case NORM_MANAGEMENT_TYPE.NORM_MANAGEMENT_PENDING:
            return {
                ...state,
                loading: true,
            };
        case NORM_MANAGEMENT_TYPE.NORM_MANAGEMENT_FULFILLED:
            return {
                ...state,
                data: action.payload.data.data,
                pagination: action.payload.data.pagination,
                loading: false,
            };
        case NORM_MANAGEMENT_TYPE.NORM_MANAGEMENT_REJECTED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};
