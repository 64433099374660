import api from 'HttpClient';

export const SET_OPEN_WORKING_HISTORY_DETAIL_MODAL = 'SET_OPEN_WORKING_HISTORY_DETAIL_MODAL';
export const setOpenWorkingHistoryDetailModal = (data) => (dispatch) => {
  return dispatch({
    type: SET_OPEN_WORKING_HISTORY_DETAIL_MODAL,
    payload: data
  });
};

export const SET_CLOSE_WORKING_HISTORY_DETAIL_MODAL = 'SET_CLOSE_WORKING_HISTORY_DETAIL_MODAL';
export const setCloseWorkingHistoryDetailModal = () => (dispatch) => {
  return dispatch({
    type: SET_CLOSE_WORKING_HISTORY_DETAIL_MODAL
  });
};

export const FETCH_ALL_WORKING_HISTORY_DETAILS = 'FETCH_ALL_WORKING_HISTORY_DETAILS';
export const FETCH_ALL_WORKING_HISTORY_DETAILS_PENDING = 'FETCH_ALL_WORKING_HISTORY_DETAILS_PENDING';
export const FETCH_ALL_WORKING_HISTORY_DETAILS_FULFILLED = 'FETCH_ALL_WORKING_HISTORY_DETAILS_FULFILLED';
export const FETCH_ALL_WORKING_HISTORY_DETAILS_REJECTED = 'FETCH_ALL_WORKING_HISTORY_DETAILS_REJECTED';
export const fetchAllWorkingHistoryDetails = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_WORKING_HISTORY_DETAILS,
    payload: api.get(`/WorkingHistoryDetailInfo/getAll`)
  });
};

// const type of function get data per page
export const FETCH_WORKING_HISTORY_DETAILS = 'FETCH_WORKING_HISTORY_DETAILS';
export const FETCH_WORKING_HISTORY_DETAILS_PENDING = 'FETCH_WORKING_HISTORY_DETAILS_PENDING';
export const FETCH_WORKING_HISTORY_DETAILS_FULFILLED = 'FETCH_WORKING_HISTORY_DETAILS_FULFILLED';
export const FETCH_WORKING_HISTORY_DETAILS_REJECTED = 'FETCH_WORKING_HISTORY_DETAILS_REJECTED';
export const fetchWorkingHistoryDetails = (searchPayload, page, pageSize) => (dispatch) => {
  return dispatch({
    type: FETCH_WORKING_HISTORY_DETAILS,
    payload: api.get(`/WorkingHistoryDetailInfo/Search`, { params: { ...searchPayload, page, pageSize: pageSize } })
  });
};

// const type of function create data
export const CREATE_WORKING_HISTORY_DETAIL = 'CREATE_WORKING_HISTORY_DETAIL';
export const CREATE_WORKING_HISTORY_DETAIL_PENDING = 'CREATE_WORKING_HISTORY_DETAIL_PENDING';
export const CREATE_WORKING_HISTORY_DETAIL_FULFILLED = 'CREATE_WORKING_HISTORY_DETAIL_FULFILLED';
export const CREATE_WORKING_HISTORY_DETAIL_REJECTED = 'CREATE_WORKING_HISTORY_DETAIL_REJECTED';
export const createWorkingHistoryDetail = (payload) => (dispatch) => {
  return dispatch({
    type: CREATE_WORKING_HISTORY_DETAIL,
    payload: api.post(`/WorkingHistoryDetailInfo/create`, payload)
  });
};

// const type of function update data
export const UPDATE_WORKING_HISTORY_DETAIL = 'UPDATE_WORKING_HISTORY_DETAIL';
export const UPDATE_WORKING_HISTORY_DETAIL_PENDING = 'UPDATE_WORKING_HISTORY_DETAIL_PENDING';
export const UPDATE_WORKING_HISTORY_DETAIL_FULFILLED = 'UPDATE_WORKING_HISTORY_DETAIL_FULFILLED';
export const UPDATE_WORKING_HISTORY_DETAIL_REJECTED = 'UPDATE_WORKING_HISTORY_DETAIL_REJECTED';
export const updateWorkingHistoryDetail = (payload) => (dispatch) => {
  return dispatch({
    type: UPDATE_WORKING_HISTORY_DETAIL,
    payload: api.put(`/WorkingHistoryDetailInfo/update`, payload),
    meta: payload
  });
};
// const type of get deatail
export const FETCH_WORKING_HISTORY_DETAIL_DETAIL = 'FETCH_WORKING_HISTORY_DETAIL_DETAIL';
export const FETCH_WORKING_HISTORY_DETAIL_DETAIL_PENDING = 'FETCH_WORKING_HISTORY_DETAIL_DETAIL_PENDING';
export const FETCH_WORKING_HISTORY_DETAIL_DETAIL_FULFILLED = 'FETCH_WORKING_HISTORY_DETAIL_DETAIL_FULFILLED';
export const FETCH_WORKING_HISTORY_DETAIL_DETAIL_REJECTED = 'FETCH_WORKING_HISTORY_DETAIL_DETAIL_REJECTED';
export const getWorkingHistoryDetailDetail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_WORKING_HISTORY_DETAIL_DETAIL,
    payload: api.get(`/WorkingHistoryDetailInfo/${id}`)
  });
};

// const type of delete
export const DELETE_WORKING_HISTORY_DETAIL = 'DELETE_WORKING_HISTORY_DETAIL';
export const DELETE_WORKING_HISTORY_DETAIL_PENDING = 'DELETE_WORKING_HISTORY_DETAIL_PENDING';
export const DELETE_WORKING_HISTORY_DETAIL_FULFILLED = 'DELETE_WORKING_HISTORY_DETAIL_FULFILLED';
export const DELETE_WORKING_HISTORY_DETAIL_REJECTED = 'DELETE_WORKING_HISTORY_DETAIL_REJECTED';
export const deleteWorkingHistoryDetail = (id) => (dispatch) => {
  return dispatch({
    type: DELETE_WORKING_HISTORY_DETAIL,
    payload: api.delete(`/WorkingHistoryDetailInfo/delete/${id}`)
  });
};
