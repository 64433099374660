import {
  CREATE_WORKING_HISTORY_DETAIL_FULFILLED,
  CREATE_WORKING_HISTORY_DETAIL_PENDING,
  CREATE_WORKING_HISTORY_DETAIL_REJECTED,
  DELETE_WORKING_HISTORY_DETAIL_FULFILLED,
  DELETE_WORKING_HISTORY_DETAIL_PENDING,
  DELETE_WORKING_HISTORY_DETAIL_REJECTED,
  FETCH_ALL_WORKING_HISTORY_DETAILS_FULFILLED,
  FETCH_ALL_WORKING_HISTORY_DETAILS_PENDING,
  FETCH_ALL_WORKING_HISTORY_DETAILS_REJECTED,
  FETCH_WORKING_HISTORY_DETAIL_DETAIL_FULFILLED,
  FETCH_WORKING_HISTORY_DETAIL_DETAIL_PENDING,
  FETCH_WORKING_HISTORY_DETAIL_DETAIL_REJECTED,
  FETCH_WORKING_HISTORY_DETAILS_FULFILLED,
  FETCH_WORKING_HISTORY_DETAILS_PENDING,
  FETCH_WORKING_HISTORY_DETAILS_REJECTED,
  SET_CLOSE_WORKING_HISTORY_DETAIL_MODAL,
  SET_OPEN_WORKING_HISTORY_DETAIL_MODAL,
  UPDATE_WORKING_HISTORY_DETAIL_FULFILLED,
  UPDATE_WORKING_HISTORY_DETAIL_PENDING,
  UPDATE_WORKING_HISTORY_DETAIL_REJECTED} from 'actions';
import { getErrorMessage } from 'commons/ReducerUtils';
import {
  DEFAULT_PAGE_SIZE,
  MSG_ADD_FAILURE,
  MSG_ADD_SUCCESS,
  MSG_DELETE_FAILURE,
  MSG_DELETE_SUCCESS,
  MSG_EDIT_FAILURE,
  MSG_EDIT_SUCCESS,
  MSG_GET_DETAIL_FAILURE,
  MSG_PAGING_FAILURE,
  MSG_TYPE_ERROR,
  MSG_TYPE_SUCCESS} from 'const/common';
const INIT_STATE = {
  //combobox: get all
  allWorkingHistoryDetails: [],
  // paging
  workingHistoryDetails: [],
  pagination: { pageNo: 1, pageSize: DEFAULT_PAGE_SIZE, totalRecord: 0 },
  listLoading: false,

  // add/edit modal
  isOpenFormModal: false, // Show/hidden update modal
  workingHistoryDetail: {},
  formLoading: false,
  //delete
  deleteLoading: false,
  // other
  showMessage: false,
  alertMessage: '',
  formAlertMessage: '',
  messageType: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region update modal
    case SET_OPEN_WORKING_HISTORY_DETAIL_MODAL: {
      console.log('reducer open form modal');
      return {
        ...state,
        isOpenFormModal: true,
        workingHistoryDetail: action.payload
      };
    }
    case SET_CLOSE_WORKING_HISTORY_DETAIL_MODAL: {
      return {
        ...state,
        isOpenFormModal: false,
        workingHistoryDetail: INIT_STATE.workingHistoryDetail
      };
    }
    //#endregion

    //#region getall
    case FETCH_ALL_WORKING_HISTORY_DETAILS_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }
    case FETCH_ALL_WORKING_HISTORY_DETAILS_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        allWorkingHistoryDetails: action.payload.data.data,
        showMessage: false
      };
    }
    case FETCH_ALL_WORKING_HISTORY_DETAILS_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region paging
    case FETCH_WORKING_HISTORY_DETAILS_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }

    case FETCH_WORKING_HISTORY_DETAILS_FULFILLED: {
      return {
        ...state,
        listLoading: false,
        workingHistoryDetails: action.payload.data.data ?? INIT_STATE.workingHistoryDetails,
        pagination: action.payload.data.pagination ?? INIT_STATE.pagination,
        showMessage: false
      };
    }
    case FETCH_WORKING_HISTORY_DETAILS_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region create data
    case CREATE_WORKING_HISTORY_DETAIL_PENDING:
      return {
        ...state,
        formLoading: true
      };
    case CREATE_WORKING_HISTORY_DETAIL_FULFILLED: {
      const workingHistoryDetail = action.payload.data;
      const workingHistoryDetails = state.workingHistoryDetails.map((item) => {
        return item.Id === workingHistoryDetail.Id ? workingHistoryDetail : item;
      });
      return {
        ...state,
        workingHistoryDetails: [...workingHistoryDetails],
        formLoading: false,
        showMessage: true,
        formAlertMessage: MSG_ADD_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case CREATE_WORKING_HISTORY_DETAIL_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_ADD_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region update data
    case UPDATE_WORKING_HISTORY_DETAIL_PENDING:
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    case UPDATE_WORKING_HISTORY_DETAIL_FULFILLED: {
      const workingHistoryDetail = action.meta;
      let workingHistoryDetails = [...state.workingHistoryDetails];
      workingHistoryDetails = workingHistoryDetails.map((item) => {
        return item.Id === workingHistoryDetail.Id ? workingHistoryDetail : item;
      });
      return {
        ...state,
        workingHistoryDetails,
        formLoading: false,
        showMessage: true,
        formAlertMessage: MSG_EDIT_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    }
    case UPDATE_WORKING_HISTORY_DETAIL_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_EDIT_FAILURE);
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        formAlertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    //#region  get detail
    case FETCH_WORKING_HISTORY_DETAIL_DETAIL_PENDING:
      return {
        ...state,
        formLoading: true,
        showMessage: false
      };
    case FETCH_WORKING_HISTORY_DETAIL_DETAIL_FULFILLED:
      return {
        ...state,
        workingHistoryDetail: action.payload.data,
        formLoading: false,
        showMessage: false
      };
    case FETCH_WORKING_HISTORY_DETAIL_DETAIL_REJECTED:
      return {
        ...state,
        formLoading: false,
        showMessage: true,
        alertMessage: MSG_GET_DETAIL_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    //#endregion

    //#region delete data
    case DELETE_WORKING_HISTORY_DETAIL_PENDING:
      return {
        ...state,
        deleteLoading: true
      };
    case DELETE_WORKING_HISTORY_DETAIL_FULFILLED:
      return {
        ...state,
        isDelete: true,
        deleteLoading: false,
        showMessage: true,
        alertMessage: MSG_DELETE_SUCCESS,
        messageType: MSG_TYPE_SUCCESS
      };
    case DELETE_WORKING_HISTORY_DETAIL_REJECTED: {
      const alertMessage = getErrorMessage(action, MSG_DELETE_FAILURE);
      return {
        ...state,
        deleteLoading: false,
        showMessage: true,
        alertMessage: alertMessage,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion

    default:
      return state;
  }
};
