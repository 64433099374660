import httpClient from 'HttpClient';

export const SHIPPING_MANAGEMENT_ACTIONS = {
  GET_SHIPPING_MANAGEMENT: 'GET_SHIPPING_MANAGEMENT',
  GET_SHIPPING_MANAGEMENT_PENDING: 'GET_SHIPPING_MANAGEMENT_PENDING',
  GET_SHIPPING_MANAGEMENT_FULFILLED: 'GET_SHIPPING_MANAGEMENT_FULFILLED',
  GET_SHIPPING_MANAGEMENT_REJECTED: 'GET_SHIPPING_MANAGEMENT_REJECTED',

  CREATE_SHIPPING_MANAGEMENT: 'CREATE_SHIPPING_MANAGEMENT',
  CREATE_SHIPPING_MANAGEMENT_PENDING: 'CREATE_SHIPPING_MANAGEMENT_PENDING',
  CREATE_SHIPPING_MANAGEMENT_FULFILLED: 'CREATE_SHIPPING_MANAGEMENT_FULFILLED',
  CREATE_SHIPPING_MANAGEMENT_REJECTED: 'CREATE_SHIPPING_MANAGEMENT_REJECTED',

  UPDATE_SHIPPING_MANAGEMENT: 'UPDATE_SHIPPING_MANAGEMENT',
  UPDATE_SHIPPING_MANAGEMENT_PENDING: 'UPDATE_SHIPPING_MANAGEMENT_PENDING',
  UPDATE_SHIPPING_MANAGEMENT_FULFILLED: 'UPDATE_SHIPPING_MANAGEMENT_FULFILLED',
  UPDATE_SHIPPING_MANAGEMENT_REJECTED: 'UPDATE_SHIPPING_MANAGEMENT_REJECTED',

  DELETE_SHIPPING_MANAGEMENT: 'DELETE_SHIPPING_MANAGEMENT',
  DELETE_SHIPPING_MANAGEMENT_PENDING: 'DELETE_SHIPPING_MANAGEMENT_PENDING',
  DELETE_SHIPPING_MANAGEMENT_FULFILLED: 'DELETE_SHIPPING_MANAGEMENT_FULFILLED',
  DELETE_SHIPPING_MANAGEMENT_REJECTED: 'DELETE_SHIPPING_MANAGEMENT_REJECTED',

  CHANGE_REAL_TRANSPORT_FEE_PRICE: 'CHANGE_REAL_TRANSPORT_FEE_PRICE',
  CHANGE_CONTRACT_TRANSPORT_FEE_PRICE: 'CHANGE_CONTRACT_TRANSPORT_FEE_PRICE'
};

export const actionGetShippingManagement = (payload) => (dispatch) => {
  return dispatch({
    type: SHIPPING_MANAGEMENT_ACTIONS.GET_SHIPPING_MANAGEMENT,
    payload: httpClient.get(`/SeaTransport/search`, { params: payload })
  });
};

export const actionCreateShippingManagement = (payload) => (dispatch) => {
  return dispatch({
    type: SHIPPING_MANAGEMENT_ACTIONS.CREATE_SHIPPING_MANAGEMENT,
    payload: httpClient.post(`/SeaTransport/create`, payload)
  });
};

export const actionUpdateShippingManagement = (payload) => (dispatch) => {
  return dispatch({
    type: SHIPPING_MANAGEMENT_ACTIONS.UPDATE_SHIPPING_MANAGEMENT,
    payload: httpClient.put(`/SeaTransport/update`, payload)
  });
};

export const actionDeleteShippingManagement = (id) => (dispatch) => {
  return dispatch({
    type: SHIPPING_MANAGEMENT_ACTIONS.DELETE_SHIPPING_MANAGEMENT,
    payload: httpClient.delete(`/SeaTransport/delete/${id}`)
  });
};

export const actionChangeRealTransportFeePrice = (value, contId, shipmentId, list) => (dispatch) => {
  return dispatch({
    type: SHIPPING_MANAGEMENT_ACTIONS.CHANGE_REAL_TRANSPORT_FEE_PRICE,
    payload: { value, contId, shipmentId, list }
  });
};

export const actionChangeContractTransportFeePrice = (value, contId, shipmentId, list) => (dispatch) => {
  return dispatch({
    type: SHIPPING_MANAGEMENT_ACTIONS.CHANGE_CONTRACT_TRANSPORT_FEE_PRICE,
    payload: { value, contId, shipmentId, list }
  });
};
