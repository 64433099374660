import { FETCH_HISTORIES_FULFILLED, FETCH_HISTORIES_PENDING, FETCH_HISTORIES_REJECTED } from 'actions';
import { MSG_PAGING_FAILURE, MSG_TYPE_ERROR } from 'const/common';
const INIT_STATE = {
  //combobox: get all
  allHistories: [],
  // paging
  histories: [],
  pagination: { pageNo: 1, pageSize: 20, totalRecord: 0, totalPage: 0 },
  listLoading: false,

  // add/edit modal
  // isOpenFormModal: false, // Show/hidden update modal
  // aprroval: {},
  // formLoading: false,
  // //delete
  // deleteLoading: false,
  // other
  showMessage: false,
  alertMessage: '',
  // formAlertMessage: '',
  messageType: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //#region paging
    case FETCH_HISTORIES_PENDING: {
      return {
        ...state,
        listLoading: true,
        showMessage: false
      };
    }

    case FETCH_HISTORIES_FULFILLED: {
      const histories =
        action.meta.page === 1 ? action.payload.data.data : [...state.histories, ...action.payload.data.data];
      return {
        ...state,
        listLoading: false,
        histories: histories,
        pagination: action.payload.data.pagination ?? INIT_STATE.pagination,
        showMessage: false
      };
    }
    case FETCH_HISTORIES_REJECTED: {
      return {
        ...state,
        listLoading: false,
        showMessage: true,
        alertMessage: MSG_PAGING_FAILURE,
        messageType: MSG_TYPE_ERROR
      };
    }
    //#endregion
    default:
      return state;
  }
};
