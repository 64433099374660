export function rgbToHex(rgbString) {
  let splitString = rgbString.split('(')[1].split(')')[0];
  splitString = splitString.split(',');
  var hex = splitString.map(function(x) {
    //For each array element
    x = parseInt(x).toString(16); //Convert to a base16 string
    return x.length == 1 ? '0' + x : x; //Add zero if we get only one character
  });
  hex = '0x' + hex.join('');
  return hex;
}

export function convertStyleStrToObj(styleStr) {
  let result = {},
    attributes = styleStr.split(';');

  for (let i = 0; i < attributes.length; i++) {
    let entry = attributes[i].split(':');
    result[entry.splice(0, 1)[0]] = entry.join(':');
  }
  return result;
}
