import { UTILS_ACTIONS, UTILS_ACTIONS_CLEAR_UPLOADED_EXCEL_FILE } from "actions/utils";

const utilsReducer = (state = {}, action) => {
    switch (action.type) {
        // upload file
        case UTILS_ACTIONS.UPLOAD_FILE_PENDING:
            return {
                ...state,
                isLoading: true
            };

        case UTILS_ACTIONS.UPLOAD_FILE_FULFILLED:
            let conts = {
                data: action.payload.data.data
            };
            return {
                ...state,
                conts: conts,
                isLoading: false
            };

        case UTILS_ACTIONS.UPLOAD_FILE_REJECTED:
            return {
                ...state,
                isLoading: false,
                isMsg: true,
                msg: "Đã xảy ra lỗi"
            };

        case UTILS_ACTIONS_CLEAR_UPLOADED_EXCEL_FILE.CLEAR_UPLOADED_EXCEL_FILE:
            return {
                ...state,
                conts: []
            };

        default:
            return state;
    }
};

export default utilsReducer;