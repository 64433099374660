import { CUSTOMER_TYPE } from 'const/common';
import api from 'HttpClient';
import { getAllOilCategory } from 'services/oilcategory';

// define const actions
export const OIL_CATEGORY_ACTIONS = {
  GET_ALL_OIL_CATEGORY: 'GET_ALL_OIL_CATEGORY',
  GET_ALL_OIL_CATEGORY_PENDING: 'GET_ALL_OIL_CATEGORY_PENDING',
  GET_ALL_OIL_CATEGORY_FULFILLED: 'GET_ALL_OIL_CATEGORY_FULFILLED',
  GET_ALL_OIL_CATEGORY_REJECTED: 'GET_ALL_OIL_CATEGORY_REJECTED',

  GET_ALL_OIL_CUSTOMERS: 'GET_ALL_OIL_CUSTOMERS',
  GET_ALL_OIL_CUSTOMERS_PENDING: 'GET_ALL_OIL_CUSTOMERS_PENDING',
  GET_ALL_OIL_CUSTOMERS_FULFILLED: 'GET_ALL_OIL_CUSTOMERS_FULFILLED',
  GET_ALL_OIL_CUSTOMERS_REJECTED: 'GET_ALL_OIL_CUSTOMERS_REJECTED',

  GET_LASTEST_OIL_PRICE: 'GET_LASTEST_OIL_PRICE',
  GET_LASTEST_OIL_PRICE_PENDING: 'GET_LASTEST_OIL_PRICE_PENDING',
  GET_LASTEST_OIL_PRICE_FULFILLED: 'GET_LASTEST_OIL_PRICE_FULFILLED',
  GET_LASTEST_OIL_PRICE_REJECTED: 'GET_LASTEST_OIL_PRICE_REJECTED'
};

// define actions
export const actionGetAllOilCategory = () => (dispatch) => {
  return dispatch({
    type: OIL_CATEGORY_ACTIONS.GET_ALL_OIL_CATEGORY,
    payload: getAllOilCategory()
  });
};

// get provider
export const actionGetAllOilCustomer = () => (dispatch) => {
  return dispatch({
    type: OIL_CATEGORY_ACTIONS.GET_ALL_OIL_CUSTOMERS,
    payload: api.get(`/customerinfo/getByCustomerType/${CUSTOMER_TYPE.FUEL_PROVIDER}`)
  });
};

//get price
export const getLastestOilPrice = (OilCategoryId, ProviderId) => (dispatch) => {
  return dispatch({
    type: OIL_CATEGORY_ACTIONS.GET_LASTEST_OIL_PRICE,
    payload: api.get(`/OilReceiving/getLastestOilPrice?providerId=${ProviderId}&oilCategoryId=${OilCategoryId}`)
  });
};
