import httpClient from 'HttpClient';

export const getEmployeesByTransportComanyID = (id) => {
  return httpClient.get(`/employeeinfo/getDriverByTransportCompanyId`, { params: { transportCompanyId: id } });
};
export const getEmployeesByTransportComanyIDV2 = (id) => {
  return httpClient.get(`/employeeinfo/getDriverByTransportCompanyIdV2`, { params: { transportCompanyId: id } });
};
export const getEmployeesPerPage = (pageNo, pageSize) => {
  return httpClient.get(`/employeeinfo/paging/page?Page=${pageNo}&PageSize=${pageSize}`);
};

export const deleteItem = (id) => {
  return httpClient.delete(`/employeeinfo/delete/${id}`);
};
