import React from 'react';
import ContentEditable from 'react-contenteditable';
import { Button, Table } from 'reactstrap';
import { formatNumberToReadableString } from 'commons/MathUtils';
import { fommatCurrency } from 'commons/ValidateEditable';
import _ from 'lodash';
import moment from 'moment';
import DeletePopConfirm from 'views/DeletePopConfirm/DeletePopConfirm';

import ContentEdit from './ContentEdit';

import './style.css';
// import { deleteItemReportResult } from '../../../../actions';

export const BUSSINESS_RESULT_TAB = 0;
export const INLAND_REVUNUE_TAB = 1;
export const MINING_YARD_TAB = 2;

export const URL_SEARCH_BUSINESS_REPORT = '/ReportRevenue/getByMonth';
export const URL_SEARCH_INLAND_REVUNUE = '/ReportRevenue/TrackSales/paging/page';
export const URL_SEARCH_MINING_REPORT = '/ReportRevenue/ReportMining/getByMonth';

export const URL_DELETE_BUSINESS_REPORT = 'ReportRevenue/deleteExpenses/';
export const URL_DELETE_INLAND_REVUNUE = '/ReportRevenue/TrackSales/delete/';
export const URL_DELETE_MINING_REPORT = '/ReportRevenue/ReportMining/delete/';

export const URL_CREATE_BUSINESS_REPORT = '/ReportRevenue/createExpense';
export const URL_CREATE_INLAND_REVUNUE = '/ReportRevenue/TrackSales/create';
export const URL_CREATE_MINING_REPORT = '/ReportRevenue/ReportMining/Creates';

export const URL_UPDATE_BUSINESS_REPORT = 'ReportRevenue/expensesUpdate';
export const URL_UPDATE_INLAND_REVUNUE = '/ReportRevenue/TrackSales/update';
export const URL_UPDATE_MINING_REPORT = '/ReportRevenue/ReportMining/update';

export const SHOW_BTN_SAVE = true;
const fieldTypeIsString = ['CustomerName', 'Content', 'Description'];
export const TYPE_NUMBER = 'number';
export const TYPE_STRING = 'string';

const validateNumberFieldFuncs = ['validateNumber', 'validateMaxLength'];
const validateStringFieldFuncs = ['validateMaxLength'];
const maxLengthNumberField = 15;
const maxLengthStringField = 100;

const INDEX_FOLLOW_LEVEL = [
  ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'K'],
  ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'],
  ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
];

export function mangementFeeData(reports = {}, aggregateColumns = [], deleteItemReportResult, addRowFees, breakline) {
  const ExpensesTree = reports.ExpensesTree && reports.ExpensesTree[0];
  const Childs = ExpensesTree?.Childs || [];
  const Total = Childs.reduce((sum, item) => sum + item.TotalMoney, 0);
  const TotalRevenusArr = aggregateColumns.filter((item) => (item.ColumnName = 'TotalRevenus'));
  const lenghtTotalRevenusArr = TotalRevenusArr.length;
  const TotalRevenus = TotalRevenusArr[lenghtTotalRevenusArr - 1];
  const onClickDelete = (id) => {
    deleteItemReportResult(id);
  };

  const addRowExpensesTree = (parentId) => {
    const payload = {
      ParentId: parentId,
      Name: '',
      TotalMoney: 0,
      Description: ''
    };
    addRowFees(payload);
  };
  return (
    <React.Fragment>
      <Table bordered>
        <thead>
          {breakline ? (
            <tr>
              <th colSpan="4"></th>
            </tr>
          ) : null}
          <tr>
            <th colSpan="3">{ExpensesTree?.Name}</th>
            <th className="text-center">
              <Button
                type="button"
                color="success"
                size="sm"
                // className="float-right"
                disabled={!ExpensesTree || (!ExpensesTree && ExpensesTree.length === 0)}
                onClick={(event) => addRowExpensesTree(ExpensesTree.Id)}
              >
                <i className="fa fa-plus-circle" />
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {Childs && Childs.length > 0
            ? Childs.map((item, index) => {
                const value = formatNumberToReadableString(item.TotalMoney);
                return (
                  <>
                    <tr key={item.Id}>
                      <td>{index + 1}</td>
                      <td>
                        <ContentEdit
                          value={item.Name}
                          item={item}
                          belongTo="ExpensesTree"
                          fieldName="Name"
                          type="text"
                        />
                      </td>
                      <td style={{ width: '40%' }}>
                        <ContentEdit
                          value={value}
                          item={item}
                          belongTo="ExpensesTree"
                          fieldName="TotalMoney"
                          type="price"
                        />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <DeletePopConfirm
                          onOk={() => onClickDelete(item.Id)}
                          onCancel={() => {}}
                          button={{ color: 'danger' }}
                          confirmTitle={'Thông báo'}
                          okText={'Xóa'}
                          cancelText={'Hủy'}
                          okIcon={'fa fa-check'}
                          cancelIcon={'fa fa-times'}
                          className="button-action"
                        />
                      </td>
                    </tr>
                  </>
                );
              })
            : null}
          <tr>
            <td colSpan={2}>Tổng</td>
            <td style={{ textAlign: 'right' }}>{formatNumberToReadableString(Math.round(Total / 1000) * 1000)}</td>
            <td></td>
          </tr>
          <tr>
            <td colSpan={2}>Lợi nhuận</td>
            <td style={{ textAlign: 'right' }}>
              {formatNumberToReadableString(Math.round((TotalRevenus?.aggregateResults[0] - Total) / 1000) * 1000)}
            </td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </React.Fragment>
  );
}

const VAN_TAI_BO_INDEX = 'A';
const VAN_TAI_BO_INPUT = ['TotalSalary'];
const KHO_BAI_INDEX = 'B';
const KHO_BAI_NOT_INPUT = ['TotalFee', 'TotalRevenus'];
const VAN_TAI_BIEN_NOI_DIA_INDEX = 'C';
const HAI_QUAN_VA_BAI_INDEX = ['D', 'E'];
const DOANH_THU_KHAC_INDEX = 'F';
const renderInputContentEdit = (value, item, belongTo, fieldName) => {
  return (
    <>
      <ContentEdit value={value} item={item} type="price" belongTo={belongTo} fieldName={fieldName} />
    </>
  );
};
const renderItem = (value, item, fieldName) => {
  const isInputVanTaiBienNoiDia = !KHO_BAI_NOT_INPUT.includes(fieldName) && item.no === VAN_TAI_BIEN_NOI_DIA_INDEX;
  const isInputHaiQuan = HAI_QUAN_VA_BAI_INDEX.includes(item.no) && !KHO_BAI_NOT_INPUT.includes(fieldName);
  const isInputDoanhThuKhac = DOANH_THU_KHAC_INDEX === item.no && !KHO_BAI_NOT_INPUT.includes(fieldName);
  const isInputKhoBai = item.Parent === KHO_BAI_INDEX && !KHO_BAI_NOT_INPUT.includes(fieldName);
  const isInputVanTaiBo = item.Parent === VAN_TAI_BO_INDEX && VAN_TAI_BO_INPUT.includes(fieldName);
  const isInput = isInputVanTaiBienNoiDia || isInputHaiQuan || isInputDoanhThuKhac || isInputKhoBai || isInputVanTaiBo;
  const valueFormatted = formatNumberToReadableString(value);
  const belongTo = item.Parent === VAN_TAI_BO_INDEX ? 'StatisticSalaries' : 'RevenuesTree';
  if (isInput) {
    return renderInputContentEdit(valueFormatted, item, belongTo, fieldName);
  }
  return valueFormatted;
};
export function preprocessingReportsForBusinessResultReport(
  reports,
  pagination,
  search,
  onChangeFunc,
  onDeleteFunc,
  createDataMiningReportFunc,
  listDepartment
) {
  if (Array.isArray(reports) || !reports) return [];
  const month = new Date(search.month).getMonth() + 1;
  const year = new Date(search.month).getFullYear();
  const data = convertDataDisplayForBusinessReport(reports, listDepartment, month, year);
  // const result = data.map((item, i) => {
  //   return {
  //     ...item
  //   };
  // });
  return data;
}
export function preprocessingReportsForBusinessResultReportCustom(reports) {
  const result = reports.map((item, i) => {
    const hasParentA = item.Parent === 'A';
    return {
      ...item,
      Revenus: renderItem(item.Revenus, item, 'Revenus'),
      Salary: hasParentA ? renderItem(item.Salary, item, 'TotalSalary') : renderItem(item.Salary, item, 'Salary'),
      WayCharge: renderItem(item.WayCharge, item, 'WayCharge'),
      SparePart: renderItem(item.SparePart, item, 'SparePart'),
      RentOutService: renderItem(item.RentOutService, item, 'RentOutService'),
      Insurance: renderItem(item.Insurance, item, 'Insurance'),
      OtherFees: renderItem(item.OtherFees, item, 'OtherFees'),
      DepreciationOfBank: renderItem(item.DepreciationOfBank, item, 'DepreciationOfBank'),
      TotalFee: renderItem(item.TotalFee, item, 'TotalFee'),
      TotalRevenus: renderItem(item.TotalRevenus, item, 'TotalRevenus'),
      Registry: renderItem(item.Registry, item, 'Registration')
    };
  });
  return result;
}

//TODO: show message validate input
function showValidateMessage(mess, fieldName) {
  const error = mess.error;
  if (error) {
    return error[fieldName];
  } else {
    return '';
  }
}

//TODO: render commons field input ediable
function renderFieldEditContent(
  value,
  item,
  index,
  fieldName,
  onChangeFunc,
  lengthValidate,
  validateFuncs,
  type,
  styles,
  isDisable
) {
  const validateMessage = showValidateMessage(item, fieldName);
  const isTypeString = fieldTypeIsString.includes(fieldName);
  const valueWithTypeIsString = value ? value + '' : '';
  const valueWithTypeIsNumber = value ? fommatCurrency(value) + '' : '';
  const valueContent = isTypeString ? valueWithTypeIsString : valueWithTypeIsNumber;
  return (
    <React.Fragment>
      <ContentEditable
        id={`${fieldName}${index}`}
        html={valueContent}
        onChange={(e) => onChangeFunc(e, fieldName, index, lengthValidate, validateFuncs, type, item)}
        className={styles ? `${styles} cus-editable-input` : 'cus-editable-input'}
        disabled={isDisable}
        // //   onKeyUp={(e) => this.onKeyUpField(el.Id, "Note", e)}
        // onKeyDown={(e) => this.handelOnPress(e, i, type, 'Note')}
      />
      <span className="text-danger" style={{ fontSize: '7', fontStyle: 'italic' }}>
        {validateMessage}
      </span>
    </React.Fragment>
  );
}

function onChangeDateInput(e, fieldName, index, lengthValidate, validateFuncs, onChangeFunc, type, item) {
  onChangeFunc(e, fieldName, index, lengthValidate, validateFuncs, type, item);
}

function onClickDelete(id, onDeleteFunc, index, parentId) {
  onDeleteFunc(id, index, parentId);
}

export function preprocessingReportsForInlandRevunueReport(
  reports,
  pagination,
  search,
  onChangeFunc,
  onDeleteFunc,
  createDataMiningReportFunc
) {
  if (!reports || !Array.isArray(reports) || reports.length === 0) return [];
  const result = reports.map((item, i) => {
    const isDisableInput = false;
    const date = item.DateImport ? item.DateImport.slice(0, 10) : '';
    return {
      ...item,
      STT: i + 1,
      DateImport: (
        <>
          <input
            type="date"
            className="input-date"
            value={date}
            onChange={(e) => onChangeDateInput(e, 'DateImport', i, 100, [], onChangeFunc, TYPE_STRING, item)}
          />
          <div style={{ display: 'none' }}>{moment(item.DateImport).format('YYYY/MM/DD')}</div>
        </>
      ),
      CustomerName: renderFieldEditContent(
        item.CustomerName,
        item,
        i,
        'CustomerName',
        onChangeFunc,
        maxLengthStringField,
        validateStringFieldFuncs,
        TYPE_STRING,
        '',
        isDisableInput
      ),
      Content: renderFieldEditContent(
        item.Content,
        item,
        i,
        'Content',
        onChangeFunc,
        maxLengthStringField,
        validateStringFieldFuncs,
        TYPE_STRING,
        '',
        isDisableInput
      ),
      TotalMoney: renderFieldEditContent(
        item.TotalMoney,
        item,
        i,
        'TotalMoney',
        onChangeFunc,
        maxLengthNumberField,
        validateNumberFieldFuncs,
        TYPE_NUMBER,
        'text-right',
        isDisableInput
      ),
      TruckingHp: renderFieldEditContent(
        item.TruckingHp,
        item,
        i,
        'TruckingHp',
        onChangeFunc,
        maxLengthNumberField,
        validateNumberFieldFuncs,
        TYPE_NUMBER,
        'text-right',
        isDisableInput
      ),
      LiftOnLiftOff: renderFieldEditContent(
        item.LiftOnLiftOff,
        item,
        i,
        'LiftOnLiftOff',
        onChangeFunc,
        maxLengthNumberField,
        validateNumberFieldFuncs,
        TYPE_NUMBER,
        'text-right',
        isDisableInput
      ),
      DoAndCont: renderFieldEditContent(
        item.DoAndCont,
        item,
        i,
        'DoAndCont',
        onChangeFunc,
        maxLengthNumberField,
        validateNumberFieldFuncs,
        TYPE_NUMBER,
        'text-right',
        isDisableInput
      ),
      Loading: renderFieldEditContent(
        item.Loading,
        item,
        i,
        'Loading',
        onChangeFunc,
        maxLengthNumberField,
        validateNumberFieldFuncs,
        TYPE_NUMBER,
        'text-right',
        isDisableInput
      ),
      OceanFreight: renderFieldEditContent(
        item.OceanFreight,
        item,
        i,
        'OceanFreight',
        onChangeFunc,
        maxLengthNumberField,
        validateNumberFieldFuncs,
        TYPE_NUMBER,
        'text-right',
        isDisableInput
      ),
      TruckingHcm: renderFieldEditContent(
        item.TruckingHcm,
        item,
        i,
        'TruckingHcm',
        onChangeFunc,
        maxLengthNumberField,
        validateNumberFieldFuncs,
        TYPE_NUMBER,
        'text-right',
        isDisableInput
      ),
      LiftOnLiftOffHcm: renderFieldEditContent(
        item.LiftOnLiftOffHcm,
        item,
        i,
        'LiftOnLiftOffHcm',
        onChangeFunc,
        maxLengthNumberField,
        validateNumberFieldFuncs,
        TYPE_NUMBER,
        'text-right',
        isDisableInput
      ),
      CostMerge: renderFieldEditContent(
        item.CostMerge,
        item,
        i,
        'CostMerge',
        onChangeFunc,
        maxLengthNumberField,
        validateNumberFieldFuncs,
        TYPE_NUMBER,
        'text-right',
        isDisableInput
      ),
      CostScCont: renderFieldEditContent(
        item.CostScCont,
        item,
        i,
        'CostScCont',
        onChangeFunc,
        maxLengthNumberField,
        validateNumberFieldFuncs,
        TYPE_NUMBER,
        'text-right',
        isDisableInput
      ),
      CostOther: renderFieldEditContent(
        item.CostOther,
        item,
        i,
        'CostOther',
        onChangeFunc,
        maxLengthNumberField,
        validateNumberFieldFuncs,
        TYPE_NUMBER,
        'text-right',
        isDisableInput
      ),
      CostBenefit: renderFieldEditContent(
        item.CostBenefit,
        item,
        i,
        'CostBenefit',
        onChangeFunc,
        maxLengthNumberField,
        validateNumberFieldFuncs,
        TYPE_NUMBER,
        'text-right',
        true
      ),
      Description: renderFieldEditContent(
        item.Description,
        item,
        i,
        'Description',
        onChangeFunc,
        maxLengthStringField,
        validateStringFieldFuncs,
        TYPE_STRING,
        '',
        isDisableInput
      ),
      actions: (
        <DeletePopConfirm
          onOk={() => onClickDelete(item.Id, onDeleteFunc, i, null)}
          onCancel={() => {}}
          button={{
            color: 'danger'
          }}
          confirmTitle={'Thông báo'}
          okText={'Xóa'}
          cancelText={'Hủy'}
          okIcon={'fa fa-check'}
          cancelIcon={'fa fa-times'}
          className="button-action"
          disabled={item.isDisableBtnDelete}
        />
      )
    };
  });

  return result;
}
//TODO: convert data --> flattedData
export function extractDisplayColumn(columns) {
  const leaves = [];
  const tree = { Childs: columns };
  findAllLeaves(tree, leaves);
  leaves.splice(0, 1);
  return leaves;
}

export function findAllLeaves(tree, leaves) {
  let index = -1;
  if (!tree.Childs || tree.Childs.length === 0) {
    leaves[leaves.length] = mapStyleToChildsTree(tree, tree.level);
  } else {
    let indexParent = tree.level ? tree.level : index + 1;
    leaves[leaves.length] = mapStyleToChildsTree(tree, indexParent);
    tree.Childs.forEach((child) => {
      let indexChild = indexParent + 1;
      let item = { ...child, level: indexChild };
      findAllLeaves(item, leaves);
    });
  }
}

//TODO: set index by level for tree data
function setIndexColumnsNoTreeLevel(reports) {
  let parentIndexTree = 0;
  let subParentIndexTree = 0;
  let childsIndexTree = 0;
  let results = [];
  reports.forEach((item, index) => {
    if (item.level === 1) {
      parentIndexTree = parentIndexTree + 1;
      item = { ...item, indexNo: parentIndexTree };
      results.push(item);
    } else if (item.level === 2) {
      if (item.ParentId === reports[index - 1].Id) {
        subParentIndexTree = 1;
      } else {
        subParentIndexTree += 1;
      }
      item = { ...item, indexNo: subParentIndexTree };
      results.push(item);
    } else {
      if (item.ParentId === reports[index - 1].Id) {
        childsIndexTree = 1;
      } else {
        childsIndexTree += 1;
      }
      results.push({ ...item, indexNo: childsIndexTree });
    }
  });

  return results;
}

export function removeSameItem(reports) {
  const uniqueItems = Array.from(new Set(reports.map((el) => el.Id))).map((item) => {
    return reports.find((el) => el.Id === item);
  });

  return uniqueItems;
}

//TODO: map index with index string ,Latin, Roma
function mapIndexStringColumns(reports) {
  const result = reports.map((el) => {
    const isParentLevel = el.level === 1 || el.level === 2;
    return {
      ...el,
      index: isParentLevel ? INDEX_FOLLOW_LEVEL[el.level - 1][el.indexNo - 1] : el.indexNo
    };
  });
  return result;
}

function mapStyleToChildsTree(data, level) {
  //   const isHasStyle = data.style;
  const levelStyleHeaders = [1, 2];
  const isHasStyleHeader = levelStyleHeaders.includes(level);
  const styleHeader = isHasStyleHeader ? 'header' : 'child';
  const result = {
    ...data,
    // style: className === 'header' ? (isHasStyle ? 'indexHeader' : className) : className,
    style: styleHeader,
    level: level
  };

  return result;
}

export function getColumnsDisplay(reports) {
  if (reports && reports.length === 0) return [];
  return extractDisplayColumn(reports);
}

function displayContentMiningYardReport(item, i, onChangeFunc) {
  const style = item.style;
  return (
    <React.Fragment>
      {renderFieldEditContent(
        item.Name,
        item,
        i,
        'Name',
        onChangeFunc,
        maxLengthStringField,
        validateStringFieldFuncs,
        TYPE_STRING,
        style
      )}
    </React.Fragment>
  );
}

function renderActions(item, onDeleteFunc, createDataMiningReportFunc, i, reports) {
  const style = item.style;
  const styleIsHeader = style === 'header';
  const isHasId = item.Id ? true : false;
  const isItemDiff = reports.length - 1 === i;
  return (
    <React.Fragment>
      <Button
        className="button-action mr-1"
        onClick={() => createDataMiningReportFunc(item.Id)}
        color="success"
        disabled={!styleIsHeader || !isHasId || isItemDiff}
      >
        {' '}
        <i className="fa fa-plus-circle" aria-hidden="true"></i>{' '}
      </Button>

      <DeletePopConfirm
        onOk={() => onClickDelete(item.Id, onDeleteFunc, item.CurrentIndex, item.ParentId)}
        onCancel={() => {}}
        button={{
          color: 'danger'
        }}
        confirmTitle={'Thông báo'}
        okText={'Xóa'}
        cancelText={'Hủy'}
        okIcon={'fa fa-check'}
        cancelIcon={'fa fa-times'}
        className="button-action"
        disabled={styleIsHeader && isHasId}
      />
    </React.Fragment>
  );
}

export function convertDataDisplayForMiningReport(reports) {
  const mapReportWithIndexNoColumns = setIndexColumnsNoTreeLevel(reports);
  const reportWithIndexStringColumns = mapIndexStringColumns(mapReportWithIndexNoColumns);
  return reportWithIndexStringColumns;
}

export function caculatorNode(trees) {
  trees.reduce(function x(r, a) {
    if (Array.isArray(a.Childs) && a.Childs.length > 0) {
      a.TotalMoney = a.Childs.reduce(x, 0) || 0;
      return r + a.TotalMoney;
    } else {
      a.TotalMoney = a.TotalMoney || 0;
      return r + a.TotalMoney;
    }
  }, 0);
}

function renderDiffValue(reports) {
  const revunue = reports[0]?.TotalMoney ?? 0;
  const fee = reports[1]?.TotalMoney ?? 0;
  const diff = revunue - fee;
  return diff;
}

function renderTotalMoney(reportsData, item, totalDiff, i) {
  const isItemDiff = reportsData.length - 1 === i;
  if (item.level === 2) {
    return '';
  } else if (isItemDiff) {
    return fommatCurrency(totalDiff);
  } else {
    return item.TotalMoney ? fommatCurrency(item.TotalMoney) : '';
  }
}

export function preprocessingReportsForMiningYardReport(
  reports,
  pagination,
  search,
  onChangeFunc,
  onDeleteFunc,
  createDataMiningReportFunc
) {
  if (!reports || !Array.isArray(reports) || reports.length === 0) return [];
  caculatorNode(reports);

  const reportsDataDisplay = getColumnsDisplay(reports);
  const reportsData = convertDataDisplayForMiningReport(reportsDataDisplay);
  const result = reportsData.map((item, i) => {
    const isItemDiff = reportsData.length - 1 === i;
    const isHeaderParent = item.style === 'header';
    const isDiableInputContent = isItemDiff || isHeaderParent;
    const totalDiff = renderDiffValue(reports);

    return {
      ...item,
      index: <p className={`${item.style} text-center`}>{item.index}</p>,
      Content: displayContentMiningYardReport(item, i, onChangeFunc),
      Quantity: (
        <React.Fragment>
          {renderFieldEditContent(
            item.Amount,
            item,
            i,
            'Amount',
            onChangeFunc,
            maxLengthNumberField,
            validateNumberFieldFuncs,
            TYPE_NUMBER,
            'text-center',
            isDiableInputContent
          )}
        </React.Fragment>
      ),
      UnitPrice: (
        <React.Fragment>
          {renderFieldEditContent(
            item.Price,
            item,
            i,
            'Price',
            onChangeFunc,
            maxLengthNumberField,
            validateNumberFieldFuncs,
            TYPE_NUMBER,
            'text-right',
            isDiableInputContent
          )}
        </React.Fragment>
      ),
      //   TotalPrice: renderFieldEditContent(
      //     item.TotalMoney,
      //     item,
      //     i,
      //     'TotalMoney',
      //     onChangeFunc,
      //     maxLengthNumberField,
      //     validateNumberFieldFuncs,
      //     TYPE_NUMBER
      //   ),
      TotalPrice: renderTotalMoney(reportsData, item, totalDiff, i),
      Note: (
        <React.Fragment>
          {renderFieldEditContent(
            item.Description,
            item,
            i,
            'Description',
            onChangeFunc,
            maxLengthStringField,
            validateStringFieldFuncs,
            TYPE_STRING,
            '',
            false
          )}
        </React.Fragment>
      ),
      actions: renderActions(item, onDeleteFunc, createDataMiningReportFunc, i, reportsData)
    };
  });

  return result;
}
const fieldData = [
  {
    name: 'DepreciationOfBank',
    fieldName: ['BankingFee', 'Interest']
  },
  {
    name: 'Insurance',
    fieldName: 'Insurance'
  },
  {
    name: 'OtherFees',
    fieldName: 'OtherFee'
  },
  {
    name: 'RentOutService',
    fieldName: 'RentedTruckingFee'
  },
  {
    name: 'Revenus',
    fieldName: ['TruckingFee', 'OverTimeMoney']
  },
  {
    name: 'Salary',
    fieldName: 'BasicSalary'
  },
  {
    name: 'WayCharge',
    fieldName: ['TravelAllowance', 'OilMoney']
  },
  {
    name: 'SparePart',
    fieldName: ['RepairingFee', 'LocationFee']
  },
  {
    name: 'Registry',
    fieldName: 'Registry'
  }
];
const fieldDataRevenueByCar = [
  {
    name: 'DepreciationOfBank',
    fieldName: ['BankingFee', 'Interest']
  },
  {
    name: 'Insurance',
    fieldName: 'Insurance'
  },
  {
    name: 'OtherFees',
    fieldName: 'OtherFee'
  },
  {
    name: 'RentOutService',
    fieldName: 'RentedTruckingFee'
  },
  {
    name: 'Revenus',
    fieldName: ['TruckingFee', 'OverTimeMoney']
  },
  {
    name: 'WayCharge',
    fieldName: ['TravelAllowance', 'OilMoney']
  },
  {
    name: 'SparePart',
    fieldName: ['RepairingFee', 'LocationFee']
  },
  {
    name: 'Registry',
    fieldName: 'Registry'
  }
];
const fieldDataRevenuesTree = [
  {
    name: 'DepreciationOfBank',
    fieldName: 'DepreciationOfBank'
  },
  {
    name: 'Insurance',
    fieldName: 'Insurance'
  },
  {
    name: 'OtherFees',
    fieldName: 'OtherFees'
  },
  {
    name: 'RentOutService',
    fieldName: 'RentOutService'
  },
  {
    name: 'Revenus',
    fieldName: 'Revenus'
  },
  {
    name: 'Salary',
    fieldName: 'Salary'
  },
  {
    name: 'TotalFee',
    fieldName: 'TotalFee'
  },
  {
    name: 'TotalRevenus',
    fieldName: 'TotalRevenus'
  },
  {
    name: 'WayCharge',
    fieldName: 'WayCharge'
  },
  {
    name: 'SparePart',
    fieldName: 'SparePart'
  },
  {
    name: 'Registry',
    fieldName: 'Registration'
  }
];
const fieldDataFull = [
  'DepreciationOfBank',
  'Insurance',
  'OtherFees',
  'RentOutService',
  'Revenus',
  'Salary',
  'TotalFee',
  'TotalRevenus',
  'WayCharge',
  'SparePart',
  'Registry'
];
const FeesCalculate = [
  'Salary',
  'WayCharge',
  'SparePart',
  'RentOutService',
  'Registry',
  'Insurance',
  'OtherFees',
  'DepreciationOfBank'
];
const FeesCalculateRevenuesTree = [
  'Salary',
  'WayCharge',
  'SparePart',
  'RentOutService',
  'Registration',
  'Insurance',
  'OtherFees',
  'DepreciationOfBank'
];

const getTotal = (fieldName, data) => {
  let sum = 0;
  if (Array.isArray(fieldName)) {
    fieldName.forEach((itemFieldName) => {
      const sumNew = _.sumBy(data, itemFieldName) || 0;
      sum += sumNew;
    });
  } else {
    sum = _.sumBy(data, fieldName) || 0;
  }
  return sum;
};

const getDataRevenueByCar = (RevenueByCar, InspectionReport, listDepartment = [], StatisticSalaries, Month, Year) => {
  //xe trong

  // group RevenueByCar by departmentId
  const dataGroupByDepartmentId = _.groupBy(RevenueByCar, 'DepartmentId');
  // map dataGroupByDepartment follow every department in comapny
  const dataFollowDepartments = listDepartment.map((department) => {
    const data = dataGroupByDepartmentId[department.Id] || [];
    return {
      department,
      data
    };
  });

  const dataRevenueByCarGrouped = dataFollowDepartments.map((dataByDepartment, index) => {
    const { department, data } = dataByDepartment;
    // init data summary follow department
    let ItemByDepartment = { Content: department.DepartmentName };
    // calculate total per field
    fieldDataRevenueByCar.forEach((itemField) => {
      const sum = getTotal(itemField.fieldName, data);
      ItemByDepartment[itemField.name] = Math.round(sum / 1000) * 1000;
    });
    // total salary of department
    const departmentSalary = StatisticSalaries.find((item) => item.DepartmentId === department.Id);
    ItemByDepartment.Salary = departmentSalary ? departmentSalary.TotalSalary : null;
    // total fee
    ItemByDepartment.TotalFee = FeesCalculate.reduce((sum, fieldName) => sum + ItemByDepartment[fieldName], 0);
    //total revenus
    ItemByDepartment.TotalRevenus = ItemByDepartment.Revenus - ItemByDepartment.TotalFee;
    // index
    ItemByDepartment.no = index + 1;
    // department id
    ItemByDepartment.DepartmentId = department.Id;
    // parent item
    ItemByDepartment.Parent = 'A';
    // month
    ItemByDepartment.Month = Month;
    //year
    ItemByDepartment.Year = Year;
    // return data
    return ItemByDepartment;
  });
  // thuê xe ngoài
  let InspectionReportData = {
    no: dataRevenueByCarGrouped.length + 1,
    Content: 'Thuê xe ngoài'
    // index: dataRevenueByCarGrouped.length
  };
  // calculate total for each fee of InspectionReport
  fieldData.forEach((itemField) => {
    const sum = getTotal(itemField.fieldName, InspectionReport);
    InspectionReportData[itemField.name] = Math.round(sum / 1000) * 1000;
  });
  // total fee
  InspectionReportData.TotalFee = FeesCalculate.reduce((sum, fieldName) => sum + InspectionReportData[fieldName], 0);
  // total revenus
  InspectionReportData.TotalRevenus = InspectionReportData.Revenus - InspectionReportData.TotalFee;
  // push to data grouped by department
  dataRevenueByCarGrouped.push(InspectionReportData);

  // caculate total company cars and outsourced cars
  let ItemTotal = { Sum: true };
  fieldDataFull.forEach((itemField) => {
    const sum = _.sumBy(dataRevenueByCarGrouped, itemField);
    ItemTotal[itemField] = Math.round(sum / 1000) * 1000;
  });
  ItemTotal.Content = 'Vận tải bộ';
  ItemTotal.no = 'A';
  // total item goes to head of array
  dataRevenueByCarGrouped.unshift(ItemTotal);
  //return data
  return dataRevenueByCarGrouped;
};
const getOtherRevenueData = (RevenuesTree) => {
  let result = [];
  RevenuesTree.forEach((item, index) => {
    let ItemTotal = item;
    ItemTotal.Content = ItemTotal.Name;
    ItemTotal.no = INDEX_FOLLOW_LEVEL[0][index + 1];
    ItemTotal.Sum = true;
    let Childs = item.Childs;
    if (Childs && Childs.length > 0) {
      Childs = Childs.map((item2, index2) => {
        const TotalFee = FeesCalculateRevenuesTree.reduce((sumChild, fieldChild) => sumChild + item2[fieldChild], 0);
        const TotalRevenus = item2.Revenus - TotalFee;
        return {
          ...item2,
          no: index2 + 1,
          Content: item2.Name,
          Parent: 'B',
          TotalFee: TotalFee,
          TotalRevenus: TotalRevenus,
          Registry: item2.Registration
        };
      });
      fieldDataRevenuesTree.forEach((itemField) => {
        // const sum = Childs.reduce((sum, item) => sum + item[itemField.fieldName], 0);
        const sum = _.sumBy(Childs, itemField.fieldName);
        ItemTotal[itemField.name] = Math.round(sum / 1000) * 1000;
      });
    } else {
      ItemTotal.Registry = ItemTotal.Registration;
    }
    const ItemAdd = { Content: <div style={{ height: 20 }}></div> };
    result = [...result, ItemAdd, ItemTotal, ...Childs];
  });
  return result;
};

export function convertDataDisplayForBusinessReport(reports, listDepartment, Month, Year) {
  const { RevenueByCar, InspectionReport, StatisticSalaries, RevenuesTree } = reports;
  const DataRevenueByCar = getDataRevenueByCar(
    RevenueByCar,
    InspectionReport,
    listDepartment,
    StatisticSalaries,
    Month,
    Year
  );
  const OtherRevenueData = getOtherRevenueData(RevenuesTree);
  let result = [...DataRevenueByCar, ...OtherRevenueData];
  result = result.map((item, index) => {
    return {
      ...item,
      index: 1 + index
    };
  });
  return result;
}
export const findIndexColumnsInArray = (columns, columnName) => {
  if (columns && columns.length === 0) return 0;
  const indexColumn = columns.findIndex((item) => {
    return item.ColumnName === columnName;
  });
  return indexColumn;
};
