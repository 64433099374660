import api from 'HttpClient/index';

export const createDriverPlan = (payload) => {
  return api.post(`/TruckingPlan/create`, payload);
};

export const updateDriverPlan = (payload) => {
  return api.put(`/TruckingPlan/update`, payload);
};
export const DailyTransportPlanInfo = (payload) => {
  return api.put(`/DailyTransportPlanInfo/updateTruckingPlan`, payload);
};

export const deleteDriverPlan = (id) => {
  return api.delete(`/TruckingPlan/delete/${id}`);
};

export const getDriverPlanByID = (id) => {
  return api.get(`/TruckingPlan/getByid/${id}`);
};

export const getAllDriverPlan = () => {
  return api.get(`/TruckingPlan/getAll`);
};

export const getDriverPlanByConsignmentID = (consignmentID) => {
  return api.get(`/TruckingPlan/getByConsignmentId?ConsignmentId=${consignmentID}`);
};

export const getDriverPlanPerPage = (pageNo, pageSize) => {
  return api.get(`/TruckingPlan/paging/page?Page=${pageNo}&PageSize=${pageSize}`);
};

export const searchDriverPlanPerPage = (payload, pageNo, pageSize) => {
  return api.get(`/TruckingPlan/Search?value=${payload.value}&Page=${pageNo}&PageSize=${pageSize}`);
};

export const deleteDriverPlanNew = (id) => {
  return api.delete(`/TruckingPlan/deletev2/${id}`);
};
