import { STATUS_INFO } from 'const/common';
import { getAllStatus, getStatusInfoByCategoryID } from 'services/statusinfo';

// define const actions
export const STATUS_INFO_ACTIONS = {
  GET_STATUS_INFO_OF_CONT: 'GET_STATUS_INFO_OF_CONT',
  GET_STATUS_INFO_OF_CONT_PENDING: 'GET_STATUS_INFO_OF_CONT_PENDING',
  GET_STATUS_INFO_OF_CONT_FULFILLED: 'GET_STATUS_INFO_OF_CONT_FULFILLED',
  GET_STATUS_INFO_OF_CONT_REJECTED: 'GET_STATUS_INFO_OF_CONT_REJECTED',

  GET_STATUS_INFO_OF_CONT_NO: 'GET_STATUS_INFO_OF_CONT_NO',
  GET_STATUS_INFO_OF_CONT_NO_PENDING: 'GET_STATUS_INFO_OF_CONT_NO_PENDING',
  GET_STATUS_INFO_OF_CONT_NO_FULFILLED: 'GET_STATUS_INFO_OF_CONT_NO_FULFILLED',
  GET_STATUS_INFO_OF_CONT_NO_REJECTED: 'GET_STATUS_INFO_OF_CONT_NO_REJECTED',

  GET_STATUS_REPAIR: 'GET_STATUS_REPAIR',
  GET_STATUS_REPAIR_PENDING: 'GET_STATUS_REPAIR_PENDING',
  GET_STATUS_REPAIR_FULFILLED: 'GET_STATUS_REPAIR_FULFILLED',
  GET_STATUS_REPAIR_REJECTED: 'GET_STATUS_REPAIR_REJECTED',

  GET_STATUS_INFO_OF_DRIVER_PLAN: 'GET_STATUS_INFO_OF_DRIVER_PLAN',
  GET_STATUS_INFO_OF_DRIVER_PLAN_PENDING: 'GET_STATUS_INFO_OF_DRIVER_PLAN_PENDING',
  GET_STATUS_INFO_OF_DRIVER_PLAN_FULFILLED: 'GET_STATUS_INFO_OF_DRIVER_PLAN_FULFILLED',
  GET_STATUS_INFO_OF_DRIVER_PLAN_REJECTED: 'GET_STATUS_INFO_OF_DRIVER_PLAN_REJECTED',

  GET_STATUS_CAR: 'GET_STATUS_CAR',
  GET_STATUS_CAR_PENDING: 'GET_STATUS_CAR_PENDING',
  GET_STATUS_CAR_FULFILLED: 'GET_STATUS_CAR_FULFILLED',
  GET_STATUS_CAR_REJECTED: 'GET_STATUS_CAR_REJECTED',

  GET_RECEIVE_STATUS: 'GET_RECEIVE_STATUS',
  GET_RECEIVE_STATUS_PENDING: 'GET_RECEIVE_STATUS_PENDING',
  GET_RECEIVE_STATUS_FULFILLED: 'GET_RECEIVE_STATUS_FULFILLED',
  GET_RECEIVE_STATUS_REJECTED: 'GET_RECEIVE_STATUS_REJECTED',

  GET_STATUS_INFO_OF_QUOTATION: 'GET_STATUS_INFO_OF_QUOTATION',
  GET_STATUS_INFO_OF_QUOTATION_PENDING: 'GET_STATUS_INFO_OF_QUOTATION_PENDING',
  GET_STATUS_INFO_OF_QUOTATION_FULFILLED: 'GET_STATUS_INFO_OF_QUOTATION_FULFILLED',
  GET_STATUS_INFO_OF_QUOTATION_REJECTED: 'GET_STATUS_INFO_OF_QUOTATION_REJECTED',

  GET_STATUS_INFO_OF_QUOTATION_DETAIL: 'GET_STATUS_INFO_OF_QUOTATION_DETAIL',
  GET_STATUS_INFO_OF_QUOTATION_DETAIL_PENDING: 'GET_STATUS_INFO_OF_QUOTATION_DETAIL_PENDING',
  GET_STATUS_INFO_OF_QUOTATION_DETAIL_FULFILLED: 'GET_STATUS_INFO_OF_QUOTATION_DETAIL_FULFILLED',
  GET_STATUS_INFO_OF_QUOTATION_DETAIL_REJECTED: 'GET_STATUS_INFO_OF_QUOTATION_DETAIL_REJECTED',

  GET_ALL_STATUS: 'GET_ALL_STATUS',
  GET_ALL_STATUS_PENDING: 'GET_ALL_STATUS_PENDING',
  GET_ALL_STATUS_FULFILLED: 'GET_ALL_STATUS_FULFILLED',
  GET_ALL_STATUS_REJECTED: 'GET_ALL_STATUS_REJECTED',

  GET_STATUS_TRANSPORT_COST_PAYMENT_WITH_DRIVER_ACTIONS: 'GET_STATUS_TRANSPORT_COST_PAYMENT_WITH_DRIVER_ACTIONS',
  GET_STATUS_TRANSPORT_COST_PAYMENT_WITH_DRIVER_ACTIONS_PENDING:
    'GET_STATUS_TRANSPORT_COST_PAYMENT_WITH_DRIVER_ACTIONS_PENDING',
  GET_STATUS_TRANSPORT_COST_PAYMENT_WITH_DRIVER_ACTIONS_FULFILLED:
    'GET_STATUS_TRANSPORT_COST_PAYMENT_WITH_DRIVER_ACTIONS_FULFILLED',
  GET_STATUS_TRANSPORT_COST_PAYMENT_WITH_DRIVER_ACTIONS_REJECTED:
    'GET_STATUS_TRANSPORT_COST_PAYMENT_WITH_DRIVER_ACTIONS_REJECTED'
};

// define actions
export const actionGetStatusInfoOfCont = () => (dispatch) => {
  return dispatch({
    type: STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_CONT,
    payload: getStatusInfoByCategoryID(STATUS_INFO.CONT)
  });
};

export const actionGetStatusInfoOfContNo = () => (dispatch) => {
  return dispatch({
    type: STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_CONT_NO,
    payload: getStatusInfoByCategoryID(STATUS_INFO.CONT_NO)
  });
};

export const actionGetStatusRepair = () => (dispatch) => {
  return dispatch({
    type: STATUS_INFO_ACTIONS.GET_STATUS_REPAIR,
    payload: getStatusInfoByCategoryID(STATUS_INFO.REPAIR)
  });
};

export const actionGetStatusInfoOfDriverPlan = () => (dispatch) => {
  return dispatch({
    type: STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_DRIVER_PLAN,
    payload: getStatusInfoByCategoryID(STATUS_INFO.DRIVER_PLAN)
  });
};

export const actionGetStatusCar = () => (dispatch) => {
  return dispatch({
    type: STATUS_INFO_ACTIONS.GET_STATUS_CAR,
    payload: getStatusInfoByCategoryID(STATUS_INFO.CAR)
  });
};

export const getReceiveStatus = () => (dispatch) => {
  return dispatch({
    type: STATUS_INFO_ACTIONS.GET_RECEIVE_STATUS,
    payload: getStatusInfoByCategoryID(STATUS_INFO.RECEIVE_STATUS)
  });
};

export const actionGetStatusQuotation = () => (dispatch) => {
  return dispatch({
    type: STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_QUOTATION,
    payload: getStatusInfoByCategoryID(STATUS_INFO.QUOTATION)
  });
};

export const actionGetStatusQuotationDetail = () => (dispatch) => {
  return dispatch({
    type: STATUS_INFO_ACTIONS.GET_STATUS_INFO_OF_QUOTATION_DETAIL,
    payload: getStatusInfoByCategoryID(STATUS_INFO.QUOTATION_DETAIL)
  });
};

export const actionGetStatusFeeFollowByDriver = () => (dispatch) => {
  return dispatch({
    type: STATUS_INFO_ACTIONS.GET_STATUS_TRANSPORT_COST_PAYMENT_WITH_DRIVER_ACTIONS,
    payload: getStatusInfoByCategoryID(STATUS_INFO.FEE_FOLLOW_BY_DRIVER)
  });
};

export const actionGetAllStatus = () => (dispatch) => {
  return dispatch({
    type: STATUS_INFO_ACTIONS.GET_ALL_STATUS,
    payload: getAllStatus()
  });
};
