import api from 'HttpClient';

export const getAllImportSparePart = () => {
  return api.get(`/sparepartreceiving/getAll`);
};

export const getImportSparePartPerPage = (pageNo, pageSize, isReceived, mode = 1) => {
  return api.get(
    `/sparepartreceiving/paging/page?page=${pageNo}&pagesize=${pageSize}&isReceived=${isReceived}&mode=${mode}`
  );
};

export const createImportSparePart = (payload) => {
  return api.post(`/sparepartreceiving/create`, payload);
};
export const updateImportSparePart = (payload) => {
  return api.put(`/sparepartreceiving/update`, payload);
};
export const deleteImportSparePart = (id) => {
  return api.delete(`/sparepartreceiving/deleteById/${id}`);
};
export const searchData = (params, page, pageSize, isReceived, mode = 1) => {
  return api.get(`/sparepartreceiving/search`, { params: { ...params, pageSize, page, isReceived, mode } });
};
export const searchDataAll = (params, mode = 1) => {
  return api.get(`/sparepartreceiving/search`, { params: { ...params, mode } });
};
export const createImportSparePartPlan = (payload) => {
  return api.post(`/sparepartreceiving/createPlan `, payload);
};
export const updateImportSparePartPlan = (payload) => {
  return api.put(`/sparepartreceiving/updatePlan`, payload);
};
