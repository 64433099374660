import { ORDER_MANAGEMENT_ALL, ORDER_MANAGEMENT_RETAIL } from 'const/common';
import api from 'HttpClient/index';

export const getOrderManagementPerPage = (pageNo, pageSize, type) => {
  let url = `/Consignment/paging/page`;
  let params = {
    page: pageNo,
    pageSize: pageSize,
    goodTypeid: 1
  };
  if (type === ORDER_MANAGEMENT_RETAIL) {
    url = `/ExportConsignment/paging/page`;
    params.goodTypeid = 2;
  } else if (type === ORDER_MANAGEMENT_ALL) {
    params.goodTypeid = undefined;
  }
  return api.get(url, { params: params });
};

// input
export const searchOrderManagementInputPerPage = (payload, pageNo, pageSize, type) => {
  let url = `/Consignment/Search`;
  let typeOder = type || payload.type;
  let params = {
    // value: payload.value,
    page: pageNo || payload.page,
    pageSize: pageSize || payload.pageSize,
    goodTypeid: 1,
    fromDate: payload.fromDate,
    toDate: payload.toDate,
    ...payload
  };
  if (typeOder === ORDER_MANAGEMENT_RETAIL) {
    url = `/ExportConsignment/Search`;
    params.goodTypeid = 2;
  } else if (typeOder === ORDER_MANAGEMENT_ALL) {
    params.goodTypeid = undefined;
  }
  return api.get(url, { params: params });
};

export const getOrderManagementInputByID = (id) => {
  return api.get(`/Consignment/getById/${id}`);
};
// end input

// output
export const searchOrderManagementOutputPerPage = (payload, pageNo, pageSize) => {
  return api.get(`/Consignment/Search?value=${payload.value}&goodTypeId=2&Page=${pageNo}&PageSize=${pageSize}`);
};

export const getOrderManagementOutputByID = (id) => {
  return api.get(`/Consignment/getById/${id}`);
};
// end output

export const deleteOrderManagement = (id) => {
  return api.delete(`/Consignment/delete/${id}`);
};
export const deleteOrderExportManagement = (id) => {
  return api.delete(`/ExportConsignment/delete/${id}`);
};
export const createOrderManagement = (payload, type) => {
  let url = `/Consignment/create`;
  if (type === ORDER_MANAGEMENT_RETAIL) {
    url = `/ExportConsignment/create`;
  }
  return api.post(url, payload);
};

export const updateOrderManagement = (payload, type) => {
  let url = `/Consignment/update`;
  if (type === ORDER_MANAGEMENT_RETAIL) {
    url = `/ExportConsignment/update`;
  }
  return api.put(url, payload);
};
// doi api ke hoach dieu xe
export const updateOrderManagementDailytrucking = (payload) => {
  const url = `/DailyTransportPlanInfo/updateCutOffDateAndTotalWeight`;
  return api.put(url, payload);
};

export const getOrderManagementByCustomsClearanceCompleted = (searchValue, pagesize) => {
  return api.get(
    `/Consignment/getByCustomsClearanceCompleted?searchValue=${searchValue ?? ''}&pagesize=${pagesize ?? '50'}`
  );
};

export const getOrderManagementByCustomsClearanceCompletedNew = (params) => {
  return api.get(`/Consignment/getByCustomsClearanceCompleted`, { params });
};

export const getCustomsClearanceCompletedByOrderManagementID = (id) => {
  return api.get(`/ConsignmentContainer/getContCustomsClearance?consignmentId=${id}`);
};
export const getConsignmentByConsignmentId = (id) => {
  return api.get(`/ConsignmentContainer/getByConsignmentId?ConsignmentId=${id}`);
};

export const getSeaTransportConsignment = (id) => {
  return api.get(`/Consignment/getConsignment.SeaTransport`);
};
